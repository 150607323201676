import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Textarea, Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { CheckCircle, Edit, Lock, XCircle } from "react-feather";
import DatePicker from "react-datepicker";
import _ from "lodash";
import ImportEmpOvertime from "./ImportEmpOvertime";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function AllOvertime() {
  
  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";

  const [EditedFinalOvertime, setEditedFinalOvertime] = useState();
  const [RemarkEdited, setRemarkEdited] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [EmpList, setEmpList] = useState([]);
  const [SelectedEmp, setSelectedEmp] = useState([]);

  const Today = new Date();
  const [SelectedEmps, setSelectedEmps] = useState([]);

  const [OvertimeEligibilityList, setOvertimeEligibilityList] = useState([]);

  const StartDateCurrent = moment().startOf("month").format("YYYY-MM-DD");
  const EndDateCurrent = moment().endOf("month").format("YYYY-MM-DD");

  const [AttendanceDateRange, setAttendanceDateRange] = useState([
    new Date(StartDateCurrent),
    new Date(EndDateCurrent),
  ]);

  console.log("Selected Emp",SelectedEmp);

  const [AttendanceStartDate, AttendanceEndDate] = AttendanceDateRange;

  const HandleAttendanceHistoryChange = (dates) => {
    const [start, end] = dates;
    console.log("Dates", dates);
    setAttendanceDateRange(dates);

    GetOvertimeRequestList(start, end);
  };


  function getDateFromTime(time) {
    const [hours, minutes, seconds] = time.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
  }

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
      headerCheckboxSelection: true,
      checkboxSelection: ({data}) => {
        return !data.isLock
      },
      pinned : 'left'
    },
    {
      headerName: "Emp Name",
      field: "empName",
      pinned : 'left'
    },
    {
      headerName: "Overtime Date",
      field: "attendanceDate",
      cellRenderer: ({data}) => moment(data.attendanceDate).format('DD-MMM-YYYY'),
    },
    {
      headerName: "In-Time",
      field: "inTime",
    },
    {
      headerName: "Out-Time",
      field: "outTime",
    },
    {
      headerName: "Total Time",
      field: "totalTime",
    },
    {
      headerName: "Actual Overtime",
      field: "actualOverTime",
      valueGetter:(params) => ConvertToHoursMinutes(params.data.actualOverTime)
    },
    {
      headerName: "Final Overtime",
      field: "finalOvertimeTime",
      valueGetter:(params) => ConvertToHoursMinutes(params.data.finalOvertimeTime)
    },
    {
      headerName: "Remark",
      field: "remark",
    },
    {
      headerName: "Status",
      field: "isStatus",
      cellRenderer: (params) =>
        params.data.isStatus === 0 ? (
          <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Pending
          </span>
        ) : params.data.isStatus === 1 ? (
          <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Approved
          </span>
        ) : params.data.isStatus === 2 ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Rejected
          </span>
        ) : params.data.isStatus === 3 ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Cancelled
          </span>
        ) : (
          ""
        ),
    },
    {
      headerName: "Final Review",
      field: "isLock",
      cellRenderer: (params) =>
        params.data.isLock === false ? (
          <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Review Pending
          </span>
        ) : (
          <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Review Done
          </span>
        ),
    },
    {
      headerName: "Action",
      field: "Status",
      width: 300,
      cellRenderer: (params) =>
        params.data.isLock === false ? (
          <div>
            {params.data.isStatus === 0 ? (
              <div className="space-x-4">

                <Button 
                 leftIcon={<Edit size="14px" />}
                 onClick={()=>{
                  onOpen();

                  if(params.data.finalOvertimeTime !== "00:00:00"){
                    setEditedFinalOvertime(getDateFromTime(params.data.finalOvertimeTime || "00:00:00"))
                  }else{
                    setEditedFinalOvertime(getDateFromTime(params.data.actualOverTime || "00:00:00"))
                  }
                  
                  setRemarkEdited(params.data.remark);
                  setSelectedEmp(params.data);
                 }} size={"xs"}
                  variant={'outline'}
                  colorScheme={"blue"}
                >
                  Edit
                </Button>


                <Button
                  onClick={async () => {
                    let OvertimeData = params.data;

                    let body1 = {
                      // "overTimeFinalId": 0,
                      empName: OvertimeData.empName,
                      empGuid: OvertimeData.empGuId,
                      // overtimeRequestId: OvertimeData.overtimeRequestId,
                      dateFrom: OvertimeData.attendanceDate,
                      dateto: OvertimeData.attendanceDate,
                      overtimeDatefrom: OvertimeData.attendanceDate,
                      overtimeDateto: OvertimeData.attendanceDate,
                      inTime: OvertimeData.inTime,
                      outTime: OvertimeData.outTime,
                      totalTime: OvertimeData.totalTime,
                      overtimeTime: OvertimeData.finalOverTime,
                      finalOvertimeTime: OvertimeData.finalOvertimeTime !== "00:00:00" ? OvertimeData.finalOvertimeTime : OvertimeData.actualOverTime ,
                      remark: OvertimeData.remark,
                      isStatus: 1,
                      isLock: true,
                      month: new Date(OvertimeData.attendanceDate).getMonth()+1,
                      year: new Date(OvertimeData.attendanceDate).getFullYear(),
                      reportingOfficeId: userData.userId,
                      approveBy: userData.userId,
                      companyId: userData.companyId,
                      branchId: userData.isActiveBranch,
                    };

                    console.log("Approved Body", body1);

                    await authApi
                      .post(
                        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalData`,
                        body1
                      )
                      .then((response) => {
                        console.log(response);
                        const res = response.data;
                        if (!toast.isActive(id)) {
                          toast({
                            id,
                            title: res,
                            position: "top",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                        }

                        GetOvertimeRequestList(
                          moment(OvertimeData.attendanceDate)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          moment(OvertimeData.attendanceDate)
                            .endOf("month")
                            .format("YYYY-MM-DD")
                        );
                        console.log(
                          "Overtime DateRange",
                          moment(OvertimeData.attendanceDate)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          moment(OvertimeData.attendanceDate)
                            .endOf("month")
                            .format("YYYY-MM-DD")
                        );
                      })
                      .catch((error) => {
                        console.error(error);

                        if (!toast.isActive(id)) {
                          toast({
                            id,
                            title: "ERROR",
                            description: `Overtime not Approved`,
                            position: "top",
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                          });
                        }
                      });
                  }}
                  size={"xs"}
                  colorScheme={"green"}
                  leftIcon={<CheckCircle size="14px" />}
                >
                  Approve
                </Button>
                <Button
                  onClick={async () => {
                    let OvertimeData = params.data;

                    let body1 = {
                      // "overTimeFinalId": 0,
                      empName: OvertimeData.empName,
                      empGuid: OvertimeData.empGuId,
                      // overtimeRequestId: OvertimeData.overtimeRequestId,
                      dateFrom: OvertimeData.attendanceDate,
                      dateto: OvertimeData.attendanceDate,
                      overtimeDatefrom: OvertimeData.attendanceDate,
                      overtimeDateto: OvertimeData.attendanceDate,
                      inTime: OvertimeData.inTime,
                      outTime: OvertimeData.outTime,
                      totalTime: OvertimeData.totalTime,
                      overtimeTime: OvertimeData.finalOverTime,
                      finalOvertimeTime: OvertimeData.finalOvertimeTime !== "00:00:00" ? OvertimeData.finalOvertimeTime : OvertimeData.actualOverTime ,
                      remark: OvertimeData.remark,
                      isStatus: 2,
                      isLock: true,
                      month: new Date(OvertimeData.attendanceDate).getMonth()+1,
                      year: new Date(OvertimeData.attendanceDate).getFullYear(),
                      reportingOfficeId: userData.userId,
                      approveBy: userData.userId,
                      companyId: userData.companyId,
                      branchId: userData.isActiveBranch,
                    };

                    console.log("Rejected Body", body1);

                    await authApi
                      .post(
                        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalData`,
                        body1
                      )
                      .then((response) => {
                        console.log(response);
                        const res = response.data;
                        if (!toast.isActive(id)) {
                          toast({
                            id,
                            title: res,
                            position: "top",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                        }

                        GetOvertimeRequestList(
                          moment(OvertimeData.attendanceDate)
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          moment(OvertimeData.attendanceDate)
                            .endOf("month")
                            .format("YYYY-MM-DD")
                        );
                      })
                      .catch((error) => {
                        console.error(error);

                        if (!toast.isActive(id)) {
                          toast({
                            id,
                            title: "ERROR",
                            description: `Overtime not Rejected`,
                            position: "top",
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                          });
                        }
                      });
                  }}
                  size={"xs"}
                  colorScheme={"red"}
                  leftIcon={<XCircle size="14px" />}
                >
                  Reject
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : params.data.isLock === false ? (
          <div>
            <Button
              onClick={async () => {
                let OvertimeData = params.data;

                let body1 = {
                  // "overTimeFinalId": 0,
                  empName: OvertimeData.empName,
                  empGuid: OvertimeData.empGuId,
                  //  overtimeRequestId: OvertimeData.overtimeRequestId,
                  dateFrom: OvertimeData.attendanceDate,
                  dateto: OvertimeData.attendanceDate,
                  overtimeDatefrom: OvertimeData.attendanceDate,
                  overtimeDateto: OvertimeData.attendanceDate,
                  inTime: OvertimeData.inTime,
                  outTime: OvertimeData.outTime,
                  totalTime: OvertimeData.totalTime,
                  overtimeTime: OvertimeData.finalOverTime,
                  isStatus: OvertimeData.isStatus,
                  finalOvertimeTime: OvertimeData.finalOvertimeTime !== "00:00:00" ? OvertimeData.finalOvertimeTime : OvertimeData.actualOverTime ,
                  remark: OvertimeData.remark,
                  isLock: true,
                  month: new Date(OvertimeData.attendanceDate).getMonth()+1,
                  year: new Date(OvertimeData.attendanceDate).getFullYear(),
                  reportingOfficeId: userData.userId,
                  approveBy: userData.userId,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch,
                };

                console.log("Review Done Body", body1);

                await authApi
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalData`,
                    body1
                  )
                  .then((response) => {
                    console.log(response);
                    const res = response.data;
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                    GetOvertimeRequestList(
                      moment(OvertimeData.attendanceDate)
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                      moment(OvertimeData.attendanceDate)
                        .endOf("month")
                        .format("YYYY-MM-DD")
                    );
                  })
                  .catch((error) => {
                    console.error(error);

                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: `Overtime not Reviewed`,
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
              }}
              size={"xs"}
              colorScheme={"blue"}
            >
              <Lock size="14px" className="mr-2" />
              Review
            </Button>
          </div>
        ) : (
          ""
        ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true,
  }));

  const onSelectionChanged = useCallback(() => {
    var SelectedEmployees = gridRef.current.api.getSelectedRows();
    console.log("Selected Employees", SelectedEmployees);
    setSelectedEmps(SelectedEmployees);
  }, []);

  const ConvertToHoursMinutes = (time) => {
    const date = new Date("1970-01-01T" + time + "Z");
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return hours + " hours " + minutes + " minutes"
  }


  useEffect(() => {
    getEmployeeList();
    GetOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);
  }, []);

  const getEmployeeList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee List", response.data);
      const res = response.data;
      let ActiveEmpList = res.filter((data) => data.isActive === true);
      setEmpList(ActiveEmpList);
    } catch (error) {
      console.error(error);
    }
  };

  const GetOvertimeRequestList = async (FromDate, ToDate) => {
    console.log("Attendance DateRange", FromDate, ToDate);

    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Overtime/GetAttendanceAllEmpOverTimeListData?CompanyId=${
          userData.companyId
        }&BranchId=${userData.isActiveBranch}&FromDate=${new Date(
          FromDate
        ).toLocaleDateString("en-CA")}&ToDate=${new Date(
          ToDate
        ).toLocaleDateString("en-CA")}&AttStatus=p`
      );
      console.log("Overtime Request List", response);

      const res = response.data;

      let FinalOvertimeData = res.filter(
        (data) => data.finalOverTime !== "00:00:00"
      );

      let ActualOvertimeData = res.filter(
        (data) => data.actualOverTime !== "00:00:00"
      )

      // setRowData(FinalOvertimeData);
      HandleOvertimeFinalData(ActualOvertimeData);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleOvertimeFinalData = async (FinalOvertimeData) => {
    console.log("Final Overtime List", FinalOvertimeData);

    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOverTimeFinalListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Final Data List", response);

      const res = response.data;

      //  console.log("Value Check", _.compact(res.map((Finaladata)=>{
      //   if(Finaladata.empGuid === "c0f35713-fd11-4b77-aca1-e278b0d32a0c" && Finaladata.overtimeDatefrom === "2023-01-03T00:00:00"){
      //      return  Finaladata.isStatus
      //   }
      //  }))[0])

      let FinalRowData = FinalOvertimeData.map((data) => {
        return {
          ...data,
          isStatus:
            _.compact(
              res.map((Finaladata) => {
                if (
                  Finaladata.empGuid === data.empGuId &&
                  Finaladata.overtimeDatefrom === data.attendanceDate
                ) {
                  return Finaladata.isStatus;
                }
              })
            )[0] || 0,
          isLock:
            _.compact(
              res.map((Finaladata) => {
                if (
                  Finaladata.empGuid === data.empGuId &&
                  Finaladata.overtimeDatefrom === data.attendanceDate
                ) {
                  return Finaladata.isLock;
                }
              })
            )[0] || false,
            finalOvertimeTime: _.compact(
              res.map((Finaladata) => {
                if (
                  Finaladata.empGuid === data.empGuId &&
                  Finaladata.overtimeDatefrom === data.attendanceDate
                ) {
                  return Finaladata.finalOvertimeTime;
                }
              })
            )[0] || "00:00:00",
            remark : _.compact(
              res.map((Finaladata) => {
                if (
                  Finaladata.empGuid === data.empGuId &&
                  Finaladata.overtimeDatefrom === data.attendanceDate
                ) {
                  return Finaladata.remark;
                }
              })
            )[0] || "",
        };
      });

      console.log("Final Row Data", FinalRowData);
      //  setRowData(FinalRowData)
      GetOvertimeEmpEligibleList(FinalRowData);
    } catch (error) {
      console.error(error);
    }
  };

  const GetOvertimeEmpEligibleList = async (FinalRowData) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log("Eligible Overtime List",response.data);

      const res = response.data;

      let EligibleList = res.filter((data) => data.isActive === true);

      console.log("Eligible List", EligibleList);

      console.log(
        "Final Eligible List",
        _.compact(
          FinalRowData.map((AttData) => {
            if (
              EligibleList.filter(
                (data) => data.empGuid === AttData.empGuId
              ).some((data) => {
                if (data.isActive) {
                  return true;
                } else {
                  return false;
                }
              })
            ) {
              return AttData;
            }
          })
        )
      );

      setRowData(
        _.compact(
          FinalRowData.map((AttData) => {
            if (
              EligibleList.filter(
                (data) => data.empGuid === AttData.empGuId
              ).some((data) => {
                if (data.isActive) {
                  return true;
                } else {
                  return false;
                }
              })
            ) {
              return AttData;
            }
          })
        )
      );

      setOvertimeEligibilityList(EligibleList);
    } catch (error) {
      console.error(error);
    }
  };

  const SaveBatchApproval = async () => {
    let body = SelectedEmps.map((OvertimeData) => {
      return {
        empName: OvertimeData.empName,
        empGuid: OvertimeData.empGuId,
        dateFrom: OvertimeData.attendanceDate,
        dateto: OvertimeData.attendanceDate,
        overtimeDatefrom: OvertimeData.attendanceDate,
        overtimeDateto: OvertimeData.attendanceDate,
        inTime: OvertimeData.inTime,
        outTime: OvertimeData.outTime,
        totalTime: OvertimeData.totalTime,
        overtimeTime: OvertimeData.finalOverTime,
        finalOvertimeTime: OvertimeData.finalOvertimeTime !== "00:00:00" ? OvertimeData.finalOvertimeTime : OvertimeData.actualOverTime ,
        remark: OvertimeData.remark,
        isStatus: 1,
        isLock: true,
        month: new Date(OvertimeData.attendanceDate).getMonth()+1,
        year: new Date(OvertimeData.attendanceDate).getFullYear(),
        reportingOfficeId: userData.userId,
        approveBy: userData.userId,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      };
    });

    console.log("Batch Approval Body", body);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalList`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Overtime not Approved`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveBatchRejection = async () => {
    let body = SelectedEmps.map((OvertimeData) => {
      return {
        empName: OvertimeData.empName,
        empGuid: OvertimeData.empGuId,
        dateFrom: OvertimeData.attendanceDate,
        dateto: OvertimeData.attendanceDate,
        overtimeDatefrom: OvertimeData.attendanceDate,
        overtimeDateto: OvertimeData.attendanceDate,
        inTime: OvertimeData.inTime,
        outTime: OvertimeData.outTime,
        totalTime: OvertimeData.totalTime,
        overtimeTime: OvertimeData.finalOverTime,
        finalOvertimeTime: OvertimeData.finalOvertimeTime !== "00:00:00" ? OvertimeData.finalOvertimeTime : OvertimeData.actualOverTime ,
        remark: OvertimeData.remark,
        isStatus: 2,
        isLock: true,
        month: new Date(OvertimeData.attendanceDate).getMonth()+1,
        year: new Date(OvertimeData.attendanceDate).getFullYear(),
        reportingOfficeId: userData.userId,
        approveBy: userData.userId,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      };
    });

    console.log("Batch Rejection Body", body);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalList`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Overtime not Approved`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };



  const SaveEditFinalOvertime = async (e) => {
    
    e.preventDefault();

    let body = {
      // overTimeFinalId: 0,
      empName: SelectedEmp.empName,
      empGuid: SelectedEmp.empGuId,
      empCode: SelectedEmp.empCode,
      grossSalary: 0,
      // overtimeRequestId: 0,
      dateFrom: SelectedEmp.attendanceDate,
      dateto: SelectedEmp.attendanceDate,
      overtimeDatefrom: SelectedEmp.attendanceDate,
      overtimeDateto: SelectedEmp.attendanceDate,
      month: new Date(SelectedEmp.attendanceDate).getMonth()+1,
      year: new Date(SelectedEmp.attendanceDate).getFullYear(),
      inTime: SelectedEmp.inTime,
      outTime: SelectedEmp.outTime,
      totalTime: SelectedEmp.totalTime,
      overtimeTime: SelectedEmp.actualOverTime,
      finalOvertimeTime: moment(EditedFinalOvertime).format('HH:mm:ss'),
      remark: RemarkEdited,
      totalovertime: moment(EditedFinalOvertime).format('HH:mm:ss'),
      otCalculationType: "string",
      overtimeAmtPerHour: 0,
      totalOvertimeAmount: 0,
      isStatus: 0,
      isLock: false,
      reportingOfficeId: userData.userId,
      approveBy: 0,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    }

    console.log("Edited Final Body",body);

    await authApi.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalData`,body
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      onClose();
      GetOvertimeRequestList(moment(SelectedEmp.attendanceDate).startOf("month").format('YYYY-MM-DD'), moment(SelectedEmp.attendanceDate).endOf("month").format('YYYY-MM-DD'),SelectedEmp.empGuId);
      
    })
    .catch((error) => {
      console.error(error);
    
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Overtime not Rejected`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
    


  }

  return (
    <div>

<Tabs colorScheme="purple" size={'sm'}>
  <TabList>
    <Tab>Calculated Overtime</Tab>
    <Tab>Import Overtime</Tab>
  </TabList>
  <TabPanels>
    <TabPanel px={0}>
      <div className="flex justify-between pb-3 items-center">
      <div className="px-5 flex space-x-5 ">
        <div className="my-auto">
          <FontAwesomeIcon
            className="rotate-180 text-xl"
            icon={faArrowTurnUp}
          />
        </div>
        <Button onClick={SaveBatchApproval} size={"sm"} colorScheme="green">
          <CheckCircle size="16px" className="mr-2" />
          Approve
        </Button>
        <Button onClick={SaveBatchRejection} size={"sm"} colorScheme="red">
          <XCircle size="16px" className="mr-2" />
          Reject
        </Button>
      </div>

      <div className="flex items-center justify-end">
          <label className="font-semibold text-lg mr-3 ">
            Overtime Data Range:
          </label>
          <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
              selectsRange={true}
              startDate={AttendanceStartDate}
              endDate={AttendanceEndDate}
              onChange={(update) => {
                HandleAttendanceHistoryChange(update);
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Attendance Range"
            />
          </div>
        </div>

      </div>



      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          rowSelection={"multiple"}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </TabPanel>
    <TabPanel px={0}>
      <ImportEmpOvertime/>
    </TabPanel>
  </TabPanels>
</Tabs>


      <Modal size={'2xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >Edit Overtime</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
          <form onSubmit={SaveEditFinalOvertime}>
            <ModalBody className="space-y-2 my-2">
             <div className="flex pb-1 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Emp Code</p>
                  <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Emp Name</p>
                  <h6 className="font-medium">{SelectedEmp.empName}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Attendance Date</p>
                  <h6 className="font-medium">{moment(SelectedEmp.attendanceDate).format('DD/MM/YYYY')}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Actual Overtime</p>
                  <h6 className="font-medium">{ConvertToHoursMinutes(SelectedEmp.actualOverTime)}</h6>
                </div>
              </div>
              <hr />
              <div className="space-y-2">
                <div className="flex justify-between"> 
                 <label>Final Overtime</label>
                 <label>Current: {ConvertToHoursMinutes(moment(EditedFinalOvertime).format('HH:mm:ss'))}</label>
                </div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={EditedFinalOvertime}
                  onChange={(date) => {

                    setEditedFinalOvertime(date);

                    // let TimeDifference = SelectedEmp?.finalOvertimeTime !== "00:00:00" ? getDateFromTime(SelectedEmp.finalOvertimeTime || "00:00:00") : getDateFromTime(SelectedEmp.actualOverTime || "00:00:00")
                    // if(date?.getTime() > TimeDifference.getTime()){
                    //   setEditedFinalOvertime(TimeDifference)
                    // }else{
                    //   setEditedFinalOvertime(date);
                    // }
                    
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  // minTime={getDateFromTime("00:00:00")}
                  // maxTime={SelectedEmp?.finalOvertimeTime !== "00:00:00" ? getDateFromTime(SelectedEmp.finalOvertimeTime || "00:00:00") : getDateFromTime(SelectedEmp.actualOverTime || "00:00:00")}
                  placeholderText="Final Overtime"
                />
              </div>

              <div className="space-y-2">
                <label>Remarks</label>
                <Textarea isRequired value={RemarkEdited} onChange={({target})=>{setRemarkEdited(target.value)}} borderColor={'gray.400'}  placeholder="Remark" />
              </div>
            </ModalBody>
            <hr/>
            <ModalFooter>
              <Button
                variant={"outline"}
                colorScheme="purple"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              <Button type="submit" colorScheme={"purple"} variant="solid">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default AllOvertime;
