import React,{useState,useEffect} from "react";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, BarChart, Bar } from "recharts";
import { Button } from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";
import useFetch from "../Utils/useFetch";
import moment from "moment";
import StatCard from "../UI Components/StatCard";
import { LuCalendarX,LuUserX,LuUser,LuUsers} from 'react-icons/lu';


export default function AtttendanceDashboard () {

    const { userData } = useAuth();

    const [AttendanceDate, setAttendanceDate] = useState(new Date());

    const { IsFetchLoading: IsAttendanceStatsLoading, FetchedData: AttendanceStats, Refetch: RefetchAttendanceStats} = useFetch('/api/Attendance/GetDashBoardData',{
      Attendancedate: moment(AttendanceDate).format('YYYY-MM-DD'),
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    })
    

    const [AttendanceChartData, setAttendanceChartData] = useState([]);
    const [ActiveChartButton, setActiveChartButton] = useState();

    useEffect(()=>{
      HandleDurationChart('5D')
      },[]);

      useEffect(()=>{
        RefetchAttendanceStats();
      },[AttendanceDate])


      const formatXAxis = (tickItem) => {
        if(ActiveChartButton === '5D' || ActiveChartButton === '1M' ){
          return moment(tickItem).format('DD-MMM-YYYY')
        }else{
          return tickItem
        }
      } 


    const HandleDurationChart=async(Duration) =>{

      setActiveChartButton(Duration)

      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetDashBoardGrapfhData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Duration=${Duration}&Attendancedate=${new Date().toLocaleDateString('en-CA')}`
        )
        console.log("Area Chart Data",response);
        setAttendanceChartData(response.data);
      } catch (error) {
        console.error(error)
  
      }

    }
  

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Attendance Dashboard</h1>
        <div className="flex items-center">
            <label className="font-semibold text-lg mr-3">Date :</label>
            <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {setAttendanceDate(date); }}
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              placeholderText='Select Attendance Date'
            />
            </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-6 gap-6">

      <StatCard IsLoading={IsAttendanceStatsLoading} StatName = "Total Employees" StatNumber = {AttendanceStats?.totalEmpCount} Description = {'-'} IconBg = {"green.100"} StatIcon ={<LuUsers className="text-green-600 text-2xl"/>} />
       <StatCard IsLoading={IsAttendanceStatsLoading} StatName = "Total Present" StatNumber = {AttendanceStats?.totalPresent} Description = {'-'} IconBg = {"orange.100"} StatIcon ={<LuUser className="text-orange-600 text-2xl"/>} />
       <StatCard IsLoading={IsAttendanceStatsLoading} StatName = "Total Leaves" StatNumber = {AttendanceStats?.totalLeave} Description = {'-'} IconBg = {"purple.100"} StatIcon ={<LuCalendarX className="text-purple-600 text-2xl"/>} />
       <StatCard IsLoading={IsAttendanceStatsLoading} StatName = "Total Absent" StatNumber = {AttendanceStats?.totalAbsent} Description = {'-'} IconBg = {"red.100"} StatIcon ={<LuUserX className="text-red-600 text-2xl"/>} />


      </div> 


      <div className="bg-white p-4 rounded-2xl">

        <div className="space-x-4 mt-4 mb-6 text-center">
          <Button onClick={()=>{HandleDurationChart('5D')}} size={'sm'} variant={ActiveChartButton === '5D'? 'solid' : 'outline'} colorScheme='purple'>5D</Button>
          <Button onClick={()=>{HandleDurationChart('1M')}} size={'sm'} variant={ActiveChartButton === '1M'? 'solid' : 'outline'} colorScheme='purple'>1M</Button>
          <Button onClick={()=>{HandleDurationChart('6M')}} size={'sm'} variant={ActiveChartButton === '6M'? 'solid' : 'outline'} colorScheme='purple'>6M</Button>
          <Button onClick={()=>{HandleDurationChart('YTD')}} size={'sm'} variant={ActiveChartButton === 'YTD'? 'solid' : 'outline'} colorScheme='purple'>YTD</Button>
          <Button onClick={()=>{HandleDurationChart('1Y')}} size={'sm'} variant={ActiveChartButton === '1Y'? 'solid' : 'outline'} colorScheme='purple'>1Y</Button>
          <Button onClick={()=>{HandleDurationChart('2Y')}} size={'sm'} variant={ActiveChartButton === '2Y'? 'solid' : 'outline'} colorScheme='purple'>2Y</Button>
          <Button onClick={()=>{HandleDurationChart('3Y')}} size={'sm'} variant={ActiveChartButton === '3Y'? 'solid' : 'outline'} colorScheme='purple'>3Y</Button>
          <Button onClick={()=>{HandleDurationChart('5Y')}} size={'sm'} variant={ActiveChartButton === '5Y'? 'solid' : 'outline'} colorScheme='purple'>5Y</Button>

        </div>

      <ResponsiveContainer height={400}>
      <AreaChart 
        width={730} 
        height={300} 
        data={AttendanceChartData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="presentColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="8%" stopColor="#82ca9d" stopOpacity={0.4}/>
            <stop offset="92%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="absentColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="8%" stopColor="#f44336" stopOpacity={0.4}/>
            <stop offset="92%" stopColor="#f44336" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="leaveColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="8%" stopColor="#60A5FA" stopOpacity={0.4}/>
            <stop offset="92%" stopColor="#60A5FA" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="attendanceDate" tickFormatter={formatXAxis}/>
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip            
           labelFormatter={value => {
             return moment(value).format('DD-MMM-YYYY, ddd');
           }}  />
        <Legend verticalAlign="top" height={40}/>
        <Area type="monotone" dataKey="totalPresent" strokeWidth={2} name="Present Employees" stroke="#82ca9d" fillOpacity={1} fill="url(#presentColor)" />
        <Area type="monotone" dataKey="totalAbsent"  strokeWidth={2} name="Absent Employees" stroke="#f44336" fillOpacity={1} fill="url(#absentColor)" />
        <Area type="monotone" dataKey="totalLeave"   strokeWidth={2} name="On Leave Employees" stroke="#60A5FA" fillOpacity={1} fill="url(#leaveColor)" />
        {/* <Area type="monotone" stackId={1} dataKey="totalPresent" strokeWidth={2} name="Present Employees" stroke="#82ca9d" fill="#82ca9d" />
        <Area type="monotone" stackId={1} dataKey="totalAbsent"  strokeWidth={2} name="Absent Employees" stroke="#f44336" fill="#f44336" />
        <Area type="monotone" stackId={1} dataKey="totalLeave"   strokeWidth={2} name="On Leave Employees" stroke="#60A5FA" fill="#60A5FA" /> */}
      </AreaChart>
     </ResponsiveContainer>

      {/* <ResponsiveContainer height={400}>
        <BarChart
          width={730} 
          height={300} 
          data={AttendanceChartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="attendanceDate" tickFormatter={formatXAxis} />
          <YAxis />
          <Tooltip 
           labelFormatter={value => {
             return moment(value).format('DD-MMM-YYYY, ddd');
           }} 
          />
          <Legend />
          <Bar dataKey="totalPresent" name="Present Employees"  stackId="a"  fill="#82ca9d" />
          <Bar dataKey="totalAbsent"  name="Absent Employees"   stackId="a"  fill="#FF4858" />
          <Bar dataKey="totalLeave"   name="On Leave Employees" stackId="a"  fill="#60A5FA" />
        </BarChart>
          </ResponsiveContainer> */}


      </div>

      
      
    </div>
  );
}
