import React, { createContext, useContext, useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import authApi from "./AuthApis";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, Input, Button, } from '@chakra-ui/react'

const AuthContext = createContext({
  isLoggedIn: false,
  userData: null,
  authToken: null,
  signIn: () => {},
  signOut: () => {},
  isAuthenticated: () => {}
});

export const useAuth = () => {
    return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { isOpen: isLoginModalOpen, onOpen: onLoginModalOpen, onClose: onLoginModalClose } = useDisclosure();
    const [IsLoading, setIsLoading] = useState(true);
    const [IsSessionLoading, setIsSessionLoading] = useState(false);
    const [LoginDetails, setLoginDetails] = useState({
      userName: '',
      password: '',
      ipAddress: '',
      userAgent: ''
    })

    const [state, setState] = useState({
      isLoggedIn: false,
      userData: null,
      authToken: null
    });

    const getBrowserInfo = () => {
      const userAgent = navigator.userAgent;
      let browserName = 'Unknown';
  
      if (userAgent.indexOf('Firefox') > -1) {
        browserName = 'Mozilla Firefox';
      } else if (userAgent.indexOf('SamsungBrowser') > -1) {
        browserName = 'Samsung Internet';
      } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        browserName = 'Opera';
      } else if (userAgent.indexOf('Trident') > -1) {
        browserName = 'Microsoft Internet Explorer';
      } else if (userAgent.indexOf('Edge') > -1) {
        browserName = 'Microsoft Edge';
      } else if (userAgent.indexOf('Chrome') > -1) {
        browserName = 'Google Chrome';
      } else if (userAgent.indexOf('Safari') > -1) {
        browserName = 'Apple Safari';
      }
  
      return browserName;
    };

    useEffect(() => {
      const loadStoredData = async () => {
        // const storedUserId = localStorage.getItem('userGuId') || null;
        const storedUserId = localStorage.getItem('uToken') || null;
        const storedToken = localStorage.getItem('token') || null;
        // console.log("Stored GuId",storedUserId,storedToken);

        if(storedUserId && storedToken){
            try {
              const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetLoggedInUser?EmpGuid=${storedUserId}`);
              console.log("userData response",response);
              const res = response.data;
              setState({ isLoggedIn: true, userData: res, authToken: storedToken });
              if(res?.companyId === 0){ 
                navigate('/createcompany');
              }else if(res?.isActiveBranch === 0){ 
                navigate('/createbranch');
              }else if(!res?.isPasswordReset){
                navigate('/changepassword');
              }else if(res?.empCode === null){
                navigate('/adminempdetails');
              }

            } catch (err) {
              console.error("userData error",err);
              if(err.response){
                  if((err.response.data === 'No record found!')){
                      signOut();  
                      navigate('/');
                  }else if(err.response.statusText === "Unauthorized" || err.response.status === 401){
                     HandleSessionModal();                     
                    // signOut();  
                    // navigate('/');                        
                  }
              }
            }
        }else if (location.pathname === "/forgotpassword" ||
          location.pathname === "/createcompany" ||
          location.pathname === "/createbranch" ||
          location.pathname === "/changepassword" ||
          location.pathname === "/register" ||
          location.pathname === "/maintenance" ||
          location.pathname.includes("/checkout")){
          navigate(location.pathname);
        }else {
          signOut();  
          navigate('/');
        }

        setIsLoading(false);
      };
      loadStoredData();
    }, []);

    console.log("test checkout",!location.pathname.includes("/checkout"));

    const HandleSessionModal = () => {
      if(location.pathname !==  '/'){
        onLoginModalOpen();
      }
    }
  
    const signIn = async (data,Action) => {
      console.log("data",data);
      setIsLoading(false);
      localStorage.removeItem('token');
      localStorage.removeItem('isLoggedIn');
      // localStorage.removeItem('userGuId');
      localStorage.removeItem('uToken');
      try {
        const response = await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyUserLogin`,data);
        const res = response.data;
        console.log("Auth login Response",res,res.token);
        
        if(res){
            localStorage.setItem('token', res.token);
            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('uToken', res.empGuid);
            // localStorage.setItem('userGuId', res.guId);
            setState({ isLoggedIn: true, userData: res, authToken: res.token });
            
            if(Action === "Session Modal"){
              onLoginModalClose();
              setLoginDetails({
                userName: '',
                password: '',
                ipAddress: '',
                userAgent: ''
              })
            }else{
              if(res?.companyId === 0){ 
                navigate('/createcompany'); 
              }else if(res?.isActiveBranch === 0){ 
                  navigate('/createbranch');
              }else if(!res?.isPasswordReset){
                  navigate('/changepassword');
              }else if(res?.empCode === null){
                  navigate('/adminempdetails');
              }else{
                  navigate('/dashboard');
              }
            }
        }

      } catch (error) {
        console.log("Login Error",error);
        if (error.response) {
            console.error('Response data:', error.response.data);
            alert(error.response.data);
            console.log('Title:', error.response.data.title);
            console.log('Status code:', error.response.status);
            // console.log('Headers:', error.response.headers);
          } else if (error.request) {
            console.log('Request:', error.request);
          } else {
            console.log('Error:', error.message);
          }
          console.log('Config:', error.config);
      }finally{
        setIsLoading(false);
        return true
      }
    };
  
    const signOut = async () => {
      localStorage.removeItem('token');
      localStorage.removeItem('isLoggedIn');
      // localStorage.removeItem('userGuId');
      localStorage.removeItem('uToken');
      setState({ isLoggedIn: false, userData: null, authToken: null });
      return true
    };
   
    const isAuthenticated = () => state?.isLoggedIn;

    if(IsLoading){
        return (<div className="flex justify-center h-screen items-center bg-gradient-to-br from-rose-100 to-teal-100">
                 <div className="rounded-md h-12 w-12 border-4 border-t-4 border-purple-600 animate-spin absolute"></div>
               </div>)
    }
 
    console.log("Auth state",state);
  
    return (
      <AuthContext.Provider value={{ ...state,HandleSessionModal, signIn, signOut, isAuthenticated }}>
        {children}
        <Modal isCentered closeOnOverlayClick={false} isOpen={isLoginModalOpen} onClose={onLoginModalClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize={"lg"}
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight={"bold"}
          >Session Expired!!</ModalHeader>
          <ModalBody >
              <form 
                className="space-y-4" 
                onSubmit={async(e)=>{
                  e.preventDefault();
                  setIsSessionLoading(true);
                     let userIpAddress = '192.168.1.1'; // Default IP address in case of failure
                      try {
                        const ipResponse = await axios.get('https://api.ipify.org/?format=json');
                        // const ipResponse = await axios.get('https://ifconfig.me/all.json');
                        userIpAddress = ipResponse?.data?.ip || userIpAddress;
                        console.log("IP Response:", ipResponse);
                      } catch (error) {
                        console.error('Error fetching IP address:', error);
                      }
                    
                      const userAgent = getBrowserInfo() || "Other";
                    
                      const body = {
                        ...LoginDetails,
                        ipAddress: userIpAddress,
                        userAgent: userAgent
                      };
                    
                      try {
                        const response = await signIn(body,"Session Modal");
                        console.log("Login Response:", response);
                      } catch (error) {
                        console.error('Error during sign in:', error);
                      } finally {
                        setIsSessionLoading(false);
                      }
                }}
              >
            <div className="space-y-2">
              <label>Username</label>
              <Input
                isRequired
                value={LoginDetails?.userName} 
                onChange={({target})=>{
                  setLoginDetails({
                    ...LoginDetails,
                    userName: target.value
                  })
                }} 
                borderColor="gray.400"
                placeholder='Enter Username' 
              />
            </div>
            <div className="space-y-2">
              <label>Password</label>
              <Input 
                isRequired
                value={LoginDetails?.password} 
                onChange={({target})=>{
                  setLoginDetails({
                    ...LoginDetails,
                    password: target.value
                  })
                }} 
                type="password"
                borderColor="gray.400"
                placeholder='Enter Password' 
              />
            </div>
              <Button 
                type='submit'
                isLoading={IsSessionLoading}
                loadingText="Signing In..."
                className='!mb-4 w-full' 
                colorScheme='purple' 
              >Sign In</Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      </AuthContext.Provider>
    );
  };
  
  export { AuthContext, AuthProvider };