import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Input, Button, useDisclosure, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Eye, Upload } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp, faEdit, faLock } from "@fortawesome/free-solid-svg-icons";
import usePost from "../Utils/usePost";
import useFetch from "../Utils/useFetch";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

const BulkFormUpload = () => {

    const { userData } = useAuth();
    const { IsPostLoading, PostData } = usePost('/api/Form16Document/SaveForm16Document');


    const FileRef = useRef();

    const reset = () => {
        FileRef.current.value = "";
      };

      const {
        isOpen: isBulkUploadOpen,
        onOpen: onBulkUploadOpen,
        onClose: onBulkUploadClose,
      } = useDisclosure();
      

      const {
        isOpen: isViewForm16Open,
        onOpen: onViewForm16Open,
        onClose: onViewForm16Close,
      } = useDisclosure();

      const [PdfPath, setPdfPath] = useState();
      const [BulkUploads, setBulkUploads] = useState([]);
      const [FinancialYear, setFinancialYear] = useState();

    const { FetchedData: FinancialYearList } = useFetch(`/api/Master/GetFinancialYearMasterList`,{
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch,
      });

      const [SelectedEmpList, setSelectedEmpList] = useState([])
    const [IsLoading, setIsLoading] = useState({
        ViewLoading: false,
        SaveLoading: false
      })
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const gridOptions = {
      isRowSelectable: (rowNode) => {
        return rowNode.data.isStatus === null;
      },
    };
    const [columnDefs] = useState([
          {
            headerName: "Employee Code",
            field: "empCode",
            checkboxSelection:(params)=> params.data.isStatus === null,
            headerCheckboxSelection:true
          },
          {
            headerName: "Employee Name",
            field: "fullName",
            valueGetter: (params) => (params.data.title || '') + ' ' + params.data.fullName
          },
          {
            headerName: "Financial Year",
            field: "financialYearName",
          },
          {
            headerName: "Status",
            field: "isStatus",
            cellRenderer:(params)=>{
             return params.data.isStatus === "1" && params.data.isLock === true ? 
             <span className="bg-green-600 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap"><FontAwesomeIcon icon={faLock} size="sm" className="mr-1"/>Confirmed</span>: params.data.isStatus === "1" && params.data.isLock === false ? 
             <span className="bg-green-500 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Completed</span>:
              <span className="bg-red-500 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Pending</span>
            }
          },
          {
            headerName: "Action",
            // width: 400,
            floatingFilter: false,
            cellRenderer: (params) => (
              <div className="space-x-4">
                {params.data.isStatus === "1" &&<Button
                 isLoading={IsLoading?.ViewLoading}
                 loadingText="Loading..."
                 leftIcon={<Eye size={'14px'} />}
                 onClick={()=>{
                  let FormRowData = params.data;
                   setIsLoading((prevData)=>{
                     return {
                       ...prevData,
                       ViewLoading : true
                     }
                   })
                //    handleDownloadFile(params.data.attachmentFile)
                 }}
                 colorScheme="blue"
                 variant='outline'
                 size={'xs'}
               >
                 View
               </Button>}
               {(params.data.isLock === false && params.data.isStatus === "1" && <Button
                 leftIcon={<FontAwesomeIcon icon={faEdit} />}
                 onClick={()=>{
                  let FormRowData = params.data;
                  //  HandleForm16Edit(FormRowData);
                //   onAddAttachmentOpen();
                 }}
                 colorScheme="blue"
                 variant='outline'
                 size={'xs'}
               >
                 Edit
               </Button>)}   
              </div>
            ),
          },
    ]);

    const onSelectionChanged = useCallback(() => {
      var SelectedEmployees = gridRef.current.api.getSelectedRows();
      console.log("Selected Employees", SelectedEmployees);
      setSelectedEmpList(SelectedEmployees);
    }, []);

    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);

    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
       flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));


    const ValidateFile = (file,Employee) => {

        const FileName = file?.name?.split('_')
  
        const FirstYear = /\d{4}/g;
        const SecondYear = /\d{2}$/;
        const year1 = FileName[2]?.match(FirstYear)[0];
        const year2 = FileName[2]?.match(SecondYear)[0];
        console.log("Filname",FileName,year1,year2);
  
        const FinanYear = FinancialYearList.filter(data => parseInt(data.financialYearId) === parseInt(FinancialYear) ).map(FilData => FilData.financialYearName)[0].split('-')
  
        if((Employee.panNumber === FileName[1]) && (year1?.includes(FinanYear[0]) && FinanYear[1]?.includes(year2))){
          return true
        }else {
          return false
        }
      }

      
    useEffect(()=>{
        if(FinancialYearList.length > 0){
            getForm16List();
        }
      },[FinancialYearList]);

    const getForm16List = async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Form16Document/GetForm16DocumentList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FinancialYearId=${FinancialYearList.filter(data => data.isCurrent === true ).map(FilData => FilData.financialYearId)[0]}`
          )
          console.log("Form 16 List",response);
          setRowData(response.data);
          setFinancialYear(FinancialYearList.filter(data => data.isCurrent === true ).map(FilData => FilData.financialYearId)[0]);
        } catch (error) {
          console.error(error)
        }
      }

      
    const HandleBulkUpload =  (target) => {

        const files = target.files;
  
      const fileDataArray = [];
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        fileDataArray.push({
          name: file.name,
          panNumber: file?.name?.split('_')[1] || "Not Found",
          file: file,
        });
      }
  
  
      let FoundedEmployees = fileDataArray.map((data)=>{
        let EmployeeFounded = SelectedEmpList.find(({panNumber})=> panNumber === data.panNumber )
        // console.log("Founded",EmployeeFounded);
  
        if(EmployeeFounded){
          return {
            ...data,
            isValidated: ValidateFile(data.file,EmployeeFounded),
            fullName: EmployeeFounded.fullName,
            guId:EmployeeFounded.guId,
            empCode:EmployeeFounded.empCode,
            fileFinanYear : ValidateFile(data.file,EmployeeFounded) ? FinancialYearList.filter(data => parseInt(data.financialYearId) === parseInt(FinancialYear) ).map(FilData => FilData.financialYearName)[0] : "Not Found"
          }
        }else{
          return {
            ...data,
            isValidated: false,
            fullName: "Not Found",
            guId:"",
            empCode:"",
            fileFinanYear: "Not Found"
          }      
        }
      })
  
  
      setBulkUploads(FoundedEmployees);
  
      }

      const SaveBulkForm16 = async () => {

        let WrongRecord = BulkUploads.filter(data => data.fullName === "Not Found" && data.fileFinanYear === "Not Found");
  
        // console.log("Wrong Records",WrongRecord);
        if(window.confirm(`${WrongRecord.length > 0 ? 'Only Error Free Employee Form 16 will get Saved. Are you sure you want to continue?' : 'Are you sure you want to save the records?'}`)){
  
        let Records = BulkUploads.filter(data => data.fullName !== "Not Found" && data.fileFinanYear !== "Not Found");
  
        // console.log("Records",Records);
  
        if(Records.length === 0){
          alert("No Records to Save! Enter the Correct Attachments")
        }else{
          for(const FormFile of Records){
            const UploadData = new FormData();
            UploadData.append("id",0);
            UploadData.append("attachment",FormFile.file); 
            UploadData.append("empGuid",FormFile.guId);
            UploadData.append("empCode",FormFile.empCode);
            UploadData.append("financialYearId",FinancialYear);
            UploadData.append("financialYearName",FinancialYearList.filter(data => parseInt(data.financialYearId) === parseInt(FinancialYear) ).map(FilData => FilData.financialYearName)[0])
            UploadData.append("companyId",userData.companyId);
            UploadData.append("branchId",userData.isActiveBranch);
   
            const response = await PostData(UploadData);
   
          }
   
          getForm16List(FinancialYear);
          onBulkUploadClose();
          setBulkUploads([]);
          setFinancialYear(undefined)
        }
  
  
  
        }
  
      }
  return (
    <div>
        <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
          <h1 className="text-xl font-bold text-gray-800">
            Bulk Form 16 Upload
          </h1>
        </div>

        <div className="px-5 flex py-3 space-x-3 ">
          <div className="my-auto">
            <FontAwesomeIcon
              className="rotate-180 text-"
              icon={faArrowTurnUp}
            />
          </div>
          <Button isDisabled={SelectedEmpList.length === 0 ? true : false} onClick={()=>{onBulkUploadOpen();}} leftIcon={<Upload size={'14px'} />} size={"sm"}  colorScheme="blue">
            Upload
          </Button>
        </div>

        <div className="ag-theme-alpine">
          <AgGridReact
            gridOptions={gridOptions}
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection="multiple"
            paginationPageSize={20}
            paginationNumberFormatter={paginationNumberFormatter}
            onSelectionChanged={onSelectionChanged}
          />
        </div>


        <Modal size='full' scrollBehavior="inside" isOpen={isBulkUploadOpen} onClose={()=>{
                onBulkUploadClose();
                setBulkUploads([]);
                setSelectedEmpList([]);
                reset();
              }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Upload Form 16</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
              <div className="flex justify-center items-center w-full my-10">
                <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
                  <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">only PDFs are accepted</p>
                  </div>
                  <Input id="dropzone-file" hidden type="file" multiple accept="application/pdf" onChange={({target})=>{HandleBulkUpload(target)}} />
                </label>
              </div> 

              {BulkUploads?.length !== 0 &&<div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-white uppercase bg-slate-700 whitespace-nowrap">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                       File Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                       File Pan Number
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Financial Year
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Employee Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {BulkUploads?.map((data,i)=>{
                      return <tr key={i} className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                      <th
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                      >
                        {data.name}
                      </th>
                      <td className="py-4 px-6">
                        {data.panNumber === "Not Found" ? <span className="bg-red-500 px-1.5 py-1 rounded-lg text-white font-semibold whitespace-nowrap">Wrong File Name</span> : data.panNumber}
                      </td>
                      <td className="py-4 px-6">
                       {data.fileFinanYear  === "Not Found" ? <span className="bg-red-500 px-1.5 py-1 rounded-lg text-white font-semibold whitespace-nowrap">Wrong Financial Year</span> : data.fileFinanYear}
                      </td>
                      <td className="py-4 px-6">
                        {data.fullName  === "Not Found" ? <span className="bg-red-500 px-1.5 py-1 rounded-lg text-white font-semibold whitespace-nowrap">Employee Not Found</span> : data.fullName}
                      </td>
                      <td className="py-4 px-6">
                        <Button onClick={()=>{
                           const objectUrl = URL.createObjectURL(data.file)
                           setPdfPath(objectUrl);
                           onViewForm16Open();
                        }} leftIcon={<Eye size={'16px'} />} size={'xs'} colorScheme="blue" >View</Button>
                      </td>
                    </tr>
                    })}
                  </tbody>
                </table>
              </div>}

            </ModalBody>
            <hr />
            <ModalFooter>
            <Button isDisabled={ BulkUploads.length === 0 && FinancialYear === undefined ? true : false} onClick={()=>{
             // SaveBulkForm16();
            }} colorScheme='purple' mr={4} >
                Save
              </Button>
              <Button variant={'outline'} colorScheme='purple' onClick={()=>{
                onBulkUploadClose();
                setBulkUploads([]);
                setSelectedEmpList([])
              }}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </div>
  )
}

export default BulkFormUpload