import React, { useEffect } from 'react'
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';

const EmpLeaveBalanceReport = () => {

    const {userData} = useAuth();

    useEffect(()=>{
        GetLeaveBalanceData();
    },[])

    const GetLeaveBalanceData = async () => {
        try {
            const response = await authApi.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveBalanceList?month=3&year=2024&CompanyId=${userData?.companyId}&BranchId=${userData?.isActiveBranch}`
            )
            console.log("Emp Leave Balance",response?.data?.filter(data => data.empGuid === userData.guId));
        } catch (err) {
            console.error(err);
        }
    }
  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Emp Leave Balance Report</h1>
      </div>
    </div>
  )
}

export default EmpLeaveBalanceReport