import * as Yup from "yup";

export const BranchMasterSchema = Yup.object({
    branchName: Yup.string()
    .matches(/^[a-zA-Z0-9\s-]*$/, 'Special characters are not allowed except hyphens')
    .required("Branch Name is required"),
    branchCode: Yup.string()
    .matches(/^[a-zA-Z0-9\s-]*$/, 'Special characters are not allowed except hyphens')
    .required("Branch Code is required"),
    countryId: Yup.string()
    .required("Country Name is required"),
    stateId: Yup.string()
    .required("State Name is required"),
    address1: Yup.string()
    .matches(/^[a-zA-Z0-9\s.,-]*$/, 'Address can contain letters, numbers, spaces, commas, hyphens, and periods.')
    .required('Address1 is required'),
    address2: Yup.string()
    .matches(/^[a-zA-Z0-9\s.,-]*$/, 'Address can contain letters, numbers, spaces, commas, hyphens, and periods.')
    .required('Address2 is required'),
    pincode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pincode is not valid')
    .required('Pincode is required'),
    currencyId: Yup.string()
    .required("Currency is required"),
    timezoneId: Yup.string()
    .required("Timezone is required"),
    companyName: Yup.string()
    .required("Company Name is required"),
    logo: Yup.string()
    .required("Branch Logo is required"),
})

export const DesignationSchema = Yup.object({
  designationName: Yup.string()
  .max(20, "Must be 20 characters or less")
  .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
  .required("Designation is required"),
});

export const CodeMasterSchema = Yup.object({
  codePrefix: Yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric are allowed').required("Prefix is required"),
  codeSuffix: Yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric are allowed'),
  startingnumber: Yup.number().required('Starting Number is required').typeError('Must be a number')
});

export const CreateEmployeeSchema = Yup.object({
  title: Yup.string()
  .required("Title is required"),
  firstName: Yup.string()
  .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
  .required("First Name is required"),
  lastName: Yup.string()
  .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
  .required("Last Name is required"),
  dateofjoin: Yup.string()
  .required("Date of Joining is required"),
  designation: Yup.string()
  .required("Designation is required"),
  department: Yup.string()
  .required("Department is required"),
  roleId: Yup.string()
  .required("Role is required"),
  reportingOfficersId: Yup.string()
  .required("Reporting Officer is required"),
  mobile: Yup.string()
  .matches(/^[0-9]{6,10}$/, 'Mobile Number is not valid')
  .required("Mobile Number is required"),
  empType: Yup.string()
  .required("Employee Type is required"),
  regulationDate: Yup.string()
  .required("Regulation Date is required"),
  probationStartDate: Yup.string()
  .required("Probation Start Date is required"),
  probationEndDate: Yup.string()
  .required("Probation End Date is required"),
  officeEmail: Yup.string()
  .matches(/^[a-zA-Z0-9.@]+$/, 'Only letters, numbers, periods, and "@" are allowed.')
  .email('Invalid Email Address')
  .required('Office Email is required')
});

export const ImportEmpSchema = Yup.array().of(Yup.object({
   firstName: Yup.string()
   .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
   .required("First Name is required"),
   lastName: Yup.string()
   .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
   .required("Last Name is required"),
   dateofjoin: Yup.string()
   .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date format must be YYYY-MM-DD')
   .required("Date of Joining is required"),
   empCode: Yup.string()
   .matches(/^[a-zA-Z0-9\s-]*$/, 'Only alphanumeric and hyphens are allowed')
   .required("Employee Code is required"),
   designation: Yup.string()
   .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
   .required("Designation is required"),
   department: Yup.string()
   .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
   .required("Department is required"),
   division: Yup.string()
   .matches(/^[a-zA-Z0-9\s-]*$/, 'Only alphanumeric and dash characters are allowed'),
   gender: Yup.string()
   .oneOf(['male', 'female', 'other', 'Male', 'Female', 'Other', 'MALE', 'FEMALE', 'OTHER'], 'Gender must be either male, female, or other')
   .required("Gender is required"),
   grade: Yup.string()
   .matches(/^[a-zA-Z0-9\s-]*$/, 'Only alphanumeric and dash characters are allowed'),
   mobile: Yup.string()
   .matches(/^[0-9]{6,10}$/, 'Mobile Number is not valid')
   .required("Mobile Number is required"),
   officeEmail: Yup.string()
   .matches(/^[a-zA-Z0-9.@]+$/, 'Only letters, numbers, periods, and "@" are allowed.')
   .email('Invalid Email Address')
   .required('Office Email is required')
}))

export const PersonalDetailSchema = Yup.object({
  dateOfBirth: Yup.string().required("Date of Birth is required"),
  nationality: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed"),
  gender: Yup.string().required("Gender is required"),
  personalMobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile Number is not valid').required("Mobile No. is required"),
  personalEmail: Yup.string()
  .matches(/^[a-zA-Z0-9.@]+$/, 'Only letters, numbers, periods, and "@" are allowed.')
  .email('Invalid Email Address')
  .required('Office Email is required'),
  placeOfBirth: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed"),
  // bloodGroup: Yup.string(),
  maritalStatus: Yup.string().required("Marital Status is required"),
  citizenShip: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed"),
  emergencyContactNo: Yup.string().matches(/^[0-9]{10}$/, 'Mobile Number is not valid').required("Contact No. is required"),
  contactName: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required("Contact Name is required"),
  relationMaillingAddress: Yup.string().matches(/^[a-zA-Z0-9\s,'-.]*$/, 'Only alphanumeric, spaces, commas, apostrophes, and hyphens are allowed').required("Address is required"),
  familyMemberName: Yup.string(),
  familyMemberRelation: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required("Relation is required"),
  familyMemberContact: Yup.string(),
  presentHouseNo: Yup.string().matches(/^[a-zA-Z0-9-/ ]*$/, 'Only alphanumeric, slash, spaces and hyphens are allowed').required("House No. is required"),
  presentAddress1: Yup.string().matches(/^[a-zA-Z0-9\s,'-.]*$/, 'Only alphanumeric, spaces, commas, apostrophes, and hyphens are allowed').required("Address1 is required"),
  presentAddress2: Yup.string().matches(/^[a-zA-Z0-9\s,'-.]*$/, 'Only alphanumeric, spaces, commas, apostrophes, and hyphens are allowed'),
  presentLandmark: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required("Landmark is required"),
  presentCountry: Yup.string().required("Country is required"),
  presentState: Yup.string().required("State is required"),
  presentCity: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required("City is required"),
  presentPin: Yup.string().matches(/^[0-9]{6}$/, 'Pincode is not valid').required('Pincode is required'),
  permanentHouseNo: Yup.string().matches(/^[a-zA-Z0-9-/ ]*$/, 'Only alphanumeric, slash and hyphens are allowed').required("House No. is required"),
  permanentAddress1: Yup.string().matches(/^[a-zA-Z0-9\s,'-.]*$/, 'Only alphanumeric, spaces, commas, apostrophes, and hyphens are allowed').required("Address1 is required"),
  permanentAddress2: Yup.string().matches(/^[a-zA-Z0-9\s,'-.]*$/, 'Only alphanumeric, spaces, commas, apostrophes, and hyphens are allowed'),
  permanentLandmark: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required("Landmark is required"),
  permanentCountry: Yup.string().required("Country is required"),
  permanentState: Yup.string().required("State is required"),
  permanentCity: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required("City is required"),
  permanentPin: Yup.string().matches(/^[0-9]{6}$/, 'Pincode is not valid').required('Pincode is required'),
});

export const PassportDetailSchema = Yup.object({
  guId: Yup.string(),
  visaStatus: Yup.string(),
  visaType: Yup.string(),
  visaNo: Yup.string().matches(/^[A-Za-z0-9]{6,20}$/,"Visa Number is invalid"),
  vissaIssuePlace: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed"),
  visaStartDate: Yup.string(),
  visaExpiryDate: Yup.string(),
  passportNo: Yup.string().matches(/^[A-Z0-9]{6,20}$/,'Passport Number is invalid'),
  passportExpiryDate: Yup.string(),
  labourCardNo: Yup.string().matches(/^[A-Za-z0-9]{6,15}$/,"Labour Card Number is invalid" ),
  labourCardExpiry: Yup.string()
});

export const QualificationDetailSchema = Yup.object({
  empQualification: Yup.string().required('Highest Qualification is required'),
  empUniversity: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required('University is required'),
  empYearOfPassing: Yup.string().required('Year of passing is required'),
  instituteName: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required('Institute Name is required'),
  status: Yup.string().required('Status is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required('City is required'),
  state: Yup.string().required('State is required')
});

export const PreviousJobDetailSchema = Yup.object({
  startDate: Yup.string().required("Start Date is required"),
  endDate: Yup.string().required("End Date is required"),
  salary: Yup.string().matches(/^\d+(\.\d{1,2})?$/,"Only whole number or a decimal number with up to two digits are allowed").required("Annual CTC is required"),
  jobDescription: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Only alphabetic characters are allowed'),
  position: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required('Job Title is required'),
  company: Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed").required('Company Name is required')
});

export const BankDetailSchema = Yup.object({
  bankName : Yup.string(),
  bankBranchName : Yup.string().matches(/^[a-zA-Z\s]*$/, 'Only alphabetic characters are allowed'),
  accountNumber : Yup.string().matches(/^[0-9]{9,18}$/, "Account Number is invalid"),
  ifscCode : Yup.string().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "IFSC Code is invalid"),
  personalId : Yup.string().matches(/^[0-9-]*$/,'Personal Id only contains numbers and hyphens').nullable(),
  routingAndSwiftCode : Yup.string().matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, "Swift Code is invalid").nullable(),
  ibanNumber : Yup.string().matches(/^[A-Z]{2}\d{2}[A-Z\d]{4}\d{7}([A-Z\d]?){0,16}$/, "IBAN Number is invalid").nullable(),
});

export const FamilyDetailSchema = Yup.object({
  gender : Yup.string().required('Gender is required'),
  memberName : Yup.string().matches(/^[a-zA-Z\s]*$/,'Only alphabetic characters are allowed').required('Member Name is required'),
  relation : Yup.string().matches(/^[a-zA-Z\s]*$/,'Only alphabetic characters are allowed').required('Relation is required'),
  dob : Yup.string().required('Date of Birth is required'),
  emiratesID : Yup.string().matches(/^[0-9-]*$/,'Emirates Id only contains numbers and hyphens'),
  // visaDetail : Yup.string().matches(/^[A-Za-z0-9]{6,20}$/,'Visa No. is invalid').nullable(),
  // passportDetail : Yup.string().matches(/^[A-Za-z0-9]{6,15}$/,"Passport No. is invalid").nullable(),
  visaDetail : Yup.string().matches(/^[A-Za-z0-9]*$/,'Visa No. is invalid').nullable(),
  passportDetail : Yup.string().matches(/^[A-Za-z0-9]*$/,"Passport No. is invalid").nullable()
});

export const ComplianceDetailSchema = Yup.object({
  empEPFNo : Yup.string().matches(/^\d{5}\/\d{7}\/\d{2,3}$/,"PF Number is invalid").nullable(),
  empESICNo : Yup.string().matches(/^\d{10}$/,"ESI Number is invalid").nullable(),
  empEPFUANNo : Yup.string().matches(/^\d{12}$/,"UAN Number is invalid")
});

export const DepartmentSchema = Yup.object({
  departmentName: Yup.string()
  .max(20,"Must be 20 characters or less")
  .matches(/^[a-zA-Z\s]+$/,"Only alphabetic characters are allowed")
  .required("Department is required"),
});

export const BankMasterSchema = Yup.object({
  bankName: Yup.string()
  .matches(/^[a-zA-Z\s]+$/,"Only alphabetic characters are allowed")
  .required("Bank Name is required"),
});

export const EmpTypeSchema = Yup.object({
  empTypeName: Yup.string()
  .max(20,"Must be 20 characters or less")
  .matches(/^[a-zA-Z\s]+$/,"Only alphabetic characters are allowed")
  .required("Employee Category is required"),
});

export const ExpenseTypeSchema = Yup.object({
  expenseType: Yup.string()
  .max(20,"Must be 20 characters or less")
  .matches(/^[a-zA-Z\s]+$/,"Only alphabetic characters are allowed")
  .required("Expense Type Name is required"),
});


export const HolidayMasterSchema = Yup.object({
  holidayDate: Yup.string()
  .required("Holiday Date is required"),
  holidayName: Yup.string()
  .max(30,"Must be 30 characters or less")
  .matches(/^[a-zA-Z\s]+$/,"Only alphabetic characters are allowed")
  .required("Holiday Name is required"),
  shortName: Yup.string()
  .max(10,"Must be 10 characters or less")
  .matches(/^[a-zA-Z\s]*$/,"Only alphabetic characters are allowed"),
  remark: Yup.string()
  .matches(/^[a-zA-Z\s]*$/,"Only alphabetic characters are allowed"),
});

export const HardResetPasswordValidation = Yup.object({
  password: Yup.string().min(8, 'Password must be at least 8 characters long')
  .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
  .required("New Password is required"),
  confirmPassword: Yup.string().min(8, 'Password must be at least 8 characters long')
  .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
  .required("Confirm Password is required"),
})

export const EmpProbationValidation = Yup.object({
  probationRemark: Yup.string().matches(/^[a-zA-Z\s]*$/,"Only alphabetic characters are allowed")
})