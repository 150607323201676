import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast, Select, Center, Spinner, useDisclosure
  } from "@chakra-ui/react";
import React, {useState,useRef,useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function ExpensePaymentProcess() {

    const { userData } = useAuth();
    const toast = useToast();
    const id = "toast";

    const {
      isOpen: isConfirmedPaymentsOpen,
      onOpen: onConfirmedPaymentsOpen,
      onClose: onConfirmedPaymentsClose,
    } = useDisclosure();


  const [InitializationMonth, setInitializationMonth] = useState(new Date());
  const [PaymentPurpose, setPaymentPurpose] = useState();
  const [SelectedEmps, setSelectedEmps] = useState([]);

  const [IsLoading, setIsLoading] = useState(false);

  const [ConfirmedPayRowData, setConfirmedPayRowData] = useState([]);

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
          headerCheckboxSelection: true,
          checkboxSelection: (params) => {
            return params.data.empCode;
          }, 
        },
        {
          headerName: "Emp Name",
          field: "empName",
        },
        {
          headerName: "Ticket Id",
          field: "expenseTicketNo",
        },
        {
          headerName: "Advance Balance Amount",
          field: "advanceBalanceAmount",
        },
        {
          headerName: "Final Amount",
          field: "finalAmountPayment",
        },
        {
          headerName: "Payment Status",
          field: "paymentStatus",
          cellRenderer: (params) => (
            params.data.paymentStatus === 'Pending'?
            <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
           : params.data.paymentStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Approved
          </span> : params.data.paymentStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Rejected
          </span>: ''
          ),
        },

        {
          headerName: "Month",
          field: "month",
          cellRenderer:(params)=><span>
            {params.data.month}/{params.data.year}
          </span>
        },

      ]);



      const [ConfirmedPayColumnDefs, setConfirmedPayColumnDefs] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
        },
          {
            headerName: "Emp Name",
            field: "fullName",
          },
            {
              headerName: "Paid Amount",
              field: "finalAmountPay",
            },
  
            {
              headerName: "Payment Mode",
              field: "paymentMethod",
              editable:true, singleClickEdit: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                 values: ['Online', 'Check', 'Cash'],
              } ,cellClass:'editable-grid-cell'
            },
            {
              headerName: "Remarks",
              field: "remark",
              editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'
            },
            {
              headerName: "Action",floatingFilter : false,
              cellRenderer:(params) => <Button onClick={async()=>{

                let RowData = params.data;

                  let NewBody = {
                       empGuId : RowData.empGuId,
                       finalAmountPay : RowData.finalAmountPay,
                       transactionMethod : RowData.paymentMethod,
                       transactionDate : RowData.paymentDate,
                       transactionStatus : "Pending",
                       paymentMethodUpdationDate : moment().format('YYYY-MM-DD'),
                       paymentFinalDetailsId : RowData.paymentFinalDetailsId,
                       companyId : userData.companyId,
                       branchId : userData.isActiveBranch,
                       lock : false,
                       expenseTicketNo : RowData.expenseTicketNo,
                       remark : RowData.remark
                    }


                  console.log("New Payment Body",NewBody);



                  await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePaymentMethodDetails`,NewBody
                  )
                  .then((response) => {
                    console.log(response);
                    const res = response.data;
                    //console.log("res",res);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title:res,
                        position: 'top',
                        status: 'info',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
        
                  })
                  .catch((error) => {
                    console.log(error.response);
                    // console.log(error.response.data);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: 'ERROR',
                        description: "Payment not Updated.",
                        position: 'top',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
                  });


              }} colorScheme={'blue'} size={'xs'} >Save</Button>
            },
        ]);
  
       // Table Pagination
       const paginationNumberFormatter = useCallback((params) => {
         return "[" + params.value.toLocaleString() + "]";
       }, []);
      
       //Table Columns Properties Applies to All Columns
       const defaultColDef = useMemo(() => ({
         flex: 1,
         sortable: true,
         filter: "agTextColumnFilter",
         floatingFilter: true,
         cacheQuickFilter: true,
         cellClass: 'no-border',
       }));

       const onSelectionChanged = useCallback(() => {
        let SelectedEmployees = gridRef.current.api.getSelectedRows();
       console.log("Selected Employees",SelectedEmployees);  
       setSelectedEmps(SelectedEmployees)
      }, []);



      const HandleInitializePayment = async () => {

        setIsLoading(true)
      
        let body = {
          empGuId : userData.guId,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch,
          paymentStatus : "Pending",
          processByGuId : userData.guId,
          month : new Date(InitializationMonth).getMonth()+1,
          year : new Date(InitializationMonth).getFullYear()
        }


        console.log("Initialize Body",body);

        await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/PaymentInitializePettyCash`,body)
        .then((response) => {

          console.log("Initialize Response",response);

          const res = response.data;



          setRowData(res.map((data) => {
            return {
              ...data,
              month : new Date(InitializationMonth).getMonth()+1,
              year : new Date(InitializationMonth).getFullYear()
            }
          }));
          setIsLoading(false)
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Expense Payment not Initialized",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

      }
 

      console.log("Payment Purpose",PaymentPurpose);



      const ConfirmPayment = async (e) => {
        e.preventDefault();

        let body = SelectedEmps.map((data)=>{
          return   {
            finalAmountPay : data.finalAmountPayment,
            method : PaymentPurpose,
            expenseTicketNo : data.expenseTicketNo,
            adjustamount : PaymentPurpose === 'Advance' ? true : false,
            empGuId : data.empGuId,
            pMonth : new Date(InitializationMonth).getMonth()+1,
            pYear : new Date(InitializationMonth).getFullYear(),
            paymentDate : moment().format('YYYY-MM-DD'),
            masterTransactionId : data.masterTransactionId,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
            lock : true
          }
        })

        console.log("Confirmation Body",body);



        await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashPaymentFinalDetails`,body)
        .then((response) => {

          console.log("Confirmation Response",response);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: response.data,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }

          HandleInitializePayment();
          GetConfirmedPaymentList(new Date(InitializationMonth).getMonth()+1,new Date(InitializationMonth).getFullYear())
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Payment not Confirmed",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });



      }




      const GetConfirmedPaymentList = async (MONTH,YEAR) => {
        try {
            const response = await authApi.get(
                `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashPaymentFinalDetailsData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
            )

            console.log("Confirmed Payment List",response);

            const res = response.data

            setConfirmedPayRowData(res.filter(obj1 => SelectedEmps.find(obj2 => obj1.empGuId === obj2.empGuId && obj1.masterTransactionId === obj2.masterTransactionId)));
            console.log("Filetered Emp",res.filter(obj1 => SelectedEmps.find(obj2 => obj1.empGuId === obj2.empGuId && obj1.masterTransactionId === obj2.masterTransactionId)))

            onConfirmedPaymentsOpen();

        } catch (error) {
            console.error(error);
        }
    }
      
  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Expense Payment Process</h1>
        <div className="flex items-center gap-4">
            <div>
            <DatePicker
            className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
            selected={InitializationMonth}
            onChange={(date) => {
              setInitializationMonth(date);
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            placeholderText="Select Month"
          />
            </div>
            <Button  isDisabled={rowData.length !== 0 ? true : false} onClick={HandleInitializePayment} size={'sm'} colorScheme={'purple'} >Initialize Payment</Button>
          </div>

      </div>


      <form onSubmit={ConfirmPayment}>
        <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
           <FontAwesomeIcon className="rotate-180 text-lg" icon={faArrowTurnUp} />
          </div>
          <div>
          <Select  isRequired value={PaymentPurpose} onChange={({target})=>{setPaymentPurpose(target.value)}} bg borderColor={'gray.400'} placeholder='Select Payment Purpose'>
            <option value={'Advance'}>Advance Adjustment</option>
            <option value={'Payment'}>Expense Payment</option>
          </Select>  
          </div>
          <Button isDisabled={rowData.length === 0 ? true : false} type="submit"  colorScheme='purple'>Confirm Payment</Button>
        </div>
      </form>
      
      {!IsLoading ? <>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          rowSelection={'multiple'}
          paginationNumberFormatter={paginationNumberFormatter}
          onSelectionChanged={onSelectionChanged}
        />
      </div>

      </> :  <Center>

          <Spinner   thickness='4px' color={'purple'}  size='lg'  />
          </Center> }



          <Modal size='full' isOpen={isConfirmedPaymentsOpen} onClose={onConfirmedPaymentsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmed Payments</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>

          <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={ConfirmedPayRowData} // Row Data for Rows
          columnDefs={ConfirmedPayColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>


          </ModalBody>
          <hr />
          <ModalFooter>
            <Button variant='outline' onClick={onConfirmedPaymentsClose} colorScheme='purple' >
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default ExpensePaymentProcess