import { Avatar, Skeleton } from '@chakra-ui/react'
import React from 'react'

const StatCard = ({IsLoading,StatName,StatNumber,Description,IconBg,StatIcon}) => {
  return (
    <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
    <div className="flex flex-col justify-between">
      <p className="text-sm font-medium text-gray-500">{StatName}</p>
      <h6 className="text-3xl font-bold text-gray-800">
        {IsLoading ? <Skeleton height={8} width={8} /> : (StatNumber || 0)}
      </h6>
      <p className="text-sm text-gray-500">{Description}</p>
    </div>
    <div className="align-middle my-auto">
      <div className="bg-orange-100 rounded-full">
        <Avatar
          bg={IconBg}
          size="md"
          icon={StatIcon}
        />
      </div>
    </div>
  </div>
  )
}

export default StatCard