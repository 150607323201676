
import { View,Text, Document, Page,StyleSheet  ,Image,Line ,Font,PDFDownloadLink,PDFViewer} from '@react-pdf/renderer';
import numeral from 'numeral';
import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
// import NumberFormatter from '../Utils/NumberFormatter';
import numberToWords from 'number-to-words';
import authApi from '../Utils/AuthApis';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        paddingTop: 35,
        paddingLeft:25,
        paddingRight:25,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
  
    imgLogo:{
        height:70,
        width:110,
    }
  });

function PaySlipDesign1() {

  const userData = JSON.parse(localStorage.getItem("user"));


  const [EmpSalarySlipData, setEmpSalarySlipData] = useState([]);
  const [EmpSalarySlipComponents, setEmpSalarySlipComponents] = useState([]);
  const monthNames = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC",];
  let CurrentYear = new Date().getFullYear();
  const [empdata, setEmpData] = useState([]);

  const [CompanyDetails, setCompanyDetails] = useState([]);
  const [CompanyLogo, setCompanyLogo] = useState();




  useEffect(() => {
GetSalarySlipData();
  }, [])
  

  const GetSalarySlipData = async () => {

    try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeDetailByEmpGuid?EmpGuid=f582f529-ced3-4e62-aa02-be0e2ed8ed3f&CompanyId=3&BranchId=1006`
        );
        // console.log("Response ",response);
        const Emp = response.data;
         console.log("EMP Data",Emp);
        setEmpData(Emp);
        // console.log("empdata " , empdata);
      } catch (error) {
        console.error(error);
      }

      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Company/GetCompanyById?CompanyId=3`
        );
        console.log("Company Details", response.data);
        const res = response.data;
        setCompanyDetails(res);

        handleDownloadFile(res.logoPath)
      } catch (error) {
        console.error(error);
      }

        try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GeneratePayslipByGuid?EmpGuid=f582f529-ced3-4e62-aa02-be0e2ed8ed3f&PayrollMonth=1&PayrollYear=2023&CompanyId=3&BranchId=1006`
      );
       console.log("Response ",response);
      const EmpSalaryData = response.data;
       console.log("EMPloyee Salary Slip Data",EmpSalaryData);
       setEmpSalarySlipData(EmpSalaryData);
       setEmpSalarySlipComponents(response.data.salarySlipComponents)
      // console.log("empdata " , empdata);
    } catch (error) {
      console.error(error);
    }
  }

  console.log("Company Logo",CompanyLogo);

  const handleDownloadFile = async (DocPath) =>{
    console.log("Document path",DocPath)
   await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
  ).then(response => {

    console.log("file Response",response);

    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values

        let alink = document.createElement('a');
        alink.href = fileURL;
        //  alink.download = fileURL;
         setCompanyLogo(alink.href)

         // alink.click();
    })

  }).catch((error)=>{
    console.log("File Api Error",error);
  })
  }

      const SalarySlip1 =  () => {
    return  <Document>
     <Page size="A4"  style={styles.page}>
           
           {/* Company Details Header */}
      <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
         <Image style={styles.imgLogo} src='/images/acquaraLogo.png' />
         <View>
             <Text style={{marginBottom:2,fontSize:13,fontWeight:'extrabold',fontFamily:'Helvetica-Bold'}}>{userData.companyName}</Text>
             <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
             <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.stateName} {userData.pincode}</Text>
         </View>
       </View>

       {/* Employee Details */}

       <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:10,borderBottom:1, borderBottomColor:'#333333'}}>
         <View style={{flexGrow:1}}>
         <Text style={{marginBottom:'5px',fontSize:8,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
         <Text style={{fontSize:8}}>Employee Code : {EmpSalarySlipData.empCode}</Text>
         </View>
 
         <View style={{flexGrow:1}}>
         <Text style={{marginBottom:'5px',fontSize:8,}}>Designation : {empdata.designationName}</Text>
         <Text style={{fontSize:8}}>Department : {empdata.departmentName}</Text>
         </View>
 
         <View style={{flexGrow:1}}>
         <Text style={{marginBottom:'5px',fontSize: 8,}}>City : {empdata.branchName}</Text>
         <Text style={{fontSize:8}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} {CurrentYear}</Text>
         </View>
       </View>


       {/* Salary Details Table */}
 
       <View style={{flexDirection:'row',justifyContent:'space-between',paddingTop:10,borderBottom:1}}>
         <View style={{flexGrow:1,borderRight:1}}>
          <Text style={{marginBottom:'5px',fontSize: 10,borderBottom:1,paddingRight:10,paddingLeft:5}}>Earnings ({userData.currencyCode})</Text>
 
         <View style={{paddingRight:10,paddingLeft:5}}>
          {EmpSalarySlipComponents.filter((data) => data.componentType === 1).map((data,i)=>{
            return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:8}}>{data.displayLabel}</Text>
              <Text style={{fontSize:8}}>{data.salaryValue}</Text>
              </View>
          })}
 
         </View>
 
 
         </View>
 
         <View style={{flexGrow:1}}>
          <Text style={{marginBottom:'5px',fontSize: 10,borderBottom:1,paddingLeft:10}}>Deductions ({userData.currencyCode})</Text>
 
         <View style={{paddingRight:10,paddingLeft:10}}>

         {EmpSalarySlipComponents.filter((data) => data.componentType === 2).map((data,i)=>{
              return  <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:8}}>{data.displayLabel}</Text>
              <Text style={{fontSize:8}}>{data.salaryValue}</Text>
              </View>
          })}

         </View>
  
         </View>

       </View>

       <View style={{flexDirection:'row',justifyContent:'space-between',paddingTop:10,borderBottom:1}}>

         <View style={{flexGrow:1,borderRight:1}}>
          <Text style={{marginBottom:'5px',fontSize: 10,borderBottom:1,paddingRight:10,paddingLeft:5}}>Additions ({userData.currencyCode})</Text>
 
         <View style={{paddingRight:10,paddingLeft:5}}>
          {EmpSalarySlipComponents.filter((data) => data.componentType === 4).map((data,i)=>{
              return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:8}}>{data.displayLabel}</Text>
              <Text style={{fontSize:8}}>{data.salaryValue}</Text>
              </View>
          })}
 
         </View>
 
 
         </View>

         <View style={{flexGrow:1}}>
 
         </View>

         

       </View>
 
 
        {/* Total Calculation Rows  */}
       <View style={{flexDirection:'row',justifyContent:'space-between',paddingTop:5,paddingBottom:4,borderBottom:1}}>
     
         <View style={{flexGrow:1,flexDirection:'row',justifyContent:'space-between',paddingRight:10,paddingLeft:10}}>
          <Text style={{fontSize:8}}>Working Days</Text>
          <Text style={{fontSize:8}}>{EmpSalarySlipData.workingdays}</Text>
         </View>
 
         <View style={{flexGrow:1,flexDirection:'row',justifyContent:'space-between',paddingRight:10,paddingLeft:15}}>
          <Text style={{fontSize:8}}>Total Deductions</Text>
          <Text style={{fontSize:8}}>{EmpSalarySlipData.totalDeduction}</Text>
         </View>
 
       </View>
 
       <View>
 
       <View style={{flexDirection:'row',paddingTop:5,paddingBottom:4,alignContent:'flex-end',justifyContent:'flex-end'}}>
     
     <View style={{width: "50%",flexDirection:'row',justifyContent:'space-between',paddingRight:10,paddingLeft:10,borderBottom:1}}>
      <Text style={{fontSize:8}}>Gross Total ({userData.currencyCode})</Text>
      <Text style={{fontSize:8}}>{EmpSalarySlipData.grossSalary}</Text>
     </View>
 
   </View>
 
   <View style={{flexDirection:'row',paddingTop:5,paddingBottom:4,alignContent:'flex-end',justifyContent:'flex-end'}}>
     
     <View style={{width: "50%",flexDirection:'row',justifyContent:'space-between',paddingRight:10,paddingLeft:10,borderBottom:1}}>
      <Text style={{fontSize:8}}>Net Pay ({userData.currencyCode})</Text>
      <Text style={{fontSize:8}}>{EmpSalarySlipData.netSalary}</Text>
     </View>
 
   </View>
 
   <View style={{flexDirection:'row',paddingTop:5,paddingBottom:4,alignContent:'flex-end',justifyContent:'flex-end'}}>
     
     <View style={{width: "50%",flexDirection:'row',justifyContent:'space-between',paddingRight:10,paddingLeft:10,borderBottom:1}}>
      <Text style={{fontSize:8}}>CTC ({userData.currencyCode})</Text>
      <Text style={{fontSize:8}}>{EmpSalarySlipData.ctc}</Text>
     </View>
 
   </View>
 
 
       </View>
 
 
     </Page>
   </Document>
 }


   const NumberFormat =  (value) =>{
     return numeral(value).format('0,0.00')
   }

   console.log("Formated Number",NumberFormat(270000))

 const SalarySlip2 =  () => {
    return  <Document>
     <Page size="A4"  style={styles.page}>
           
           {/* Company Details Header */}
      <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
      <Image style={{width:90}} src={CompanyLogo} />
         <View>
             <Text style={{marginBottom:2,fontSize:13,fontWeight:'extrabold',fontFamily:'Helvetica-Bold'}}>{userData.companyName}</Text>
             <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
             <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.stateName} {userData.pincode}</Text>
         </View>
       </View>

       {/* Employee Details */}

       <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:10,borderBottom:1, borderBottomColor:'#333333'}}>
         <View style={{flexGrow:1}}>
         <Text style={{marginBottom:'5px',fontSize:8,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
         <Text style={{fontSize:8}}>Employee Code : {EmpSalarySlipData.empCode}</Text>
         </View>
 
         <View style={{flexGrow:1}}>
         <Text style={{marginBottom:'5px',fontSize:8,}}>Designation : {empdata.designationName}</Text>
         <Text style={{fontSize:8}}>Department : {EmpSalarySlipData.departmentName}</Text>
         </View>

         <View style={{flexGrow:1}}>
         <Text style={{marginBottom:'5px',fontSize: 8,}}>Division : {EmpSalarySlipData.division}</Text>
         <Text style={{fontSize:8}}>Grade : {EmpSalarySlipData.grade}</Text>
         </View>
 
         <View style={{flexGrow:1}}>
         <Text style={{marginBottom:'5px',fontSize: 8,}}>Currency : {userData.currencyCode}</Text>
         <Text style={{fontSize:8}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} {CurrentYear}</Text>
         </View>
       </View>


       {/* Salary Details Table */}


       <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>

       <View style={{width:'49.5%',borderRight:1,flexDirection:'column',justifyContent:'space-between'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>

        </View>
 
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
          {EmpSalarySlipComponents.filter((data) => data.componentType === 1).map((data,i)=>{
            return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:8,width:'30%'}}>{data.displayLabel}</Text>
              <Text style={{fontSize:8}}>{NumberFormat(data.actualSalaryValue)}</Text>

              <Text style={{fontSize:8}}>{NumberFormat(data.salaryValue)}</Text>
              </View>
          })}
 
         </View>

         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px'}}>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>

        </View>
 
 
         </View>

         <View style={{width:'50%',flexDirection:'column',justifyContent:'space-between'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Deductions</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

        </View>
 
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
          {EmpSalarySlipComponents.filter((data) => data.componentType === 2).map((data,i)=>{
            return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:8}}>{NumberFormat(data.displayLabel)}</Text>
              <Text style={{fontSize:8}}>{NumberFormat(data.salaryValue)}</Text>
              </View>
          })}
 
         </View>

         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px'}}>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Deductions</Text>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalDeduction)}</Text>

        </View>
 
 
         </View>

       </View>


       <View style={{flexDirection:'row',marginTop:'10px'}}>


       <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'1%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>
 <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
 <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Additions</Text>
 <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

 </View>

  <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
   {EmpSalarySlipComponents.filter((data) => data.componentType === 4).map((data,i)=>{
     return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:8}}>{data.displayLabel}</Text>
       <Text style={{fontSize:8}}>{NumberFormat(data.salaryValue)}</Text>
       </View>
   })}

  </View>

  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px'}}>
 <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Additions</Text>
 <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalAddition)}</Text>

 </View>


  </View>


</View>

<View style={{width:'50%'}}>
<View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10}}>Adhoc Payment</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
<Text style={{fontSize:10}}>Adhoc Deduction</Text>
<Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>
</View>



</View>
</View>




       </View>


 
 
 
        {/* Total Calculation Rows  */}


        <View style={{flexDirection:'row',marginTop:'10px'}}>


<View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'1%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>
<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
</View>

<View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Payment Type</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Account Number</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Bank Name</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
</View>



</View>




</View>


</View>

<View style={{width:'50%'}}>
<View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>

{/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
 <Text style={{fontSize: 10}}>Working Days</Text>
 <Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
 </View> */}

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>

</View>

 <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
 <Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
 <Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
 </View>

 <View style={{flexDirection:'row',alignItems:'center', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
 <Text style={{fontSize:10,fontFamily:'Helvetica-Bold',marginRight:'4px'}}>In Words:</Text>
 <Text style={{fontSize:8 }}>{numberToWords.toWords(EmpSalarySlipData.netPayableSalary || 0).toString().toUpperCase()}</Text>
 </View>

</View>
</View>




</View>

 

 
     </Page>
   </Document>
 }


console.log("Company Logo",CompanyLogo);


 const PrivateOfficeSalarySlip =  () => {
  return  <Document>
   <Page size="A4"  style={styles.page}>
         
         {/* Company Details Header */}
    <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
      <View style={{width:'50%'}}>
        {CompanyLogo !== undefined ? 
       <Image style={{width:110}} src={CompanyLogo} /> 
       : <Text>Logo</Text>}
      </View>
      <View style={{width:'40%'}}>
           <Text style={{marginBottom:2,fontSize:13,fontWeight:'extrabold',fontFamily:'Helvetica-Bold'}}>{userData.activeBranchName}</Text>
           <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
           <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.stateName}</Text>
       </View>
     </View>

     {/* Employee Details */}

     <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
       <View style={{width:'60%',flexWrap:'wrap'}}>
       <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
       <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Code : {EmpSalarySlipData.empCode}</Text>
       <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
       <Text style={{fontSize:10}}>Leave Balance : {EmpSalarySlipData.totalLeaveBalance}</Text>


       </View>

       <View style={{width:'40%',flexWrap:'wrap'}}>
       <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {empdata.designationName}</Text>
       <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
       <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {CurrentYear}</Text>
       <Text style={{fontSize:10}}>Present Days : {EmpSalarySlipData.totalPresent}</Text>

       </View>
     </View>


     {/* Salary Component Details Table */}


     <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>

     <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'space-between'}}>
      <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>

      </View>

       <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
        {EmpSalarySlipComponents.filter((data) => data.componentType === 1).map((data,i)=>{
          return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
            <Text style={{fontSize:9,width:'30%'}}>{data.displayLabel}</Text>
            <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>

            <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
            </View>
        })}

       </View>

       <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px'}}>
      <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
      <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>

      </View>


       </View>

       <View style={{width:'50%',flexDirection:'column',justifyContent:'space-between'}}>
      <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Additions</Text>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

      </View>

       <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
       {EmpSalarySlipComponents.filter((data) => data.componentType === 4).map((data,i)=>{
   return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
     <Text style={{fontSize:9}}>{data.displayLabel}</Text>
     <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
     </View>
 })}

       </View>

       <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px'}}>
 <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Additions</Text>
 <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalAddition)}</Text>
</View>



       </View>

     </View>


     <View style={{flexDirection:'row',marginTop:'10px'}}>


<View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Adhoc Payment</Text>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

      </View>
      <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
      <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
      <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>

      </View>




</View>


</View>

<View style={{width:'49%',border:1,borderRadius:'5px'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Adhoc Deduction</Text>
      <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

      </View>
      <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
      <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
      <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>

      </View>




</View>


</View>




</View>

     


      {/* Total Calculation Rows  */}


      <View style={{flexDirection:'row',marginTop:'10px'}}>


<View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>
<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
</View>

<View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Payment Type</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Account Number</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Bank Name</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
</View>



</View>




</View>


</View>

<View style={{width:'49%'}}>
<View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>

{/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10}}>Working Days</Text>
<Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
</View> */}

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
<Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
</View>

<View style={{flexDirection:'row',alignItems:'center', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
<Text style={{fontSize:10,fontFamily:'Helvetica-Bold',marginRight:'4px'}}>In Words:</Text>
<Text style={{fontSize:8 }}>{numberToWords.toWords(EmpSalarySlipData.netPayableSalary || 0).toString().toUpperCase()}</Text>
</View>

</View>
</View>




</View>




   </Page>
 </Document>
}


  return (
    <div>
      <PDFViewer width="100%" className="h-screen my-10">
        <PrivateOfficeSalarySlip />
      </PDFViewer>
    </div>
  )
}

export default PaySlipDesign1