import React, { useState, useMemo, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Avatar, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { faCheck, faClock, faCalendar, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { useEffect } from "react";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

export default function LeaveDashboard() {
  const { userData } = useAuth();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable

  const Today = new Date();

  const {
    isOpen: ismodalopen,
    onOpen: onmodalopen,
    onClose: onmodalclose,
  } = useDisclosure();

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function subDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  const [LeaveHistoryDateRange, setLeaveHistoryDateRange] = useState([
    subDays(Today, 15),
    addDays(Today, 15),
  ]);
  const [LeaveHistoryStartDate, LeaveHistoryEndDate] = LeaveHistoryDateRange;

  const [LeaveDashboardStat, setLeaveDashboardStat] = useState([]);

  const [EmpReason, setEmpReason] = useState();

  const HandleLeaveHistoryChange = (dates) => {
    const [start, end] = dates;
    console.log("Dates", dates);
    setLeaveHistoryDateRange(dates);

    getLeaveAppliedList(start, end);
    getLeaveDashboardStats(start, end);
  };

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Emp Name",
      field: "empName",
    },

    {
      headerName: "Days",
      field: "leaveNoOfDays",
    },

    {
      headerName: "Date",
      field: "leaveNoOfDays",
      cellRenderer: (params) => (
        <div>
          {new Date(params.data.leaveFromDate).toLocaleDateString("en-GB")} -{" "}
          {new Date(params.data.leaveToDate).toLocaleDateString("en-GB")}
        </div>
      ),
    },

    {
      headerName: "Leave Type",
      field: "leaveType",
    },
    {
      headerName: "Leave Reason",
      field: "leaveReason",
      cellRenderer: (params) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            setEmpReason(params.data.leaveReason);
            onmodalopen();
          }}
        >
          {params.data.leaveReason}
        </div>
      ),
    },
    {
      headerName: "Reporting Person",
      field: "reportingPersonName",
    },

    {
      headerName: "Leave Status",
      field: "leaveStatus",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending" ? (
          <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
        ) : params.data.leaveStatus === "Approve" ||
          params.data.leaveStatus === "Approved" ? (
          <span className="bg-green-600 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Approved
          </span>
        ) : params.data.leaveStatus === "Reject" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Rejected
          </span>
        ) : params.data.leaveStatus === "Cancel" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Cancelled
          </span>
        ) : (
          ""
        ),
    },
  ]);

  useEffect(() => {
    getLeaveAppliedList(LeaveHistoryStartDate, LeaveHistoryEndDate);
    getLeaveDashboardStats(LeaveHistoryStartDate, LeaveHistoryEndDate);
  }, []);

  const getLeaveDashboardStats = async (FromDate, ToDate) => {
    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Leave/GetLeaveDataForDashBoard?fromdate=${new Date(
          FromDate
        ).toLocaleDateString("en-CA")}&Todate=${new Date(
          ToDate
        ).toLocaleDateString("en-CA")}&CompanyId=${
          userData.companyId
        }&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Dashboard Stat", response.data);
      setLeaveDashboardStat(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLeaveAppliedList = async (FromDate, ToDate) => {
    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Leave/GetLeaveApplyList?startdate=${new Date(
          FromDate
        ).toLocaleDateString("en-CA")}&Enddate=${new Date(
          ToDate
        ).toLocaleDateString("en-CA")}&CompanyId=${
          userData.companyId
        }&BranchId=${userData.isActiveBranch}`
      );

      console.log("Leave Applied List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Dashboard</h1>

        <div className="flex items-center">
          <label className="font-semibold text-lg mr-3 ">
            Leave Data Range :
          </label>

          <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selectsRange={true}
              startDate={LeaveHistoryStartDate}
              endDate={LeaveHistoryEndDate}
              onChange={(update) => {
                //setLeaveHistoryDateRange(update);
                HandleLeaveHistoryChange(update);
                // getLeaveHistory(LeaveHistoryStartDate,LeaveHistoryEndDate);
              }}
              dateFormat="dd/MM/yyyy"
              // isClearable={true}
              placeholderText="Select Leave History Range"
            />
          </div>
        </div>
      </div>
      <div className="h-full  mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">Total Leave</p>
              <h6 className="text-3xl font-bold">
                {LeaveDashboardStat.totalLeave || 0}
              </h6>
              <p className="text-sm text-gray-50">-</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-2xl"
                      icon={faCalendar}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">Approved</p>
              <h6 className="text-3xl font-bold">
                {LeaveDashboardStat.leaveApproved || 0}
              </h6>
              <p className="text-sm text-gray-50">-</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-2xl"
                      icon={faCheck}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">Pending</p>
              <h6 className="text-3xl font-bold">
                {LeaveDashboardStat.leavePending || 0}
              </h6>
              <p className="text-sm text-gray-50">-</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="yellow.200"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-yellow-600 text-2xl"
                      icon={faClock}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">Rejected</p>
              <h6 className="text-3xl font-bold">
                {LeaveDashboardStat.leaveReject || 0}
              </h6>
              <p className="text-sm text-gray-50">-</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="red.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-red-600 text-2xl"
                      icon={faXmark}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal isOpen={ismodalopen} onClose={onmodalclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reason</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div>{EmpReason}</div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onmodalclose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
