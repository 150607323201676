import React, {useState,useRef,useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function EmployeeOvertimeReport() {

    const { userData } = useAuth();

    const Today = new Date();

    const StartDateCurrent = moment(Today).startOf("month").format('YYYY-MM-DD');
    const EndDateCurrent = moment(Today).endOf("month").format('YYYY-MM-DD');
    console.log(StartDateCurrent,EndDateCurrent)


    const [OvertimeDateRange, setOvertimeDateRange] = useState([new Date(StartDateCurrent),new Date(EndDateCurrent)]);

    const [OvertimeStartDate, OvertimeEndDate] = OvertimeDateRange;



    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const gridRef = useRef();
    const [rowData, setRowData] = useState([ ]); // Table Data Variable


    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: "no-border",
      }));
      
      const [columnDefs, setColumnDefs] = useState([
        // {
        //   headerName: "Employee Name",
        //   field: "empName",
        // },
          {
              headerName: "Overtime Date",
              field: "attendanceDate",
              valueGetter:(params)=> new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
            },
            {
              headerName: "In-Time",
              field: "inTime",
            },
            {
              headerName: "Out-Time",
              field: "outTime",
            },
            {
              headerName: "Total Time",
              field: "totalTime",
            },
            {
              headerName: "Actual Overtime",
              field: "actualOverTime",
            }
      ]);



    const HandleAttendanceHistoryChange = (dates) => {
        const [start, end] = dates;
        console.log("Dates",dates)
        setOvertimeDateRange(dates);
        getEmpAttendance(start,end);
      };


      const getEmpAttendance = async (FromDate,ToDate) =>{
        try {
          const response= await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetAttendanceTotalOverTimeListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FromDate=${new Date(FromDate).toLocaleDateString('en-CA')}&ToDate=${new Date(ToDate).toLocaleDateString('en-CA')}&AttStatus=P&EmpGuid=${userData.guId}`
            )
            console.log("Get Emp Attendance Response",response);
            setRowData(response.data)
          
         } catch (error) {
            console.error(error);
         }
      }

      

  return (
    <div>
              <div className="border-b items-center flex justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Emp Overtime Report </h1>
        <div className='flex items-center justify-end'>
        <label className="font-semibold text-lg mr-3 ">Overtime Data Range :</label>
        <div>
        <DatePicker
          className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
          selectsRange={true}
          startDate={OvertimeStartDate}
          endDate={OvertimeEndDate}
          onChange={(update) => {
            HandleAttendanceHistoryChange(update);
          }}
          dateFormat="dd/MM/yyyy"
          // isClearable={true}
          placeholderText="Select Attendance Range"
        />
        </div>
        </div>
        </div>



        <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={12}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

    </div>
  )
}

export default EmployeeOvertimeReport