import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure } from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function EmpPolicies() {

    const {userData} = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [PdfPath, setPdfPath] = useState();
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Policy Name",
        field: "policyName",
      },
      {
        headerName: "Policy Attachment",
        cellRenderer: (params) => (
          <div className="space-x-4">
            <Button
             leftIcon={<FontAwesomeIcon icon={faEye} />}
             onClick={()=>{handleDownloadFile(params.data.attachPolicyPath)}}
             colorScheme="purple"
             variant='link'
           >
             View
           </Button>      
             </div>
        ),
      },
    ]);
  
       // Table Pagination
       const paginationNumberFormatter = useCallback((params) => {
         return "[" + params.value.toLocaleString() + "]";
       }, []);
      
       //Table columns properties applies to all columns
       const defaultColDef = useMemo(() => ({
         flex: 1,
         sortable: true,
         filter: "agTextColumnFilter",
         floatingFilter: true,
         cacheQuickFilter: true,
         cellClass: 'no-border'
       }));


       
       let PdfDiv = document.getElementById('pdf-div');

      //  useEffect(() => {
      //   PdfDiv.addEventListener('contextmenu', function (e) {
      //       e.preventDefault();
      //     }, false);
      // }, [PdfDiv])

      useEffect(() => {
        getPolicyList();
      }, [])
    
      const getPolicyList = async()=>{
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/CompanyPolicy/GetCompanyPolicyMasterList?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
          )
          console.log("Policy List",response.data);
          setRowData(response.data);
        } catch (error) {
          console.error(error)
        }
      }
    


      const handleDownloadFile = async (DocPath) =>{
        console.log(DocPath)
       await fetch(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
      ).then(response => {
    
        console.log("file Response",response);
    
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
    
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            // alink.download = fileURL;
            setPdfPath(alink)
            onOpen();
            // alink.click();
        })
    
      }).catch((error)=>{
        console.log("File Api Error",error);
      })
      }

      
  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Policies</h1>
      </div>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>


      <Modal size='full' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Policies</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div id="pdf-div"  >
            <embed

              src={PdfPath + "#toolbar=0"}
              type="application/pdf"
              className="h-screen w-full !select-none"
              height={800}
              width={500}
              oncontextmenu="return false;"
            />
            </div>
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme='purple' onClick={onClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default EmpPolicies