import { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import authApi from "./AuthApis";

const usePermissionCheck = (MainMenu, SubMenu) => {
  const {userData} = useAuth();
  const [hasPermission, setHasPermission] = useState(false);
  const [IsPermissionLoading, setIsPermissionLoading] = useState(false);

  useEffect(() => {
    const MenuList = async () => {
      setIsPermissionLoading(true);
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData.roleId || 0}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
        );

        //  console.log("User Permission",response);
        const userPermissions = response.data;

        const hasRequiredPermission = userPermissions?.some((data) => {
          if (data.mainMenuName === MainMenu && data.subMenuName === SubMenu) {
            const UserAccess = userData?.isRoleBased ? (data.isCreate || data.isModify || data.isView ) : data.isDefault;
            return UserAccess;
          }
          return false;
         }); 

        setHasPermission(hasRequiredPermission);
      } catch (err) {
        console.error("Error Fetching User Permission", err);
      } finally {
        setIsPermissionLoading(false);
      }
    };

    MenuList();
  }, [MainMenu, SubMenu]);

  return hasPermission;
};

export default usePermissionCheck;
