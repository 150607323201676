import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Button,
  Select,
  useDisclosure,
  useToast,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Modal,
  ModalOverlay,
  RadioGroup,
  Radio,
  Textarea,
  Checkbox,
  Stack,
  Center,
  Spinner,
  Switch,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";

import { CheckCircle, Eye, XCircle } from "react-feather";
import { faPaperclip, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import usePost from '../Utils/usePost';
import * as Yup from "yup";
import authApi from "../Utils/AuthApis";

function EmployeeWFH() {
  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";

  const validationSchema = Yup.object({
    reason: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Only Alphabets are allowed')
      .required('Work from home reason is required'),
  });

  const { IsPostLoading: IsSaveWfhLoading, PostData: PostWfhData } = usePost('/api/Attendance/SaveWFH')

  const [FormErrors, setFormErrors] = useState(null);
  const [IsView, setIsView] = useState(false);

  const [HybridData, setHybridData] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isViewAttachmentOpen, onOpen: onViewAttachmentOpen, onClose: onViewAttachmentClose } = useDisclosure();
  const [WfhAttachment, setWfhAttachment] = useState(null);
  const [WfhAttachmentPath, setWfhAttachmentPath] = useState();

  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };  

  const [NoOfDaysLeave, setNoOfDaysLeave] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [WfhReason, setWfhReason] = useState();
  const [ReviewBy, setReviewBy] = useState();
  const [ApprovedBy, setApprovedBy] = useState();

  const [ApiSavedData, setApiSavedData] = useState([]);
  const {
    isOpen: isdraweropen,
    onOpen: ondraweropen,
    onClose: ondrawerclose,
  } = useDisclosure();

  const [EmpList, setEmpList] = useState([]);
  const [SelectedEmp, setSelectedEmp] = useState([]);
  

  const [ReviewByList, setReviewByList] = useState([]);
  const [ApprovedByList, setApprovedByList] = useState([]);


  const [AllWeek, setAllWeek] = useState(false);

  const [WFHMode, setWFHMode] = useState('0')

  const [PermanentAll, setPermanentAll] = useState(false);
  const [Monday, setMonday] = useState(false);
  const [Tuesday, setTuesday] = useState(false);
  const [Wednesday, setWednesday] = useState(false);
  const [Thursday, setThursday] = useState(false);
  const [Friday, setFriday] = useState(false);
  const [Saturday, setSaturday] = useState(false);
  const [Sunday, setSunday] = useState(false)

  const gridRef = useRef();
  const [rowData, setRowData] = useState([ ]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp code",
      field: "empCode",
    },
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Department",
      field: "departmentName",
    },
    {
      headerName: "Reporting Person",
      field: "reportingPerson",
    },
    {
      headerName: "From Date",
      field: "fromDate",
      valueGetter:(params) => new Date(params.data.fromDate).toLocaleDateString('en-GB')
    },
    {
      headerName: "To Date",
      field: "toDate",
      valueGetter:(params) => new Date(params.data.toDate).toLocaleDateString('en-GB')
    },
    // {
    //   headerName: "Review By",
    //   field: "reviewBy",
    // },
    // {
    //   headerName: "Approve By",
    //   field: "approveByName",
    // },
    {
      headerName: "Reason",
      field: "reason",
    },
    {
      headerName: "Status",
      field: "isStatus",
      cellRenderer:(params)=>(
        params.data.isStatus === 1 ? 
        <span className="bg-green-500  px-1 py-0.5 rounded-md font-medium text-white">
          Approved
        </span> : 
        params.data.isStatus === 0 ? 
        <span className="bg-yellow-500  px-1 py-0.5 rounded-md font-medium text-white">
          Pending
        </span>
        : params.data.isStatus === 2 ? 
        <span className="bg-red-500  px-1 py-0.5 rounded-md font-medium text-white">
          Rejected
        </span> : params.data.isStatus === 3?
        <span className="bg-red-500  px-1 py-0.5 rounded-md font-medium text-white">
          Cancelled
        </span> : ''
      )
    },
    {
      headerName: "Attachment",
      field: "attachmentPath",
      cellRenderer: ({data}) =>
        data.attachmentPath !== null ? (
          <Button 
            colorScheme={"blue"}
            size={"xs"}
            onClick={async() => {

              await fetch(
                `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${data.attachmentPath}`
              )
                .then((response) => {
                  console.log("file Response", response);
          
                  response.blob().then((blob) => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
          
                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    // alink.download = fileURL;
                    // alink.click();
                    setWfhAttachmentPath(alink);
                    onViewAttachmentOpen();
                  });
                })
                .catch((error) => {
                  console.log("File Api Error", error);
                })
            }}
            leftIcon={<FontAwesomeIcon icon={faPaperclip} />}
          >
            View
          </Button>
        ) : (
          ""
        ),
    },
    {
      headerName: "Action",
      floatingFilter: false,
      width:300,
      cellRenderer: (params) => (
        params.data.isStatus === 0 ? 
        <div className="space-x-4">
                          <Button onClick={()=>{
                  let WFHData = params.data
                  setIsView(true);
                  setSelectedEmp(params.data);
                  setDateRange([new Date(WFHData.fromDate),new Date(WFHData.toDate)])
                  setWfhReason(WFHData.reason);
                  setApprovedBy(WFHData.approveBy);
                  getEditHybridData(WFHData.empGuid,WFHData.id,false);
                  setWFHMode(String(WFHData.wfhType));
                  onOpen();
                  
                }} variant="outline" size="xs" colorScheme="blue">
                  <Eye size="14px" className="mr-2" />
                  View
                </Button>

          <Button
           onClick={async()=>{
            let WFHData = params.data;

             HandleApproveHybridList(WFHData.empGuid,WFHData.id,WFHData.isActive,true);

            let body ={
              id:WFHData.id,
              reviewBy: String(userData.userId),
              guId: WFHData.empGuid,
              empName: WFHData.empName,
              empCode: WFHData.empCode,
              departmentName:String(WFHData.departmentId),
              fromDate:WFHData.fromDate,
              toDate:WFHData.toDate,
              reason: WFHData.reason,
              isActive: true,
              isStatus:1,
              approveBy: String(userData.userId),
              reportingPerson: WFHData.reportingPerson,
              companyId: userData.companyId,
              branchId: userData.isActiveBranch
            }
        
            console.log( "Approved  Body",body);
        
            
            await authApi.post(
              `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`,body
            )
            .then((response) => {
              const res = response.data;
              console.log("res",res);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: res,
                  position: 'top',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                })
              }
        
              GetWFHList();              
              
            })
            .catch((error) => {
              console.log(error.response);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: 'ERROR',
                  description: "Employee WFH Not Updated",
                  position: 'top',
                  status: 'error',
                  duration: 2000,
                  isClosable: true,
                })
              }
            });

          }}
           variant="solid" size="xs" colorScheme="green">
            <CheckCircle size="14px" className="mr-2" />
            Approve
          </Button>
          <Button            
           onClick={async()=>{
            let WFHData = params.data;
            HandleApproveHybridList(WFHData.empGuid,WFHData.id,WFHData.isActive,false);
            let body ={
              id:WFHData.id,
              reviewBy:  String(userData.userId),
              guId: WFHData.empGuid,
              empName: WFHData.empName,
              empCode: WFHData.empCode,
              departmentName: String(WFHData.departmentId),
              fromDate:WFHData.fromDate,
              toDate:WFHData.toDate,
              reason: WFHData.reason,
              isActive: false,
              isStatus:2,
              approveBy:  String(userData.userId),              
              reportingPerson: WFHData.reportingPerson,
              companyId: userData.companyId,
              branchId: userData.isActiveBranch
            }
        
            console.log( "Not Approved  Body",body);
        
            
            await authApi.post(
              `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`,body
            )
            .then((response) => {
              const res = response.data;
              console.log("res",res);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: res,
                  position: 'top',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                })
              }
        
              GetWFHList();
              
            })
            .catch((error) => {
              console.log(error.response);
              console.log(error.response.data);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: 'ERROR',
                  description: "Employee WFH Not Updated",
                  position: 'top',
                  status: 'error',
                  duration: 2000,
                  isClosable: true,
                })
              }
            });


          }} variant="solid" size="xs" colorScheme="red">
            <XCircle  size="14px" className="mr-2" />
            Reject
          </Button>
        </div> :  params.data.isStatus === 1 ? 
                <div className="space-x-4">

                <Button onClick={()=>{
                  let WFHData = params.data
                  setIsView(true);
                  setSelectedEmp(params.data);
                  setDateRange([new Date(WFHData.fromDate),new Date(WFHData.toDate)])
                  setWfhReason(WFHData.reason);
                  setApprovedBy(WFHData.approveBy);

                  getEditHybridData(WFHData.empGuid,WFHData.id,true);
                  setWFHMode(String(WFHData.wfhType));
                  onOpen();
                  
                }} variant="outline" size="xs" colorScheme="blue">
                  <Eye size="14px" className="mr-2" />
                  View
                </Button>


                {/* <Button onClick={()=>{
                  let WFHData = params.data
                  setSelectedEmp(params.data);
                  setDateRange([new Date(WFHData.fromDate),new Date(WFHData.toDate)])
                  setWfhReason(WFHData.reason);
                  setApprovedBy(WFHData.approveBy);
                  getEditHybridData(WFHData.empGuid,WFHData.id,true);
                  setWFHMode(String(WFHData.wfhType));
                  onOpen();
                  
                }} variant="solid" size="xs" colorScheme="blue">
                  <Edit size="14px" className="mr-2" />
                  Edit
                </Button> */}

                <Button   onClick={async()=>{
            let WFHData = params.data;
            HandleApproveHybridList(WFHData.empGuid,WFHData.id,WFHData.isActive,false);

            let body ={
              id:WFHData.id,

              reviewBy:  String(userData.userId),
              guId: WFHData.empGuid,
              empName: WFHData.empName,
              empCode: WFHData.empCode,
              departmentName:String(WFHData.departmentId),
              fromDate:WFHData.fromDate,
              toDate:WFHData.toDate,
              reason: WFHData.reason,
              isActive: false,
              isStatus:3,
              approveBy:  String(userData.userId),
              reportingPerson: WFHData.reportingPerson,
              companyId: userData.companyId,
              branchId: userData.isActiveBranch
            }
        
            console.log( "Not Approved  Body",body);
        
            
            await authApi.post(
              `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`,body
            )
            .then((response) => {
              const res = response.data;
              console.log("res",res);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: res,
                  position: 'top',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                })
              }
        
              GetWFHList();
              
            })
            .catch((error) => {
              console.log(error.response);
              console.log(error.response.data);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: 'ERROR',
                  description: "Employee WFH Not Updated",
                  position: 'top',
                  status: 'error',
                  duration: 2000,
                  isClosable: true,
                })
              }
            });
          }} size={'xs'} colorScheme={'red'}><XCircle  size="14px" className="mr-2" />Cancel</Button>
              </div> :''
      ),
    },
  ]);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable:true
  }));


  useEffect(() => {
    getEmployeeList();
    GetWFHList();
  }, []);




  const getEmployeeList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee List", response.data);
      const res = response.data;
      let ActiveEmpList = res.filter((data) => data.isActive === true);
      setEmpList(ActiveEmpList);
    } catch (error) {
      console.error(error);
    }
  };


  const GetWFHList = async () =>{
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetWFHListCB?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Get WFH List", response);
      const res = response.data;

      setRowData(_.orderBy(res, ["id"], ["desc"]));

    } catch (error) {
      console.error(error);
    }
  }

  console.log("Approved By",ApprovedBy);
  console.log(SelectedEmp);


  const getEditHybridData= async(EmpGuid,WfhId,IsActive) => {

    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetWFHHybridListDetails?EmpGuid=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Id=${WfhId}&IsActive=${IsActive}`
      );
      console.log("Edit Hybrid List", response);
      const res = response.data;

      res.map((obj)=>{
        // HandleCheckboxes(obj);
        if(obj.weeklyofDay === "Monday"){
          setMonday(obj.isweekselect);

          let body = {
            whfHybridId:obj.whfHybridId,
            guId: obj.empGuid,
            empName: obj.empName,
            empCode: obj.empCode,
            departmentName:  String(obj.departmentId),
            fromDate: obj.fromDate,
            toDate: obj.toDate,
            reason: obj.reason,
            isActive: obj.isActive,
            approveBy: String(obj.approveBy),
            isStatus: obj.isStatus,
            reportingPerson: obj.reportingPerson,
            id:obj.id,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            weeklyofDay: obj.weeklyofDay,
            selectWeek: obj.selectWeek,
            dayName: obj.dayName,
            isweekselect: obj.isweekselect
          }

          
          setHybridData(prev => {
            return [...prev,body]
          })

        }
        if(obj.weeklyofDay === "Tuesday"){
          setTuesday(obj.isweekselect);
          let body = {
            whfHybridId:obj.whfHybridId,
            guId: obj.empGuid,
            empName: obj.empName,
            empCode: obj.empCode,
            departmentName:  String(obj.departmentId),
            fromDate: obj.fromDate,
            toDate: obj.toDate,
            reason: obj.reason,
            isActive: obj.isActive,
            approveBy: String(obj.approveBy),
            isStatus: obj.isStatus,
            reportingPerson: obj.reportingPerson,
            id:obj.id,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            weeklyofDay: obj.weeklyofDay,
            selectWeek: obj.selectWeek,
            dayName: obj.dayName,
            isweekselect: obj.isweekselect
          }

          
          setHybridData(prev => {
            return [...prev,body]
          })
        }
        if(obj.weeklyofDay === "Wednesday"){
          setWednesday(obj.isweekselect);

          let body = {
            whfHybridId: obj.whfHybridId,
            guId: obj.empGuid,
            empName: obj.empName,
            empCode: obj.empCode,
            departmentName:  String(obj.departmentId),
            fromDate: obj.fromDate,
            toDate: obj.toDate,
            reason: obj.reason,
            isActive: obj.isActive,
            approveBy: String(obj.approveBy),
            isStatus: obj.isStatus,
            reportingPerson: obj.reportingPerson,
            id: obj.id,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            weeklyofDay: obj.weeklyofDay,
            selectWeek: obj.selectWeek,
            dayName: obj.dayName,
            isweekselect: obj.isweekselect
          }

          
          setHybridData(prev => {
            return [...prev,body]
          })

        }
        if(obj.weeklyofDay === "Thursday"){
          setThursday(obj.isweekselect);

          let body = {
            whfHybridId:obj.whfHybridId,
            guId: obj.empGuid,
            empName: obj.empName,
            empCode: obj.empCode,
            departmentName:  String(obj.departmentId),
            fromDate: obj.fromDate,
            toDate: obj.toDate,
            reason: obj.reason,
            isActive: obj.isActive,
            approveBy: String(obj.approveBy),
            isStatus: obj.isStatus,
            reportingPerson: obj.reportingPerson,
            id:obj.id,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            weeklyofDay: obj.weeklyofDay,
            selectWeek: obj.selectWeek,
            dayName: obj.dayName,
            isweekselect: obj.isweekselect
          }

          
          setHybridData(prev => {
            return [...prev,body]
          })

        }
        if(obj.weeklyofDay === "Friday"){
          setFriday(obj.isweekselect);

          let body = {
            whfHybridId:obj.whfHybridId,
            guId: obj.empGuid,
            empName: obj.empName,
            empCode: obj.empCode,
            departmentName: String(obj.departmentId),
            fromDate: obj.fromDate,
            toDate: obj.toDate,
            reason: obj.reason,
            isActive: obj.isActive,
            approveBy: String(obj.approveBy),
            isStatus: obj.isStatus,
            reportingPerson: obj.reportingPerson,
            id:obj.id,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            weeklyofDay: obj.weeklyofDay,
            selectWeek: obj.selectWeek,
            dayName: obj.dayName,
            isweekselect: obj.isweekselect
          }

          
          setHybridData(prev => {
            return [...prev,body]
          })


        }
        if(obj.weeklyofDay === "Saturday"){
          setSaturday(obj.isweekselect);

          let body = {
            whfHybridId:obj.whfHybridId,
            guId: obj.empGuid,
            empName: obj.empName,
            empCode: obj.empCode,
            departmentName: String(obj.departmentId),
            fromDate: obj.fromDate,
            toDate: obj.toDate,
            reason: obj.reason,
            isActive: obj.isActive,
            approveBy: String(obj.approveBy),
            isStatus: obj.isStatus,
            reportingPerson: obj.reportingPerson,
            id:obj.id,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            weeklyofDay: obj.weeklyofDay,
            selectWeek: obj.selectWeek,
            dayName: obj.dayName,
            isweekselect: obj.isweekselect
          }

          
          setHybridData(prev => {
            return [...prev,body]
          })


        }
        if(obj.weeklyofDay === "Sunday"){
          setSunday(obj.isweekselect);

          let body = {
            whfHybridId:obj.whfHybridId,
            guId: obj.empGuid,
            empName: obj.empName,
            empCode: obj.empCode,
            departmentName:  String(obj.departmentId),
            fromDate: obj.fromDate,
            toDate: obj.toDate,
            reason: obj.reason,
            isActive: obj.isActive,
            approveBy: String(obj.approveBy),
            isStatus: obj.isStatus,
            reportingPerson: obj.reportingPerson,
            id:obj.id,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            weeklyofDay: obj.weeklyofDay,
            selectWeek: obj.selectWeek,
            dayName: obj.dayName,
            isweekselect: obj.isweekselect
          }

          
          setHybridData(prev => {
            return [...prev,body]
          })

        }
      })

    } catch (error) {
      console.error(error);
    }

  }



  const HandleApproveHybridList = async (EmpGuid,ID,ActiveStatus,BoolStatus) => {
    console.log("EMpGuid",EmpGuid);
    console.log("WFH id",ID);
    console.log("Active Status",ActiveStatus);
    console.log("BoolStatus",BoolStatus);
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetWFHHybridListDetails?EmpGuid=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Id=${ID}&IsActive=${ActiveStatus}`
      );
      console.log("Approve Hybrid List", response.data);
      const res = response.data;

      let HybridData = res.map(obj => {
        return {...obj,isActive:BoolStatus,
          guId:obj.empGuid
        }
      })

      console.log("Changed Hybrid Data",HybridData);

      await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFHHybridlist`,HybridData
      )
      .then((response) => {
        console.log(response)
        const res = response.data;
        console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: 'top',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })
        }
  
        GetWFHList();
            
  
      })
      .catch((error) => {
        console.error(error)
        console.log(error.response);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: "Employee WFH Not Created",
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });


    } catch (error) {
      console.error(error);
    }
  }



  const HandleEditCheckboxes = async (target) =>{

    
    console.log("Target ID",target.id);
    console.log("Target Name",target.name);
    console.log("Target Checked", target.checked)

    if(target.name === "Monday"){
      setMonday(target.checked);
    }
    if(target.name === "Tuesday"){
      setTuesday(target.checked);
    }
    if(target.name === "Wednesday"){
      setWednesday(target.checked);
    }
    if(target.name === "Thursday"){
      setThursday(target.checked);
    }
    if(target.name === "Friday"){
      setFriday(target.checked);
    }
    if(target.name === "Saturday"){
      setSaturday(target.checked);
    }
    if(target.name === "Sunday"){
      setSunday(target.checked);
    }

    let body = {
      whfHybridId:0,
      guId: SelectedEmp.empGuid,
      empName: SelectedEmp.empName,
      empCode: SelectedEmp.empCode,
      departmentName:  String(SelectedEmp.departmentId),
      fromDate: moment(startDate).format('YYYY-MM-DD'),
      toDate: moment(endDate).format('YYYY-MM-DD'),
      reason: WfhReason,
      isActive: ApprovedBy === undefined ? false : true,
      approveBy: String(ApprovedBy || 0),
      isStatus: ApprovedBy === undefined ? 0 : 1,
      reportingPerson: SelectedEmp.reportingPerson,
      id:SelectedEmp.id,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      weeklyofDay: target.name,
      selectWeek: target.id,
      dayName: target.name,
      isweekselect: target.checked
    }

    console.log("Handle Edit Checkbox Body", body);


    
    const isFound= HybridData.some(data => {
      if(data.weeklyofDay === target.name){
        return true
      }
      return false
    })


    if(isFound){
      setHybridData(prev => {
        const newState = prev.map((obj)=>{
          if(obj.weeklyofDay === target.name){
             return {...obj,isActive:target.checked}
          }
          return obj
        })
        return newState
      })
    }else{
      setHybridData(prev => {
        return [...prev,body]
      })
       }

    // if(target.checked === false){
    //   let FilteredData = _.remove(HybridData,(data)=>{
    //     return data.weeklyofDay !== target.name;
    //   })
    //   console.log("Filtered Data" , FilteredData);
    //    setHybridData(FilteredData);
    // }else{
    //  setHybridData(prev => {
    //    return [...prev,body]
    //  })
    // }


  }



  const SaveEmpWFH = async (e) => {

   e.preventDefault();

   validationSchema.validate({reason: WfhReason},{abortEarly:false})
   .then(async() => {
     setFormErrors({});

    const WfhFormData = new FormData();

    WfhFormData.append('reviewBy',String(SelectedEmp.reportingOfficersId))
    WfhFormData.append('guId',SelectedEmp.guId)
    WfhFormData.append('empName',SelectedEmp.fullName)
    WfhFormData.append('empCode',SelectedEmp.empCode)
    WfhFormData.append('departmentName',SelectedEmp.department)
    WfhFormData.append('fromDate',moment(startDate || new Date()).format('YYYY-MM-DD'))
    WfhFormData.append('toDate',moment(endDate || new Date()).format('YYYY-MM-DD'))
    WfhFormData.append('reason',WfhReason)
    WfhFormData.append('isActive',ApprovedBy === undefined ? false : true)
    WfhFormData.append('isStatus',ApprovedBy === undefined ? 0 : 1)
    WfhFormData.append('wfhType',Number(WFHMode))
    WfhFormData.append('approveBy', String(ApprovedBy || 0))
    WfhFormData.append('reportingPerson',String(SelectedEmp.reportingOfficerName))
    WfhFormData.append('companyId', userData.companyId)
    WfhFormData.append('branchId', userData.isActiveBranch)
    WfhFormData.append('attachment', WfhAttachment)


    const response = await PostWfhData(WfhFormData);

    if(response){

      const GetWFHUpdatedList = async () =>{
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetWFHListCB?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
          console.log("Get WFH Updated List", response);
          const res = response.data;

          const SelectedEmpData = res.filter((data)=>data.empGuid === SelectedEmp.guId);
    
          console.log("Selected Emp Data",SelectedEmpData[0]);

          HandleHybridListSave(SelectedEmpData[0])
    
        } catch (error) {
          console.error(error);
        }
      }
       GetWFHUpdatedList();

    }
   })
   .catch((validationErrors) => {
    console.log("validationErrors",validationErrors);
    const newErrors = {};
    validationErrors.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setFormErrors(newErrors);
  });


    
  }

//  console.log("Selected Emp",SelectedEmp);
  const EditEmpWFH = async (e) => {

    e.preventDefault();
 
     let body ={
       id:SelectedEmp.id,
       reviewBy: SelectedEmp.reviewBy,
       guId: SelectedEmp.empGuid,
       empName: SelectedEmp.empName,
       empCode: SelectedEmp.empCode,
       departmentName: String(SelectedEmp.departmentId),
       fromDate: moment(startDate).format('YYYY-MM-DD'),
       toDate: moment(endDate).format('YYYY-MM-DD'),
       reason: WfhReason,
       wfhType:Number(WFHMode),
       isActive: ApprovedBy === undefined ? false : SelectedEmp.isActive,
       isStatus:ApprovedBy === undefined ? 0 : SelectedEmp.isStatus,
       approveBy: String(ApprovedBy || 0),
       reportingPerson: SelectedEmp.reportingPerson,
       companyId: userData.companyId,
       branchId: userData.isActiveBranch
     }
 
     console.log( "Save WFH",body);
 
     
     await authApi.post(
       `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`,body
     )
     .then((response) => {
       const res = response.data;
       console.log("res",res);
      //  if (!toast.isActive(id)) {
      //    toast({
      //      id,
      //      title: res,
      //      position: 'top',
      //      status: 'success',
      //      duration: 2000,
      //      isClosable: true,
      //    })
      //  }
 

       
     })
     .catch((error) => {
       console.log(error.response);
      //  if (!toast.isActive(id)) {
      //    toast({
      //      id,
      //      title: 'ERROR',
      //      description: "Employee WFH Not Created",
      //      position: 'top',
      //      status: 'error',
      //      duration: 2000,
      //      isClosable: true,
      //    })
      //  }
     });


     
    let DaysList = HybridData.map((obj)=>{
      return {
        ...obj,
        reason: WfhReason,
      }
    })


     await authApi.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFHHybridlist`,DaysList
    )
    .then((response) => {
      console.log(response)
      const res = response.data;
      console.log("res",res);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: res,
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      }

      GetWFHList();

      onClose();


    })
    .catch((error) => {
      console.error(error)
      console.log(error.response);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: 'ERROR',
          description: "Employee WFH Not Created",
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    });
 
   }





   const HandleHybridListSave = async (RowEmpData) => {
    // setPermanentAll(checkedStatus)
     
    console.log("Emp Data",RowEmpData)



    let DaysList = HybridData.map((obj)=>{
      return {
        ...obj,
        id:RowEmpData.id,
        isActive: ApprovedBy === undefined ? false : true,
        reason: WfhReason,
      }
    })

    console.log("Days List",DaysList);

    await authApi.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFHHybridlist`,DaysList
    )
    .then((response) => {
      console.log(response)
      const res = response.data;
      console.log("res",res);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: res,
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      }

      GetWFHList();

      setDateRange([null,null])
      setWfhReason('');
      setApprovedBy('');
      setSelectedEmp([]);
      setHybridData([]);
      setWFHMode('0');
      ondrawerclose();

      setMonday(false)
      setTuesday(false)
      setWednesday(false)
      setThursday(false)
      setFriday(false)
      setSaturday(false)
      setSunday(false)



    })
    .catch((error) => {
      console.error(error)
      console.log(error.response);
      console.log(error.response.data);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: 'ERROR',
          description: "Employee WFH Not Created",
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    });

   }



   const HandleCheckboxes = async (target) => {

    console.log("Target ID",target.id);
    console.log("Target Name",target.name);
    console.log("Target Checked", target.checked)

    if(target.name === "Monday"){
      setMonday(target.checked);
    }
    if(target.name === "Tuesday"){
      setTuesday(target.checked);
    }
    if(target.name === "Wednesday"){
      setWednesday(target.checked);
    }
    if(target.name === "Thursday"){
      setThursday(target.checked);
    }
    if(target.name === "Friday"){
      setFriday(target.checked);
    }
    if(target.name === "Saturday"){
      setSaturday(target.checked);
    }
    if(target.name === "Sunday"){
      setSunday(target.checked);
    }

    

    let body = {
      guId: SelectedEmp.guId,
      empName: SelectedEmp.fullName,
      empCode: SelectedEmp.empCode,
      departmentName:  SelectedEmp.department,
      fromDate: moment(startDate).format('YYYY-MM-DD'),
      toDate: moment(endDate).format('YYYY-MM-DD'),
      reason: WfhReason,
      isActive: ApprovedBy === undefined ? false : true,
      approveBy: String(ApprovedBy||0),
      isStatus: ApprovedBy === undefined ? 0 : 1,
      reportingPerson: String(SelectedEmp.reportingOfficersId),
      id:0,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      weeklyofDay: target.name,
      selectWeek: target.id,
      dayName: target.name,
      isweekselect: target.checked
    }


    console.log("Handle Checkbox Body", body);



    if(target.checked === false){

      let FilteredData = _.remove(HybridData,(data)=>{
        return data.weeklyofDay !== target.name;
      })

      console.log("Filtered Data",FilteredData);

       setHybridData(FilteredData);
    }else{

     setHybridData(prev => {
       return [...prev,body]
     })

    }

   }

   console.log("Hybrid Data",HybridData);


  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Work From Home</h1>
          <Button size="sm" colorScheme="purple" onClick={ondraweropen}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Apply WFH
          </Button>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Drawer
        isOpen={isdraweropen}
        placement="right"
        size="md"
        onClose={()=>{
          setDateRange([null,null])
          setWfhReason('');
          setApprovedBy('');
          setSelectedEmp([]);
          setHybridData([]);
          setWFHMode('0');
          ondrawerclose();
          setMonday(false)
          setTuesday(false)
          setWednesday(false)
          setThursday(false)
          setFriday(false)
          setSaturday(false)
          setSunday(false)
          reset();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Work From Home </DrawerHeader>
          <hr />

          <DrawerBody>

            <form  className="space-y-4" onSubmit={SaveEmpWFH}>


          <div className="space-y-2">
            <label>Select Employee Name</label>
            <ReactSelect
              options={EmpList}
              value={SelectedEmp}
              onChange={(data)=>{
                setSelectedEmp(data);
                 setApprovedBy(undefined)
              }}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}  ${option.empCode}`
              }
              getOptionValue={(option) => option.guId}
              placeholder="Select Employee"
            />
          </div>

          {!SelectedEmp.guId || <>
            <div className="my-2">
              <div className="space-y-2">
                <h6 className="font-medium">
                  Employee Name:{" "}
                  <span className="font-normal">
                    {SelectedEmp.fullName}
                  </span>
                </h6>
                <h6 className="font-medium">
                  Employee Code:{" "}
                  <span className="font-normal">{SelectedEmp.empCode}</span>
                </h6>
                <h6 className="font-medium">
                  Department:{" "}
                  <span className="font-normal">{SelectedEmp.departmentName}</span>
                </h6>
                <h6 className="font-medium">
                  Department:{" "}
                  <span className="font-normal">{SelectedEmp.designationName}</span>
                </h6>
                <h6 className="font-medium">
                  Reporting Person:{" "}
                  <span className="font-normal">
                    {SelectedEmp.reportingOfficerName}
                  </span>
                </h6>
              </div>
              <hr className="mt-2" />

              <div className="space-y-2 mt-4">
                <label>WFH Period<span className="text-red-600">*</span></label>
               <RadioGroup onChange={setWFHMode} value={WFHMode}>
                 <Stack direction='row'>
                   <Radio borderColor={'gray.400'} mr={6} value='0'>Permanent</Radio>
                   <Radio borderColor={'gray.400'} value='1'>Temporary</Radio>
                 </Stack>
               </RadioGroup>
              </div>
              <div className="space-y-2 mt-4">
                <div className="flex justify-between">
                 <label>Select Date Range<span className="text-red-600">*</span></label>
                </div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selectsRange={true}
                  startDate={startDate}
                  // disabled={WFHMode === '0'? true : false}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  placeholderText="Select Date range"
                />
              </div>


              <div className="space-y-2 my-4">

              <h6>Hybrid</h6>
                <hr />

                <div className="grid grid-cols-2 gap-2">
                  <Checkbox id="1" name="Monday" isChecked={Monday} onChange={({target})=>{
                     HandleCheckboxes(target);      
                  }} borderColor={'gray.400'}>Monday</Checkbox>
                  <Checkbox id="2" name="Tuesday" isChecked={Tuesday} onChange={({target})=>{
                    HandleCheckboxes(target);
                    }} borderColor={'gray.400'}>Tuesday</Checkbox>

                  <Checkbox id="3" name="Wednesday" isChecked={Wednesday} onChange={({target})=>{
                     HandleCheckboxes(target);
                    }} borderColor={'gray.400'}>Wednesday</Checkbox>

                  <Checkbox id="4" name="Thursday" isChecked={Thursday} onChange={({target})=>{
                     HandleCheckboxes(target);

                    }} borderColor={'gray.400'}>Thursday</Checkbox>

                  <Checkbox id="5" name="Friday" isChecked={Friday} onChange={({target})=>{
                    HandleCheckboxes(target);
                    }} borderColor={'gray.400'}>Friday</Checkbox>

                  <Checkbox id="6" name="Saturday" isChecked={Saturday} onChange={({target})=>{
                     HandleCheckboxes(target);
                    }} borderColor={'gray.400'}>Saturday</Checkbox>

                  <Checkbox id="0" name="Sunday" isChecked={Sunday} onChange={({target})=>{
                    HandleCheckboxes(target);
                    }} borderColor={'gray.400'}>Sunday</Checkbox>
                </div>


              </div>

              <div className="space-y-2 my-4">
                <label>Reason<span className="text-red-600">*</span></label>
                <Textarea isInvalid={FormErrors?.reason} isRequired value={WfhReason} onChange={({target})=>{setWfhReason(target.value)}} borderColor="gray" placeholder="Enter Reason" />
                {FormErrors?.reason && <span className="text-red-600 my-1 text-sm">{FormErrors?.reason}</span>}
              </div>
              
              <div className="space-y-2 my-4">
                <label>Approved By<span className="text-red-600">*</span></label>
                <Select isRequired value={ApprovedBy} onChange={({target})=>{setApprovedBy(target.value)}} borderColor="gray" placeholder="Select Name">
                  {EmpList.map((data,i)=>{
                   return <option key={i} value={data.id}>{data.firstName} {data.lastName}</option>
                  })}
                </Select>
              </div>

              <div className="space-y-2 mb-4">
                <label>Attachment<span className="text-red-600">*</span></label>
                <Input isRequired ref={FileRef} onChange={({target})=>{setWfhAttachment(target.files[0])}} accept=".pdf,image/*" borderColor="gray" type="file" />
              </div>

            </div>


            <div className="space-x-3 my-2 text-right">

              <Button isLoading={IsSaveWfhLoading} loadingText={'Submitting...'} type='submit' colorScheme="purple">Submit</Button>
              <Button
                onClick={()=>{
                  setDateRange([null,null])
                  setWfhReason('');
                  setApprovedBy('');
                  setSelectedEmp([]);
                  setHybridData([]);
                  setWFHMode('0');
                  ondrawerclose();
        
                  setMonday(false)
                  setTuesday(false)
                  setWednesday(false)
                  setThursday(false)
                  setFriday(false)
                  setSaturday(false)
                  setSunday(false);
                  reset();
                }}
                variant="outline"
                colorScheme="purple"
              >
                Cancel
              </Button>
            </div>
            </>}
            </form>

          </DrawerBody>
        </DrawerContent>
      </Drawer>



      <Modal size={'3xl'} isOpen={isOpen} onClose={()=>{
        setDateRange([null,null])
        setWfhReason('');
        setApprovedBy('');
        setSelectedEmp([]);
        setHybridData([]);
        setIsView(false);
        setWFHMode('0');
        onClose();
        setMonday(false)
        setTuesday(false)
        setWednesday(false)
        setThursday(false)
        setFriday(false)
        setSaturday(false)
        setSunday(false)
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Employee WFH</ModalHeader>
          <ModalCloseButton/>
          <hr />
          <form onSubmit={EditEmpWFH}>
          <ModalBody>

          <div className="flex pb-1 gap-8">
           <div>
             <p className="text-sm text-gray-500">Emp Name</p>
             <h6 className="font-medium">{SelectedEmp.empName}</h6>
           </div>
           <div>
             <p className="text-sm text-gray-500">Emp Code</p>
             <h6 className="font-medium">{SelectedEmp.empCode}</h6>
           </div>
           <div>
             <p className="text-sm text-gray-500">Department</p>
             <h6 className="font-medium">{SelectedEmp.departmentName}</h6>
           </div>
           <div>
             <p className="text-sm text-gray-500">Reporting Person</p>
             <h6 className="font-medium">{SelectedEmp.reportingPerson}</h6>
           </div>
         </div>
                <hr />

            <div className="grid grid-cols-2 gap-6 my-4">

            <div className="space-y-2 ">
              <label>Select Date range</label>
                <DatePicker
                 readOnly={IsView}
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  placeholderText="Select Date range"
                />
              </div>

              <div className="space-y-2 mt-4">
                <label>WFH Period</label>
               <RadioGroup isDisabled={IsView} onChange={setWFHMode} value={WFHMode}>
                 <Stack direction='row' >
                   <Radio borderColor={'gray.400'} mr={6} value='0'>Permanent</Radio>
                   <Radio borderColor={'gray.400'} value='1'>Temporary</Radio>
                 </Stack>
               </RadioGroup>
              </div>

              <div className="space-y-2">
                <label>Reason</label>
                <Textarea isReadOnly={IsView} value={WfhReason} onChange={({target})=>{setWfhReason(target.value)}} borderColor="gray" placeholder="Enter Reason" />
              </div>

              <div className="space-y-2">
                <label>Days</label>
                <div className="flex flex-wrap gap-4">
                 <div><Checkbox isReadOnly={IsView} isChecked={Monday} onChange={({target})=>{HandleEditCheckboxes(target)}} id="1" name="Monday" borderColor={'gray.400'}>Mon</Checkbox></div>
                 <div><Checkbox isReadOnly={IsView} isChecked={Tuesday} onChange={({target})=>{HandleEditCheckboxes(target)}} id="2" name="Tuesday" borderColor={'gray.400'}>Tue</Checkbox></div>
                 <div><Checkbox isReadOnly={IsView} isChecked={Wednesday} onChange={({target})=>{HandleEditCheckboxes(target)}} id="3" name="Wednesday" borderColor={'gray.400'}>Wed</Checkbox></div>
                 <div><Checkbox isReadOnly={IsView} isChecked={Thursday} onChange={({target})=>{HandleEditCheckboxes(target)}} id="4" name="Thursday" borderColor={'gray.400'}>Thu</Checkbox></div>
                 <div><Checkbox isReadOnly={IsView} isChecked={Friday} onChange={({target})=>{HandleEditCheckboxes(target)}} id="5" name="Friday" borderColor={'gray.400'}>Fri</Checkbox></div>
                 <div><Checkbox isReadOnly={IsView} isChecked={Saturday} onChange={({target})=>{HandleEditCheckboxes(target)}} id="6" name="Saturday" borderColor={'gray.400'}>Sat</Checkbox></div>
                 <div><Checkbox isReadOnly={IsView} isChecked={Sunday} onChange={({target})=>{HandleEditCheckboxes(target)}} id="0" name="Sunday" borderColor={'gray.400'}>Sun</Checkbox></div>
                </div>
              </div>

              <div className="space-y-2 ">
                <label>Approved By</label>
                <Select disabled={IsView} value={ApprovedBy} onChange={({target})=>{setApprovedBy(target.value)}} borderColor="gray" placeholder="Select Name">
                {EmpList.map((data,i)=>{
                 return <option key={i} value={data.id}>{data.firstName} {data.lastName}</option>
                })}
                </Select>
              </div>

            </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <div className="space-x-4">
            <Button  variant='outline' colorScheme='purple' 
              onClick={()=>{
                onClose();
                setDateRange([null,null])
                setWfhReason('');
                setApprovedBy('');
                setSelectedEmp([]);
                setHybridData([]);
                setIsView(false);
                setWFHMode('0');
                setMonday(false)
                setTuesday(false)
                setWednesday(false)
                setThursday(false)
                setFriday(false)
                setSaturday(false)
                setSunday(false)
              }}>
              Close
            </Button>
            {!IsView ?
            <Button type="submit" colorScheme='purple' variant='solid'>Save</Button> : ''}
              </div>
          </ModalFooter>

          </form>
        </ModalContent>
      </Modal>

      <Modal size="full" isOpen={isViewAttachmentOpen} onClose={onViewAttachmentClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attachment</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            {WfhAttachmentPath === undefined ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> : <div id="pdf-div">
              <embed
                src={WfhAttachmentPath + "#toolbar=0"}
                // type="application/pdf"
                className="h-screen w-full !select-none"
                height={800}
                width={500}
              />
            </div>}
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme="purple" onClick={onViewAttachmentClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default EmployeeWFH;
