import React, { useState, useEffect, useMemo, useCallback } from "react";
import moment from "moment";
import { Button, Center, Skeleton, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Download, Eye } from "react-feather";
import { useAuth } from "../Utils/AuthContext";
import useFetch from "../Utils/useFetch";

function AllTransactions() {
  const {userData} = useAuth();

  const { IsFetchLoading:IsUpdatePlanLoading, FetchedData: UpdatePlanData, Refetch: RefetchUpdatePlan } = useFetch('/api/Plan/UpdateSubscritpionData',{
    CompanyId:userData?.companyId
  });
  const { IsFetchLoading:IsUserSubscriptionLoading, FetchedData: UserSubscriptionData, Refetch: RefetchUserSubscription } = useFetch('/api/SuperAdmin/GetUserSubscriptionByEmail',{
    EmailId:userData?.userName
  },false);
  const { IsFetchLoading:IsUserTransactionLoading, FetchedData: UserTransactionData, Refetch: RefetchUserTransaction } = useFetch('/api/Razorpay/GetTransactionByEmail',{
    UserName:userData?.userName
  },false);


  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [ columnDefs ] = useState([
    {
      headerName: "Transaction Date",
      field: "paymentDate",
      cellRenderer:(params) => moment(params.data.paymentDate).format('DD/MM/YYYY')
    },
    {
      headerName: "Plan",
      field: "planName"
    },
    {
      headerName: "Amount",
      field: "paidAmount",
      cellRenderer:(params) => <span>{params.data.currency === 'USD' ? '$' : '₹'} {params.data.paidAmount * 0.01}</span>
    },
    // {
    //   headerName: "Action",
    //   cellRenderer:(params) => <div className="space-x-4">
    //     <Button onClick={()=>{setAttachmentPath(params.data.currency)}} leftIcon={<Eye size={'14px'} />} size={'xs'} variant={'outline'} colorScheme="purple" >View</Button>
    //     <Button onClick={()=>{setAttachmentPath(params.data.currency)}} leftIcon={<Download size={'14px'} />} size={'xs'} variant={'solid'} colorScheme="purple" >Download</Button>
    //   </div>
    // },
  ]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true
  }));

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  useEffect(()=>{
    if(UpdatePlanData){
      RefetchUserSubscription();
      RefetchUserTransaction();
    }
  }, [UpdatePlanData]);


  return (
    <div>
      <div className="bg-white drop-shadow-md rounded-xl p-4 mb-4 max-w-2xl">
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-2">
          <h1 className="text-lg font-bold text-gray-800">My Plan</h1>
        </div>
        
        <div className="grid grid-cols-2">
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Active Plan :</h2>
              <h6 className="font-medium">{IsUserSubscriptionLoading ? <Skeleton height='20px' width={'20'} /> : UserSubscriptionData.planName}</h6>
            </div>
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Employees :</h2>
              <h6 className="font-medium">{IsUserSubscriptionLoading ? <Skeleton height='20px' width={'20'}/> : UserSubscriptionData.currentUsers+"/"+UserSubscriptionData.uptoUsers}</h6>
            </div>
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Purchase Date :</h2>
              <h6 className="font-medium"><Skeleton height='20px' width={'fit-content'} isLoaded={!IsUserSubscriptionLoading} >{moment(UserSubscriptionData.purchaseDate).format('DD/MM/YYYY')}</Skeleton></h6>
            </div>
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Due Date :</h2>
              <h6 className="font-medium"><Skeleton height='20px' width={'fit-content'} isLoaded={!IsUserSubscriptionLoading} >{moment(UserSubscriptionData.dueDate).format('DD/MM/YYYY')}</Skeleton></h6>
            </div>
          </div>

          <div className="space-x-4 mt-4 text-right">
          <Link to={`/upgradeplan/1/${UserSubscriptionData.planRefId}`}>
           <Button colorScheme="purple">Renew</Button>
          </Link>
          <Link to={`/viewsubscription`}>
           <Button variant={'outline'} colorScheme="purple">Upgrade Plan</Button>
          </Link>
          </div>
      </div>

      {IsUserTransactionLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> : <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            rowData={UserTransactionData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection="single"
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>}

    </div>
  )
}

export default AllTransactions