import React,{useState, useEffect, useMemo, useRef, useCallback} from 'react';
import { Button, Checkbox, Input, Select, useToast } from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { AgGridReact } from "ag-grid-react";
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';

function IncrementRule() {

  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";

  const [IsFixedDate, setIsFixedDate] = useState(false);
  const [FixedDate, setFixedDate] = useState();
  const [RevAfterMonth, setRevAfterMonth] = useState();
  const [IsProbationApply, setIsProbationApply] = useState(false);
  const [ForwardDays, setForwardDays] = useState();
  const [IsProbationSkipAnnual, setIsProbationSkipAnnual] = useState(false);
  const [IsProRataApply, setIsProRataApply] = useState(false);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridRef = useRef();

  const [MasterData, setMasterData] = useState([]);
  const [SelectedData, setSelectedData] = useState([]);

  const [McolumnDefs, setMColumnDefs] = useState([
      { headerName: "Salary Label", field: "displayLabel", headerCheckboxSelection: true, checkboxSelection:true},
      { headerName: "Salary Type", field: "componentTypeName"}
    ]);

    const [SelectedColumnDefs, setSelectedColumnDefs] = useState([
      { headerName: "Salary Label", field: "displayLabel"},
      { headerName: "Salary Type", field: "componentTypeName"}
    ]);


    const onSelectionChanged = useCallback(() => {
      var SelData = gridRef.current.api.getSelectedRows();
      setSelectedData(SelData);
      console.log(SelData);
    }, []);


    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        minWidth: 150,
        sortable: true,
        filter: true,
        resizable: true,
      };
    }, []);

    const onFirstDataRendered = useCallback((params) => {
      gridRef.current.api.forEachNode((node) =>
        node.setSelected(node.data.isRevisionComponent === true)
      );
    }, []);

  useEffect(() => {
    GetRevisionRule();
    GetMasterStructure();
  }, [])
  

  const GetRevisionRule = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/GetSalaryRevisionRuleSettingList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Get Revsion Rule Response",response);
      const res = response.data;

      setIsFixedDate(res.fixedDate_Check);
      setFixedDate(new Date(res.fixedDate || new Date()) );
      setRevAfterMonth(res.revisionAfter_Month);
      setIsProRataApply(res.proRatad_Check);
      setIsProbationApply(res.probation_Check);
      setIsProbationSkipAnnual(res.probation_Skip_Annual);
      setForwardDays(res.nextRevision_forward_Days);

    } catch (error) {
      console.log(error);
    }
  }


  const GetMasterStructure = async () => {
    await authApi
      .get(
        `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      .then((response) => {
        //  console.log("structure res", response.data);
         const res = response.data;

         let BranchStructure = res.filter(data => data.isActive === true)
        // setMasterData(BranchStructure);
        GetRevsionComps(BranchStructure);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const GetRevsionComps = async (BranchComps) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/GetPayroll_AnnualSalaryStructLink?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      const res = response.data;
      console.log("REvision Comp",response);

      console.log("Branch Components",BranchComps.map((CompData)=>{
        return { 
            ...CompData,
            isRevisionComponent : res.some(RevData => RevData.branchSalaryStructureMasterId === CompData.branchSalaryStructureMasterId && RevData.isActive === true)
        }
      }));

      setMasterData(BranchComps.map((CompData)=>{
        return { 
            ...CompData,
            isRevisionComponent : res.some(RevData => RevData.branchSalaryStructureMasterId === CompData.branchSalaryStructureMasterId && RevData.isActive === true)
        }
      }));

    } catch (error) {
      console.error(error);
    }
  }

  const SaveRevisionRules = async(e) => {
    e.preventDefault();

    let body = {
        // sal_NRR_ID : 0,
        fixedDate_Check : IsFixedDate,
        fixedDate : moment(FixedDate).format('YYYY-MM-DD'),
        revisionAfter_Month : Number(RevAfterMonth || 0),
        proRatad_Check : IsProRataApply,
        nextRevision_forward_Days : Number(ForwardDays || 0),
        probation_Check : IsProbationApply,
        probation_Skip_Annual : IsProbationSkipAnnual,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch
      }

      console.log("Save Revision Rule Body",body);

      await authApi
      .post(`${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/SaveSalaryRevisionRuleSetting`,body)
      .then((response) => {
        const res = response.data;
        console.log("res", response);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
        GetRevisionRule();
        GetMasterStructure();
      })
      .catch((error) => {
        console.log(error.response);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Revsion Rules Not Saved.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  }


  const SaveSalaryRevisionCompRule = async () => {

    let body = SelectedData.map((data)=>{
      return {
          branchSalaryStructureMasterId : data.branchSalaryStructureMasterId,
          isActive : true,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
        }
    })

    console.log("Save Component body",body);

    await authApi.post(
      `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/SavePayrollSalaryRevisionComponenets`,body
    ).then((response)=>{
      console.log("response",response);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: response.data,
          position: "top",
          status: "info",
          duration: 2000,
          isClosable: true
        });
      }
    }).catch((err)=>{
      console.error(err);
      if(!toast.isActive(id)){
        toast({
          id,
          title:'ERROR',
          description:"Salary Revision Components Not Saved.",
          position:'top',
          status:'error',
          duration:2000,
          isClosable:true
        })
      }
    })

  }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Salary Revision Rule</h1>
      </div>

      <div className='grid grid-cols-3 gap-6'>
      <form onSubmit={SaveRevisionRules} className='bg-white p-4  rounded-xl drop-shadow-md space-y-3'>
        <div className='space-y-2'>
         <div className='flex justify-between'>
             <label>Fixed Date</label>
             <Checkbox isChecked={IsFixedDate} onChange={({target})=>{setIsFixedDate(target.checked)}} borderColor={'gray.400'}>Applicable?</Checkbox>
         </div>
         <DatePicker
           disabled={!IsFixedDate}
            className="bg-white border  border-gray-400 text-gray-900 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
            selected={FixedDate}
            onChange={(date) => {
              setFixedDate(date);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Applicable Fixed Date"
          />
        </div>
        <div className='space-y-2'>
            <label>Revision After</label>
            <Select value={RevAfterMonth} onChange={({target})=>{setRevAfterMonth(target.value)}} borderColor={'gray.400'} placeholder='Select Month'>
                <option value={'1'}>1 Month</option>
                <option value={'2'}>2 Month</option>
                <option value={'3'}>3 Month</option>
                <option value={'4'}>4 Month</option>
                <option value={'5'}>5 Month</option>
                <option value={'6'}>6 Month</option>
                <option value={'7'}>7 Month</option>
                <option value={'8'}>8 Month</option>
                <option value={'9'}>9 Month</option>
                <option value={'10'}>10 Month</option>
                <option value={'11'}>11 Month</option>
                <option value={'12'}>12 Month</option>
            </Select>
        </div>

        <div className='space-y-2'>
          <label>Next Revision Forward Days</label>
          <Input value={ForwardDays || ''} onChange={({target})=>{setForwardDays(target.value)}} type='number' borderColor={'gray.400'} placeholder='Next Revision Forward Days' />
        </div>

        <div>
          <Checkbox isChecked={IsProbationApply} onChange={({target})=>{setIsProbationApply(target.checked)}} borderColor={'gray.400'}>Probation Applicable?</Checkbox>
        </div>

        <div>
          <Checkbox isChecked={IsProRataApply} onChange={({target})=>{setIsProRataApply(target.checked)}} borderColor={'gray.400'}>Pro-rata Basis Applicable?</Checkbox>
        </div>

        <div>
          <Checkbox isChecked={IsProbationSkipAnnual} onChange={({target})=>{setIsProbationSkipAnnual(target.checked)}} borderColor={'gray.400'}>Probation Skip Annually?</Checkbox>
        </div>
        <div className='text-right mt-4'>
            <Button type='submit' colorScheme='purple'>Save</Button>
        </div>

      </form>


      <div className='bg-white p-4 col-span-2 rounded-xl drop-shadow-md space-y-3'>
      <div className="grid grid-cols-2 gap-6 ">
                  <div>
                    <div className=" border-b border-gray-400 mb-4 pb-2 text-left">
                      <h1 className="text-gray-700 text-lg font-bold ">
                        All Components
                      </h1>
                    </div>
                    <div className="ag-theme-alpine mb-6">
                      <AgGridReact
                        style={gridStyle}
                        ref={gridRef} // Ref for accessing Grid's API
                        rowData={MasterData} // Row Data for Rows
                        columnDefs={McolumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional- set to 'true'
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        rowSelection={"multiple"}
                        onFirstDataRendered={onFirstDataRendered}
                        onSelectionChanged={onSelectionChanged}
                      />
                    </div>
                  </div>

                  <div>
                    <div className=" border-b border-gray-400 mb-4 pb-2 text-left">
                      <h1 className="text-gray-700 text-lg font-bold ">
                        Components for Salary Revision
                     </h1>
                    </div>
                    <div className="ag-theme-alpine mb-6">
                      <AgGridReact
                        style={gridStyle}
                        rowData={SelectedData} // Row Data for Rows
                        columnDefs={SelectedColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional- set to 'true'
                        domLayout={"autoHeight"}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-right space-x-4">
                    <Button
                      onClick={() => {
                        gridRef.current.api.deselectAll();
                      }}
                      colorScheme={"purple"}
                      variant={"outline"}
                    >
                      Reset
                    </Button>
                    <Button isDisabled={SelectedData.length === 0 ? true : false} onClick={SaveSalaryRevisionCompRule} colorScheme={"purple"}>
                      Save
                    </Button>
                  </div> 
              </div>

      </div>
    </div>
  )
}

export default IncrementRule