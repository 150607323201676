import { Button, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, useToast, Input, Textarea, Tabs, TabList, TabPanels, Tab, TabPanel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, } from "@chakra-ui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect, useMemo, useCallback, } from "react";
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import { CheckCircle, XCircle } from "react-feather";
import ReactSelect from "react-select";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";
import usePost from "../Utils/usePost";

function EmpLeave() {

  const { userData } = useAuth();
  const { IsPostLoading: IsLeaveApplyLoading, PostData: PostLeaveApplyData } = usePost('/api/Leave/SAVELeaveApply');
  const toast = useToast();
  const id = "toast";
  const {
    isOpen: ismodalopen,
    onOpen: onmodalopen,
    onClose: onmodalclose,
  } = useDisclosure();

  const Today = new Date();

  const [MenuList, setMenuList] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function subDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  const [LeaveAttachment, setLeaveAttachment] = useState();
  const [InProbation, setInProbation] = useState(false);

  const [LeaveHistoryDateRange, setLeaveHistoryDateRange] = useState([
    subDays(Today, 15),
    addDays(Today, 15),
  ]);
  const [LeaveHistoryStartDate, LeaveHistoryEndDate] = LeaveHistoryDateRange;

  const [TeamRowData, setTeamRowData] = useState([]);

  const [EmpGeneralInfo, setEmpGeneralInfo] = useState([]);
  const [LeaveQuotaList, setLeaveQuotaList] = useState([]);

  const [NoOfDaysLeave, setNoOfDaysLeave] = useState(0);
  const [AppliedLeaveType, setAppliedLeaveType] = useState([]);
  console.log("Applied LEave",AppliedLeaveType);
  const [LeaveReason, setLeaveReason] = useState();
  const [EmpReason, setEmpReason] = useState();

  const [EmpLeaveBalance, setEmpLeaveBalance] = useState([]);
  const gridRef = useRef();

  const HandleLeaveHistoryChange = (dates) => {
    const [start, end] = dates;
    console.log("Dates", dates);
    setLeaveHistoryDateRange(dates);

    getLeaveHistory(start, end);
    getTeamLeaveHistory(start, end);
  };

  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp Name",
      field: "empName",
      floatingFilter: false,
    },
    {
      headerName: "From Date",
      field: "leaveFromDate",
      valueGetter: (params) =>
        new Date(params.data.leaveFromDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "To Date",
      field: "leaveToDate",
      valueGetter: (params) => new Date(params.data.leaveToDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "Days",
      field: "leaveNoOfDays",
    },
    {
      headerName: "Leave Type",
      field: "leaveType",
    },

    {
      headerName: "Leave Reason",
      field: "leaveReason",
    },

    {
      headerName: "Leave Status",
      field: "leaveStatus",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending" ? (
            <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Pending
            </span>
        ) : params.data.leaveStatus === "Approve" ||
          params.data.leaveStatus === "Approved" ? (
            <span className="bg-green-600 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Approved
            </span>
        ) : params.data.leaveStatus === "Reject" ? (
            <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Rejected
            </span>
        ) : params.data.leaveStatus === "Cancel" ||
          params.data.leaveStatus === "Cancel" ? (
            <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Cancelled
            </span>
        ) : (
          ""
        ),
    },
    {
      headerName: "Action",
      field: "leaveStatus",
      floatingFilter: false,
      cellRenderer: (params) => ((new Date(params.data.leaveToDate) >= new Date(new Date().setHours(23, 59, 59, 999))) &&
        (params.data.leaveStatus === "Pending" || params.data.leaveStatus === "Approved" || params.data.leaveStatus === "Approve")
      ) ? (
        <Button
          onClick={async () => {
            await authApi
              .post(
                `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Cancelled`
              )
              .then((response) => {
                console.log("Leave Approve Cancel", response);
                let res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: res,
                    position: "top",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                  });
                }
                getLeaveHistory(LeaveHistoryStartDate, LeaveHistoryEndDate);
                RunEmailService();
              })
              .catch((err) => {
                console.error(err);
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "Server Error",
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });
          }}
          size="xs"
          colorScheme="red"
          leftIcon={<XCircle size={"14px"} />}
        >
          Cancel
        </Button>
      ) : ''
      
    },
  ]);

  const [TeamColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "From Date",
      field: "leaveFromDate",
      valueGetter: (params) => new Date(params.data.leaveFromDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "To Date",
      field: "leaveToDate",
      valueGetter: (params) => new Date(params.data.leaveToDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "Days",
      field: "leaveNoOfDays",
      width: 100
    },
    {
      headerName: "Leave Type",
      field: "leaveType"
    },

    {
      headerName: "Leave Reason",
      field: "leaveReason",
      cellRenderer: (params) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            setEmpReason(params.data.leaveReason);
            onmodalopen();
          }}
        >
          {params.data.leaveReason}
        </div>
      ),
    },

    {
      headerName: "Leave Status",
      field: "leaveStatus",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending" ? (
          <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
        ) : params.data.leaveStatus === "Approve" ||
          params.data.leaveStatus === "Approved" ? (
          <span className="bg-green-600 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Approved
          </span>
        ) : params.data.leaveStatus === "Reject" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Rejected
          </span>
        ) : params.data.leaveStatus === "Cancel" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Cancelled
          </span>
        ) : (
          ""
        )
    },
    {
      headerName: "Action",
      field: "guId",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending" ? (
          <div className="space-x-4">
            <Button
              size="xs"
              onClick={async () => {
                await authApi
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Approved`
                  )
                  .then((response) => {
                    console.log("Leave Approve Response", response);
                    let res = response.data;
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "info",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                    getTeamLeaveHistory(LeaveHistoryStartDate,LeaveHistoryEndDate);
                    RunEmailService();
                    
                  }).catch((err)=>{
                    console.error(err);
                    if(!toast.isActive(id)){
                      toast({
                        id,
                        title:'Server Error',
                        postition: 'top',
                        status:'error',
                        duration: 2000,
                        isClosable : true
                      })
                    }
                  })
              }}
              colorScheme="green"
              leftIcon={<CheckCircle size="14px" />}
            >
              Approve
            </Button>
            <Button
              size="xs"
              onClick={async () => {
                await authApi
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Reject`
                  )
                  .then((response) => {
                    console.log("Leave Reject Response", response);
                    let res = response.data;
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "info",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                    getTeamLeaveHistory(
                      LeaveHistoryStartDate,
                      LeaveHistoryEndDate
                    );
                    RunEmailService();
                  }).catch((err)=>{
                    console.error(err);
                    if(!toast.isActive(id)){
                      toast({
                        id,
                        title:"Server Error",
                        position:'top',
                        status: 'error',
                        duration: 2000,
                        isClosable: true
                      });
                    }
                  })
              }}
              colorScheme="red"
              leftIcon={<XCircle size="14px" />}
            >
              Reject
            </Button>
          </div>
        ) : (
          ""
        ),
    },
  ]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable: true,
    cellClass: "no-border"
  }));

  useEffect(() => {
    setNoOfDaysLeave((new Date(endDate).getTime() - new Date(startDate).getTime()) /(1000 * 3600 * 24) +1);
  }, [dateRange]);

  useEffect(() => {
    getEmpList();
    getEmployeeLeaveBalance();
    getEmpGeneralInfo();
    getLeaveHistory(LeaveHistoryStartDate, LeaveHistoryEndDate);
    getTeamLeaveHistory(LeaveHistoryStartDate, LeaveHistoryEndDate);
    getLeaveQuotaList();
    HandleSelectedRole();
  }, []);

   const RunEmailService = async () => {
    try{
      const response = await authApi.get(`${process.env.REACT_APP_NOTI_URL}/api/EmailService/SendEmailBySendGrid?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`)
      console.log("Email Service Response",response);
    }catch(err){
      console.error("Email Service Error",err)
    }
   }

  const getEmpList = async () => {
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`)
      console.log(response);
      const res = response.data
      setInProbation(res.filter(obj => obj.guId === userData.guId).map((data) => data.isProbationEnd)[0])
      console.log("In Probation",res.filter(obj => obj.guId === userData.guId).map((data) => data.isProbationEnd)[0]);

    } catch (err) {
      console.error(err);
    }
  }

  const HandleSelectedRole = async () => {
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData.roleId}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`);
      console.log("Menu List", response);
      setMenuList(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getEmpGeneralInfo = async () => {
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeGeneralInfoByGuid?EmpGuid=${userData.guId}`);
      console.log("Emp General Info Response", response.data);
      setEmpGeneralInfo(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getLeaveQuotaList = async () => {
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`);
      console.log("Leave Quota List", response.data);
      setLeaveQuotaList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLeaveHistory = async (FromDate, ToDate) => {
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDashBoard/GetEmployeeLeaveHistory?EmpGuid=${userData.guId}&Fromdate=${moment(FromDate).format("YYYY-MM-DD")}&ToDate=${moment(ToDate).format("YYYY-MM-DD")}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`);
      console.log("Leave History Data", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTeamLeaveHistory = async (FromDate, ToDate) => {
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveDataByReportingOfficer?EmpGuid=${userData.guId}&fromdate=${new Date(FromDate).toLocaleDateString("en-CA")}&Todate=${new Date(ToDate).toLocaleDateString("en-CA")}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`);
      console.log("Team Leave History Data", response.data);
      setTeamRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmployeeLeaveBalance = async () => {
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDashBoard/GetLeaveBalanceByEmpGuid?Empguid=${userData.guId}&year=${Today.getFullYear()}&month=${Today.getMonth() + 1}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`);
      console.log("Leave Balance Data", response);
      setEmpLeaveBalance(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const SaveAppliedLeave = async (e) => {
    e.preventDefault();

    if(startDate === null){
      alert('Please Select Leave Start Date');
    }else if(endDate === null){
      alert('Please Select Leave End Date');
    }else{

      const LeaveUploadData = new FormData();
      LeaveUploadData.append("empGuid", userData?.guId);
      LeaveUploadData.append("empCode", userData?.empCode);
      LeaveUploadData.append("leaveQuotaId", Number(AppliedLeaveType?.leaveQuotaId || 0));
      LeaveUploadData.append("leaveFromDate", moment(startDate).format("YYYY-MM-DD"));
      LeaveUploadData.append("leaveToDate", moment(endDate).format("YYYY-MM-DD"));
      LeaveUploadData.append("leaveNoOfDays", NoOfDaysLeave);
      LeaveUploadData.append("leaveReason", LeaveReason);
      LeaveUploadData.append("leaveStatus", "Pending");
      LeaveUploadData.append("Attachment", LeaveAttachment);
      LeaveUploadData.append("companyId", userData?.companyId);
      LeaveUploadData.append("branchId", userData?.branchId);
  
      const response = await PostLeaveApplyData(LeaveUploadData);
  
      if (response) {
        RunEmailService();
        getLeaveHistory(LeaveHistoryStartDate, LeaveHistoryEndDate);
        setDateRange([null, null]);
        setLeaveReason("");
        setAppliedLeaveType("");
        setNoOfDaysLeave(0);
        onClose();
      }
  
    }

  };


  console.log("Selected Balance",EmpLeaveBalance.find((empbal)=> empbal.leaveType === AppliedLeaveType.leaveType)?.remainingQuota > 0);

  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Application</h1>
        {/* {InProbation ? */}
        <Button leftIcon={<FontAwesomeIcon icon={faPlus}/>} colorScheme="purple" size="sm" onClick={onOpen}>
          Apply Your Leave
        </Button>  
         {/* : InProbation === false ? <h6 className="text-white bg-red-500 text-sm py-1 px-1.5 rounded-lg font-medium">Sorry, Probation Employees are not Eligible to Apply Leave!!</h6> : '' } */}
      </div>

      <div className="flex items-center justify-end gap-4">
        <label className="font-semibold text-lg">Leave History Range :</label>
        <div>
          <DatePicker
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
            selectsRange={true}
            startDate={LeaveHistoryStartDate}
            endDate={LeaveHistoryEndDate}
            onChange={(update) => {
              // setLeaveHistoryDateRange(update);
              HandleLeaveHistoryChange(update);
              // getLeaveHistory(LeaveHistoryStartDate,LeaveHistoryEndDate);
            }}
            dateFormat="dd/MM/yyyy"
            // isClearable={true}
            placeholderText="Select Leave History Range"
          />
        </div>
      </div>

      <Tabs size={"sm"} variant={"solid-rounded"} colorScheme={"purple"}>
        {userData.role === "Admin" ? (
          <TabList>
            <Tab>Your Leaves</Tab>
            <Tab>Team Leaves</Tab>
          </TabList>
        ) : (
          <TabList>
            {MenuList.filter((data) => data.subMenuName === "View Leave").map(
              (data) => {
                return data.isCreate ||
                  data.isModify ||
                  data.isView === true ? (
                  <Tab>Your Leaves</Tab>
                ) : (
                  ""
                );
              }
            )}

            {MenuList.filter((data) => data.subMenuName === "Team Leave").map(
              (data) => {
                return data.isCreate ||
                  data.isModify ||
                  data.isView === true ? (
                  <Tab>Team Leaves</Tab>
                ) : (
                  ""
                );
              }
            )}
          </TabList>
        )}

        <TabPanels>
          <TabPanel>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={TeamRowData} // Row Data for Rows
                columnDefs={TeamColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bg="purple.300 ">Apply Leave</DrawerHeader>
          <hr />
          <DrawerBody>
            <form className="space-y-4" onSubmit={SaveAppliedLeave}>
              <div className="space-y-2">
                <h6 className="font-medium text-lg">
                  Employee Name :{" "}
                  <span className="font-normal">
                    {EmpGeneralInfo.firstName} {EmpGeneralInfo.lastName}
                  </span>
                </h6>
                <h6 className="font-medium text-lg">
                  Employee Code :{" "}
                  <span className="font-normal">{EmpGeneralInfo.empCode}</span>
                </h6>
                <h6 className="font-medium text-lg">
                  Department :{" "}
                  <span className="font-normal">
                    {EmpGeneralInfo.departmentName}
                  </span>
                </h6>
                <h6 className="font-medium text-lg">
                  Reporting Person :{" "}
                  <span className="font-normal">
                    {EmpGeneralInfo.reportingOfficerName}
                  </span>
                </h6>
              </div>
              <hr />
              <div className="space-y-2">
                <h6 className="text-lg font-semibold">Leave Balance</h6>
                <div className="flex flex-wrap gap-5">
                  {EmpLeaveBalance.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className="bg-blue-100 rounded-xl drop-shadow-md  px-2 py-1  hover:-translate-y-2 ease-in-out duration-200"
                      >
                        <h6 className="font-semibold ">
                          {data.leaveType} :{" "}
                          <span className="font-semibold">
                          {data.remainingQuota > data.openingQuota ? data.openingQuota : data.remainingQuota}
                            <span className="text-xs text-gray-500 font-normal">
                              / {data.openingQuota}
                            </span>
                          </span>
                        </h6>
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr />

              <div className="space-y-2">
                <label>Leave Type</label>
                <ReactSelect
                  required
                  options={LeaveQuotaList}
                  value={AppliedLeaveType}
                  onChange={(value) => {
                    setAppliedLeaveType(value);
                    setDateRange([null,null]);
                  }}
                  getOptionLabel={(option) => option.leaveDescription}
                  getOptionValue={(option) => option.leaveQuotaId}
                  placeholder="Select Leave Type"
                />
              </div>

              <div className="space-y-2">
                <div className="flex justify-between">
                  <label>Select Leave Date</label>
                  <label>No. of Days: {(startDate && endDate) && (NoOfDaysLeave || 0)}</label>
                </div>
                <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  required
                  minDate={
                    AppliedLeaveType.leaveAppliedBeforeDays === 0
                      ? new Date(2000, 1, 1)
                      : new Date(moment().add(Number(AppliedLeaveType.leaveAppliedBeforeDays),"days"))
                  }
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  placeholderText="Select Date range"
                />
              </div>

              <div className="space-y-2">
                <label>Leave Reason</label>
                <Textarea
                  value={LeaveReason}
                  onChange={({ target }) => {
                    setLeaveReason(target.value);
                  }}
                  isRequired
                  borderColor="gray.500"
                  placeholder="Leave Reason"
                />
              </div>

              {AppliedLeaveType?.medicalRequired === true ? (
                    <div className="space-y-2">
                      <label>
                        Attach Document{" "}
                        <span
                          className={`${NoOfDaysLeave > AppliedLeaveType?.medicalAfterNoOfDays ? "hidden" : ""}`}
                        >
                          (Optional)
                        </span>
                      </label>
                      <Input
                        isRequired={
                          AppliedLeaveType.medicalRequired === true &&
                          (NoOfDaysLeave > AppliedLeaveType.medicalAfterNoOfDays)
                        }
                        onChange={({ target }) => {
                          setLeaveAttachment(target.files[0]);
                        }}
                        type="file"
                        borderColor="gray.500"
                        placeholder="Attach Document"
                      />
                    </div>
                  ) : (
                    " "
                  )}



              <div className="space-x-3 my-2 text-right">
                {/* {EmpLeaveBalance.find((empbal)=> empbal.leaveType === AppliedLeaveType.leaveType)?.remainingQuota > 0 ? */}
                <Button IsPostLoading={IsLeaveApplyLoading} loadingText="Submitting..." type="submit" colorScheme="purple">
                  Submit
                </Button> 
                {/* : '' } */}
                <Button
                  onClick={onClose}
                  variant="outline"
                  colorScheme="purple"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </DrawerBody>
          <hr />
        </DrawerContent>
      </Drawer>

      <Modal isOpen={ismodalopen} onClose={onmodalclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reason</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div>{EmpReason}</div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onmodalclose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default EmpLeave;
