import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { Button, Switch, useToast,Box,  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,Select,Tooltip,InputGroup,InputRightAddon,
  Input,
  Stack,
  Radio,
  RadioGroup } from "@chakra-ui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from 'react-select';
import moment from "moment";
import DatePicker from "react-datepicker";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function OvertimeApplicable() {
  
    const { userData } = useAuth();
    const toast = useToast();
    const id = "toast";
    const gridRef = useRef();
    const [SelectedEmpList, setSelectedEmpList] = useState([]);

    const [IsAllEligibility, setIsAllEligibility] = useState(false);

    const [CalculateOn, setCalculateOn] = useState('Gross');
    const [EmpList, setEmpList] = useState([]);
    const [DepartmentList, setDepartmentList] = useState([])

    const [OvertimeMasterID, setOvertimeMasterID] = useState(0)
    const [SelectedEmp, setSelectedEmp] = useState([]);
    const [SelectedDepartment, setSelectedDepartment] = useState([]);

    const [DeptEligilityDepartment, setDeptEligilityDepartment] = useState([]);

    const [EligibilityAddOptions, setEligibilityAddOptions] = useState('1');

    const [CalculationTypeList, setCalculationTypeList] = useState([]);

     const [CalculationType, setCalculationType] = useState();

     const [CalculationAmount, setCalculationAmount] = useState();

     const [OvertimeStartDate, setOvertimeStartDate] = useState(moment().format('YYYY-MM-DD'));

    
    //  Add Calculation Type Variables
     const [AddCalculationTypeName, setAddCalculationTypeName] = useState();
     const [AddCalculationTypeAmount, setAddCalculationTypeAmount] = useState();

     const [MinOvertime, setMinOvertime] = useState();
     const [MaxOvertime, setMaxOvertime] = useState();


    const {
      isOpen: isAddOvertimeEligibilityOpen,
      onOpen: onAddOvertimeEligibilityOpen,
      onClose: onAddOvertimeEligibilityClose,
    } = useDisclosure();

    const {
      isOpen: isEditOvertimeEligibilityOpen,
      onOpen: onEditOvertimeEligibilityOpen,
      onClose: onEditOvertimeEligibilityClose,
    } = useDisclosure();


    const {
      isOpen: isAddCalculationTypeOpen,
      onOpen: onAddCalculationTypeOpen,
      onClose: onAddCalculationTypeClose,
    } = useDisclosure();


    const ConvertToHoursMinutes = (time) => {
      const date = new Date("1970-01-01T" + time + "Z");
const hours = date.getUTCHours();
const minutes = date.getUTCMinutes();
  return hours + " hours " + minutes + " minutes"
    }

    function getDateFromTime(time) {
      const [hours, minutes, seconds] = time.split(':');
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      // date.setSeconds(seconds);
      return date;
    }
  
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Employee Name",
          field: "empName",
          // headerCheckboxSelection: true,
          // checkboxSelection: (params) => {
          //   return params.data.empName;
          // },
        },
        {
          headerName: "Min Overtime",
          field: "overtimeMin",
          valueGetter:(params) => ConvertToHoursMinutes(params.data.overtimeMin)
        },

        {
          headerName: "Max Overtime",
          field: "overTimeMax",
          valueGetter:(params) => ConvertToHoursMinutes(params.data.overTimeMax)
        },
        // {
        //   headerName: "Department",
        //   field: "departmentName",
        // },
        {
          headerName: "Eligible Date",
          field: "overtimeEligibleDate",
          valueGetter:(params) => new Date(params.data.overtimeEligibleDate).toLocaleDateString('en-GB')
        },
        { headerName: "Is Eligible", field: "isActive" ,
         cellRenderer:(params) => <Switch colorScheme='green' isChecked={params.data.isActive} onChange={({target})=>{handleIsActiveSwitch(target.checked,params.data)}}  />
        },
        {
          headerName: "Calculation Type",
          field: "attendanceTypeName",
        },
        {
          headerName: "Action",
          cellRenderer: (params) => 
             <Button onClick={()=>{
              let EligibilityData = params.data;


              console.log("Date from Time",getDateFromTime(EligibilityData.overtimeMin || "00:00:00"));
              setOvertimeMasterID(EligibilityData.overtimeId);
              setSelectedEmp(EligibilityData);
              setMinOvertime(getDateFromTime(EligibilityData.overtimeMin || "00:00:00"));
              setMaxOvertime(getDateFromTime(EligibilityData.overTimeMax || "00:00:00"));
              setCalculationType(EligibilityData.otCalculationType);
              setCalculateOn(EligibilityData.otCalculationOn);
              setCalculationAmount(EligibilityData.overtimeAmtPerHour);
              setOvertimeStartDate(moment(EligibilityData.overtimeEligibleDate).format('YYYY-MM-DD'))
              onEditOvertimeEligibilityOpen();
              
             }} variant='solid' size='xs' colorScheme='blue'><Edit size={'13px'} className='mr-1' />Edit</Button>
        },
      ]);
  


      const onSelectionChanged = useCallback(() => {
        var EmpList = gridRef.current.api.getSelectedRows();
       setSelectedEmpList(EmpList);
      }, []);
  
  
      const handleIsActiveSwitch = async (checked,EmpData)=>{
  
        console.log("Emp Data",EmpData);
  
  
        setRowData(prev => {
          const newState =  prev.map((obj) => {
            if (obj.overtimeId === EmpData.overtimeId) {
              return {...obj, isActive: checked};
            }
            return obj;
          });
          return newState
        })

       let body ={
          overtimeId : EmpData.overtimeId,
          empName : EmpData.empName,
          empGuid : EmpData.empGuid,
          department : EmpData.department,
          overtimeMin : EmpData.overtimeMin,
          overTimeMax : EmpData.overTimeMax ,
          otCalculationType : EmpData.otCalculationType,
          overtimeAmtPerHour : EmpData.overtimeAmtPerHour ,
          overtimeEligibleDate :EmpData.overtimeEligibleDate,
          isActive : checked,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch,
        }


        console.log("Switch Saved Body",body)


  
  
      
        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimeMasterData`,body).then((response) => {
          console.log("Switch Reponse",response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
  
      
        })
        .catch((error) => {
          console.log("Switch Error",error);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Employee not Updated`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });
  
        // getEmpList();

        GetOvertimeEmpEligibleList();
  
      }
      console.log("New Row Data",rowData);
            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border'
            }));


            useEffect(()=>{
              getEmployeeList();    
              getDepartmentList();
              GetOvertimeEmpEligibleList();
              getAttendanceTypeMasterList();
            },[])
          

            const getAttendanceTypeMasterList = async()=>{
              try {
                const response= await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendanceTypeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Get Attendance Type List Response",response);
                 setCalculationTypeList(response.data);
              } catch (error) {
                console.error(error);
              }
            }


            const getEmployeeList = async() =>{
              try {
          
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Employee List",response.data);
          
                const res = response.data;
          
                let ActiveEmpList = res.filter((data)=>data.isActive === true);
          
                setEmpList(ActiveEmpList);
                
              } catch (error) {
                console.error(error)
              }
          
            }
  
  
            const getDepartmentList = async()=>{
              try {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/Master/DepartmentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Department List",response.data);
                setDepartmentList(response.data);
              } catch (error) {
                console.error(error)
              }
            }


            console.log("Employe no wrt department", EmpList.length)

            const HandleDepartmentChange = async (DeptID) =>{

              try {
          
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Employee List",response.data);
          
                const res = response.data;
          
                let departmenetViseEmp = res.filter(data =>  data.department === String(DeptID) && data.isActive === true)
          
                setEmpList(departmenetViseEmp);
                
              } catch (error) {
                console.error(error)
              }


            }

            const GetOvertimeEmpEligibleList = async () =>{

              try {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Eligible Overtime List",response.data);
                setRowData(response.data);
              } catch (error) {
                console.error(error)
              }

            }

      
            console.log("Selected Emp",SelectedEmp);

            const HandleEligibleSave = async () => {
              
              let body = {
                empName: SelectedEmp.fullName,
                empGuid: SelectedEmp.guId,
                isActive: true,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
              }

              console.log("Save Eligible Body",body);


              await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimeMasterData`,body
              )
              .then((response) => {
                console.log(response);
                const res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title:res,
                    position: "top",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }
          
                GetOvertimeEmpEligibleList();

                setSelectedDepartment([]);
                setSelectedEmp([]);
                getEmployeeList();
          
              })
              .catch((error) => {
                console.error(error);
        
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "ERROR",
                    description: `Employee not Added`,
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });


            }
  
  
       const SaveCalculationTypeMaster = async (e) =>{

        e.preventDefault();

        let body ={
          otCalculationType : AddCalculationTypeName,
          overtimeAmtPerHour : Number(AddCalculationTypeAmount || 0),
          isActive : true,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
        }
        
        console.log("Save Calculation Type Body",body);


        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimeCalculationTypeData`,body
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
    
          onAddCalculationTypeClose();
          setAddCalculationTypeName('');
          setAddCalculationTypeAmount('');
    
        })
        .catch((error) => {
          console.error(error);
  
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Employee not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

       }
  

       function convertHMS(value) {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        // add 0 if value < 10
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
      }
    
      function convertToSeconds(hours, minutes) {
        return Number(hours) * 60 * 60 + Number(minutes) * 60;
      }


      const ConverToHours = (time) =>{
        let getTime = time;
        //  console.log("Get time",getTime);
    
        const [hours, minutes] = getTime.split(":");
  
  
        let seconds = convertToSeconds(hours, minutes);
  
  
        return Math.floor(seconds/3600)
      }

       const convertToTimeSpan = (time) => {
        let getTime = time;
        //  console.log("Get time",getTime);
    
        const [hours, minutes] = getTime.split(":");
    
        //  console.log("Hours",hours);
        // console.log("Minutes",minutes);
    
        let seconds = convertToSeconds(hours, minutes);
    
        //  console.log("Secconds",seconds);
    
        let ConvertedTime = convertHMS(seconds);
    
        //  console.log("Converted Time",ConvertedTime);
    
        return ConvertedTime;
      };


       const SaveEmployeeOvertimeEligibility = async (e) =>{

        e.preventDefault();
        
        let body ={
          overtimeId : OvertimeMasterID,
          empName : !IsAllEligibility ? SelectedEmp.fullName : '',
          empGuid : !IsAllEligibility ? SelectedEmp.guId : '',
          department : !IsAllEligibility ? SelectedEmp.department : 0,
          overtimeMin: moment(MinOvertime).format('HH:mm:ss') || '00:00:00',
          overTimeMax: moment(MaxOvertime).format('HH:mm:ss') || '00:00:00',
          otCalculationType : CalculationType,
          overtimeAmtPerHour : parseFloat(CalculationAmount || 0) ,
          OTCalculationOn: CalculateOn,
          isAllEligibility: IsAllEligibility,
          isActive : true,
          overtimeEligibleDate:OvertimeStartDate,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch,
        }
        
        console.log("Save Employee Overtime Eligibility Body",body);

        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimeMasterData`,body
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
    
          GetOvertimeEmpEligibleList();
          onAddOvertimeEligibilityClose();
          setMinOvertime('');
          setMaxOvertime('');
          setOvertimeMasterID(0);
          setCalculationType([]);
          setSelectedEmp([]);
          setSelectedDepartment([]);
          setOvertimeStartDate(moment().format('YYYY-MM-DD'))
          setIsAllEligibility(false);
        })
        .catch((error) => {
          console.error(error);
  
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Employee not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

       }


       const SaveDepartmentOvertimeEligibility = async (e) =>{

        e.preventDefault();
        
        let body = EmpList.filter(data =>  data.department === String(DeptEligilityDepartment.departmentId) && data.isActive === true).map((Empdata)=>{
          return {
            empName : Empdata.fullName,
            empGuid : Empdata.guId,
            department : Empdata.department,
            // overtimeMin : new Date(MinOvertime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' , hour12: false}) || "00:00:00",
            // overTimeMax : new Date(MaxOvertime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' , hour12: false}) || "00:00:00", 
            overtimeMin : moment(MinOvertime).format("HH:mm:ss") || "00:00:00",
            overTimeMax : moment(MaxOvertime).format('HH:mm:ss') || "00:00:00",
            otCalculationType : CalculationType.otCalculationType,
            overtimeAmtPerHour : CalculationType.overtimeAmtPerHour ,
            isActive : true,
            overtimeEligibleDate:OvertimeStartDate,

            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
          }
        })
        
        console.log("Save Department Overtime Eligibility Body",body);

        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimeMasterDatalist`,body
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
    
          GetOvertimeEmpEligibleList();
          onAddOvertimeEligibilityClose();
          setMinOvertime('');
          setMaxOvertime('');
          setOvertimeMasterID(0);
          setCalculationType([]);
          setSelectedEmp([]);
          setDeptEligilityDepartment([]);
          setIsAllEligibility(false);

        })
        .catch((error) => {
          console.error(error);
  
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Employee not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

       }


       const ModifyOvertimeEligibility = async (e) =>{

        e.preventDefault();
        
        let body ={
          overtimeId : OvertimeMasterID,
          empName : SelectedEmp.empName,
          empGuid : SelectedEmp.empGuid,
          department : SelectedEmp.department,
          overtimeMin : moment(MinOvertime).format('HH:mm:ss') || '00:00:00',
          overTimeMax : moment(MaxOvertime).format('HH:mm:ss') || '00:00:00',
          otCalculationType : CalculationType,
          overtimeAmtPerHour : parseFloat(CalculationAmount || 0) ,
          OTCalculationOn: CalculateOn,
          isActive : true,
          overtimeEligibleDate:OvertimeStartDate,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch,
        }
        
        console.log("Modify Overtime Eligibility Body",body);

        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimeMasterData`,body
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
    
          GetOvertimeEmpEligibleList();
          onEditOvertimeEligibilityClose();
          setMinOvertime('');
          setMaxOvertime('');
          setOvertimeMasterID(0);
          setCalculationType([]);
          setSelectedEmp([]);
          setSelectedDepartment([]);

        })
        .catch((error) => {
          console.error(error);
  
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Employee not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

       }
  
    console.log(EmpList);


  
    return (
      <div>
        <div className="flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
          <h1 className="text-xl font-bold text-gray-800">Overtime Eligibility</h1>
          <Button  onClick={()=>{
            setIsAllEligibility(true);
            onAddOvertimeEligibilityOpen();
          }} colorScheme="purple" >Add/Edit All Employee Eligibility</Button>
        </div>

        <Box className='bg-white rounded-2xl p-4  items-end mb-4 '>
        <div className="flex justify-between items-center border-b border-gray-400 mb-4 pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Add Overtime Eligibility</h1>

        <div className="flex gap-4">
          <label>Choose Method:</label>
          <div>
           <RadioGroup onChange={setEligibilityAddOptions} value={EligibilityAddOptions}>
             <Stack direction='row' gap={4}>
               <Radio borderColor={'gray.400'} size={'md'} value='1'>Employee wise</Radio>
               <Radio borderColor={'gray.400'} size={'md'} value='2'>Department wise</Radio>
             </Stack>
           </RadioGroup>
          </div>
        </div>
      </div>

      {EligibilityAddOptions === '1' ? 
            <div className="flex items-end  gap-8">
            <div className='space-y-2 w-1/4'>
                  <label>Select Department</label>
                  <ReactSelect
                     options={DepartmentList}
                     value={SelectedDepartment}
                     onChange={(value) => {
                      setSelectedDepartment(value); 
                      let selctedDepId = value.departmentId;
                      console.log("Selected Department Id",selctedDepId);
                      HandleDepartmentChange(selctedDepId);
                      setSelectedEmp([]);
                    }}
                     getOptionLabel={(option) => option.departmentName}
                     getOptionValue={(option) => option.departmentId}
                     key={(option) => option.departmentId}
                     placeholder="Select Department"
                    />
              </div>
      
              <div className='space-y-2 w-1/4'>
                  <label>Select Employee</label>
                  <ReactSelect
                     options={EmpList}
                     value={SelectedEmp}
                     onChange={(value) => {setSelectedEmp(value); }}
                     getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
                     getOptionValue={(option) => option.guId}
                     placeholder="Select Employee"
                    />              
              </div>
              <Button  isDisabled={SelectedEmp.length === 0 ? true : false}  onClick={onAddOvertimeEligibilityOpen} colorScheme={'purple'}><FontAwesomeIcon icon={faPlus} className='mr-1' />Add Employee Eligibility</Button>
            </div>
            :
          <div className="flex items-end  gap-8">
            <div className='space-y-2 w-1/4'>
                <label>Select Department</label>
                <ReactSelect
                   options={DepartmentList}
                   value={DeptEligilityDepartment}
                   onChange={(value) => {
                    setDeptEligilityDepartment(value);
                  }}
                   getOptionLabel={(option) => option.departmentName}
                   getOptionValue={(option) => option.departmentId}
                   placeholder="Select Department"
                  />
            </div>
            <Button  isDisabled={DeptEligilityDepartment.length === 0 ? true : false}  onClick={onAddOvertimeEligibilityOpen} colorScheme={'purple'}><FontAwesomeIcon icon={faPlus} className='mr-1' />Add Department Eligibility</Button>
          </div>
      }



      </Box>

  
        {/* <div className="px-5 flex items-center pb-4 space-x-5 ">
          <FontAwesomeIcon className="rotate-180 text-xl" icon={faArrowTurnUp} />
            <Button size={'sm'} colorScheme='purple'>Eligible</Button>
            <Button size={'sm'} variant={'outline'} colorScheme='purple'>Not Eligible</Button>

          </div> */}
  
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
            onSelectionChanged={onSelectionChanged}
            rowSelection={'multiple'}
          />
        </div>


        <Modal size={'3xl'} isOpen={isAddOvertimeEligibilityOpen} onClose={()=>{
          onAddOvertimeEligibilityClose();
          setMinOvertime('');
          setMaxOvertime('');
          setOvertimeMasterID(0);
          setCalculationType([]);
          setSelectedEmp([]);
          setSelectedDepartment([]);
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{EligibilityAddOptions === '1'? 'Add Employee Eligibility' : 'Add Department Eligibility'}</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={EligibilityAddOptions === '1'? SaveEmployeeOvertimeEligibility : SaveDepartmentOvertimeEligibility}>
          <ModalBody className="space-y-4">
            {
              EligibilityAddOptions === '1' && IsAllEligibility === false ? 
              <>

           <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Employee Name</p>
                <h6 className="font-medium">{SelectedEmp.fullName}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Employee Code</p>
                <h6 className="font-medium">{SelectedEmp.empCode}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Designation</p>
                <h6 className="font-medium">{SelectedEmp.designationName}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Department</p>
                <h6 className="font-medium">{SelectedEmp.departmentName}</h6>
              </div>
            </div>

            <hr />

              </> : EligibilityAddOptions === '1' && IsAllEligibility === false ?

              <>

            <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Department Name</p>
                <h6 className="font-medium">{DeptEligilityDepartment.departmentName}</h6>
              </div>

            </div>

            <hr />

              </> : ''
            }



            <div className="grid grid-cols-2 gap-4 my-2">

            <div className="space-y-2">
    <label>Overtime Min Time</label>
    {/* <Input value={MinOvertime || ''} onChange={({target})=>{setMinOvertime(target.value)}}  type='time' borderColor='gray.500' placeholder="Min Time" /> */}
    <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={MinOvertime}
                  onChange={(date) => setMinOvertime(date)}
                  showTimeSelect
                  required
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="In Time"
                />
</div>


<div className="space-y-2">
    <label>Overtime Max Time</label>
    {/* <Input value={MaxOvertime || ''} onChange={({target})=>{setMaxOvertime(target.value)}}  type='time' borderColor='gray.500' placeholder="Max Time" /> */}
    <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={MaxOvertime}
                  onChange={(date) => setMaxOvertime(date)}
                  showTimeSelect
                  required
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="In Time"
                />
</div>



<div className="space-y-2">
  <label>Calculation Type</label>
  <Select value={CalculationType} isRequired onChange={({target})=>{setCalculationType(target.value)}} borderColor={"gray.400"} placeholder="Calculation Type">
    {CalculationTypeList.map((data,i)=>{
      return <option key={i} value={data.attendanceTypeId}>{data.attendanceTypeName}</option>
    })}
  </Select>
</div>

<div className="space-y-2">
    <label>Overtime Start Date</label>
    <Input value={OvertimeStartDate || ''} onChange={({target})=>{setOvertimeStartDate(target.value)}}  type='date' borderColor='gray.500' placeholder="Min Time" />
</div>

<div className="space-y-4">
    <label>Calculate On</label>
    <RadioGroup onChange={setCalculateOn} value={CalculateOn}>
      <div className='items-center flex my-auto gap-6'>
        <Radio borderColor={'gray.400'} value='Gross'>Gross Salary</Radio>
        {/* <Radio borderColor={'gray.400'} value='Basic'>Basic Salary</Radio> */}
        <Radio borderColor={'gray.400'} value='Manually'>Fix Amount</Radio>
      </div>
    </RadioGroup>
          </div>

          {CalculateOn === 'Manually' ? 
          <div className="space-y-2">
    <label>Calculation Amount</label>
    <Input value={CalculationAmount} onChange={({target})=>{setCalculationAmount(target.value)}} type='number' borderColor='gray.500' placeholder="Amount" />
</div> : ' '}

            </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={()=>{
onAddOvertimeEligibilityClose();
setMinOvertime('');
setMaxOvertime('');
setOvertimeMasterID(0);
setCalculationType([]);
setSelectedEmp([]);
setSelectedDepartment([]);
        }} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>



    {/* Edit OVertime Eligibility */}


      <Modal size={'3xl'} isOpen={isEditOvertimeEligibilityOpen} onClose={()=>{
onEditOvertimeEligibilityClose();
setMinOvertime('');
setMaxOvertime('');
setOvertimeMasterID(0);
setCalculationType([]);
setSelectedEmp([]);
setSelectedDepartment([]);
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Overtime Eligibility</ModalHeader>
          <ModalCloseButton onClick={()=>{
onEditOvertimeEligibilityClose();
setMinOvertime('');
setMaxOvertime('');
setOvertimeMasterID(0);
setCalculationType([]);
setSelectedEmp([]);
setSelectedDepartment([]);
        }} />
          <hr />
          <form onSubmit={ModifyOvertimeEligibility}>
          <ModalBody className="space-y-4">
          <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Employee Name</p>
                <h6 className="font-medium">{SelectedEmp.empName}</h6>
              </div>
            </div>

            <hr />


            <div className="grid grid-cols-2 gap-4 my-2">



            <div className="space-y-2">
    <label>Overtime Min Time</label>
    <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={MinOvertime}
                  onChange={(date) => setMinOvertime(date)}
                  showTimeSelect
                  required
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="In Time"
                />
</div>


<div className="space-y-2">
    <label>Overtime Max Time</label>
    <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={MaxOvertime}
                  onChange={(date) => setMaxOvertime(date)}
                  showTimeSelect
                  required
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="In Time"
                />
</div>



<div className="space-y-2">
  <label>Calculation Type</label>
  <Select value={CalculationType} isRequired onChange={({target})=>{setCalculationType(target.value)}} borderColor={"gray.400"} placeholder="Calculation Type">
  {CalculationTypeList.map((data,i)=>{
      return <option key={i} value={data.attendanceTypeId}>{data.attendanceTypeName}</option>
    })}

  </Select>
</div>

<div className="space-y-2">
    <label>Overtime Start Date</label>
    <Input value={OvertimeStartDate || ''} onChange={({target})=>{setOvertimeStartDate(target.value)}}  type='date' borderColor='gray.500' placeholder="Min Time" />
</div>

<div className="space-y-4">
    <label>Calculate On</label>
    <RadioGroup onChange={setCalculateOn} value={CalculateOn}>
      <div className='items-center flex my-auto gap-6'>
        <Radio borderColor={'gray.400'} value='Gross'>Gross Salary</Radio>
        {/* <Radio borderColor={'gray.400'} value='Basic'>Basic Salary</Radio> */}
        <Radio borderColor={'gray.400'} value='Manually'>Fix Amount</Radio>
      </div>
    </RadioGroup>
          </div>

          {CalculateOn === 'Manually' ? 
          <div className="space-y-2">
    <label>Calculation Amount</label>
    <Input value={CalculationAmount} onChange={({target})=>{setCalculationAmount(target.value)}} type='number' borderColor='gray.500' placeholder="Amount" />
</div> : ' '}

            </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={()=>{
onEditOvertimeEligibilityClose();
setMinOvertime('');
setMaxOvertime('');
setOvertimeMasterID(0);
setCalculationType([]);
setSelectedEmp([]);
setSelectedDepartment([]);
        }} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>




      <Modal isOpen={isAddCalculationTypeOpen} onClose={onAddCalculationTypeClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Calculation Type</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveCalculationTypeMaster}>
          <ModalBody className="space-y-4">

          <div className="space-y-2">
              <label>Calculation Name</label>
              <Input value={AddCalculationTypeName || ''} onChange={({target})=>{setAddCalculationTypeName(target.value)}} isRequired type='text' borderColor='gray.500' placeholder="Calculation Name" />
          </div>
            

            <div className="space-y-2">
                <label>Calculation Amount</label>
                <Input value={AddCalculationTypeAmount || ''} onChange={({target})=>{setAddCalculationTypeAmount(target.value)}} isRequired type='number' borderColor='gray.500' placeholder="Calculation Name" />
            </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={onAddCalculationTypeClose} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>




      </div>
    )
  }
  

export default OvertimeApplicable