import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function ViewSalaryIncrement() {

  const { userData } = useAuth();

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [rowData, setRowData] = useState(); // Table Data Variable
  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Emp Code", field: "empCode", },
    {
      headerName: "Emp Name",
      field: "fullName"
    },
    { headerName: "Designation", field: "designationName" },
    { headerName: "Department", field: "departmentName" },
    // {
    //   headerName: "Salary Structure Status",
    //   field: "isSalaryStructureCompleted",
    //   cellRenderer: (params) =>
    //     params.data.isSalaryStructureCompleted === 1 ? (
    //       <div className="text-center">
    //         <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
    //           Data Pending
    //         </span>
    //       </div>
    //     ) : params.data.isSalaryStructureCompleted === 2 ? (
    //       <div className="text-center">
    //         <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
    //           Completed
    //         </span>
    //       </div>
    //     ): (
    //       <div className="text-center">
    //         <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
    //           Pending
    //         </span>
    //       </div>
    //     ),
    // },
    {
      headerName: "Action",
      cellRenderer: (params) => <Link to={`/empsalaryincrement/${params.data.guId}`}>
      <Button colorScheme="blue" variant={'solid'} size={'xs'}>View History</Button>
      </Link>
    }
  ]);


  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable:true
  }));

  useEffect(()=>{
    getEmp();
  },[])

  const getEmp = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
       console.log(response.data);
      const EmpList = response.data;
      setRowData(EmpList.filter(data => data.isSalaryStructureCompleted === 2));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">View Increment Salary</h1>
      </div>

      <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
          />
        </div>
  

    </div>
  )
}

export default ViewSalaryIncrement