import axios from 'axios';

const authApi = axios.create({
    baseURL: `${process.env.REACT_APP_ACQ_URL}`,
});

authApi.interceptors.request.use(
    (config) => {
        const token = localStorage?.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default authApi;
