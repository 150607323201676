import { Button } from "@chakra-ui/react";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, useParams } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import moment from "moment";
import _ from "lodash";
import authApi from "../Utils/AuthApis";
import { useAuth } from "../Utils/AuthContext";

const data = [
  {
    SalaryRevisionDate: "10/2019",
    Gross: 12000,
  },
  {
    SalaryRevisionDate: "10/2020",
    Gross: 15980,
  },
  {
    SalaryRevisionDate: "10/2021",
    Gross: 28000,
  },
  {
    SalaryRevisionDate: "10/2022",
    Gross: 39080,
  }
];


function EmployeeIncrementStructure() {

  const { userData } = useAuth();

  const userId = useParams();
  // console.log(userId);
  const guID = userId.empGuid;

  const [EmpData, setEmpData] = useState([]);

  const [AnalyticsData, setAnalyticsData] = useState([]);

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const [columnDefs, setColumnDefs] = useState([ ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable:true
  }));

  useEffect(()=>{
    getMasterStructure();
    getEmp();
    GetSalaryHistory();
    GetEmpAnalyticsData();
  },[]);

  const getEmp = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeDetailByEmpGuid?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
       const Emp = response.data;
       console.log("EMP response",response);
      setEmpData(Emp);
    } catch (error) {
      console.error(error);
    }
  };

  const GetSalaryHistory = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/GetEmployeeSalaryIncrementHistory?EmpGuId=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
       console.log("Emp Salary History",response);
       const res = _.orderBy(response.data,['salaryStatus'], ['desc'])
      setRowData(res.map((data)=>{
        let EmpData = data;
        let FinalRowdata = EmpData.componantDataList?.map((data)=>{
            let rowData = [];
            rowData.push({[data.branchSalaryStructureMasterId+'salaryValue']: data.salaryValue});
            return  Object.assign(EmpData,...rowData)
          })
          // console.log("F Row Data",_.unionBy(FinalRowdata,'empGuid'));
          return _.unionBy(FinalRowdata,'empGuid')[0]
       }));
    } catch (error) {
      console.error(error);
    }
  };


  const getMasterStructure = async () => {
    await authApi
      .get(
        `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      .then((response) => {
        //  console.log("Branch Header", response.data);
        let resdata = response.data;
        let ActiveComp = resdata.filter(data => data.isActive  === true)
         HandleHeaderData(ActiveComp);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const GetEmpAnalyticsData = async () => {
    try{
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/GetEmpSalaryGrossOrderByDate?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Analytics Data",response);
      setAnalyticsData(response.data);
    }catch(err){
      console.error(err)
    }
  }

  const HandleHeaderData = (SalaryComp) => {

    let StartingHeaders = [
      { 
        headerName: "Start Date", 
        field: "startDate", 
        cellRenderer:(params) => moment(params.data.startDate).format('DD/MM/YYYY')
      },
      {
        headerName: "Status",
        field: "salaryStatus",
        cellRenderer:(params) => params.data.salaryStatus ? 
        <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          New
        </span> :
        <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Old
        </span>
      },
      {
        headerName: "Next Salary Revision Date",
        field: "salaryIncrementFinal.nextRevisionDate",
        cellRenderer:(params) => params.data.salaryIncrementFinal !== null ? moment(params.data.salaryIncrementFinal.nextRevisionDate).format('DD/MM/YYYY') : ''
      },
      {
        headerName: "Revision Type",
        field: "salaryIncrementFinal.salary_RevisionType",
      },
      {
        headerName: "Salary Percentage",
        field: "salaryIncrementFinal.salary_Percentage",
      },
      {
        headerName: "Salary Rating",
        field: "salaryIncrementFinal.salary_Rating",
      }
    ]

    let MiddleHeaders = SalaryComp.map(obj=>{
      return { 
        headerName: obj.displayLabel, 
        field: obj.branchSalaryStructureMasterId+'salaryValue'
      }
    })

    let EndHeaders = userData.countryName === "India" ? [
      { 
        headerName: "Emp PF", 
        field: `empEPFContribution`
      },
      { 
        headerName: "Emp_ER PF", 
        field: `employerEPFContribution`
      },
      { 
        headerName: "Additional PF", 
        field: `empAdditionalEPFContribution`
      },
      { 
        headerName: "Emp ESI", 
        field: `empESIContribution`
      },
      { 
        headerName: "Emp_ER ESI", 
        field: `employerESIContribution`
      },
      { 
        headerName: "Gross", 
        field: `grossSalary`
      },
      { 
        headerName: "Net Salary", 
        field: `netSalary`
      },
      { 
        headerName: "CTC", 
        field: `ctc`
      }
    ] : [
      { 
        headerName: "Gross", 
        field: `grossSalary`
      },
      { 
        headerName: "Net Salary", 
        field: `netSalary`
      },
      { 
        headerName: "CTC", 
        field: `ctc`
      }
    ]

    console.log("Header Data",_.union(StartingHeaders,MiddleHeaders,EndHeaders));

    setColumnDefs(_.union(StartingHeaders,MiddleHeaders,EndHeaders))
  }



  


  return (
    <div>


<div className="bg-white shadow-lg rounded-3xl p-6 mb-6">
        <div className="flex  gap-8">
          <div className="space-y-2">
            <p className="text-sm text-gray-500">Employee Name</p>
            <h6 className="font-medium">{EmpData.fullName}</h6>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-gray-500">Employee Code</p>
            <h6 className="font-medium">{EmpData.empCode}</h6>
          </div>        
            <div className="space-y-2">
            <p className="text-sm text-gray-500">Designation</p>
            <h6 className="font-medium">{EmpData.designationName}</h6>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-gray-500">Department</p>
            <h6 className="font-medium">{EmpData.departmentName}</h6>
          </div>
        </div>
      </div>


        <div className="ag-theme-alpine mb-4">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
          />
        </div>


    <div className="bg-white p-6 rounded-3xl ">
     <div className="border-b pb-3">
       <h2 className="text-xl  font-semibold">Salary Increment Data</h2>
     </div>

    <LineChart
      className="my-4"
      width={1000}
      height={300}
      data={AnalyticsData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey='startDate'/>
      <YAxis />
      <Tooltip />
      <Legend  verticalAlign="top" height={36}/>
      <Line
        type="monotone"
        dataKey='grossSalary'
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
    </LineChart>
    </div>


    </div>
  );
}

export default EmployeeIncrementStructure;
