import moment from "moment";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authApi from "./AuthApis";
import { useAuth } from "./AuthContext";

function formatOrdinal(number) {
    if (number % 10 === 1 && number % 100 !== 11) {
      return number + 'st';
    } else if (number % 10 === 2 && number % 100 !== 12) {
      return number + 'nd';
    } else if (number % 10 === 3 && number % 100 !== 13) {
      return number + 'rd';
    } else {
      return number + 'th';
    }
  }

const useValidation = () => {
    
    const { userData } = useAuth();
    const navigate = useNavigate();
    const [ ValidationData, setValidationData ] = useState();
    // const [ ValidationLoading, setValidationLoading ] = useState(false);
    useEffect(()=>{
        const GetValidationData = async () => {
            // setValidationLoading(true);
            try{
                const response = await authApi.get(
                    `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetPayrollStatusChecks?CompanyId=${userData?.companyId}&BranchId=${userData?.isActiveBranch}`
                )
                console.log("Validation Data",response);
                const res = response.data;
                if(res === 'No Record found!'){
                    alert('Please Setup Payroll Configuration!');
                    navigate('/payrollconfiguration');
                }else{
                    let CurrentPayrollDate = res?.payrollMonth === 0 ? new Date(res?.payrollStartFrom) : new Date(res?.payrollYear,res?.payrollMonth,1)
                    setValidationData({
                        ...res,
                        currentPayrollDate: moment(CurrentPayrollDate).format('YYYY-MM-DD'),
                        currentPayrollYear: new Date(CurrentPayrollDate).getFullYear(),
                        currentPayrollMonth: new Date(CurrentPayrollDate).getMonth()+1,
                        currentPayrollOrdinalMonth: formatOrdinal(new Date(CurrentPayrollDate).getMonth()+1),
                        currentPayrollDesc: `${moment(CurrentPayrollDate).format('MMMM')} - ${moment(CurrentPayrollDate).format('YYYY')}`
                    });
                }
            } catch (err) {
                console.error(err);
            } 
            // finally {
            //     setValidationLoading(false);
            // }
        }
        GetValidationData();
    },[]);
    return ValidationData;
}

export default useValidation;