import { Tooltip, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Button, } from '@chakra-ui/react'
import React,{ useState, useEffect } from 'react'
import HelpGuideMenu from './HelpGuideMenu';
import { useLocation } from 'react-router-dom';
import authApi from '../Utils/AuthApis';

const HelpDesk = () => {

  const location = useLocation();
  const { pathname } = location;

  const userData = JSON.parse(localStorage.getItem("user"));
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [HelpMenuList, setHelpMenuList] = useState([]);

  useEffect(()=>{
    GetAllMenuList();
  },[])

  const GetAllMenuList = async () => {
    try{
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/HelpGuide/GetHelpGuideMenuList`
      )
      console.log("All Menu List",response);
      setHelpMenuList(response.data)
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div  className="relative inline-flex">
      <Tooltip 
        borderRadius={"7px"}
        hasArrow
        label="Help Guide"
        bgColor={"gray.600"} >
        <Button onClick={onOpen} colorScheme='blue' variant={'link'}>Help</Button>
      </Tooltip>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton className="mt-2" />
          <DrawerHeader>Help Guide</DrawerHeader>
          <hr />
          <DrawerBody>
            <ul>
              {HelpMenuList.map((data,i) => {
                return <HelpGuideMenu
                 key={i}
                activecondition={
                  pathname === "/viewclients"
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#"
                        className={`block text-slate-700 hover:text-slate-900 truncate transition duration-150 ${
                          (pathname === "/sdf" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                           e.preventDefault();
                           handleClick();
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-sm font-medium hover:ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              {data.categoryName}
                            </span>
                          </div>
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-4 mt-1 ${!open && "hidden"}`}>
                          {data?._helpGuideSubCategoryList?.map((SubData,j)=>{
                            return <li key={j} onClick={onClose} className="mb-1 last:mb-0">
                            <a
                              href={`/help/${data.categoryId}/${SubData.subCategoryId || 0}`}
                              className={({ isActive }) =>
                                "block text-slate-800 hover:text-slate-600 hover:translate-x-2 transition duration-150 truncate " +
                                (isActive ? "!text-indigo-500" : "")
                              }
                               target='_blank'
                            >
                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                               {SubData.subCategoryName}
                              </span>
                            </a>
                          </li>
                          })}
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </HelpGuideMenu>
              })}

            </ul>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default HelpDesk