import React, { useCallback, useRef, useState, useMemo, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faArrowTurnUp, faCalendarDay, faCircleCheck, faCircleExclamation, faDownload, faFileInvoice, faLock, faMoneyBill1, faMoneyBill1Wave, faSackDollar, faUpload, faUserPlus, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Avatar, useDisclosure, Modal, ModalContent, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Flex, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, ModalOverlay, Spinner, Tooltip, AlertDialogFooter, Center, useToast, AlertDialog, AlertDialogBody, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,   Menu, MenuButton, MenuList, MenuItem, MenuItemOption, MenuGroup, MenuOptionGroup, MenuDivider, Skeleton, Input, Select, } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { CheckCircle, XCircle,Edit, ChevronDown } from "react-feather";
import { AgGridReact } from "ag-grid-react";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { View, Text, Document, Page, StyleSheet ,Image, PDFViewer } from '@react-pdf/renderer';
import DatePicker from "react-datepicker";
import _ from "lodash";
import numberToWords from 'number-to-words';
import numeral from 'numeral';
import moment from "moment";
import { LuDownload, LuLock, LuUnlock } from "react-icons/lu";
import DynamicSalarySlip from "../SalarySlips/DynamicSalarySlip";
import useValidation from "../Utils/useValidation";
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import usePost from "../Utils/usePost";
import useFetch from "../Utils/useFetch";
import { Font } from '@react-pdf/renderer'
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";


const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);
  
  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
  chunks[i] = str.substr(o, size);
  }
  
  return chunks;
  };

Font.registerHyphenationCallback((word) => {
if (word.length > 12) {
return chunkSubstr(word, 10);
} else {
return [word];
}
});


  const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        paddingTop: 35,
        paddingLeft:25,
        paddingRight:25,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    imgLogo:{
        height:70,
        width:110,
    }
  });
  
  const Leavesteps = [
    { label: "Step 1", description: "Leave Applied" },
    { label: "Step 2", description: "No Attendance" },
    { label: "Step 3", description: "Attendance Summary" },
  ];
  
  const NewJoineesteps = [
    { label: "Step 1", description: "New Joinees" },
    { label: "Step 2", description: "Employee in Exit Process" },
  ];
  
  const Bonussteps = [
    { label: "Step 1", description: "Bonus" },
    { label: "Step 2", description: "Salary Revision" },
    { label: "Step 3", description: "Overtime Payment" },
  ];
  
  const Reimbursementsteps = [
    { label: "Step 3", description: "Adhoc Payments" },
    { label: "Step 4", description: "Adhoc Deductions" },
  ];
  
  const Arrearssteps = [
    { label: "Step 1", description: "Salary Payout on Hold" },
    { label: "Step 2", description: "Arrears" },
  ];
  
  const Overridesteps = [
    { label: "Step 1", description: "PF Override" },
    { label: "Step 2", description: "ESI Override" },
    { label: "Step 3", description: "TDS Override" },
  ];

  const NumberFormat = (value) => {
    return numeral(value).format('0,0.00');
  }

function FinalRunPayroll() {

  const Validation = useValidation();
  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";

  const [PayrollMonth, setPayrollMonth] = useState(new Date().getMonth()+1);
  const [PayrollYear, setPayrollYear] = useState(new Date().getFullYear())
  console.log("Payroll Month",PayrollMonth,"Payroll Year",PayrollYear);

    const { IsPostLoading: IsPostAttCountLoading, PostData: PostAttCountData } = usePost('/api/Attendance/ImportClientPayrollAttendance');
    const { IsPostLoading: IsPostNoAttLoading, PostData: PostNoAttData } = usePost('/api/LOPAdjustment/SaveAttendanceLopList');
    
    console.log("Validations",Validation);

    const amountToWords = (amount, currency) => {
      // Separate integer and decimal parts
      const integerPart = Math.floor(amount);
      const decimalPart = Math.round((amount - integerPart) * 100); // assuming two decimal places
    
      // Convert integer and decimal parts to words
      const integerWords = numberToWords.toWords(integerPart);
      const decimalWords = numberToWords.toWords(decimalPart);
    
      // Choose the appropriate currency word based on the currency code
      const currencyWord = currency === 'AED' ? 'dirhams' : currency === 'INR' ? 'rupees' : 'dollars';
      const decimalUnit = currency === 'AED' ? 'fils' : 'paise';
    
      // Combine and format the result
      const result = `${integerWords} and ${decimalWords} ${decimalUnit}`;
    
      return result?.toUpperCase();
    };

    const gridRef = useRef();
    const sliderRef = useRef(null);
    const ReviewAllEmpGrid = useRef();
    const PayslipGrid = useRef();
    const NoAttRef = useRef();
    const OttGridRef = useRef();

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    

    const [LoadingStatus, setLoadingStatus] = useState({
      payrollMonthDataLoading: false,
      payrollCostLoading: false,
      leaveDataLoading: false,
      NoAttDataLoading: false,
      LOPSummaryDataLoading: false,
      NewJoineeDataLoading: false,
      SeparationDataLoading: false,
      PfDataLoading: false,
      EsiDataLoading: false,
      TdsDataLoading: false,
      BonusDataLoading: false,
      SalaryRevDataLoading: false,
      OvertimeDataLoading: false,
      AdhocPayDataLoading: false,
      AdhocDedDataLoading: false,
      HoldSalaryDataLoading: false,
      ArrearsDataLoading: false,
      ImportAttDataLoading: false
    })

    console.log("Loading Status",LoadingStatus);

  const [IsReviewPayrollLoading,setIsReviewPayrollLoading] = useState(false);
  const [BulkPayslipLoading, setBulkPayslipLoading] = useState(false);

  const [PayrollConfigData, setPayrollConfigData] = useState([]);
  const [PendingMonthNo, setPendingMonthNo] = useState();
  const [PayrollStepStatus, setPayrollStepStatus] = useState([]);
  // Slider Functions
  const handlePrev = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
    }, []);

  const handleNext = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
    }, []);

  const { 
    isOpen : isAlertOpen , 
    onOpen : onAlertOpen, 
    onClose : onAlertClose 
  } = useDisclosure()

  const {
    isOpen: isReviewAllEmployeeOpen,
    onOpen: onReviewAllEmployeeOpen,
    onClose: onReviewAllEmployeeClose,
  } = useDisclosure();

  const {
    isOpen: isImportAttendanceCountOpen,
    onOpen: onImportAttendanceCountOpen,
    onClose: onImportAttendanceCountClose,
  } = useDisclosure();


  const {
    isOpen: isBulkSalarySlipOpen,
    onOpen: onBulkSalarySlipOpen,
    onClose: onBulkSalarySlipClose,
  } = useDisclosure();

  const {
    isOpen: isViewPayRegisterOpen,
    onOpen: onViewPayRegisterOpen,
    onClose: onViewPayRegisterClose,
  } = useDisclosure();

  const {
    isOpen: isManagePaySlipsOpen,
    onOpen: onManagePaySlipsOpen,
    onClose: onManagePaySlipsClose,
  } = useDisclosure();


  const {
    isOpen: isGenerateSalarySlipOpen,
    onOpen: onGenerateSalarySlipOpen,
    onClose: onGenerateSalarySlipClose,
  } = useDisclosure();


  const {
    isOpen: isLeaveAttOpen,
    onOpen: onLeaveAtttOpen,
    onClose: onLeaveAttClose,
  } = useDisclosure();


  const {
    isOpen: isNoAttActionOpen,
    onOpen: onNoAttActionOpen,
    onClose: onNoAttActionClose,
  } = useDisclosure();

  const {
    isOpen:  isMarkNoAttLeaveOpen,
    onOpen:  onMarkNoAttLeaveOpen,
    onClose: onMarkNoAttLeaveClose
  } = useDisclosure();

  const {
    isOpen: isNewJoineeOpen,
    onOpen: onNewJoineeOpen,
    onClose: onNewJoineeClose
  } = useDisclosure();

  const {
    isOpen: isOverrideOpen,
    onOpen: onOverrideOpen,
    onClose: onOverrideClose
  } = useDisclosure();

  const {
    isOpen: isBonusOpen,
    onOpen: onBonusOpen,
    onClose: onBonusClose
  } = useDisclosure();

  const {
    isOpen: isReimbursementOpen,
    onOpen: onReimbursementOpen,
    onClose: onReimbursementClose,
  } = useDisclosure();

  const {
    isOpen: isArrearsOpen,
    onOpen: onArrearsOpen,
    onClose: onArrearsClose,
  } = useDisclosure();

  const {
    nextStep: LeaveNext,
    prevStep: LeavePrev,
    reset: leavereset,
    activeStep: LeaveActiveStep,
  } = useSteps({ initialStep: 0 });

  const {
    nextStep: NewJoineeNext,
    prevStep: NewJoineePrev,
    reset: NewJoineeReset,
    activeStep: NewJoineeActiveStep,
  } = useSteps({ initialStep: 0 });

  const {
    nextStep: OverrideNext,
    prevStep: OverridePrev,
    reset: OverrideReset,
    activeStep: OverrideActiveStep,
  } = useSteps({ initialStep: 0 });

  const {
    nextStep: BonusNext,
    prevStep: BonusPrev,
    reset: BonusReset,
    activeStep: BonusActiveStep,
  } = useSteps({ initialStep: 0 });

  const {
    nextStep: ReimbursementNext,
    prevStep: ReimbursementPrev,
    reset: ReimbursementReset,
    activeStep: ReimbursementActiveStep,
  } = useSteps({ initialStep: 0 });


  const {
    nextStep: ArrearsNext,
    prevStep: ArrearsPrev,
    reset: ArrearsReset,
    activeStep: ArrearsActiveStep,
  } = useSteps({ initialStep: 0 });

  const [ImportedAttendanceCountData, setImportedAttendanceCountData] = useState([]);

  const [IsPayrollReviewed, setIsPayrollReviewed] = useState(false);

  const [IsPayrollMonthFinalized, setIsPayrollMonthFinalized] = useState(false);

  const [SelectedYear, setSelectedYear] = useState(new Date());

  const monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    
  const [PayrollMonthList, setPayrollMonthList] = useState([]);

  const [AllEmpData, setAllEmpData] = useState([]);
  const [empdata, setEmpData] = useState([]);
  const [componentList, setComponentList] = useState([]);



  const [PayrollDetailsOfMonth, setPayrollDetailsOfMonth] = useState([]);
  const [TotalPayrollCost, setTotalPayrollCost] = useState([]);

  const [EmpsReleasedPayslips, setEmpsReleasedPayslips] = useState([]);

  const [PayslipSelectedEmp, setPayslipSelectedEmp] = useState([]);
  console.log("Payslip Selected Emp",PayslipSelectedEmp);

  const [EmployeeSalaryReviewRowData, setEmployeeSalaryReviewRowData] = useState([]);
  console.log("Employee Salary Review",EmployeeSalaryReviewRowData);

  const [ReviewAllEmpSalaryColumnDefs, setReviewAllEmpSalaryColumnDefs] = useState([]);
  const [ManagePaySlipsColumnDefs, setManagePaySlipsColumnDefs] = useState([]);

  const [EmpSalarySlipData, setEmpSalarySlipData] = useState([]);
  const [EmpSalarySlipComponents, setEmpSalarySlipComponents] = useState([]);

  const [BranchLogo, setBranchLogo] = useState();

  const [SalaryOnHoldRowData,setSalaryOnHoldRowData] = useState([]);

  const [LeaveRowData, setLeaveRowData] = useState([]);
  const [NoAttendance, setNoAttendance] = useState([]);

  const [EditAttSelectedEmp, setEditAttSelectedEmp] = useState([]);
  const [LOPSummary, setLOPSummary] = useState([]);
        
  const [LeaveTypeList, setLeaveTypeList] = useState([]);
  const [TreatAsLeave, setTreatAsLeave] = useState();


  console.log("Leave Type List", LeaveTypeList);

  const [AllPayRegisterEmp, setAllPayRegisterEmp] = useState([]);

  const [PayrollAttendanceStatus, setPayrollAttendanceStatus] = useState();

  const [NewJoineeRowData, setNewJoineeRowData] = useState([]);
  const [AllResignationRowData, setAllResignationRowData] = useState([]);

  const [PFOverrideRowData, setPFOverrideRowData] = useState([]);
  const [ESIOverrideRowData, setESIOverrideRowData] = useState([]);
  const [TDSOverrideRowData, setTDSOverrideRowData] = useState([]);

  const [BonusRowData, setBonusRowData] = useState([]);
  const [SalaryRevisionRowData, setSalaryRevisionRowData] = useState([]);
  const [OvertimeRowData, setOvertimeRowData] = useState([]);

  const [AdhocPaymentRowData, setAdhocPaymentRowData] = useState([]);
  const [AdhocDeductionRowData, setAdhocDeductionRowData] = useState([]);

  const [ImpAttCountRowData, setImpAttCountRowData] = useState([]);
  const [ImportTotalAttendanceEmpData, setImportTotalAttendanceEmpData] = useState([]);

  const [NoAttSelectedDates, setNoAttSelectedDates] = useState([]);
  const [ AttendanceCountHeads ] = useState([
    { label: "empGuId", key: `empGuId`},
    { label: "empName", key: `empName`},
    { label: "empCode", key: `empCode`},
    { label: "totalPresent", key: `totalPresent`},
    { label: "totalLeaveBalance", key: `totalLeaveBalance`},
    { label: "payrollMonth", key: `payrollMonth`},
    { label: "payrollYear", key: `payrollYear`},
   ])

  const [ SalaryOnHoldColumnDefs ] = useState([
    {headerName : "Emp Code",field : "empCode", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    }},
    {headerName : "Emp Name",field : "empName"},
    {headerName : "Hold Month", field : "holdMonth"},
    {headerName : "Hold Amount", field : "holdAmount"},
    {headerName : "Remarks", field : "remarks"},
    {headerName : "Pay Action", field : "payAction"},
    {headerName : "Action", cellRenderer : (params) => <Button colorScheme="blue" size={'xs'}>Submit</Button>}
  ])

  const [ LeaveColumnDefs ] = useState([
    { headerName: "Employee Name", field: "empName" },
    { headerName: "Leave Date", field: "month" ,valueGetter: (params) => new Date(params.data.leaveFromDate).toLocaleDateString('en-GB') + " to " + new Date(params.data.leaveToDate).toLocaleDateString('en-GB') },
    { headerName: "Total Leaves", field: "leaveNoOfDays" },
    { headerName: "Leave Type", field: "leaveType" },
    {
      headerName: "Leave Status",
      field: "leaveStatus",
      floatingFilter: false,
      cellRenderer: (params) =>
      params.data.leaveStatus === "Pending" ? (
        <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Pending
        </span>
      ) : params.data.leaveStatus === "Approve" || params.data.leaveStatus === "Approved" ? (
          <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Approved
          </span>
        ) :  params.data.leaveStatus === "Reject" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Rejected
          </span>
        ) :params.data.leaveStatus === "Cancel" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
         Cancelled
        </span>
        ):
         (
          ""
        )
    },
  // { headerName: "Leave Status", field: "leaveStatus" },
    {
      headerName: "Action",
      field: "leaveStatus",
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending"? (
          <div className="space-x-4">
            <Button onClick={async()=>{
              await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Approved`
              ).then((response)=>{
                console.log("Leave Approve Response",response);
                  let res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: res,
                    position: "top",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                  });
                }
                getLeaveListOfMonth(new Date(params.data.leaveFromDate).getMonth()+1,new Date(params.data.leaveFromDate).getFullYear());
                console.log("Leave Action Month",new Date(params.data.leaveFromDate).getMonth()+1,new Date(params.data.leaveFromDate).getFullYear());
              })
            }} size="xs" colorScheme="green">
              <CheckCircle size="1rem" className="mr-1" />
              Approve
            </Button>
            <Button onClick={async()=>{
              await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Reject`
              ).then((response)=>{
                console.log("Leave Approve Response",response);
                  let res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: res,
                    position: "top",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                  });
                }
                getLeaveListOfMonth(new Date(params.data.leaveFromDate).getMonth()+1,new Date(params.data.leaveFromDate).getFullYear());

              })
            }}size="xs" colorScheme="red">
              <XCircle size="1rem" className="mr-1" />
              Reject
            </Button>
          </div>
        ):(" "),
    },
  ]);

    const [NoAttendanceColumnDefs] = useState([
      { headerName: "Employee Code", field: "empCode", comparator: (valueA, valueB) => {
        return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
      } },
    { headerName: "Employee Name", field: "empName" },
    { headerName: "Status", 
      field: "status",
      cellRenderer : (params) => <span className="bg-yellow-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      Pending
    </span>
    },
    { headerName: "Action", cellRenderer:(params) => {
      return <Button leftIcon={<Edit size={'14px'} />} onClick={()=>{
         setEditAttSelectedEmp(params.data);
         getLeaveTypeList(params.data.empGuid);
         onNoAttActionOpen();
        }} size='xs' colorScheme='blue'>Edit</Button>
    } },
  ]);

  const [LOPSummaryColumnDefs] = useState([
    { headerName: "Employee Name", field: "empName" },
    { headerName: "Employee Code", field: "empCode" },
    { headerName: "Actual LOP", field: "totalLOP" },
    { headerName: "Adjusted LOP", field: "totalLOP" },
    { headerName: "Final LOP", field: "finalLop" },
    // { headerName: "Remarks", field: "remark" ,editable:true, cellClass:'editable-grid-cell'},
    // { headerName: "Action",cellRenderer:(params)=>{
    //   return <Button size='sm' colorScheme='blue'>Save & Pay</Button>
    // } },
  ]);

  const [NewJoineeColumnDefs] = useState([
    { headerName: "Employee Name", field: "empName" },
    { headerName: "Employee Salary", field: "empSalary" },
    { headerName: "Date of Joining", field: "empDateOfJoining",valueGetter: (params) => {
      return new Date(params.data.empDateOfJoining).toLocaleDateString('en-GB')
    } },
    { headerName: "Pay Action", field: "payAction" , 
    cellClass:'editable-grid-cell' ,
    editable:true, singleClickEdit: true,
     cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
        values: ['Pay','Hold'],
    }
   },
    { headerName: "HR Comment", field: "comment",cellClass:'editable-grid-cell' , editable:true ,singleClickEdit: true,},
    { headerName: "Action",cellRenderer:(params)=>{
      return <Button 
      onClick={async()=>{
        let data = params.data;

        let body = {
            newJoiningId: data.newJoiningId,
            empGuId: data.empGuId,
            empName: data.empName,
            empDateOfJoining: data.empDateOfJoining,
            empSalary: data.empSalary,
            payAction: data.payAction,
            comment: data.comment,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch
          }
      
          console.log("New Joinee Save Body",body);
      
          await authApi
          .post(
            `${process.env.REACT_APP_ACQ_URL}/api/NewJoiningEmployee/SaveNewJoiningEmp`,
            body
          )
          .then((response) => {
            const res = response.data;
            console.log("New Joinee Save Response", res);
      
            if (!toast.isActive(id)) {
              toast({
                id,
                title: res,
                position: "top",
                status: "info",
                duration: 2000,
                isClosable: true,
              });
            }

            getNewJoineeListofMonth(PayrollMonth,PayrollYear);
      
          })
          .catch((err) => {
            console.error(err);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: "New Joinee not Updated.",
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });

    }} size='xs' colorScheme='blue'>Submit</Button>

    } },
  ]);

  const [AllResignationColumnDefs] = useState([
    { headerName: "Employee Name", field: "empName" },
    {
      headerName: "Separation Date",
      field: "resignationDate",
      valueGetter: (params) => {
        return new Date(params.data.resignationDate).toLocaleDateString(
          "en-GB"
        );
      },
    },
    {
      headerName: "Notice Status",
      field: "isNoticeStatus",
      width: 140,
      cellRenderer: (params) =>
        params.data.isNoticeStatus === true ? (
          <span>YES</span>
        ) : (
          <span>NO</span>
        ),
    },
    {
      headerName: "Last Working Day",
      field: "lastWorkingDate",
      valueGetter: (params) => {
        return new Date(params.data.lastWorkingDate).toLocaleDateString(
          "en-GB"
        );
      },
    },
    { headerName: "Exit Reason", field: "resignationReason" },
    { headerName: "Salary Status", field: "empSalaryStatus" },
    { headerName: "Seperation Status", field: "resignationStatus",
      cellRenderer:(params) => (
      params.data.resignationStatus === 'Pending' ?
      <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
        Pending
      </span> : params.data.resignationStatus === 'Approved' ? 
      <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
        Approved
      </span> :  params.data.resignationStatus === 'Rejected' ? 
      <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
      Rejected
      </span> : params.data.resignationStatus === 'Cancelled' ? 
      <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
      Cancelled
      </span> : ''
      )
   },
  ]);

  const [PFOverrideColumnDefs] = useState([
    { headerName: "Employee Name", field: "employeeName" },
    { headerName: "Employee Code", field: "empCode", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    { headerName: "PF Contribute value", field: "grossSalary" },
    { headerName: "Employee EPF Contribution", field: "employeeEPFContribution" },
    { headerName: "Employer EPF Contribution", field: "employerEPFContribution" },
    { headerName: "Employee Additional PF Contribution", field: "empAdditionalEPFContribution" },
  ]);

  const [ESIOverrideColumnDefs] = useState([
    { headerName: "Employee Name", field: "employeeName" },
    { headerName: "Employee Code", field: "empCode", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    { headerName: "Gross Salary", field: "grossSalary" },
    { headerName: "Employee ESI Contribution", field: "employeeESIContribution" },
    { headerName: "Employer ESI Contribution", field: "employerESIContribution" },
  ]);

  const [TDSOverrideColumnDefs] = useState([
    { headerName: "Employee Name", field: "empName" },
    { headerName: "Employee Code", field: "empCode", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    // { headerName: "Gross Salary", field: "grossSalary" },
    { headerName: "Total Deductions", field: "empTDSAmount" },
  ]);
  
  const [BonusColumnDefs] = useState([
    { headerName: "Employee Name", field: "employeeName" },
    { headerName: "Employee Code", field: "empCode", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    { headerName: "Bonus Name", field: "bonusName" },
    { headerName: "Bonus Amount", field: "bonusAmount" },
    { headerName: "Amount Payable", field: "amountPaid",singleClickEdit: true ,editable:true,cellClass:'editable-grid-cell'},

    { headerName: "Month", field: "month" },
    { headerName: "Year", field: "year" },
    { headerName: "Pay Action", field: "payAction",
      editable:true, singleClickEdit: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Pay','Hold'],
      } ,cellClass:'editable-grid-cell'
    },
    { headerName: "Remarks", field: "remark",singleClickEdit: true ,editable:true, cellClass:'editable-grid-cell'},
    // { headerName: "Pay Action", field: "payAction" },
    //     { headerName: "Pay Action", field: "payAction",cellRenderer:(params)=>        
    //     <Button onClick={()=>{HandleBonusPay(params.data)}} size='sm' colorScheme='blue'>Save & Pay</Button>
    //   },
  ]);

  const [SalaryRevisionColumnDefs] = useState([
    { headerName: "Employee Name", field: "empName" },
    { headerName: "Employee Code", field: "empCode", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    { headerName: "Old Salary", field: "oldCTC" },
    { headerName: "New Salary", field: "ctc" },
    { headerName: "Change %", field: "changePercent" },
    { headerName: "Gross Pay This Month", field: "grossSalary" },
    // { headerName: "Remarks", field: "remark" ,cellRenderer:()=>{
    //   return <Input borderRadius='8px' size='sm' textColor='black' bg='white'  borderColor='gray.400' rows={1} placeholder='Remarks' />
    // }},
    // { headerName: "Pay Action", field: "payAction",cellRenderer:()=>{
    //   return <Button size='sm' colorScheme='blue'>Pay</Button>
    // } },
  ]);



  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    let result = '';
  
    if (hours > 0) {
      result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }
  
    if (remainingMinutes > 0) {
      if (result.length > 0) {
        result += ' ';
      }
      result += `${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`;
    }
  
    return result;
  }

  const [OvertimeColumnDefs] = useState([
    { headerName: "Emp Code", field: "empCode", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    { headerName: "Emp Name", field: "empName" },
    { headerName: "Month", field: "month" },
    { headerName: "Year", field: "year" },
    { headerName: "Total Overtime", field: "finalOvertimeTime",
    cellRenderer:({data}) => data.finalOvertimeTime + " Hours" 
   },
    { headerName: "Per Hour Amount", field: "overtimeAmtPerHour",
    cellRenderer:({data}) => data.overtimeAmtPerHour + " /hr" 
   },
    { headerName: "Amount Payable", field: "totalOvertimeAmount" },
//  { headerName: "Pay Action", field: "payAction",
//     editable:true, singleClickEdit: true,
//     cellEditor: 'agSelectCellEditor',
//     cellEditorParams: {
//       values: ['Pay','Hold'],
//   } ,cellClass:'editable-grid-cell'
// },

  ]);


  const [AdhocPaymentColumnDefs] = useState([
    {
        headerName: "Employee Name",
        field: "employeeName",
      },

      {
          headerName: "Employee Code",
          field: "empCode", comparator: (valueA, valueB) => {
            return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
          }
          
        },
        {
          headerName: "Payment Name",
          field: "adhocPaymentName",
        },
        {
          headerName: "Amount",
          field: "adhocPaymentAmount",
          editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

        },

        {
          headerName: "Month",
          field: "month",
        },
        {
          headerName: "Pay Action",
          field: "payAction",
          editable:true, singleClickEdit: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['Pay','Hold'],
        },cellClass:'editable-grid-cell'
        },

        {
          headerName: "Remarks",
          field: "remark",
          editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'
        },

        {
            headerName: "Action",
            cellRenderer : (params) => <Button 
            onClick={async()=>{
                let AdhocData = params.data;

                let body={
                    payrollAdhocPaymentId : AdhocData.payrollAdhocPaymentId,
                    employeeName : AdhocData.employeeName,
                    empCode : AdhocData.empCode,
                    empGuId : AdhocData.empGuId,
                    adhocPaymentId : AdhocData.adhocPaymentId,
                    adhocPaymentAmount : AdhocData.adhocPaymentAmount,
                    remark : AdhocData.remark,
                    payAction : AdhocData.payAction,
                    month : AdhocData.month,
                    year : AdhocData.year,
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch,
                    isLock : true,
                  }


                console.log("Row Save Body",body)


                await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/SaveEmployeeAdhocPayment`,body
                )
                .then((response) => {
                  const res = response.data;
                  console.log("res",res);
              
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title:res,
                      position: 'top',
                      status: 'info',
                      duration: 2000,
                      isClosable: true,
                    })
                  }
              
                  getAdhocPaymentListofMonth(PayrollMonth,PayrollYear);

              
                })
                .catch((err) => {
                  console.log(err.response);
                  console.log(err.response.data);
              
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: 'ERROR',
                      description: "Payment not Updates.",
                      position: 'top',
                      status: 'error',
                      duration: 2000,
                      isClosable: true,
                    })
                  }
                });


            }}
             size={'xs'} colorScheme='blue'>Save</Button>
          },

  ]);

  const [AdhocDeductionColumnDefs] = useState([
    {
        headerName: "Employee Name",
        field: "employeeName",
      },

      {
          headerName: "Employee Code",
          field: "empCode", comparator: (valueA, valueB) => {
            return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
          }
        },
                      
      {
        headerName: "Deduction Name",
        field: "deductionName",
      },
        {
          headerName: "Amount",
          field: "deductionAmount",
          editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

        },

        {
          headerName: "Month",
          field: "month",
        },

        {
          headerName: "Pay Action",
          field: "payAction",
          editable:true, singleClickEdit: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['Pay','Hold'],
          } ,cellClass:'editable-grid-cell'
        },
        {
          headerName: "Remarks",
          field: "remark",
          editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'
        },
        {
            headerName: "Action",
            cellRenderer : (params) => <Button 
            onClick={async()=>{
                let AdhocData = params.data;

                let body={
                    payrollAdhocDeductionId : AdhocData.payrollAdhocDeductionId,
                    employeeName : AdhocData.employeeName,
                    empCode : AdhocData.empCode,
                    empGuId : AdhocData.empGuId,
                    deductionId : AdhocData.deductionId,
                    deductionAmount : AdhocData.deductionAmount,
                    remark : AdhocData.remark,
                    payAction : AdhocData.payAction,
                    month : AdhocData.month,
                    year : AdhocData.year,
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch,
                    isLock : true,
                  }

                console.log("Row Save Body",body)

                await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/SaveAdhocDeduction`,body
                )
                .then((response) => {
                  const res = response.data;
                  console.log("res",res);
              
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title:res,
                      position: 'top',
                      status: 'info',
                      duration: 2000,
                      isClosable: true,
                    })
                  }
              
                  getAdhocDeductionListofMonth(PayrollMonth,PayrollYear);

              
                })
                .catch((err) => {
                  console.log(err.response);
                  console.log(err.response.data);
              
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: 'ERROR',
                      description: "Deduction not Updates.",
                      position: 'top',
                      status: 'error',
                      duration: 2000,
                      isClosable: true,
                    })
                  }
                });

            }}
             size={'xs'} colorScheme='blue'>Save</Button>
          },

       ]);


      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        resizable: true
      }));

      const EmployeeReviewColDef = useMemo(() => ({
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        resizable: true
      }));

        // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);


  const onSelectionPayslipChanged = useCallback(() => {
    var PayslipEmpRow = PayslipGrid.current.api.getSelectedRows();
    console.log("Payslip Selection",PayslipEmpRow);
    setPayslipSelectedEmp(PayslipEmpRow);
  }, []);

  const onSelectionNoAttChanged = useCallback(() => {
    var NoAttDatesRow = NoAttRef.current.api.getSelectedRows();
    console.log("No Att Selection",NoAttDatesRow);
    setNoAttSelectedDates(NoAttDatesRow);
  }, []);



  useEffect(()=>{
    if (AllEmpData) {
      setImportTotalAttendanceEmpData(AllEmpData.map((data) => {
           return {
             empGuId : data.guId,
             empName : data.fullName,
             empCode : data.empCode,
             totalPresent : 0,
             totalLeaveBalance : 0,
             payrollMonth : Number(PayrollMonth || 0),
             payrollYear : Number(PayrollYear || 0)
           }
         }));
    }
    GetImportAttCountData(PayrollMonth,PayrollYear);
  },[AllEmpData, PayrollMonth, PayrollYear]);


  useEffect(()=>{
    if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
      onAlertOpen();
    }
  },[Validation, PayrollAttendanceStatus]);

  useEffect(()=>{
    if(Validation){
      setSelectedYear(new Date(Validation?.currentPayrollDate));
      GetPayrollSettingStatus(new Date(Validation?.currentPayrollDate).getFullYear());
    }
  },[Validation])

  useEffect(() => {
    getAllEmpData();
    getMasterStructure();
    GetBranchDetails();
  }, [])
      

      const getAllEmpData = async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("All Employees",response);
          setAllEmpData(response.data);
        } catch (err) {
          console.error(err);
        }
      };

      const getMasterStructure = async () => {
        await authApi
          .get(
            `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          .then((response) => {
             console.log("Branch Salary Structure Response", response);
    
             const res = response.data;
            HandleReviewAllEmployeeTableHeads(res.filter(data => data.isActive === true));
    
          }).catch((err) => {
            console.error(err);
          });
      };


      const getLeaveTypeList = async(EMPGUID) => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDashBoard/GetLeaveBalanceByEmpGuid?Empguid=${EMPGUID}&year=${PayrollYear}&month=${PayrollMonth}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Leave Balance List", response);
          setLeaveTypeList(response.data);
        } catch (err) {
          console.error(err);
        }
      }


      const GetPayrollSettingStatus = async (YEAR) => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PayrollConfiguration/GetPayrollConfiguration?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Payroll Configuration Setting",response);
          const res = response.data;
          setPayrollConfigData(response.data);

          if(res?.isAsPerLeave && userData?.countryName !== "India"){
            // SavePayrollSixStepStatus("1","3",true);
            SavePayrollSixStepStatus("3","3",true);
          }

          getPayrollMonthList(YEAR,response.data);
        } catch (err) {
          console.error(err);
        }
      }

    // Getting Payroll Month Slider List
    const getPayrollMonthList = async (YEAR,ConfigData) => {
      console.log("Year",YEAR,"Payroll Config Data",ConfigData);
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PayrollEstimate/GetPayrollMonthList?PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("Month List Response",response);
           const MonthRes = response.data;

          setPayrollMonthList(MonthRes);
          // let getCurrentMonth = MonthRes.filter((data)=>data.payrollStatus === "Current").map((mdata)=>{
          //   return mdata.payrollMonthNo
          // })[0]
          // console.log("Get Current Month",getCurrentMonth);
          // Find the first object with "Pending" status
          // const fetchPendingMonth2 = MonthRes.find(month => month.payrollStatus === 'Pending');
          // const selectedMonth = fetchPendingMonth2 || MonthRes[0];
          // const firstPendingMonth = MonthRes.find(month => month.payrollStatus === 'Pending' || month.payrollStatus === 'Current');


          const firstPendingMonth =  MonthRes.find((month) => {
            const monthStartDate = new Date(Number(month.payrollYear),Number(month.payrollMonthNo)-1, 1);
           return  (month.payrollStatus === 'Pending' || month.payrollStatus === 'Current') && (monthStartDate >= new Date(ConfigData.payrollStartFrom))
          })

          console.log("First Pending",firstPendingMonth);

          const lastCompletedMonth = MonthRes.filter(month => month.payrollStatus === 'Complete').slice(-1)[0];
          console.log("Last Completed Month",lastCompletedMonth);

          let getCurrentMonth = firstPendingMonth?.payrollMonthNo || lastCompletedMonth?.payrollMonthNo || MonthRes[0]?.payrollMonthNo;
          console.log("Get Current Month",getCurrentMonth);

           sliderRef.current.swiper.slideTo(Number(getCurrentMonth-1))
           setPendingMonthNo(getCurrentMonth);

           setPayrollMonth(getCurrentMonth);
           setPayrollYear(YEAR);

           getPayrollDetailsOfMonth(getCurrentMonth,YEAR);
           GetTotalPayrollCostOfMonth(getCurrentMonth,YEAR);
           GetFinalizePayrollStatus(getCurrentMonth,YEAR);
           GetPayrollStepMonthStatus(getCurrentMonth,YEAR);
           GetPayRegisterEmployee(getCurrentMonth,YEAR);
           GetPayrollAttendanceStatus(getCurrentMonth,YEAR);
          

        } catch (err) {
          console.error(err);
        }
      };





          // Handle Slider Month Selection
          const handleMonthChange = async (Clickedmonth,ClickedYear) => {
            // console.log("Clicked Month",Clickedmonth,"Clicked Year",ClickedYear);
            setPayrollMonth(Clickedmonth);
            setPayrollYear(ClickedYear);
    
            GetPayrollSettingStatus();
    
            // Section 2 Functions
            getPayrollDetailsOfMonth(Clickedmonth,ClickedYear);
            GetTotalPayrollCostOfMonth(Clickedmonth,ClickedYear);
            GetFinalizePayrollStatus(Clickedmonth,ClickedYear);
            GetPayrollStepMonthStatus(Clickedmonth,ClickedYear);
            GetPayRegisterEmployee(Clickedmonth,ClickedYear);
            GetPayrollAttendanceStatus(Clickedmonth,ClickedYear);

         };


         const GetPayrollStepMonthStatus = async (StatusMonth,StatusYear) => {
          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/GetSixStepPayrollData?PayrollMonth=${StatusMonth}&PayrollYear=${StatusYear}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Payroll Step Status",response);
            setPayrollStepStatus(response.data)
          } catch (err) {
            console.error(err)
          }
        }


        const GetPayrollAttendanceStatus = async(MONTH,YEAR) => {
          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetPayrollAttendanceStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&PayrollMonth=${MONTH}&PayrollYear=${YEAR}`
            )
            console.log("Payroll Attednance Res",response);
            setPayrollAttendanceStatus(response.data)
          } catch (err) {
            console.error(err);
          }
        }

        const GetPayRegisterEmployee = async (MONTH,YEAR) => {
          try{
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetEmployeePRList?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Pay Register response",response);
            setAllPayRegisterEmp(response?.data?.filter(data => data.guId !== null));
          } catch (err) {
            console.error(err);
          }
        }

        console.log("All Pay Register Emps",AllPayRegisterEmp);
         
     const GetFinalizePayrollStatus = async (MONTH,YEAR) => {
        try {
         const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PayrollFinalize/GetPayrollFinalStatus?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Payroll Month Finalized Status Month",MONTH,"Status",response)
          setIsPayrollMonthFinalized(response.data);
        } catch (err) {
          console.error(err);
        }
      }


      const getPayrollDetailsOfMonth = async (MONTH,YEAR) => {
        console.log("Payroll Details MONTH",MONTH,"YEAR",YEAR);
        setLoadingStatus(prevStatus => ({
          ...prevStatus,
          payrollMonthDataLoading: true
        }));
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetPayrollMonthReviewData?PayrollMonth=${MONTH}&&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("Payroll Details of Month Response",response.data);
           setPayrollDetailsOfMonth(response.data);
        } catch (err) {
          console.error(err);
        } finally{
          setLoadingStatus(prevStatus => ({
            ...prevStatus,
            payrollMonthDataLoading: false
          }));
        }
      };
           
      const GetTotalPayrollCostOfMonth = async (MONTH,YEAR) => {
        // console.log("Total Payroll Cost MONTH",MONTH,"YEAR",YEAR);
        setLoadingStatus(prevStatus => ({
          ...prevStatus,
          payrollCostLoading: true
        }));
        try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetPayrollSalaryReview?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Total Payroll Cost Response",response);
            setTotalPayrollCost(response.data)
           } catch (err) {
            console.error(err);
           }finally{
            setLoadingStatus(prevStatus => ({
              ...prevStatus,
              payrollCostLoading: false
            }));
           }
      }

  const getLeaveListOfMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      leaveDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/GetPayrollLeaveDataByMonth?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log(response);
       console.log("Leave List of Month", response);
       const res = response.data;
      setLeaveRowData(res.filter(data => data.leaveStatus === "Pending"));
    } catch (err) {
      console.error(err);
    }finally{
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        leaveDataLoading: false
      }));
     }
  };


  console.log("Modified No Attendance List",NoAttendance);


  
  const getNoAttendanceList = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      NoAttDataLoading: true
    }));
    try {
     const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/GetNoAttendanceMonth?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
     console.log("No Attendance List of Month",response);

     const res = response.data;

     let AllAttData = response.data;

     setNoAttendance(_.uniqBy(res, 'empGuid')?.map((emp) => {
      return {
        empGuid: emp.empGuid,
        empName: emp.empName,
        empCode: emp.empCode,
        noAttList: AllAttData?.filter(allemp => allemp.empGuid === emp.empGuid)
      }
     }))
    } catch (err) {
     console.error(err);
    }finally{
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        NoAttDataLoading: false
      }));
    }
   }

   const getLOPSummaryList = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      LOPSummaryDataLoading: true
    }));
    try {
     const response = await authApi.get(
       `${process.env.REACT_APP_ACQ_URL}/api/LOPAdjustment/GetLOPAdjustmentList?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
     )
     console.log("LOP Summary List of Month",response);
     setLOPSummary(response.data);
    } catch (err) {
     console.error(err);
    }finally{
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        LOPSummaryDataLoading: false
      }));
    }
   }

   const getNewJoineeListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      NewJoineeDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/NewJoiningEmployee/GetNewJoiningEmpList?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log(response);
      console.log("New Joinee List of Month", response);
      const res = response.data;
      setNewJoineeRowData(res)
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        NewJoineeDataLoading: false
      }));
    }
  };

   const getResignationListofMonth = async (MONTH,YEAR) =>{
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      SeparationDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpResignationByMonthYear?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&ResignMonth=${MONTH}&ResignYear=${YEAR}`
      );
      //  console.log(response);
      console.log("Resignation List of Month", response.data);
      const AllResignation = response.data;

      let FinalizedResignations = AllResignation.filter((data)=> data.resignationStatus === "Finalized" )
      console.log("Finalized Resignations",FinalizedResignations);
      setAllResignationRowData(AllResignation)
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        SeparationDataLoading: false
      }));
    }
  };

  const getPfOverrideListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      PfDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/ESI_PF/GetPFDatabySalaryReview?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Month=${MONTH}&year=${YEAR}`
      );
      //  console.log(response);
      console.log("PF Override List of Month", response.data);
      setPFOverrideRowData(response.data)
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        PfDataLoading: false
      }));
    }
  };

  const getESIOverrideListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      EsiDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/ESI_PF/GetESIDatabySalaryReview?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Month=${MONTH}&year=${YEAR}`
      );
      //  console.log(response);
      console.log("ESI Override List of Month", response.data);
      setESIOverrideRowData(response.data)
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        EsiDataLoading: false
      }));
    }
  };

  const getTDSOverrideListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      TdsDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollTDSMonthData/GetPayrollTDSMonthDataList?Month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log(response);
      console.log("TDS Override List of Month", response.data);
      setTDSOverrideRowData(response.data)
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        TdsDataLoading: false
      }));
    }
  };

  const getBonusListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      BonusDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Bonus/GetBonusDataList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
      );
      // console.log(response);
       console.log("Bonus List of Month", response.data);

      setBonusRowData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        BonusDataLoading: false
      }));
    }
  };

  const getOvertimeListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      OvertimeDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetPayrollOvertimeDataTotalEmp?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}&Islock=true&IsStatus=1`
      );
      // console.log(response);
      console.log("Overtime List of Month", response);

      setOvertimeRowData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        OvertimeDataLoading: false
      }));
    }
  };


  const getSalaryRevisionList = async () => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      SalaryRevDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/GetEmployeeSalaryDetails?CompanyId=${userData.isActiveBranch}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Salary Revision List Response",response);
      setSalaryRevisionRowData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        SalaryRevDataLoading: false
      }));
    }
  }


  const getAdhocPaymentListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      AdhocPayDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/GetAdhocpaymentlist?month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log(response);
      console.log("Adhoc Payment List of Month", response.data);
      setAdhocPaymentRowData(response.data);

    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        AdhocPayDataLoading: false
      }));
    }
  };


  const getAdhocDeductionListofMonth = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      AdhocDedDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/GetAdhocDeductionlist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
      );
      // console.log(response);
      console.log("Adhoc Deduction List of Month", response.data);
      setAdhocDeductionRowData(response.data)

    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        AdhocDedDataLoading: false
      }));
    }
  };


  const GetSalaryOnHold = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      HoldSalaryDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetPayrollSalaryonHold?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Salaries on Hold Response",response);
      setSalaryOnHoldRowData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        HoldSalaryDataLoading: false
      }));
    }
  }

  const GetImportAttCountData = async (MONTH,YEAR) => {
    setLoadingStatus(prevStatus => ({
      ...prevStatus,
      ImportAttDataLoading: true
    }));
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetClientPayrollAttendance?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Import Att Count Response",response);
      setImpAttCountRowData(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingStatus(prevStatus => ({
        ...prevStatus,
        ImportAttDataLoading: false
      }));
    }
  }

      const HandleReviewAllEmployeeTableHeads = (branchComponents)=>{

        let StartingHeaders = userData.countryName === "India" ? [
          { headerName: "Employee Name", field: "empName", pinned: 'left',
           cellRenderer:(params) => <span className="align-middle">{params.data.isLock ? <LuLock className="inline-block text-red-600"/> : <LuUnlock className="inline-block text-blue-600"/> }  {params.data.empName}</span>
          },
          { headerName: "Employee Code", field: "empCode", pinned: 'left', comparator: (valueA, valueB) => {
            return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
          } },
          { headerName: "Designation", field: "designationName" },
          { headerName: "Department", field: "departmentName" },
          { headerName: "Salary Month", field: "payrollMonth" },
          { headerName: "Salary Year", field: "payrollYear" },
          { headerName: "Payable Days", field: "actualPayableDays" }
        ] : [
          { headerName: "Employee Name", field: "empName", pinned: 'left',
           cellRenderer:(params) => <span className="align-middle">{params.data.isLock ? <LuLock className="inline-block text-red-600"/> : <LuUnlock className="inline-block text-blue-600"/> }  {params.data.empName}</span>
          },
          { headerName: "Employee Code", field: "empCode", pinned: 'left', comparator: (valueA, valueB) => {
            return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
          } },
          { headerName: "Designation", field: "designationName" },
          { headerName: "Department", field: "departmentName" },
          { headerName: "Grade", field: "grade" },
          { headerName: "Division", field: "division" },
          { headerName: "Salary Month", field: "payrollMonth" },
          { headerName: "Salary Year", field: "payrollYear" },
          { headerName: "Payable Days", field: "actualPayableDays" }
        ];
    
    
        let PaySlipStartingHeaders = userData.countryName === "India" ? [
          { headerName: "Employee Name", field: "empName", pinned: 'left', 
            headerCheckboxSelection: true, checkboxSelection: true 
          },
          { headerName: "Employee Code", field: "empCode", pinned: 'left', comparator: (valueA, valueB) => {
            return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
          } },
          { headerName: "Designation", field: "designationName" },
          { headerName: "Department", field: "departmentName" },
          { headerName: "Salary Month", field: "payrollMonth" },
          { headerName: "Salary Year", field: "payrollYear" },
          { headerName: "Payable Days", field: "actualPayableDays" }
        ] : [
          { headerName: "Employee Name", field: "empName", pinned: 'left', 
            headerCheckboxSelection: true, checkboxSelection: true 
          },
          { headerName: "Employee Code", field: "empCode", pinned: 'left', comparator: (valueA, valueB) => {
            return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
          } },
          { headerName: "Designation", field: "designationName" },
          { headerName: "Department", field: "departmentName" },
          { headerName: "Grade", field: "grade" },
          { headerName: "Division", field: "division" },
          { headerName: "Salary Month", field: "payrollMonth" },
          { headerName: "Salary Year", field: "payrollYear" },
          { headerName: "Payable Days", field: "actualPayableDays" }
        ] 
    
        let MiddleHeaders = branchComponents.map(obj=>{
          return { 
            headerName: obj.salarySlipLabel, 
            field: obj.branchSalaryStructureMasterId + 'salaryValue',
          }
        })
    
    
        let OtherEndHeads = [
          { headerName: "Adhoc Payments", field: `adhocPayment` },
          { headerName: "Gross", field: `gross` },
          { headerName: "Adhoc Deductions", field: `adhocDeduction` },
          { headerName: "Net Payable Salary", field: `netPayableSalary` }
        ]


        let IndiaEndHeads = [
            { headerName: "EPF_EE", field: "pF_Employee" },
            { headerName: "EPF_ER", field: "pF_Employer" },
            // { headerName: "Additional EPF", field: "empAdditionalEPFContribution" },
            { headerName: "ESI_EE", field: "esI_Employee" },
            { headerName: "ESI_ER", field: "esI_Employer" },
            { headerName: "Adhoc Payments", field: `adhocPayment` },
            { headerName: "Gross", field: `gross` },
            { headerName: "Adhoc Deductions", field: `adhocDeduction` },
            { headerName: "TDS", field: `empTDSAmount` },
            { headerName: "Net Payable Salary", field: `netPayableSalary` }
          ]
    
    
            let OtherPayslipsEndHeads = [
          { headerName: "Adhoc Payments", field: `adhocPayment` },
          { headerName: "Gross", field: `gross` },
          { headerName: "Adhoc Deductions", field: `adhocDeduction` },
          { headerName: "Net Payable Salary", field: `netPayableSalary` },
          { headerName: "Action", width:145, pinned: 'right', cellRenderer:(params) =>{
            return <Button size={'xs'} onClick={()=>{handleGeneratePayslip(params.data.empGuid,params.data.payrollMonth,params.data.payrollYear)}} colorScheme='purple'>View PaySlip</Button>
          }}
        ]


        let IndiaPayslipsEndHeads = [
            { headerName: "EPF_EE", field: "pF_Employee" },
            { headerName: "EPF_ER", field: "pF_Employer" },
        //   { headerName: "Additional EPF", field: "empAdditionalEPFContribution" },
            { headerName: "ESI_EE", field: "esI_Employee" },
            { headerName: "ESI_ER", field: "esI_Employer" },
            { headerName: "Adhoc Payments", field: `adhocPayment` },
            { headerName: "Gross", field: `gross`},
            { headerName: "Adhoc Deductions", field: `adhocDeduction` },
            { headerName: "TDS", field: "empTDSAmount" },
            { headerName: "Net Payable Salary", field: `netPayableSalary` },
            { headerName: "Action",width:145,pinned: 'right', cellRenderer:(params) =>{
              return <Button size={'xs'} onClick={()=>{handleGeneratePayslip(params.data.empGuid,params.data.payrollMonth,params.data.payrollYear)}} colorScheme='purple'>View PaySlip</Button>
            }}
          ]
    
        if(userData.countryName === "India"){
            setReviewAllEmpSalaryColumnDefs(_.union(StartingHeaders,MiddleHeaders,IndiaEndHeads))
        }else{
            setReviewAllEmpSalaryColumnDefs(_.union(StartingHeaders,MiddleHeaders,OtherEndHeads))
        }

        if(userData.countryName === "India"){
            setManagePaySlipsColumnDefs(_.union(PaySlipStartingHeaders,MiddleHeaders,IndiaPayslipsEndHeads))
        }else{
            setManagePaySlipsColumnDefs(_.union(PaySlipStartingHeaders,MiddleHeaders,OtherPayslipsEndHeads))
        }
            
    
    
      }


      const HandleReviewRunPayroll = async (MONTH,YEAR) => {
        // console.log("Total Payroll Cost MONTH",MONTH,"YEAR",YEAR);
        try {
          Promise.all(
            AllEmpData.filter(obj => {
              const date = new Date(obj.dateOfJoin);
              const month = date.getMonth()+1;
              const year = date.getFullYear();
              console.log("Filtered DOJ",date,month,year,PayrollYear,PayrollMonth);
              if(year > Number(PayrollYear) || (year === Number(PayrollYear) && month > Number(PayrollMonth)) || obj.isSalaryStructureCompleted !== 2){
                return false;
              }
              return true;
            }).map(
              async (data) => {

                let body = {
                    payroll_Month : Number(PayrollMonth),
                    payroll_Year : Number(PayrollYear),
                    empGuid : data.guId,
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch
                  }
                  console.log("Prorated Update Body",body);
      
                const response = await authApi.post(
                   `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/ProratedUpdateRule`,body
                )
                 console.log("Prorated update Response",response);                
              }
              
            )
          );
        } catch (err) {
          console.error("Prorated update Error",err);
        }

        try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetPayrollSalaryReview?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Review Run Payroll Response",response);
            setTotalPayrollCost(response.data);

            if (!toast.isActive(id)) {
                toast({
                  id,
                  title: `Refereshed`,
                  description: `Payroll Cost Refereshed`,
                  position: "top",
                  status: "success",
                  duration: 2000,
                  isClosable: true
                })
              }

              setIsPayrollReviewed(true);
           } catch (err) {
            console.error(err);
           }finally{
            // GetTotalPayrollCostOfMonth(MONTH,YEAR);
            setIsReviewPayrollLoading(false);
           }

      }


     useEffect(() => {
        setEmployeeSalaryReviewRowData(_.flatten(componentList));
      }, [componentList])



     const HandleReviewAllEmployee = async(MONTH,YEAR,MODALID) => {
        console.log("Handle Review All Employee MONTH",MONTH,"YEAR",YEAR,AllEmpData);
        setComponentList([]);
        Promise.all(
            AllEmpData.filter(obj => {
              const date = new Date(obj.dateOfJoin);
              const month = date.getMonth()+1;
              const year = date.getFullYear();
              // console.log("Filtered DOJ",date,month,year,PayrollYear,PayrollMonth);
              if(year > Number(PayrollYear) || (year === Number(PayrollYear) && month > Number(PayrollMonth)) || obj.isSalaryStructureCompleted !== 2){
                return false;
              }
              return true;
            }).map(
              async (data) => {
      
                const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetEmpMonthSalaryReportByEmpGuid?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&EmpGuid=${data.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
      
                 console.log("Emp Salary Response",response);
                 const Empdata = response.data;
                 // console.log("EmpData",Empdata);
      
                 return setComponentList((prev) => {

                 let componentData = Empdata.empSalaryData || [];
                 let FinalRowdata = componentData.map((data)=>{
                 let rowData = [];
                  rowData.push({[data.branchSalaryStructureMasterId + 'salaryValue']: data.salaryValue});
                    return Object.assign(Empdata,...rowData)
                  })
      
                  let UniqData = _.uniqBy(FinalRowdata,'empGuid')

                  return [...prev,UniqData];
                });
      
              }
              
            )
          );


          if (MODALID === 'Review') {
            onReviewAllEmployeeOpen();
          }

          // if (MODALID === 'PayRegister') {
          //   onViewPayRegisterOpen();
          // }
          // if (MODALID === 'PaySlip') {
          //   onManagePaySlipsOpen();
          // }

     }


     

     const HandlePayRegisterEmps = async (MONTH,YEAR,MODALID)  => {

      setComponentList([]);

      Promise.all(
        AllPayRegisterEmp.map(
          async (data) => {
  
            const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetEmpMonthSalaryReportByEmpGuid?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&EmpGuid=${data.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
  
            console.log("Emp Salary Response",response);
            const Empdata = response.data;
             // console.log("EmpData",Empdata);
  
             return setComponentList((prev) => {

             let componentData = Empdata.empSalaryData || [];
             let FinalRowdata = componentData.map((data)=>{
             let rowData = [];
              rowData.push({[data.branchSalaryStructureMasterId+'salaryValue']: data.salaryValue});
                return Object.assign(Empdata,...rowData)
              })
  
              let UniqData = _.uniqBy(FinalRowdata,'empGuid')

              return [...prev,UniqData];
            });
  
          }
          
        )
      );


          if (MODALID === 'PayRegister') {
            onViewPayRegisterOpen();
          }

          if (MODALID === 'PaySlip') {
            onManagePaySlipsOpen();
          }


     }

     const GetBranchDetails= async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/GetBranchMasterById?branchId=${userData.isActiveBranch}`
          );
          console.log("Branch Details", response.data);
          const res = response.data;
        
          handleDownloadFile(res.logoPath)
        } catch (err) {
          console.error(err);
        }
      }
    
    
      const handleDownloadFile = async (DocPath) =>{
        console.log("Document path",DocPath)
       await fetch(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
      ).then(response => {
    
        console.log("file Response",response);
    
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
    
            // Setting various property values
    
            let alink = document.createElement('a');
            alink.href = fileURL;
            //  alink.download = fileURL;
             setBranchLogo(alink.href)
    
             // alink.click();
        })
    
      }).catch((err)=>{
        console.log("File Api Error",err);
      })
      }
    



      const SaveEditAttendance = async (MarkAction,QuotaId) => {

        let body = NoAttSelectedDates?.map((data) => {
          return {
            empGuid: data.empGuid,
            empCode: data.empCode,
            aDate: moment(data.attendanceDate).format('YYYY-MM-DD'),
            leaveTypeId: MarkAction === "Leave" ? QuotaId : 0,
            isLop:  MarkAction === "LOP" ? true : false,
            isPresent: MarkAction === "Present" ? true : false,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch
          }
        })

        console.log("Edit Att body", body);

        const response = await PostNoAttData(body);
        if(response){
          getNoAttendanceList(PayrollMonth,PayrollYear);
          setEditAttSelectedEmp([]);
          setNoAttSelectedDates([]);
          onNoAttActionClose();
        }
    
      }




       const HandleFinalizePayroll = async () => {

        if (window.confirm('Are you sure you want to Finalize Payroll of this Month? By Finalizing Payroll You Will Not able to Perform Any Action for this Month!')) {
        try {

            const response = await authApi.get(
             `${process.env.REACT_APP_ACQ_URL}/api/PayrollFinalize/FinalizePayrollAndLock?PayrollMonth=${PayrollMonth}&PayrollYear=${PayrollYear}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Finalize Payroll",response);
            GetFinalizePayrollStatus(PayrollMonth,PayrollYear);


            if(response.data === true){
              if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: `Payroll Finalized`,
                    description: `Payroll Finalized and Locked Successfully `,
                    position: "top",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  })
                }
            }else{
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: response.data,
                  position: "top",
                  status: "info",
                  duration: 2000,
                  isClosable: true,
                })
              }
            }

            // getPayrollMonthList(PayrollYear);
            
        } catch (err) {
            console.error(err);
        }finally {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PayrollEstimate/GetPayrollMonthList?PayrollYear=${PayrollYear}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("Month List Response",response);
          const MonthRes = response.data;
          setPayrollMonthList(response.data);
        }

        }
       }


       const HandleLeaveModalSteps = async() =>{

        if (LeaveActiveStep === 0) {
          if(LeaveRowData.length === 0){
            SavePayrollSixStepStatus("1","1");
            getNoAttendanceList(PayrollMonth,PayrollYear);
            LeaveNext();
          }else {
            alert('Please Complete the Pending Actions.');
          }
        }

        if (LeaveActiveStep === 1) {          
          if(NoAttendance.length === 0) {
            SavePayrollSixStepStatus("1","2");
            getLOPSummaryList(PayrollMonth,PayrollYear);
            LeaveNext();
          }else {
            alert('Please Complete the Pending Actions.');
          }
        }

        if (LeaveActiveStep === 2) {
          SavePayrollSixStepStatus("1","3");
          onLeaveAttClose();
        }

       }

       const NewJoineesModalSteps = async () => {
 
        if(NewJoineeActiveStep === 0){

          

          if(window.confirm('Are you sure you want to continue?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: 1,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);
              getResignationListofMonth(PayrollMonth,PayrollYear);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              NewJoineeNext();
            } catch (err) {
              console.error(err);
            }
          }

        }

        if(NewJoineeActiveStep === 1){

          if(window.confirm('Are you sure you want to Finalize this step?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: 2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              onNewJoineeClose();
            } catch (err) {
              console.error(err);
            }
          }

        }
       }

       const OverrideModalSteps = async () => {

        if(OverrideActiveStep === 0 ){

          if(window.confirm('Are you sure you want to continue?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: 1,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);
              getESIOverrideListofMonth(PayrollMonth,PayrollYear);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              OverrideNext();
            } catch (err) {
              console.error(err);
            }
          }

        }

        if(OverrideActiveStep === 1 ){


          if(window.confirm('Are you sure you want to continue?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: 2,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);
              getTDSOverrideListofMonth(PayrollMonth,PayrollYear);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              OverrideNext();
            } catch (err) {
              console.error(err);
            }
          }


        }

        if(OverrideActiveStep === 2 ){

          if(window.confirm('Are you sure you want to Finalize this step?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: 3,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              onOverrideClose();
            } catch (err) {
              console.error(err);
            }
          }

        }

       }

       const BonusModalSteps = async () => {

        if(BonusActiveStep === 0 ){        

          if(window.confirm('Are you sure you want to continue?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: 1,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);

              getSalaryRevisionList();
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              BonusNext();

            } catch (err) {
              console.error(err);
            }
          }

        }

        if(BonusActiveStep === 1 ){

          if(window.confirm('Are you sure you want to continue?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: 2,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);

              getOvertimeListofMonth(PayrollMonth,PayrollYear);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              BonusNext();

            } catch (err) {
              console.error(err);
            }
          }

        }

        if(BonusActiveStep === 2 ){

          if(window.confirm('Are you sure you want to Finalize this step?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: 3,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);

              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              onBonusClose();
            } catch (err) {
              console.error(err);
            }
          }


        }

       }

       const AdhocModalSteps = async () => {

        if(ReimbursementActiveStep === 0){

          if(window.confirm('Are you sure you want to continue?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: 1,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);
              getAdhocDeductionListofMonth(PayrollMonth,PayrollYear);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              ReimbursementNext();

            } catch (err) {
              console.error(err);
            }
          }


        }

        if(ReimbursementActiveStep === 1){        

          if(window.confirm('Are you sure you want to Finalize this step?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: 2,
                payrollStep6: PayrollStepStatus.payrollStep6,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);
              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              onReimbursementClose();

            } catch (err) {
              console.error(err);
            }
          }

        }

       }

       const ArrearModalSteps = async () => {

        if(ArrearsActiveStep === 0){
        
          if(window.confirm('Are you sure you want to continue?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: 1,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);

              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              ArrearsNext();

            } catch (err) {
              console.error(err);
            }
          }

        }

        if(ArrearsActiveStep === 1){

          if(window.confirm('Are you sure you want to Finalize this step?')){
            try {
              let body = {
                payrollStep1: PayrollStepStatus.payrollStep1,
                payrollStep2: PayrollStepStatus.payrollStep2,
                payrollStep3: PayrollStepStatus.payrollStep3,
                payrollStep4: PayrollStepStatus.payrollStep4,
                payrollStep5: PayrollStepStatus.payrollStep5,
                payrollStep6: 2,
                payrollMonth: PayrollMonth,
                payrollYear: PayrollYear,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch,
                isLock: PayrollStepStatus.isLock,
                confirm: PayrollStepStatus.confirm
              }
            
              const response = await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
              )
              console.log("Step Save Response",response);

              GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
              onArrearsClose()

            } catch (err) {
              console.error(err);
            }
          }
        }

       }


     const handleGeneratePayslip = async (empGuid,MONTH,YEAR) => {
       // console.log(empGuid);
        try {
         const response = await authApi.get(
           `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeDetailByEmpGuid?EmpGuid=${empGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
         );
         // console.log("Response ",response);
         const Emp = response.data;
          console.log("Selected Emp  General Data",response);
         setEmpData(Emp);
         // console.log("empdata", empdata);
       } catch (err) {
         console.error(err);
       }
   
       try {
         const response = await authApi.get(
           `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GeneratePayslipByGuid?EmpGuid=${empGuid}&PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
         );
          console.log("Payslip data Response ",response);
         const EmpSalaryData = response.data;
          setEmpSalarySlipData(EmpSalaryData);
          setEmpSalarySlipComponents(response.data.salarySlipComponents)
         // console.log("empdata " , empdata);
       } catch (err) {
         console.error(err);
       }
   
       if(userData.countryName === "India"){
        IndiaSalarySlip();
       }else{
         if(userData.activeBranchName === "G42" || userData.activeBranchName === "G42 XFUND" || userData.activeBranchName === "VR OpCo Ltd." || userData.activeBranchName === "DFOAD RSC LTD"){
           G42SalarySlip();
         }

         if(userData.companyName === "Ethmar Holdings"){
          EthmarPrivateSalarySlip();
         }else{
          SharjahResearchSalarySlip();
         }
       }
   
       onGenerateSalarySlipOpen();
     }


     const SharjahResearchSalarySlip =  () => {
      return  <Document onRender={async({blob})=>{
  
        console.log("Save Pdf Emp Data Sharjah",EmpSalarySlipData);
  
  
        const SalarySlipData = new FormData();
        SalarySlipData.append('file', blob, `${EmpSalarySlipData.employeeName}.pdf`);
        SalarySlipData.append('empGuId', EmpSalarySlipData.empGuid);
        SalarySlipData.append('empName', EmpSalarySlipData.employeeName);
        SalarySlipData.append('month', EmpSalarySlipData.salaryMonth);
        SalarySlipData.append('year', PayrollYear);
        SalarySlipData.append('companyId', userData.companyId);
        SalarySlipData.append('branchId', userData.isActiveBranch);
  
  
        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/SaveEmpMonthlySalarySlip`,SalarySlipData,{
            headers: {'Content-Type': 'application/pdf' }
        }
          )
          .then((response) => {
            const res = response.data;
            console.log("PDF Save Response", response);
  
            if (!toast.isActive(id)) {
  
            toast({
              id,
  
              title: res,
              description: `We've Saved ${EmpSalarySlipData.employeeName} Payslip.`,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
      
          })
          .catch((err) => {
            console.error(err);
            console.log(err.response.data);
  
            if (!toast.isActive(id)) {
  
            toast({
              id,
  
              title: "ERROR", 
              description: `${EmpSalarySlipData.employeeName} Payslip Not Saved.`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
           }
  
          });
  
      }}>
     <Page size="A4"  style={styles.page}>
           
           {/* Company Details Header */}
      <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
        <View style={{width:'50%'}}>
          {BranchLogo !== undefined ? 
         <Image style={{width:110}} src={BranchLogo} /> 
         : <Text>Logo</Text>}
        </View>
        <View style={{width:'40%'}}>
             <Text style={{marginBottom:2,fontSize:13,fontWeight:'extrabold',fontFamily:'Helvetica-Bold'}}>{userData.activeBranchName}</Text>
             <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
             {/* <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.stateName}</Text> */}
         </View>
       </View>
  
       {/* Employee Details */}
  
       <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
         <View style={{width:'60%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Code : {EmpSalarySlipData.empCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10}}>Date of Joining : {EmpSalarySlipData.dateofjoin }</Text>
         <Text style={{marginBottom:'5px',fontSize:10}}>Present Days : {EmpSalarySlipData.totalPresent }</Text>
         <Text style={{fontSize:10}}>Leave Balance : {EmpSalarySlipData.totalLeaveBalance}</Text>
         </View>
  
         <View style={{width:'40%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {empdata.designationName}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
         <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {PayrollYear}</Text>
         {/* <Text style={{fontSize:10}}>Date of Joining : {EmpSalarySlipData.dateofjoin }</Text> */}
         </View>
       </View>
  
  
       {/* Salary Component Details Table */}
  
  
       <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>
  
       <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>
  
        </View>
  
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
          {EmpSalarySlipComponents.filter((data) => data.componentType === 1).map((data,i)=>{
            return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:9,width:'30%'}}>{data.salarySlipLabel}</Text>
              <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>
  
              <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
              </View>
          })}
  
         </View>
  
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>
  
        </View>
  
  
         </View>
  
         <View style={{width:'50%',flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Deductions</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
  
        </View>
  
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
         {EmpSalarySlipComponents.filter((data) => data.componentType === 2).map((data,i)=>{
     return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
       <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
       </View>
   })}
  
         </View>
  
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
   <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Deductions</Text>
   <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalDeduction)}</Text>
  </View>
  
  
  
         </View>
  
       </View>


       <View style={{flexDirection:'row',marginTop:'8px'}}>
  
  <View style={{width:'49%',borderRight:1,flexDirection:'column',justifyContent:'flex-start',border:1,borderRadius:'5px',marginRight:'2%'}}>
  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Additions</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
  
        </View>

        <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
         {EmpSalarySlipComponents.filter((data) => data.componentType === 4).map((data,i)=>{
     return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
       <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
       </View>
   })}
  
         </View>

         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
   <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Additions</Text>
   <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalAddition)}</Text>
  </View>

    </View>

    <View style={{width:'49%',flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{border:1,borderRadius:'5px', marginBottom:'5px'}}>
  
  <View style={{flexDirection:'column',justifyContent:'space-between'}}>
  
  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Adhoc Payment</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
  
        </View>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>
  
        </View>
  
  
  
  
  </View>
  
  
  </View>
  
  <View style={{border:1,borderRadius:'5px'}}>
  
  <View style={{flexDirection:'column',justifyContent:'space-between'}}>
  
  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Adhoc Deduction</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
  
        </View>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>
  
        </View>
  
  </View>
    
  </View>

    </View>

  </View>
  
        {/* Total Calculation Rows  */}
  
  
        <View style={{flexDirection:'row',marginTop:'10px'}}>
  
  
  <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
  
  <View style={{flexDirection:'column',justifyContent:'space-between'}}>
  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
  <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
  </View>
  
  <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
  {/* <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
  <Text style={{fontSize:9}}>Payment Type</Text>
  <Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
  </View> */}
  
  <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
  <Text style={{fontSize:9}}>IBAN Number</Text>
  <Text style={{fontSize:9}}>{EmpSalarySlipData.ibanNumber}</Text>
  </View>
  
  <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
  <Text style={{fontSize:9}}>Bank Name</Text>
  <Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
  </View>
  
  
  
  </View>
  
  
  
  
  </View>
  
  
  </View>
  
  <View style={{width:'49%'}}>
  <View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>
  
  {/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
  <Text style={{fontSize: 10}}>Working Days</Text>
  <Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
  </View> */}
  
  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
  <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
  <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>
  
  </View>
  
  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
  <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
  <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>
  </View>
  
  <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
  <Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
  <Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
  </View>
  
  <View style={{flexDirection:'row', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold', marginRight:'4px'}}>In Words:</Text>
    <Text style={{fontSize:8, flex:1 }}>{amountToWords(EmpSalarySlipData.netPayableSalary || 0.00,userData.currencyCode) + " ONLY"}</Text>
    </View>
  
  </View>
  </View>
  
  
  
  
  </View>
  
  
  
  
     </Page>
     </Document>
   }


     const EthmarPrivateSalarySlip =  () => {
        return  <Document onRender={async({blob})=>{
    
          console.log("Save Pdf Emp Data",EmpSalarySlipData);
    
          // const pdf = await PDFJS.getDocument(blob);
          // const pdfData = pdf.getData();
          // pdfData.encrypt('user');
          // const encryptedBlob = new Blob([pdfData], {type: 'application/pdf'});
    
          const SalarySlipData = new FormData();
          SalarySlipData.append('file', blob, `${EmpSalarySlipData.employeeName}.pdf`);
          SalarySlipData.append('empGuId', EmpSalarySlipData.empGuid);
          SalarySlipData.append('empName', EmpSalarySlipData.employeeName);
          SalarySlipData.append('month', EmpSalarySlipData.salaryMonth);
          SalarySlipData.append('year', PayrollYear);
          SalarySlipData.append('companyId', userData.companyId);
          SalarySlipData.append('branchId', userData.isActiveBranch);
    
    
          await authApi.post(
            `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/SaveEmpMonthlySalarySlip`,SalarySlipData,{
              headers: {'Content-Type': 'application/pdf' }
          }
            )
            .then((response) => {
              const res = response.data;
              console.log("PDF Save Response", response);
    
              if (!toast.isActive(id)) {
    
              toast({
                id,
    
                title: res,
                description: `We've Saved ${EmpSalarySlipData.employeeName} Payslip.`,
                position: "top",
                status: "info",
                duration: 2000,
                isClosable: true,
              });
            }
        
            })
            .catch((e) => {
              console.error(e);
              console.log(e.response.data);
    
              if (!toast.isActive(id)) {
    
              toast({
                id,
    
                title: "ERROR",
                description: `${EmpSalarySlipData.employeeName} Payslip Not Saved.`,
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
             }
    
            });
    
        }}>
       <Page size="A4"  style={styles.page}>
             
             {/* Company Details Header */}
        <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
          <View style={{width:'50%'}}>
            {BranchLogo !== undefined ? 
           <Image style={{width:110}} src={BranchLogo} /> 
           : <Text>Logo</Text>}
          </View>
          <View style={{width:'40%'}}>
               <Text style={{marginBottom:2,fontSize:13,fontWeight:'extrabold',fontFamily:'Helvetica-Bold'}}>{userData.activeBranchName}</Text>
               <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
               {/* <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.stateName}</Text> */}
           </View>
         </View>
    
         {/* Employee Details */}
    
         <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
           <View style={{width:'60%',flexWrap:'wrap'}}>
           <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
           {/* <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Code : {EmpSalarySlipData.empCode}</Text> */}
           <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {empdata.designationName}</Text>
           <Text style={{marginBottom:'5px',fontSize:10}}>Date of Joining : {EmpSalarySlipData.dateofjoin }</Text>
           <Text style={{fontSize:10}}>Leave Balance : {EmpSalarySlipData.totalLeaveBalance}</Text>
           </View>
    
           <View style={{width:'40%',flexWrap:'wrap'}}>
           <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
           <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
           <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {PayrollYear}</Text>
           {/* <Text style={{fontSize:10}}>Date of Joining : {EmpSalarySlipData.dateofjoin }</Text> */}
           </View>
         </View>
    
    
         {/* Salary Component Details Table */}
    
    
         <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>
    
         <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'flex-start'}}>
          <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>
    
          </View>
    
           <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
            {EmpSalarySlipComponents.filter((data) => data.componentType === 1).map((data,i)=>{
              return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
                <Text style={{fontSize:9,width:'30%'}}>{data.salarySlipLabel}</Text>
                <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>
    
                <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
                </View>
            })}
    
           </View>
    
           <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
          <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
          <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>
    
          </View>
    
    
           </View>
    
           <View style={{width:'50%',flexDirection:'column',justifyContent:'flex-start'}}>
          <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Additions</Text>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
          </View>
    
           <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
           {EmpSalarySlipComponents.filter((data) => data.componentType === 4).map((data,i)=>{
       return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
         <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
         <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
         </View>
     })}
    
           </View>
    
           <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
     <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Additions</Text>
     <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalAddition)}</Text>
    </View>
    
    
    
           </View>
    
         </View>
    
    
         <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Adhoc Payment</Text>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
          </View>
          <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
          <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
          <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>
    
          </View>
    
    
    
    
    </View>
    
    
    </View>
    
    <View style={{width:'49%',border:1,borderRadius:'5px'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Adhoc Deduction</Text>
          <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
          </View>
          <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
          <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
          <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>
    
          </View>
    
    
    
    
    </View>
    
    
    </View>
    
    
    
    
    </View>
    
         
    
    
          {/* Total Calculation Rows  */}
    
    
          <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
    </View>
    
    <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Payment Type</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Account Number</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Bank Name</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
    </View>
    
    
    
    </View>
    
    
    
    
    </View>
    
    
    </View>
    
    <View style={{width:'49%'}}>
    <View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>
    
    {/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10}}>Working Days</Text>
    <Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
    </View> */}
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
    <Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
    </View>
    
    <View style={{flexDirection:'row', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold', marginRight:'4px'}}>In Words:</Text>
    <Text style={{fontSize:8, flex:1 }}>{amountToWords(EmpSalarySlipData.netPayableSalary || 0.00,userData.currencyCode) + " ONLY"}</Text>
    </View>
    
    </View>
    </View>
    
    
    
    
    </View>
    
    
    
    
       </Page>
       </Document>
     }
    
    
     const G42SalarySlip =  () => {
      return  <Document onRender={async({blob})=>{
    
        console.log("Save Pdf Emp Data G42",EmpSalarySlipData);
    
        // const pdf = await PDFJS.getDocument(blob);
        // const pdfData = pdf.getData();
        // pdfData.encrypt('user');
        // const encryptedBlob = new Blob([pdfData], {type: 'application/pdf'});
    
        const SalarySlipData = new FormData();
        SalarySlipData.append('file', blob, `${EmpSalarySlipData.employeeName}.pdf`);
        SalarySlipData.append('empGuId', EmpSalarySlipData.empGuid);
        SalarySlipData.append('empName', EmpSalarySlipData.employeeName);
        SalarySlipData.append('month', EmpSalarySlipData.salaryMonth);
        SalarySlipData.append('year', PayrollYear);
        SalarySlipData.append('companyId', userData.companyId);
        SalarySlipData.append('branchId', userData.isActiveBranch);
    
    
        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/SaveEmpMonthlySalarySlip`,SalarySlipData,{
            headers: {'Content-Type': 'application/pdf' }
        }
          )
          .then((response) => {
            const res = response.data;
            console.log("PDF Save Response", response);
    
            if (!toast.isActive(id)) {
    
            toast({
              id,
    
              title: res,
              description: `We've Saved ${EmpSalarySlipData.employeeName} Payslip.`,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
      
          })
          .catch((err) => {
            console.error(err);
            console.log(err.response.data);
    
            if (!toast.isActive(id)) {
    
            toast({
              id,
    
              title: "ERROR",
              description: `${EmpSalarySlipData.employeeName} Payslip Not Saved.`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
           }
    
          });
    
      }}>
     <Page size="A4"  style={styles.page}>
           
           {/* Company Details Header */}
      <View style={{flexDirection:"row",justifyContent:"center",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
        <View>
          {BranchLogo !== undefined ? 
         <Image style={{width:110}} src={BranchLogo} /> 
         : <Text>Logo</Text>}
        </View>
       </View>
    
       {/* Employee Details */}
    
       <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
         <View style={{width:'60%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee ID : {EmpSalarySlipData.empCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {empdata.designationName}</Text>
         <Text style={{marginBottom:'5px',fontSize: 10,}}>Division : {EmpSalarySlipData.division}</Text>
         <Text style={{fontSize:10}}>Present Days : {EmpSalarySlipData.totalPresent}</Text>
    
         </View>
    
         <View style={{width:'40%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
         <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {PayrollYear}</Text>
         <Text style={{fontSize:10}}>Grade : {EmpSalarySlipData.grade}</Text>
         </View>
       </View>
    
    
       {/* Salary Component Details Table */}
    
    
       <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>
    
       <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>
    
        </View>
    
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
          {EmpSalarySlipComponents.filter((data) => data.componentType === 1).map((data,i)=>{
            return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:9,width:'30%'}}>{data.salarySlipLabel}</Text>
              <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>
    
              <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
              </View>
          })}
    
         </View>
    
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>
    
        </View>
    
    
         </View>
    
         <View style={{width:'50%',flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Additions</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
        </View>
    
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
         {EmpSalarySlipComponents.filter((data) => data.componentType === 4).map((data,i)=>{
     return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
       <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
       </View>
    })}
    
         </View>
    
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Additions</Text>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalAddition)}</Text>
    </View>
    
    
    
         </View>
    
       </View>
    
    
       <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Payment</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
        </View>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>
    
        </View>
    
    
    
    
    </View>
    
    
    </View>
    
    <View style={{width:'49%',border:1,borderRadius:'5px'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Deduction</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
        </View>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>
    
        </View>
    
    
    
    
    </View>
    
    
    </View>
    
    
    
    
    </View>
    
       
    
    
        {/* Total Calculation Rows  */}
    
    
        <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
    </View>
    
    <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Payment Type</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Account Number</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Bank Name</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
    </View>
    
    
    
    </View>
    
    
    
    
    </View>
    
    
    </View>
    
    <View style={{width:'49%'}}>
    <View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>
    
    {/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10}}>Working Days</Text>
    <Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
    </View> */}
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
    <Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
    </View>
    
    <View style={{flexDirection:'row', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold', marginRight:'4px'}}>In Words:</Text>
    <Text style={{fontSize:8, flex:1 }}>{amountToWords(EmpSalarySlipData.netPayableSalary || 0.00,userData.currencyCode) + " ONLY"}</Text>
    </View>
    
    </View>
    </View>
    
    </View>

     </Page>
     </Document>
    }
    
    
    const IndiaSalarySlip =  () => {
      return  <Document onRender={async({blob})=>{
    
        console.log("Save Pdf Emp Data",EmpSalarySlipData,blob);
        
        const SalarySlipData = new FormData();
        SalarySlipData.append('file', blob, `${EmpSalarySlipData.employeeName}.pdf`);
        SalarySlipData.append('empGuId', EmpSalarySlipData.empGuid);
        SalarySlipData.append('empName', EmpSalarySlipData.employeeName);
        SalarySlipData.append('month', EmpSalarySlipData.salaryMonth);
        SalarySlipData.append('year', PayrollYear);
        SalarySlipData.append('isActive', true);
        SalarySlipData.append('companyId', userData.companyId);
        SalarySlipData.append('branchId', userData.isActiveBranch);
    
    
        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/SaveEmpMonthlySalarySlip`,SalarySlipData,{
            headers: {'Content-Type': 'application/pdf' }
        }
          )
          .then((response) => {
            const res = response.data;
            console.log("PDF Save Response", response);
    
            if (!toast.isActive(id)) {
    
            toast({
              id,
    
              title: res,
              description: `We've Saved ${EmpSalarySlipData.employeeName} Payslip.`,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
      
          })
          .catch((err) => {
            console.error(err);
            console.log(err.response.data);
    
            if (!toast.isActive(id)) {
    
            toast({
              id,
    
              title: "ERROR",
              description: `${EmpSalarySlipData.employeeName} Payslip Not Saved.`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
           }
    
          });
    
      }}>
     <Page size="A4"  style={styles.page}>


         {userData.activeBranchName === "HR Muster" || userData.companyName === "HR Muster"?   
         <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
          <View style={{width:'50%'}}>
            {BranchLogo !== undefined ? 
           <Image style={{width:220}} src={BranchLogo} /> 
           : <Text>Logo</Text>}
          </View>
         </View> 
         :  
         <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
          <View style={{width:'50%'}}>
            {BranchLogo !== undefined ? 
           <Image style={{width:110}} src={BranchLogo} /> 
           : <Text>Logo</Text>}
          </View>
          <View style={{width:'40%'}}>
               <Text style={{marginBottom:2,fontSize:13,fontWeight:'extrabold',fontFamily:'Helvetica-Bold'}}>{userData.companyName}</Text>
               <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
               <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.stateName}</Text>
           </View>
         </View>}
    
       {/* Employee Details */}
    
       <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
         <View style={{width:'60%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Code : {EmpSalarySlipData.empCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {empdata.designationName}</Text>
         {/* <Text style={{marginBottom:'5px',fontSize: 10,}}>Division : {EmpSalarySlipData.division}</Text> */}
         <Text style={{fontSize:10}}>Present Days : {EmpSalarySlipData.totalPresent}</Text>
    
         </View>
    
         <View style={{width:'40%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
         <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {PayrollYear}</Text>
         {/* <Text style={{fontSize:10}}>Grade : {EmpSalarySlipData.grade}</Text> */}
         </View>
       </View>
    
    
       {/* Salary Component Details Table */}
    
    
       <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>
    
       <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>
    
        </View>
    
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
          {EmpSalarySlipComponents.filter((data) => data.componentType === 1).map((data,i)=>{
            return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:9,width:'30%'}}>{data.salarySlipLabel}</Text>
              <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>
    
              <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
              </View>
          })}
    
         </View>
    
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>
    
        </View>
    
    
         </View>
    
         <View style={{width:'50%',flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Deductions</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
        </View>
    
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
         {EmpSalarySlipComponents.filter((data) => data.componentType === 2).map((data,i)=>{
     return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
       <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
       </View>
    })}


<View style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>PF</Text>
       <Text style={{fontSize:9}}>{NumberFormat(EmpSalarySlipData.pF_Employee)}</Text>
       </View>

       <View style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>ESI</Text>
       <Text style={{fontSize:9}}>{NumberFormat(EmpSalarySlipData.esI_Employee)}</Text>
       </View>

       <View style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>TDS</Text>
       <Text style={{fontSize:9}}>{NumberFormat(EmpSalarySlipData.empTDSAmount)}</Text>
       </View>
    
         </View>
    
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Deduction</Text>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalDeduction)}</Text>
    </View>
    
    
    
         </View>
    
       </View>
    
    
       <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Other Payment</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
        </View>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>
    
        </View>
    
    
  
    </View>
    
    
    </View>
    
    <View style={{width:'49%',border:1,borderRadius:'5px'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Other Deduction</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
        </View>

        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>
    
        </View>
    
    
    
    
    </View>
    
    
    </View>
    
    
    
    
    </View>
    
       
    
    
        {/* Total Calculation Rows  */}
    
    
        <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
    </View>
    
    <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Payment Type</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Account Number</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Bank Name</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
    </View>
    
    
    
    </View>
    
    
    
    
    </View>
    
    
    </View>
    
    <View style={{width:'49%'}}>
    <View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>
    
    {/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10}}>Working Days</Text>
    <Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
    </View> */}
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
    <Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
    </View>
    
    <View style={{flexDirection:'row', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold', marginRight:'4px'}}>In Words:</Text>
    <Text style={{fontSize:8, flex:1 }}>{amountToWords(EmpSalarySlipData.netPayableSalary || 0.00,userData.currencyCode) + " ONLY"}</Text>
    </View>
    
    </View>
    </View>
    
    </View>
        
     </Page>
     </Document>
    }

    console.log("Pending Month No",PendingMonthNo);
    
    const getParams = () => {
      return {
        // prependContent: getValue('#prependContent'),
        // appendContent: getValue('#appendContent'),
        prependContent: `Branch Name: ${userData.activeBranchName}`,
        // appendContent: 'Append Content',
        suppressQuotes: undefined,
        columnSeparator: undefined
      };
    };


    const onBtnExport = useCallback(() => {
      var params = getParams();
      ReviewAllEmpGrid.current.api.exportDataAsCsv(params);
    }, [alert]);


    const DownloadEmpSalarySlip = async (EMPGUID,MONTH,YEAR)=>{
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/GetSelectedMonthSalarySlipbyGuid?EmpGuId=${EMPGUID}&Month=${MONTH}&Year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )

        console.log("Get Download Salary Slip",response);
        const res = response.data;

        handleDownloadSalarySlip(res.filePath,res.month,res.year)

      } catch (err) {
        console.error(err);
      }
    }


    const handleDownloadSalarySlip = async (DocPath,MONTH,YEAR) =>{
      console.log("Document path",DocPath,MONTH,YEAR)
     await fetch(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
      ).then(response => {
  
      console.log("file Response",response);
  
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
  
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${EmpSalarySlipData.employeeName} ${monthNames[MONTH-1]} ${YEAR} Payslip.pdf`;
          alink.click();
      })
  
      }).catch((err)=>{
        console.log("File Api Error",err);
      })
    }




    const SavePayrollSixStepStatus = async (PayrollStep,StepNo,Condition) => {

      if(Condition || window.confirm('Are you sure you want to continue?')){

        try {
          let body = {
            payrollStep1: PayrollStep === "1" && StepNo === "1" ? 1 : PayrollStep === "1" && StepNo === "2" ? 2 : PayrollStep === "1" && StepNo === "3"  ? 3 : PayrollStepStatus.payrollStep1,
            payrollStep2: PayrollStep === "2" && StepNo === "1" ? 1 : PayrollStep === "2" && StepNo === "2" ? 2 : PayrollStepStatus.payrollStep2,
            payrollStep3: PayrollStep === "3" && StepNo === "1" ? 1 : PayrollStep === "3" && StepNo === "2" ? 2 : PayrollStep === "3" && StepNo === "3" ? 3 : userData.countryName === "India" ? PayrollStepStatus.payrollStep3 : 3,
            payrollStep4: PayrollStep === "4" && StepNo === "1" ? 1 : PayrollStep === "4" && StepNo === "2" ? 2 : PayrollStep === "4" && StepNo === "3" ? 3 : PayrollStepStatus.payrollStep4,
            payrollStep5: PayrollStep === "5" && StepNo === "1" ? 1 : PayrollStep === "5" && StepNo === "2" ? 2 : PayrollStepStatus.payrollStep5,
            payrollStep6: PayrollStep === "6" && StepNo === "1" ? 1 : PayrollStep === "6" && StepNo === "2" ? 2 : PayrollStepStatus.payrollStep6,
            payrollMonth: PayrollMonth,
            payrollYear: PayrollYear,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            isLock: PayrollStepStatus.isLock,
            confirm: PayrollStepStatus.confirm
          }

          console.log("Save Payroll Six Step",body);

          const response = await authApi.post(
            `${process.env.REACT_APP_ACQ_URL}/api/SixStepPayroll/SaveSixStepPayroll`,body
          )
          console.log("Step Save Response",response);
          GetPayrollStepMonthStatus(PayrollMonth,PayrollYear);
        } catch (err) {
          console.error(err);
        }
      }

    }



    const HandleReleaseAllPayslip = async () => {
      setBulkPayslipLoading(true);
      setEmpsReleasedPayslips([]);
      for (const employee of PayslipSelectedEmp) {
        const { empGuid, payrollMonth, payrollYear } = employee;
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GeneratePayslipByGuid?EmpGuid=${empGuid}&PayrollMonth=${payrollMonth}&PayrollYear=${payrollYear}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("Payslip data Response ",response);
          const res = response.data;
          setEmpsReleasedPayslips((prev)=>{
            return [...prev, res]
          })
        } catch (err) {
          console.error(err);
        }
      }
      setBulkPayslipLoading(false);
      onBulkSalarySlipOpen();      
    }

    console.log("Emp Generated Data",EmpsReleasedPayslips);


    const EmailGeneratedPayslips = async () => {

      let body = PayslipSelectedEmp.map((data) => {
        return {
          empGuId: data.empGuid,
          empName: data.empName,
          filePath: "",
          month: data.payrollMonth,
          year: data.payrollYear,
          isActive: true,
          companyId: data.companyId,
          branchId: data.branchId
        }
      })

      console.log("Email Payslips Body",body);

      await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/ShareSalarySlip`,body
      ).then((response) => {
        const res = response.data;
        console.log("res",res);
    
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: 'top',
            status: 'info',
            duration: 2000,
            isClosable: true,
          })
        }
    
      })
      .catch((err) => {
        console.log(err.response);
    
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: "Email not sent",
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });

    }

    
    const ImportedAttendanceCountReadExcel = (file) => {

      const promise = new Promise((resolve,reject) => {
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
       fileReader.onload=(e)=>{
         const bufferArray = e.target.result;
         const wb = XLSX.read(bufferArray,{type:'buffer'});
         const wsname = wb.SheetNames[0];
         const ws = wb.Sheets[wsname];
         const data = XLSX.utils.sheet_to_json(ws, {raw: false});
         resolve(data);
       } 
   
       fileReader.onerror = (error) => {
         reject(error);
       }
   
      });
   
      promise.then((data)=>{
        let FinalData = data.map((obj)=>{
          return {...obj,
            companyId:userData.companyId,
            branchId:userData.isActiveBranch
          }
         })

         console.log("Attendance Count Final Data",FinalData);
         setImportedAttendanceCountData(FinalData);
         HandleTotalAttendanceCount(FinalData);
       });
   }


   const HandleTotalAttendanceCount = async (FinalData) => {

    let body = FinalData.map((data)=>{
      return {
        ...data,
        totalLeaveBalance : Number(data.totalLeaveBalance),
        totalPresent : Number(data.totalPresent),
        payrollMonth : Number(data.payrollMonth),
        payrollYear :  Number(data.payrollYear)
      }
    })
    
     console.log("Total Attendance Final Body",body);

     const response =  await PostAttCountData(body);

     if(response){
      GetImportAttCountData(PayrollMonth,PayrollYear);
     }
   }
    
  return (
    <div>

      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Run Payroll</h1>
        <div>
          <DatePicker
            className="bg-white border border-gray-400 z-60 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
            selected={SelectedYear}
            onChange={(date) => {
              setSelectedYear(date);
              GetPayrollSettingStatus(new Date(date).getFullYear());
            }}
            minDate={new Date(Number(moment(PayrollConfigData?.payrollStartFrom).format('YYYY'))-1,1,1)}
            maxDate={new Date()}
            dateFormat="yyyy"
            showYearPicker
            placeholderText="Select Year"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="bg-white rounded-l-3xl p-2 align-middle flex border-r-2 drop-shadow-md">
          <button className="prev-arrow" onClick={handlePrev}>
            <FontAwesomeIcon className="text-2xl" icon={faAngleLeft} />
          </button>
        </div>

        <Swiper
          ref={sliderRef}
          grabCursor={true}
          className="mySwiper bg-white !z-0"
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 4 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 5 },
            1440: { slidesPerView: 7, spaceBetween:10 },
            1920: { slidesPerView: 8}
          }}
        >
          {PayrollMonthList?.map((data, i) => {
            return (
              <SwiperSlide key={i}>
                <div
                  onClick={() => {
                    if(new Date(data.payrollYear,data.payrollMonthNo,1) > new Date(PayrollConfigData?.payrollStartFrom) && (Number(data.payrollMonthNo) <= Number(PendingMonthNo) || data.payrollStatus === "Complete")){
                      handleMonthChange(data.payrollMonthNo,data.payrollYear);
                    }
                  }}
                  className={` flex flex-col  ${new Date(data.payrollYear,data.payrollMonthNo,1) > new Date(PayrollConfigData?.payrollStartFrom) && (Number(data.payrollMonthNo) <= Number(PendingMonthNo) || data.payrollStatus === "Complete") ? 'cursor-pointer' : 'cursor-not-allowed'} justify-between rounded-2xl ${
                    data.payrollStatus === "Pending"
                      ? "border-red-300"
                      : data.payrollStatus === "Current"
                      ? "border-red-300"
                      : data.payrollStatus === "Upcoming"
                      ? "border-gray-400"
                      : data.payrollStatus === "Complete"
                      ? "border-green-500"
                      : ""
                  } ${
                    data.payrollMonthNo === PayrollMonth
                      ? "!bg-blue-200 !border-blue-500"
                      : ""
                  } border-2 m-2`}
                >
                  <div className="text-center p-4 space-y-2">
                    <h6 className="text-gray-800  texl-2xl font-bold">
                      {data.payrollMonthName.substring(0, 3).toUpperCase()}{" "}
                      {data.payrollYear}
                    </h6>
                    <p className="text-sm">
                      {data.payrollMonthName.substring(0, 3).toUpperCase()}{" "}
                      {data.startDate} -{" "}
                      {data.payrollMonthName.substring(0, 3).toUpperCase()}{" "}
                      {data.endDate}
                    </p>
                  </div>
                  <div
                    className={`${
                        data.payrollStatus === "Pending"
                        ? "bg-red-300"
                        : data.payrollStatus === "Current"
                        ? "bg-red-300"
                        : data.payrollStatus === "Upcoming"
                        ? "bg-gray-400"
                        : data.payrollStatus === "Complete"
                        ? "bg-green-500"
                        : " "
                    } ${
                      data.payrollMonthNo === PayrollMonth ? "!bg-blue-500 " : ""
                    } text-white rounded-b-xl text-center`}
                  >
                    {data.payrollStatus}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="bg-white rounded-r-3xl p-2 align-middle flex border-l-2 drop-shadow-md">
          <button className="next-arrow" onClick={handleNext}>
            <FontAwesomeIcon className="text-2xl" icon={faAngleRight} />
          </button>
        </div>
      </div>



      <div className="bg-white p-4 rounded-3xl mb-4 drop-shadow-md">
        <div className="border-b-2">
        
          <h6 className="text-xl font-bold pb-3 text-gray-800">
          <Skeleton height='20px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollMonthDataLoading} >
            {PayrollDetailsOfMonth.payrollMonthName} - {PayrollDetailsOfMonth.payrollYear}
            &nbsp;
            <span className="text-gray-400 text-xl font-extralight">
              ({PayrollDetailsOfMonth.payrollMonthName} {PayrollDetailsOfMonth.startDate} -{" "}
              {PayrollDetailsOfMonth.payrollMonthName} {PayrollDetailsOfMonth.endDate},{" "}
              {PayrollDetailsOfMonth.calendarDays} Days)
            </span>
            </Skeleton>
          </h6> 
          
        </div>

        <div className="flex flex-wrap">
          <div className="border-r-2 flex flex-col justify-between space-y-3 p-2 pr-6">
            <h6 className="text-sm font-medium text-gray-500">
              <Skeleton height='14px' isLoaded={!LoadingStatus?.payrollMonthDataLoading}>Total Employees</Skeleton>
            </h6>
            <p className="text-2xl text-gray-800 font-bold">
              <Skeleton height='24px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollMonthDataLoading}>{PayrollDetailsOfMonth?.totalEmployee}</Skeleton>
            </p>
            <p className="text-sm text-gray-50">_</p>
          </div>

          <div className="border-r-2 flex flex-col justify-between space-y-3 p-2 pr-6">
            <h6 className="text-sm font-medium text-gray-500">
            <Skeleton height='14px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollMonthDataLoading}>Calendar Days</Skeleton>
            </h6>
            <p className="text-2xl text-gray-800 font-bold">
            <Skeleton height='24px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollMonthDataLoading}>{PayrollDetailsOfMonth.calendarDays}</Skeleton>
            </p>
            <p className="text-sm text-gray-50">_</p>
          </div>

          <div className="border-r-2 flex flex-col justify-between space-y-3 p-2 pr-6">
            <h6 className="text-sm font-medium text-gray-500">
              <Skeleton height='14px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollMonthDataLoading}>Current Payroll</Skeleton>
            </h6>
            <p className="text-2xl text-gray-800 font-bold">
            <Skeleton height='24px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollMonthDataLoading}>{PayrollDetailsOfMonth.payrollProcessedEmployee}</Skeleton>
            </p>
            <p className="text-sm text-gray-400"><Skeleton height='14px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollMonthDataLoading}>Employees</Skeleton></p>
          </div>

            <div className="flex flex-col justify-between space-y-3 p-2 px-6">
              <h6 className="text-sm font-medium text-gray-500">
               <Skeleton height='14px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollCostLoading}>Total Payroll Cost</Skeleton>
              </h6>
              <p className="text-2xl text-gray-800 font-bold">
              <Skeleton height='24px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollCostLoading}>{userData.currencyCode} {NumberFormat(TotalPayrollCost.totalPayrollCost)}</Skeleton>
              </p>
              <p className="text-sm text-gray-400"><Skeleton height='14px' width={'fit-content'} isLoaded={!LoadingStatus?.payrollCostLoading}>{amountToWords(TotalPayrollCost?.totalPayrollCost || 0.00,userData.currencyCode)}</Skeleton></p>
            </div>

        </div>
      </div>



      <div className="bg-white p-4 rounded-3xl mb-4 drop-shadow-md">
        <div className="border-b-2">
          <h6 className="text-xl font-bold pb-3 text-gray-800">Run Payroll</h6>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 p-10">

          <div onClick={()=>{
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
              }else if(!IsPayrollMonthFinalized){
                getLeaveListOfMonth(PayrollMonth,PayrollYear);
                onLeaveAtttOpen();
              }
            }}
            className={`border-2 rounded-xl p-2 flex gap-4 ${!IsPayrollMonthFinalized ? "hover:bg-blue-50 hover:border-blue-200 cursor-pointer" : ''}`}
          >
            <Avatar bg="purple.100" icon={<FontAwesomeIcon className=" text-purple-500 text-xl" icon={faCalendarDay} />} />
            <h6 className=" my-auto font-bold  text-gray-700">
              {Validation?.isAsPerAttendance ? "Leave and Attendance" : "Attendance Summary"}
            </h6>
            {IsPayrollMonthFinalized ?
             <div className="ml-auto my-auto">
              <Tooltip placement='top' rounded='5px' hasArrow label='Locked' bg='gray.700' color='white'>
               <FontAwesomeIcon className=" text-gray-400 mr-2 text-xl"icon={faLock}/>
              </Tooltip>
            </div>  : <>
          {PayrollStepStatus?.payrollStep1 === 3 ? 
           <div className="ml-auto my-auto">          
            <Tooltip placement='top' rounded='5px' hasArrow label='Completed' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-green-500 mr-2 text-xl" icon={faCircleCheck} />
            </Tooltip>
           </div> : 
           <div className="ml-auto my-auto">
            <Tooltip placement='top' rounded='5px' hasArrow label='Pending' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-yellow-500 mr-2 text-xl"icon={faCircleExclamation}/>
            </Tooltip>
           </div> } </>} 
          </div> 

          <div onClick={()=>{
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
              }else if (!IsPayrollMonthFinalized) {
                getNewJoineeListofMonth(PayrollMonth,PayrollYear);
                onNewJoineeOpen();
              }
            }}
            className={`border-2 rounded-xl p-2 flex gap-4 ${!IsPayrollMonthFinalized ? "hover:bg-blue-50 hover:border-blue-200 cursor-pointer" : ''}`}
          >
            <Avatar
              bg="red.100"
              icon={
                <FontAwesomeIcon
                  className=" text-red-600 text-xl"
                  icon={faUserPlus}
                />
              }
            />
            <h6 className=" my-auto font-bold  text-gray-700">
              New Joinees & Exits
            </h6>

            {IsPayrollMonthFinalized ?
             <div className="ml-auto my-auto">
              <Tooltip placement='top' rounded='5px' hasArrow label='Locked' bg='gray.700' color='white'>
               <FontAwesomeIcon className="text-gray-400 mr-2 text-xl"icon={faLock}/>
              </Tooltip>
            </div>  : <>
            {PayrollStepStatus?.payrollStep2 === 2 ? 
           <div className="ml-auto my-auto">          
            <Tooltip placement='top' rounded='5px' hasArrow label='Completed' bg='gray.700' color='white'>
             <FontAwesomeIcon className="text-green-500 mr-2 text-xl" icon={faCircleCheck} />
            </Tooltip>
           </div> : 
           <div className="ml-auto my-auto">
            <Tooltip placement='top' rounded='5px' hasArrow label='Pending' bg='gray.700' color='white'>
             <FontAwesomeIcon className="text-yellow-500 mr-2 text-xl"icon={faCircleExclamation}/>
            </Tooltip>
           </div> }</>}
          </div>

          {userData.countryName === "India" ?
          <div 
          onClick={()=>{
            if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
              onAlertOpen();
          }else if(!IsPayrollMonthFinalized){
              getPfOverrideListofMonth(PayrollMonth,PayrollYear);
              onOverrideOpen();
            }
          }}
          className={`border-2 rounded-xl p-2 flex gap-4 ${!IsPayrollMonthFinalized ? "hover:bg-blue-50 hover:border-blue-200 cursor-pointer" : ''}`}
          >
            <Avatar
              bg="orange.100"
              icon={<FontAwesomeIcon className=" text-orange-600 text-xl" icon={faSackDollar} />}
            />
            <h6 className=" my-auto font-bold  text-gray-700">
              Override (PF, ESI, TDS)
            </h6>

            {IsPayrollMonthFinalized ?
             <div className="ml-auto my-auto">
             <Tooltip placement='top' rounded='5px' hasArrow label='Locked' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-gray-400 mr-2 text-xl"icon={faLock}/>
             </Tooltip>
            </div>  : <>
            {PayrollStepStatus?.payrollStep3 === 3 ? 
           <div className="ml-auto my-auto">          
            <Tooltip placement='top' rounded='5px' hasArrow label='Completed' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-green-500 mr-2 text-xl" icon={faCircleCheck} />
            </Tooltip>
           </div> : 
           <div className="ml-auto my-auto">
            <Tooltip placement='top' rounded='5px' hasArrow label='Pending' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-yellow-500 mr-2 text-xl"icon={faCircleExclamation}/>
            </Tooltip>
           </div> } </>}
          </div> :
                    <div
                    className={`border-2 rounded-xl p-2 flex gap-4 ${!IsPayrollMonthFinalized ? "hover:bg-blue-50 hover:border-blue-200 cursor-pointer" : ''}`}
                    >
                    <Avatar
                      bg="orange.100"
                      icon={
                        <FontAwesomeIcon
                          className=" text-orange-600 text-xl"
                          icon={faSackDollar}
                        />
                      }
                    />
                    <h6 className=" my-auto font-bold  text-gray-700">
                      Bank Details
                    </h6>

                    {IsPayrollMonthFinalized ?
             <div className="ml-auto my-auto">
             <Tooltip placement='top' rounded='5px' hasArrow label='Locked' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-gray-400 mr-2 text-xl"icon={faLock}/>
             </Tooltip>
            </div>  : <>
            {PayrollStepStatus?.payrollStep3 === 3 ? 
           <div className="ml-auto my-auto">          
            <Tooltip placement='top' rounded='5px' hasArrow label='Completed' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-green-500 mr-2 text-xl" icon={faCircleCheck} />
            </Tooltip>
           </div> : 
           <div className="ml-auto my-auto">
            <Tooltip placement='top' rounded='5px' hasArrow label='Pending' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-yellow-500 mr-2 text-xl"icon={faCircleExclamation}/>
            </Tooltip>
           </div> }
           </> }
                  </div> }

          <div 
            onClick={()=>{
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
            }else if(!IsPayrollMonthFinalized){
              getBonusListofMonth(PayrollMonth,PayrollYear);
              onBonusOpen();
             }
           }} 
           className={`border-2 rounded-xl p-2 flex gap-4 ${!IsPayrollMonthFinalized ? "hover:bg-blue-50 hover:border-blue-200 cursor-pointer" : ''}`}
           >
            <Avatar
              bg="cyan.100"
              icon={
                <FontAwesomeIcon
                  className=" text-cyan-600 text-xl"
                  icon={faSackDollar}
                />
              }
            />
            <h6 className=" my-auto font-bold  text-gray-700">
              Bonus, Salary Revisions, Overtime
            </h6>

            {IsPayrollMonthFinalized ?
             <div className="ml-auto my-auto">
             <Tooltip placement='top' rounded='5px' hasArrow label='Locked' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-gray-400 mr-2 text-xl"icon={faLock}/>
             </Tooltip>
            </div>  : <>
            {PayrollStepStatus?.payrollStep4 === 3 ? 
           <div className="ml-auto my-auto">          
            <Tooltip placement='top' rounded='5px' hasArrow label='Completed' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-green-500 mr-2 text-xl" icon={faCircleCheck} />
            </Tooltip>
           </div> : 
           <div className="ml-auto my-auto">
            <Tooltip placement='top' rounded='5px' hasArrow label='Pending' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-yellow-500 mr-2 text-xl"icon={faCircleExclamation}/>
            </Tooltip>
           </div> } </> }
          </div>

          <div 
            onClick={()=>{
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
             }else if(!IsPayrollMonthFinalized){
                getAdhocPaymentListofMonth(PayrollMonth,PayrollYear);
                onReimbursementOpen();
              }
            }}
            className={`border-2 rounded-xl p-2 flex gap-4 ${!IsPayrollMonthFinalized ? "hover:bg-blue-50 hover:border-blue-200 cursor-pointer" : ''}`}
          >
            <Avatar
              bg="green.100"
              icon={
                <FontAwesomeIcon
                  className=" text-green-600 text-xl"
                  icon={faMoneyBill1}
                />
              }
            />
            <h6 className=" my-auto font-bold  text-gray-700">
              Adhoc Payments & Deductions
            </h6>

            {IsPayrollMonthFinalized ?
             <div className="ml-auto my-auto">
             <Tooltip placement='top' rounded='5px' hasArrow label='Locked' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-gray-400 mr-2 text-xl"icon={faLock}/>
             </Tooltip>
            </div>  : <>
            {PayrollStepStatus?.payrollStep5 === 2 ? 
           <div className="ml-auto my-auto">          
            <Tooltip placement='top' rounded='5px' hasArrow label='Completed' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-green-500 mr-2 text-xl" icon={faCircleCheck} />
            </Tooltip>
           </div> : 
           <div className="ml-auto my-auto">
            <Tooltip placement='top' rounded='5px' hasArrow label='Pending' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-yellow-500 mr-2 text-xl"icon={faCircleExclamation}/>
            </Tooltip>
           </div> } </>}

          </div>

          <div
            onClick={()=>{
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
              }else if (!IsPayrollMonthFinalized) {
                GetSalaryOnHold(PayrollMonth,PayrollYear)
                onArrearsOpen();
              }
            }}
            className={`border-2 rounded-xl p-2 flex gap-4 ${!IsPayrollMonthFinalized ? "hover:bg-blue-50 hover:border-blue-200 cursor-pointer" : ''}`}
          >
            <Avatar
              bg="purple.100"
              icon={
                <FontAwesomeIcon
                  className=" text-purple-500 text-xl"
                  icon={faMoneyBill1Wave}
                />
              }
            />
            <h6 className=" my-auto font-bold  text-gray-700">
              Salaries on Hold & Arrears
            </h6>

            {IsPayrollMonthFinalized ?
             <div className="ml-auto my-auto">
             <Tooltip placement='top' rounded='5px' hasArrow label='Locked' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-gray-400 mr-2 text-xl"icon={faLock}/>
             </Tooltip>
            </div>  : <>
            {PayrollStepStatus?.payrollStep6 === 2 ? 
           <div className="ml-auto my-auto">          
            <Tooltip placement='top' rounded='5px' hasArrow label='Completed' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-green-500 mr-2 text-xl" icon={faCircleCheck} />
            </Tooltip>
           </div> : 
           <div className="ml-auto my-auto">
            <Tooltip placement='top' rounded='5px' hasArrow label='Pending' bg='gray.700' color='white'>
             <FontAwesomeIcon className=" text-yellow-500 mr-2 text-xl"icon={faCircleExclamation}/>
            </Tooltip>
           </div> }
           </> }
          </div>
        </div>

        <div className="space-x-4 text-right">
          <Button 
            isLoading={IsReviewPayrollLoading}
            loadingText="Reviewing..."
            isDisabled={IsPayrollMonthFinalized}
            onClick={async() => {
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
              }else{
                setIsReviewPayrollLoading(true)
                try {
                  const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/ProcessDailyAttendance?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Attendancedate=${moment(`${PayrollYear}-${PayrollMonth}-01`).format('YYYY-MM-DD')}`)
                    console.log("Process Attendance Response",response);
                } catch (err) {
                    console.error(err);
                } finally {
                  HandleReviewRunPayroll(PayrollMonth,PayrollYear)
                }
              }
            }}
            colorScheme="purple"
            variant="link"
          >
            Review Run Payroll
          </Button>
          <Button
            isDisabled={IsPayrollMonthFinalized || !IsPayrollReviewed}
            onClick={ () => {
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
              }else{
                HandleReviewAllEmployee(PayrollMonth,PayrollYear,'Review');
              }
            }}
            colorScheme="purple"
            variant="outline"
          >
            Review All Employee
          </Button>
          <Button 
            isDisabled={IsPayrollMonthFinalized || !(
              PayrollStepStatus?.payrollStep1 === 3 &&
              PayrollStepStatus?.payrollStep2 === 2 &&
              PayrollStepStatus?.payrollStep3 === 3 &&
              PayrollStepStatus?.payrollStep4 === 3 &&
              PayrollStepStatus?.payrollStep5 === 2 &&
              PayrollStepStatus?.payrollStep6 === 2
            )}
            onClick={()=>{
              if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                onAlertOpen();
              }else{
                HandleFinalizePayroll();
              }
            }}
            colorScheme="purple"
            variant="solid"
          >
            Finalize Payroll
          </Button>
        </div>
      </div>

      <Accordion border="transparent" defaultIndex={[0]} rounded="3xl" allowMultiple bg="white">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <h6 className="text-xl text-left flex-1 font-bold  text-gray-800">
                Payroll Outcome
              </h6>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <div className="flex space-x-8 pt-2 border-t">
              <Button 
                // isDisabled={!IsPayrollMonthFinalized}
                variant="link"
                onClick={()=>{
                  if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                    onAlertOpen();
                  }else{
                    HandlePayRegisterEmps(PayrollMonth,PayrollYear,'PayRegister')
                  }
                }}
                colorScheme="blue"
                leftIcon={<FontAwesomeIcon icon={faNewspaper} />}
              >
                View Pay Register
              </Button>
              <Button
                // isDisabled={!IsPayrollMonthFinalized}
                variant="link"
                onClick={()=>{
                  if(Validation?.payrollConfigurationSetting === false || Validation?.employeeSalaryStatus === false || PayrollAttendanceStatus?.attendanceStatus === false){
                    onAlertOpen();
                  }else{
                    HandlePayRegisterEmps(PayrollMonth,PayrollYear,'PaySlip')
                  }
                }}
                colorScheme="blue"
                leftIcon={<FontAwesomeIcon icon={faFileInvoice} />}
              >
                Manage PaySlip
              </Button>
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>


      {/* Leave Attendance Lop Modal */}
      <Modal scrollBehavior="inside" onClose={onLeaveAttClose} size="full" isOpen={isLeaveAttOpen}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} fontWeight='bold'>
            {Validation?.isAsPerAttendance ? "Leave, No Attendance and LOP Summary" : "Attendance Summary"}
          </ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody className="bg-slate-300">
            {Validation?.isAsPerAttendance ?
              <div className=" mx-auto my-6">
                <Steps
                  checkIcon={CheckCircle}
                  colorScheme="purple"
                  activeStep={LeaveActiveStep}
                >
                  <Step label={"Leave Applied"}>
                    <div>
                      <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                        <h1 className="text-gray-700 text-lg  font-bold ">
                          Leave Applied
                        </h1>
                      </div>
                      {LoadingStatus?.leaveDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>: <div className="ag-theme-alpine">
                          <AgGridReact
                            style={gridStyle}
                            domLayout={"autoHeight"}
                            ref={gridRef} // Ref for accessing Grid's API
                            rowData={LeaveRowData} // Row Data for Rows
                            columnDefs={LeaveColumnDefs} // Column Defs for Columns
                            defaultColDef={defaultColDef} // Default Column Properties
                            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                            pagination={true}
                            paginationPageSize={10}
                            paginationNumberFormatter={paginationNumberFormatter}
                          />
                        </div>}
                    </div>
                  </Step>

                  <Step label={"No Attendance"}>
                    <div>
                      <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                        <h1 className="text-gray-700 text-lg  font-bold ">
                          No Attendance
                        </h1>
                      </div>


                      {LoadingStatus?.NoAttDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:

                      <div className="ag-theme-alpine mt-2">
                        <AgGridReact
                          style={gridStyle}
                          domLayout={"autoHeight"}
                          rowData={NoAttendance} // Row Data for Rows
                          columnDefs={NoAttendanceColumnDefs} // Column Defs for Columns
                          defaultColDef={defaultColDef} // Default Column Properties
                          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                          pagination={true}
                          paginationPageSize={12}
                          paginationNumberFormatter={paginationNumberFormatter}
                        />
                      </div>}
                    </div>
                  </Step>

                  <Step label={"Attendance Summary"}>
                    <div>
                      <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                        <h1 className="text-gray-700 text-lg  font-bold ">
                          Attendance Summary
                        </h1>
                      </div>

                      {/* <div className="ag-theme-alpine mt-2">
                        <AgGridReact
                          style={gridStyle}
                          domLayout={"autoHeight"}
                          rowData={LOPSummary} // Row Data for Rows
                          columnDefs={LOPSummaryColumnDefs} // Column Defs for Columns
                          defaultColDef={defaultColDef} // Default Column Properties
                          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                          pagination={true}
                          paginationPageSize={10}
                          paginationNumberFormatter={paginationNumberFormatter}
                        />
                      </div> */}


{LoadingStatus?.LOPSummaryDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:

                      <div className="ag-theme-alpine">
                  <AgGridReact
                    style={gridStyle}
                    domLayout={"autoHeight"}
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={ImpAttCountRowData} // Row Data for Rows
                    columnDefs={[
                      { headerName: "Emp Code", field: `empCode`},
                      { headerName: "Emp Name", field: `empName`},
                      { headerName: "Present Days", field: `totalPresent`},
                      { headerName: "Leaves", field: `totalLeave`},
                      { headerName: "Weekly Offs", field: `totalWO`},
                      { headerName: "Holidays", field: `totalHoliday`},
                      { headerName: "Payable Days", valueGetter: ({data}) => data.totalPresent + data.totalLeave + data.totalWO + data.totalHoliday },
                     ]} 
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    pagination={true}
                    paginationPageSize={15}
                    paginationNumberFormatter={paginationNumberFormatter}
                  />
                </div>}

                    </div>
                  </Step>
                </Steps>
              </div> : 
              <>
                <div className="text-right my-4">
                  <Button leftIcon={<FontAwesomeIcon icon={faUpload} />} colorScheme="purple" onClick={()=>{onImportAttendanceCountOpen();}}>Import Attendance</Button>
                </div>
                {LoadingStatus?.ImportAttDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> :
                <div className="ag-theme-alpine">
                  <AgGridReact
                    style={gridStyle}
                    domLayout={"autoHeight"}
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={ImpAttCountRowData} // Row Data for Rows
                    columnDefs={[
                      { headerName: "Emp Code", field: `empCode`},
                      { headerName: "Emp Name", field: `empName`},
                      { headerName: "Payable Days", field: `totalPresent`}
                     ]} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    pagination={true}
                    paginationPageSize={15}
                    paginationNumberFormatter={paginationNumberFormatter}
                  />
                </div>}
              </> }

          </ModalBody>
          <hr />
          <ModalFooter>
            {Validation?.isAsPerAttendance ? <>
            {LeaveActiveStep === Leavesteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={leavereset}>
                  Reset
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  onClick={onLeaveAttClose}
                >
                  Back to Run Payroll
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={LeaveActiveStep === 0}
                  mr={4}
                  onClick={LeavePrev}
                  colorScheme="purple"
                  variant="outline"
                >
                  Prev
                </Button>
                {LeaveActiveStep === Leavesteps.length - 1 ? (
                  <Button onClick={HandleLeaveModalSteps} colorScheme="purple">
                    Finish
                  </Button>
                ) : (
                  <Button colorScheme="purple" onClick={HandleLeaveModalSteps}>
                    Continue
                  </Button>
                )}
              </Flex>
            )} </> : <Button onClick={()=>{
              SavePayrollSixStepStatus("1","3");
              onLeaveAttClose();
            }} colorScheme="purple">
            Finish
          </Button>}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* New Joinees & Exits Modal Start */}
      <Modal scrollBehavior="inside" size="full" isOpen={isNewJoineeOpen} onClose={onNewJoineeClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} fontWeight='bold'>New Joinees & Exits</ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody className="bg-slate-300">
            <div className=" mx-auto my-6">
              <Steps
                checkIcon={CheckCircle}
                size="sm"
                colorScheme="purple"
                activeStep={NewJoineeActiveStep}
              >
                <Step label={"New Joinees"}>
                  <div>
                    <div className="w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        New Joinees
                      </h1>
                    </div>
                    {LoadingStatus?.NewJoineeDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> :
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={NewJoineeRowData} // Row Data for Rows
                        columnDefs={NewJoineeColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>

                <Step label={"Employee in Exit Process"}>
                    <div className="mb-10">
                      <div className="w-full border-b border-gray-400 my-3 pb-3 text-left">
                        <h1 className="text-gray-700 text-lg  font-bold ">
                          All Seperations
                        </h1>
                      </div>
                      {LoadingStatus?.SeparationDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                      <div className="ag-theme-alpine mt-2">
                        <AgGridReact
                          style={gridStyle}
                          domLayout={"autoHeight"}
                          rowData={AllResignationRowData} // Row Data for Rows
                          columnDefs={AllResignationColumnDefs} // Column Defs for Columns
                          defaultColDef={defaultColDef} // Default Column Properties
                          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                          pagination={true}
                          paginationPageSize={10}
                          paginationNumberFormatter={paginationNumberFormatter}
                        />
                      </div>}
                    </div>
                </Step>

              </Steps>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            {NewJoineeActiveStep === NewJoineesteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={NewJoineeReset}>
                  Reset
                </Button>

                <Button
                  variant="outline"
                  colorScheme="purple"
                  onClick={onLeaveAttClose}
                >
                  Back to Run Payroll
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={NewJoineeActiveStep === 0}
                  mr={4}
                  onClick={NewJoineePrev}
                  colorScheme="purple"
                  variant="outline"
                >
                  Prev
                </Button>
                {NewJoineeActiveStep === NewJoineesteps.length - 1 ? (
                  <Button onClick={NewJoineesModalSteps} colorScheme="purple">
                    Finish
                  </Button>
                ) : (
                  <Button colorScheme="purple" onClick={NewJoineesModalSteps}>
                    Continue
                  </Button>
                )}
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Override Modal Start */}
      <Modal scrollBehavior="inside" size="full" isOpen={isOverrideOpen} onClose={onOverrideClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} fontWeight='bold'>Override (PF, ESI, TDS)</ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody className="bg-slate-300">
                        <div className=" mx-auto my-6">
              <Steps
                checkIcon={CheckCircle}   
                size='sm'
                colorScheme="purple"
                activeStep={OverrideActiveStep}
              >
                <Step label={"PF Details"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        PF Details
                      </h1>
                    </div>

                    {LoadingStatus?.PfDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={PFOverrideRowData} // Row Data for Rows
                        columnDefs={PFOverrideColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>

                <Step label={"ESI Override"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        ESI Override
                      </h1>
                    </div>

                    {LoadingStatus?.EsiDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={ESIOverrideRowData} // Row Data for Rows
                        columnDefs={ESIOverrideColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>

                <Step label={"TDS Override"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        TDS Override
                      </h1>
                    </div>
                    {LoadingStatus?.TdsDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> :
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={TDSOverrideRowData} // Row Data for Rows
                        columnDefs={TDSOverrideColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={15}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>
              </Steps>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            {OverrideActiveStep === Overridesteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={OverrideReset}>
                  Reset
                </Button>

                <Button
                  variant="outline"
                  colorScheme="purple"
                  onClick={onOverrideClose}
                >
                  Back to Run Payroll
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={OverrideActiveStep === 0}
                  mr={4}
                  onClick={OverridePrev}
                  colorScheme="purple"
                  variant="outline"
                >
                  Prev
                </Button>
                {OverrideActiveStep === Overridesteps.length - 1 ? (
                  <Button onClick={OverrideModalSteps} colorScheme="purple">
                    Finish
                  </Button>
                ) : (
                  <Button colorScheme="purple" onClick={OverrideModalSteps}>
                    Continue
                  </Button>
                )}
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Bonus Modal Start */}
      <Modal scrollBehavior="inside" size="full" isOpen={isBonusOpen} onClose={onBonusClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} fontWeight='bold'>
            Bonus, Salary Revisions, Overtime
          </ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody className="bg-slate-300">
            <div className=" mx-auto my-6">
              <Steps
                checkIcon={CheckCircle}   
                size='sm'
                colorScheme="purple"
                activeStep={BonusActiveStep}
              >
                <Step label={"Bonus"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Bonus
                      </h1>
                    </div>

                    {LoadingStatus?.BonusDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={BonusRowData} // Row Data for Rows
                        columnDefs={BonusColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        // rowSelection={'single'}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                        // onRowSelected={onRowSelected}
                      />
                    </div>}
                  </div>
                </Step>

                <Step label={"Salary Revision"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Salary Revision
                      </h1>
                    </div>
                    {LoadingStatus?.SalaryRevDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={SalaryRevisionRowData} // Row Data for Rows
                        columnDefs={SalaryRevisionColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        // rowSelection={'single'}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                        // onRowSelected={onRowSelected}
                      />
                    </div>}
                  </div>
                </Step>

                <Step label={"Overtime Payment"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Overtime Payment
                      </h1>
                    </div>

                    <div className="text-right mb-4">
                      <Button onClick={()=>{
                        OttGridRef.current.api.exportDataAsCsv();
                      }} leftIcon={<LuDownload/>} colorScheme="purple" >Export</Button>
                    </div>

                    {LoadingStatus?.OvertimeDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        ref={OttGridRef}
                        domLayout={"autoHeight"}
                        rowData={OvertimeRowData} // Row Data for Rows
                        columnDefs={OvertimeColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        rowSelection="single"
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>

                {/* <Step label={"Shift Allowance"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Shift Allowance
                      </h1>
                    </div>
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={ShiftAllowanceRowData} // Row Data for Rows
                        columnDefs={ShiftAllowanceColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>
                  </div>
                </Step> */}
              </Steps>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            {BonusActiveStep === Bonussteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={BonusReset}>
                  Reset
                </Button>

                <Button
                  variant="outline"
                  colorScheme="purple"
                  onClick={onLeaveAttClose}
                >
                  Back to Run Payroll
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={BonusActiveStep === 0}
                  mr={4}
                  onClick={BonusPrev}
                  colorScheme="purple"
                  variant="outline"
                >
                  Prev
                </Button>
                {BonusActiveStep === Bonussteps.length - 1 ? (
                  <Button onClick={BonusModalSteps} colorScheme="purple">
                    Finish
                  </Button>
                ) : (
                  <Button colorScheme="purple" onClick={BonusModalSteps}>
                    Continue
                  </Button>
                )}
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Reimbursement & Adhoc Payment Modal Start */}
      <Modal
        scrollBehavior="inside"
        size="full"
        isOpen={isReimbursementOpen}
        onClose={onReimbursementClose}
      >
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} fontWeight='bold'>Adhoc Payments & Deductions</ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody className="bg-slate-300">
            <div className=" mx-auto my-6">
              <Steps
                checkIcon={CheckCircle}
                size='sm'
                colorScheme="purple"
                activeStep={ReimbursementActiveStep}
              >
                <Step label={"Adhoc Payments"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Adhoc Payments
                      </h1>
                    </div>

                    {LoadingStatus?.AdhocPayDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={AdhocPaymentRowData} // Row Data for Rows
                        columnDefs={AdhocPaymentColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>

                <Step label={"Adhoc Deductions"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Adhoc Deductions
                      </h1>
                    </div>

                    {LoadingStatus?.AdhocDedDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={AdhocDeductionRowData} // Row Data for Rows
                        columnDefs={AdhocDeductionColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>
              </Steps>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            {ReimbursementActiveStep === Reimbursementsteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={ReimbursementReset}>
                  Reset
                </Button>

                <Button
                  variant="outline"
                  colorScheme="purple"
                  onClick={onReimbursementClose}
                >
                  Back to Run Payroll
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={ReimbursementActiveStep === 0}
                  mr={4}
                  onClick={ReimbursementPrev}
                  colorScheme="purple"
                  variant="outline"
                >
                  Prev
                </Button>
                {ReimbursementActiveStep === Reimbursementsteps.length - 1 ? (
                  <Button onClick={AdhocModalSteps} colorScheme="purple">
                    Finish
                  </Button>
                ) : (
                  <Button colorScheme="purple" onClick={AdhocModalSteps}>
                    Continue
                  </Button>
                )}
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Arrears Salaries on Hold Modal Start */}
      <Modal scrollBehavior="inside" size="full" isOpen={isArrearsOpen} onClose={onArrearsClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} fontWeight='bold'>
            Salaries on Hold & Arrears
          </ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody className="bg-slate-300">
            <div className=" mx-auto my-6">
              <Steps
                checkIcon={CheckCircle}   
                size='sm'
                colorScheme="purple"
                activeStep={ArrearsActiveStep}>
                <Step label={"Salary Payout on Hold"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Salary Payout on Hold
                      </h1>
                    </div>
                    {LoadingStatus?.HoldSalaryDataLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center>:
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={SalaryOnHoldRowData} // Row Data for Rows
                        columnDefs={SalaryOnHoldColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                      />
                    </div>}
                  </div>
                </Step>

                <Step label={"Arrears"}>
                  <div>
                    <div className=" w-full border-b border-gray-400 my-3 pb-3 text-left">
                      <h1 className="text-gray-700 text-lg  font-bold ">
                        Arrears
                      </h1>
                    </div>
                    <div className="mb-4 overflow-x-auto relative shadow-md sm:rounded-lg">
                      <div className="pb-4 bg-slate-500 align-middle flex justify-between p-4">
                        <div className="my-auto">
                          <h6 className="text-lg font-medium text-white">
                            Arrears
                          </h6>
                        </div>
                        <div className="relative mt-1">
                          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg
                              className="w-5 h-5 text-gray-500 "
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="table-search"
                            className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                            placeholder="Search for items"
                          />
                        </div>
                      </div>
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-white uppercase bg-slate-700 ">
                          <tr>
                            <th scope="col" className="py-3 px-6">
                              Employee name
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Date Joined
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Total Arrear Amount
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Reasons
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-lg">No Records Found</tbody>
                      </table>
                    </div>
                  </div>
                </Step>
              </Steps>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            {ArrearsActiveStep === Arrearssteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={ArrearsReset}>
                  Reset
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  onClick={onArrearsClose}
                >
                  Back to Run Payroll
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={ArrearsActiveStep === 0}
                  mr={4}
                  onClick={ArrearsPrev}
                  colorScheme="purple"
                  variant="outline"
                >
                  Prev
                </Button>
                {ArrearsActiveStep === Arrearssteps.length - 1 ? (
                  <Button onClick={ArrearModalSteps} colorScheme="purple">
                    Finish
                  </Button>
                ) : (
                  <Button colorScheme="purple" onClick={ArrearModalSteps}>
                    Continue
                  </Button>
                )}
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>



       {/* Edit Attendance Modal */}
      <Modal size="3xl" isOpen={isNoAttActionOpen} onClose={()=>{
        setTreatAsLeave(0);
        onNoAttActionClose();
      }}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'}  >Edit Attendance</ModalHeader>
          <ModalCloseButton mt={2} />
            <ModalBody>
              <div className="space-y-4 ">
              <div className="flex pb-1 gap-8 border-b">
                <div>
                  <p className="text-sm text-gray-500">Employee Name</p>
                  <h6 className="font-medium">{EditAttSelectedEmp?.empName}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Employee Code</p>
                  <h6 className="font-medium">{EditAttSelectedEmp?.empCode}</h6>
                </div>
              </div>

              </div>

              <div className="px-5 flex py-3 space-x-5 ">
                <div className="my-auto">
                  <FontAwesomeIcon className="rotate-180 text-lg" icon={faArrowTurnUp} />
                </div>
                {/* <Button isDisabled={NoAttSelectedDates.length === 0} onClick={() => {SaveEditAttendance("Present",0)}} size={"sm"} variant={'outline'} colorScheme="purple">
                  Mark Present
                </Button> */}
                <Button isDisabled={NoAttSelectedDates.length === 0} onClick={() => {SaveEditAttendance("LOP",0)}} size={"sm"} variant={'outline'} colorScheme="purple">
                  Mark LOP
                </Button>
                {/* <Menu>
                  <MenuButton as={Button} size={'sm'} color={'purple'} rightIcon={<ChevronDown size={'14px'} />}>
                    Mark Leave
                  </MenuButton>
                  <MenuList>
                    {LeaveTypeList?.filter((filData) => filData?.isAbsenceSettledwithLeave)?.map((data)=> {
                      return <MenuItem onClick={()=>{SaveEditAttendance("Leave",data.leaveQuotaId);}} key={data.leaveBalanceId} isDisabled={NoAttSelectedDates.length === 0} >{data.leaveType} - {data.remainingQuota}</MenuItem>
                    })}
                  </MenuList>
                </Menu> */}
              </div>

              <div className="ag-theme-alpine">
                      <AgGridReact
                        ref={NoAttRef}
                        style={gridStyle}
                        domLayout={"autoHeight"}
                        rowData={EditAttSelectedEmp?.noAttList}
                        columnDefs={[
                          { 
                            headerName: "Attendance Date", 
                            field: "attendanceDate", 
                            headerCheckboxSelection: true,  
                            checkboxSelection: (params) => {
                              return params.data.attendanceDate;
                            }, 
                            cellRenderer:({data}) => moment(data.attendanceDate).format('DD-MMM-YYYY') 
                          },
                          { 
                            headerName: "Status", 
                            field: "attendanceStatus", 
                            width:200, 
                            cellRenderer : ({data}) => data.attendanceStatus === "A" ? 
                             <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
                               Absent
                             </span> : 
                             <span className="bg-yellow-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
                               {data.attendanceStatus}
                             </span>
                            },
                            {
                              headerName : "Action",
                              cellRenderer: ({data}) => <Button onClick={()=>{onMarkNoAttLeaveOpen();}} size='xs' colorScheme='blue' >Mark Leave</Button>
                            }
                        ]} 
                        defaultColDef={{
                          flex: 1,
                          sortable: true,
                          filter: "agTextColumnFilter",
                          floatingFilter: true,
                          cacheQuickFilter: true,
                          resizable: true
                        }} 
                        animateRows={true} 
                        pagination={true}
                        rowSelection="multiple"
                        paginationPageSize={8}
                        paginationNumberFormatter={paginationNumberFormatter}
                        onSelectionChanged={onSelectionNoAttChanged}
                      />
                    </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
              <Button colorScheme="purple" variant={'outline'} onClick={()=>{
                 setTreatAsLeave(0);
                 onNoAttActionClose();
                }}>Cancel</Button>
              {/* <Button isDisabled={!((isTreatAsLeave && TreatAsLeave !== 0) || isLOP || isPresent)} type="submit" colorScheme="purple" mr={3}>
                Save
              </Button> */}
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size="md" isCentered isOpen={isMarkNoAttLeaveOpen} onClose={()=>{
        setTreatAsLeave(0);
        onMarkNoAttLeaveClose();
      }}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'}>Mark Leave</ModalHeader>
          <ModalCloseButton mt={2} />
            <ModalBody>
              <div className="space-y-4">
               <div className="flex pb-1 gap-8 border-b">
                 <div>
                   <p className="text-sm text-gray-500">Employee Name</p>
                   <h6 className="font-medium">{EditAttSelectedEmp?.empName}</h6>
                 </div>
                 <div>
                   <p className="text-sm text-gray-500">Employee Code</p>
                   <h6 className="font-medium">{EditAttSelectedEmp?.empCode}</h6>
                 </div>
               </div>

               <div className="space-y-2">
                <label>Leave Type</label>
                <Select borderColor='gray.400' placeholder='Select Leave Type'>
                   {LeaveTypeList?.filter((filData) => filData?.isAbsenceSettledwithLeave)?.map((data)=> {
                      return <option key={data.leaveBalanceId} value={data.leaveQuotaId} >{data.leaveType} - {data.remainingQuota}</option>
                    })}
                </Select>

               </div>
               <div className="space-y-2">
                <label>Attachment</label>
                <Input borderColor='gray.400' type='file' />
               </div>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
              <Button colorScheme="purple" variant={'outline'} onClick={()=>{
                 onNoAttActionClose();
                }}>Cancel</Button>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal
        scrollBehavior="inside"
        size="full"
        isOpen={isReviewAllEmployeeOpen}
        onClose={onReviewAllEmployeeClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>All Employee Salary Review</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div className=" text-right mb-4">
              <Button 
               leftIcon={<FontAwesomeIcon icon={faDownload} />}
               onClick={()=>{
               ReviewAllEmpGrid.current.api.exportDataAsCsv();
          }} colorScheme="purple">
                Export for Approval
              </Button>
            </div>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={ReviewAllEmpGrid} // Ref for accessing Grid's API
                rowData={_.sortBy(EmployeeSalaryReviewRowData, [(a) => _.toNumber(_.replace(a.empCode, /[^0-9]/g, ""))])} // Row Data for Rows
                columnDefs={ReviewAllEmpSalaryColumnDefs} // Column Defs for Columns
                defaultColDef={EmployeeReviewColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                rowSelection="single"
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={onReviewAllEmployeeClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



      <Modal
        size="full"
        isOpen={isViewPayRegisterOpen}
        onClose={onViewPayRegisterClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pay Register for {monthNames[PayrollMonth-1]} {PayrollYear}</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody className="bg-slate-300">
              <div className="flex flex-wrap items-center mt-4 border-b-2">
                <div className="flex flex-col justify-between space-y-2 p-2 px-6">
                  <h6 className="text-base font-medium text-gray-500">
                    Total Payroll Cost
                  </h6>
                  <p className="text-3xl text-gray-800 font-bold">
                    {userData.currencyCode} {NumberFormat(TotalPayrollCost.totalPayrollCost)}
                  </p>
                  <p className="text-sm text-gray-50">_</p>
                </div>
              </div>

              <div className="justify-end flex gap-4 my-2">
              <Button 
               leftIcon={<FontAwesomeIcon icon={faDownload} />}
               onClick={()=>{
                onBtnExport();
                }} colorScheme="purple">
                  Export CSV
                </Button>

                <a href="/Bank File.txt" download>
                  <Button
                    leftIcon={<FontAwesomeIcon icon={faDownload} />}
                    colorScheme="purple"
                    variant="outline"
                  >
                    Download Bank File
                  </Button>
                </a>
              </div>

              <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={ReviewAllEmpGrid} // Ref for accessing Grid's API
                rowData={_.sortBy(EmployeeSalaryReviewRowData, [(a) => _.toNumber(_.replace(a.empCode, /[^0-9]/g, ""))])} // Row Data for Rows
                columnDefs={ReviewAllEmpSalaryColumnDefs} // Column Defs for Columns
                defaultColDef={EmployeeReviewColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={onViewPayRegisterClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



      <Modal
        size="full"
        isOpen={isManagePaySlipsOpen}
        onClose={onManagePaySlipsClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>PaySlips for {monthNames[PayrollMonth-1]} {PayrollYear}</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody className="bg-slate-300">
                <div className="px-5 flex py-3 space-x-5 ">
                  <div className="my-auto">
                    <FontAwesomeIcon
                      className="rotate-180 text-xl"
                      icon={faArrowTurnUp}
                    />
                  </div>
                  <Button isLoading={BulkPayslipLoading} loadingText="Generating Payslips..." isDisabled={PayslipSelectedEmp?.length === 0} onClick={()=>{HandleReleaseAllPayslip();}} colorScheme="purple">Generate Payslips</Button>
                  {/* <Button colorScheme="purple">Hold Back Payslips</Button> */}
                  <Button  isDisabled={PayslipSelectedEmp?.length === 0} onClick={()=>{EmailGeneratedPayslips();}} variant={'outline'} colorScheme="purple">Send Payslips by E-mail</Button>
                </div>

                <div className="ag-theme-alpine">
                  <AgGridReact
                    style={gridStyle}
                    domLayout={"autoHeight"}
                    ref={PayslipGrid} // Ref for accessing Grid's API
                    rowData={_.sortBy(EmployeeSalaryReviewRowData, [(a) => _.toNumber(_.replace(a.empCode, /[^0-9]/g, ""))])} // Row Data for Rows
                    columnDefs={ManagePaySlipsColumnDefs} // Column Defs for Columns
                    defaultColDef={EmployeeReviewColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    pagination={true}
                    rowSelection={'multiple'}
                    paginationPageSize={15}
                    paginationNumberFormatter={paginationNumberFormatter}
                    onSelectionChanged={onSelectionPayslipChanged}
                  />
                </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button variant={'outline'} colorScheme="purple" onClick={onManagePaySlipsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal 
        scrollBehavior="inside"
        size="full"
        isOpen={isGenerateSalarySlipOpen}
        onClose={onGenerateSalarySlipClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {EmpSalarySlipData.employeeName} PaySlip for{" "}
            {monthNames[EmpSalarySlipData.salaryMonth - 1]} {PayrollYear}
          </ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div className="p-4">
              <Button onClick={()=>{DownloadEmpSalarySlip(EmpSalarySlipData.empGuid,EmpSalarySlipData.salaryMonth,EmpSalarySlipData.salaryYear);}} leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'} >Download Salary Slip</Button>
              <PDFViewer showToolbar={false} width="100%" className="h-screen my-10">
               {userData.countryName === "India" ?  <IndiaSalarySlip/> : (userData.activeBranchName === 'G42' || userData.activeBranchName === 'G42 XFUND'  || userData.activeBranchName === "VR OpCo Ltd." || userData.activeBranchName === "DFOAD RSC LTD") ? <G42SalarySlip/> : userData.companyName === "Ethmar Holdings" ? <EthmarPrivateSalarySlip /> : <SharjahResearchSalarySlip/>}
              </PDFViewer>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={onGenerateSalarySlipClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



      <Modal
        size="full"
        isOpen={isBulkSalarySlipOpen}
        onClose={()=>{
          onBulkSalarySlipClose();
          setEmpsReleasedPayslips([]);
          setPayslipSelectedEmp([]);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Released Payslips {monthNames[PayrollMonth-1]} {PayrollYear}</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody className="bg-slate-300">
            <div className="space-y-2">
              <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-white uppercase bg-slate-700 ">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                       Employee Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Employee Code
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {EmpsReleasedPayslips?.map((PayslipData,i)=>{
                      return <DynamicSalarySlip key={i} data={PayslipData} BranchLogo={BranchLogo} />
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={onBulkSalarySlipClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size='full' isOpen={isImportAttendanceCountOpen} onClose={onImportAttendanceCountClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Total Attendance</ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedAttendanceCountData([])}} />
          <hr />
            <ModalBody>
              <div className='flex items-end gap-5 mt-4'>
                <div>
                <CSVLink data={ImportTotalAttendanceEmpData} headers={AttendanceCountHeads} filename={"Import Total Month Attendance.csv"}>
                 <Button leftIcon={<FontAwesomeIcon icon={faDownload}/>} size='sm' colorScheme='purple'>Download Sample Excel for Total Import</Button>
               </CSVLink>
                </div>

              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={({target}) => {
                const file = target.files[0];
                ImportedAttendanceCountReadExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(ImportedAttendanceCountData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedAttendanceCountData([]);
                    onImportAttendanceCountClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>


      <AlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent rounded={'3xl'}>
            <AlertDialogHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>
              Payroll Status
            </AlertDialogHeader>
            <AlertDialogBody>
            <div className='space-y-4 mt-1'>

            <div className='flex justify-between'>
             <h6>Payroll Configuration Setting</h6>
             {Validation?.payrollConfigurationSetting ?
             <h6>Completed <FontAwesomeIcon icon={faCircleCheck}  className='text-green-600' /></h6> : 
             <h6>Incomplete <FontAwesomeIcon icon={faXmarkCircle} className='text-red-600' /></h6>}
            </div>

            <div className='flex justify-between'>
             <h6>All Employee Salary Status</h6>
             {Validation?.employeeSalaryStatus ?
             <h6>Completed <FontAwesomeIcon icon={faCircleCheck}  className='text-green-600' /></h6> : 
             <h6>Incomplete <FontAwesomeIcon icon={faXmarkCircle} className='text-red-600' /></h6>}
            </div>

            <div className='flex justify-between'>
             <h6>Attendance Status</h6>
             {PayrollAttendanceStatus?.attendanceStatus ?
             <h6>Completed <FontAwesomeIcon icon={faCircleCheck}  className='text-green-600' /></h6> : 
             <h6>Incomplete <FontAwesomeIcon icon={faXmarkCircle} className='text-red-600' /></h6>}
            </div>

          </div>

          <div className="bg-slate-200 p-1.5 rounded-lg mt-2">
            <p className="text-sm text-gray-500">Please complete the required criteria to Run Payroll</p>
          </div>
            </AlertDialogBody>
            <hr />
            <AlertDialogFooter>
              <Button variant={'outline'} colorScheme="purple" onClick={onAlertClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </div>
  )
}

export default FinalRunPayroll