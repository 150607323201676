import React, { useState, useRef, useEffect } from "react";
import { Input, Button, Select, Switch,Textarea, Image, Avatar, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import ReactSelect from 'react-select';
import { Edit } from "react-feather";
import { LuMapPin } from "react-icons/lu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faMoneyBill1 } from "@fortawesome/free-regular-svg-icons";
import { BsPlusCircleDotted } from 'react-icons/bs';
import { useAuth } from "../Utils/AuthContext";
import usePost from "../Utils/usePost";
import useFetch from '../Utils/useFetch';
import { BranchMasterSchema } from "../Utils/FormValidations";
import authApi from "../Utils/AuthApis";

function BranchMaster() {

  const { userData } = useAuth();
  
  const { FetchedData: TimezoneDataList, Refetch:RefetchTimezoneList } = useFetch('/api/MasterComponent/GetTimezoneMasterList');
  const {IsPostLoading: BranchPostLoading, PostData: PostBranchData} = usePost('/api/BranchMaster/SaveBranchMaster');

  const [FormErrors, setFormErrors] = useState({});
  const [BranchFormDetails, setBranchFormDetails] = useState({
    branchId: 0,
    branchName: '',
    branchCode: '',
    countryId: '',
    countryName: '',
    stateId: '',
    stateName: '',
    address1: '',
    address: '',
    address2: '',
    pincode:0,
    currencyId: '',
    timeZoneName: '',
    companyName: userData?.companyName,
    isActive: true,
    companyId: userData?.companyId,
    _userId: '',
    _userName: '',
    logo: '',
    logoPath: '',
    viewLogoPath: undefined
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };

  const [ViewLogoPath, setViewLogoPath] = useState();
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);

  const [rowData, setRowData] = useState([]); 

  const HandleEditBranch = async (BranchData) => {
    
    setBranchFormDetails((prev)=>{
      return {
        ...prev,
        logoPath: BranchData?.logoPath,
        logo: BranchData?.logoPath,
        branchId: BranchData?.branchId,
        branchCode: BranchData?.branchCode,
        branchName: BranchData?.branchName,
        companyName: BranchData?.companyName,
        address1: BranchData?.address1,
        address2: BranchData?.address2,
        pincode: BranchData?.pincode,
        currencyId: BranchData?.currencyId,
        timezoneId: BranchData?.timeZoneId || '',
      }
    });

    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCountryMasterList`
      )
      console.log("Country List",response.data);

      const res = response.data;
      setBranchFormDetails((prev)=>{
        return {
          ...prev,
          countryId: res[res.findIndex(data => data.countryId === Number(BranchData.countryId))]?.countryId,
          countryName: res[res.findIndex(data => data.countryId === Number(BranchData.countryId))]
        }
      })
    } catch (error) {
      console.error(error)
    }


    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetStateListByCountryId?CountryId=${BranchData.countryId}`
      )
      console.log("State List",response.data);
      const res= response.data;
      setStateList(response.data);
      setBranchFormDetails((prev)=>{
        return {
          ...prev,
          stateId: res[res.findIndex(data => data.stateId === Number(BranchData.stateId))]?.stateId,
          stateName: res[res.findIndex(data => data.stateId === Number(BranchData.stateId))]
        }
      })
    } catch (error) {
      console.error(error)
    }

    handleDownloadFile(BranchData.logoPath);

  }


  const handleDownloadFile = async (DocPath) => {
    console.log("Document path", DocPath);
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
        { responseType: 'blob' } 
      );
  
      console.log("file Response", response);
  
      const blob = response.data;
      const fileURL = window.URL.createObjectURL(blob);
  
      let alink = document.createElement('a');
      alink.href = fileURL;
      setViewLogoPath(alink);
      setBranchFormDetails((prev) => {
        return {
          ...prev,
          viewLogoPath: alink
        };
      });
      onOpen();
    } catch (error) {
      console.error("File Api Error", error);
    }
  };
  

  useEffect(() => {
    getCountryList();
    getCurrencyList();
    getBranchList();
  }, []);

  const getBranchList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/GetBranchMasterList?CompanyId=${userData.companyId}`
      );
      console.log("Branch List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCountryList = async()=>{
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCountryMasterList`
      )
       console.log("Country List",response.data);
       setCountryList(response.data);
    } catch (err) {
      console.error(err);
    }
  }


  const getStateList = async(CountryID)=>{
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetStateListByCountryId?CountryId=${CountryID}`
      )
       console.log("State List",response.data);
       setStateList(response.data);
    } catch (error) {
      console.error(error)
    }
  }
  

  const getCurrencyList = async()=>{
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCurrencyMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Currency List",response.data);
      setCurrencyList(response.data);
    } catch (error) {
      console.error(error)
    }
  }





  const HandleBranchSwitch = async (checked,BranchData) => {
    if (window.confirm('Are you sure you want to perform this Action?')) {
    
      setRowData(prev => {
        const newState = prev.map((obj)=>{
          if (obj.branchId === BranchData.branchId) {
            return {...obj, isActive: checked};
          }
          return obj;
        })
        return newState
      })


  const BranchFormData = new FormData();

  BranchFormData.append('branchId',BranchData.branchId)
  BranchFormData.append('branchName',BranchData.branchName)
  BranchFormData.append('branchCode',BranchData.branchCode)
  BranchFormData.append('countryId',String(BranchData.countryId))
  BranchFormData.append('stateId',String(BranchData.stateId))
  BranchFormData.append('address1',BranchData.address1)
  BranchFormData.append('address',BranchData.address)
  BranchFormData.append('address2',BranchData.address2)
  BranchFormData.append('pincode',BranchData.pincode,)
  BranchFormData.append('currencyId',BranchData.currencyId)
  BranchFormData.append('timeZoneName',BranchData.timeZoneName)
  BranchFormData.append('companyName',BranchData.companyName)
  BranchFormData.append('isActive',checked)
  BranchFormData.append('companyId',userData.companyId)
  BranchFormData.append('_userId',String(userData.userId))
  BranchFormData.append('_userName',userData.userName)
  BranchFormData.append('logo',BranchData.logo)
  BranchFormData.append('logoPath',BranchData.logoPath)

  const response = await PostBranchData(BranchFormData);

  if(response){
    getBranchList();
  }


  }

  }


  const SaveBranchName = async (e) => {
    e.preventDefault();

    BranchMasterSchema.validate(BranchFormDetails,{abortEarly:false})
    .then(async() => {
      setFormErrors({});

      const BranchFormData = new FormData();

      BranchFormData.append('branchId',BranchFormDetails.branchId)
      BranchFormData.append('branchName',BranchFormDetails.branchName)
      BranchFormData.append('branchCode',BranchFormDetails.branchCode)
      BranchFormData.append('countryId',String(BranchFormDetails.countryId))
      BranchFormData.append('stateId',String(BranchFormDetails.stateId))
      BranchFormData.append('address1',BranchFormDetails.address1)
      BranchFormData.append('address',BranchFormDetails.address)
      BranchFormData.append('address2',BranchFormDetails.address2)
      BranchFormData.append('pincode',BranchFormDetails.pincode,)
      BranchFormData.append('currencyId',BranchFormDetails.currencyId)
      BranchFormData.append('timezoneId',BranchFormDetails.timezoneId)
      BranchFormData.append('companyName',BranchFormDetails.companyName)
      BranchFormData.append('isActive',true)
      BranchFormData.append('companyId',userData.companyId)
      BranchFormData.append('_userId',String(userData.userId))
      BranchFormData.append('_userName',userData.userName)
      BranchFormData.append('logo',BranchFormDetails.logo)
      BranchFormData.append('logoPath',BranchFormDetails.logoPath)
      
      const response = await PostBranchData(BranchFormData);

      if(response){
         onClose();
         getBranchList();
         setBranchFormDetails({
          branchId: 0,
          branchName: '',
          branchCode: '',
          countryId: '',
          countryName: '',
          stateId: '',
          stateName: '',
          address1: '',
          address: '',
          address2: '',
          pincode:0,
          currencyId: '',
          timeZoneName: '',
          companyName: userData.companyName,
          isActive: true,
          companyId: userData?.companyId,
          _userId: '',
          _userName: '',
          logo: '',
          logoPath: '',
          viewLogoPath: undefined
        });
        reset();
      }
    }).catch((validationErrors) => {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setFormErrors(newErrors);
    });
    
  };


  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Branch Master</h1>
      </div>

        <div className="flex flex-wrap gap-6">

          {rowData.map((data,i) => {
            return <div key={i} className="bg-white rounded-2xl w-96 drop-shadow-md p-3 ">
                <div className="flex gap-3 items-center mb-4">
                  <Avatar size={'md'} bgColor={'gray.200'} icon={<FontAwesomeIcon icon={faBuilding} className="text-purple-500 text-2xl"/> } />

                  <div className="flex justify-between w-full">

                  <div>
                     <h6 className="text-lg font-bold text-gray-800">{data.branchName}</h6>
                     <p className="text-sm font-light text-gray-600">{data.branchCode}</p>
                    </div>

                    <div className="flex gap-4 ">
                      <div>
                       <Switch
                         colorScheme="green"
                         isChecked={data.isActive}
                         size="md"
                         onChange={({target})=>{HandleBranchSwitch(target.checked,data)}}
                       />
                      </div>

                        <div>
                     <Tooltip 
                       borderRadius={"7px"}
                       hasArrow
                       label="Edit"
                       bgColor={"gray.600"}
                     >
                      <button onClick={()=>{
                        HandleEditBranch(data);
                      }}><Edit size="16px" className="text-blue-500 hover:text-blue-800 mt-1.5" /></button>
                     </Tooltip>
                        </div>
                    </div>
                  </div>

                </div>
                <div className="space-y-1.5">
                  <div className="flex items-center gap-2"><FontAwesomeIcon icon={faMoneyBill1} className="text-orange-400"/> {data.currencyName}</div>
                  <div className="flex items-center gap-2"><LuMapPin className="text-orange-400"/> {data.address1} {data.address2}, {data.pincode}</div>
                  <div className="flex items-center gap-2"><LuMapPin className="text-orange-400"/> {data.stateName}, {data.countryName}</div>
                </div>
              </div>
          })}

            <div onClick={onOpen} className="border-gray-200 text-gray-500 hover:text-gray-700 cursor-pointer border-2 bg-white bg-opacity-20 hover:bg-opacity-40 rounded-2xl w-96 flex items-center drop-shadow-md p-3 ">
              <div className="mx-auto ">
               <div className="space-y-2 text-center">
                <BsPlusCircleDotted size={'4rem'} className="mx-auto" />
                <p className="text-sm ">Add New Branch</p>
               </div>
              </div>
            </div>

        </div>
          

        <Modal size={'4xl'} isOpen={isOpen} onClose={()=>{
          onClose();
          setBranchFormDetails({
            branchId: 0,
            branchName: '',
            branchCode: '',
            countryId: '',
            countryName: '',
            stateId: '',
            stateName: '',
            address1: '',
            address: '',
            address2: '',
            pincode:0,
            currencyId: '',
            timeZoneName: '',
            companyName: userData.companyName,
            isActive: true,
            companyId: userData?.companyId,
            _userId: '',
            _userName: '',
            logo: '',
            logoPath: '',
            viewLogoPath: undefined
          });
          reset();
        }}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >Branch Details</ModalHeader>
          <ModalCloseButton className="mt-1.5" />

          <form onSubmit={SaveBranchName}>
          <ModalBody>

          <div>
                {BranchFormDetails?.logoPath !== undefined ?
                  <Image h={40} src={BranchFormDetails?.viewLogoPath} />
                  : ''}
                </div> 
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                  <div className="space-y-2">
                    <label>Branch Name</label>
                    <Input 
                      isInvalid={FormErrors?.branchName}
                      value={BranchFormDetails?.branchName || ""}
                      onChange={({target})=>{
                        setBranchFormDetails(
                          {
                            ...BranchFormDetails,
                            branchName: target.value
                          }
                        );
                      }}
                      borderColor="gray"
                      placeholder="Enter Branch Name"
                    />
                    {FormErrors?.branchName && <span className="text-red-600 my-1 text-sm">{FormErrors?.branchName}</span>}
                  </div>

                  <div className="grid grid-cols-2 gap-4">

                  <div className="space-y-2">
                    <label>Branch Code</label>
                    <Input
                      isInvalid={FormErrors?.branchCode}
                      value={BranchFormDetails?.branchCode || ""}
                      onChange={({target})=>{
                        setBranchFormDetails(
                          {
                            ...BranchFormDetails,
                            branchCode: target.value
                          }
                        );
                      }}
                      borderColor="gray"
                      placeholder="Enter Branch Code"
                    />
                    {FormErrors?.branchCode && <span className="text-red-600 my-1 text-sm">{FormErrors?.branchCode}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Branch Logo</label>
                    <Input
                      ref={FileRef}
                      accept="image/*"
                      isInvalid={FormErrors?.logo}
                      onChange={({target})=>{
                        setBranchFormDetails({
                          ...BranchFormDetails,
                          logo: target.files[0]
                        });
                        console.log("Logo",target.files[0]);
                      }}
                      borderColor="gray"
                      type={'file'}
                      placeholder="Enter Branch Code"
                    />
                    {FormErrors?.logo && <span className="text-red-600 my-1 text-sm">{FormErrors?.logo}</span>}
                  </div>
                    
                  </div>

                    <div className="space-y-2">
                      <label>Country Name</label>
                      <ReactSelect
                        required
                        options={CountryList}
                        value={BranchFormDetails?.countryName}
                        className="w-full my-auto"
                        onChange={(value) => {
                          console.log("Country ID",value.countryId);
                          setBranchFormDetails({
                            ...BranchFormDetails,
                            countryId:value.countryId,
                            countryName: value
                          })
                          getStateList(value.countryId);
                        }}
                        getOptionLabel={(option) => option.countryName}
                        getOptionValue={(option) => option.countryId}
                        placeholder="Select Country"
                      />
                      {FormErrors?.countryId && <span className="text-red-600 my-1 text-sm">{FormErrors?.countryId}</span>}
                    </div>

                    <div className="space-y-2">
                      <label>State Name</label>
                      <ReactSelect
                        required
                        options={StateList}
                        value={BranchFormDetails?.stateName}
                        className="w-full my-auto"
                        onChange={(value) => {
                          console.log("State ID",value.stateId);
                          setBranchFormDetails({
                            ...BranchFormDetails,
                            stateId:value.stateId,
                            stateName: value
                          })
                        }}
                        getOptionLabel={(option) => option.stateName}
                        getOptionValue={(option) => option.stateId}
                        placeholder="Select State"
                      />
                      {FormErrors?.stateId && <span className="text-red-600 my-1 text-sm">{FormErrors?.stateId}</span>}
                    </div>

                    <div className="space-y-2">
                      <label>Address 1</label>
                      <Textarea 
                        isInvalid={FormErrors?.address1}
                        value={BranchFormDetails?.address1 || ""}
                        onChange={({target})=>{
                          setBranchFormDetails(
                            {
                              ...BranchFormDetails,
                              address1: target.value
                            }
                          );
                        }}
                        borderColor="gray" 
                        placeholder="Enter Address" />
                        {FormErrors?.address1 && <span className="text-red-600 my-1 text-sm">{FormErrors?.address1}</span>}
                    </div>

                    <div className="space-y-2">
                      <label>Address 2</label>
                      <Textarea 
                        isInvalid={FormErrors?.address2}
                        value={BranchFormDetails?.address2 || ""}
                        onChange={({target})=>{
                          setBranchFormDetails(
                            {
                              ...BranchFormDetails,
                              address2: target.value
                            }
                          );
                        }}
                        borderColor="gray" 
                        placeholder="Enter Address" />
                        {FormErrors?.address2 && <span className="text-red-600 my-1 text-sm">{FormErrors?.address2}</span>}
                    </div>

                    <div className="space-y-2">
                      <label>Pincode</label>
                      <Input  
                        type="number"
                        isInvalid={FormErrors?.pincode}
                        value={BranchFormDetails?.pincode || ""}
                        onChange={({target})=>{
                          setBranchFormDetails(
                            {
                              ...BranchFormDetails,
                              pincode: target.value
                            }
                          );
                        }}
                        borderColor="gray" 
                        placeholder="Pincode" />
                      {FormErrors?.pincode && <span className="text-red-600 my-1 text-sm">{FormErrors?.pincode}</span>}
                    </div>

                    <div className="space-y-2">
                      <label>Currency Name</label>
                      <Select
                        isInvalid={FormErrors?.currencyId}
                        value={BranchFormDetails?.currencyId || ""}
                        onChange={({target})=>{
                          setBranchFormDetails(
                            {
                              ...BranchFormDetails,
                              currencyId: target.value
                            }
                          );
                        }}
                        borderColor="gray"
                        placeholder="Please Select Currency"
                      >
                        {CurrencyList.map((data,i)=>{
                          return <option key={i} value={data.currencyId} >{data.currencyName}</option>
                        })}
                      </Select>
                      {FormErrors?.currencyId && <span className="text-red-600 my-1 text-sm">{FormErrors?.currencyId}</span>}
                    </div>

                    <div className="space-y-2">
                      <label>Time Zone</label>
                      <Select
                        isInvalid={FormErrors?.timezoneId}
                        value={BranchFormDetails?.timezoneId || ""}
                        onChange={({target})=>{
                          setBranchFormDetails(
                            {
                              ...BranchFormDetails,
                              timezoneId: target.value
                            }
                          );
                        }}
                        borderColor="gray"
                        placeholder="Please Select Time Zone"
                      >
                        {TimezoneDataList?.map((data)=>{
                          return <option key={data?.timezoneId} value={data?.timezoneId}>{data?.timezoneName}</option>
                        })}
                      </Select>
                      {FormErrors?.timezoneId && <span className="text-red-600 my-1 text-sm">{FormErrors?.timezoneId}</span>}
                    </div>

                    <div className="space-y-2">
                      <label>Company Name</label>
                      <Input
                        isDisabled
                        isInvalid={FormErrors?.companyName}
                        value={BranchFormDetails?.companyName || ""}
                        borderColor="gray"
                        placeholder="Enter Company Name"
                      />
                      {FormErrors?.companyName && <span className="text-red-600 my-1 text-sm">{FormErrors?.companyName}</span>}
                    </div>

                  </div>
          </ModalBody>
          <hr />
          <ModalFooter>
          <Button 
            onClick={()=>{
              onClose();
              setBranchFormDetails({
                branchId: 0,
                branchName: '',
                branchCode: '',
                countryId: '',
                countryName: '',
                stateId: '',
                stateName: '',
                address1: '',
                address: '',
                address2: '',
                pincode:0,
                currencyId: '',
                timeZoneName: '',
                companyName: userData.companyName,
                isActive: true,
                companyId: userData?.companyId,
                _userId: '',
                _userName: '',
                logo: '',
                logoPath: '',
                viewLogoPath: undefined
              });
              reset();
            }} variant="outline" colorScheme="purple" mr={4}>Cancel</Button>
          <Button isLoading={BranchPostLoading} loadingText={'Saving...'} type="submit" colorScheme="purple">Save</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      </div>
    );
  }
  
  export default BranchMaster;
  