import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Input, Button, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, Textarea, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Spinner, Center } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { CheckCircle, XCircle } from "react-feather";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { faPaperclip, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";
import usePost from "../Utils/usePost";

function LeaveApply() {
  const { userData } = useAuth();
  const { IsPostLoading: IsLeaveApplyLoading, PostData: PostLeaveApplyData } = usePost('/api/Leave/SAVELeaveApply');

  const toast = useToast();
  const id = "toast";
  const Today = new Date();

  const {
    isOpen: isdraweropen,
    onOpen: ondraweropen,
    onClose: ondrawerclose,
  } = useDisclosure();

  const {
    isOpen: ismodalopen,
    onOpen: onmodalopen,
    onClose: onmodalclose,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [InProbation, setInProbation] = useState(false);

  const [LeaveHistoryDateRange, setLeaveHistoryDateRange] = useState([
    new Date(moment().startOf("month")),
    new Date(moment().endOf("month")),
  ]);
  const [LeaveHistoryStartDate, LeaveHistoryEndDate] = LeaveHistoryDateRange;

  const [PdfPath, setPdfPath] = useState();

  const HandleLeaveHistoryChange = (dates) => {
    const [start, end] = dates;
    console.log("Dates", dates, start,end);
    setLeaveHistoryDateRange(dates);
    if(start && end){
     getLeaveAppliedList(start, end);
    }
  };
  const [EmpList, setEmpList] = useState([]);
  const [SelectedEmp, setSelectedEmp] = useState([]);
  const [LeaveQuotaList, setLeaveQuotaList] = useState([]);

  const [LeaveAttachment, setLeaveAttachment] = useState();

  const [NoOfDaysLeave, setNoOfDaysLeave] = useState(0);
  const [AppliedLeaveType, setAppliedLeaveType] = useState([]);

  const [LeaveReason, setLeaveReason] = useState();
  const [EmpLeaveBalance, setEmpLeaveBalance] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [EmpReason, setEmpReason] = useState();

  useEffect(() => {
    setNoOfDaysLeave(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        (1000 * 3600 * 24) +
        1
    );
  }, [dateRange]);

  console.log("Start Date", startDate);
  console.log("End Date", endDate);

  console.log(
    "No.of Days",
    (new Date(endDate).getTime() - new Date(startDate).getTime()) /
      (1000 * 3600 * 24) +
      1
  );

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Employee Code",
      field: "empCode",
    },
    {
      headerName: "Employee Name",
      field: "empName",
    },

    {
      headerName: "Reporting Person",
      field: "reportingPersonName",
    },
    {
      headerName: "Days",
      field: "leaveNoOfDays",
      width: 100,
    },
    {
      headerName: "Date",
      field: "leaveFromDate",
      cellRenderer: (params) => (
        <div>
          {new Date(params.data.leaveFromDate).toLocaleDateString("en-GB")} -{" "}
          {new Date(params.data.leaveToDate).toLocaleDateString("en-GB")}
        </div>
      ),
    },
    {
      headerName: "Leave Type",
      field: "leaveType",
    },
    {
      headerName: "Leave Reason",
      field: "leaveReason",
      cellRenderer: (params) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            setEmpReason(params.data.leaveReason);
            onmodalopen();
          }}
        >
          {params.data.leaveReason}
        </div>
      ),
    },
    {
      headerName: "Attachment",
      field: "attachmentPath",
      cellRenderer: ({data}) =>
        data.attachmentPath !== null ? (
          <Button 
            colorScheme={"blue"}
            size={"xs"}
            onClick={() => {
              onOpen();
              handleDownloadFile(data.attachmentPath);
            }}
            leftIcon={<FontAwesomeIcon icon={faPaperclip} />}
          >
            View
          </Button>
        ) : (
          ""
        ),
    },

    {
      headerName: "Leave Status",
      field: "leaveStatus",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending" ? (
          <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
        ) : params.data.leaveStatus === "Approve" ||
          params.data.leaveStatus === "Approved" ? (
          <span className="bg-green-600 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Approved
          </span>
        ) : params.data.leaveStatus === "Reject" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Rejected
          </span>
        ) : params.data.leaveStatus === "Cancel" ? (
          <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Cancelled
          </span>
        ) : (
          ""
        ),
    },
    {
      headerName: "Action",
      field: "guId",
      pinned: "right",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending" ? (
          <div className="space-x-4">
            <Button
              size="xs"
              onClick={async () => {
                await authApi
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Approved`
                  )
                  .then((response) => {
                    console.log("Leave Approve Response", response);
                    let res = response.data;
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "info",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                    // getLeaveAppliedList(LeaveHistoryStartDate,LeaveHistoryEndDate);
                    setLeaveHistoryDateRange([
                      new Date(
                        moment(params.data.leaveFromDate).startOf("month")
                      ),
                      new Date(
                        moment(params.data.leaveFromDate).endOf("month")
                      ),
                    ]);
                    getLeaveAppliedList(
                      moment(params.data.leaveFromDate)
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                      moment(params.data.leaveFromDate)
                        .endOf("month")
                        .format("YYYY-MM-DD")
                    );

                    RunEmailService();
                  }).catch((err)=>{
                    console.error(err);

                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "Server Error",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                  });

              }}
              colorScheme="green"
            >
              <CheckCircle size="14px" className="mr-2" />
              Approve
            </Button>
            <Button
              size="xs"
              onClick={async () => {
                await authApi
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Reject`
                  )
                  .then((response) => {
                    console.log("Leave Reject Response", response);
                    let res = response.data;
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "info",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                    // getLeaveAppliedList(LeaveHistoryStartDate,LeaveHistoryEndDate);
                    getLeaveAppliedList(
                      moment(params.data.leaveFromDate)
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                      moment(params.data.leaveFromDate)
                        .endOf("month")
                        .format("YYYY-MM-DD")
                    );
                    setLeaveHistoryDateRange([
                      new Date(
                        moment(params.data.leaveFromDate).startOf("month")
                      ),
                      new Date(
                        moment(params.data.leaveFromDate).endOf("month")
                      ),
                    ]);

                    RunEmailService();
                  }).catch((err)=>{
                    console.error(err);

                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "Server Error",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                  });
              }}
              colorScheme="red"
            >
              <XCircle size="14px" className="mr-2" />
              Reject
            </Button>
          </div>
        ) : (
          ""
        ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true,
  }));

  useEffect(() => {
    getLeaveAppliedList(LeaveHistoryStartDate, LeaveHistoryEndDate);
    getEmployeeList();
    getLeaveQuotaList();
    RunEmailService();
  }, []);

  const RunEmailService = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_NOTI_URL}/api/EmailService/SendEmailBySendGrid?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Email Process Response",response);
    } catch (error) {
      console.error("Email Process Error",error);
    }
  }

  const getEmployeeList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee List", response.data);

      const res = response.data;

      let ActiveEmpList = res.filter((data) => data.isActive === true);

      setEmpList(ActiveEmpList);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmpList = async (EMPGUID) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log(response);
      const res = response.data
      setInProbation(res.filter(obj => obj.guId === EMPGUID).map((data) => data.isProbationEnd)[0])
      console.log("In Probation",res.filter(obj => obj.guId === EMPGUID).map((data) => data.isProbationEnd)[0]);

    } catch (error) {
      console.error(error);
    }
  }

  const getEmployeeLeaveBalance = async (EMPGUID) => {
    // console.log(userData.guId);
    // console.log(Today.getFullYear());
    // console.log(Today.getMonth()+1);
    // console.log(userData.companyId);
    // console.log(userData.isActiveBranch);

    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/EmployeeDashBoard/GetLeaveBalanceByEmpGuid?Empguid=${EMPGUID}&year=${Today.getFullYear()}&month=${
          Today.getMonth() + 1
        }&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Balance Data", response);
      setEmpLeaveBalance(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLeaveQuotaList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Quota List", response.data);
      setLeaveQuotaList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLeaveAppliedList = async (FromDate, ToDate) => {
    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Leave/GetLeaveApplyList?startdate=${new Date(
          FromDate
        ).toLocaleDateString("en-CA")}&Enddate=${new Date(
          ToDate
        ).toLocaleDateString("en-CA")}&CompanyId=${
          userData.companyId
        }&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Applied List", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(
      "Leave to be applied",
      new Date(moment().add(AppliedLeaveType.leaveAppliedBeforeDays, "days"))
    );
  }, [AppliedLeaveType]);

  console.log("Applied Leave Type", AppliedLeaveType);

  const SaveAppliedLeave = async (e) => {
    e.preventDefault();

    if(startDate === null){
      alert('Please Select Leave Start Date');
    }else if(endDate === null){
      alert('Please Select Leave End Date');
    }else{

    const LeaveUploadData = new FormData();

    LeaveUploadData.append("Attachment", LeaveAttachment);
    LeaveUploadData.append("empGuid", SelectedEmp.guId);
    LeaveUploadData.append("empCode", SelectedEmp.empCode);
    LeaveUploadData.append("leaveQuotaId", Number(AppliedLeaveType.leaveQuotaId || 0));
    LeaveUploadData.append("leaveFromDate",moment(startDate).format("YYYY-MM-DD"));
    LeaveUploadData.append("leaveToDate", moment(endDate).format("YYYY-MM-DD"));
    LeaveUploadData.append("leaveNoOfDays", NoOfDaysLeave);
    LeaveUploadData.append("leaveReason", LeaveReason);
    LeaveUploadData.append("leaveStatus", "Pending");
    LeaveUploadData.append("companyId", userData.companyId);
    LeaveUploadData.append("branchId", userData.isActiveBranch);

    const response = await PostLeaveApplyData(LeaveUploadData);

    if(response){
      getLeaveAppliedList(LeaveHistoryStartDate, LeaveHistoryEndDate);
      setSelectedEmp([]);
      setDateRange([null, null]);
      setLeaveReason("");
      setAppliedLeaveType("");
      setLeaveAttachment("");
      setNoOfDaysLeave(0);
      ondrawerclose();
      RunEmailService();
    }
  }
    // await authApi.post(
    //     `${process.env.REACT_APP_ACQ_URL}/api/Leave/SAVELeaveApply`,
    //     LeaveUploadData,{
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       }
    //     }).then((response) => {
    //     console.log(response);
    //     const res = response.data;
    //     if (!toast.isActive(id)) {
    //       toast({
    //         id,
    //         title: res,
    //         position: "top",
    //         status: "success",
    //         duration: 4000,
    //         isClosable: true,
    //       });
    //     }


    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     if (!toast.isActive(id)) {
    //       toast({
    //         id,
    //         title: "ERROR",
    //         description: `Leave not Added`,
    //         position: "top",
    //         status: "error",
    //         duration: 4000,
    //         isClosable: true,
    //       });
    //     }
    //   });
  };

  const handleDownloadFile = async (DocPath) => {
    console.log(DocPath);

    await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`
    )
      .then((response) => {
        console.log("file Response", response);

        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          // alink.download = fileURL;
          // alink.click();
          setPdfPath(alink);
          // onOpen();
        });
      })
      .catch((error) => {
        console.log("File Api Error", error);
      })
  };

  console.log("Pdf Path",PdfPath);

  return (
    <div>
      <div className="border-b flex justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Application</h1>
        <Button leftIcon={<FontAwesomeIcon icon={faPlus} />} size="sm" colorScheme="purple" onClick={ondraweropen}>
          Apply Leave
        </Button>
      </div>

      <div className="flex items-center justify-end mb-4">
        <label className="font-semibold text-lg mr-3 ">
          Leave Data Range :
        </label>

        <div>
          <DatePicker
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
            selectsRange={true}
            startDate={LeaveHistoryStartDate}
            endDate={LeaveHistoryEndDate}
            onChange={(update) => {
              //setLeaveHistoryDateRange(update) ;

              HandleLeaveHistoryChange(update);

              // getLeaveHistory(LeaveHistoryStartDate,LeaveHistoryEndDate);
            }}
            dateFormat="dd/MM/yyyy"
            // isClearable={true}
            placeholderText="Select Leave History Range"
          />
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Drawer
        isOpen={isdraweropen}
        placement="right"
        size="md"
        onClose={ondrawerclose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Apply Leave</DrawerHeader>
          <hr />

          <form className="block" onSubmit={SaveAppliedLeave}>
            <DrawerBody className={`${!SelectedEmp.guId ? "h-96" : ""} space-y-4`}>
              <div className="my-2">
                <ReactSelect
                  options={EmpList}
                  value={SelectedEmp}
                  onChange={(value) => {
                    setSelectedEmp(value);
                    getEmployeeLeaveBalance(value.guId);
                    getEmpList(value.guId);
                  }}
                  getOptionLabel={(option) =>`${option.firstName} ${option.lastName}  ${option.empCode}`}
                  getOptionValue={(option) => option.guId}
                  placeholder="Select Employee"
                />
              </div>

              {!SelectedEmp.guId || (
                <>
                  <div className="space-y-2">
                    <h6 className="font-medium">
                      Employee Name:{" "}
                      <span className="font-normal">
                        {SelectedEmp.firstName} {SelectedEmp.lastName}
                      </span>
                    </h6>
                    <h6 className="font-medium">
                      Employee Code:{" "}
                      <span className="font-normal">{SelectedEmp.empCode}</span>
                    </h6>
                    <h6 className="font-medium">
                      Department:{" "}
                      <span className="font-normal">
                        {SelectedEmp.departmentName}
                      </span>
                    </h6>
                    <h6 className="font-medium">
                      Designation:{" "}
                      <span className="font-normal">
                        {SelectedEmp.designationName}
                      </span>
                    </h6>
                    <h6 className="font-medium">
                      Reporting Person:{" "}
                      <span className="font-normal">
                        {SelectedEmp.reportingOfficename}
                      </span>
                    </h6>
                  </div>
                  <hr />


                  <div className="space-y-2">
                    <h6 className="text-lg font-semibold">Leave Balance</h6>
                    <div className="flex flex-wrap gap-5">
                      {EmpLeaveBalance.map((data, i) => {
                        return (
                          <div
                            key={i}
                            className="bg-blue-100 rounded-xl drop-shadow-md  px-2 py-1  hover:-translate-y-2 ease-in-out duration-200"
                          >
                            <h6 className="font-semibold ">
                              {data.leaveType} :{" "}
                              <span className="font-semibold">
                                {data.remainingQuota > data.openingQuota ? data.openingQuota : data.remainingQuota}
                                <span className="text-xs text-gray-500 font-normal">
                                  / {data.openingQuota}
                                </span>
                              </span>
                            </h6>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <hr />

                  <div className="space-y-2 w-1/1 mb-4">
                    <label>Leave Type</label>
                    <ReactSelect
                      options={LeaveQuotaList}
                      value={AppliedLeaveType}
                      onChange={(value) => {
                        setAppliedLeaveType(value);
                      }}
                      getOptionLabel={(option) => option.leaveDescription}
                      getOptionValue={(option) => option.leaveQuotaId}
                      placeholder="Select Leave Type"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="flex justify-between">
                      <label>Select Date range</label>
                      <label>No. of Days: {(startDate && endDate) && (NoOfDaysLeave || 0)}</label>
                    </div>
                    <DatePicker
                      required
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      // minDate={
                      //   AppliedLeaveType.medicalRequired === true
                      //      new Date(2000, 1, 1)
                      //     : new Date(moment().add(AppliedLeaveType.leaveAppliedBeforeDays,"days"))
                      // }
                      dateFormat="dd/MM/yyyy"
                      isClearable={true}
                      placeholderText="Select Date range"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Leave Reason</label>
                    <Textarea
                      isRequired
                      value={LeaveReason}
                      onChange={({ target }) => {
                        setLeaveReason(target.value);
                      }}
                      borderColor="gray.500"
                      placeholder="Leave Reason"
                    />
                  </div>

                  {AppliedLeaveType?.medicalRequired === true ? (
                    <div className="space-y-2">
                      <label>
                        Attach Document{" "}
                        <span
                          className={`${NoOfDaysLeave > AppliedLeaveType?.medicalAfterNoOfDays ? "hidden" : ""}`}
                        >
                          (Optional)
                        </span>
                      </label>
                      <Input
                        isRequired={
                          AppliedLeaveType?.medicalRequired === true &&
                          (NoOfDaysLeave > AppliedLeaveType?.medicalAfterNoOfDays)
                        }
                        onChange={({ target }) => {
                          setLeaveAttachment(target.files[0]);
                        }}
                        type="file"
                        borderColor="gray.500"
                        placeholder="Attach Document"
                      />
                    </div>
                  ) : (
                    " "
                  )}

                  {/* {NoOfDaysLeave > AppliedLeaveType?.maxLeaveQuota && <h6 className="px-2 py-1 text-white font-bold rounded-lg bg-red-600">Applied Leave is more than assigned quota</h6>} */}

                  <div className="space-x-3 my-2 text-right">
                  {/* {EmpLeaveBalance.find((empbal)=> empbal.leaveType === AppliedLeaveType.leaveType)?.remainingQuota > 0 ? */}
                <Button isLoading={IsLeaveApplyLoading} loadingText={"Submitting..."} type="submit" colorScheme="purple">
                  Submit
                </Button>
                 {/* : '' } */}
                    <Button
                      onClick={ondrawerclose}
                      variant="outline"
                      colorScheme="purple"
                    >
                      Cancel
                    </Button>
                  </div>

                </>
              )}


            </DrawerBody>

          </form>
        </DrawerContent>
      </Drawer>

      <Modal isOpen={ismodalopen} onClose={onmodalclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reason</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div>{EmpReason}</div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onmodalclose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attachment</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            {PdfPath === undefined ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> : <div id="pdf-div">
              <embed
                src={PdfPath + "#toolbar=0"}
                type="application/pdf"
                className="h-screen w-full !select-none"
                height={800}
                width={500}
              />
            </div>}
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme="purple" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default LeaveApply;
