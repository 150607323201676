import { Button, useToast, RadioGroup, useDisclosure, Modal, ModalOverlay, ModalContent, ModalFooter, ModalHeader, ModalCloseButton, ModalBody, Select, Input, Stack, Radio, Textarea, InputGroup, InputRightAddon, Tooltip } from "@chakra-ui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {  useRef,  useState,  useMemo, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { CheckCircle, Edit, XCircle } from "react-feather";
import ReactSelect from "react-select";
import moment from "moment";
import _ from "lodash";
import useValidation from "../Utils/useValidation";
import DatePicker from "react-datepicker";
import useFetch from "../Utils/useFetch";
import authApi from "../Utils/AuthApis";
import { useAuth } from "../Utils/AuthContext";


function AddSeparation() {
  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";

  const { FetchedData: SeparationData, Refetch: RefetchSeparationData } = useFetch('/api/EmployeeResignation/GetEmpResignationList',{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
  })

  const Validation = useValidation();

  const [SeparationType, setSeparationType] = useState();
  const [SeparationTypeList, setSeparationTypeList] = useState([]);
  const [AddSeparationTypeName, setAddSeparationTypeName] = useState();
  const [IsEdit, setIsEdit] = useState(false);

  const [NoticeDays, setNoticeDays] = useState(0);
  const [NoticePeriodMonths, setNoticePeriodMonths] = useState();
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [EmpList, setEmpList] = useState([]);
  const [SelectedEmp, setSelectedEmp] = useState([]);
  const [ResignationDate, setResignationDate] = useState();
  const [NoticeStart, setNoticeStart] = useState();
  const [NoticeEnd, setNoticeEnd] = useState();
  const [NoticeStatus, setNoticeStatus] = useState("1");
  const [ResigantionReason, setResigantionReason] = useState();
  const [SalaryStatus, setSalaryStatus] = useState("Hold Salary");
  const [HRComment, setHRComment] = useState();
  const [LastWorkingDate, setLastWorkingDate] = useState();
  const [HoldSalaryMonth, setHoldSalaryMonth] = useState();
  const [AllSeperationRowData, setAllSeperationRowData] = useState([]);
  const [AddSeparationReasonName, setAddSeparationReasonName] = useState();
  const [SeparationReasonList, setSeparationReasonList] = useState([]);

  useEffect(()=>{
    if(SeparationData){
      SeparationData?.filter((filData) => filData.resignationStatus === "Approved" || filData.resignationStatus === "Finalized")?.map(async(data)=>{
        if(new Date(data.lastWorkingDate) < new Date() ){
          try {
            const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${data.empGuId}&IsActive=false`);
            console.log("Out Response",response);
          } catch (err) {
            console.error("Out Error",err);
          }
        } else {
          try {
            const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${data.empGuId}&IsActive=true`);
            console.log("In Response",response);
          } catch (err) {
            console.error("In Error",err);
          }
        }
      })

      // SeparationData?.filter((filData) => filData.resignationStatus === "Rejected")?.map(async(data)=>{
      //   try {
      //     const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${data.empGuId}&IsActive=true`);
      //     console.log("Rejected In Response",response);
      //   } catch (err) {
      //     console.error("Rejected In Error",err);
      //   }
      // })
    }
  },[SeparationData])
  
  useEffect(() => {
    getResignationList();
    getSeparationTypeList();
    getSeparationReasonList();
    getNoticePeriodMonths();
  }, []);

  useEffect(() => {
    console.log(
      "Difference Between Days",
      moment(LastWorkingDate).diff(moment(NoticeEnd), "days")
    );
    setNoticeDays(moment(LastWorkingDate).diff(moment(NoticeEnd), "days") || 0);
  }, [NoticeEnd, LastWorkingDate]);

  const {
    isOpen: isAddResignationOpen,
    onOpen: onAddResignationOpen,
    onClose: onAddResignationClose,
  } = useDisclosure();

  const {
    isOpen: isAddSeparationTypeOpen,
    onOpen: onAddSeparationTypeOpen,
    onClose: onAddSeparationTypeClose,
  } = useDisclosure();

  const {
    isOpen: isAddSeparationReasonOpen,
    onOpen: onAddSeparationReasonOpen,
    onClose: onAddSeparationReasonClose,
  } = useDisclosure();

  const {
    isOpen: isEditResignationOpen,
    onOpen: onEditResignationOpen,
    onClose: onEditResignationClose,
  } = useDisclosure();

  const [columnDefs] = useState([
    { headerName: "Employee Name", field: "empName" },
    {
      headerName: "Resignation Date",
      field: "resignationDate",
      cellRenderer:(params) => <span>{moment(params.data.resignationDate).format('DD/MM/YYYY')}</span>
    },
    {
      headerName: "Notice Status",
      field: "isNoticeStatus",
      width: 140,
      cellRenderer: (params) => params.data.isNoticeStatus ? "YES" : "NO"
    },
    {
      headerName: "Last Working Day",
      field: "lastWorkingDate",
      cellRenderer:(params) => <span>{moment(params.data.lastWorkingDate).format('DD/MM/YYYY')}</span>
    },
    { headerName: "Exit Reason", field: "resignationReason" },
    { headerName: "Salary Status", field: "empSalaryStatus" },
    {
      headerName: "Separation Status",
      field: "resignationStatus",
      cellRenderer: (params) =>
        params.data.resignationStatus === "Pending" ? (
          <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Pending
          </span>
        ) : params.data.resignationStatus === "Approved" ? (
          <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Approved
          </span>
        ) : params.data.resignationStatus === "Rejected" ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Rejected
          </span>
        ) : params.data.resignationStatus === "Cancelled" ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Cancelled
          </span>
        ) : params.data.resignationStatus === "Finalized" ? (
          <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Finalized
          </span>
        ) : (
          ""
        ),
    },
    {
      headerName: "Action",
      // pinned:'right',
      floatingFilter:false,
      width: 300,
      cellRenderer: (params) =>
        params.data.resignationStatus === "Pending" ? (
          <div className="space-x-4">
            <Button
              onClick={async () => {
                let data = params.data;
                let body = {
                  resignationId: data.resignationId,
                  empGuId: data.empGuId,
                  empName: data.empName,
                  resignationDate: data.resignationDate,
                  resignationStatus: "Approved",
                  isNoticeStatus: data.isNoticeStatus,
                  noticeStartDate: data.noticeStartDate,
                  noticeEndDate: data.noticeEndDate,
                  resignationReason: data.resignationReason,
                  hrComment: data.hrComment,
                  empSalaryStatus: data.empSalaryStatus,
                  lastWorkingDate: data.lastWorkingDate,
                  resignRecoveryDays: data.resignRecoveryDays,
                  fnfDate: data.fnfDate,
                  salaryHoldMonth: data.salaryHoldMonth,
                  separationType: data.separationType,
                  resignationLinkId: data.resignationLinkId,
                  resigSalaryStatus: data.resigSalaryStatus,
                  resigSalaryHold: data.resigSalaryHold,
                  companyId: data.companyId,
                  branchId: data.branchId,
                };

                console.log("Separation Approve Body", body);

                await authApi
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveEmpResignation`,
                    body
                  )
                  .then((response) => {
                    const res = response.data;
                    console.log("res", res);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "info",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error.response);
                    console.log(error.response.data);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: "Resignation Not Approved.",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
                  RefetchSeparationData()
                getResignationList();
              }}
              size="xs"
              colorScheme="green"
            >
              <CheckCircle size="1rem" className="mr-1" />
              Approve
            </Button>
            <Button
              onClick={async () => {
                let data = params.data;
                let body = {
                  resignationId: data.resignationId,
                  empGuId: data.empGuId,
                  empName: data.empName,
                  resignationDate: data.resignationDate,
                  resignationStatus: "Rejected",
                  isNoticeStatus: data.isNoticeStatus,
                  noticeStartDate: data.noticeStartDate,
                  noticeEndDate: data.noticeEndDate,
                  hrComment: data.hrComment,
                  resignationReason: data.resignationReason,
                  empSalaryStatus: data.empSalaryStatus,
                  lastWorkingDate: data.lastWorkingDate,
                  resignRecoveryDays: data.resignRecoveryDays,
                  fnfDate: data.fnfDate,
                  salaryHoldMonth: data.salaryHoldMonth,
                  resignationLinkId: data.resignationLinkId,
                  resigSalaryStatus: data.resigSalaryStatus,
                  resigSalaryHold: data.resigSalaryHold,
                  separationType: data.separationType,
                  companyId: data.companyId,
                  branchId: data.branchId,
                };

                console.log("Separation Reject Body", body);

                await authApi
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveEmpResignation`,
                    body
                  )
                  .then((response) => {
                    const res = response.data;
                    console.log("res", res);

                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "info",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error.response);
                    console.log(error.response.data);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: "Resignation Not Rejected.",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
                  RefetchSeparationData()
                getResignationList();
              }}
              size="xs"
              colorScheme="red"
            >
              <XCircle size="1rem" className="mr-1" />
              Reject
            </Button>
            <Button
              onClick={() => {
                let editRes = params.data;

                console.log("Edit Row Data", editRes);

                setSelectedEmp(editRes);
                setResignationDate(new Date(editRes.resignationDate));
                setNoticeStatus(editRes.isNoticeStatus === true ? "1" : "2");
                setNoticeStart(new Date(editRes.noticeStartDate));
                setNoticeEnd(new Date(editRes.noticeEndDate));
                setResigantionReason(editRes.resignationReason);
                setSalaryStatus(editRes.empSalaryStatus);
                setLastWorkingDate(new Date(editRes.lastWorkingDate));
                setHRComment(editRes.hrComment || "");
                setSeparationType(editRes.separationType);
                setHoldSalaryMonth(editRes.salaryHoldMonth);
                setIsEdit(true);
                onEditResignationOpen();
              }}
              size="xs"
              colorScheme="blue"
              leftIcon={<Edit size="0.8rem" />}
            > 
              Edit
            </Button>
          </div>
        ) : params.data.resignationStatus === "Approved" ? (
          <div>
            <Button
              onClick={() => {
                let editRes = params.data;

                console.log("Edit Row Data", editRes);

                setSelectedEmp(editRes);
                setResignationDate(new Date(editRes.resignationDate));
                setNoticeStatus(editRes.isNoticeStatus === true ? "1" : "2");
                setNoticeStart(new Date(editRes.noticeStartDate));
                setNoticeEnd(new Date(editRes.noticeEndDate));
                setResigantionReason(editRes.resignationReason);
                setSalaryStatus(editRes.empSalaryStatus);
                setLastWorkingDate(new Date(editRes.lastWorkingDate));
                setHRComment(editRes.hrComment || "");
                setSeparationType(editRes.separationType);
                setHoldSalaryMonth(editRes.salaryHoldMonth);
                setIsEdit(true);
                onEditResignationOpen();
              }}
              size="xs"
              colorScheme="blue"
              leftIcon={<Edit size="0.8rem" />}
            >
              Edit
            </Button>
          </div>
        ) : (
          ""
        ),
    },
  ]);



  const getNoticePeriodMonths = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SeparationNoticePeriodMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Notice Period Month  Response", response);

      const res = response.data;

      setNoticePeriodMonths(res.noticePeriodMonth);
    } catch (error) {
      console.error(error);
    }
  };

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable: true,
  }));

  const getResignationList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpResignationList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Resignation List of Month", response.data);
      const AllResignation = response.data;

      setAllSeperationRowData(
        _.orderBy(AllResignation, ["resignationDate"], ["desc"])
      );

      getEmployeeList(AllResignation);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmployeeList = async (AllResignation) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee List", response.data);

      const res = response.data;

      let ActiveEmpList = res
        .filter((data) => data.isActive === true)
        .map((EmpList) => {
          return {
            ...EmpList,
            resignationStatus:
              AllResignation.filter(
                (ResignData) => ResignData.empGuId === EmpList.guId
              ).map((data) => data.resignationStatus)[0] || "Not Applied",
          };
        });

      console.log("Modified Emp List", ActiveEmpList);

      console.log(
        "Filetered Emp",
        ActiveEmpList.filter(
          (obj1) => !AllResignation.find((obj2) => obj1.guId === obj2.empGuId)
        )
      );
      setEmpList(
        ActiveEmpList.filter(
          (obj1) =>
            !AllResignation.find(
              (obj2) =>
                obj1.guId === obj2.empGuId &&
                obj2.resignationStatus !== "Rejected"
            )
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getSeparationReasonList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SeparationReasonMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log(" Reason List", response.data);
      const res = response.data;
      setSeparationReasonList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSeparationTypeList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetSeparationTypeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Speration Type List", response.data);
      setSeparationTypeList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(SelectedEmp);
  const handleAddResignation = async (e) => {
    e.preventDefault();

    let body = {
      empGuId: SelectedEmp.guId,
      empName: SelectedEmp.fullName,
      resignationDate: moment(ResignationDate).format('YYYY-MM-DD'),
      isNoticeStatus: NoticeStatus === "1" ? true : false,
      noticeStartDate: moment(NoticeStart).format('YYYY-MM-DD'),
      noticeEndDate: moment(NoticeEnd).format('YYYY-MM-DD'),
      resignationReason: ResigantionReason,
      empSalaryStatus: SalaryStatus,
      lastWorkingDate: moment(LastWorkingDate).format('YYYY-MM-DD'),
      resignationStatus: "Pending",
      hrComment: HRComment,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      salaryHoldMonth: HoldSalaryMonth,
      resignRecoveryDays: NoticeDays,
      // separationTypeId:SeparationType,
      separationType: SeparationType,
      // resignationLinkId:SeparationType,
      resignMonth: new Date(ResignationDate).getMonth(),
      resignYear: new Date(ResignationDate).getFullYear(),
      resigSalaryStatus: SalaryStatus,
      resigSalaryHold: HoldSalaryMonth,
    };

    console.log("Add Resignation Body", body);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveEmpResignation`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        RefetchSeparationData()
        getResignationList();
        onAddResignationClose();
        setSelectedEmp([]);
        setResignationDate("");
        setSeparationType("");
        setNoticeStart("");
        setNoticeEnd("");
        setNoticeStatus("1");
        setResigantionReason("");
        setSalaryStatus("Hold Salary");
        setHRComment("");
        setLastWorkingDate("");
        setHoldSalaryMonth("");
        setIsEdit(false);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Resignation Not Approved.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  console.log(SelectedEmp);

  const SaveEditResignation = async (e) => {
    e.preventDefault();

    let body = {
      resignationId: SelectedEmp.resignationId,
      empGuId: SelectedEmp.empGuId,
      empName: SelectedEmp.empName,
      resignationDate: moment(ResignationDate).format('YYYY-MM-DD'),
      isNoticeStatus: NoticeStatus === "1" ? true : false,
      noticeStartDate: moment(NoticeStart).format('YYYY-MM-DD'),
      noticeEndDate: moment(NoticeEnd).format('YYYY-MM-DD'),
      resignationReason: ResigantionReason,
      empSalaryStatus: SalaryStatus,
      lastWorkingDate: moment(LastWorkingDate).format('YYYY-MM-DD'),
      resignationStatus: "Pending",
      salaryHoldMonth: HoldSalaryMonth,
      separationType: SeparationType,
      resignRecoveryDays: NoticeDays,
      // separationTypeName: SeparationType,
      // resignationLinkId: SeparationType,
      resigSalaryStatus: SalaryStatus,
      resigSalaryHold: HoldSalaryMonth,
      hrComment: HRComment,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      resignMonth: new Date(ResignationDate).getMonth(),
      resignYear: new Date(ResignationDate).getFullYear(),
    };

    console.log("Edit Resignation Body", body);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveEmpResignation`,
        body
      )
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
        RefetchSeparationData()
        getResignationList();
        setIsEdit(false);
        onEditResignationClose();
        setSelectedEmp([]);
        setResignationDate("");
        setSeparationType("");
        setNoticeStart("");
        setNoticeEnd("");
        setNoticeStatus("1");
        setResigantionReason("");
        setSalaryStatus("Hold Salary");
        setHRComment("");
        setLastWorkingDate("");
        setHoldSalaryMonth("");
        setIsEdit(false);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Resignation Not Updated.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };



  const SaveAddSeparationType = async (e) => {
    e.preventDefault();

    let body = {
      separationTypeName: AddSeparationTypeName,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log(body);
    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveSeparationTypeMasteradd`,
        body
      )
      .then((response) => {
        const res = response.data;
        console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        getSeparationTypeList();
        onAddSeparationTypeClose();
        setAddSeparationTypeName("");
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Separation Type Not Added.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveSeparationReason = async (e) => {
    e.preventDefault();

    let body = {
      separationReasonName: AddSeparationReasonName,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log(body);
    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveSeparationReasonMasteradd`,
        body
      )
      .then((response) => {
        const res = response.data;
        console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        getSeparationReasonList();
        onAddSeparationReasonClose();
        setAddSeparationReasonName("");
      })
      .catch((error) => {
        console.log(error.response);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Separation Reason Not Added.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };


  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">All Separations</h1>
        <div>
          <Button
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            size={{ base: "xs", md: "sm", lg: "sm" }}
            onClick={onAddResignationOpen}
            colorScheme="purple"
          >
            Add Separation
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine mb-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={AllSeperationRowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>


      <Modal
        size="3xl"
        isOpen={isAddResignationOpen}
        onClose={() => {
          onAddResignationClose();
          setSelectedEmp([]);
          setResignationDate("");
          setSeparationType("");
          setNoticeStart("");
          setNoticeEnd("");
          setNoticeStatus("1");
          setResigantionReason("");
          setSalaryStatus("Hold Salary");
          setHRComment("");
          setLastWorkingDate("");
          setHoldSalaryMonth("");
          setIsEdit(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Separation</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={handleAddResignation}>
            <ModalBody>
              <div className="flex  align-middle pb-2">
                <h6 className="my-auto font-medium text-lg">
                  Search Employee Name:-
                </h6>
                <div className="ml-5 w-1/2">
                  <ReactSelect
                    options={EmpList}
                    value={SelectedEmp}
                    onChange={setSelectedEmp}
                    getOptionLabel={(option) =>
                      `${option.fullName} - ${option.empCode}`
                    }
                    getOptionValue={(option) => option.guId}
                    placeholder="Select Employee"
                  />
                </div>
              </div>
              <hr />
              {!SelectedEmp.guId || (
                <>
                  <div className="flex py-2 gap-8">
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Employee Name</p>
                      <h6 className="font-medium">{SelectedEmp.fullName}</h6>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Department</p>
                      <h6 className="font-medium">
                        {SelectedEmp.departmentName}
                      </h6>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Designation</p>
                      <h6 className="font-medium">
                        {SelectedEmp.designationName}
                      </h6>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Date of Joining</p>
                      <h6 className="font-medium">
                        {new Date(SelectedEmp.dateofjoin).toLocaleDateString(
                          "en-GB"
                        )}
                      </h6>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Reporting Officer</p>
                      <h6 className="font-medium">
                        {SelectedEmp.reportingOfficerName}
                      </h6>
                    </div>
                  </div>{" "}
                </>
              )}

              <div className="grid grid-cols-2 gap-6 mt-4">
                <div className="space-y-2 mb-2">
                  <label>Resignation Date</label>
                  <DatePicker
                    required
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={ResignationDate}
                    onChange={(date) =>{
                      setResignationDate(date);

                      NoticeStatus === "1"
                      ? setNoticeStart(
                          new Date(moment(date).format("YYYY-MM-DD"))
                        )
                      : setNoticeStart(date);
                    NoticeStatus === "1"
                      ? setNoticeEnd(
                          new Date(moment(date).add(Number(NoticePeriodMonths || 0), "days").format("YYYY-MM-DD"))
                        )
                      : setNoticeEnd(date);

                    setLastWorkingDate(
                      new Date(moment(date).add(Number(NoticePeriodMonths || 0), "days").format("YYYY-MM-DD"))
                    );
                    }}
                    placeholderText="Resignation Date"
                    dateFormat={"dd-MM-yyyy"}
                    minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
                  />

                </div>

                <div className="space-y-2 mb-6">
                  <label>Notice Served</label>
                  <RadioGroup onChange={setNoticeStatus} value={NoticeStatus}>
                    <Stack direction="row" className="space-x-4 mb-2">
                      <Radio borderColor="gray.400" value="1">
                        YES
                      </Radio>
                      <Radio borderColor="gray.400" value="2">
                        NO
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div className="space-y-2 mb-2">
                  <label>Notice Start Date</label>
                  <DatePicker
                    required
                    readOnly
                    disabled={NoticeStatus === "2" ? true : false}
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={NoticeStart}
                    onChange={(date) =>{
                      setNoticeStart(date);
                    }}
                    placeholderText="Notice Start Date"
                    dateFormat={"dd-MM-yyyy"}
                  />
                </div>

                <div className="space-y-2">
                  <label>Notice End Date</label>
                  <DatePicker
                    required
                    disabled={NoticeStatus === "2" ? true : false}
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={NoticeEnd}
                    onChange={(date) =>{
                      setNoticeEnd(date);
                    }}
                    placeholderText="Notice End Date"
                    dateFormat={"dd-MM-yyyy"}
                    minDate={new Date(ResignationDate)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-2">
                <div className="space-y-2">
                  <label>Salary Status</label>
                  <Select
                    value={SalaryStatus}
                    onChange={(e) => setSalaryStatus(e.target.value)}
                    borderColor="gray.400"
                    placeholder="Select option"
                  >
                    <option value="Hold Salary">Hold Salary</option>
                    <option value="Process Salary">Process Salary</option>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label>Hold Salary Month</label>
                  <Select
                    value={HoldSalaryMonth}
                    onChange={(e) => {
                      setHoldSalaryMonth(e.target.value);
                    }}
                    borderColor="gray.400"
                    placeholder="Select option"
                  >
                    <option value="0">0 Month</option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Month</option>
                    <option value="3">3 Month</option>
                  </Select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-2">
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <label>Last Working Day</label>
                    <label className="font-semibold">
                      {NoticeDays === 0 ? "Normal Days" : `${NoticeDays} Days`}
                    </label>
                  </div>
                  <DatePicker
                    required
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={LastWorkingDate}
                    onChange={(date) =>{
                      setLastWorkingDate(date);
                    }}
                    placeholderText="Last Working Date"
                    dateFormat={"dd-MM-yyyy"}
                    minDate={new Date(ResignationDate)}
                  />
                </div>

                <div className="space-y-2">
                  <label>Separation Reason</label>
                  {/* <Textarea value={ResigantionReason} onChange={(e)=>{setResigantionReason(e.target.value)}} borderColor='gray.400' rows={1} placeholder='Enter Reason' /> */}
                  <InputGroup>
                    <Select
                      value={ResigantionReason}
                      onChange={(e) => {
                        setResigantionReason(e.target.value);
                      }}
                      borderColor="gray.400"
                      placeholder="Select Separation Reason"
                    >
                      {SeparationReasonList.map((data, i) => {
                        return (
                          <option key={i} value={data.separationReasonName}>
                            {data.separationReasonName}
                          </option>
                        );
                      })}
                    </Select>

                    <Tooltip
                      rounded="5px"
                      hasArrow
                      label="Add Separation Reason"
                      bg="gray.300"
                      color="black"
                    >
                      <InputRightAddon
                        onClick={onAddSeparationReasonOpen}
                        className="cursor-pointer"
                        bg="purple.400"
                        children={
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faPlus}
                          />
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-4">
                <div className="space-y-2">
                  <label>Separation Type</label>

                  <InputGroup>
                    <Select
                      value={SeparationType}
                      onChange={(e) => {
                        setSeparationType(e.target.value);
                      }}
                      borderColor="gray.400"
                      placeholder="Select option"
                    >
                      {SeparationTypeList.map((data, i) => {
                        return (
                          <option key={i} value={data.separationTypeId}>
                            {data.separationTypeName}
                          </option>
                        );
                      })}
                    </Select>

                    <Tooltip
                      rounded="5px"
                      hasArrow
                      label="Add Separation Type"
                      bg="gray.300"
                      color="black"
                    >
                      <InputRightAddon
                        onClick={onAddSeparationTypeOpen}
                        className="cursor-pointer"
                        bg="purple.400"
                        children={
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faPlus}
                          />
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </div>
                <div className="space-y-2">
                  <label>HR Comment</label>
                  <Textarea
                    value={HRComment}
                    onChange={(e) => {
                      setHRComment(e.target.value);
                    }}
                    borderColor="gray.400"
                    rows={1}
                    placeholder="Enter Comment"
                  />
                </div>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={() => {
                  onAddResignationClose();
                  setSelectedEmp([]);
                  setResignationDate("");
                  setSeparationType("");
                  setNoticeStart("");
                  setNoticeEnd("");
                  setNoticeStatus("1");
                  setResigantionReason("");
                  setSalaryStatus("Hold Salary");
                  setHRComment("");
                  setLastWorkingDate("");
                  setHoldSalaryMonth("");
                  setIsEdit(false);
                }}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        size="3xl"
        isOpen={isEditResignationOpen}
        onClose={() => {
          onEditResignationClose();
          setSelectedEmp([]);
          setResignationDate("");
          setSeparationType("");
          setNoticeStart("");
          setNoticeEnd("");
          setNoticeStatus("1");
          setResigantionReason("");
          setSalaryStatus("Hold Salary");
          setHRComment("");
          setLastWorkingDate("");
          setHoldSalaryMonth("");
          setIsEdit(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Separation</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveEditResignation}>
            <ModalBody>
              <div className="flex py-2 gap-8">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Employee Name</p>
                  <h6 className="font-medium">{SelectedEmp.empName}</h6>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mt-4">
                <div className="space-y-2 mb-2">
                  <label>Resignation Date</label>

                  <DatePicker
                    required
                    readOnly={IsEdit}
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={ResignationDate}
                    onChange={(date) =>{
                      setResignationDate(date);
                    }}
                    placeholderText="Resignation Date"
                    dateFormat={"dd-MM-yyyy"}
                    minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
                  />

                </div>

                <div className="space-y-2 mb-6">
                  <label>Notice Status</label>
                  <RadioGroup onChange={setNoticeStatus} value={NoticeStatus}>
                    <Stack direction="row" className="space-x-4 mb-2">
                      <Radio borderColor="gray.400" value="1">
                        YES
                      </Radio>
                      <Radio borderColor="gray.400" value="2">
                        NO
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-2">
                <div className="space-y-2 ">
                  <label>Notice Start Date</label>
                  <DatePicker
                    required
                    readOnly
                    disabled={NoticeStatus === "2" ? true : false}
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={NoticeStart}
                    onChange={(date) =>{
                      setNoticeStart(date);
                    }}
                    placeholderText="Notice Start Date"
                    dateFormat={"dd-MM-yyyy"}
                  />
                </div>

                <div className="space-y-2">
                  <label>Notice End Date</label>
                  <DatePicker
                    required
                    disabled={NoticeStatus === "2" ? true : false}
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={NoticeEnd}
                    onChange={(date) =>{
                      setNoticeEnd(date);
                    }}
                    placeholderText="Notice End Date"
                    dateFormat={"dd-MM-yyyy"}
                    minDate={new Date(ResignationDate)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-2">
                <div className="space-y-2">
                  <label>Salary Status</label>
                  <Select
                    value={SalaryStatus}
                    onChange={(e) => setSalaryStatus(e.target.value)}
                    borderColor="gray.400"
                    placeholder="Select option"
                  >
                    <option value="Hold Salary">Hold Salary</option>
                    <option value="Process Salary">Process Salary</option>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label>Hold Salary Month</label>
                  <Select
                    value={HoldSalaryMonth}
                    onChange={(e) => {
                      setHoldSalaryMonth(e.target.value);
                    }}
                    borderColor="gray.400"
                    placeholder="Select option"
                  >
                    <option value="0">0 Month</option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Month</option>
                    <option value="3">3 Month</option>
                  </Select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-2">
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <label>Last Working Day</label>
                    <label className="font-semibold">
                      {NoticeDays === 0 ? "Normal Days" : `${NoticeDays} Days`}
                    </label>
                  </div>
                  <DatePicker
                    required
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selected={LastWorkingDate}
                    onChange={(date) =>{
                      setLastWorkingDate(date);
                    }}
                    placeholderText="Last Working Date"
                    dateFormat={"dd-MM-yyyy"}
                    minDate={new Date(ResignationDate)}
                  />
                </div>

                <div className="space-y-2">
                  <label>Separation Reason</label>
                  {/* <Textarea value={ResigantionReason} onChange={(e)=>{setResigantionReason(e.target.value)}} borderColor='gray.400' rows={1} placeholder='Enter Reason' /> */}
                  <InputGroup>
                    <Select
                      value={ResigantionReason}
                      onChange={(e) => {
                        setResigantionReason(e.target.value);
                      }}
                      borderColor="gray.400"
                      placeholder="Select Separation Reason"
                    >
                      {SeparationReasonList.map((data, i) => {
                        return (
                          <option key={i} value={data.separationReasonName}>
                            {data.separationReasonName}
                          </option>
                        );
                      })}
                    </Select>

                    <Tooltip
                      rounded="5px"
                      hasArrow
                      label="Add Separation Reason"
                      bg="gray.300"
                      color="black"
                    >
                      <InputRightAddon
                        onClick={onAddSeparationReasonOpen}
                        className="cursor-pointer"
                        bg="purple.400"
                        children={
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faPlus}
                          />
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-4">
                <div className="space-y-2">
                  <label>Separation Type</label>
                  <InputGroup>
                    <Select
                      value={SeparationType}
                      onChange={(e) => {
                        setSeparationType(e.target.value);
                      }}
                      borderColor="gray.400"
                      placeholder="Select option"
                    >
                      {SeparationTypeList.map((data, i) => {
                        return (
                          <option key={i} value={data.separationTypeId}>
                            {data.separationTypeName}
                          </option>
                        );
                      })}
                    </Select>

                    <Tooltip
                      rounded="5px"
                      hasArrow
                      label="Add Separation Type"
                      bg="gray.300"
                      color="black"
                    >
                      <InputRightAddon
                        onClick={onAddSeparationTypeOpen}
                        className="cursor-pointer"
                        bg="purple.400"
                        children={
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faPlus}
                          />
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </div>
                <div className="space-y-2">
                  <label>HR Comment</label>
                  <Textarea
                    value={HRComment}
                    onChange={(e) => {
                      setHRComment(e.target.value);
                    }}
                    borderColor="gray.400"
                    rows={1}
                    placeholder="Enter Reason"
                  />
                </div>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={() => {
                  onEditResignationClose();
                  setSelectedEmp([]);
                  setResignationDate("");
                  setSeparationType("");
                  setNoticeStart("");
                  setNoticeEnd("");
                  setNoticeStatus("1");
                  setResigantionReason("");
                  setSalaryStatus("Hold Salary");
                  setHRComment("");
                  setLastWorkingDate("");
                  setHoldSalaryMonth("");
                  setIsEdit(false);
                }}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isAddSeparationTypeOpen}
        onClose={onAddSeparationTypeClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Separation Type</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveAddSeparationType}>
            <ModalBody>
              <div className=" space-y-4">
                <div className="space-y-2">
                  <label>Separation Type Name</label>
                  <Input
                    value={AddSeparationTypeName || ""}
                    onChange={({ target }) => {
                      setAddSeparationTypeName(target.value);
                    }}
                    isRequired
                    borderColor="gray"
                    placeholder="Separation Type Name"
                  />
                </div>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button type="submit" colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={onAddSeparationTypeClose}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isAddSeparationReasonOpen}
        onClose={onAddSeparationReasonClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Separation Reason</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveSeparationReason}>
            <ModalBody>
              <div className=" space-y-4">
                <div className="space-y-2">
                  <label>Separation Reason Name</label>
                  <Input
                    value={AddSeparationReasonName || ""}
                    onChange={({ target }) => {
                      setAddSeparationReasonName(target.value);
                    }}
                    isRequired
                    borderColor="gray"
                    placeholder="Separation Type Name"
                  />
                </div>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button type="submit" colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={onAddSeparationReasonClose}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AddSeparation;
