import { Accordion, AccordionButton, AccordionIcon, AccordionPanel, AccordionItem, Input, Button, Switch, useDisclosure, Tooltip, InputRightAddon, ModalFooter, Modal, ModalBody, ModalCloseButton,ModalContent,ModalOverlay,ModalHeader, FormControl, FormLabel } from "@chakra-ui/react";
import React, { useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Edit, Plus } from "react-feather";
import useFetch from "../Utils/useFetch";
import usePost from "../Utils/usePost";
import { useAuth } from "../Utils/AuthContext";
import { DesignationSchema } from "../Utils/FormValidations";

export const AddDesignation = ({ onAddDesignationResponse }) => {

  const { userData } = useAuth();
  const { IsPostLoading, PostData } = usePost('/api/Master/SaveDesignationMaster');
  const { isOpen: isAddDesignationOpen, onOpen: onAddDesignationOpen, onClose: onAddDesignationClose } = useDisclosure();

  const [DesignationDetails, setDesignationDetails] = useState({
    designationId: 0,
    designationName: '',
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  return (
    <>
     <Tooltip placement='top' rounded='5px' hasArrow label='Add Designation' bg='gray.700' color='white'>
       <InputRightAddon onClick={onAddDesignationOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>

      <Modal isOpen={isAddDesignationOpen} onClose={()=>{
        onAddDesignationClose();
        setDesignationDetails({
          designationId: 0,
          designationName: '',
          isActive: true,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch
        })
      }}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Add Designation</ModalHeader>
          <ModalCloseButton mt={1.5} />
            <ModalBody py={4}>
             <FormControl variant="floating" isRequired >
               <Input
                 value={DesignationDetails?.designationName || ''}
                 onChange={({target})=>{
                   setDesignationDetails({...DesignationDetails, designationName : target.value});
                 }}
                 isRequired
                 borderColor="gray.400"
                 placeholder=""
               />
               <FormLabel className="!font-normal">Designation Name</FormLabel>
             </FormControl>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button onClick={async(e)=>{
            e.preventDefault();

            const response = await PostData(DesignationDetails);
            if(response){
              onAddDesignationClose();
              setDesignationDetails({
                designationId: 0,
                designationName: '',
                isActive: true,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch
              })

              if(onAddDesignationResponse){
                onAddDesignationResponse(true);
              }
            }
           }} isLoading={IsPostLoading} isDisabled={!DesignationDetails?.designationName?.length > 0} loadingText="Saving..." colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    onAddDesignationClose();
                    setDesignationDetails({
                      designationId: 0,
                      designationName: '',
                      isActive: true,
                      companyId: userData.companyId,
                      branchId: userData.isActiveBranch
                    })
                  }}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function DesignationMaster() {
  const {userData} = useAuth()

  const { FetchedData, Refetch } = useFetch(
    '/api/Master/GetDesignationMasterList',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });

  const { IsPostLoading, PostData } = usePost('/api/Master/SaveDesignationMaster');

  const [DesignationDetails, setDesignationDetails] = useState({
    designationId: 0,
    designationName: '',
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  });

  const [Errors, setErrors] = useState({});

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Designation Name",
      field: "designationName",
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleDesignationSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "isActive",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setDesignationDetails((prev)=>{
                return {...prev,
                    designationId: params.data.designationId,
                    designationName: params.data.designationName,
                    isActive: true,
                    companyId: userData.companyId,
                    branchId: userData.isActiveBranch,
                };
              });
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
            leftIcon={<Edit size="14px" />}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
    resizable: true
  }));


  const HandleDesignationSwitch = async (checked, DesigData) => {
    let body = {
      designationId: DesigData.designationId,
      srNo: DesigData.srNo,
      designationName: DesigData.designationName,
      isActive: checked,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);

    const response = await PostData(body);
    console.log(response);

    if(response){
      Refetch();
    }
  };

  const SaveDesignationMaster = async () => {

    DesignationSchema.validate(DesignationDetails,{abortEarly:false})
    .then(async() => {
      setErrors({});
      const response = await PostData(DesignationDetails);
      console.log(response);
      if(response){
        Refetch()
        setDesignationDetails({
          designationId: 0,
          designationName: '',
          isActive: true,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        });
      }
    }).catch((validationErrors) => {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Designation Master</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Designation
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <div className="space-y-2 w-1/3">
                  <label>Add Designation Name</label>
                  <Input
                    isInvalid={Errors?.designationName}
                    value={DesignationDetails.designationName || ""}
                    onChange={({ target }) => {
                      setDesignationDetails((prev)=>{
                        return {
                          ...prev,
                          designationName: target.value
                        }
                      });
                    }}
                    borderColor="gray"
                    placeholder="Add Designation Name"
                  />
                  {Errors?.designationName && <span className="text-red-600 my-1 text-sm">{Errors?.designationName}</span>}
                </div>

                <div className="space-x-3 my-2 text-right">
                  <Button
                    onClick={() => {
                      setDesignationDetails({
                        designationId: 0,
                        designationName: '',
                        isActive: true,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch,
                      });

                    }}
                    type="reset"
                    variant="outline"
                    colorScheme="purple"
                  >
                    Reset
                  </Button>
                  <Button isLoading={IsPostLoading} loadingText="Saving..." onClick={SaveDesignationMaster} colorScheme="purple">
                    Save
                  </Button>
                </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FetchedData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
          
        />
      </div>
    </div>
  );
}

export default DesignationMaster;
