import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Edit, FilePlus, X, XCircle } from "react-feather";
import { Avatar, Tabs, TabList, TabPanels, Tab, TabPanel, Input, Select, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay, Button, useDisclosure, Checkbox, Textarea, useToast, InputGroup, InputRightElement, IconButton } from "@chakra-ui/react";
import { Briefcase, File, Tv, User, Users, Book, Award } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEdit, faEye } from "@fortawesome/free-regular-svg-icons";
import DatePicker from "react-datepicker";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import useFetch from "../Utils/useFetch";
import usePost from "../Utils/usePost";
import { useAuth } from "../Utils/AuthContext";
import useValidation from "../Utils/useValidation";
import { BankDetailSchema, ComplianceDetailSchema, FamilyDetailSchema, PassportDetailSchema, PersonalDetailSchema, PreviousJobDetailSchema, QualificationDetailSchema } from "../Utils/FormValidations";
import authApi from "../Utils/AuthApis";
import useFormValidation from "../Utils/useFormValidation";

function EmpProfile() {
  const { userData } = useAuth();
  const Validation = useValidation();
  const { FormErrors, ValidateField } = useFormValidation();

  const FileRef = useRef();
  const reset = () => {
    FileRef.current.value = "";
  };

  const toast = useToast();
  const id = "toast";
  const { IsPostLoading: IsSaveDocumentLoading , PostData: PostDocumentData } = usePost('/api/EmployeeDetail/SaveEmployeeDocuumentDetail');

  const [tabIndex, setTabIndex] = useState(0);

  const [MaskedAadhar, setMaskedAadhar] = useState('');

  const maskAadhaarNumber = (aadhaar) => {
    if (!aadhaar || aadhaar.length !== 12) {
      return aadhaar;
    }
    return aadhaar.replace(/.(?=.{4})/g, '*');
  };

  const { FetchedData: EmpDocumentList , Refetch: RefetchEmpDocumentList } = useFetch('/api/EmployeeDetail/GetEmpDocuumentDetailsByGuid',{EmpGuid: userData.guId});
  const { FetchedData: ReportingPersonList , Refetch: RefetchReportingPersonList } = useFetch('/api/EmployeeDetail/GetReportingOfficerList',{CompanyId: userData?.companyId});
  const { FetchedData: DesignationList, Refetch: RefetchDesignationList} = useFetch('/api/Master/GetDesignationMasterList',{CompanyId: userData.companyId,BranchId: userData.isActiveBranch});
  const { FetchedData: DepartmentList, Refetch: RefetchDepartmentList } = useFetch('/api/Master/DepartmentMasterList', {CompanyId: userData.companyId,BranchId: userData.isActiveBranch});
  const { FetchedData: EmployeeCategoryList, Refetch: RefetchEmployeeCategoryList } = useFetch('/api/Master/GetEmployeeTypeMasterList', {CompanyId: userData.companyId,BranchId: userData.isActiveBranch});
  const { FetchedData: BankMasterList, Refetch: RefetchBankMasterList } = useFetch('/api/Master/GetBankMasterList', {CompanyId: userData.companyId,BranchId: userData.isActiveBranch});
  const { FetchedData: DocumentMasterList, Refetch: RefetchDocumentMasterList } = useFetch('/api/Master/GetDocumentMasterList', {CompanyId: userData.companyId,BranchId: userData.isActiveBranch});
  const { FetchedData: RoleList, Refetch: RefetchRoleList } = useFetch('/api/RoleAndPermission/GetRoleMasterList', {CompanyId: userData.companyId,BranchId: userData.isActiveBranch});
  const { FetchedData: EmpTabLock, Refetch: RefetchEmpTabLock } = useFetch(`/api/EmployeeDetail/GetEmpIsLockStatusById`,{ Guid: userData?.guId, CompanyId: userData.companyId, BranchId: userData.isActiveBranch });
  const { FetchedData: EmpTabData, Refetch: RefetchEmpTabData } = useFetch(`/api/EmployeeDetail/GetEmpIsCompleteStatusById`,{ Guid: userData?.guId, CompanyId: userData.companyId, BranchId: userData.isActiveBranch });
  const { FetchedData: CountryList, Refetch: RefetchCountryListData } = useFetch(`/api/Master/GetCountryMasterList`);

  const { IsPostLoading: IsPostGeneralEmpLoading, PostData: PostGeneralEmpData } = usePost('/api/EmployeeDetail/SaveEmployeeGeneralInfo');
  const { IsPostLoading: IsPostPersonalLoading, PostData: PostPersonalData } = usePost('/api/EmployeeDetail/SaveEmployeePersonalDetail');
  const { IsPostLoading: IsPostPassportLoading, PostData: PostPassportData } = usePost('/api/EmployeeDetail/SaveEmployeePassportVisaDetail');
  const { IsPostLoading: IsPostQualificationLoading, PostData: PostQualificationData } = usePost('/api/EmployeeDetail/SaveEmployeeQualificationDetail');
  const { IsPostLoading: IsPostPreviousJobLoading, PostData: PostPreviousJobData } = usePost('/api/EmployeeDetail/SaveEmployeePreviousJobDetail');
  const { IsPostLoading: IsPostEmpBankLoading, PostData: PostEmpBankData } = usePost('/api/EmployeeDetail/SaveEmployeeBankDetail');
  const { IsPostLoading: IsPostFamilyLoading, PostData: PostFamilyData } = usePost('/api/EmployeeDetail/SaveEmployeeFamilyDetail');
  const { IsPostLoading: IsPostComplianceLoading, PostData: PostComplianceData } = usePost('/api/EmployeeDetail/SaveEmpPFESIData');

  
  const {
    isOpen: isViewDocOpen,
    onOpen: onViewDocOpen,
    onClose: onViewDocClose
  } = useDisclosure();

  const [EmpFormErrors, setEmpFormErrors] = useState({});
  const [EmpGeneralInfo, setEmpGeneralInfo] = useState([]);

  const [GeneralInfoFormData, setGeneralInfoFormData] = useState({
    title: '',
    guId: userData?.guId,
    id: 0,
    empCode: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateofjoin: '',
    designation: '',
    department: '',
    selfReporting: false,
    reportingOfficersId: '',
    mobile: '',
    officeEmail: '',
    isReportingOfficer: '',
    regulationDate: '',
    roleId: 0,
    empType: '',
    probationStartDate: '',
    probationEndDate: '',
    isActive: true,
    isComplete: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  const [PersonalFormData, setPersonalFormData] = useState({
    empGuid: userData?.guId,
    dateOfBirth: '',
    nationality: '',
    gender: '',
    personalMobile: '',
    personalEmail: '',
    placeOfBirth: '',
    bloodGroup: '',
    maritalStatus: '',
    citizenShip: '',
    emergencyContactNo: '',
    contactName: '',
    relationMaillingAddress: '',
    familyMemberName: '',
    familyMemberRelation: '',
    familyMemberContact: '',
    presentHouseNo: '',
    presentAddress1: '',
    presentAddress2: '',
    presentLandmark: '',
    presentCountry: '',
    presentState: '',
    presentCity: '',
    presentPin: '',
    addressStatus: false,
    permanentHouseNo: '',
    permanentAddress1: '',
    permanentAddress2: '',
    permanentLandmark: '',
    permanentCountry: '',
    permanentState: '',
    permanentCity: '',
    permanentPin: '',
    isComplete: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  const [PassportFormData, setPassportFormData] = useState({
    guId: userData?.guId,
    visaStatus: '',
    visaType: '',
    visaNo: '',
    vissaIssuePlace: '',
    visaStartDate: '',
    visaExpiryDate: '',
    passportNo: '',
    passportExpiryDate: '',
    labourCardNo: '',
    labourCardExpiry: '',
    isComplete: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  });

  const [QualificationFormData, setQualificationFormData] = useState({
    guId: userData?.guId,
    empQualification: '',
    empUniversity: '',
    empYearOfPassing: '',
    instituteName: '',
    status: '',
    country: '',
    city: '',
    state: '',
    isComplete: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  const [PreviousJobFormData, setPreviousJobFormData] = useState({
    guId: userData?.guId,
    startDate: '',
    endDate: '',
    salary: '',
    jobDescription: '',
    position: '',
    company: '',
    isComplete: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  const [BankFormData, setBankFormData] = useState({
    guId: userData?.guId,
    empCode: '',
    bankName: '',
    bankBranchName: '',
    accountNumber: '',
    ifscCode: '',
    personalId: '',
    routingAndSwiftCode: '',
    ibanNumber: '',
    isActive: true,
    isComplete: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  const [EmpDocDetails, setEmpDocDetails] = useState({
    empDocumentId: 0,
    guId: userData?.guId,
    documentType: "",
    documentNumber: "",
    documentName: '',
    isValidityReq: false,
    validUpto: "",
    attachment: "",
    attachmentPath: "",
    remark: "",
    companyId: userData?.companyId,
    branchId: userData?.isActiveBranch
  })


  const [FamilyFormData, setFamilyFormData] = useState({
    empFamilyDetailId: 0,
    guId: userData?.guId,
    empCode: '',
    gender: '',
    memberName: '',
    relation: '',
    dob: '',
    emiratesID: '',
    visaDetail: '',
    passportDetail: '',
    companyId: userData?.companyId,
    branchId: userData?.isActiveBranch
  });

  const [ComplianceFormData, setComplianceFormData] = useState({
    empGuid : userData?.guId,
    empCode : EmpGeneralInfo?.empCode,
    empEPFNo : '',
    empESICNo : '',
    empEPFUANNo : '',
    isComplete: true,
    companyId : userData.companyId,
    branchId : userData.branchId
  })



  const [PdfPath, setPdfPath] = useState();


  const [ProfilePicPath, setProfilePicPath] = useState();
  const [ActivePlanDetails, setActivePlanDetails] = useState([]);

  const [EmployeeList, setEmployeeList] = useState([]);



  const [StateList, setStateList] = useState([]);

  const [EmpGrade, setEmpGrade] = useState();

  //Induction DetailsVariables
  const [IsInductionCompleted, setIsInductionCompleted] = useState(false);
  const [InductionCompleteDate, setInductionCompleteDate] = useState();
  const [InductionGivenBy, setInductionGivenBy] = useState();



  const gridRef = useRef();

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [DocumentColumnDefs, setDocumentColumnDefs] = useState([
    {
      headerName: "Document Type",
      field: "documentName",
    },
    {
      headerName: "Document Number",
      field: "documentNumber",
    },
    {
      headerName: "Remark",
      field: "remark",
    },
    {
      headerName: "Action",
      cellRenderer: (params) => <div className="space-x-4">
        <Button
          leftIcon={<FontAwesomeIcon icon={faEdit} />}
          colorScheme="purple"
          variant='outline'
          size={'xs'}
          onClick={()=>{
            setEmpDocDetails({
              ...params.data,
              attachment: params.data.attachmentPath || null
            });
          }}
        >
          Edit
        </Button>

    {params.data.attachmentPath !== null && params.data.attachmentPath !== "null" && <Button
      leftIcon={<FontAwesomeIcon icon={faEye} />}
      colorScheme="purple"
      variant='outline'
      size={'xs'}
      onClick={async()=>{
       await fetch( 
         `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${params.data.attachmentPath}`,
     ).then((response) => {
      console.log("file Response", response);


      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        // alink.download = fileURL;
        // alink.click();
        setPdfPath(alink);
        onViewDocOpen();
      });
    })
    .catch((error) => {
      console.log("File Api Error", error);
    });
      }}
    >
      View
    </Button>}
      </div>
    },
  ]);

  const [familyrowData, setfamilyRowData] = useState([]); // Table Data Variable

  const [familycolumnDefs, setsecondColumnDefs] = useState([
    {
      headerName: "Member Name",
      field: "memberName",
    },
    {
      headerName: "Relation",
      field: "relation",
    },
    {
      headerName: "Age",
      field: "age",
    },
    {
      headerName: "Gender",
      field: "gender",
    },
    {
      headerName: "Emirates Id",
      field: "emiratesID",
    },
    {
      headerName: "Visa Detail",
      field: "visaDetail",
    },
    {
      headerName: "Passport Detail",
      field: "passportDetail",
    },
    {
      headerName: "Action",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setFamilyFormData(params.data);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));


  useEffect(() => {
    getEmpGeneralInfo();
    getEmpList();
    getEmpInductionDetails();
    getEmpPersonalDetails();
    getEmpPassportDetails();
    getEmpQualificationDetails();
    getEmpPreviousJobDetails();
    getEmpBankDetails();
    getEmpFamilyDetails();
    GetComplianceDetails();
    UpdatePlanDetails();
    GetUserPlanDetails();
    GetEmpGradeInfo();
  }, []);

  const GetEmpGradeInfo = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpGradAndOtherDetailByGuid?EmpGuid=${userData?.guId}`
      )
      console.log("Emp Grade Info",response);
      setEmpGrade(response?.data)
    } catch (err) {
      console.error(err);
    }
  }


  const UpdatePlanDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Plan/UpdateSubscritpionData?CompanyId=${userData.companyId}`
      );
      console.log("Plan Update Res", response);
    } catch (err) {
      console.error(err);
    }
  };

  const GetUserPlanDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetUserSubscriptionByEmail?EmailId=${userData.userName}`
      );
      console.log("Active Plan Details", response);
      setActivePlanDetails(response.data);
    } catch (err) {
      console.error(err);
    }
  };



  const getEmpList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log(response);
      setEmployeeList(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const getStateList = async (CountryID) => {
    console.log("Country ID", CountryID);

    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Master/GetStateListByCountryId?CountryId=${CountryID || 0}`
      );
      console.log("State List", response.data);
      setStateList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

console.log("Generalk indo",GeneralInfoFormData, GeneralInfoFormData?.roleId);
  const getEmpGeneralInfo = async() => {
    try {
        const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeGeneralInfoByGuid?EmpGuid=${userData.guId}`)
        console.log("Emp General Info Response",response);

        const res = response.data;

        setEmpGeneralInfo(response.data);
        setGeneralInfoFormData({
          ...res,
          dateofjoin: new Date(res.dateofjoin),
          regulationDate: new Date(res.regulationDate),
          probationEndDate: new Date(res.probationEndDate),
          probationStartDate: new Date(res.probationStartDate),
          selfReporting: res.reportingOfficersId === 0 ? true : false
        });

        FetchProfilePic(res.profilePicPath);
      } catch (error) {
          console.error(error);
      }
  }

  const FetchProfilePic = async (FilePath) => {
    await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${FilePath}`
    )
      .then((response) => {
        console.log("file Response", response);

        const fileURL = response.url;

        console.log("File URL", fileURL);

        let alink = document.createElement("a");
        alink.href = fileURL;
        // setViewChecklistAttachment(alink);
        // onViewAttachmentOpen();

        console.log("alink", alink);
        // alink.download = fileURL;
        // alink.click();

        setProfilePicPath(fileURL);
      })
      .catch((error) => {
        console.log("File Api Error", error);
      });
  };

  const getEmpInductionDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeInductionDetailByGuid?EmpGuid=${userData.guId}`
      );
      console.log("Emp Induction Details", response.data);
      const res = response.data;
      setInductionCompleteDate(moment(res.inductionDate).format("YYYY-MM-DD"));
      //  setInductionCompleteDate(new Date(res.inductionDate));

      setInductionGivenBy(res.inductionGivenBy);

      if (res.inductionDate) {
        setIsInductionCompleted(true);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const getEmpPersonalDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPersonalInfoByGuid?EmpGuid=${userData?.guId}`
    )
    // console.log("Emp Personal Details",response.data);

    const res = response.data;
    setPersonalFormData({
      ...res,
      addressStatus: res.addressStatus,
      dateOfBirth: new Date(res.dateOfBirth || new Date(moment().subtract(20, 'years').format('YYYY-MM-DD')))
    });
    } catch (error) {
      console.error(error)
    }
  }

  const getEmpPassportDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPassportVisaByGuid?EmpGuid=${userData?.guId}`
    )
     // console.log("Emp Passport Details",response.data);
     const res = response.data;
     setPassportFormData({
      ...res,
      visaStartDate: new Date(res.visaStartDate || new Date()),
      visaExpiryDate: new Date(res.visaExpiryDate || new Date()),
      passportExpiryDate: new Date(res.passportExpiryDate || new Date()),
      labourCardExpiry: new Date(res.labourCardExpiry || new Date())
    })
    } catch (error) {
      console.error(error)
    }
  }

  const getEmpQualificationDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpQualificationByGuid?EmpGuid=${userData?.guId}`
    )
    // console.log("Emp Qualification Details",response.data);
    const res = response.data;
    
    getStateList(res.country || 0)
    setQualificationFormData({
      ...res,
      empYearOfPassing: new Date(res.empYearOfPassing || new Date())
    })


    } catch (error) {
      console.error(error)
    }
  }


  const getEmpPreviousJobDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPreviousJobDetailsByGuid?EmpGuid=${userData?.guId}`
      )
       console.log("Emp Previous Job Details",response);
      const res = response.data;
      setPreviousJobFormData({
        ...res,
        startDate: new Date(res.startDate || new Date()),
        endDate: new Date(res.endDate || new Date())
      })

    } catch (error) {
      console.error(error)
    }
  }




  const getEmpBankDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeBankDetail?EmpGuid=${userData?.guId}`
    )
    console.log("Emp Bank Details",response);
    const res = response.data;

    setBankFormData(res)

    } catch (error) {
      console.error(error)
    }
  }

  const getEmpFamilyDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeFamilyDetail?EmpGuid=${userData.guId}`
      );
      console.log("Emp Family Details", response);
      setfamilyRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetComplianceDetails = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPFESIDataByGuid?EmpGuid=${userData.guId}`
      );
      console.log("Emp Compliance Details", response);
      const res = response.data;
      setComplianceFormData(res)
    } catch (err) {
      console.error(err);
    }
  };


  const SaveEmpInductionDetails = async (e) => {
    e.preventDefault();

    let body = {
      GuId: userData.guId,
      InductionDate: InductionCompleteDate,
      InductionGivenBy: InductionGivenBy,
      CompanyId: userData.companyId,
      BranchId: userData.branchId,
    };

    console.log("Emp Induction Save Body", body);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeInductionDetail`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        setTabIndex(2);

        getEmpInductionDetails();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };


  const SaveEmpPersonalInfo = async () => {
    setEmpFormErrors({});

    PersonalDetailSchema.validate(PersonalFormData,{abortEarly:false})
    .then(async() => {
      let body = {
        ...PersonalFormData,
        empGuid: userData?.guId,
        dateOfBirth: moment(PersonalFormData?.dateOfBirth).format('YYYY-MM-DD'),
        permanentHouseNo: PersonalFormData?.addressStatus ? PersonalFormData.presentHouseNo : PersonalFormData?.permanentHouseNo,
        permanentAddress1: PersonalFormData?.addressStatus ? PersonalFormData.presentAddress1 : PersonalFormData?.permanentAddress1,
        permanentAddress2: PersonalFormData?.addressStatus ? PersonalFormData.presentAddress2 : PersonalFormData?.permanentAddress2,
        permanentLandmark: PersonalFormData?.addressStatus ? PersonalFormData.presentLandmark : PersonalFormData?.permanentLandmark,
        permanentCountry: PersonalFormData?.addressStatus ? PersonalFormData.presentCountry : PersonalFormData?.permanentCountry,
        permanentState: PersonalFormData?.addressStatus ? PersonalFormData.presentState : PersonalFormData?.permanentState,
        permanentCity: PersonalFormData?.addressStatus ? PersonalFormData.presentCity : PersonalFormData?.permanentCity,
        permanentPin: PersonalFormData?.addressStatus ? PersonalFormData.presentPin : PersonalFormData?.permanentPin,
        isComplete: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      };
      console.log("Emp Personal Save Body",body);
      const response = await PostPersonalData(body);
      if(response){
        setTabIndex(2);
        getEmpPersonalDetails();
        setEmpFormErrors({});

      }
    }).catch((validationErrors) => {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setEmpFormErrors(newErrors);
    });

  }

 const SaveEmpPassportDetails = async (e) => {
  e.preventDefault();
  PassportDetailSchema.validate(PassportFormData,{abortEarly:false})
  .then(async() => {
    let body ={
      ...PassportFormData,
      guId: userData?.guId,
      visaStartDate: moment(PassportFormData?.visaStartDate).format('YYYY-MM-DD'),
      visaExpiryDate: moment(PassportFormData?.visaExpiryDate).format('YYYY-MM-DD'),
      passportExpiryDate: moment(PassportFormData?.passportExpiryDate).format('YYYY-MM-DD'),
      labourCardExpiry: moment(PassportFormData?.labourCardExpiry).format('YYYY-MM-DD'),
      isComplete: true,
      companyId: userData?.companyId,
      branchId: userData?.isActiveBranch
    }
    console.log("Emp Passport Save Body",body);
    const response = await PostPassportData(body);
    if(response){
      setTabIndex(3);
      getEmpPassportDetails();
      setEmpFormErrors({});

    }
  }).catch((validationErrors) => {
    console.log("Validation ERrros",validationErrors);
    const newErrors = {};
    validationErrors.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setEmpFormErrors(newErrors);
  });
 }


const SaveEmpQualificationDetails = async (e) => {
  e.preventDefault();

  QualificationDetailSchema.validate(QualificationFormData,{abortEarly:false})
  .then(async() => {
    setEmpFormErrors({});

    let body={
      ...QualificationFormData,
      guId: userData?.guId,
      empYearOfPassing: moment(QualificationFormData?.empYearOfPassing).format('YYYY-MM-DD'),
      isComplete:true,
      companyId : userData.companyId,
      branchId : userData.isActiveBranch
    }
    console.log("Emp Qualification Save Body",body);
    
    const response = await PostQualificationData(body);

    if(response){
      setTabIndex(4);
      getEmpQualificationDetails();
      setEmpFormErrors({});
    }

  }).catch((validationErrors) => {
    const newErrors = {};
    validationErrors.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setEmpFormErrors(newErrors);
  });

}


 const SaveEmpPreviousJobDetails = async (e) => {
  e.preventDefault();
  setEmpFormErrors({})

  PreviousJobDetailSchema.validate(PreviousJobFormData,{abortEarly:false})
  .then(async() => {

  let body={
    ...PreviousJobFormData,
    guId: userData?.guId,
    startDate: moment(PreviousJobFormData?.startDate).format('YYYY-MM-DD'),
    endDate: moment(PreviousJobFormData?.endDate).format('YYYY-MM-DD'),
    isComplete:true,
    companyId : userData?.companyId,
    branchId : userData?.isActiveBranch
  }

   console.log("Emp Previous Job Save Body",body);

   const response = await PostPreviousJobData(body);

   if(response){
    setTabIndex(5);
    getEmpPreviousJobDetails();
    setEmpFormErrors({})
   }

  }).catch((validationErrors) => {
    const newErrors = {};
    validationErrors.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setEmpFormErrors(newErrors);
  });

 }

  const SaveEmpDocumentsDetails = async (e) => {
    e.preventDefault();

    const UploadDocument = new FormData();
    UploadDocument.append("empDocumentId",EmpDocDetails?.empDocumentId);
    UploadDocument.append("Attachment",EmpDocDetails?.attachment)
    UploadDocument.append("attachmentPath",EmpDocDetails?.attachmentPath)
    UploadDocument.append("guId",userData.guId);
    UploadDocument.append("empCode",EmpGeneralInfo.empCode);
    UploadDocument.append("documentType",EmpDocDetails?.documentType);
    UploadDocument.append("documentNumber",EmpDocDetails?.documentNumber);
    UploadDocument.append("validUpto",moment(EmpDocDetails?.validUpto || new Date()).format('YYYY-MM-DD'));
    UploadDocument.append("remark",EmpDocDetails?.remark);
    UploadDocument.append("companyId", userData.companyId);
    UploadDocument.append("branchId",userData.isActiveBranch);

    console.log("Emp Document Details Body",EmpDocDetails);

    const response = await PostDocumentData(UploadDocument);

    if(response) {
      RefetchEmpDocumentList();
      reset();
      setEmpDocDetails({
        empDocumentId: 0,
        guId: userData.guId,
        documentType: "",
        documentNumber: "",
        isValidityReq: false,
        validUpto: "",
        attachment: "",
        attachmentPath: "",
        remark: "",
        companyId: userData?.companyId,
        branchId: userData?.isActiveBranch
      });
    }
  };

  const SaveEmpBankDetails = async (e) => {
    e.preventDefault();
    setEmpFormErrors({});

    BankDetailSchema.validate(BankFormData,{abortEarly:false})
    .then(async() => {

      
      let body = {
        ...BankFormData,
        guId: userData?.guId,
        isComplete:true,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch
      }

    console.log("Emp Bank Save Body",body);

    const response = await PostEmpBankData(body);

    if(response){
      setTabIndex(7);
      getEmpBankDetails();
      setEmpFormErrors({});
      
    }

  }).catch((validationErrors) => {
    const newErrors = {};
    validationErrors.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setEmpFormErrors(newErrors);
  });

   }


   const SaveEmpFamilyDetails = async (e) => {
    e.preventDefault();
    setEmpFormErrors({});
    
    FamilyDetailSchema.validate(FamilyFormData,{abortEarly:false})
    .then(async () => {
      let body = {
        ...FamilyFormData,
        dob: moment(FamilyFormData?.dob).format('YYYY-MM-DD')
      };
      console.log("Emp Family Save Body",body);
      const response = await PostFamilyData(body);
      if(response){
        getEmpFamilyDetails();
        setEmpFormErrors({});
      }
    }).catch((validationErrors) => {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setEmpFormErrors(newErrors);
    });

   }

  const SaveComplianceDetails = async (e) => {
    e.preventDefault();
    setEmpFormErrors({});


    ComplianceDetailSchema.validate(ComplianceFormData,{abortEarly:false})
    .then(async() => {
    
    let body={
      ...ComplianceFormData,
      empGuid : userData?.guId,
      empCode : EmpGeneralInfo.empCode,
      isComplete: true,
      companyId : userData.companyId,
      branchId : userData.branchId
    }

    console.log("Compliance Save Body",body);
    const response = await PostComplianceData(body);

    if(response){
      GetComplianceDetails();
      setEmpFormErrors({});

    }
              
   }).catch((validationErrors) => {
     const newErrors = {};
     validationErrors.inner.forEach((error) => {
       newErrors[error.path] = error.message;
     });
     setEmpFormErrors(newErrors);
   });


  }


  const SaveProfilePic = async (ProfilePic) => {
    console.log("Profile Pic", ProfilePic);

    const ProfileData = new FormData();

    ProfileData.append("empGuid", userData.guId);
    ProfileData.append("attachment", ProfilePic);
    ProfileData.append("companyId", userData.companyId);
    ProfileData.append("branchId", userData.isActiveBranch);
    ProfileData.append("empCode", EmpGeneralInfo.empCode);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeProfilePic`,
        ProfileData
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getEmpGeneralInfo();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Branch not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };


  console.log("Document Details",EmpDocDetails);

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Profile Details</h1>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md mb-4 p-4">
        <div className="flex flex-wrap gap-10 align-middle items-center">
          <div>
            <label htmlFor="dropzone-file" className="cursor-pointer">
              <div>
                <Avatar
                  size="2xl"
                  name={EmpGeneralInfo.fullName}
                  src={ProfilePicPath}
                />
                {/* <div className="absolute bottom-5 bg-slate-800 p-2 left-5" >
              <h6 className="text-xs text-white">Choose Photo</h6>
            </div> */}
              </div>
              <Input
                id="dropzone-file"
                onChange={({ target }) => {
                  SaveProfilePic(target.files[0]);
                }}
                type="file"
                hidden
              />
            </label>
          </div>

          <div className="space-y-1 flex-auto">
            <h6 className="font-medium">
              Employee Name/Code :{" "}
              <span className="font-normal ml-2">
                {EmpGeneralInfo.fullName} / {EmpGeneralInfo.empCode}
              </span>
            </h6>
            <h6 className="font-medium">
              Designation :{" "}
              <span className="font-normal ml-2">
                {EmpGeneralInfo.designationName}
              </span>
            </h6>
            <h6 className="font-medium">
              Department :{" "}
              <span className="font-normal ml-2">
                {EmpGeneralInfo.departmentName}
              </span>
            </h6>
          </div>

          <div className="space-y-1 flex-auto">
            <h6 className="font-medium">
              Employee status :{" "}
              <span className="font-normal ml-2">
                {EmpGeneralInfo.isActive === true ? "Active" : "In Active"}
              </span>
            </h6>
            <h6 className="font-medium">
              Email :{" "}
              <span className="font-normal ml-2">
                {EmpGeneralInfo.officeEmail}
              </span>
            </h6>
            <h6 className="font-medium">
              Branch :{" "}
              <span className="font-normal ml-2">
                {userData.activeBranchName}
              </span>
            </h6>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md p-4 mb-4">
        <Tabs
          variant="line"
          colorScheme="purple"
          size="sm"
          index={tabIndex}
          onChange={(index)=>{setTabIndex(index);}}
        >
          <TabList  flexWrap={"wrap"}>
            <Tab>
              <User size="14px" className="mr-1.5" />
              <span className="hidden lg:block text-xs">General</span>
              {EmpTabLock.generalInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <Tv size="14px" className="mr-1.5" />
              <span className="hidden lg:block text-xs">Induction</span>
              {EmpTabLock.indcutionInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <User size="14px" className="mr-1.5" />
              <span className="hidden lg:block text-xs">Personal</span>
              {EmpTabLock.personalInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <Book size="14px" className="mr-1.5" />
              <span className="hidden lg:block">
                {userData.countryName === "India"
                  ? "Passport"
                  : "Passport/Visa"}
              </span>
              {EmpTabLock.passportVisaInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <Award size="14px" className="mr-1.5" />
              <span className="hidden lg:block text-xs">Qualification</span>
              {EmpTabLock.qualificationInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <Briefcase size="14px" className="mr-1.5" />
              <span className="hidden lg:block text-xs">Previous Job</span>
              {EmpTabLock.previousJobInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <File size="14px" className="mr-1.5" />
              <span className="hidden lg:block text-xs">Document</span>
              {EmpTabLock.employeeDocsInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <FontAwesomeIcon size="xs" icon={faBuilding} className="mr-1.5" />
              <span className="hidden lg:block text-xs">Bank Info</span>
              {EmpTabLock.bankInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            <Tab>
              <Users size="14px" className="mr-1.5" />
              <span className="hidden lg:block text-xs">Family Info</span>
              {EmpTabLock.familyInfoIsLock === true ||
              userData.isActiveBranch === 1023 ? (
                <FontAwesomeIcon size="xs"
                  icon={faLock}
                  className="ml-1 text-green-500"
                />
              ) : (
                <FontAwesomeIcon size="xs"
                  icon={faLockOpen}
                  className="ml-1 text-red-500"
                />
              )}
            </Tab>
            {userData.countryName === "India" ? (
              <Tab>
                <FilePlus size="14px" className="mr-1.5" />
                <span className="hidden lg:block">Compliance</span>
                {EmpTabLock.complianceInfoIsLock === true ? <FontAwesomeIcon size="xs" icon={faLock} className="ml-1 text-green-500" />: <FontAwesomeIcon size="xs" icon={faLockOpen} className="ml-1 text-red-500" />}
              </Tab>
            ) : (
              ""
            )}
          </TabList>
          {/* <hr className="mt-2" /> */}

          <TabPanels>
            <TabPanel>
              <form>
                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                  <User size="18px" className=" text-indigo-600 mr-2 " />
                  <h1 className="text-lg font-normal text-indigo-600">
                    Employee Official Details
                  </h1>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="flex gap-6">
                  <div className='space-y-2'>
              <label>Title</label>
              <Select 
                isInvalid={EmpFormErrors?.title}
                value={GeneralInfoFormData?.title} 
                onChange={({target})=>{setGeneralInfoFormData({
                  ...GeneralInfoFormData,
                  title: target.value
                })}}
                borderColor='gray.400' 
                placeholder='Select Title'
              >
                <option value={'Mr.'}>Mr.</option>
                <option value={'Ms.'}>Ms.</option>
              </Select>
              {EmpFormErrors?.title && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.title}</span>}
            </div>

            <div className='space-y-2'>
              <label>First Name</label>
              <Input 
                isInvalid={EmpFormErrors?.firstName}
                value={GeneralInfoFormData?.firstName || ''} 
                onChange={({target})=>{
                  let InputTarget = target.value;
                  setGeneralInfoFormData({
                    ...GeneralInfoFormData,
                    firstName: InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1)
                  });
                }} 
                borderColor='gray.400' 
                placeholder='First Name' 
              />
              {EmpFormErrors?.firstName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.firstName}</span>}
            </div>

            <div className='space-y-2'>
              <label>Middle Name</label>
              <Input 
                isInvalid={EmpFormErrors?.middleName}
                value={GeneralInfoFormData?.middleName || ''} 
                onChange={({target})=>{
                  let InputTarget = target.value;
                  setGeneralInfoFormData({
                    ...GeneralInfoFormData,
                    middleName: InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1)
                  })
                }} 
                borderColor='gray.400' 
                placeholder='Middle Name' />
                {EmpFormErrors?.middleName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.middleName}</span>}
            </div>
                  </div>

                  <div className="grid grid-cols-2 gap-6">
                  <div className='space-y-2'>
              <label>Last Name</label>
              <Input 
                isInvalid={EmpFormErrors?.lastName}
                value={GeneralInfoFormData?.lastName || ''} 
                onChange={({target})=>{
                  let InputTarget = target.value;
                  setGeneralInfoFormData({
                    ...GeneralInfoFormData,
                    lastName: InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1)
                  })
                }} 
                borderColor='gray.400' 
                placeholder='Last Name' />
                {EmpFormErrors?.lastName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.lastName}</span>}
            </div>


            <div className='space-y-2'>
  <label>Assign Role</label>
  <Select 
    isInvalid={EmpFormErrors?.roleId}
    value={GeneralInfoFormData?.roleId || ''} 
    onChange={({target})=>{
      setGeneralInfoFormData({
        ...GeneralInfoFormData,
        roleId: target.value
      })
    }} 
    borderColor={'gray.400'} 
    placeholder='Select Role'
  >
    {RoleList.filter((data)=> data.isActive ===  true).map((data,i)=>{
        return <option key={i} value={data.id}>{data.roleName}</option>
    })}
 </Select>
 {EmpFormErrors?.roleId && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.roleId}</span>}
</div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                <div className='space-y-2'>
            <label>Date of Joining</label>
            <DatePicker
              className={`${EmpFormErrors?.dateofjoin ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
              selected={GeneralInfoFormData?.dateofjoin}
              onChange={(date) =>{
                setGeneralInfoFormData({
                  ...GeneralInfoFormData,
                  dateofjoin: date,
                  regulationDate: date,
                  probationStartDate: date,
                  probationEndDate: date
                });
              }}
              minDate={Validation?.payrollMonth === 0 ? new Date(Validation?.payrollStartFrom) : new Date(Validation?.payrollYear,Validation?.payrollMonth,1)}
              placeholderText="Date of Joining"
              dateFormat={"dd-MM-yyyy"}
            />
            {EmpFormErrors?.dateofjoin && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.dateofjoin}</span>}
          </div>

                  <div className="space-y-2">
                    <label>
                      Designation
                    </label>
  <Select isInvalid={EmpFormErrors?.designation} borderColor='gray.400' value={GeneralInfoFormData?.designation} onChange={({target})=>{
    setGeneralInfoFormData((prev)=>{
      return {
        ...prev,
        designation: target.value
      }
    })
  }}>
       {DesignationList.map((data,i)=>{
        return <option key={i} value={data.designationId}>{data.designationName}</option>
       })
       }
     </Select>
     {EmpFormErrors?.designation && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.designation}</span>}

                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                  <label>Department</label>
                    <Select isInvalid={EmpFormErrors?.department} borderColor='gray.400' value={GeneralInfoFormData?.department} onChange={({target})=>{setGeneralInfoFormData((prev)=>{
                           return {
                             ...prev,
                             department: target.value
                           }
                         })}}>
                      {DepartmentList.map((data,i)=>{
                       return <option key={i} value={data.departmentId}>{data.departmentName}</option>
                      })}
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <div className="flex justify-between">
                      <label>
                        Reporting Officer
                      </label>
                      <Checkbox 
     borderColor={'gray.400'} 
     isChecked={GeneralInfoFormData?.selfReporting} 
     onChange={({target})=>{
       if(target.checked){
        setGeneralInfoFormData({
          ...GeneralInfoFormData,
          selfReporting: target.checked,
          reportingOfficersId: "0"
        })
       }else{
        setGeneralInfoFormData({
          ...GeneralInfoFormData,
          selfReporting: target.checked,
          reportingOfficersId: ''
        })
       }
      }}>Self Reporting</Checkbox>
                    </div>
                    <Select isInvalid={EmpFormErrors?.reportingOfficersId} isDisabled={GeneralInfoFormData?.selfReporting}
  value={GeneralInfoFormData?.reportingOfficersId} onChange={({target})=>{
    setGeneralInfoFormData((prev)=>{
      return {
        ...prev,
        reportingOfficersId: target.value,
      }
    });
  }}  borderColor='gray.400' placeholder="Reporting Officer">
   {ReportingPersonList?.filter((data)=>data.empGuid !== userData?.guId).map((data,i)=>{
    return <option key={i} value={data.id}>{data.fullName}</option>
   })}
 </Select>
 {EmpFormErrors?.reportingOfficersId && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.reportingOfficersId}</span>}

                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                <div className='space-y-2'>
  <label>Mobile Number</label>
  <Input 
    isInvalid={EmpFormErrors?.mobile}
    value={GeneralInfoFormData?.mobile || ''} 
    onChange={({target})=>{
      setGeneralInfoFormData({
        ...GeneralInfoFormData,
        mobile: target.value
      })
    }} 
    type='number' 
    borderColor='gray.400' 
    placeholder='Mobile Number' 
  />
  {EmpFormErrors?.mobile && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.mobile}</span>}
</div>

                  <div className="space-y-2">
                   <label>Employee Category</label>
                   <Select isInvalid={EmpFormErrors?.empType} borderColor='gray.400' value={GeneralInfoFormData?.empType} 
     onChange={({target})=>{
        let ProbationMonth = EmployeeCategoryList.filter(data => data.empTypeId === Number(target.value))[0];
        // console.log("Probation Month",ProbationMonth);
         let dt = GeneralInfoFormData?.regulationDate;
         let EndDate = moment(dt).add(ProbationMonth,'M');
         setGeneralInfoFormData((prev)=>{
          return {
            ...prev,
            empType: target.value,
            probationEndDate: new Date(EndDate)
          }
         })
     }}>
       {EmployeeCategoryList.map((data,i)=>{
        return <option  key={i} value={data.empTypeId}>{data.empTypeName}</option>
       })
       }
     </Select>
                  </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>


<div className='space-y-2'>
  <label>Regulation Date</label>
  <DatePicker
    disabled
    className={`${EmpFormErrors?.regulationDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
    selected={GeneralInfoFormData?.regulationDate}
    placeholderText="Regulation Date"
    dateFormat={'dd-MM-yyyy'}
  />
  {EmpFormErrors?.regulationDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.regulationDate}</span>}
</div>


<div className="grid grid-cols-2 gap-6"> 

<div className='space-y-2'>
  <label>Probation Start Date</label>
  <DatePicker
    disabled
    className={`${EmpFormErrors?.probationStartDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
    selected={GeneralInfoFormData?.probationStartDate}
    placeholderText="Probation Start Date"
    dateFormat={'dd-MM-yyyy'}
  />
  {EmpFormErrors?.probationStartDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.probationStartDate}</span>}
</div>

<div className='space-y-2'>
  <label>Probation End Date</label>
  <DatePicker
    disabled
    className={`${EmpFormErrors?.probationEndDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
    selected={GeneralInfoFormData?.probationEndDate}
    placeholderText="Probation End Date"
    dateFormat={'dd-MM-yyyy'}
  />
  {EmpFormErrors?.probationEndDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.probationEndDate}</span>}
</div>

</div>


</div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
<div className='space-y-2'>
  <label>Office Email</label>
  <Input 
    isInvalid={EmpFormErrors?.officeEmail}
    value={GeneralInfoFormData?.officeEmail || ''} 
    onChange={({target})=>{
      setGeneralInfoFormData({
        ...GeneralInfoFormData,
        officeEmail: target.value
      })
    }} 
    type='email' 
    borderColor='gray.400' 
    placeholder='Office Email' />
    {EmpFormErrors?.officeEmail && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.officeEmail}</span>}
</div>

                  <div className="space-y-2">
  <div className="flex justify-between">
    <label>Grade</label>
    {/* {EmpGrade && <label>Division: <span className="font-semibold">{GradeList.filter((data) => data.grade === EmpGrade)[0]?.division || ''}</span></label>} */}
  </div>
  <Input isReadOnly  value={EmpGrade?.division && EmpGrade?.grade ? ((EmpGrade?.grade || '') + ' - ' + (EmpGrade?.division || '')) : ''} borderColor='gray.400' placeholder='Grade' />
  {/* <Select value={EmpGrade} onChange={({target})=>{setEmpGrade(target.value)}} borderColor={'gray.400'} placeholder="Select Grade">
    {GradeList.map((data,i) => {
      return <option key={i} value={data.grade}>{data.grade} - {data.division}</option>
    })}
  </Select> */}
</div>

</div>
              </form>
            </TabPanel>
            <TabPanel>
              <form onSubmit={SaveEmpInductionDetails}>
                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                  <Tv size="18px" className=" text-indigo-600 mr-2 " />
                  <h1 className="text-lg font-normal text-indigo-600">
                    Employee Induction Details
                  </h1>
                </div>

                <div className="space-y-4">
                  <div>
                    <Checkbox
                      isChecked={IsInductionCompleted}
                      onChange={({ target }) => {
                        setIsInductionCompleted(target.checked);
                      }}
                      borderColor="gray"
                    >
                      Is Induction Completed ?
                    </Checkbox>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                    <div className="space-y-2">
                      <label>
                        Induction Complete Date (DD-MM-YYYY)
                        <span className="text-red-500 font-semibold">*</span>
                      </label>
                      <Input
                        isReadOnly
                        isDisabled={
                          IsInductionCompleted === true ? false : true
                        }
                        value={InductionCompleteDate}
                        onChange={({ target }) => {
                          setInductionCompleteDate(target.value);
                        }}
                        type="date"
                        borderColor="gray"
                        placeholder="First Name"
                      />
                      {/* <DatePicker
                      disabled={IsInductionCompleted === true ? false : true}
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={InductionCompleteDate}
                      onChange={(date) => setInductionCompleteDate(date)}
                      showYearDropdown
                      placeholderText="Induction Date Date"
                       dateFormat='dd/MM/yyyy'
                    /> */}
                    </div>

                    <div className="space-y-2">
                      <label>
                        Induction Given By
                        <span className="text-red-500 font-semibold">*</span>
                      </label>
                      <Select
                        isDisabled
                        value={InductionGivenBy}
                        onChange={({ target }) => {
                          setInductionGivenBy(target.value);
                        }}
                        borderColor="gray.500"
                        placeholder="Induction Given By"
                      >
                        {EmployeeList.filter(
                          (data) => data.department === "HR"
                        ).map((data, i) => {
                          return (
                            <option key={i} value={data.id}>
                              {data.firstName} {data.lastName}
                            </option>
                          );
                        })}
                      </Select>

                      {/* <ReactSelect
        options={EmployeeList}
        value={InductionGivenBy}
         
        className="w-full my-auto"
        onChange={setInductionGivenBy}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionValue={(option) => option.id}
        placeholder="Induction Given By"
      /> */}
                    </div>
                  </div>
                </div>

                {/* {EmpTabLock.indcutionInfoIsLock === false ?
              <div className="flex gap-6 justify-end mt-6">
                  <Button variant="outline" colorScheme="purple">
                    Reset
                  </Button>
                  <Button type="submit" variant="solid" colorScheme="purple">
                    Submit
                  </Button>
                </div> : ''} */}
              </form>
            </TabPanel>

            {/* Personal Details Tab */}
            <TabPanel>
              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <User size="18px" className=" text-indigo-600 mr-2" />
                <h1 className="text-lg font-normal text-indigo-600">
                  Personal Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Gender<span className="text-red-600">*</span></label>
                    <Select 
                      isInvalid={EmpFormErrors?.gender} 
                      value={PersonalFormData?.gender} 
                      onChange={({target}) => {
                        setPersonalFormData({
                          ...PersonalFormData,
                          gender: target.value
                        });
                      }} 
                      borderColor="gray" 
                      placeholder="Select Gender" 
                    >
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Other'>Other</option>
                    </Select>
                    {EmpFormErrors?.gender && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.gender}</span>}
                  </div>

                  <div className="space-y-2">
                    <div className="flex justify-between">
                     <label>Date of Birth (DD-MM-YYYY)<span className="text-red-600">*</span></label>
                     <label className="font-medium">{`${moment().diff(moment(PersonalFormData?.dateOfBirth), 'years')} Years`}</label>
                    </div>

                    <DatePicker
                       required
                       className={`${EmpFormErrors?.dateOfBirth ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={PersonalFormData?.dateOfBirth}
                        onChange={(date) => {
                          setPersonalFormData({
                            ...PersonalFormData,
                            dateOfBirth : date
                          })
                        }}
                        maxDate={new Date(moment().subtract(20, 'years').format('YYYY-MM-DD'))}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Date of Birth"
                        dateFormat={"dd-MM-yyyy"}
                      />
                      {EmpFormErrors?.dateOfBirth && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.dateOfBirth}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Place of Birth</label>
                    <Input 
                      isInvalid={EmpFormErrors?.placeOfBirth}
                      value={PersonalFormData?.placeOfBirth ||''} 
                      onChange={({target})=>{setPersonalFormData({
                        ...PersonalFormData,
                        placeOfBirth : target.value
                      })}} 
                      borderColor="gray" 
                      placeholder="Place of Birth"
                    />
                    {EmpFormErrors?.placeOfBirth && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.placeOfBirth}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Nationality</label>
                    <Input 
                      isInvalid={EmpFormErrors?.nationality}
                      value={PersonalFormData?.nationality ||''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          nationality: target.value
                        })
                      }} 
                      borderColor="gray" 
                      placeholder="Nationality" 
                    />
                    {EmpFormErrors?.nationality && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.nationality}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Mobile No<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.personalMobile}
                      value={PersonalFormData?.personalMobile || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          personalMobile: target.value
                        })
                      }} 
                      type='number' 
                      borderColor="gray" 
                      placeholder="Mobile No." 
                    />
                    {EmpFormErrors?.personalMobile && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.personalMobile}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Personal Email Id<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.personalEmail} 
                      value={PersonalFormData?.personalEmail ||''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          personalEmail: target.value
                        })
                      }}
                      borderColor="gray" 
                      type='email' 
                      placeholder="Personal Email Id" 
                    />
                    {EmpFormErrors?.personalEmail && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.personalEmail}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Marital Status<span className="text-red-600">*</span></label>
                    <Select 
                      isInvalid={EmpFormErrors?.maritalStatus} 
                      value={PersonalFormData?.maritalStatus} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          maritalStatus: target.value
                        })
                      }} 
                      borderColor="gray" 
                      placeholder="Select Martial Status" 
                    >
                      <option value='Single'>Single</option>
                      <option value='Married'>Married</option>
                      <option value='Divorced'>Divorced</option>
                      <option value='Other'>Other</option>
                    </Select>
                    {EmpFormErrors?.maritalStatus && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.maritalStatus}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Citizenship</label>
                    <Input 
                      isInvalid={EmpFormErrors?.citizenShip}
                      value={PersonalFormData?.citizenShip ||''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          citizenShip: target.value
                        })
                      }} 
                      borderColor="gray" 
                      placeholder="Citizenship" 
                    />
                    {EmpFormErrors?.citizenShip && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.citizenShip}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Blood Group</label>
                    <Select 
                     isInvalid={EmpFormErrors?.bloodGroup}
                     value={PersonalFormData?.bloodGroup || ''} 
                     onChange={({target})=>{
                       setPersonalFormData({
                         ...PersonalFormData,
                         bloodGroup: target.value
                       })
                     }} 
                      borderColor='gray' 
                      placeholder='Select Blood Group'
                    >
                        <option value={'A Positive (A+)'}>A Positive (A+)</option>
                        <option value={'A Negative (A-)'}>A Negative (A-)</option>
                        <option value={'B Positive (B+)'}>B Positive (B+)</option>
                        <option value={'B Negative (B-)'}>B Negative (B-)</option>
                        <option value={'AB Positive (AB+)'}>AB Positive (AB+)</option>
                        <option value={'AB Negative (AB-)'}>AB Negative (AB-)</option>
                        <option value={'O Positive (O+)'}>O Positive (O+)</option>
                        <option value={'O Negative (O-)'}>O Negative (O-)</option>
                    </Select>
                    {EmpFormErrors?.bloodGroup && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.bloodGroup}</span>}
                  </div>

                </div>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                  <User size="18px" className=" text-indigo-600 mr-2 " />
                  <h1 className="text-lg font-normal text-indigo-600">
                    Emergency Contact Details
                  </h1>
                </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Contact Name<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.contactName}
                      value={PersonalFormData?.contactName || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          contactName: target.value,
                          familyMemberName: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Contact Name" 
                    />
                    {EmpFormErrors?.contactName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.contactName}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Contact No.<span className="text-red-600">*</span></label>
                    <Input  
                      type={'number'} 
                      isInvalid={EmpFormErrors?.emergencyContactNo}
                      value={PersonalFormData?.emergencyContactNo || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          emergencyContactNo: target.value,
                          familyMemberContact: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Contact No." />
                    {EmpFormErrors?.emergencyContactNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.emergencyContactNo}</span>}
                  </div>
                </div>
              
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Relation<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.familyMemberRelation}
                      value={PersonalFormData?.familyMemberRelation || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          familyMemberRelation: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Relation" />
                    {EmpFormErrors?.familyMemberRelation && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.familyMemberRelation}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Address<span className="text-red-600">*</span></label>
                    <Textarea 
                      isInvalid={EmpFormErrors?.relationMaillingAddress}
                      value={PersonalFormData?.relationMaillingAddress || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          relationMaillingAddress: target.value
                        })
                      }}
                      rows={1} 
                      borderColor="gray" placeholder="Address" />
                    {EmpFormErrors?.relationMaillingAddress && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.relationMaillingAddress}</span>}
                  </div>
                </div>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <User size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Present Address
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>House/Flat No.<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.presentHouseNo}
                      value={PersonalFormData?.presentHouseNo || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentHouseNo: target.value
                        })
                      }}
                      borderColor="gray" placeholder="House/Flat No." />
                    {EmpFormErrors?.presentHouseNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentHouseNo}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Address Line 1<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.presentAddress1} 
                      value={PersonalFormData?.presentAddress1 || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentAddress1: target.value
                        })
                      }}
                      borderColor="gray" placeholder="Address Line 1" />
                    {EmpFormErrors?.presentAddress1 && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentAddress1}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Address Line 2</label>
                    <Input 
                      isInvalid={EmpFormErrors?.presentAddress2} 
                      value={PersonalFormData?.presentAddress2 || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentAddress2: target.value
                        })
                      }}
                      borderColor="gray" placeholder="Address Line 2" />
                    {EmpFormErrors?.presentAddress2 && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentAddress2}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Landmark<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.presentLandmark} 
                      value={PersonalFormData?.presentLandmark || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentLandmark: target.value
                        })
                      }}
                      borderColor="gray" placeholder="Landmark" />
                    {EmpFormErrors?.presentLandmark && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentLandmark}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Country<span className="text-red-600">*</span></label>
                    <Select 
                      isInvalid={EmpFormErrors?.presentCountry} 
                      value={PersonalFormData?.presentCountry} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentCountry: target.value
                        })
                        getStateList(target.value)
                      }} 
                      borderColor="gray" 
                      placeholder="Select Country" 
                    >
                     {CountryList.map((data,i)=>{
                      return <option key={i} value={data.countryId} >{data.countryName}</option>
                     })}
                    </Select>
                    {EmpFormErrors?.presentCountry && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentCountry}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>State<span className="text-red-600">*</span></label>
                    <Select 
                      isInvalid={EmpFormErrors?.presentState} 
                      value={PersonalFormData?.presentState} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentState: target.value
                        })
                      }} 
                      borderColor="gray" placeholder="Select State" >
                      {StateList.map((data,i)=>{
                        return  <option key={i} value={data.stateId}>{data.stateName}</option>

                      })}
                    </Select>
                    {EmpFormErrors?.presentState && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentState}</span>}
                  </div>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>City<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.presentCity} 
                      value={PersonalFormData?.presentCity} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentCity: target.value
                        })
                      }}
                      borderColor="gray" placeholder="City" />
                    {EmpFormErrors?.presentCity && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentCity}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Pincode<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.presentPin} 
                      value={PersonalFormData?.presentPin} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          presentPin: target.value
                        })
                      }}
                      borderColor="gray" placeholder="Pincode" />
                    {EmpFormErrors?.presentPin && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.presentPin}</span>}
                  </div>
                </div>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                  <User size="18px" className=" text-indigo-600 mr-2 " />
                  <h1 className="text-lg font-normal text-indigo-600">
                    Permanent Address
                  </h1>
                </div>

              <div className="mb-4">
                <Checkbox 
                 isChecked={PersonalFormData?.addressStatus} 
                 onChange={({target}) => {
                  setPersonalFormData({
                    ...PersonalFormData,
                    addressStatus: target.checked
                  });
                  if(target.checked === true){

                    setPersonalFormData((prev) => {
                      return {
                        ...prev,
                        permanentHouseNo: prev.presentHouseNo,
                        permanentAddress1: prev.presentAddress1,
                        permanentAddress2: prev.presentAddress2,
                        permanentLandmark: prev.presentLandmark,
                        permanentCountry: prev.presentCountry,
                        permanentState: prev.presentState,
                        permanentCity: prev.presentCity,
                        permanentPin: prev.presentPin
                      }
                    })
                  }else{

                    setPersonalFormData((prev) => {
                      return {
                        ...prev,
                        permanentHouseNo: '',
                        permanentAddress1: '',
                        permanentAddress2: '',
                        permanentLandmark: '',
                        permanentCountry: '',
                        permanentState: '',
                        permanentCity: '',
                        permanentPin: ''
                      }
                    })
                  }
                }} borderColor='gray' >Same as Present Address</Checkbox>
              </div>

              {!PersonalFormData?.addressStatus && <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>House/Flat No.<span className="text-red-600">*</span></label>
                    <Input 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentHouseNo}
                      value={PersonalFormData?.permanentHouseNo || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentHouseNo: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="House/Flat No." />
                    {EmpFormErrors?.permanentHouseNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentHouseNo}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Address Line 1<span className="text-red-600">*</span></label>
                    <Input 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentAddress1} 
                      value={PersonalFormData?.permanentAddress1 || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentAddress1: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Address Line 1" />
                    {EmpFormErrors?.permanentAddress1 && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentAddress1}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Address Line 2</label>
                    <Input 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentAddress2} 
                      value={PersonalFormData?.permanentAddress2 || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentAddress2: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Address Line 2" />
                    {EmpFormErrors?.permanentAddress2 && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentAddress2}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Landmark<span className="text-red-600">*</span></label>
                    <Input 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentLandmark} 
                      value={PersonalFormData?.permanentLandmark || ''} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentLandmark: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Landmark" />
                    {EmpFormErrors?.permanentLandmark && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentLandmark}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Country<span className="text-red-600">*</span></label>
                    <Select 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentCountry} 
                      value={PersonalFormData?.permanentCountry} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentCountry: target.value
                        })
                        getStateList(target.value)
                      }} 
                      borderColor="gray" 
                      placeholder="Select Country" 
                    >
                     {CountryList.map((data,i)=>{
                      return <option key={i} value={data.countryId} >{data.countryName}</option>
                     })}
                    </Select>
                    {EmpFormErrors?.permanentCountry && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentCountry}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>State<span className="text-red-600">*</span></label>
                    <Select 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentState} 
                      value={PersonalFormData?.permanentState} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentState: target.value
                        })
                      }} 
                      borderColor="gray" placeholder="Select State" >
                      {StateList.map((data,i)=>{
                        return  <option key={i} value={data.stateId}>{data.stateName}</option>
                      })}
                    </Select>
                    {EmpFormErrors?.permanentState && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentState}</span>}
                  </div>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>City<span className="text-red-600">*</span></label>
                    <Input 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentCity} 
                      value={PersonalFormData?.permanentCity} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentCity: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="City" />
                    {EmpFormErrors?.permanentCity && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentCity}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Pincode<span className="text-red-600">*</span></label>
                    <Input 
                      isReadOnly={PersonalFormData?.addressStatus}
                      isInvalid={EmpFormErrors?.permanentPin} 
                      value={PersonalFormData?.permanentPin} 
                      onChange={({target})=>{
                        setPersonalFormData({
                          ...PersonalFormData,
                          permanentPin: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Pincode" />
                    {EmpFormErrors?.permanentPin && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.permanentPin}</span>}
                  </div>
                </div>
              </>}

                {EmpTabLock.personalInfoIsLock === true ||
                userData.isActiveBranch === 1023 ? (
                  ""
                ) : (
                  <div className="flex gap-6 justify-end mt-6">
                    <Button variant="outline" colorScheme="purple">
                      Reset
                    </Button>
                    <Button isLoading={IsPostPersonalLoading} loadingText={'Submitting...'} onClick={()=>{
                      SaveEmpPersonalInfo();
                    }} variant="solid" colorScheme="purple">Submit</Button> 
                  </div>
                )}

            </TabPanel>
            <TabPanel>
              <form onSubmit={SaveEmpPassportDetails}>
              {userData.countryName === "India"? <>

              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Book size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                 Passport Details
                </h1>
              </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Passport No.<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.passportNo}
                      isRequired 
                      value={PassportFormData?.passportNo ||''} 
                      onChange={({target})=>{
                        setPassportFormData({
                          ...PassportFormData,
                          passportNo: target.value
                        });
                      }} 
                      borderColor="gray" 
                      placeholder="Passport No." />
                    {EmpFormErrors?.passportNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.passportNo}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Passport Expiry Date<span className="text-red-600">*</span></label>
                    <DatePicker
                     required
                     className={`${EmpFormErrors?.passportExpiryDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                     selected={PassportFormData?.passportExpiryDate}
                      onChange={(date) =>{
                        setPassportFormData({
                          ...PassportFormData,
                          passportExpiryDate : date
                        })
                      }}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      placeholderText="Passport Expiry Date"
                      dateFormat={"dd-MM-yyyy"}
                    />
                    {EmpFormErrors?.passportExpiryDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.passportExpiryDate}</span>}
                  </div>
                </div>

                </> : <>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                  <Book size="18px" className=" text-indigo-600 mr-2 " />
                  <h1 className="text-lg font-normal text-indigo-600">
                   Passport Details
                  </h1>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Passport No.<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.passportNo}
                      isRequired 
                      value={PassportFormData?.passportNo ||''} 
                      onChange={({target})=>{
                        setPassportFormData({
                          ...PassportFormData,
                          passportNo: target.value
                        });
                      }} 
                      borderColor="gray" 
                      placeholder="Passport No." />
                    {EmpFormErrors?.passportNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.passportNo}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Passport Expiry Date<span className="text-red-600">*</span></label>
                    <DatePicker
                       required
                       className={`${EmpFormErrors?.passportExpiryDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={PassportFormData?.passportExpiryDate}
                        onChange={(date) =>{
                          setPassportFormData({
                            ...PassportFormData,
                            passportExpiryDate : date
                          })
                        }}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Passport Expiry Date"
                        dateFormat={"dd-MM-yyyy"}
                      />
                    {EmpFormErrors?.passportExpiryDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.passportExpiryDate}</span>}
                  </div>
                </div>
                
             <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Book size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                 Visa Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Visa Type<span className="text-red-600">*</span></label>
                    <Select 
                     isInvalid={EmpFormErrors?.visaType}
                     isRequired 
                     value={PassportFormData?.visaType ||''} 
                     onChange={({target})=>{
                       setPassportFormData({
                         ...PassportFormData,
                         visaType: target.value
                       });
                     }} 
                     borderColor="gray" placeholder="Select Visa Type" >
                      <option value='visit visa'>Visit Visa</option>
                      <option value='Employeement visa'>Employeement Visa</option>
                      <option value='Un-limited'>Un-limited</option>
                    </Select>
                    {EmpFormErrors?.visaType && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.visaType}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Visa No.<span className="text-red-600">*</span></label>
                    <Input  
                      isInvalid={EmpFormErrors?.visaNo} 
                      isRequired 
                      value={PassportFormData?.visaNo ||''} 
                      onChange={({target})=>{
                        setPassportFormData({
                          ...PassportFormData,
                          visaNo: target.value
                        });
                      }} 
                      borderColor="gray" placeholder="Visa No." />
                    {EmpFormErrors?.visaNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.visaNo}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Visa Issue Place<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.vissaIssuePlace} 
                      isRequired 
                      value={PassportFormData?.vissaIssuePlace ||''} 
                      onChange={({target})=>{
                        setPassportFormData({
                          ...PassportFormData,
                          vissaIssuePlace: target.value
                        });
                      }}
                      borderColor="gray" placeholder="Visa Issue Place" />
                    {EmpFormErrors?.vissaIssuePlace && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.vissaIssuePlace}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Visa Start Date<span className="text-red-600">*</span></label>
                    <DatePicker
                       required
                       className={`${EmpFormErrors?.visaStartDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={PassportFormData?.visaStartDate}
                        onChange={(date) =>{
                          setPersonalFormData({
                            ...PassportFormData,
                            visaStartDate: date
                          })
                        }}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Visa Start Date"
                        dateFormat={"dd-MM-yyyy"}
                      />
                    {EmpFormErrors?.visaStartDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.visaStartDate}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Visa Expiry Date<span className="text-red-600">*</span></label>
                    <DatePicker
                       required
                       className={`${EmpFormErrors?.visaExpiryDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={PassportFormData?.visaExpiryDate}
                        onChange={(date) =>{
                          setPersonalFormData({
                            ...PassportFormData,
                            visaExpiryDate : date
                          })
                        }}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Visa Expiry Date"
                        dateFormat={"dd-MM-yyyy"}
                      />
                    {EmpFormErrors?.visaExpiryDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.visaExpiryDate}</span>}
                  </div>

                </div>



                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Book size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                 Labour Card Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Labour Card No.<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.labourCardNo} 
                      isRequired 
                      value={PassportFormData?.labourCardNo ||''} 
                      onChange={({target})=>{
                        setPassportFormData({
                          ...PassportFormData,
                          labourCardNo: target.value
                        });
                      }}
                      borderColor="gray" 
                      placeholder="Labour Card No." 
                    />
                    {EmpFormErrors?.labourCardNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.labourCardNo}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Labour Card Expiry Date<span className="text-red-600">*</span></label>
                    <DatePicker
                       required
                       className={`${EmpFormErrors?.visaExpiryDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={PassportFormData?.labourCardExpiry}
                        onChange={(date) =>{
                          setPersonalFormData({
                            ...PassportFormData,
                            labourCardExpiry : date
                          })
                        }}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Labour Card Expiry Date"
                        dateFormat={"dd-MM-yyyy"}
                      />
                    {EmpFormErrors?.labourCardExpiry && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.labourCardExpiry}</span>}
                  </div>
                </div>

                </>}

                {EmpTabLock.passportVisaInfoIsLock === true ||
                userData.isActiveBranch === 1023 ? (
                  ""
                ) : (
                  <div className="flex gap-6 justify-end mt-6">
                    <Button type="submit" isLoading={IsPostPassportLoading} loadingText={'Submitting...'} variant="solid" colorScheme="purple">Submit</Button> 
                  </div>
                )}
              </form>
            </TabPanel>
            <TabPanel>
              <form onSubmit={SaveEmpQualificationDetails}>
               

              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Award size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Qualification Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Highest Qualification<span className="text-red-600">*</span></label>
                    <Select 
                     isInvalid={EmpFormErrors?.empQualification}
                     value={QualificationFormData?.empQualification || ''}
                     onChange={({target})=>{
                      setQualificationFormData({
                        ...QualificationFormData,
                        empQualification: target.value
                      })
                     }} 
                     borderColor="gray" 
                     placeholder="Select Qualification" 
                    >
                      <option value='Post Graduation'>Post Graduation</option>
                      <option value='Graduation'>Graduation</option>
                    </Select>
                    {EmpFormErrors?.empQualification && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.empQualification}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>University<span className="text-red-600">*</span></label>
                    <Input  
                     isInvalid={EmpFormErrors?.empUniversity}
                     value={QualificationFormData?.empUniversity || ''}
                     onChange={({target})=>{
                      setQualificationFormData({
                        ...QualificationFormData,
                        empUniversity: target.value
                      })
                     }} 
                     borderColor="gray"
                     placeholder="University" />
                    {EmpFormErrors?.empUniversity && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.empUniversity}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Institute<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.instituteName}
                      value={QualificationFormData?.instituteName || ''}
                      onChange={({target})=>{
                       setQualificationFormData({
                         ...QualificationFormData,
                         instituteName: target.value
                       })
                      }} 
                      borderColor="gray" 
                      placeholder="Institute" 
                    />
                    {EmpFormErrors?.instituteName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.instituteName}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Year of Passing<span className="text-red-600">*</span></label>
                    <DatePicker
                      className={`${EmpFormErrors?.empYearOfPassing ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                      selected={QualificationFormData?.empYearOfPassing}
                      onChange={(date) =>{
                        setQualificationFormData({
                          ...QualificationFormData,
                          empYearOfPassing: date
                        })
                      }}
                      placeholderText="Year of Passing"
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                    {EmpFormErrors?.empYearOfPassing && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.empYearOfPassing}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
              
                <div className="space-y-2">
                    <label>Status<span className="text-red-600">*</span></label>
                    <Select 
                      isInvalid={EmpFormErrors?.status}
                      value={QualificationFormData?.status || ''}
                      onChange={({target})=>{
                       setQualificationFormData({
                         ...QualificationFormData,
                         status: target.value
                       })
                      }}  
                      borderColor="gray" 
                      placeholder="Status" 
                    >
                      <option value='Completed'>Completed</option>
                      <option value='Pursuing'>Pursuing</option>
                    </Select>
                    {EmpFormErrors?.status && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.status}</span>}
                  </div>
              
                <div className="space-y-2">
                    <label>City<span className="text-red-600">*</span></label>
                    <Input 
                     isInvalid={EmpFormErrors?.city}
                     value={QualificationFormData?.city || ''}
                     onChange={({target})=>{
                      setQualificationFormData({
                        ...QualificationFormData,
                        city: target.value
                      })
                     }}  
                     borderColor="gray" 
                     placeholder="City" 
                    />
                    {EmpFormErrors?.city && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.city}</span>}
                  </div>


                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Country<span className="text-red-600">*</span></label>
                    <Select  
                      isInvalid={EmpFormErrors?.country}
                      value={QualificationFormData?.country || ''}
                      onChange={({target})=>{
                       setQualificationFormData({
                         ...QualificationFormData,
                         country: target.value
                       });
                       getStateList(target.value);
                      }} 
                      borderColor="gray" 
                      placeholder="Select Country" >
                    {CountryList.map((data,i)=>{
                      return <option key={i} value={data.countryId} >{data.countryName}</option>
                     })}
                    </Select>
                    {EmpFormErrors?.country && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.country}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>State<span className="text-red-600">*</span></label>
                    <Select 
                     isInvalid={EmpFormErrors?.state}
                     value={QualificationFormData?.state || ''}
                     onChange={({target})=>{
                      setQualificationFormData({
                        ...QualificationFormData,
                        state: target.value
                      })
                     }}
                     borderColor="gray" 
                     placeholder="Select State" >
                    {StateList.map((data,i)=>{
                        return  <option key={i} value={data.stateId}>{data.stateName}</option>

                      })}
                    </Select>
                    {EmpFormErrors?.state && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.state}</span>}
                  </div>
                </div>



                {EmpTabLock.qualificationInfoIsLock === true ||
                userData.isActiveBranch === 1023 ? (
                  ""
                ) : (
                  <div className="flex gap-6 justify-end mt-6">
                    <Button isLoading={IsPostQualificationLoading} loadingText={'Submitting...'} type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </div>
                )}
              </form>
            </TabPanel>
            <TabPanel>
              <form onSubmit={SaveEmpPreviousJobDetails}>
              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Briefcase size="18px" className=" text-indigo-600 mr-2" />
                <h1 className="text-lg font-normal text-indigo-600">
                  Previous Job Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">

                  <div className="space-y-2">
                    <label>Start Date<span className="text-red-600">*</span></label>
                    <DatePicker 
                       className={`${EmpFormErrors?.startDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={PreviousJobFormData?.startDate}
                        onChange={(date) =>{
                          setPreviousJobFormData({
                            ...PreviousJobFormData,
                            startDate : date
                          })
                        }}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Start Date"
                        dateFormat={"dd-MM-yyyy"}
                      />
                    {EmpFormErrors?.startDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.startDate}</span>}
                  </div> 

                  <div className="space-y-2">
                    <label>End Date<span className="text-red-600">*</span></label>
                    <DatePicker
                       className={`${EmpFormErrors?.endDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={PreviousJobFormData?.endDate}
                        onChange={(date) =>{
                          setPreviousJobFormData({
                            ...PreviousJobFormData,
                            endDate : date
                          })
                        }}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="End Date"
                        dateFormat={"dd-MM-yyyy"}
                      />
                    {EmpFormErrors?.endDate && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.endDate}</span>}
                  </div>

                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Annual CTC<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.salary} 
                      value={PreviousJobFormData?.salary || ''} 
                      onChange={({target})=>{
                        setPreviousJobFormData({
                          ...PreviousJobFormData,
                          salary: target.value
                        })
                      }} 
                      type="number" 
                      borderColor="gray" 
                      placeholder="Annual CTC (in LPA)" />
                    {EmpFormErrors?.salary && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.salary}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Job Title<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.position} 
                      value={PreviousJobFormData?.position || ''} 
                      onChange={({target})=>{
                        setPreviousJobFormData({
                          ...PreviousJobFormData,
                          position: target.value
                        })
                      }}  
                      borderColor="gray" 
                      placeholder="Job Title" 
                    />
                    {EmpFormErrors?.position && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.position}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Job Description</label>
                    <Input 
                      isInvalid={EmpFormErrors?.jobDescription} 
                      value={PreviousJobFormData?.jobDescription || ''} 
                      onChange={({target})=>{
                        setPreviousJobFormData({
                          ...PreviousJobFormData,
                          jobDescription: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Job Description" />
                    {EmpFormErrors?.jobDescription && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.jobDescription}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Company<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.company} 
                      value={PreviousJobFormData?.company || ''} 
                      onChange={({target})=>{
                        setPreviousJobFormData({
                          ...PreviousJobFormData,
                          company: target.value
                        })
                      }} 
                      borderColor="gray" 
                      placeholder="Company" 
                    />
                    {EmpFormErrors?.company && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.company}</span>}
                  </div>
                </div>

                {EmpTabLock.previousJobInfoIsLock === true ||
                userData.isActiveBranch === 1023 ? (
                  ""
                ) : (
                  <div className="flex gap-6 justify-end mt-6">
                    <Button isLoading={IsPostPreviousJobLoading} loadingText={'Submitting...'} type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </div>
                )}
              </form>
            </TabPanel>

            <TabPanel>
              <form onSubmit={SaveEmpDocumentsDetails}>
            <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <File size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Document Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Document Type<span className='text-red-500 font-semibold'>*</span></label>
                    <Select 
                     isRequired 
                     value={EmpDocDetails?.documentType} 
                     onChange={({target})=>{
                      setEmpDocDetails({
                       ...EmpDocDetails, 
                       documentType: target.value,
                       documentNumber: '',
                       documentName: DocumentMasterList?.filter((data)=> Number(data.documentId) === Number(target.value) )?.map((data)=> data.documentTypeName)[0]
                     });
                     }} borderColor='gray' placeholder='Please Select a Document'>
                      {DocumentMasterList.map((data,i)=>{
                        return <option key={i} value={data.documentId}>{data.documentTypeName}</option>
                      })}
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <label>Document Number<span className='text-red-500 font-semibold'>*</span></label>
                    <InputGroup size='md'>
                    <Input 
                      isInvalid={FormErrors?.documentNumber}
                      isRequired 
                      value={EmpDocDetails?.documentNumber || ''} 
                      onChange={({target})=>{

                        const value = target.value;
                        const docType = Number(EmpDocDetails?.documentType);
                        setEmpDocDetails({
                          ...EmpDocDetails,
                          documentNumber: value,
                        });
                        // switch (docType) {
                        //   case 1:
                        //     ValidateField('AadharNumber', 'documentNumber', value);
                        //       setEmpDocDetails({
                        //         ...EmpDocDetails,
                        //         documentNumber: value,
                        //       });
                        //     break;
                        //   case 2:
                        //     ValidateField('Pancard', 'documentNumber', value);
                        //     setEmpDocDetails({
                        //       ...EmpDocDetails,
                        //       documentNumber: value,
                        //     });
                        //     break;
                        //   case 3:
                        //     ValidateField('Passport', 'documentNumber', value);
                        //     setEmpDocDetails({
                        //       ...EmpDocDetails,
                        //       documentNumber: value,
                        //     });
                        //     break;
                        //   case 4:
                        //     ValidateField('Alphanumeric', 'documentNumber', value);
                        //     setEmpDocDetails({
                        //       ...EmpDocDetails,
                        //       documentNumber: value,
                        //     });
                        //     break;
                        //   case 5:
                        //     ValidateField('EmiratesId', 'documentNumber', value);
                        //     setEmpDocDetails({
                        //       ...EmpDocDetails,
                        //       documentNumber: value,
                        //     });
                        //     break;
                        //   case 7:
                        //     ValidateField('VisaNumber', 'documentNumber', value);
                        //     setEmpDocDetails({
                        //       ...EmpDocDetails,
                        //       documentNumber: value,
                        //     });
                        //     break;
                        //   default:
                        //     setEmpDocDetails({
                        //       ...EmpDocDetails,
                        //       documentNumber: value,
                        //     });
                        // }
                        
                      }} 
                      borderColor="gray" 
                      placeholder="Document Number" 
                    />
                      <InputRightElement width='4.5rem'>
                        <Button 
                          onClick={()=>{
                            setEmpDocDetails({...EmpDocDetails, documentNumber: ''});
                          }} 
                          size='xs'
                        >Clear</Button>
                      </InputRightElement>
                    </InputGroup>
                    {FormErrors?.documentNumber && <span className="text-red-600 text-sm" >{FormErrors?.documentNumber}</span>}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                <div className="space-y-2">
                    <div className="flex justify-between">
                      <label>Valid Upto (DD/MM/YYYY)</label>
                      <Checkbox isChecked={EmpDocDetails?.isValidityReq} onChange={({target})=>{
                         setEmpDocDetails({...EmpDocDetails, isValidityReq: target.checked,validUpto: ''})
                        }}>Is Validity Required</Checkbox>
                    </div>
                    <Input isDisabled={!EmpDocDetails?.isValidityReq} type='date' value={EmpDocDetails?.validUpto || ''} onChange={({target})=>{setEmpDocDetails({...EmpDocDetails,validUpto: target.value})}}  borderColor="gray" placeholder="Valid Upto" />
                  </div>

                  <div className="space-y-2"> 
                    <label>Attach Document{EmpDocDetails?.empDocumentId === 0 && <span className='text-red-500 font-semibold'>*</span>}</label>
                    <Input 
                      ref={FileRef}
                      isRequired={EmpDocDetails?.empDocumentId === 0 ? true : false} 
                      onChange={({target})=>{
                        setEmpDocDetails({...EmpDocDetails, attachment: target.files[0]})
                      }} 
                      type='file' 
                      borderColor="gray" 
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Remarks</label>
                    <Input 
                      isInvalid={FormErrors?.remark} 
                      value={EmpDocDetails?.remark || ''} 
                      onChange={({target})=>{
                        ValidateField('Alphanumeric','remark',target.value)
                        setEmpDocDetails({...EmpDocDetails, remark: target.value})
                      }} 
                      borderColor="gray" 
                      placeholder="Remarks" 
                    />
                    {FormErrors?.remark && <span className="text-red-600 text-sm" >{FormErrors.remark}</span>}
                  </div>
                </div>
                {EmpTabLock.employeeDocsInfoIsLock === true ||
                userData.isActiveBranch === 1023 ? (
                  ""
                ) : (
                  <>
                    {Number(ActivePlanDetails.currentDataUsage || 0) <=
                    Number(ActivePlanDetails.dataUsageUpto || 0) ? (
                      <div className="flex gap-6 justify-end mt-6">
                    <Button onClick={()=>{
                       reset();
                       setEmpDocDetails({
                         empDocumentId: 0,
                         guId: userData.guId,
                         documentType: "",
                         documentNumber: "",
                         isValidityReq: false,
                         validUpto: "",
                         attachment: "",
                         attachmentPath: "",
                         remark: "",
                         companyId: userData?.companyId,
                         branchId: userData?.isActiveBranch
                       });
                    }} variant="outline" colorScheme="purple">Reset</Button>
                    <Button isLoading={IsSaveDocumentLoading} loadingText="Saving..." type="submit" variant="solid" colorScheme="purple">Save</Button> 
                      </div>
                    ) : (
                      <div className="flex justify-end">
                        <h6 className="text-white bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">
                          Data Limit Exceeded. Please Contact to HR!!
                        </h6>
                      </div>
                    )}
                  </>
                )}
              </form>

              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <File size="18px" className="text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Documents
                </h1>
              </div>

              <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={EmpDocumentList} // Row Data for Rows
                  columnDefs={DocumentColumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <form onSubmit={SaveEmpBankDetails}>
                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className=" text-indigo-600 mr-2 "
                  />
                  <h1 className="text-lg font-normal text-indigo-600">
                    Bank Details
                  </h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-6">
                  <div className='space-y-2'>
                    <label>Bank Name<span className="text-red-600">*</span></label>
                    <Select 
                      isInvalid={EmpFormErrors?.bankName}
                      isRequired 
                      value={BankFormData?.bankName ||''} 
                      onChange={({target})=>{
                        setBankFormData({
                          ...BankFormData,
                          bankName: target.value
                        });
                      }} 
                      borderColor={'gray'} 
                      placeholder="Select Bank">
                      {BankMasterList.map((data,i)=>{
                        return <option key={i} value={data.bankId}>{data.bankName}</option>
                      })}
                    </Select>
                    {EmpFormErrors?.bankName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.bankName}</span>}
                  </div>

                  {userData.countryName === "India"?<>

                  <div className="space-y-2">
                    <label>Branch Name<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.bankBranchName}
                      isRequired 
                      value={BankFormData?.bankBranchName ||''} 
                      onChange={({target})=>{
                        setBankFormData({
                          ...BankFormData,
                          bankBranchName: target.value
                        });
                      }} 
                      borderColor="gray" 
                      placeholder="Branch Name" />
                    {EmpFormErrors?.bankBranchName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.bankBranchName}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>IFSC Code<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.ifscCode}
                      isRequired 
                      value={BankFormData?.ifscCode ||''} 
                      onChange={({target})=>{
                        setBankFormData({
                          ...BankFormData,
                          ifscCode: target.value
                        });
                      }} 
                     borderColor="gray" 
                     placeholder="IFSC Code" />
                    {EmpFormErrors?.ifscCode && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.ifscCode}</span>}
                  </div>

                  <div className='space-y-2'>
                    <label>Account Number<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.accountNumber}
                      isRequired 
                      value={BankFormData?.accountNumber ||''} 
                      onChange={({target})=>{
                        setBankFormData({
                          ...BankFormData,
                          accountNumber: target.value
                        });
                      }} 
                      type='number'
                     borderColor="gray" 
                     placeholder="Account Number" />
                    {EmpFormErrors?.accountNumber && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.accountNumber}</span>}
                  </div>
                  </> : <>

                  <div className='space-y-2'>
                    <label>IBAN Number<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.ibanNumber}
                      isRequired 
                      value={BankFormData?.ibanNumber ||''} 
                      onChange={({target})=>{
                        setBankFormData({
                          ...BankFormData,
                          ibanNumber: target.value
                        });
                      }} 
                      borderColor="gray" 
                      placeholder="IBAN Number" 
                    />
                    {EmpFormErrors?.ibanNumber && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.ibanNumber}</span>}
                  </div>


                  <div className='space-y-2'>
                    <label>Routing/Swift Code<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.routingAndSwiftCode}
                      isRequired 
                      value={BankFormData?.routingAndSwiftCode ||''} 
                      onChange={({target})=>{
                        setBankFormData({
                          ...BankFormData,
                          routingAndSwiftCode: target.value
                        });
                      }}
                      borderColor="gray" 
                      placeholder="Routing/Swift Number" />
                    {EmpFormErrors?.routingAndSwiftCode && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.routingAndSwiftCode}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Personal Id</label>
                    <Input 
                      isInvalid={EmpFormErrors?.personalId} 
                      value={BankFormData?.personalId || ''} 
                      onChange={({target})=>{
                        setBankFormData({
                          ...BankFormData,
                          personalId: target.value
                        });
                      }}
                      borderColor="gray" 
                      placeholder="Personal Id" />
                    {EmpFormErrors?.personalId && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.personalId}</span>}
                  </div>
                  </>}
                </div>
                {EmpTabLock.bankInfoIsLock === true ||
                userData.isActiveBranch === 1023 ? (
                  ""
                ) : (
                  <div className="flex gap-6 justify-end mt-6">
                    <Button isLoading={IsPostEmpBankLoading} loadingText="Submitting..." type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </div>
                )}
              </form>
            </TabPanel>
            <TabPanel>
              <form onSubmit={SaveEmpFamilyDetails}>
              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Users size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Family Details
                </h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Member Name<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.memberName} 
                      value={FamilyFormData?.memberName || ''} 
                      onChange={({target})=>{
                        setFamilyFormData({
                          ...FamilyFormData,
                          memberName: target.value
                        })
                      }} 
                      borderColor="gray" 
                      placeholder="Member Name" 
                    />
                    {EmpFormErrors?.memberName && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.memberName}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Relation<span className="text-red-600">*</span></label>
                    <Input 
                      isInvalid={EmpFormErrors?.relation} 
                      value={FamilyFormData?.relation || ''} 
                      onChange={({target})=>{
                        setFamilyFormData({
                          ...FamilyFormData,
                          relation: target.value
                        })
                      }} 
                      borderColor="gray" 
                      placeholder="Relation" />
                    {EmpFormErrors?.relation && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.relation}</span>}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Date of Birth<span className="text-red-600">*</span></label>
                    <DatePicker
                       className={`${EmpFormErrors?.dob ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                       selected={FamilyFormData?.dob}                                                                                                                                                                                                                                                                                               
                        onChange={(date) =>{
                          setFamilyFormData({
                            ...FamilyFormData,
                            dob: date
                          })
                        }}
                        maxDate={new Date(moment().subtract(20, 'years').format('YYYY-MM-DD'))}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Date of Birth"
                        dateFormat={"dd-MM-yyyy"}
                      />
                    {EmpFormErrors?.dob && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.age}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Gender<span className="text-red-600">*</span></label>
                    <Select 
                      isInvalid={EmpFormErrors?.gender} 
                      value={FamilyFormData?.gender || ''} 
                      onChange={({target})=>{
                        setFamilyFormData({
                          ...FamilyFormData,
                          gender: target.value
                        })
                      }}  
                      borderColor='gray' 
                      placeholder="Select Gender"
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Select>
                    {EmpFormErrors?.gender && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.gender}</span>}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Emirates ID</label>
                    <Input  
                      isInvalid={EmpFormErrors?.emiratesID} 
                      value={FamilyFormData?.emiratesID || ''} 
                      onChange={({target})=>{
                        setFamilyFormData({
                          ...FamilyFormData,
                          emiratesID: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Emirates ID" 
                    />
                    {EmpFormErrors?.emiratesID && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.emiratesID}</span>}
                  </div>

                  <div className="space-y-2">
                    <label>Visa No.</label>
                    <Input  
                      isInvalid={EmpFormErrors?.visaDetail} 
                      value={FamilyFormData?.visaDetail || ''} 
                      onChange={({target})=>{
                        setFamilyFormData({
                          ...FamilyFormData,
                          visaDetail: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Visa No." />
                    {EmpFormErrors?.visaDetail && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.visaDetail}</span>}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Passport No.</label>
                    <Input 
                      isInvalid={EmpFormErrors?.passportDetail} 
                      value={FamilyFormData?.passportDetail || ''} 
                      onChange={({target})=>{
                        setFamilyFormData({
                          ...FamilyFormData,
                          passportDetail: target.value
                        })
                      }}
                      borderColor="gray" 
                      placeholder="Passport No." />
                    {EmpFormErrors?.passportDetail && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.passportDetail}</span>}
                  </div>

                </div>

                {EmpTabLock.familyInfoIsLock === true ||
                userData.isActiveBranch === 1023 ? (
                  ""
                ) : (
                  <div className="flex gap-6 justify-end mt-6">
                    <Button isLoading={IsPostFamilyLoading} loadingText="Submitting..." type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </div>
                )}
              </form>
              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <File size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Family Member Details
                </h1>
              </div>

              <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={familyrowData} // Row Data for Rows
                  columnDefs={familycolumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                />
              </div>
            </TabPanel>

            {userData.countryName === "India" ? (
              <>
                {/* Compliance Details Tab */}
                <TabPanel>
                  <form onSubmit={SaveComplianceDetails}>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-6">

<div className="space-y-2">
<label>UAN Number<span className="text-red-600">*</span></label>
<Input 
isRequired
isInvalid={EmpFormErrors?.empEPFUANNo} 
value={ComplianceFormData?.empEPFUANNo || ''} 
onChange={({target})=>{
  setComplianceFormData({
    ...ComplianceFormData,
    empEPFUANNo: target.value
  })
}} 
type="number"
borderColor={"gray.400"} 
placeholder="UAN Number" />
 {EmpFormErrors?.empEPFUANNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.empEPFUANNo}</span>}
</div>

<div className="space-y-2">
  <label>EPF Number</label>
  <Input 
    isInvalid={EmpFormErrors?.empEPFNo} 
    value={ComplianceFormData?.empEPFNo || ''} 
    onChange={({target})=>{
      setComplianceFormData({
        ...ComplianceFormData,
        empEPFNo: target.value
      })
    }}
    borderColor={"gray.400"} 
    placeholder="EPF Number" />
  {EmpFormErrors?.empEPFNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.empEPFNo}</span>}
</div>

<div className="space-y-2">
  <label>ESI Number</label>
  <Input 
    isInvalid={EmpFormErrors?.empESICNo} 
    value={ComplianceFormData?.empESICNo || ''} 
    onChange={({target})=>{
      setComplianceFormData({
        ...ComplianceFormData,
        empESICNo: target.value
      })
    }}      
    borderColor={"gray.400"} 
    placeholder="ESI Number" 
  />
  {EmpFormErrors?.empESICNo && <span className="text-red-600 my-1 text-sm">{EmpFormErrors?.empESICNo}</span>}
</div>

</div>

                    <div className="text-right">
                     <Button isLoading={IsPostComplianceLoading} loadingText="Submitting..." type="submit" variant="solid" colorScheme="purple">Submit</Button> 

                    </div>
                  </form>
                </TabPanel>{" "}
              </>
            ) : (
              ""
            )}
          </TabPanels>
        </Tabs>
      </div>


      <Modal size="full" isOpen={isViewDocOpen} onClose={onViewDocClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attachment</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div id="pdf-div">
              <embed
                src={PdfPath}
                type="application/pdf"
                className="h-screen w-full !select-none"
                height={800}
                width={500}
              />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme="purple" onClick={onViewDocClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default EmpProfile;
