import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { Button, Radio,RadioGroup,Stack, Center, Spinner, Box } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import { Search } from "react-feather";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from 'react-select';
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function EmployeesReport() {

    const { userData } = useAuth();

    const [ReportBasedOn, setReportBasedOn] = useState('1');
    const [ShowEmployees, setShowEmployees] = useState('1');

    const [isLoading, setisLoading] = useState(false);

    const [SelctdDepartment, setSelctdDepartment] = useState([]);
    const [SelctdDesignation, setSelctdDesignation] = useState([]);

    const [DesignationList, setDesignationList] = useState([]);
    const [DepartmentList, setDepartmentList] = useState([]);

    const [DOJDateRange, setDOJDateRange] = useState([new Date(moment().startOf("month")), new Date(moment().endOf("month"))]);
    const [DOJstartDate, DOJendDate] = DOJDateRange;

    const [DOJRowData, setDOJRowData] = useState([]);
    const [DesignationRowData, setDesignationRowData] = useState([]);
    const [DepartmentRowData, setDepartmentRowData] = useState([]);


    const DojGridRef = useRef();
    const DesignationGridRef = useRef();
    const DepartmentGridRef = useRef();

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [DOJColumnDefs] = useState( userData.countryName === "India" ?[
      {
        headerName: "Date of Joining",
        field: "dateofjoin",
        valueGetter: (params) =>
          moment(params.data.dateofjoin).format("DD/MM/YYYY"),
      },

      {
        headerName: "Emp Code",
        field: "empCode",
      },
      {
        headerName: "Emp Name",
        field: "fullName",
      },

      {
        headerName: "Designation",
        field: "designationName",
      },

      {
        headerName: "Department",
        field: "departmentName",
      },
      {
        headerName: "Status",
        field: "isResignee",
        cellRenderer: ({ data }) =>
          data.isResignee ? (
            <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Resigned
            </span>
          ) : (
            <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Active
            </span>
          ),
      },
    ] : [
      {
        headerName: "Date of Joining",
        field: "dateofjoin",
        valueGetter: (params) =>
          moment(params.data.dateofjoin).format("DD/MM/YYYY"),
      },

      {
        headerName: "Emp Code",
        field: "empCode",
      },
      {
        headerName: "Emp Name",
        field: "fullName",
      },

      {
        headerName: "Designation",
        field: "designationName",
      },

      {
        headerName: "Department",
        field: "departmentName",
      },
      {
        headerName: "Status",
        field: "isResignee",
        cellRenderer: ({ data }) =>
          data.isResignee ? (
            <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Resigned
            </span>
          ) : (
            <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Active
            </span>
          ),
      },
      {
        headerName: "Grade",
        field: "grade"
      },
      {
        headerName: "Division",
        field: "division"
      },
    ]);
  
      const [DesignationColumnDefs] = useState(userData.countryName === "India" ? [
           {
            headerName: "Emp Code",
            field: "empCode",
          },
          {
            headerName: "Emp Name",
            field: "fullName",
          },
          {
            headerName: "Department",
            field: "departmentName",
          },
          {
            headerName: "Date of Joining",
            field: "dateofjoin",
            valueGetter:(params)=> moment(params.data.dateofjoin).format("DD/MM/YYYY")
          },
          {
            headerName: "Status",
            field: "isResignee",
            cellRenderer: ({ data }) =>
              data.isResignee ? (
                <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                  Resigned
                </span>
              ) : (
                <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                  Active
                </span>
              ),
          },
      ] : [
        {
         headerName: "Emp Code",
         field: "empCode",
       },
       {
         headerName: "Emp Name",
         field: "fullName",
       },
       {
         headerName: "Department",
         field: "departmentName",
       },
       {
         headerName: "Date of Joining",
         field: "dateofjoin",
         valueGetter:(params)=> moment(params.data.dateofjoin).format("DD/MM/YYYY")
       },
       {
         headerName: "Status",
         field: "isResignee",
         cellRenderer: ({ data }) =>
           data.isResignee ? (
             <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
               Resigned
             </span>
           ) : (
             <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
               Active
             </span>
           ),
       },
       {
        headerName: "Grade",
        field: "grade"
      },
      {
        headerName: "Division",
        field: "division"
      },
   ]);

      const [DepartmentColumnDefs] = useState( userData.countryName === "India" ? [
          {
            headerName: "Emp Code",
            field: "empCode",
          },
          {
            headerName: "Emp Name",
            field: "fullName",
          },

          {
            headerName: "Designation",
            field: "designationName",
          },

          {
            headerName: "Date of Joining",
            field: "dateofjoin",
            valueGetter:(params)=> moment(params.data.dateofjoin).format("DD/MM/YYYY")
          },
          {
            headerName: "Status",
            field: "isResignee",
            cellRenderer: ({ data }) =>
              data.isResignee ? (
                <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                  Resigned
                </span>
              ) : (
                <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                  Active
                </span>
              ),
          },
      ] : [
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "fullName",
        },

        {
          headerName: "Designation",
          field: "designationName",
        },

        {
          headerName: "Date of Joining",
          field: "dateofjoin",
          valueGetter:(params)=> moment(params.data.dateofjoin).format("DD/MM/YYYY")
        },
        {
          headerName: "Status",
          field: "isResignee",
          cellRenderer: ({ data }) =>
            data.isResignee ? (
              <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Resigned
              </span>
            ) : (
              <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Active
              </span>
            ),
        },
        {
          headerName: "Grade",
          field: "grade"
        },
        {
          headerName: "Division",
          field: "division"
        },
    ]);

     // Table Pagination
     const paginationNumberFormatter = useCallback((params) => {
       return "[" + params.value.toLocaleString() + "]";
     }, []);
   
     //Table columns properties applies to all columns
     const defaultColDef = useMemo(() => ({
       flex: 1,
       sortable: true,
       filter: "agTextColumnFilter",
       floatingFilter: true,
       cacheQuickFilter: true,
       cellClass: 'no-border',
       enableCellTextSelection: true,
       resizable: true
     }));


    
    useEffect(() => {
      SearchDOJData(DOJstartDate,DOJendDate);
      getDesignationMasterList();
      getDepartmentList();
    }, [])
    

    const getDesignationMasterList = async () => {
        try {
          const response= await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetDesignationMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Designation List Response",response);
          const res = response.data;
          let ActiveDesignation = res.filter(data => data.isActive === true);
          setDesignationList(ActiveDesignation);
        } catch (error) {
          console.error(error);
        }
      }


      const getDepartmentList = async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/DepartmentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Department List Response",response);
          const res = response.data;
          let ActiveDepartment = res.filter(data => data.isActive === true);
          setDepartmentList(ActiveDepartment);
        } catch (err) {
          console.error(err);
        }
      }



    const SearchDOJData = async (StartDate,EndDate) => {
        setisLoading(true);
        try {
            const response = await authApi.get(
                `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )
              console.log("DOJ Emp List Response",response);
              const res = response.data;
              let ActiveEmployees = ShowEmployees === "2" ? res.filter((data) => data.isResignee === true) : res;
              const DojFilteredArray = ActiveEmployees.filter(obj => {
                const date = new Date(obj.dateofjoin);
                return date >= StartDate && date <= EndDate;
              });
              setDOJRowData(DojFilteredArray);
              setisLoading(false)
        } catch (error) {
            console.error(error);
        }
    }


    const SearchDesignationData = async () => {
        setisLoading(true);
        try {
          const response= await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
          console.log("Designation List Response",response);
          const res = response.data;
          let ActiveDesignation = ShowEmployees === "2" ? res.filter((data) => data.isResignee === true) : res;
          const FilteredData = ActiveDesignation.filter(data => Number(data.designation) === Number(SelctdDesignation.designationId))
          setDesignationRowData(FilteredData);
          setisLoading(false);
        } catch (error) {
          console.error(error);
        }
    }



    const SearchDepartmentData = async () => {
        setisLoading(true);
        try {
            const response= await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Designation List Response",response);
            const res = response.data;
            let ActiveEmp = ShowEmployees === "2" ? res.filter((data) => data.isResignee === true) : res;
            const FilteredData = ActiveEmp.filter(data => Number(data.department) === Number(SelctdDepartment.departmentId) )
            setDepartmentRowData(FilteredData);
            setisLoading(false);
          } catch (err) {
            console.error(err);
          }
    }

  return (
    <div>
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
          <h1 className="text-lg font-bold text-gray-800">
            {ReportBasedOn === "1" ? 'Date of Joining Report' : ReportBasedOn === "2" ? 'Designation Report' : ReportBasedOn === "3" ? "Department Report" : ''}
          </h1>
          <div className="bg-white drop-shadow-sm flex gap-2 items-center rounded-lg py-1.5 px-3">    
           <label className="font-medium">Show Report for:</label>
           <RadioGroup onChange={setReportBasedOn} value={ReportBasedOn}>
             <Stack direction='row' gap={2}>
               <Radio borderColor={'gray.400'} value='1'>DOJ</Radio>
               <Radio borderColor={'gray.400'} value='2'>Designation</Radio>
               <Radio borderColor={'gray.400'} value='3'>Department</Radio>
             </Stack>
           </RadioGroup>
         </div>
        </div>

        {ReportBasedOn === "1"?
        <div>
           <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 '>
            <div className='space-y-2 w-1/4'>
                <label>Select Date Range</label>
                <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
                  selectsRange={true}
                  startDate={DOJstartDate}
                  endDate={DOJendDate}
                  onChange={(update) => {
                    setDOJDateRange(update);
                  }}
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  placeholderText="Select Date Range"
                />
            </div>

            <div className="space-y-2">    
              <label>Show Employees</label>
              <RadioGroup onChange={setShowEmployees} value={ShowEmployees}>
                <Stack direction='row' gap={2} mb={2}>
                  <Radio borderColor={'gray.400'} value='1'>All</Radio>
                  <Radio borderColor={'gray.400'} value='2'>Resigned</Radio>
                </Stack>
              </RadioGroup>
            </div>

            <Button onClick={()=>{SearchDOJData(DOJstartDate,DOJendDate);}} leftIcon={<Search size={'18px'} />} colorScheme={'purple'} >Search</Button>


            </Box>


                {isLoading ? 

<Center>
<Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
</Center> : <>

<div className="text-right mb-4">
    <Button isDisabled={DOJRowData.length === 0 ? true : false} onClick={()=>{
        DojGridRef.current.api.exportDataAsCsv();
    }}  leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'}>Export</Button>
</div>

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={DojGridRef} // Ref for accessing Grid's API
          rowData={DOJRowData} // Row Data for Rows
          columnDefs={DOJColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>

</>
                
 }
        </div> 
        : ReportBasedOn === "2"?
        <div>

          <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end mb-4'>
            
            <div className='space-y-2 w-1/4'>
             <label>Select Designation</label>
             <ReactSelect
               options={DesignationList}
               value={SelctdDesignation}
               onChange={(value) => {setSelctdDesignation(value);}}
               getOptionLabel={(option) => option.designationName}
               getOptionValue={(option) => option.designationId}
               placeholder="Select Designation"
             />
            </div>

            <div className="space-y-2">    
              <label>Show Employees</label>
              <RadioGroup onChange={setShowEmployees} value={ShowEmployees}>
                <Stack direction='row' gap={2} mb={2}>
                  <Radio borderColor={'gray.400'} value='1'>All</Radio>
                  <Radio borderColor={'gray.400'} value='2'>Resigned</Radio>
                </Stack>
              </RadioGroup>
            </div>

            <Button isDisabled={SelctdDesignation.length === 0 ? true : false} onClick={()=>{SearchDesignationData();}} leftIcon={<Search size={'18px'} />} colorScheme={'purple'} >Search</Button>
            
            </Box>

            {isLoading ? 

<Center>
<Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
</Center> : <>

<div className="text-right mb-4">
    <Button isDisabled={DesignationRowData.length === 0 ? true : false} onClick={()=>{
        DesignationGridRef.current.api.exportDataAsCsv();
    }}  leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'}>Export</Button>
</div>

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={DesignationGridRef} // Ref for accessing Grid's API
          rowData={DesignationRowData} // Row Data for Rows
          columnDefs={DesignationColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>

</>
                
 }

        </div> 
        : ReportBasedOn === "3"?
        <div>

<Box className='bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 '>
            <div className='space-y-2 w-1/4'>
                <label>Select Department</label>
             <ReactSelect
               options={DepartmentList}
               value={SelctdDepartment}
               onChange={(value) => {setSelctdDepartment(value);}}
               getOptionLabel={(option) => option.departmentName}
               getOptionValue={(option) => option.departmentId}
               placeholder="Select Department"
              />

            </div>

            <div className="space-y-2">    
              <label>Show Employees</label>
              <RadioGroup onChange={setShowEmployees} value={ShowEmployees}>
                <Stack direction='row' gap={2} mb={2}>
                  <Radio borderColor={'gray.400'} value='1'>All</Radio>
                  <Radio borderColor={'gray.400'} value='2'>Resigned</Radio>
                </Stack>
              </RadioGroup>
            </div>

            <Button isDisabled={SelctdDepartment.length === 0 ? true : false} onClick={()=>{SearchDepartmentData();}} leftIcon={<Search size={'18px'} />} colorScheme={'purple'} >Search</Button>


            </Box>

            {isLoading ? 

<Center>
<Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
</Center> : <>

<div className="text-right mb-4">
    <Button isDisabled={DepartmentRowData.length === 0 ? true : false} onClick={()=>{
        DepartmentGridRef.current.api.exportDataAsCsv();
    }}  leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'}>Export</Button>
</div>

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={DepartmentGridRef} // Ref for accessing Grid's API
          rowData={DepartmentRowData} // Row Data for Rows
          columnDefs={DepartmentColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>

</>
                
 }

        </div> 
        :''}
    </div>
  )
}

export default EmployeesReport