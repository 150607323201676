import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import {useAuth} from '../Utils/AuthContext';
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import { Button, Center, Spinner } from "@chakra-ui/react";
import { LuSearch } from "react-icons/lu";
import moment from "moment";
import { FaDownload } from "react-icons/fa";
import authApi from "../Utils/AuthApis";

const AllEmpLeaveAppliedReport = () => {
    const {userData} = useAuth();

    const [IsLoading, setIsLoading] = useState(false);

    const [StartDate, setStartDate] = useState(new Date(moment().startOf('month')));
    const [EndDate, setEndDate] = useState(new Date(moment().endOf('month')));
    // console.log("Start Date",StartDate, "End Date", EndDate);

    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [RowData, setRowData] = useState([]);
    const [ColumnDefs,setColumnDefs] = useState([
    ]);
  
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    const defaultColDef = useMemo(() => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));

    useEffect(()=>{
        HandleGridHeader();
        GetEmployeeList();
    },[]);
    
    const HandleGridHeader = () => {
        setIsLoading(true);
        setColumnDefs([]);
        let StartingHeaders = [
            {
                headerName: "Emp Code",
                field: "empCode",
            },
            {
              headerName: "Emp Name",
              field: "empName",
            },
        ];

        const start = new Date(StartDate);
        const end = new Date(EndDate);
        const dates = [];
    
        while (start <= end) {
          dates.push({
            headerName: moment(start).format('DD-MMM-YYYY')+' / '+moment(start).format('ddd'),
            field: moment(start).format('YYYY-MM-DD'),
            // floatingFilter: false,
        });
          start.setDate(start.getDate() + 1);
        }

        setColumnDefs([
            ...StartingHeaders,
            ...dates
        ])
    }


//     const getLeaveAppliedList = async () => {

//         try {
//           const response = await authApi.get(
//             `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveApplyList?startdate=${moment(StartDate).format('YYYY-MM-DD')}&Enddate=${moment(EndDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`);
//           console.log("Leave Applied List", response.data);
    
//           const res = response.data;
    
//           let ApprovedList = res.filter((data) => data.leaveStatus === "Approve" || data.leaveStatus === "Approved" );
    
//           console.log("Approved Leave List", ApprovedList);

//           let FinalRowData = ApprovedList.map((data)=>{
// const leaveObject = data
  
//   const { leaveFromDate, leaveToDate, leaveType } = leaveObject;
  
//   const startDate = new Date(leaveFromDate);
//   const endDate = new Date(leaveToDate);
  
//   const dateArray = [];
//   let currentDate = startDate;
//   while (currentDate <= endDate) {
//     dateArray.push(new Date(currentDate));
//     currentDate.setDate(currentDate.getDate() + 1);
//   }
  
//   const modifiedLeaveObject = { 
//     ...leaveObject, 
//     typeBgColor : data.leaveType === "SL" ? 'bg-yellow-500' : data.leaveType === "CL" ? 'bg-blue-500' : 'bg-orange-500' 
// };
  
//   dateArray.forEach((date) => {
//     const formattedDate = date.toISOString().split('T')[0];
//     modifiedLeaveObject[formattedDate] = leaveType;
//   });
  
//   console.log(modifiedLeaveObject);
//   return modifiedLeaveObject
  
//           })

//           console.log("Final Row Data",FinalRowData);

//         //   setRowData(FinalRowData);

          
//         } catch (err) {
//           console.error(err);
//         } finally {
//           setIsLoading(false);
//         }
//       };


      const GetEmployeeList = async () => {
        try {
            const response = await authApi.get(
                `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`,
            )

            let ActiveEmps = response?.data?.filter(data => data.isActive);
            GetAttLeaveReportList(ActiveEmps);
        } catch (err) {
            console.error(err);
        }
      }


      const GetAttLeaveReportList = async (EmpList) => {
        // console.log("Emp List", EmpList);
    
        let FinalRowData = [];
    
        await Promise.all(
            EmpList.map(
                async (data) => {
                    try {
                        const response = await authApi.get(
                            `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetEmpFinalAttendanceByDate?EmpGuid=${data.guId}&fromdate=${moment(StartDate).format("YYYY-MM-DD")}&Todate=${moment(EndDate).format("YYYY-MM-DD")}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                        );
    
                        if (response?.data?.length > 0) {
                            // let LeaveData = response?.data?.filter(data => data.attendanceStatus === "L");
                            let LeaveData = response?.data
    
                            if (LeaveData.length > 0) {
                                const { empName, empCode } = LeaveData[0];
                                const resultObject = {
                                    empName: empName,
                                    empCode: empCode
                                };
    
                                LeaveData.forEach((data) => {
                                    resultObject[moment(data.attendanceDate).format('YYYY-MM-DD')] = data.attendanceStatus === "L" ? data.att_statusType : data.attendanceStatus;
                                });
    
                                // console.log("Emp Leave Data", resultObject);
                                FinalRowData.push(resultObject);
                            } 
                        }
                    } catch (error) {
                        console.error("Error fetching data for employee:", data.guId, error);
                    }
                }
            )
        );
    
        // console.log("Final Emp Data", FinalRowData);

        setRowData(FinalRowData)
        setIsLoading(false)
    };
    
    
      const exportData = () => {
        const params = {
          fileName: `Leave Report.csv`,
        };
        gridRef.current.api.exportDataAsCsv(params);
      };

  return (
    <div>
        <div className="flex gap-4 mb-4 justify-end">
          <div>
          <DatePicker
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
            selectsRange
            startDate={StartDate}
            endDate={EndDate}
            onChange={(update) => {
              setStartDate(update[0]);
              setEndDate(update[1]);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Leave History Range"
            showMonthDropdown
            showYearDropdown
          />
          </div>
          <Button onClick={()=>{
            HandleGridHeader();
            GetEmployeeList();
          }} isLoading={IsLoading} loadingText="Searching..." colorScheme="purple"  leftIcon={<LuSearch/>} >Search</Button>
          <Button onClick={()=>{
            exportData();
          }} isDisabled={RowData.length === 0}  colorScheme="purple" leftIcon={<FaDownload/>} >Export</Button>
        </div>
      {IsLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> :<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={RowData} // Row Data for Rows
          columnDefs={ColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>}
    </div>
  )
}

export default AllEmpLeaveAppliedReport