import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import authApi from "../Utils/AuthApis";
import { useAuth } from "../Utils/AuthContext";

function EmpSummaryDetails() {

    const { userData } = useAuth();
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Employee Code",
          field: "empCode",
        },
        {
          headerName: "Employee Name",
          field: "empName",
        },
        {
            headerName: "Amount Paid",
            field: "amountPaid",
          },
          {
            headerName: "Approved Bill Amount",
            field: "approvedBillAmount",
          },
          {
            headerName: "Pending Bill Amount",
            field: "approvedBillAmount",
          },
          {
            headerName: "Total Limit",
            field: "totalLimit",
          },
          {
            headerName: "Remaining Limit",
            field: "remanningLimit",
          },
          {
            headerName: "Advance Limit",
            field: "advanceLimit",
          },
          {
            headerName: "Expense Limit",
            field: "expenseLimit",
          },
          {
            headerName: "Approved Advance Amount",
            field: "advanceApprovedAmount",
          }
      ]);


      
            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
                return "[" + params.value.toLocaleString() + "]";
              }, []);
            
              //Table columns properties applies to all columns
              const defaultColDef = useMemo(() => ({
                // flex: 1,
                sortable: true,
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cacheQuickFilter: true,
                cellClass: 'no-border',
                resizable:true
              }));
    

              useEffect(()=>{
                GetEmpSummaryDetails();
              },[])


              const GetEmpSummaryDetails = async () => {
                try {
                    const response = await authApi.get(
                        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetSummaryReportList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                    )
                    console.log("Emp Summary List",response);
                    setRowData(response.data);
                } catch (error) {
                    console.error(error);
                }
              }
  return (
    <div>
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
          <h1 className="text-gray-700 text-lg font-bold ">
            Employee Limits Summary
          </h1>
        </div>

        
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>
    </div>
  )
}

export default EmpSummaryDetails