import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import ReactSelect from 'react-select';
import { Search } from "react-feather";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Box, Button, RadioGroup, Stack, Radio, useDisclosure, useToast, Input, Center, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../Utils/AuthContext";
import { LuCheckCircle, LuEdit } from "react-icons/lu";
import moment from "moment";
import usePost from "../Utils/usePost";
import authApi from "../Utils/AuthApis";
import useValidation from "../Utils/useValidation";

const EmpEditAttendance = () => {
   const { userData } = useAuth();
  const Validation = useValidation();


   const { IsPostLoading: IsPostNoAttLoading, PostData: PostNoAttData } = usePost('/api/LOPAdjustment/SaveAttendanceLopList');
   const { IsPostLoading: IsPostEditFinalAttLoading, PostData: PostEditFinalAttData } = usePost('/api/Attendance/UpdateLockedFinalAttandance');

   const [IsLoading, setIsLoading] = useState(false);

   const { isOpen, onOpen, onClose } = useDisclosure();
   const [AttInTime, setAttInTime] = useState();
   const [AttOutTime, setAttOutTime] = useState();
   const [AttAction, setAttAction] = useState("Mark Present");
   const [EditAttRemarks, setEditAttRemarks] = useState();

   const [SelectedDates, setSelectedDates] = useState([]);
   const [DataCondition, setDataCondition] = useState("All");


   function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  function convertToSeconds(hours, minutes) {
    return Number(hours) * 60 * 60 + Number(minutes) * 60;
  }

  const convertToTimeSpan = (time) => {
    let getTime = time;
    //  console.log("Get time",getTime);
    const [hours, minutes] = getTime.split(":");
    //  console.log("Hours",hours);
    //  console.log("Minutes",minutes);
    let seconds = convertToSeconds(hours, minutes);
    //  console.log("Secconds",seconds);
    let ConvertedTime = convertHMS(seconds);
    //  console.log("Converted Time",ConvertedTime);
    return ConvertedTime;
  };



    const [AllEmpList, setAllEmpList] = useState([]);
    const [SelectedEmp, setSelectedEmp] = useState([]);
    const [EditSelectedEmp, setEditSelectedEmp] = useState([]);
    const [DateRange, setDateRange] = useState([null,null]);
    const [StartDate, EndDate] = DateRange;
    
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const [FilteredRowData, setFilteredRowData] = useState([]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  const [ columnDefs ] = useState([
    {
      headerName: "Date",
      field: "attendanceDate",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      valueGetter:(params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
    },

    {
        headerName: "Punch In Time",
        field: "inTime",
      },
      {
        headerName: "Punch Out Time",
        field: "outTime",
      },
      {
        headerName: "Device",
        field: "linkDevice",
      },
      {
        headerName: "Status",
        field: "attendanceStatus",
        cellRenderer: (params) => 
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        P
      </span>:
       params.data.attendanceStatus === "L"?
      <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      L
    </span>
      :
       params.data.attendanceStatus === "A"?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      A
    </span> :
       params.data.attendanceStatus === "WO"?
      <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      WO
    </span> :
       params.data.attendanceStatus === "H"?
      <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      H
    </span> 
    : (
      <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        {params.data.attendanceStatus}
      </span>
    ),
      },
      {
        headerName: "Attendance",
        field: "att_statusType",
        cellRenderer:(params)=>
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.attendanceStatus === 'A'?
        <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.att_statusType === 'Late'?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.attendanceStatus === 'L'?
        <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.att_statusType === 'HD'?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "WO"?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "H"?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>
       : (
        <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>
      ),
      },
      {
        headerName: "Remarks",
        field: "remark"
      },
      {
        headerName: "Action",
        cellRenderer: ({data}) => (data.empGuId !== userData.guId && data.linkDevice === "NA") && <Button
          leftIcon={<LuEdit />}
          onClick={() => {
            setEditSelectedEmp(data);
            setAttInTime(data.inTime);
            setAttOutTime(data.outTime)
            onOpen();
          }}
          size={"xs"}
          colorScheme={"blue"}
        >
          Edit
        </Button>
      },
  ]); 


  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: 'no-border',
    resizable:true
  }));


  useEffect(() => {
    if(DataCondition === "All"){
        setFilteredRowData(_.orderBy(rowData,['attendanceDate'], ['asc']));
    } else if(DataCondition === "Absent"){
        setFilteredRowData(_.orderBy(rowData?.filter(data => data.attendanceStatus === "A"),['attendanceDate'], ['asc']));
    }else if(DataCondition === "Present"){
        setFilteredRowData(_.orderBy(rowData?.filter(data => data.attendanceStatus === "P"),['attendanceDate'], ['asc']));
    }
  }, [DataCondition]);
  

    useEffect(()=>{
        getEmployeeList();    
      },[]);
    
      const getEmployeeList = async() =>{
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Employee List",response.data);
          const res = response.data;
        
          setAllEmpList(res.filter((emp)=> emp.isActive === true));
        // setFilteredList(res.filter((emp)=> emp.isActive === true));
          
        } catch (error) {
          console.error(error)
        }
        
      }


      const HandleSearch = async () => {
        setIsLoading(true);
        try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetEmpFinalAttendanceByDate?EmpGuid=${SelectedEmp.guId}&fromdate=${new Date(StartDate).toLocaleDateString('en-CA')}&Todate=${new Date(EndDate).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Emp Attendance Data List",response);
            const res = response.data;
            setRowData(_.orderBy(res,['attendanceDate'], ['asc']));
            setFilteredRowData(_.orderBy(res,['attendanceDate'], ['asc']));
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
      }


      const onSelectionChanged = useCallback(() => {
        var SelectedEmployees = gridRef.current.api.getSelectedRows();
        console.log("Selected Dates", SelectedEmployees);
        setSelectedDates(SelectedEmployees);
      }, []);

      const exportData = () => {
        // let SelMonthName = MonthNames[new Date(SelectedDateMonth).getMonth()]
        const params = {
          fileName: `Attendance Report of ${SelectedEmp?.fullName}.csv`,
        };
      
        gridRef.current.api.exportDataAsCsv(params);
      };

      const SaveFinalEditAttendance = async (e) => {
        e.preventDefault();
        
        let body = {
          empGuId: EditSelectedEmp.empGuId,
          empName: EditSelectedEmp.empName,
          empCode: EditSelectedEmp.empCode,
          attendanceDate: EditSelectedEmp.attendanceDate,
          inTime: convertToTimeSpan(AttInTime || "00:00:00") ,
          outTime: convertToTimeSpan(AttOutTime || "00:00:00"),
          attendanceStatus: AttAction === "Mark Present" ? "P" : AttAction === "Mark LWP" ? "LWP" : AttAction === "Mark LOP" ? "LOP" : AttAction === "Mark Half Day" ? "HD" : '',
          linkDevice: EditSelectedEmp.linkDevice,
          remark: EditAttRemarks + '*',
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
          att_statusType: AttAction === "Mark Present" ? "Present" : AttAction === "Mark LWP" ? "LWP" : AttAction === "Mark LOP" ? "LOP" : AttAction === "Mark Half Day" ? "Half Day" : 'Absent',
          lock: true,
          designation: EditSelectedEmp.designation,
          department: EditSelectedEmp.department
        };
    
        console.log("Edit Body", body);

        const response = await PostEditFinalAttData(body);

        if(response){
          HandleSearch();
          setAttInTime("");
          setAttOutTime("");
          onClose();
        }
    
      };
    

    const SaveEditAttendance = async (MarkAction,QuotaId) => {

        let body = SelectedDates?.map((data) => {
          return {
            empGuid: data.empGuId,
            empCode: data.empCode,
            aDate: moment(data.attendanceDate).format('YYYY-MM-DD'),
            leaveTypeId: MarkAction === "Leave" ? QuotaId : 0,
            isLop:  MarkAction === "LOP" ? true : false,
            isPresent: MarkAction === "Present" ? true : false,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch
          }
        })

        console.log("Edit Att body", body);

        const response = await PostNoAttData(body);

        if(response){
            HandleSearch()
        }
    
       }

  return (
    <div>
      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end  mb-4'>

        <div className='space-y-2 w-1/4'>
            <label>Select Employee</label>
            <ReactSelect
              options={AllEmpList}
              value={SelectedEmp}
              onChange={(value) => {
                setSelectedEmp(value); 
                setRowData([]);
                setFilteredRowData([])
              }}
              getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
              getOptionValue={(option) => option.guId}
              key={(option)=> option.id}
              placeholder="Select Employee"
            />
        </div>

        <div className="space-y-2">
            <label>Select Date Range</label>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selectsRange={true}
              startDate={StartDate}
              endDate={EndDate}
              onChange={(update) => {
                 setDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Date Range'
              minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
              maxDate={new Date()}
            />
        </div>

        <Button isDisabled={(SelectedEmp.length === 0 || EndDate === null )? true : false} isLoading={IsLoading} loadingText="Searching..." colorScheme={'purple'} onClick={()=>{
            setDataCondition('All')
            HandleSearch();
            }} leftIcon={<Search size={'16px'}  />} >Search</Button>
        <Button  isDisabled={rowData.length === 0 ? true : false} onClick={()=>{exportData()}} leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'} >Export</Button>

      </Box>

      {IsLoading ? ( 
        <Center>
          <Spinner thickness='4px' color={'purple.500'}  size='lg'  />
        </Center> 
      ) : (
        <>

        <div className="flex justify-between items-center">
           <div className="px-5 flex py-3 space-x-5 ">
              <div className="my-auto">
                <FontAwesomeIcon className="rotate-180 text-lg" icon={faArrowTurnUp} />
              </div>
              <Button isDisabled={SelectedDates.length === 0} onClick={() => {SaveEditAttendance("Present",0)}} size={"sm"} variant={'outline'} colorScheme="purple">
                Mark Present
              </Button>
              <Button isDisabled={SelectedDates.length === 0} onClick={() => {SaveEditAttendance("LOP",0)}} size={"sm"} variant={'outline'} colorScheme="purple">
                Mark LOP
              </Button>
            </div>
            <div className="space-x-2">
              <Button size={'sm'} rounded={'full'} onClick={()=>{setDataCondition('All')}} variant={DataCondition === "All" ? 'solid' : 'outline'} leftIcon={DataCondition === "All" ? <LuCheckCircle/> : ''} colorScheme="blue">All</Button>
              <Button size={'sm'} rounded={'full'} onClick={()=>{setDataCondition('Absent')}} variant={DataCondition === "Absent" ? 'solid' : 'outline'} leftIcon={DataCondition === "Absent" ? <LuCheckCircle/> : ''} colorScheme="blue">Absent</Button>
              <Button size={'sm'} rounded={'full'} onClick={()=>{setDataCondition('Present')}} variant={DataCondition === "Present" ? 'solid' : 'outline'} leftIcon={DataCondition === "Present" ? <LuCheckCircle/> : ''} colorScheme="blue">Present</Button>
            </div>
        </div>

        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={FilteredRowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            // paginationPageSize={20}
            rowSelection="multiple"
            paginationNumberFormatter={paginationNumberFormatter}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
        </>

      )}


      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize={"lg"}
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight={"bold"}
          >Edit Attendance</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
          <form onSubmit={SaveFinalEditAttendance}>
            <ModalBody className="space-y-2 my-2">
              <div className="flex pb-1 gap-8">
                <div>
                  <p className="text-sm text-gray-500">Emp Code</p>
                  <h6 className="font-medium">{EditSelectedEmp.empCode}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Emp Name</p>
                  <h6 className="font-medium">{EditSelectedEmp.empName}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Attendance Date</p>
                  <h6 className="font-medium">{moment(EditSelectedEmp.attendanceDate).format('DD/MM/YYYY')}</h6>
                </div>
              </div>
              <hr />

              <div className="grid grid-cols-2 gap-4">

        <div onClick={()=>{setAttAction("Mark Present")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark Present" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark Present</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark LWP")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark LWP" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark LWP</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark LOP")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark LOP" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark LOP</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark Half Day")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark Half Day" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark Half Day</div>
            </div>
        </div>
              </div>

              {AttAction === "Mark Present" ? 
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label>In-Time</label>
              <Input
                value={AttInTime || ""}
                onChange={({ target }) => {
                  setAttInTime(target.value);
                }}
                borderColor={"gray.400"}
                type={"time"}
              />
            </div>
            <div className="space-y-2">
              <label>Out-Time</label>
              <Input
                value={AttOutTime || ""}
                onChange={({ target }) => {
                  setAttOutTime(target.value);
                }}
                borderColor={"gray.400"}
                type={"time"}
              />
            </div>
          </div> 
        : '' }

        <div className="space-y-2">
          <label>Reason</label>
          <Input isRequired value={EditAttRemarks || ''} onChange={({target})=>{setEditAttRemarks(target.value)}} borderColor={'gray.400'} placeholder="Enter Reason" />
        </div>

        <div className="space-y-2">
          <label>Attachment</label>
          <Input type='file' borderColor='gray.400' />
        </div>

            </ModalBody>
            <hr />
            <ModalFooter>
              <Button variant="outline" colorScheme="purple" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button isLoading={IsPostEditFinalAttLoading} loadingText="Saving..." type="submit" colorScheme={"purple"} variant="solid">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


    </div>
  )
}

export default EmpEditAttendance