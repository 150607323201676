import React, { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import authApi from "../Utils/AuthApis";

function ViewSubscription() {
  const userData = JSON.parse(localStorage.getItem("user"));
  
  const [SubscriptionType, setSubscriptionType] = useState('Monthly')
  const [ActiveSubscription, setActiveSubscription] = useState([]);
  const [PlanList, setPlanList] = useState([]);
  useEffect(() => {
    GetUserSubscription();
    GetPlanList();
  }, []);
  
  const GetUserSubscription = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetUserSubscriptionByEmail?EmailId=${userData.userName}`
      );
      // console.log(response);
      setActiveSubscription(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetPlanList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetPlanList`
      );
      // console.log(response);
      setPlanList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="space-y-8 mt-4">

<div className="text-center space-y-3">
  <h2 className="font-bold text-3xl">Its time to upgrade</h2>
  <p className="text-lg">You are currently using <span className='font-semibold text-blue-600'>{ActiveSubscription.planName} Plan</span> - upgrade it at anytime.</p>
</div>

<div className="space-x-4 text-center">
 <Button variant={SubscriptionType === 'Monthly' ? 'solid' : 'outline'} colorScheme="purple" size={'sm'} onClick={()=>{setSubscriptionType('Monthly')}} >Monthly</Button>
 <Button variant={SubscriptionType === 'Annual' ? 'solid' : 'outline'} colorScheme="purple" size={'sm'} onClick={()=>{setSubscriptionType('Annual')}} >Annual</Button>
</div>

<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
  {PlanList.filter(obj => obj.planType === SubscriptionType).map((data, i) => {
          return (
            <div key={i} className="flex flex-col p-6 w-full mx-auto max-w-lg text-center text-gray-900 bg-white rounded-2xl border border-gray-100 shadow-md">
              <h3 className=" text-xl text-gray-600 font-semibold">{data.planName}</h3>
              <div className="flex justify-center items-baseline my-6">
                  <span className="mr-1 text-4xl text-gray-800 font-extrabold">{data.currency === "USD" ? '$' : ''}{data.price}</span>
                  <span className="text-gray-500">{data.planType === "Monthly"
                    ? "/month"
                    : data.planType === "Annual"
                    ? "/year"
                    : ""}</span>
              </div>
              <ul role="list" className="mb-8 space-y-3 text-left">
                  <li className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faCircleCheck} className="flex-shrink-0 w-4 h-4 text-blue-400"/>              
                    <span>Users Upto: <span className="font-semibold">{data.users}</span></span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faCircleCheck} className="flex-shrink-0 w-4 h-4 text-blue-400"/>              
                    <span>Storage Upto: <span className="font-semibold">{data.storage?.toString().replace(/\.?0+$/, '')} GB</span></span>
                  </li>
              </ul>
                <Link to={data.planRefId === "HRM-2023-5" || data.planRefId === "HRM-2023-9"? `/upgradeplan/3/${data.planRefId}` : `/upgradeplan/2/${data.planRefId}`} >
                 <Button disabled={data.planRefId === ActiveSubscription.planRefId ? true : false} width={'full'} colorScheme="purple">Buy Now</Button>
                </Link>        
              </div>
          );
        })}
   </div>
    </div>
  );
}

export default ViewSubscription;
