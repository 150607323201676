export const checkValidData = (action, enteredValue) => {
    
    const validateEmail = (email) => {
      return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.trim());
    };
  
    const validatePassword = (password) => {
      return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password);
    };
  
    switch (action) {
      case 'emails':
        const emailList = enteredValue.split(',');
        for (const email of emailList) {
          if (validateEmail(email)) {
            return true;
          }
        }
        return false;

        case 'email':

            if (validateEmail(enteredValue)) {
              return true;
            }

          return false;
  
      case 'password':
        if (validatePassword(enteredValue)) {
          return true;
        }
        return false;
  
      default:
        throw new Error(`Invalid action: ${action}`);
    }
  };
  