import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import ReactSelect from 'react-select';
import { Box, Button, Avatar } from "@chakra-ui/react";
import { Search } from "react-feather";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";


function ActivityReport() {

    const {userData} = useAuth();


    const [EmpList, setEmpList] = useState([]);

    const [SelectedEmp, setSelectedEmp] = useState([]);
    const [DateRange, setDateRange] = useState([null,null]);
    const [StartDate, EndDate] = DateRange;

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable


    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: "no-border",
      }));
      const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Employee Code",
          field: "empCode",
        },
        {
          headerName: "Employee Name",
          field: "fullName",
        },

        {
          headerName: "Att. Date",
          field: "attendanceDate",
          valueGetter:(params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB')

        },

        {
            headerName: "Att. Time",
            field: "attendanceTime",
          },
          {
            headerName: "Latitude",
            field: "latitude",
          },
          {
            headerName: "Longitude",
            field: "longitude",
          },
          {
            headerName: "Att. Status",
            field: "attendanceStatus",

          },

          {
            headerName: "Image",
            field: "imagePath",
            cellRenderer:(params) => <Avatar size={'sm'}  src={params.data.imagePath}/>
          },
          {
            headerName: "Location",
            field: "officeLocation",

          },
 
    
      ]);
    

    useEffect(()=>{
        getEmployeeList();    
      },[])
    
      const getEmployeeList = async() =>{
        try {
    
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Employee List",response.data);
    
          const res = response.data;
    
          let ActiveEmpList = res.filter((data)=>data.isActive === true);
    
          setEmpList(ActiveEmpList);
          
        } catch (error) {
          console.error(error)
        }
    
      }


      const HandleSearch = async () => {
        try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetActivityReport?Empguid=${SelectedEmp.guId}&Fromdate=${new Date(StartDate).toLocaleDateString('en-CA')}&Todate=${new Date(EndDate).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
      
            console.log("Emp Activity Data List",response.data);
            setRowData(response.data)
          } catch (error) {
            console.error(error)
      
          }
      }
    

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Activity Report</h1>
      </div>

      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end  '>
        <div className='space-y-2 w-1/4'>
            <label>Select Employee</label>
            <ReactSelect
               options={EmpList}
               value={SelectedEmp}
               onChange={(value) => {setSelectedEmp(value); }}
               getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
               getOptionValue={(option) => option.guId}
               placeholder="Select Employee"
              />
        </div>

        <div className="space-y-2">
            <label>Select Date Range</label>
            <DatePicker
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selectsRange={true}
              startDate={StartDate}
              endDate={EndDate}
              onChange={(update) => {
                 setDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Date Range'
            />
        </div>

        <Button colorScheme={'purple'} onClick={()=>{HandleSearch();}}><Search size={'16px'} className="mr-2" />Search</Button>

      </Box>



      <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={12}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  )
}

export default ActivityReport