import React, {useState, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import {  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, Textarea, Center, Spinner } from "@chakra-ui/react";
import { CheckCircle, Edit } from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useAuth } from "../Utils/AuthContext";
import usePost from "../Utils/usePost";
import { EmpProbationValidation } from "../Utils/FormValidations";
import useFetch from "../Utils/useFetch";

function ProbationEmployees() {

    const { userData } = useAuth();

    const { IsFetchLoading: IsEmpProbationListLoading , FetchedData: EmpProbationList, Refetch: RefetchEmpProbationList } = useFetch('/api/EmployeeDetail/GetEmployeelist',{
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    });
    const { IsPostLoading: IsEmpProbationLoading,PostData: PostEmpProbationData} = usePost('/api/EmployeeDetail/SaveEmpProbationInfo');
    const [FormErrors, setFormErrors] = useState({});

    const {
        isOpen: isExtendProbationOpen,
        onOpen: onExtendProbationOpen,
        onClose: onExtendProbationClose
      } = useDisclosure();

    const [ProbationFormData, setProbationFormData] = useState({
      guId: '',
      probationStartDate: new Date(),
      probationEndDate: new Date(),
      probationRemark: '',
      isProbationEnd: false,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
  })

    const [SelectedEmp, setSelectedEmp] = useState([]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "fullName",
        },
        {
            headerName: "Designation",
            field: "designationName",
          },
        // {
        //   headerName: "Department",
        //   field: "departmentName",
        // },
        {
          headerName: "Date of Joining",
          field: "dateofjoin",
          cellRenderer:(params) =><span>
            {moment(params.data.dateofjoin).format('DD/MM/YYYY')}
          </span>
        },
          {
            headerName: "Probation Date",
            field: "probationDate",
            valueGetter:(params) => `${moment(params.data.probationStartDate).format('DD/MM/YYYY')} - ${moment(params.data.probationEndDate).format('DD/MM/YYYY')}`
          },
          {
            headerName: "Action",
            width: 210,
            cellRenderer:(params) =><div className="space-x-3">
                <Button 
                 onClick={async()=>{
                    let body = {
                        guId:params.data.guId,
                        probationStartDate:params.data.probationStartDate,
                        probationEndDate:params.data.probationEndDate,
                        probationRemark:'Confirmed',
                        isProbationEnd:true,
                        companyId:userData.companyId,
                        branchId:userData.isActiveBranch
                    }
    
                    console.log("Confirm Save Body",body);

                    const response = await PostEmpProbationData(body);

                    if(response){
                      RefetchEmpProbationList();
                    }
                 }}
                 leftIcon={<CheckCircle size='14px' />} size={'xs'} colorScheme={'green'}>Confirm</Button>
                <Button onClick={()=>{
                    
                    setSelectedEmp(params.data);
                    setProbationFormData({
                      guId: params.data.guId,
                      probationStartDate: new Date(params.data.probationStartDate),
                      probationEndDate: new Date(params.data.probationEndDate),
                      probationRemark: params.data.probationRemark,
                      isProbationEnd: true,
                      companyId: userData.companyId,
                      branchId: userData.isActiveBranch
                    })
                    onExtendProbationOpen();
                }} variant={'outline'} leftIcon={<Edit size='13px' />} size={'xs'} colorScheme={'orange'}>Extend</Button>
            </div>
          },
      ]);
  
            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border',
              resizable:true
            }));


              const SaveExtendProbation = async (e) => {
                e.preventDefault();

                EmpProbationValidation.validate(ProbationFormData,{abortEarly:false})
                .then(async()=>{

                let body = {
                  guId: SelectedEmp?.guId,
                  probationStartDate: moment(ProbationFormData?.probationStartDate).format('YYYY-MM-DD'),
                  probationEndDate: moment(ProbationFormData?.probationEndDate).format('YYYY-MM-DD'),
                  probationRemark: ProbationFormData?.probationRemark,
                  isProbationEnd: false,
                  companyId: userData?.companyId,
                  branchId: userData?.isActiveBranch
                }

                console.log("Extend Save Body",body);

                const response = await PostEmpProbationData(body);

                if(response){
                  RefetchEmpProbationList();
                  onExtendProbationClose();
                }

              }).catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                  newErrors[error.path] = error.message;
                });
                setFormErrors(newErrors);
              });
                
            }

  return (
    <div>
       <div className="border-b flex justify-between border-slate-400 pb-2 mb-4">
          <h1 className="text-lg font-bold text-gray-800">
            Employees in Probation
          </h1>
        </div>

        {IsEmpProbationListLoading ? <Center mt={10}>
         <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
       </Center> :
        <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={EmpProbationList.filter(data => !data.isProbationEnd)} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>}


      <Modal
          size="3xl"
          isOpen={isExtendProbationOpen}
          onClose={onExtendProbationClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent rounded={"3xl"}>
            <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Extend Probation</ModalHeader>
            <ModalCloseButton mt={1}/>
            <form onSubmit={SaveExtendProbation}>
              <ModalBody>
                <div className="flex py-2 gap-8">
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Employee Name</p>
                    <h6 className="font-medium">{SelectedEmp.fullName}</h6>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Employee Code</p>
                    <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Designation</p>
                    <h6 className="font-medium">
                      {SelectedEmp.designationName}
                    </h6>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Department</p>
                    <h6 className="font-medium">
                      {SelectedEmp.departmentName}
                    </h6>
                  </div>

                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Date of Joining</p>
                    <h6 className="font-medium">
                      {moment(SelectedEmp.dateofjoin).format('DD/MM/yyyy')}
                    </h6>
                  </div>

                </div>
                <hr />

                <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="space-y-2">
                        <label>Probation Start Date</label>
                          <DatePicker 
                             required
                             readOnly
                             className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                             selected={ProbationFormData?.probationStartDate}
                             onChange={(date) => {
                              setProbationFormData({
                                ...ProbationFormData,
                                probationStartDate: date
                              })
                             }}
                             placeholderText="Probation Start Date"
                             dateFormat={'dd-MM-yyyy'}
                           />
                    </div>
                    <div className="space-y-2">
                        <label>Probation End Date</label>
                        <DatePicker
                          required
                           className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                           selected={ProbationFormData?.probationEndDate}
                           onChange={(date) => {
                            setProbationFormData({
                              ...ProbationFormData,
                              probationEndDate: date
                            })
                           }}
                           minDate={new Date(ProbationFormData?.probationStartDate)}
                           placeholderText="Probation End Date"
                           dateFormat={'dd-MM-yyyy'}
                         />
                    </div>

                </div>

                <div className="space-y-2">
                    <label>Reason</label>
                    <Textarea isInvalid={FormErrors?.probationRemark} isRequired value={ProbationFormData?.probationRemark} onChange={({target})=>{
                      setProbationFormData({
                        ...ProbationFormData,
                        probationRemark: target.value
                      })
                    }} borderColor={'gray.400'} placeholder='Enter Reason for Extending Probation' />
                      {FormErrors?.probationRemark && <span className="text-red-600 my-1 text-sm">{FormErrors?.probationRemark}</span>}
                </div>
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button isLoading={IsEmpProbationLoading} loadingText='Saving...' variant="solid" colorScheme="purple" type="submit">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={() => {
                      onExtendProbationClose();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

    </div>
  )
}

export default ProbationEmployees