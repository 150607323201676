import { useEffect, useState } from "react";
import authApi from "./AuthApis";
import { useAuth } from "./AuthContext";

const useFetch = (endpoint, query, shouldFetchOnMount = true) => {
  const { userData, HandleSessionModal } = useAuth();
  const [FetchedData, setFetchedData] = useState([]);
  const [IsFetchLoading, setIsFetchLoading] = useState(false);
  const [FetchError, setFetchError] = useState(null);

  const FetchData = async () => {

    var elapsedTime = 0;

    const startTime = performance.now();
    setIsFetchLoading(true);
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}${endpoint}`,{params: { ...query },}
      );
       console.log("Hook Response",response);
       const endTime = performance.now();
       elapsedTime = (endTime - startTime)/1000;
       setFetchedData(response.data);
    }  catch (err) {
      console.error("Hook Fetch Error",err);
      setFetchError(err?.message);
      SaveErrorLog(endpoint,err?.message,elapsedTime);
      if(err.response){
          if(err.response.statusText === "Unauthorized" || err.response.status === 401){
              // signOut();
              // navigate('/');
              HandleSessionModal(); 
          }
      }
  } finally {
      setIsFetchLoading(false);
    }
  };

  useEffect(() => {
    if(shouldFetchOnMount){
      FetchData();
    }
  }, [shouldFetchOnMount]);

  const Refetch = () => {
    FetchData();
  };


  const SaveErrorLog = async (ApiEndPoint,ErrMsg,ResTime) => {
    // console.log("Split",ApiEndPoint,ResTime);
    let body ={
      controllerName: ApiEndPoint?.split('/')[2],
      actionName: ApiEndPoint?.split('/')[3],
      businessName: "string",
      procedureName: ResTime?.toFixed(2) || '',
      errorLogDescription: `Request: ${ApiEndPoint} Error Msg: ${ErrMsg}`,
      logDate: new Date(),
      companyId: userData?.companyId || 0,
      branchId: userData?.isActiveBranch || 0
    }

    console.log('Error Log Body',body);

    await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/ErrorLog/SaveErrorLog`,body)
    .then((response)=>{
      console.log('Err Log Res',response);
    }).catch((err)=>{
      console.error('Err Log Error',err);
    })
  }


  return { FetchedData, IsFetchLoading, FetchError, Refetch };
};

export default useFetch;
