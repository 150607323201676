import { Button, useDisclosure, Modal, ModalOverlay, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Input, Checkbox, InputGroup, InputRightAddon, Select, Tooltip } from "@chakra-ui/react";
import { faDownload, faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import usePost from "../Utils/usePost";
import useFormValidation from '../Utils/useFormValidation';
import DatePicker from "react-datepicker";
import useFetch from "../Utils/useFetch";
import { Edit, XCircle } from "react-feather";

function AddAsset() {
  const { userData } = useAuth();
  const { FormErrors, ValidateField } = useFormValidation();

  const { FetchedData: InventoryRowData, Refetch: RefetchInventoryData } = useFetch('/api/Assets/GetAssetsMasterList',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });
  const { FetchedData: AssetTypeList, Refetch: RefetchAssetTypeList } = useFetch('/api/Assets/AssetsTypeList',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });
  const { IsPostLoading: IsPostAssetTypeLoading, PostData: PostAssetTypeData } = usePost('/api/Assets/SaveAssetsType');
  const { IsPostLoading: IsPostAddAssetLoading, PostData: PostAddAssetData } = usePost('/api/Assets/SaveAssetsMaster');
  const { IsPostLoading: IsPostImportAssetsLoading, PostData: PostImportAssetsData } = usePost('/api/Assets/ImportAssetsMaster');

  const { isOpen: isAddAssetOpen, onOpen: onAddAssetOpen, onClose: onAddAssetClose } = useDisclosure();
  const { isOpen: isImportAssetOpen, onOpen: onImportAssetOpen, onClose: onImportAssetClose } = useDisclosure();
  const { isOpen: isAddAssetTypeOpen, onOpen: onAddAssetTypeOpen, onClose: onAddAssetTypeClose } = useDisclosure();

  const [AssetFormData, setAssetFormData] = useState({
    assetsMasterId: 0,
    assetsName: '',
    assetsSerialNo: '',
    assetsTypeId: 0,
    assetsPurchaseDate: '',
    assetsWarrentyExpiryDate: '',
    assetsModelNo: '',
    isActive: true,
    isAllocate: false,
    assetsStatus: "Available",
    createdBy: userData.userId,
    modifyBy: userData.userId,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  const [AssetTypeName, setAssetTypeName] = useState();

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    { headerName: "Asset Name", field: "assetsName", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    { headerName: "Serial No.", field: "assetsSerialNo", comparator: (valueA, valueB) => {
      return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
    } },
    { headerName: "Allocated To", field: "empName" },
    { headerName: "Asset Type", field: "assetsTypeName" },
    { headerName: "Model No", field: "assetsModelNo" },
    {
      headerName: "Purchase Date",
      field: "assetsPurchaseDate",
      cellRenderer: ({data}) => moment(data.assetsPurchaseDate).format("DD-MMM-YYYY")
    },
    {
      headerName: "Warrenty Expiry Date",
      field: "assetsWarrentyExpiryDate",
      cellRenderer: ({data}) => moment(data.assetsWarrentyExpiryDate).format("DD-MMM-YYYY")
    },
    {
      headerName: "Asset Status",
      field: "isAllocate",
      cellRenderer: ({data}) => <span className={`${data.isDamage ? 'bg-red-500' : data.isDisposed ? 'bg-red-500' : data.isAllocate ? 'bg-red-500' : 'bg-green-500'} text-white rounded-md px-2 py-0.5 text-sm font-medium`}>
      {data.isDamage ? 'Damaged' : data.isDisposed ? 'Disposed' : data.isAllocate ? 'Not Available' : 'Available'}
    </span>
    },

    { headerName: "Allocated Date", field: "assetsIssueDate", cellRenderer: ({data}) => data?.empName && moment(data?.assetsIssueDate).format('DD-MMM-YYYY') },
    { headerName: "Configuration", field: "assetConfiguration" },
    { headerName: "Remark", field: "assetsRemark" },
    { 
      headerName: "Warranty Status", 
      field: "assetsWarrentyExpiryDate",
      cellRenderer: ({data}) => new Date() < new Date(data.assetsWarrentyExpiryDate) ? (
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          In Warranty
        </span>
      ) : (
        <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          Out of Warranty
        </span>
      )
    },
    { 
      headerName: "Action",
      width: 300,
      cellRenderer: ({data}) => <div className="space-x-4">
        <Button 
        onClick={()=>{
          setAssetFormData({
            ...data,
            assetsPurchaseDate: new Date(data.assetsPurchaseDate),
            assetsWarrentyExpiryDate: new Date(data.assetsWarrentyExpiryDate),
          });
          onAddAssetOpen();
        }} variant={'solid'} leftIcon={<Edit size={'14px'} />} size='xs' colorScheme='blue' >Edit</Button>
      <Button 
        onClick={async () => {
          
          let body ={
            ...data,
            isDamage: true,
            isAllocate: false
          }
          console.log("Damaged Body",body)

          const response = await PostAddAssetData(body);
          if(response){
            RefetchInventoryData();
          }

        }} 
        variant='outline' 
        leftIcon={<XCircle size={'14px'} />} 
        size='xs' 
        colorScheme="red"
      >Damaged</Button>
      <Button 
        onClick={async () => {

          let body = {
            ...data,
            isDisposed: true,
            isAllocate: false
          }
          console.log("Disposed Body",body)

          const response = await PostAddAssetData(body);
          if(response){
            RefetchInventoryData();
          }

        }} 
        variant='outline' 
        leftIcon={<XCircle size={'14px'} />} 
        size='xs' 
        colorScheme="red"
      >Disposed</Button>
      </div>
    }
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    enableCellTextSelection: true,
    resizable: true
  }));

  const [AssetsExcelHeads] = useState([
    { label: "assetsName", key: `assetsName` },
    { label: "assetsTypeName", key: `assetsTypeName` },
    { label: "assetsSerialNo", key: `assetsSerialNo` },
    { label: "assetsModelNo", key: `assetsModelNo` },
    { label: "assetsPurchaseDate", key: `assetsPurchaseDate` },
    { label: "assetsWarrentyExpiryDate", key: `assetsWarrentyExpiryDate` },
    { label: "assetsRemark", key: `assetsRemark` }
  ]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { raw: false });
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      console.log("Imported Data", data);

      let FinalData = data.map((obj) => {
        return {
          ...obj,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        };
      });

      console.log("Final Data", FinalData);
      handleImportAsset(FinalData);
    });
  };


  const handleImportAsset = async (FinalData) => {
    let body = FinalData.map((obj) => {
      return {
        ...obj,
        assetsPurchaseDate: moment(obj.assetsPurchaseDate).format("YYYY-MM-DD"),
        assetsWarrentyExpiryDate: moment(obj.assetsWarrentyExpiryDate).format("YYYY-MM-DD"),
      };
    });

    console.log("Import Asset Body", body);

    await PostImportAssetsData(body);
  };

  const HandleAddAsset = async (e) => {
    console.log("Asset Form Data",AssetFormData);
    e.preventDefault();
    let body = {
      ...AssetFormData,
      assetsPurchaseDate: moment(AssetFormData?.assetsPurchaseDate).format('YYYY-MM-DD'),
      assetsWarrentyExpiryDate: moment(AssetFormData?.assetsWarrentyExpiryDate).format('YYYY-MM-DD'),
    };
    console.log("Add Asset Body", body);

    const response = await PostAddAssetData(body);

    if(response){
      onAddAssetClose();
      RefetchInventoryData();
      setAssetFormData({
        assetsMasterId: 0,
        assetsName: '',
        assetsSerialNo: '',
        assetsTypeId: 0,
        assetsPurchaseDate: '',
        assetsWarrentyExpiryDate: '',
        assetsModelNo: '',
        isActive: true,
        isAllocate: false,
        assetsStatus: "Available",
        createdBy: userData.userId,
        modifyBy: userData.userId,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      });
    }

  };

  const handleAddAssetType = async (e) => {
    e.preventDefault();

    let body = {
      assetsTypeName: AssetTypeName,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    
    console.log("Add Asset Type",body);

    const response = await PostAssetTypeData(body);

    console.log("Asset Type Response",response);

    if(response){
      RefetchAssetTypeList();
      onAddAssetTypeClose();
      setAssetTypeName();
    }

  };


  const exportData = () => {
    const params = {
      fileName: `Asset List of ${userData?.companyName}.csv`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  };


  return (
    <div>
      <div className="flex items-center justify-between border-b border-gray-400 mb-4 pb-3 text-left">
        <h1 className="text-gray-700 text-xl font-bold">Assets</h1>
        <div className="space-x-4">
          <Button leftIcon={<FontAwesomeIcon icon={faPlus} />} size={"sm"} onClick={onAddAssetOpen} colorScheme="purple">
            Add Asset
          </Button>
          <Button leftIcon={<FontAwesomeIcon icon={faFileImport} />} size={"sm"} onClick={onImportAssetOpen} colorScheme="purple">
            Import Assets
          </Button>
          <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} size={"sm"} onClick={exportData} colorScheme="purple">
            Export
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={InventoryRowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          rowSelection="single"
          enableCellTextSelection={true}
        />
      </div>

      <Modal
        size={"2xl"}
        isOpen={isImportAssetOpen}
        onClose={onImportAssetClose}
      >
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
        <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Import Assets</ModalHeader>
        <ModalCloseButton mt={1.5}/>
            <ModalBody>
              <div className="mt-4">
                <CSVLink
                  data={AssetsExcelHeads}
                  headers={AssetsExcelHeads}
                  filename={"Assets Data.csv"}
                >
                  <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} size="sm" colorScheme="purple">
                    Download Sample Excel
                  </Button>
                </CSVLink>
              </div>
              <div className="flex justify-center items-center w-full my-10">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600"
                >
                  <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="mb-3 w-10 h-10 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      XLSV, CSV
                    </p>
                  </div>
                  <input
                    onChange={(e) => {
                      const file = e.target.files[0];
                      readExcel(file);
                    }}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </label>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={onImportAssetClose}
                colorScheme="purple"
                variant={"outline"}
              >
                Cancel
              </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size="2xl" isOpen={isAddAssetOpen} onClose={onAddAssetClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
        <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Add Asset</ModalHeader>
        <ModalCloseButton mt={1.5}/>
          <form onSubmit={HandleAddAsset}>
            <ModalBody className="my-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label>Asset Name<span className="text-red-600">*</span></label>
                  <Input 
                    isInvalid={FormErrors?.assetName}
                    isRequired
                    value={AssetFormData?.assetsName || ''}
                    onChange={({target}) => {
                      ValidateField('AlphanumericHyphens','assetName',target.value);
                      setAssetFormData({
                        ...AssetFormData,
                        assetsName: target.value
                      })
                    }}
                    type="text"
                    borderColor="gray.400"
                    placeholder="Asset Name"
                  />
                  {FormErrors?.assetName && <span className="text-sm text-red-600" >{FormErrors?.assetName}</span>}
                </div>

                <div className="space-y-2">
                  <label>Asset Serial No.<span className="text-red-600">*</span></label>
                  <Input 
                    isInvalid={FormErrors?.assetsSerialNo}
                    isRequired
                    value={AssetFormData?.assetsSerialNo || ''}
                    onChange={({target}) => {
                      ValidateField('AlphanumericHyphens','assetsSerialNo',target.value);
                      setAssetFormData({
                        ...AssetFormData,
                        assetsSerialNo: target.value
                      })
                    }}
                    type="text"
                    borderColor="gray.400"
                    placeholder="Asset Serial No."
                  />
                  {FormErrors?.assetsSerialNo && <span className="text-sm text-red-600" >{FormErrors?.assetsSerialNo}</span>}
                </div>

                <div className="space-y-2">
                  <label>Asset Model No.<span className="text-red-600">*</span></label>
                  <Input 
                    isInvalid={FormErrors?.assetsModelNo}  
                    isRequired
                    value={AssetFormData?.assetsModelNo || ''}
                    onChange={({target}) => {
                      ValidateField('AlphanumericHyphens','assetsModelNo',target.value);
                      setAssetFormData({
                        ...AssetFormData,
                        assetsModelNo: target.value
                      })
                    }}
                    borderColor="gray.400"
                    placeholder="Asset Model No."
                  />
                  {FormErrors?.assetsModelNo && <span className="text-sm text-red-600" >{FormErrors?.assetsModelNo}</span>}
                </div>

                <div className="space-y-2">
                  <label>Asset Type<span className="text-red-600">*</span></label>
                  <InputGroup>
                    <Select
                      value={AssetFormData?.assetsTypeId}
                      isRequired
                      onChange={({target}) => {
                        setAssetFormData({
                          ...AssetFormData,
                          assetsTypeId: target.value
                        });
                      }}
                      borderColor="gray.400"
                      placeholder="Select Asset Type"
                    >
                      {AssetTypeList.map((data, i) => (
                        <option key={i} value={data.assetsTypeId}>
                          {data.assetsTypeName}
                        </option>
                      ))}
                    </Select>
                    <Tooltip
                      rounded="5px"
                      hasArrow
                      label="Add Asset Type"
                      bg={"black"}
                    >
                      <InputRightAddon
                        onClick={onAddAssetTypeOpen}
                        className="cursor-pointer"
                        bg="purple.400"
                        children={
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faPlus}
                          />
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </div>

                <div className="space-y-2">
                  <label>Purchased Date<span className="text-red-600">*</span></label>
                  <DatePicker
                    required
                    className={`${FormErrors?.assetsPurchaseDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                    selected={AssetFormData?.assetsPurchaseDate}
                    onChange={(date) => setAssetFormData({...AssetFormData, assetsPurchaseDate: date})}
                    placeholderText="Purchase Date"
                    dateFormat={'dd-MM-yyyy'}
                  />
                  {FormErrors?.assetsPurchaseDate && <span className="text-red-600 my-1 text-sm">{FormErrors?.assetsPurchaseDate}</span>}
                </div>

                <div className="space-y-2">
                  <label>Warranty Expiry Date<span className="text-red-600">*</span></label>
                  <DatePicker
                    required
                    className={`${FormErrors?.assetsWarrentyExpiryDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                    selected={AssetFormData?.assetsWarrentyExpiryDate}
                    onChange={(date) => setAssetFormData({...AssetFormData, assetsWarrentyExpiryDate: date})}
                    min
                    placeholderText="Warranty Expiry Date"
                    dateFormat={'dd-MM-yyyy'}
                  />
                  {FormErrors?.assetsWarrentyExpiryDate && <span className="text-red-600 my-1 text-sm">{FormErrors?.assetsWarrentyExpiryDate}</span>}
                </div>
              </div>

            </ModalBody>
            <hr />
            <ModalFooter>
              <Button isDisabled={Object.keys(FormErrors).length > 0} isLoading={IsPostAddAssetLoading} loadingText="Saving..." type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={onAddAssetClose}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddAssetTypeOpen} onClose={onAddAssetTypeClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
        <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Add Asset Type</ModalHeader>
        <ModalCloseButton mt={1.5}/>
          <form onSubmit={handleAddAssetType}>
            <ModalBody>
              <div className="space-y-2">
                <label>Asset Type Name</label>
                <Input
                  isRequired
                  isInvalid={FormErrors?.assetsTypeName}
                  value={AssetTypeName}
                  onChange={({target}) => {
                    ValidateField('Alphanumeric','assetsTypeName',target.value);
                    setAssetTypeName(target.value);
                  }}
                  type="text"
                  borderColor="gray.400"
                  placeholder="Asset Type Name"
                />
                  {FormErrors?.assetsTypeName && <span className="text-sm text-red-600" >{FormErrors?.assetsTypeName}</span>}
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button isDisabled={Object.keys(FormErrors).length > 0} isLoading={IsPostAssetTypeLoading} loadingText="Saving..." type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AddAsset;
