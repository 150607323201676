import React, { useMemo, useState, useCallback, useRef } from "react";
import {useAuth} from '../Utils/AuthContext';
import useFetch from '../Utils/useFetch';
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import { Button, useDisclosure, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Modal, Input } from '@chakra-ui/react';
import { CheckCircle, Download, XCircle } from 'react-feather';
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { LuEdit, LuPlus, LuSearch, LuUpload } from 'react-icons/lu';
import { AgGridReact } from "ag-grid-react";
import usePost from "../Utils/usePost";
import NoRecordFound from "../UI Components/NoRecordFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";
import authApi from "../Utils/AuthApis";

const ImportEmpOvertime = () => {
  const {userData} = useAuth();
  const { IsFetchLoading: IsEmpListLoading, FetchedData: EmpListData, Refetch: RefetchEmpList } = useFetch('/api/EmployeeDetail/GetEmployeelist',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });
  const { IsPostLoading: IsPostImportDataLoading, PostData: PostImportData } = usePost('/api/Overtime/SaveImportOverTimeFinal');
  const { IsPostLoading: IsPostOTFinalDataLoading, PostData: PostOTFinalData } = usePost('/api/Overtime/SaveOverTimeFinalData');
  const { isOpen: isImportDataOpen, onOpen: onImportDataOpen, onClose: onImportDataClose } = useDisclosure();

  const { isOpen: isOttDataOpen, onOpen: onOttDataOpen, onClose: onOttDataClose } = useDisclosure();

  const calculateHours = (timeString) => {
    const [hoursStr, minutesStr, secondsStr] = timeString.split(':');
    const totalHours = parseInt(hoursStr) + (parseInt(minutesStr) / 60) + (parseInt(secondsStr) / 3600);
    return totalHours
  };

  const [SelectedDates, setSelectedDates] = useState([]);

  const [SingleOttData, setSingleOttData] = useState({
    empName: '',
    empGuid: '',
    empCode: '',
    overtimeDate: '',
    otHrs: '',
    IsStatus: 0,
    IsLock: false,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  });


  const gridRef = useRef();
  const [SelectedEmp, setSelectedEmp] = useState([]);
  const [DataImportMonth, setDataImportMonth] = useState(new Date());
  const [ImportedData, setImportedData] = useState([]);
  const [SampleExcelHeads] = useState([
    { label: "empGuid", key: `empGuid`},
    { label: "empName", key: `empName`},
    { label: "empCode", key: `empCode`},
    { label: "overtimeDate", key: `overtimeDate`},
    { label: "otHrs", key: `otHrs`}
  ]);
  const [SampleExcelData, setSampleExcelData] = useState([]);
  const [EmpOvertimeData, setEmpOvertimeData] = useState(null);
  const [IsEmpOttDataLoading, setIsEmpOttDataLoading] = useState(false)
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [ ImportedDataColDef ] = useState([
    { headerName: "Employee Code", field: "empCode" },
    { headerName: "Employee Name", field: "empName" },
    { headerName: "Attendance Date", field: "overtimeDate" },
    { headerName: "Overtime Hours", field: "otHrs",
    //  singleClickEdit: true, editable: true, cellClass: "editable-grid-cell" 
    }
  ])

  const onSelectionChanged = useCallback(() => {
    var SelectedEmployees = gridRef.current.api.getSelectedRows();
    console.log("Selected Dates", SelectedEmployees);
    setSelectedDates(SelectedEmployees);
  }, []);

  function convertTimeToHours(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      throw new Error('Invalid time format. Please use HH:mm:ss');
    }
  
    const totalHours = hours + minutes / 60 + seconds / 3600;
    return totalHours;
  }


  const isDateFormatValid = (dateString) => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormatRegex.test(dateString);
  };
  
  const hasInvalidDateFormat = (arrayOfObjects) => {
    return arrayOfObjects.some((obj) => {
      return obj.date && !isDateFormatValid(obj.date);
    });
  };
  

  const [EmpOTColumnDefs] = useState([
    {
      headerName: "Emp Name",
      field: "empName",
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      headerName: "Attendance Date",
      field: "overtimeDatefrom",
      cellRenderer: ({data}) => moment(data.overtimeDatefrom).format('DD-MMM-YYYY') 
    },
    {
      headerName: "Final Overtime",
      field: "finalOvertimeTime",
      valueGetter:({data}) => ConvertToHoursMinutes(data.finalOvertimeTime)
    },
    {
      headerName: "Remark",
      field: "remark",
    },
    {
      headerName: "Status",
      field: "isStatus",
      cellRenderer: ({data}) =>
        data.isStatus === 0 ? (
          <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Pending
          </span>
        ) : data.isStatus === 1 ? (
          <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Approved
          </span>
        ) : data.isStatus === 2 ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Rejected
          </span>
        ) : data.isStatus === 3 ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Cancelled
          </span>
        ) : (
          ""
        ),
    },
    {
      headerName: "Final Review",
      field: "isLock",
      cellRenderer: (params) =>
        params.data.isLock === false ? (
          <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Review Pending
          </span>
        ) : (
          <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Review Done
          </span>
        ),
    },
    {
      headerName: "Action",
      field: "Status",
      width: 300,
      cellRenderer: (params) =>
        params.data.isLock === false ? (
          <div>
            {params.data.isStatus === 0 ? (
              <div className="space-x-4">    
                <Button onClick={()=>{
                  setSingleOttData({
                    empName: params.data.empName,
                    empGuid: params.data.empGuid,
                    empCode: params.data.empCode,
                    overtimeDate: new Date(params.data?.dateFrom),
                    otHrs: calculateHours(params.data?.finalOvertimeTime),
                    IsStatus: 0,
                    IsLock: false,
                    companyId: userData.companyId,
                    branchId: userData.isActiveBranch
                  });
                  onOttDataOpen();
                }} leftIcon={<LuEdit size={'14px'} />} colorScheme="blue" size={'xs'}>Edit</Button>
                <Button
                  onClick={async () => {
                    let OvertimeData = params.data;

                    let body = [
                      {
                        empName: OvertimeData.empName,
                        empGuid: OvertimeData.empGuid,
                        empCode: OvertimeData.empCode,
                        overtimeDate: OvertimeData.dateFrom,
                        otHrs: convertTimeToHours(OvertimeData.finalOvertimeTime),
                        IsStatus: 1,
                        IsLock: true,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch
                      }
                    ]


                    console.log("Approved Body", body);
                    
                    const result = await PostImportData(body);
                    
                    if(result){
                      GetEmpOvertimeData(OvertimeData.empGuid,OvertimeData.month,OvertimeData.year);
                    }
                  }}
                  size={"xs"}
                  colorScheme={"green"}
                  leftIcon={<CheckCircle size="14px" />}
                >
                  Approve
                </Button>
                <Button
                  onClick={async () => {
                    let OvertimeData = params.data;

                    let body = [
                      {
                        empName: OvertimeData.empName,
                        empGuid: OvertimeData.empGuid,
                        empCode: OvertimeData.empCode,
                        overtimeDate: OvertimeData.dateFrom,
                        otHrs: convertTimeToHours(OvertimeData.finalOvertimeTime),
                        IsStatus: 2,
                        IsLock: true,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch
                      }
                    ]

                    console.log("Rejected Body", body);

                    const result = await PostImportData(body);

                    if(result){
                      GetEmpOvertimeData(OvertimeData.empGuid,OvertimeData.month,OvertimeData.year);
                    }

                  }}
                  size={"xs"}
                  colorScheme={"red"}
                  leftIcon={<XCircle size="14px" />}
                >
                  Reject
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        ),
    },

  ])


  const ConvertToHoursMinutes = (time) => {
    const date = new Date("1970-01-01T" + time + "Z");
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return hours + " hours " + minutes + " minutes"
  }


  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable: true
  }));


  function getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push({
        empName: SelectedEmp?.fullName,
        empGuid: SelectedEmp?.guId,
        empCode: SelectedEmp?.empCode,
        overtimeDate: moment(date).format('YYYY-MM-DD'),
        otHrs: 0,
        companyId: userData?.companyId,
        branchId: userData?.isActiveBranch
      });
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  // console.log("Excel data", SampleExcelData);

  const HandleImportData = async () => {
    // console.log("Data Import Month", DataImportMonth, new Date(DataImportMonth).getFullYear(), new Date(DataImportMonth).getMonth());
    setSampleExcelData(getDaysInMonth(new Date(DataImportMonth).getMonth() ,new Date(DataImportMonth).getFullYear()));
    onImportDataOpen();
  }


  const readExcel = (file)=>{

    const promise = new Promise((resolve,reject) => {
      
     const fileReader = new FileReader();
     fileReader.readAsArrayBuffer(file);
 
     fileReader.onload = (e) => {
       const bufferArray = e.target.result;
 
       const wb = XLSX.read(bufferArray,{type:'buffer'});
       const wsname = wb.SheetNames[0];
 
       const ws = wb.Sheets[wsname];
 
       const data = XLSX.utils.sheet_to_json(ws, {raw: false});
 
       resolve(data)
     } 
 
     fileReader.onerror = (error) => {
       reject(error)
     }
 
    });
 
    promise.then((data)=>{
      console.log("Imported Data",data);

      let FinalData = data.map((obj)=>{
        return {...obj,
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
      })

      console.log("Final Data",FinalData);
      SaveImportedData(FinalData);
    });
 
 }

 const SaveImportedData = async (FinalData) =>{

  const Validate = FinalData.every((obj)=>{
    console.log(obj.overtimeDate,moment(obj.overtimeDate, 'YYYY-MM-DD').isValid());
    return obj.overtimeDate && moment(obj.overtimeDate, 'YYYY-MM-DD').isValid()
  })

  console.log("Validate",Validate);

  let body = FinalData?.filter(filData => Number(filData?.otHrs || 0) !== 0)?.map(data => {
    return { 
      ...data,
      overtimeDate: moment(data.overtimeDate).format('YYYY-MM-DD')
    }
  });

  setImportedData(body);

  console.log("Import Body",body);
   await PostImportData(body);
 }
 

 const GetEmpOvertimeData = async (EMPGUID,DataMonth,DataYear) => {
    setIsEmpOttDataLoading(true);
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetEmpOvertimeByGuid?EmpGuid=${EMPGUID}&CompanyId=${userData?.companyId}&BranchId=${userData?.isActiveBranch}&OTMonth=${DataMonth}&OTYear=${DataYear}`
      )
      console.log("Emp Overtime Data",response);
      setEmpOvertimeData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsEmpOttDataLoading(false);
    }
 }

 const calculateTotalHours = (overtimeData) => {
  let totalHours = 0;

  overtimeData?.filter(data => data.isStatus === 1)?.forEach(record => {
    const finalOvertimeHours = convertTimeToHours(record?.finalOvertimeTime);
    totalHours += finalOvertimeHours;
  });

  return totalHours;
};

  return (
    <div>
      <div className='bg-white p-4 flex gap-6 items-end rounded-2xl drop-shadow-md mb-6'>
        <div className='space-y-2 w-1/5'>
          <label>Select Employee</label>
          <ReactSelect
           className="whitespace-nowrap"
           menuPortalTarget={document.body} 
           styles={{
             menuPortal: provided => ({ ...provided, zIndex: 9999 }),
             menu: provided => ({ ...provided, zIndex: 9999 })
           }}
           options={EmpListData}
           value={SelectedEmp}
           onChange={(value) => {setSelectedEmp(value);}}
           getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
           getOptionValue={(option) => option.guId}
           placeholder="Select Employee"
          />    
        </div>

        <div className='space-y-2'>
          <label>Select Month</label>
          <DatePicker
            portalId="root-portal"
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
            selected={DataImportMonth}
            onChange={(date) => setDataImportMonth(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            placeholderText="Import Data Month"
            maxDate={new Date()}
            required
          />
        </div>
        <Button isLoading={IsEmpOttDataLoading} loadingText="Searching..."  isDisabled={SelectedEmp?.length === 0} onClick={()=>{GetEmpOvertimeData(SelectedEmp?.guId,new Date(DataImportMonth).getMonth()+1,new Date(DataImportMonth).getFullYear())}} leftIcon={<LuSearch/>} colorScheme='purple'>Search</Button>
        <Button isDisabled={SelectedEmp?.length === 0} onClick={()=>{HandleImportData()}} leftIcon={<LuUpload/>} colorScheme='purple'>Import Data</Button>
        <Button 
          isDisabled={SelectedEmp?.length === 0} 
          leftIcon={<LuPlus size={'22px'} />}
          colorScheme="purple"
          onClick={()=>{
          setSingleOttData({
            ...SingleOttData,
            empName: SelectedEmp?.fullName,
            empGuid: SelectedEmp?.guId,
            empCode: SelectedEmp?.empCode
          })
          onOttDataOpen();
        }} >Add Overtime</Button>
      </div>

      {!IsEmpOttDataLoading && EmpOvertimeData?.length === 0 && <NoRecordFound/> }
      {!IsEmpOttDataLoading && EmpOvertimeData?.length > 0 &&  <>

         <div className="flex justify-between items-center">
         <div className="px-5 flex py-3 space-x-5 ">
              <div className="my-auto">
                <FontAwesomeIcon className="rotate-180 text-lg" icon={faArrowTurnUp} />
              </div>
              <Button isDisabled={SelectedDates.length === 0} 
                onClick={ async() => {
                  let body = SelectedDates.map((data)=>{
                    return {
                      empName: data.empName,
                      empGuid: data.empGuid,
                      empCode: data.empCode,
                      overtimeDate: data.dateFrom,
                      otHrs: convertTimeToHours(data.finalOvertimeTime),
                      IsStatus: 1,
                      IsLock: true,
                      companyId: userData.companyId,
                      branchId: userData.isActiveBranch
                    }
                  })

                  console.log("Approved Body", body);

                  const result = await PostImportData(body);
                    
                  if(result){
                    GetEmpOvertimeData(body[0]?.empGuid,new Date(body[0]?.overtimeDate).getMonth()+1,new Date(body[0]?.overtimeDate).getFullYear());
                  }

                }}  size={"sm"}
                colorScheme={"green"}
                leftIcon={<CheckCircle size="14px" />}>
                Approve
              </Button>
              <Button isDisabled={SelectedDates.length === 0}                 onClick={ async() => {
                  let body = SelectedDates.map((data) => {
                    return {
                      empName: data.empName,
                      empGuid: data.empGuid,
                      empCode: data.empCode,
                      overtimeDate: data.dateFrom,
                      otHrs: convertTimeToHours(data.finalOvertimeTime),
                      IsStatus: 2,
                      IsLock: true,
                      companyId: userData.companyId,
                      branchId: userData.isActiveBranch
                    }
                  })

                  console.log("Rejected Body", body);

                  const result = await PostImportData(body);
                    
                  if(result){
                    GetEmpOvertimeData(body[0]?.empGuid,new Date(body[0]?.overtimeDate).getMonth()+1,new Date(body[0]?.overtimeDate).getFullYear());
                  }

                }} size={"sm"}
                colorScheme={"red"}
                leftIcon={<XCircle size="14px" />}
                >
                Reject
              </Button>
            </div>

            <div className="bg-white rounded-lg px-2 py-1">
              <h6 className=" text-gray-700">Approved Overtime: <span className="font-semibold">{calculateTotalHours(EmpOvertimeData)} Hours</span></h6>
            </div>
         </div>


        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            ref={gridRef}
            domLayout={"autoHeight"}
            rowData={EmpOvertimeData} // Row Data for Rows
            columnDefs={EmpOTColumnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            pagination={true}
            paginationPageSize={31}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            suppressExcelExport={true}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </> } 

      <Modal
        size="full"
        isOpen={isImportDataOpen}
        onClose={() => {
          setImportedData([]);
          onImportDataClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Overtime Data</ModalHeader>
          <ModalCloseButton/>
          <hr />
          <ModalBody>
            <div className="mt-4">
              <CSVLink data={SampleExcelData} headers={SampleExcelHeads} filename={`${SelectedEmp?.fullName} ${moment(DataImportMonth).format('MMM-YYYY')} Overtime Data.csv`}>
                <Button size='sm' leftIcon={<Download size={'16px'}/>} colorScheme='purple'>Download Excel for Overtime</Button>
              </CSVLink>
            </div>
            <div className="flex justify-center items-center w-full my-10">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600"
              >
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    XLSV, CSV
                  </p>
                </div>
                <input
                  onChange={(e) => {
                    const file = e.target.files[0];
                     readExcel(file);
                  }}
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                rowData={ImportedData} // Row Data for Rows
                columnDefs={ImportedDataColDef} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection="multiple" // Options - allows click selection of rows
                pagination={true}
                paginationPageSize={31}
                paginationNumberFormatter={paginationNumberFormatter}
                cacheQuickFilter={true}
                suppressExcelExport={true}
              />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <div className="space-x-4">
              <Button
                variant="outline"
                colorScheme="purple"
                onClick={() => {
                  setImportedData([]);
                  onImportDataClose();
                }}
              >
                Close
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOttDataOpen} onClose={()=>{
         onOttDataClose();
        }}>
        <ModalOverlay />
        <ModalContent  rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold">Add Overtime</ModalHeader>
          <ModalCloseButton mt={1} />
          <hr />
          <form onSubmit={ async (e)=>{
            e.preventDefault();
              let body = [
                {
                  empName: SingleOttData.empName,
                  empGuid: SingleOttData.empGuid,
                  empCode: SingleOttData.empCode,
                  overtimeDate: moment(SingleOttData?.overtimeDate).format('YYYY-MM-DD'),
                  otHrs: SingleOttData?.otHrs,
                  IsStatus: 0,
                  IsLock: false,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch
                }
              ]

              console.log("Single Ott Body",body);

              const result = await PostImportData(body);

              if(result){
                GetEmpOvertimeData(SelectedEmp?.guId,new Date(DataImportMonth).getMonth()+1,new Date(DataImportMonth).getFullYear());
                onOttDataClose();
                setSingleOttData({
                  empName: '',
                  empGuid: '',
                  empCode: '',
                  overtimeDate: '',
                  otHrs: '',
                  IsStatus: 0,
                  IsLock: false,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch
                });

              }
          }}>
          <ModalBody className="space-y-4">

          <div className="flex gap-6 border-b pb-2 border-gray-400">
                <div className="space-y-1">
                  <label className="text-sm text-gray-500">Employee Name</label>
                  <h6 className="font-medium">{SingleOttData.empName}</h6>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Employee Code</label>
                  <h6 className="font-medium">{SingleOttData.empCode}</h6>
                </div>
              </div>


            <div className="space-y-2">
              <label>Overtime Date</label>
              <DatePicker
                portalId="root-portal"
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
                selected={SingleOttData?.overtimeDate}
                onChange={(date) => setSingleOttData({
                  ...SingleOttData,
                  overtimeDate: date
                })}
                dateFormat="dd-MM-yyyy"
                placeholderText="Overtime Date"
                maxDate={new Date()}
                required
              />
            </div>
            <div>
              <label>Overtime Hours</label>
              <Input value={SingleOttData?.otHrs} onChange={({target})=>{
                setSingleOttData({
                  ...SingleOttData,
                  otHrs: target.value
                })
              }} type="number" borderColor={'gray.400'} placeholder="Overtime Hours" />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button  type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={()=>{
              onOttDataClose();
              setSingleOttData({
                empName: '',
                empGuid: '',
                empCode: '',
                overtimeDate: '',
                otHrs: '',
                IsStatus: 0,
                IsLock: false,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch
              });
            }} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      
    </div>
  )
}

export default ImportEmpOvertime