import React, {useEffect, useState} from "react";
import { Input, InputGroup, InputRightElement, InputLeftElement, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, PinInput, PinInputField, HStack, Checkbox, } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Eye, EyeOff,Lock, User } from "react-feather";
import QRScanner from "./OtherComponents/QRScanner";
import usePost from "./Utils/usePost";
import { useAuth } from "./Utils/AuthContext"
import useFormValidation from "./Utils/useFormValidation";

const Login = () =>  {

  const { isLoggedIn, signIn } = useAuth();
  const navigate = useNavigate();
  const { FormErrors, ValidateField } = useFormValidation();


  const [show, setShow] = React.useState(false);
  const [IsLoginLoading, setIsLoginLoading] = useState(false)

  const { IsPostLoading : IsResetPasswordLoading, PostData: PostResetPasswordData } = usePost(`/api/Account/HardResetPassword`);
  
  const [ResetPasswordEmp, setResetPasswordEmp] = useState([]);

  const { 
    isOpen : isGetEmailAddOpen, 
    onOpen : onGetEmailAddOpen, 
    onClose : onGetEmailAddClose
  } = useDisclosure();

  const { 
    isOpen : isVerifyOtpOpen, 
    onOpen : onVerifyOtpOpen, 
    onClose : onVerifyOtpClose
  } = useDisclosure();

  const { 
    isOpen : isResetPasswordOpen, 
    onOpen : onResetPasswordOpen, 
    onClose : onResetPasswordClose
  } = useDisclosure();

  const [ResetNewPassword, setResetNewPassword] = useState();
  const [ConfirmResetPassword, setConfirmResetPassword] = useState();
  const [ComparePassword, setComparePassword] = useState(true);
  const [OTPInput, setOTPInput] = useState();

  // console.log('OTP Input',OTPInput);
  const [EmailVerifyMessage, setEmailVerifyMessage] = useState(null);
  const [VerifyEmailAdd, setVerifyEmailAdd] = useState();


  useEffect(()=>{
    if(isLoggedIn){
      navigate('/dashboard');
    }
  },[]);
  
  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';

    if (userAgent.indexOf('Firefox') > -1) {
      browserName = 'Mozilla Firefox';
    } else if (userAgent.indexOf('SamsungBrowser') > -1) {
      browserName = 'Samsung Internet';
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      browserName = 'Opera';
    } else if (userAgent.indexOf('Trident') > -1) {
      browserName = 'Microsoft Internet Explorer';
    } else if (userAgent.indexOf('Edge') > -1) {
      browserName = 'Microsoft Edge';
    } else if (userAgent.indexOf('Chrome') > -1) {
      browserName = 'Google Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      browserName = 'Apple Safari';
    }

    return browserName;
  };

  // console.log("Browser NAme",getBrowserInfo());
  // Sign In Variables
  const [signemail, setSignEmail] = useState('');
  const [signpassword,setSignPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoginLoading(true);
  
    let userIpAddress = '192.168.1.1'; // Default IP address in case of failure
  
    try {
      const ipResponse = await axios.get('https://api.ipify.org/?format=json');
      // const ipResponse = await axios.get('https://ifconfig.me/all.json');
      userIpAddress = ipResponse?.data?.ip || userIpAddress;
      console.log("IP Response:", ipResponse);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  
    const userAgent = getBrowserInfo() || "Other";
  
    const body = {
      userName: signemail,
      password: signpassword,
      ipAddress: userIpAddress,
      userAgent: userAgent
    };
  
    try {
      const response = await signIn(body);
      console.log("Login Response:", response);
    } catch (error) {
      console.error('Error during sign in:', error);
    } finally {
      setIsLoginLoading(false);
    }
  };
  


  const HandleVerifyEmailAddress = async (e) => {
    e.preventDefault();
    try {
      const response =  await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/ForgotPassword?UserName=${VerifyEmailAdd}`
      )
      //  console.log("Verify Email Response",response);
      if(response.data){
        onVerifyOtpOpen();
        onGetEmailAddClose();
      }else{
        setEmailVerifyMessage('Incorrect Email Entered!')
      }
    } catch (error) {
      console.error(error);
    }
  }


  
  const HandleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response =  await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyOTP?UserName=${VerifyEmailAdd}&OTP=${OTPInput}`
      )
      //  console.log("Verify OTP Response",response);

      const res = response.data;
      if(res.errorMessage === "true"){
        setResetPasswordEmp(res);
        onVerifyOtpClose();
        onResetPasswordOpen();
      }else{
        setEmailVerifyMessage('Incorrect OTP Entered');
      }
    } catch (err) {
      console.error(err);
    }
  }


  const HandleResetPassword = async (e) => {

    e.preventDefault();

    if(ComparePassword){   

    let body = {
        empGuId: ResetPasswordEmp.empGuId,
        userName: ResetPasswordEmp.userName,
        password: ResetNewPassword,
        confirmPassword: ConfirmResetPassword,
        companyId: ResetPasswordEmp.companyId,
        branchId: ResetPasswordEmp.branchId,
      }

      // console.log("Reset Password Body",body);

      const response = await PostResetPasswordData(body);

      if(response){
        setResetNewPassword('');
        setConfirmResetPassword('');
        onResetPasswordClose();
      }
      
    }

  }

  console.log("Form ERrors",FormErrors);
  return (
    <>

    <div className="bg-gradient-to-br from-pink-300 via-purple-300 to-indigo-400 h-full py-16 md:pb-0 md:h-screen flex">
      <div className="container px-5 py-14 md:py-24 mx-auto my-auto flex flex-wrap items-center ">
        <div className="hidden md:block w-full lg:w-3/5 md:w-1/2  space-y-3">
            <div>
              <img
                src="./HRMusterLogo.png"
                className="h-28"
                alt="HR Muster Logo"
              />
            </div>
            <p className="font-bold text-2xl lg:text-5xl text-gray-700">
              Streamline
            </p>
            <p className="font-bold text-2xl lg:text-5xl text-gray-700">
              Your Workforce,
            </p>
            <p className="font-bold text-2xl lg:text-5xl text-gray-700">
              Simplify Your HR -
            </p>
            <p className="font-bold text-2xl lg:text-5xl text-gray-700">
             with HR Muster Software!!
            </p>
        </div>

        <div className="relative lg:w-[30%] md:w-1/2 w-full">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-12 sm:skew-y-0 sm:-rotate-[8deg] rounded-3xl drop-shadow-md"></div>

          <form onSubmit={handleSubmit} className="bg-gray-50 rounded-2xl drop-shadow-md p-8 flex flex-col md:ml-auto  mt-10 md:mt-0 space-y-2">
            <div className="flex items-center gap-4 border-b pb-3 border-gray-300">
              <img
                src="./HRMusterLogo.png"
                className="h-14"
                alt="HR Muster Logo"
              />
            <h2 className="text-gray-800 text-3xl font-bold ">
              HR Muster
            </h2>  
            </div>
            <h2 className="text-gray-800 text-2xl font-medium ">
              Login To Your Account
            </h2>
            <div className="space-y-2">
              <label>Email Address</label>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <User size={"17px"} className="text-gray-500" />
                </InputLeftElement>
                <Input
                  isRequired
                  value={signemail || ''}
                  onChange={({target})=>{
                    setSignEmail(target.value);
                  }}
                  type='email'
                  borderColor="gray"
                  placeholder="Enter Email Address"
                />
              </InputGroup>
            </div>
            <div className="space-y-2">
              <label>Password</label>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Lock size={"17px"} className="text-gray-500" />
                </InputLeftElement>
                <Input
                  isRequired
                  value={signpassword || ''}
                  onChange={(e)=>{setSignPassword(e.target.value)}}
                  // pr="2rem"
                  borderColor='gray'
                  type={show ? "text" : "password"}
                  placeholder="Enter Password"
                />
                <InputRightElement width="2.5rem">
                  <Button h="1.75rem" size="xs" onClick={()=>{setShow(!show)}}>
                    {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>

            <div className="flex justify-between">
              <span></span>
             {/* <Checkbox borderColor={'gray.400'} isChecked={RememberUser} onChange={({target})=>{setRememberUser(target.checked)}} >Remember me</Checkbox> */}
             <Button 
                onClick={()=>{
                  setEmailVerifyMessage(null);
                  onGetEmailAddOpen();
                }} 
                size={'sm'} 
                colorScheme='blue' 
                variant='link' 
              >Forgot Password?</Button>
            </div>
            <Button type="submit" isLoading={IsLoginLoading} loadingText="Signing In" colorScheme="purple" w={"full"}>
              Sign In
            </Button>
            <div className="text-center">
              <span className="text-xs text-gray-50 hover:text-blue-600">OR</span>
              <QRScanner/>
            </div>
          </form>
        </div>
      </div>
    </div>

    


    <Modal isOpen={isGetEmailAddOpen} onClose={onGetEmailAddClose} isCentered closeOnOverlayClick={false}>
  <ModalOverlay />
  <ModalContent rounded={"3xl"}>
    <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Forgot Password?</ModalHeader>
    <ModalCloseButton mt={1} />
    <form onSubmit={HandleVerifyEmailAddress}>
    <ModalBody>
      <div className="space-y-2">
       <label>Enter Email Address</label>
       <Input isRequired value={VerifyEmailAdd || ''} onChange={({target})=>{setVerifyEmailAdd(target.value);}} borderColor={'gray.400'} placeholder={'Enter Email Address'} type='email'/>
       {EmailVerifyMessage && <label className="animate-pulse text-sm text-red-500">Incorrect E-mail Address. Please Enter the Correct E-mail</label>}
      </div>
    </ModalBody>
    <hr />
    <ModalFooter>
     <Button type="submit" colorScheme={'purple'}>Get OTP</Button>
    </ModalFooter>
    </form>
  </ModalContent>
</Modal>



<Modal isOpen={isVerifyOtpOpen} onClose={onVerifyOtpClose} isCentered closeOnOverlayClick={false}>
  <ModalOverlay />
  <ModalContent rounded={"3xl"}>
    <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Verify OTP</ModalHeader>
    <ModalCloseButton mt={1} />
    <form onSubmit={HandleVerifyOTP}>

    <ModalBody className='space-y-4'>
        <div className="space-y-2 text-center">
        <HStack justifyContent={'center'}>
             <PinInput otp value={OTPInput} onChange={setOTPInput}>
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
             </PinInput>
           </HStack>

            {EmailVerifyMessage && <label className="animate-pulse text-sm text-red-600">Incorrect OTP! Please Enter Correct OTP.</label>}

         </div>

    </ModalBody>
    <hr />
    <ModalFooter>
     <Button type="submit" colorScheme={'purple'}>Verify</Button>
    </ModalFooter>

    </form>
  </ModalContent>
</Modal>


<Modal isOpen={isResetPasswordOpen} onClose={onResetPasswordClose} isCentered closeOnOverlayClick={false}>
  <ModalOverlay />
  <ModalContent rounded={"3xl"}>
    <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Reset Password</ModalHeader>
    <ModalCloseButton mt={1} />
    <form onSubmit={HandleResetPassword}>
    <ModalBody className="space-y-4">
      <div className="space-y-2">
       <label>New Password</label>
       <Input 
         isRequired 
         isInvalid={FormErrors?.password}  
         value={ResetNewPassword || ''} 
         onChange={({target})=>{
          ValidateField('Password','password',target.value);
           setResetNewPassword(target.value);
         }} 
         borderColor={'gray.400'} 
         placeholder={'New Password'} 
         type='password'
       />
      {FormErrors?.password && <span className="text-sm text-red-600" >{FormErrors?.password}</span>}
      </div>
      <div className="space-y-2">
       <label>Confirm Password</label>
       <Input 
         isRequired 
         isInvalid={FormErrors?.confirmPassword}
         value={ConfirmResetPassword || ''} 
         onChange={({target})=>{
          ValidateField('Password','confirmPassword',target.value);

         setConfirmResetPassword(target.value);
 
         if(target.value !== ResetNewPassword){
           setComparePassword(false);
         }else{
           setComparePassword(true);
         }
         }} 
         borderColor={'gray.400'} 
         placeholder={'New Password'} 
         type='password'
        />
        {FormErrors?.confirmPassword && <span className="text-sm text-red-600" >{FormErrors?.confirmPassword}</span>}
      </div>
      {!ComparePassword ? <span className="text-red-600 text-sm animate-ping ">Password doesn't match!!</span> : ''}
    </ModalBody>
    <hr />
    <ModalFooter>
     <Button isLoading={IsResetPasswordLoading} isDisabled={Object.keys(FormErrors).length > 0} loadingText="Saving" type="submit" colorScheme={'purple'}>Save</Button>
    </ModalFooter>
    </form>
  </ModalContent>
</Modal>
    </>

  );
}

export default Login;
