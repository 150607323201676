import { Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useToast} from '@chakra-ui/react'
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect, useMemo, useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import moment from 'moment';
import { Lock, XCircle } from 'react-feather';
import DatePicker from "react-datepicker";
import _ from "lodash";
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';

function AllOvertime2() {

  const { userData } = useAuth();
    
  const toast = useToast();
  const id = "toast";

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [EmpList, setEmpList] = useState([]);

    const Today = new Date();
    const [SelectedEmps, setSelectedEmps] = useState([]);

    const [SelectedEmpOvertimeData, setSelectedEmpOvertimeData] = useState([]);

    const StartDateCurrent = moment(Today).startOf("month").format('YYYY-MM-DD');
    const EndDateCurrent = moment(Today).endOf("month").format('YYYY-MM-DD');

    const [AttendanceDateRange, setAttendanceDateRange] = useState([new Date(StartDateCurrent),new Date(EndDateCurrent)]);

    const [AttendanceStartDate, AttendanceEndDate] = AttendanceDateRange;


    const [UniqueGuidEmpRowData, setUniqueGuidEmpRowData] = useState([]);
        
    const HandleAttendanceHistoryChange = (dates) => {
      const [start, end] = dates;
      console.log("Dates",dates)
      setAttendanceDateRange(dates);

      GetEmpOvertimeRequestList(start,end);
    };


    const ConvertToHoursMinutes = (time) => {
      const date = new Date("1970-01-01T" + time + "Z");
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      return hours + " hours " + minutes + " minutes"
    }

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
          {
            headerName: "Emp Code",
            field: "empCode",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: false,
          },
          {
            headerName: "Emp Name",
            field: "empName", 
          },
          {
            headerName: "Overtime Date",
            field: "attendanceDate",
            cellRenderer:(params) => (
              `${new Date(params.data.attendanceDate).toLocaleDateString('en-GB')}`
            )
          },
          {
            headerName: "In-Time",
            field: "inTime",
          },
          {
            headerName: "Out-Time",
            field: "outTime",
          },
          {
            headerName: "Total Time",
            field: "totalTime",
          },
          // {
          //   headerName: "Actual Overtime",
          //   field: "totalTime",
          // },
          {
            headerName: "Final Overtime",
            field: "finalOverTime",
            valueGetter:(params) => ConvertToHoursMinutes(params.data.finalOverTime)
          },
          {
            headerName: "Status",
            field: "isStatus",
            cellRenderer:(params) => (
              params.data.isStatus === 0 ?
              <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                Pending
              </span> : params.data.isStatus === 1 ? 
              <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                Approved
              </span> :  params.data.isStatus === 2 ? 
              <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
              Rejected
              </span> : params.data.isStatus === 3 ? 
              <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
              Cancelled
              </span> : ''
            )
          },
          {
            headerName: "Final Review",
            field: "isLock",
            cellRenderer:(params) => (
              params.data.isLock === false && params.data.isStatus === 1 ? 
              <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
             Review Pending
            </span> :  params.data.isLock === true && params.data.isStatus === 1 ? 
                        <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                          Review Done
                        </span> : ''
            )
          },
          {
            headerName: "Action",
            field: "Status",
            width:250,
            cellRenderer: (params) => ( 
              params.data.isLock === false && params.data.isStatus === 1 ?
              <div className='space-x-3'>
              <Button  
                
                onClick={async ()=>{

                  let OvertimeData = params.data;

                  let body1={
                    // "overTimeFinalId": 0,
                    empName: OvertimeData.empName,
                    empGuid: OvertimeData.empGuId,
                   //  overtimeRequestId: OvertimeData.overtimeRequestId,
                    dateFrom :OvertimeData.attendanceDate,
                    dateto : OvertimeData.attendanceDate,
                    overtimeDatefrom : OvertimeData.attendanceDate,
                    overtimeDateto : OvertimeData.attendanceDate,
                    inTime : OvertimeData.inTime,
                    outTime : OvertimeData.outTime,
                    totalTime : OvertimeData.totalTime,
                    overtimeTime : OvertimeData.finalOverTime,
                    isStatus : OvertimeData.isStatus,
                    isLock : true,
                    month: new Date(OvertimeData.attendanceDate).getMonth()+1,
                    year : new Date(OvertimeData.attendanceDate).getFullYear(),
                    reportingOfficeId: OvertimeData.reportingOfficeId,
                    companyId: userData.companyId,
                    branchId: userData.isActiveBranch,
                  }

                  console.log("Review Done Body",body1)
                                                
                  await authApi.post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalData`,body1
                  )
                  .then((response) => {
                    console.log(response);
                    const res = response.data;
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title:res,
                        position: "top",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                    GetOvertimeRequestList(moment(OvertimeData.attendanceDate).startOf("month").format('YYYY-MM-DD'), moment(OvertimeData.attendanceDate).endOf("month").format('YYYY-MM-DD'),OvertimeData.empGuId);
                    
                  })
                  .catch((error) => {
                    console.error(error);
            
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: `Overtime not Reviewed`,
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
                }} 
                size={'xs'} colorScheme={'blue'} leftIcon={<Lock size='14px' />}>Final Review</Button>
           
           <Button  

onClick={async ()=>{

let OvertimeData = params.data;


let body1={
  // "overTimeFinalId": 0,
  empName: OvertimeData.empName,
  empGuid: OvertimeData.empGuId,
  // overtimeRequestId: OvertimeData.overtimeRequestId,
  dateFrom :OvertimeData.attendanceDate,
  dateto : OvertimeData.attendanceDate,
  overtimeDatefrom : OvertimeData.attendanceDate,
  overtimeDateto : OvertimeData.attendanceDate,
  inTime : OvertimeData.inTime,
  outTime : OvertimeData.outTime,
  totalTime : OvertimeData.totalTime,
  overtimeTime : OvertimeData.finalOverTime,
  isStatus : 2,
  isLock : true,
  month: new Date(OvertimeData.attendanceDate).getMonth()+1,
  year : new Date(OvertimeData.attendanceDate).getFullYear(),
  reportingOfficeId: OvertimeData.reportingOfficeId,
  approveBy: userData.userId,
  companyId: userData.companyId,
  branchId: userData.isActiveBranch,
}


console.log("Rejected Body",body1);

                              
await authApi.post(
  `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalData`,body1
)
.then((response) => {
  console.log(response);
  const res = response.data;
  if (!toast.isActive(id)) {
    toast({
      id,
      title:res,
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  }

  GetOvertimeRequestList(moment(OvertimeData.attendanceDate).startOf("month").format('YYYY-MM-DD'), moment(OvertimeData.attendanceDate).endOf("month").format('YYYY-MM-DD'),OvertimeData.empGuId);
  
})
.catch((error) => {
  console.error(error);

  if (!toast.isActive(id)) {
    toast({
      id,
      title: "ERROR",
      description: `Overtime not Rejected`,
      position: "top",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  }
});



}} 

size={'xs'} colorScheme={'red'}><XCircle size='14px' className="mr-2" />Reject</Button>
</div>
                  : ''

            )
          },
      ]);

      const isRowSelectable = useMemo(() => {
        return (params) => {
          return !!params.data && params.data.isStatus === 1;
        };
      }, []);

      const [UniqColumnDefs, setUniqColumnDefs] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "empName", 

        },

        {
            headerName: "From Date",
            field: "dataFromDate",
            valueGetter:(params) => new Date(params.data.dataFromDate).toLocaleDateString('en-GB')
          },
          {
            headerName: "To Date",
            field: "dataToDate",
            valueGetter:(params) => new Date(params.data.dataToDate).toLocaleDateString('en-GB')
          },
          {
            headerName: "Approved Overtime",
            field: "totalCalcOvertime",
          },

          {
            headerName: "Final Approval",
            field: "finalApprovalStatus",
            cellRenderer:(params) => (
              params.data.finalApprovalStatus === false ? 
              <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
              Pending
            </span> :  params.data.finalApprovalStatus === true ? 
                        <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                          Completed
                        </span> : ''
            )
          },

          {
            headerName: "Action",
            field: "empCode",
            cellRenderer:(params) => <Button
               onClick={()=>{
                // let EmpOverData = params.data;

                setSelectedEmpOvertimeData(params.data);

                GetOvertimeRequestList(params.data.dataFromDate,params.data.dataToDate,params.data.empGuId);
                // let EmpOvertime = AllEmpRowData.filter(data => data.empGuId === EmpOverData.empGuId)

                // console.log("Emp OVertime List",EmpOvertime,"All Emp OVertime",AllEmpRowData);

                // onOpen();
               }}
             size={'xs'} colorScheme='blue'>View</Button>
          }

      ]);





  

      
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);

    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: 'no-border',
      resizable:true
    }));


    const EmpViewColDef = useMemo(() => ({
       // flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border',
        resizable:true
      }));

      
    const onSelectionChanged = useCallback(() => {
      var SelectedEmployees = gridRef.current.api.getSelectedRows();
     console.log("Selected Employees",SelectedEmployees);  
    
     setSelectedEmps(SelectedEmployees);
    
    }, []);


    useEffect(() => {
        getEmployeeList();
        // GetOvertimeEmpEligibleList();
        GetEmpOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);
      }, []);
    

      const getEmployeeList = async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Employee List", response.data);
          const res = response.data;
          let ActiveEmpList = res.filter((data) => data.isActive === true);
          setEmpList(ActiveEmpList);
        } catch (error) {
          console.error(error);
        }
      };



      const GetEmpOvertimeRequestList = async (FromDate,ToDate) =>{
        console.log("Attendance DateRange",FromDate, ToDate);

        try {

          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetAttendanceAllEmpOverTimeListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FromDate=${new Date(FromDate).toLocaleDateString('en-CA')}&ToDate=${new Date(ToDate).toLocaleDateString('en-CA')}&AttStatus=p`
          )
          console.log("Overtime Request List",response);

          const res = response.data;

          let FinalOvertimeData = res.filter((data)=> data.finalOverTime !== "00:00:00")
        
          HandleFinalTableData(FinalOvertimeData,FromDate,ToDate);
        } catch (error) {
          console.error(error);
        }
      }



      const HandleFinalTableData = async (FinalOvertimeData,FromDate,ToDate) => {

        console.log("Final Overtime List",FinalOvertimeData);

        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOverTimeFinalListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
           console.log("Final Data List",response);

           const res = response.data;


          //  console.log("Value Check", _.compact(res.map((Finaladata)=>{
          //   if(Finaladata.empGuid === "c0f35713-fd11-4b77-aca1-e278b0d32a0c" && Finaladata.overtimeDatefrom === "2023-01-03T00:00:00"){
          //      return  Finaladata.reportingOfficeId
          //   }
          //  }))[0])
           
          let FinalRowData = FinalOvertimeData.map((data)=>{
              return {...data,
                isStatus : _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isStatus 
                  }
                 }))[0] || 0,
                isLock :  _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isLock
                  }
                 }))[0] || false,
                 reportingOfficeId :_.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.reportingOfficeId 
                  }
                 }))[0] || 0
              }
           })

           console.log("Final Row Data",FinalRowData)
          //  setRowData(FinalRowData)
          HandleEligibleList(FinalRowData,FromDate,ToDate)
        } catch (error) {
          console.error(error)
        }
      }


      const CalculateTotalOvertime = (data)=>{
        let minutesArray = data.map(item => {
          const time = item.finalOverTime.split(':');
          const hours = parseInt(time[0]);
          const mins = parseInt(time[1]);
          return (hours * 60) + mins;
        });

        const totalMinutes = minutesArray.reduce((total, current) => total + current, 0);

        return Math.floor(totalMinutes / 60) + ' Hours ' + totalMinutes % 60 + ' Minutes';
      }

      const HandleEligibleList = async(FinalOvertimeData,FromDate,ToDate) => {

        try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            // console.log("Eligible Overtime List",response.data);
      
            const res = response.data;
  
            let EligibleList = res.filter((data)=>data.isActive === true);
  
            console.log("Eligible List",EligibleList)
  
  
  
            let UniqueRowData = _.compact(FinalOvertimeData.filter(data => data.isStatus !== 0).map((AttData)=>{
              if(EligibleList.filter(data => data.empGuid === AttData.empGuId).some((data)=>{
                if(data.isActive){
                  return true;
                }else{
                  return false;
                }
              })){
                return AttData
              }
            }))
  
            console.log("temp row Data",UniqueRowData)
            setUniqueGuidEmpRowData(_.uniqBy(UniqueRowData,'empGuId').map((data)=>{
              return {...data,
                  empGuId : data.empGuId,
                  empName : data.empName,
                  empCode : data.empCode,
                  dataFromDate : FromDate,
                  dataToDate : ToDate,
                  totalCalcOvertime: CalculateTotalOvertime(UniqueRowData.filter((AllData)=>AllData.empGuId === data.empGuId && AllData.isStatus === 1)),
                  finalApprovalStatus: UniqueRowData.filter((AllData)=>AllData.empGuId === data.empGuId).every(LockData => LockData.isLock === true)
              }
            }))
          
      
          } catch (error) {
            console.error(error)
          }

      }


      const GetOvertimeRequestList = async (FromDate,ToDate,RowEmpGuid) =>{
        console.log("Attendance DateRange",FromDate, ToDate,RowEmpGuid);

        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetAttendanceAllEmpOverTimeListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FromDate=${new Date(FromDate).toLocaleDateString('en-CA')}&ToDate=${new Date(ToDate).toLocaleDateString('en-CA')}&AttStatus=p`
          )
          console.log("Overtime Request List",response);

          const res = response.data;

          let FinalOvertimeData = res.filter((data)=> data.finalOverTime !== "00:00:00")

          // setRowData(FinalOvertimeData);
          HandleOvertimeFinalData(FinalOvertimeData,RowEmpGuid)
        } catch (error) {
          console.error(error);
        }
      }


      const HandleOvertimeFinalData = async (FinalOvertimeData,RowEmpGuid) => {

        console.log("Final Overtime List",FinalOvertimeData);

        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOverTimeFinalListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
           console.log("Final Data List",response);

           const res = response.data;

          //  console.log("Value Check", _.compact(res.map((Finaladata)=>{
          //   if(Finaladata.empGuid === "c0f35713-fd11-4b77-aca1-e278b0d32a0c" && Finaladata.overtimeDatefrom === "2023-01-03T00:00:00"){
          //      return  Finaladata?.isStatus
          //   }
          //  }))[0])
           
          let FinalRowData = FinalOvertimeData.map((data)=>{
              return {...data,
                isStatus : _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isStatus 
                  }
                 }))[0] || 0,
                isLock :  _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isLock
                  }
                 }))[0] || false,
              }
           })

           console.log("Final Row Data",FinalRowData)
          //  setRowData(FinalRowData)
           GetOvertimeEmpEligibleList(FinalRowData,RowEmpGuid)
        } catch (error) {
          console.error(error)
        }
      }

      const GetOvertimeEmpEligibleList = async (FinalRowData,RowEmpGuid) =>{

        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log("Eligible Overtime List",response.data);
    
          const res = response.data;

          let EligibleList = res.filter((data)=>data.isActive === true);

          console.log("Eligible List",EligibleList)

          

          console.log("Final Eligible List",_.compact(FinalRowData.map((AttData)=>{
            if(EligibleList.filter(data => data.empGuid === AttData.empGuId).some((data)=>{
              if(data.isActive){
                return true;
              }else{
                return false;
              }
            })){
              return AttData
            }
          })))



          let EmpOVertimeList = _.compact(FinalRowData.map((AttData)=>{
            if(EligibleList.filter(data => data.empGuid === AttData.empGuId).some((data)=>{
              if(data.isActive){
                return true;
              }else{
                return false;
              }
            })){
              return AttData
            }
          }))

          setRowData(EmpOVertimeList.filter(data => data.empGuId === RowEmpGuid && data.isStatus !== 0))

       onOpen();
    
    
        } catch (error) {
          console.error(error)
        }
    
      }

      console.log("Uniq GUid Row Data",UniqueGuidEmpRowData)



    const SaveBatchFinalReview = async () => {

      let body = SelectedEmps.map((OvertimeData)=>{
        return {
          empName: OvertimeData.empName,
          empGuid: OvertimeData.empGuId,
          dateFrom :OvertimeData.attendanceDate,
          dateto : OvertimeData.attendanceDate,
          overtimeDatefrom : OvertimeData.attendanceDate,
          overtimeDateto : OvertimeData.attendanceDate,
          inTime : OvertimeData.inTime,
          outTime : OvertimeData.outTime,
          totalTime : OvertimeData.totalTime,
          overtimeTime : OvertimeData.finalOverTime,
          isStatus : OvertimeData.isStatus,
          isLock : true,
          month: new Date(OvertimeData.attendanceDate).getMonth()+1,
          year : new Date(OvertimeData.attendanceDate).getFullYear(),
          reportingOfficeId: OvertimeData.reportingOfficeId,
          approveBy: userData.userId,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        }
      })


      console.log("Batch Approval Body",body);

      await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalList`,body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
  
        // GetOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);

        GetOvertimeRequestList(moment(SelectedEmps[0].attendanceDate).startOf("month").format('YYYY-MM-DD'), moment(SelectedEmps[0].attendanceDate).endOf("month").format('YYYY-MM-DD'),SelectedEmps[0].empGuId);

        
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Overtime not Approved`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });


    }



    const SaveBatchRejection = async () => {

      let body = SelectedEmps.map((OvertimeData)=>{
        return {
          empName: OvertimeData.empName,
          empGuid: OvertimeData.empGuId,
          dateFrom :OvertimeData.attendanceDate,
          dateto : OvertimeData.attendanceDate,
          overtimeDatefrom : OvertimeData.attendanceDate,
          overtimeDateto : OvertimeData.attendanceDate,
          inTime : OvertimeData.inTime,
          outTime : OvertimeData.outTime,
          totalTime : OvertimeData.totalTime,
          overtimeTime : OvertimeData.finalOverTime,
          isStatus : 2,
          isLock : true,
          month: new Date(OvertimeData.attendanceDate).getMonth()+1,
          year : new Date(OvertimeData.attendanceDate).getFullYear(),
          reportingOfficeId: OvertimeData.reportingOfficeId,
          approveBy: userData.userId,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        }
      })


      console.log("Batch Rejection Body",body);

      await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOverTimeFinalList`,body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
  
        GetOvertimeRequestList(moment(SelectedEmps[0].attendanceDate).startOf("month").format('YYYY-MM-DD'), moment(SelectedEmps[0].attendanceDate).endOf("month").format('YYYY-MM-DD'),SelectedEmps[0].empGuId);
        
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Overtime not Approved`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });


    }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">All Overtime</h1>
        <div className="flex items-center justify-end">
          <label className="font-semibold text-lg mr-3 ">
            Overtime Data Range :
          </label>
          <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
              selectsRange={true}
              startDate={AttendanceStartDate}
              endDate={AttendanceEndDate}
              onChange={(update) => {
                HandleAttendanceHistoryChange(update);
              }}
              dateFormat="dd/MM/yyyy"
              // isClearable={true}
              placeholderText="Select Attendance Range"
            />
          </div>
        </div>      </div>





      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={UniqueGuidEmpRowData} // Row Data for Rows
          columnDefs={UniqColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          rowSelection={'multiple'}
        //   onSelectionChanged={onSelectionChanged}
        />
      </div>



      <Modal size='full' isOpen={isOpen} onClose={()=>{
        GetEmpOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);
        onClose();

      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>View Employee Overtime</ModalHeader>
          <ModalCloseButton onClick={()=>{
                    GetEmpOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);
                 onClose();
           }} />
          <hr />
          <ModalBody className='bg-slate-200'>

          <div className='bg-white p-4 rounded-2xl drop-shadow-md mb-5'>
            <div className="flex  gap-8">
                  <div>
                    <p className="text-sm text-gray-500">Emp Name</p>
                    <h6 className="font-medium">{SelectedEmpOvertimeData.empName}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Emp Code</p>
                    <h6 className="font-medium">{SelectedEmpOvertimeData.empCode}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Overtime Min</p>
                    <h6 className="font-medium">{ConvertToHoursMinutes(SelectedEmpOvertimeData.overTimeMin)}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Overtime Max</p>
                    <h6 className="font-medium">{ConvertToHoursMinutes(SelectedEmpOvertimeData.overTimeMax)}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Overtime Eligible Date</p>
                    <h6 className="font-medium">{new Date(SelectedEmpOvertimeData.overtimeEligibleDate).toLocaleDateString('en-GB')}</h6>
                  </div>

                  <div>
                    <p className="text-sm text-gray-500">Approved Overtime</p>
                    <h6 className="font-medium">{SelectedEmpOvertimeData.totalCalcOvertime}</h6>
                  </div>

                </div>

            </div>



         <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
           <FontAwesomeIcon className="rotate-180 text-xl" icon={faArrowTurnUp} />
          </div>
          <Button onClick={SaveBatchFinalReview} size={'sm'} colorScheme='blue'><Lock size='14px' className="mr-2" />Final Review</Button>
          <Button onClick={SaveBatchRejection} size={'sm'} colorScheme='red'><XCircle size='16px' className="mr-2" />Reject</Button>
        </div>

          <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={EmpViewColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          rowSelection={'multiple'}
          onSelectionChanged={onSelectionChanged}
          isRowSelectable={isRowSelectable}
        />
      </div>
          </ModalBody>
          <hr />
          <ModalFooter>
           <Button colorScheme='purple' variant={'outline'}  onClick={()=>{
                    GetEmpOvertimeRequestList(AttendanceStartDate, AttendanceEndDate);
                 onClose();
           }}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>




    </div>
  )
}

export default AllOvertime2