import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Transition from '../Utils/Transition';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { CheckCircle } from 'react-feather';
import { MdOutlineNotificationsActive } from 'react-icons/md'
import { Tooltip } from '@chakra-ui/react';
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';
function Notifications() {

  const {userData} = useAuth();


  const navigate = useNavigate()

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [NotificationsList, setNotificationsList] = useState([]);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });


  useEffect(()=>{
    GetNotifications();
  },[])


   const GetNotifications = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Notification/GetNotificationlist?EmpGuid=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      // console.log("Notification Response",response);
      setNotificationsList(response.data);
    } catch (error) {
      console.error(error);
    }
   }
  return (
    <div className="relative inline-flex ">

      <Tooltip borderRadius={'7px'} hasArrow label='Notifications' bgColor={'gray.600'}>
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full ${dropdownOpen && 'bg-slate-200'}`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Notifications</span>
        <MdOutlineNotificationsActive className='text-xl text-gray-700' />
        {NotificationsList.some(data => data.isRead === false) ? 
        <div className="absolute inline-flex items-center justify-center w-4 h-4 text-[0.7rem] font-bold text-white bg-rose-500 border border-white rounded-full -top-1 -right-1">{NotificationsList.filter(data => data.isRead === false).length}</div>
        : ''}
      </button>
      </Tooltip>


      <Transition
        className="origin-top-right z-10 absolute top-full right-0 -mr-48 sm:mr-0 min-w-80 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="text-xs font-semibold text-slate-800 bg-slate-100 uppercase pt-1.5 pb-2 px-4 border-b">Notifications</div>
          <ul className='max-h-80 h-auto overflow-auto'>

            {NotificationsList.length === 0 ? <>

            <li onClick={() => setDropdownOpen(!dropdownOpen)} className="border-b border-slate-200 last:border-0 text-center">
                <span className="block text-sm py-2">📣 No Notifications Found</span>
            </li>
            </> : <>

            {NotificationsList.map((data,i)=>{
              return <li key={i} className="border-b border-slate-200  last:border-0">
              <Link
                className={`block py-2 px-4 hover:bg-slate-100 ${data.isRead ? 'bg-slate-200' : ''}`}
                to="/myleave"
                onClick={async() =>{
                  try {
                    const response =  await authApi.get(
                      `${process.env.REACT_APP_ACQ_URL}/api/Notification/UpdateNotificationById?Id=${data.id}`
                      )
                          // console.log("Notification Save Response",response);
                          // const res = response.data;
                          GetNotifications();
                  } catch (error) {
                    console.error(error);
                  }
                  setDropdownOpen(!dropdownOpen); 
                  }}
              >
                <span className="block font-semibold text-sm mb-1 text-gray-800">📣 {data.title}</span>
                <span className="block font-light text-xs mb-2 text-gray-600">{data.nMessage}</span>
                <div className='flex justify-between'>
                 <span className="block text-xs font-medium text-slate-400">{moment(data.createdOn).format('MMM D, YYYY')}</span>
                 <CheckCircle size={'14px'} className={`${data.isRead ? 'text-blue-600' : 'text-slate-200'}`} />
                </div>
              </Link>
            </li>
            })}
            </>}





          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default Notifications;