import { data } from 'autoprefixer';
import React, { useState } from 'react';
import Tesseract from 'tesseract.js';

const ImageRecognition = () => {
    const [image, setImage] = useState(null);
    const [text, setText] = useState('');
  
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setImage(URL.createObjectURL(file));
        convertImageToText(file);
      }
    };
  
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    const convertImageToText = (file) => {
      Tesseract.recognize(
        file,
        'eng', // Language code (e.g., 'eng' for English)
        { logger: (info) => console.log(info) }
      ).then(({ data: { text } }) => {
        console.log("Data",text.split(/[ \n]+/),text.split(/[ \n]+/)?.filter(item => panRegex.test(item)));

        const PanCardNumber = text.split(/[ \n]+/)?.filter(item => panRegex.test(item))
        setText({
          extractedText: text,
          panNumber: PanCardNumber
        });
      });
    };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {image && <img className='h-52' src={image} alt="Uploaded" style={{ maxWidth: '100%' }} />}
      {text && <div className='my-6'>
         <h2 className='text-lg font-semibold'>Extracted Text: {text?.extractedText} </h2>
         <h2 className='text-lg font-semibold'>Pan Card Number: {text?.panNumber} </h2>
        </div>}
    </div>
  )
}

export default ImageRecognition