import React, { useState, useMemo, useCallback } from "react";
import { Input, Button, Accordion, AccordionButton, AccordionIcon, AccordionPanel, AccordionItem, Radio, RadioGroup, Stack,  Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,  useDisclosure, FormControl, FormLabel, Switch} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { FiEdit, FiSettings } from "react-icons/fi";
import { useAuth } from "../Utils/AuthContext";
import useFetch from "../Utils/useFetch";
import usePost from "../Utils/usePost";
import authApi from "../Utils/AuthApis";

export default function LeaveQuota() {
  const {userData} = useAuth();
  const { isOpen: isLeaveSettingOpen, onOpen: onLeaveSettingOpen, onClose: onLeaveSettingClose } = useDisclosure()
  const { FetchedData: LeaveQuotaList, Refetch: RefetchLeaveQuotaList} = useFetch('/api/Leave/GetLeaveQuotaList',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });

  const { IsPostLoading: IsPostLeaveQuotaLoading, PostData: PostLeaveQuotaData} = usePost('/api/Leave/SAVELeaveQuota');
  const { IsPostLoading: IsPostLeaveSettingLoading, PostData: PostLeaveSettingData} = usePost('/api/Leave/SAVELeaveSettings');

  const [LeaveQuotaDetails,setLeaveQuotaDetails] = useState({
    leaveQuotaId: 0,
    leaveType: '',
    leaveDescription: '',
    colorCodeHash: '#f44336',
    assignAnnualLeaves:'',
    maxLeaveQuota: '',
    calculateMonthly: "1",
    paidLeave: "1",
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  });

  const [LeaveSettingDetails, setLeaveSettingDetails] = useState({
    srNo: 0,
    leaveSettingId: 0,
    leaveQuotaId: 0,
    leaveTypeName: "",
    backgroundColor: null,
    fontColor: null,
    policyName: "",
    perYearLeave: 0,
    perMonthlyLeaves: 0,
    monthyBasisEarningLeaves: false,
    applicableInNoticePeriod: false,
    applicableInProbationPeriod: false,
    empLeaveCancellaion: false,
    leavesInStartingofYear: false,
    leaveAppliedBeforeDays: 0,
    medicalRequired: false,
    medicalAfterNoOfDays: 0,
    presentConsiderBy: "1",
    isActive: false,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch,
    shortName: null,
    remark: null,
    isMale: false,
    isFemale: false,
    isOther: false,
    isOverlapHoliday: false,
    isOverlapWO: false,
    isSandwichRule: false,
    isSandwichRuleHoliday: false,
    isSandwichRuleWO: false
  });

  console.log("Leave Setting Details",LeaveSettingDetails);

  const [SelectedLeaveQuota, setSelectedLeaveQuota] = useState([]);
  

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true
  }));
  
  const [columnDefs] = useState([
    {
      headerName: "Leave Type",
      field: "leaveType",
    },
    {
      headerName: "Assign Annual Leave",
      field: "assignAnnualLeaves",
    },
    {
      headerName: "Max Leave Quota",
      field: "maxLeaveQuota",
    },
    {
      headerName: "Paid Leave",
      field: "paidLeave",
    },
    {
      headerName: "Monthly Calculation",
      field: "calculateMonthly",
    },
    {
      headerName: "Show to Employees",
      field: "isActive",
      cellRenderer: ({data}) => <Switch 
        isChecked={data.isActive} 
        onChange={async({target})=>{
          let body ={
            ...data,
            isActive: target.checked
          }
          console.log("Switch body",body);
          const response = await PostLeaveQuotaData(body);
          if(response){
            RefetchLeaveQuotaList();
          }
        }} 
        colorScheme="green" />
    },
    { headerName: "Configuration Status",
      cellRenderer: ({data}) => data.leaveSettingId ? 
        <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Completed
        </span> :
        <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Pending
        </span>
    },
    {
      headerName: "Action",
      cellRenderer: ({data}) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setLeaveQuotaDetails({
                leaveQuotaId: data.leaveQuotaId,
                leaveType: data.leaveType,
                leaveDescription: data.leaveDescription,
                colorCodeHash: data.colorCodeHash,
                assignAnnualLeaves: data.assignAnnualLeaves,
                maxLeaveQuota: data.maxLeaveQuota,
                calculateMonthly: data.calculateMonthly === true ? "1" : "2",
                paidLeave: data.paidLeave === true ? "1" : "2",
                companyId: userData.companyId,
                branchId: userData.isActiveBranch
              })
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
            leftIcon={<FiEdit size="14px" />}
          >
            Edit
          </Button>
          <Button
            onClick={async() => {
              setSelectedLeaveQuota(data);

              try {
                const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveSettingByQuotaId?LeaveQuotaId=${data.leaveQuotaId}`)
                console.log("leave quota setting",response);
                setLeaveSettingDetails({
                  ...response.data,
                  perYearLeave: data.assignAnnualLeaves,
                  perMonthlyLeaves: (data.assignAnnualLeaves / 12).toFixed(2)
                });
                onLeaveSettingOpen();
              } catch (err) {
                console.error(err);
              }
            }}
            variant="outline"
            size="xs"
            colorScheme="blue"
            leftIcon={<FiSettings size="14px" />}
          >
            Configure
          </Button>
        </div>
      ),
    },
  ]);


  const SaveLeaveQuota = async (e) => {
    e.preventDefault();

    let body = {
      leaveType: LeaveQuotaDetails?.leaveType,
      leaveDescription: LeaveQuotaDetails?.leaveDescription,
      colorCodeHash: LeaveQuotaDetails?.colorCodeHash,
      assignAnnualLeaves: LeaveQuotaDetails?.assignAnnualLeaves,
      maxLeaveQuota: LeaveQuotaDetails?.maxLeaveQuota,
      calculateMonthly: LeaveQuotaDetails?.calculateMonthly === "1" ? true : false,
      paidLeave: LeaveQuotaDetails?.paidLeave === "1" ? true : false,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    };

    console.log("Save Leave Quota", body);

    const response = await PostLeaveQuotaData(body);
    if(response){
      RefetchLeaveQuotaList();
      setLeaveQuotaDetails({
        leaveQuotaId: 0,
        leaveType: '',
        leaveDescription: '',
        colorCodeHash: '#f44336',
        assignAnnualLeaves:'',
        maxLeaveQuota: '',
        calculateMonthly: "1",
        paidLeave: "1",
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      });
    }
  };
  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Quota</h1>
      </div>      

      <Accordion
        defaultIndex={[0]}
        shadow="lg"
        border="transparent"
        rounded="xl"
        allowMultiple
        bg="white"
      >
        <AccordionItem>
          <div>
            <AccordionButton>
              <h6 className="text-xl text-left flex-1 font-bold  text-gray-800">
                Add Leave Quota
              </h6>
              <AccordionIcon />
            </AccordionButton>
          </div>
          <AccordionPanel pb={4}>
            <form onSubmit={SaveLeaveQuota}>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
                <FormControl variant={'floating'}>
                  <Input
                    isRequired
                    value={LeaveQuotaDetails?.leaveType || ''}
                    onChange={({ target }) => {
                      setLeaveQuotaDetails({...LeaveQuotaDetails, leaveType: target.value});
                    }}
                    borderColor="gray.400"
                    placeholder=""
                  />
                  <FormLabel>Leave Type</FormLabel>
                </FormControl>

                <FormControl variant={'floating'}>
                  <Input
                    isRequired
                    value={LeaveQuotaDetails?.leaveDescription || ''}
                    onChange={({ target }) => {
                      setLeaveQuotaDetails({...LeaveQuotaDetails, leaveDescription: target.value});
                    }}
                    borderColor="gray.400"
                    placeholder=""
                  />
                  <FormLabel>Leave Description</FormLabel>
                </FormControl>

                <FormControl variant={'floating'}>
                  <Input
                    isRequired
                    type={"number"}
                    value={LeaveQuotaDetails?.assignAnnualLeaves}
                    onChange={({ target }) => {
                      setLeaveQuotaDetails({...LeaveQuotaDetails, assignAnnualLeaves: target.value});
                    }}
                    borderColor="gray.400"
                    placeholder=""
                  />
                  <FormLabel>Annual Leave</FormLabel>
                </FormControl>

                <FormControl variant={'floating'}>
                  <Input
                    isRequired
                    type={"number"}
                    value={LeaveQuotaDetails?.maxLeaveQuota}
                    onChange={({ target }) => {
                      setLeaveQuotaDetails({...LeaveQuotaDetails, maxLeaveQuota:target.value});
                    }}
                    borderColor="gray.400"
                    placeholder=""
                  />
                  <FormLabel>Max Leave Quota</FormLabel>
                </FormControl>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label>Paid Leave</label>
                    <RadioGroup value={LeaveQuotaDetails?.paidLeave} onChange={(value)=>{
                      setLeaveQuotaDetails({...LeaveQuotaDetails, paidLeave: value})
                    }}>
                      <Stack direction="row" gap={6}>
                        <Radio size="lg" borderColor={"gray.400"} value="1">
                          Yes
                        </Radio>
                        <Radio size="lg" borderColor={"gray.400"} value="2">
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </div>

                  <div className="space-y-2 ">
                    <label>Calculate Monthly</label>
                    <RadioGroup
                      value={LeaveQuotaDetails?.calculateMonthly}
                      onChange={(value)=>{
                        setLeaveQuotaDetails({...LeaveQuotaDetails, calculateMonthly: value})
                      }}
                    >
                      <Stack direction="row" gap={6}>
                        <Radio size="lg" borderColor={"gray.400"} value="1">Yes</Radio>
                        <Radio size="lg" borderColor={"gray.400"} value="2">No</Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </div>

                <div className="space-y-2 w-1/3">
                  <label>Color</label>
                  <Input
                    value={LeaveQuotaDetails?.colorCodeHash}
                    onChange={({ target }) => {
                      setLeaveQuotaDetails({...LeaveQuotaDetails, colorCodeHash: target.value})
                    }}
                    borderColor="gray"
                    type="color"
                  />
                </div>

              </div>

              <div className="space-x-4 my-2 text-right">
                <Button
                  colorScheme={"purple"}
                  variant={"outline"}
                  onClick={() => {
                    setLeaveQuotaDetails({
                      leaveQuotaId: 0,
                      leaveType: '',
                      leaveDescription: '',
                      colorCodeHash: '#f44336',
                      assignAnnualLeaves:'',
                      maxLeaveQuota: '',
                      calculateMonthly: "1",
                      paidLeave: "1",
                      companyId: userData.companyId,
                      branchId: userData.isActiveBranch
                    });
                  }}
                >
                  Reset
                </Button>
                <Button isLoading={IsPostLeaveQuotaLoading} loadingText="Saving..." type="submit" colorScheme="purple">
                  Save
                </Button>
              </div>
            </form>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={LeaveQuotaList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>


      <Drawer isOpen={isLeaveSettingOpen} placement='right' onClose={onLeaveSettingClose} size={'md'} >
        <DrawerOverlay />
        <DrawerContent roundedLeft={"3xl"}>
          <DrawerCloseButton  mt={1} />
          <DrawerHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTopLeft={"3xl"} roundedBottomLeft={"xl"} fontWeight="bold">Configure Leave Quota</DrawerHeader>
          <DrawerBody className="space-y-4 !pb-8">
          <div className="flex gap-6">
                <div className="space-y-1">
                  <label className="text-sm text-gray-500">Leave Type</label>
                  <h6 className="font-medium">{SelectedLeaveQuota?.leaveType}</h6>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Assigned Annual Leaves</label>
                  <h6 className="font-medium">{SelectedLeaveQuota?.assignAnnualLeaves}</h6>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Max Leave Quota</label>
                  <h6 className="font-medium">{SelectedLeaveQuota?.maxLeaveQuota}</h6>
                </div>
            </div>
            <hr />

            <div className="space-y-4">
              <h6 className="text-lg font-medium">Applicable for</h6>
              <div className="flex gap-4">
               <div className="space-x-4">
                 <label>Male</label>
                 <Switch isChecked={LeaveSettingDetails?.isMale} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, isMale: target.checked})}} colorScheme="green" />
               </div>
               <div className="space-x-4">
                 <label>Female</label>
                 <Switch isChecked={LeaveSettingDetails?.isFemale} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, isFemale: target.checked})}} colorScheme="green" />
               </div>
               <div className="space-x-4">
                 <label>Other</label>
                 <Switch isChecked={LeaveSettingDetails?.isOther} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,isOther: target.checked})}} colorScheme="green" />
               </div>
              </div>
            </div>

            <div className="space-y-4">
              <label className="text-lg font-medium">Leave Counts</label>
              <div className="grid grid-cols-2 gap-6">
                <FormControl variant={'floating'}>
                  <Input isReadOnly value={LeaveSettingDetails?.perYearLeave || ''} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,perYearLeave: target.value})}} type="number" borderColor={'gray.400'} placeholder=" "/>
                  <FormLabel>Per Year Leaves</FormLabel>
                </FormControl>
                <FormControl variant={'floating'}>
                  <Input isReadOnly value={LeaveSettingDetails?.perMonthlyLeaves || ''} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,perMonthlyLeaves: target.value})}} type="number" borderColor={'gray.400'} placeholder=" "/>
                  <FormLabel>Per Month Leaves</FormLabel>
                </FormControl>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <FormControl variant={'floating'}>
                <Input value={LeaveSettingDetails?.leaveAppliedBeforeDays || ''} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, leaveAppliedBeforeDays: target.value})}} type="number" borderColor={'gray.400'} placeholder=" "/>
                <FormLabel>Leave To Be Applied Before</FormLabel>
              </FormControl>
              <span>Days</span>
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Documents Required</label>
              <Switch isChecked={LeaveSettingDetails?.medicalRequired} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,medicalRequired: target.checked})}} colorScheme="green" />
            </div>
            {LeaveSettingDetails?.medicalRequired && <div className="flex items-center gap-2">
              <FormControl variant={'floating'}>
                <Input value={LeaveSettingDetails?.medicalAfterNoOfDays || ''} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,medicalAfterNoOfDays: target.value})}} type="number" borderColor={'gray.400'} placeholder=" "/>
                <FormLabel>Required After</FormLabel>
              </FormControl>
              <span>Days</span>
            </div>}
            <div className="space-x-4">
              <label className="text-lg font-medium">Monthly Earning Leave</label>
              <Switch isChecked={LeaveSettingDetails?.monthyBasisEarningLeaves} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,monthyBasisEarningLeaves: target.checked})}} colorScheme="green" />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Applicable in Probation Period</label>
              <Switch isChecked={LeaveSettingDetails?.applicableInProbationPeriod} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,applicableInProbationPeriod: target.checked})}} colorScheme="green" />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Applicable in Notice Period</label>
              <Switch isChecked={LeaveSettingDetails?.applicableInNoticePeriod} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,applicableInNoticePeriod: target.checked})}} colorScheme="green" />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Leave Cancellable by employee</label>
              <Switch isChecked={LeaveSettingDetails?.empLeaveCancellaion} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails,empLeaveCancellaion: target.checked})}} colorScheme="green" />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Can take in starting of the year</label>
              <Switch isChecked={LeaveSettingDetails?.leavesInStartingofYear} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, leavesInStartingofYear: target.checked})}} colorScheme="green" />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Present Considered</label>
              <Switch isChecked={LeaveSettingDetails?.presentConsiderBy} onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, presentConsiderBy: target.checked})}} colorScheme="green" />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Applicable on Holidays?</label>
              <Switch 
                isChecked={LeaveSettingDetails?.isOverlapHoliday} 
                onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, isOverlapHoliday: target.checked})}} 
                colorScheme="green" 
              />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Applicable on Week-Offs?</label>
              <Switch 
                isChecked={LeaveSettingDetails?.isOverlapWO} 
                onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, isOverlapWO: target.checked})}} 
                colorScheme="green" 
              />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Sandwich Rule Applicable?</label>
              <Switch 
                isChecked={LeaveSettingDetails?.isSandwichRule} 
                onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, isSandwichRule: target.checked})}} 
                colorScheme="green" 
              />
            </div>
            {
              LeaveSettingDetails?.isSandwichRule && <>
            <div className="space-x-4">
              <label className="text-lg font-medium">Sandwich Applicable on Holidays?</label>
              <Switch 
                isChecked={LeaveSettingDetails?.isSandwichRuleHoliday} 
                onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, isSandwichRuleHoliday: target.checked})}} 
                colorScheme="green" 
              />
            </div>
            <div className="space-x-4">
              <label className="text-lg font-medium">Sandwich Applicable on Week-Offs?</label>
              <Switch 
                isChecked={LeaveSettingDetails?.isSandwichRuleWO} 
                onChange={({target})=>{setLeaveSettingDetails({...LeaveSettingDetails, isSandwichRuleWO: target.checked})}} 
                colorScheme="green" 
              />
            </div>
              </>
            }

          </DrawerBody>
          <hr />
          <DrawerFooter>
            <Button variant='outline' colorScheme='purple' mr={3} onClick={onLeaveSettingClose}>
              Cancel
            </Button>
            <Button isLoading={IsPostLeaveSettingLoading} loadingText="Saving..." onClick={async () => {
              let body = {
                ...LeaveSettingDetails,
                leaveQuotaId: SelectedLeaveQuota?.leaveQuotaId,
                presentConsiderBy: LeaveSettingDetails?.presentConsiderBy ? "1" : "2"
              }
              const response = await PostLeaveSettingData(body)
              if(response){
                RefetchLeaveQuotaList();
                onLeaveSettingClose();
              }
            }} colorScheme='purple'>Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

    </div>
  );
}
