import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useAuth } from "./AuthContext";
import authApi from "./AuthApis";

const usePost = (endpoint) => {

  const { userData, HandleSessionModal  } = useAuth();
  const toast = useToast();
  const id = "toast";
  const [IsPostLoading, setIsPostLoading] = useState(false);
  const [PostError, setPostError] = useState(null);

  const PostData = async (data) => {
    var elapsedTime = 0;
    const startTime = performance.now();
    setIsPostLoading(true);
    try {
      const response = await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}${endpoint}`,
        data
      );
      console.log("Post Hook Response", response);
      const endTime = performance.now();
      elapsedTime = (endTime - startTime)/1000;

      if (typeof response?.data !== "object") {
        if (!toast.isActive(id) && (endpoint !== "/api/Account/VerifyUserLogin")) {
          toast({
            id,
            title: response.data,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true
          });
        }
      }else{
        if (!toast.isActive(id) && (endpoint !== "/api/Account/VerifyUserLogin") && (endpoint !== "/api/Leave/GetLOPAdjustmentList")) {
          toast({
            id,
            title: "Done",
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true
          });
        }
      }

      return response.data;
    } catch (err) {
      console.error("Post Hook PostError", err, err?.response?.data, err?.response?.statusText);
      SaveErrorLog(endpoint,err?.message,elapsedTime);
      setPostError(err?.message);
      if(err.response){
        if (typeof err?.response?.data !== "object") {
        toast({
          id,
          title: "ERROR",
          description: err?.response?.data || err?.response?.statusText || "Something Went Wrong!!",
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true
        })
      }
      }else if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: err?.message,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true
        })
      }else{
        toast({
          id,
          title: "ERROR",
          description: "Something Went Wrong!!",
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true
        })
      }

      if(err.response){
        if(err.response.statusText === "Unauthorized" || err.response.status === 401){
            HandleSessionModal();
        }
      }

    } finally {
      setIsPostLoading(false);
    }
  };

  const SaveErrorLog = async (ApiEndPoint,ErrMsg,ResTime) => {
    // console.log("Split",ApiEndPoint,ResTime);
    let body ={
      controllerName: ApiEndPoint?.split('/')[2],
      actionName: ApiEndPoint?.split('/')[3],
      businessName: "string",
      procedureName: ResTime?.toFixed(2) || '',
      errorLogDescription: `Request: ${ApiEndPoint} Error Msg: ${ErrMsg}`,
      logDate: new Date(),
      companyId: userData?.companyId || 0,
      branchId: userData?.isActiveBranch || 0
    }

    console.log('Error Log Body',body);

    await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/ErrorLog/SaveErrorLog`,body)
    .then((response)=>{
      console.log('Response',response);
    }).catch((err)=>{
      console.error(err);
    })
  }

  return { IsPostLoading, PostError, PostData };
};

export default usePost;
