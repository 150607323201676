import React, { useState } from 'react'
import useFetch from '../Utils/useFetch'
import { useAuth } from '../Utils/AuthContext'
import ReactSelect from 'react-select';
import { Button, Select } from '@chakra-ui/react';
import usePost from '../Utils/usePost';
import moment from 'moment';

const EmpBranchTransfer = () => {
    const {userData} = useAuth();
    const { IsFetchLoading: IsEmpListLoading, FetchedData : EmployeeList, Refetch: RefetchEmployeeList} = useFetch('/api/EmployeeDetail/GetEmployeelist',{
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch
    });
    const { IsFetchLoading: IsBranchListLoading, FetchedData : BranchList, Refetch: RefetchBranchList} = useFetch('/api/BranchMaster/GetBranchMasterList',{
        CompanyId: userData.companyId
    });
    const { IsPostLoading: IsPostEmpTransferLoading, PostData: PostEmpTransferData } = usePost('/api/EmployeeDetail/SaveEmployeeGeneralInfo');

    const [SelectedEmp, setSelectedEmp] = useState();
    const [SelectedBranch, setSelectedBranch] = useState();

    const SaveTransferEmp = async () => {
        
       let body={
         title:SelectedEmp?.title,
         firstName: SelectedEmp?.firstName,
         middleName: SelectedEmp?.middleName || '',
         lastName: SelectedEmp?.lastName,
         empCode: '',
         dateofjoin:  moment(SelectedEmp?.dateofjoin).format('YYYY-MM-DD'),
         designation: String(SelectedEmp?.designation),
         department: String(SelectedEmp?.department),
         reportingOfficersId: SelectedEmp?.reportingOfficersId,
         mobile: SelectedEmp?.mobile,
         officeEmail: SelectedEmp?.officeEmail,
         personalEmail: SelectedEmp?.personalEmail,
         regulationDate:  moment(SelectedEmp?.regulationDate).format('YYYY-MM-DD'),
         probationStartDate: moment(SelectedEmp?.probationStartDate).format('YYYY-MM-DD'),
         probationEndDate: moment(SelectedEmp?.probationEndDate).format('YYYY-MM-DD'),
         empType: String(SelectedEmp?.empType || 0),
         roleId: Number(SelectedEmp?.roleId),
         status: true,
         isActive: true,
         companyId: userData.companyId,
         branchId: SelectedBranch
       }

       console.log("Transfer Body",body);

       const response = await PostEmpTransferData(body);
       
    }

  return (
    <div className='pb-64'>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Transfer Employee</h1>
      </div>

      <div className='bg-white p-4 rounded-lg drop-shadow-md flex gap-4 items-end flex-wrap '>
        <div className='space-y-2 w-1/4'>
            <label>Select Employee</label>
            <ReactSelect
              options={EmployeeList?.filter(data => data.isActive )}
              value={SelectedEmp}
              onChange={(value) => {setSelectedEmp(value); }}
              getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
              getOptionValue={(option) => option.guId}
              key={(option)=> option.id}
              placeholder="Select Employee"
              className='whitespace-nowrap '
            />
        </div>
        
        <div className='space-y-2'>
            <label>Select Branch</label>
            <Select borderColor={'gray.400'} value={SelectedBranch} onChange={({target}) => setSelectedBranch(target.value)} placeholder='Select Branch'>
                {BranchList?.map((data)=> <option key={data?.branchId} value={data?.branchId}>{data?.branchName}</option>)}
            </Select>
        </div>

        <div>
            <Button isLoading={IsPostEmpTransferLoading} loadingText='Transferring' onClick={()=>{
              SaveTransferEmp();
            }} colorScheme='purple'>Transfer</Button>
        </div>
      </div>
    </div>
  )
}

export default EmpBranchTransfer