import React, { useState, useRef } from "react";
import { Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Button, Textarea, Input, Select } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";
import moment from "moment";
import usePost from './usePost'
import { AiOutlineIssuesClose } from "react-icons/ai";
import useFormValidation from "./useFormValidation";

const RaiseIssue = () => {

  const { FormErrors, ValidateField } = useFormValidation();

  const FileRef = useRef();
  const reset = () => {
    FileRef.current.value = "";
  };

  const { IsPostLoading, PostData} = usePost('/api/Issues/SaveIssues');
  const { userData } = useAuth();
  const location = useLocation();
  const { isOpen: isRaiseIssueOpen, onOpen: onRaiseIssueOpen, onClose: onRaiseIssueClose } = useDisclosure();
  const [RaisedIssueData, setRaisedIssueData] = useState({
    menuName: "",
    issueTitle: "",
    issueDescription: "",
    fileAttachment: "",
  });

  return (
    <div className="relative inline-flex ">
      <Tooltip
        borderRadius={"7px"}
        hasArrow
        label="Facing Any Issue? Please Raise Here."
        bgColor={"gray.600"}
      >
        <button
          onClick={() => {onRaiseIssueOpen();}}
          className={` w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full`}
        >
          <span className="sr-only">Settings</span>
          <AiOutlineIssuesClose className=" text-xl text-gray-700" />
        </button>
      </Tooltip>


      <Modal isCentered isOpen={isRaiseIssueOpen} onClose={onRaiseIssueClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.800"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >
            Raise An Issue
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={() => {}}>
            <ModalBody className="space-y-2">
              {/* <div className="space-y-2">
                <label>
                  Menu Name<span className="text-red-600">*</span>
                </label>
                <Input
                  isRequired
                  isInvalid={FormErrors?.menuName}
                  value={RaisedIssueData?.menuName}
                  onChange={({ target }) => {
                    ValidateField('Alphanumeric','menuName',target.value);
                    setRaisedIssueData({
                      ...RaisedIssueData,
                      menuName: target.value,
                    });
                  }}
                  borderColor={"gray"}
                  placeholder="Menu Name"
                />
                {FormErrors?.menuName && <span className="text-sm text-red-600" >{FormErrors?.menuName}</span>}
              </div> */}
              <div className="space-y-2">
                <label>Issue Type</label>
                <Select 
                  isRequired 
                  value={RaisedIssueData?.menuName} 
                  onChange={({ target }) => {
                    setRaisedIssueData({
                      ...RaisedIssueData,
                      menuName: target.value,
                    });
                  }} 
                  borderColor='gray' 
                  placeholder="Select Issue Type"
                >
                  <option>HR Muster</option>
                  <option>Hardware</option>
                  <option>Software</option>
                  <option>Network</option>
                  <option>Other</option>
                </Select>
              </div>
              <div className="space-y-2">
                <label>
                  Issue Title<span className="text-red-600">*</span>
                </label>
                <Input 
                  isInvalid={FormErrors?.issueTitle}
                  isRequired
                  value={RaisedIssueData?.issueTitle}
                  onChange={({ target }) => {
                    ValidateField('Alphanumeric','issueTitle',target.value);

                    setRaisedIssueData({
                      ...RaisedIssueData,
                      issueTitle: target.value,
                    });
                  }}
                  borderColor={"gray"}
                  placeholder="Issue Title"
                />
                {FormErrors?.issueTitle && <span className="text-sm text-red-600" >{FormErrors?.issueTitle}</span>}
              </div>
              <div className="space-y-2">
                <label>
                  Description<span className="text-red-600">*</span>
                </label>
                <Textarea
                  isRequired
                  isInvalid={FormErrors?.issueDescription}
                  value={RaisedIssueData?.issueDescription}
                  onChange={({ target }) => {
                    ValidateField('Alphanumeric','issueDescription',target.value);

                    setRaisedIssueData({
                      ...RaisedIssueData,
                      issueDescription: target.value,
                    });
                  }}
                  placeholder="Issue Description"
                  rows={2}
                  borderColor={"gray"}
                />
                {FormErrors?.issueDescription && <span className="text-sm text-red-600" >{FormErrors?.issueDescription}</span>}
              </div>
              <div className="space-y-2">
                <label>Attachment</label>
                <Input 
                  ref={FileRef}
                  borderColor={"gray"}
                  onChange={({ target }) => {
                    setRaisedIssueData({
                      ...RaisedIssueData,
                      fileAttachment: target.files[0],
                    });
                  }}
                  type="file"
                  accept="image/*"
                />
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button isDisabled={Object.keys(FormErrors).length > 0} isLoading={IsPostLoading} loadingText="Saving..."
                onClick={ async () => {
                  const IssueData = new FormData();

                  const { menuName, issueTitle, issueDescription, fileAttachment } = RaisedIssueData;

                  IssueData.append("menuName", menuName);
                  IssueData.append("issueUrl", location?.pathname);
                  IssueData.append("issueTitle", issueTitle);
                  IssueData.append("issueDescription", issueDescription);
                  IssueData.append("FileAttachmentPath", fileAttachment);
                  IssueData.append("raisedBy", userData?.userId);
                  IssueData.append("emailId", userData?.userName);
                  IssueData.append("raisedDate",moment().format('YYYY-MM-DD'));
                  IssueData.append("resolvedDate",moment().format('YYYY-MM-DD'));
                  IssueData.append("status",'Open');
                  IssueData.append("priority","Medium");
                  IssueData.append("companyId",userData?.companyId);
                  IssueData.append("branchId", userData?.isActiveBranch);
                  IssueData.append("createdOn",moment().format('YYYY-MM-DD'));
                  IssueData.append("createdBy",userData?.userId);

                  const response = await PostData(IssueData);
                  if(response){
                    onRaiseIssueClose();
                    reset();
                    setRaisedIssueData({
                        menuName: "",
                        issueTitle: "",
                        issueDescription: "",
                        fileAttachment: "",
                      });

                  }
                }}
                colorScheme="purple"
                mr={3}
              >
                Save
              </Button>
              <Button colorScheme="purple" onClick={onRaiseIssueClose} variant="outline">
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default RaiseIssue;
