import { useState } from 'react';
import * as Yup from 'yup';

const useFormValidation = () => {

  const [FormErrors, setFormErrors] = useState({});
  const [IsValidating, setIsValidating] = useState(false);

  const ValidateField = async (type, objectKey, value) => {
    setIsValidating(true);
    try {
      let schema;
      switch (type) {
        case 'Required': schema =  Yup.string().required("Field is required")
          break;
        case 'Alphanumeric': schema = Yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Only Alphanumeric are allowed');
          break;
        case 'AlphanumericHyphens': schema = Yup.string().matches(/^[a-zA-Z0-9\s-]*$/, 'Only Alphanumeric & Hyphens are allowed');
          break;
        case 'Email': schema = Yup.string().email('Invalid email format');
          break;
          case 'Password': schema = Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character');
          break;
        case 'Pincode': schema = Yup.string().matches(/^[0-9]{6}$/, 'Pincode is not valid')
          break;
        case 'Alphabets': schema = Yup.string().matches(/^[a-zA-Z\s]*$/, "Only alphabetic characters are allowed")
          break;
        case 'Mobile': schema = Yup.string().matches(/^[0-9]{6,10}$/, 'Mobile Number is not valid')
          break;
        case 'Address': schema =  Yup.string().matches(/^[a-zA-Z0-9\s,'-.]*$/, 'Only alphanumeric, spaces, commas, apostrophes, and hyphens are allowed')
          break;
        case 'Number': schema =  Yup.string().matches(/^[0-9]*$/, 'Only Numbers are allowed')
          break;
        case 'Passport': schema =  Yup.string().matches(/^[A-Z0-9]{6,20}$/,'Passport Number is invalid')
          break;
        case 'LabourCard': schema =  Yup.string().matches(/^[A-Za-z0-9]{6,15}$/,"Labour Card Number is invalid" )
          break;
        case 'VisaNumber': schema =  Yup.string().matches(/^[A-Za-z0-9]{6,20}$/,"Visa Number is invalid")
          break;
        case 'AccountNumber': schema =  Yup.string().matches(/^[0-9]{9,18}$/, "Account Number is invalid")
          break;
        case 'IfscCode': schema =  Yup.string().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "IFSC Code is invalid")
          break;
        case 'SwiftCode': schema =  Yup.string().matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, "Swift Code is invalid")
          break;
        case 'IbanNumber': schema =  Yup.string().matches(/^[A-Z]{2}\d{2}[A-Z\d]{4}\d{7}([A-Z\d]?){0,16}$/, "IBAN Number is invalid")
          break;
        case 'EmiratesId': schema =  Yup.string().matches(/^[0-9-]*$/,'Emirates Id only contains numbers and hyphens')
          break;
        case 'PersonalId': schema =  Yup.string().matches(/^[0-9-]*$/,'Personal Id only contains numbers and hyphens')
          break;
        case 'AadharNumber': schema =  Yup.string().matches(/^[0-9*]{12}$/,'Aadhar Number must be 12 digit')
          break;
        case 'Pancard': schema =  Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,'Pancard Number is incorrect')
          break;
        default: throw new Error('Invalid validation type');
      }
  
      await schema.validate(value);
      setFormErrors((prevErrors) => {
        const newFormErrors = { ...prevErrors };
        delete newFormErrors[objectKey];
        return newFormErrors;
      });
    } catch (error) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [objectKey]: error.message,
      }));
    } finally {
      setIsValidating(false);
    }
  };
  
  return {
    FormErrors,
    IsValidating,
    ValidateField,
  };
};

export default useFormValidation;
