import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Input,
  Button,
  useToast,
  Select,
} from "@chakra-ui/react";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import ReactSelect from "react-select";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function ShiftMaster() {

  // Getting Logged in user data from Local Storage
  const { userData } = useAuth();

  // Toastr Variables
  const toast = useToast();
  const id = "toast";

  // Input Variables
  const [shiftName, setShiftName] = useState();
  const [ShiftStartDate, setShiftStartDate] = useState();
  const [ShiftEndDate, setShiftEndDate] = useState();
  const [ShiftInTime, setShiftInTime] = useState();
  const [ShiftOutTime, setShiftOutTime] = useState();
  const [ShiftType, setShiftType] = useState();

  const [ShiftTypeList, setShiftTypeList] = useState([]);

  const [ProjectList, setProjectList] = useState([]);
  const [SelectedProject, setSelectedProject] = useState();

  const gridRef = useRef(); // Table Reference Variable
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Project Name",
      field: "shiftPojectID",
    },
    {
      headerName: "Shift Name",
      field: "shiftName",
    },
    {
      headerName: "Shift Type",
      field: "shiftType",
    },
    {
      headerName: "Start Date",
      field: "sValidStartDate",
      cellRenderer: (params) => (
        <span>{moment(params.data.sValidStartDate).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      headerName: "End Date",
      field: "sValidEndDate",
      cellRenderer: (params) => (
        <span>{moment(params.data.sValidEndDate).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      headerName: "In Time",
      field: "shiftInTime",
      // cellRenderer : (params) => <span>{moment(params.data.shiftInTime,'HH:mm:ss').format('HH:mm A')}</span>
    },
    {
      headerName: "Out Time",
      field: "shiftOutTime",
      // cellRenderer : (params) => <span>{moment(params.data.shiftOutTime,'HH:mm:ss').format('HH:mm A')}</span>
    },
    // {
    //   headerName: "Test Height",
    //   cellRenderer : (params) =>(
    //    <div className={`border-l-4 bg-purple-200 p-1 mb-1 border-purple-400 bg-opacity-60 rounded-lg`}>
    //     <h1 className="text-gray-800 font-semibold">08:00:00 - 12:00:00</h1>
    //     <h6 className="text-gray-600 text-sm font-normal">General</h6>
    //     <p className="text-gray-600 text-sm font-normal">Test Project</p>
    //    </div>)
    // }
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
    resizable: true,
  }));

  // Fetching Data on load
  useEffect(() => {
    getShiftMasterList();
    GetProjectList();
  }, []);

  // Calling API Fetching Shift Data
  const getShiftMasterList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Get Shift Type List Response", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetProjectList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftProjectMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Project List Response", response.data);
      const res = response.data;

      setProjectList(res.filter((data) => data.isActive === true));
    } catch (error) {
      console.error(error);
    }
  };

  // Posting Input data to API/Database
  const SaveShiftsetting = async (e) => {
    e.preventDefault();

    let body = {
      shiftPojectID: SelectedProject.shiftPojectID,
      shiftName: shiftName,
      sValidStartDate: moment(ShiftStartDate).format("YYYY-MM-DD"),
      sValidEndDate: moment(ShiftEndDate).format("YYYY-MM-DD"),
      shiftInTime: moment(ShiftInTime).format("HH:mm:ss") || "00:00:00",
      shiftOutTime: moment(ShiftOutTime).format("HH:mm:ss") || "00:00:00",
      shiftType: ShiftType,
      isActive: true,
      isLock: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log("Save Shift Setting Body", body);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/SaveShiftMasterData`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        // Again Calling API to fetch Updated Data
        getShiftMasterList();
        setShiftName("");
        setShiftStartDate();
        setShiftEndDate();
        setShiftInTime();
        setShiftOutTime();
        setShiftType("");
        setSelectedProject("");
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Shift not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const FilterShiftTypeList = (ProjectId) => {
    let ShiftTypeFilList = [
      {
        id: 1,
        shiftType: "General Shift",
      },
      {
        id: 2,
        shiftType: "Shift A",
      },
      {
        id: 3,
        shiftType: "Shift B",
      },
      {
        id: 4,
        shiftType: "Shift C",
      },
      {
        id: 5,
        shiftType: "Night Shift",
      },
    ];
    // console.log("Filtered List",ShiftTypeFilList.filter(obj1 => !rowData.find(obj2 => obj1.shiftType === obj2.shiftType && Number(obj2.shiftPojectID || 0) === Number(ProjectId || 0))));
    setShiftTypeList(
      ShiftTypeFilList.filter(
        (obj1) =>
          !rowData.find(
            (obj2) =>
              obj1.shiftType === obj2.shiftType &&
              Number(obj2.shiftPojectID || 0) === Number(ProjectId || 0)
          )
      )
    );
  };

  const areTimesInRange = () => {
    if (ShiftType === "General Shift" || ShiftType === "Night Shift") {
      return false;
    } else {
      const isOverlapping = rowData.some((shift) => {
        if (
          shift.shiftType !== "General Shift" &&
          shift.shiftType !== "Night Shift"
        ) {
          const shiftStart = moment(shift.shiftInTime, "HH:mm:ss").valueOf();
          const shiftEnd = moment(shift.shiftOutTime, "HH:mm:ss").valueOf();
          const newShiftStart = moment(ShiftInTime, "HH:mm:ss").valueOf();
          const newShiftEnd = moment(ShiftOutTime, "HH:mm:ss").valueOf();

          return (
            (newShiftStart >= shiftStart && newShiftStart < shiftEnd) ||
            (newShiftEnd > shiftStart && newShiftEnd <= shiftEnd) ||
            (newShiftStart <= shiftStart && newShiftEnd >= shiftEnd)
          );
        } else {
          return false;
        }
      });
      if (isOverlapping) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div>
      <div className="w-full border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Shift Master</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="md"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left flex-1 font-bold  text-gray-800">
                  Add Shift
                </h6>
                {ShiftType &&
                ShiftInTime &&
                ShiftOutTime &&
                areTimesInRange() ? (
                  <h1 className="text-white bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">
                    Shift Time Already Exists
                  </h1>
                ) : (
                  ""
                )}
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveShiftsetting}>
                <div className="grid grid-cols-4 gap-5">
                  <div className="space-y-2">
                    <label>Select Project</label>
                    <ReactSelect
                      options={ProjectList}
                      value={SelectedProject}
                      onChange={(value) => {
                        setSelectedProject(value);
                        FilterShiftTypeList(value.shiftPojectID);
                        setShiftStartDate(new Date(value.projectStartDate));
                        setShiftEndDate(new Date(value.projectEndDate));
                      }}
                      getOptionLabel={(option) => option.shiftProjectName}
                      getOptionValue={(option) => option.shiftPojectID}
                      placeholder="Select Project"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Shift Name</label>
                    <Input
                      value={shiftName || ""}
                      onChange={({ target }) => {
                        setShiftName(target.value);
                      }}
                      isRequired
                      borderColor="gray"
                      placeholder="Enter Shift Name"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Shift Start Date</label>
                    <DatePicker
                      readOnly
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={ShiftStartDate}
                      onChange={(date) => setShiftStartDate(date)}
                      placeholderText="Start Date"
                      dateFormat={"dd-MM-yyyy"}
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Shift End Date</label>
                    <DatePicker
                      readOnly
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={ShiftEndDate}
                      onChange={(date) => setShiftEndDate(date)}
                      placeholderText="End Date"
                      dateFormat={"dd-MM-yyyy"}
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Shift In Time</label>
                    <DatePicker
                      required
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={ShiftInTime}
                      onChange={(date) => setShiftInTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      timecaption="Time"
                      placeholderText="Shift In Time"
                      dateFormat="HH:mm"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Shift Out Time</label>
                    <DatePicker
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={ShiftOutTime}
                      onChange={(date) => setShiftOutTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      timecaption="Time"
                      placeholderText="Shift Out Time"
                      dateFormat="HH:mm"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Shift Type</label>
                    <Select
                      isRequired
                      value={ShiftType}
                      onChange={({ target }) => {
                        setShiftType(target.value);
                      }}
                      borderColor={"gray.400"}
                      placeholder="Select Shift Type"
                    >
                      {ShiftTypeList.map((data, i) => {
                        return (
                          <option key={i} value={data.shiftType}>
                            {data.shiftType}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="space-x-4 text-right">
                  <Button
                    onClick={() => {
                      setShiftName("");
                      setShiftStartDate();
                      setShiftEndDate();
                      setShiftInTime();
                      setShiftOutTime();
                      setShiftType("");
                      setSelectedProject("");
                    }}
                    colorScheme={"purple"}
                    variant={"outline"}
                  >
                    Reset
                  </Button>
                  <Button
                    isDisabled={areTimesInRange()}
                    colorScheme={"purple"}
                    variant={"solid"}
                    type={"submit"}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          // rowHeight={100}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default ShiftMaster;
