import { Button, Image,Input,useToast } from '@chakra-ui/react'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React,{useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import numeral from 'numeral';
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';

function UpgradePayment() {

    const { userData } = useAuth();

    const toast = useToast();
    const id = "toast";

    const navigate = useNavigate();

    const UrlParams = useParams();
    const PlanID = UrlParams.planId;
    const CheckoutType = UrlParams.checkoutType
    console.log("Url Params",UrlParams);

    const [ActivePlanDetails, setActivePlanDetails] = useState([]);

    const [UserDetails, setUserDetails] = useState([]);
    const [PlanDetails, setPlanDetails] = useState([]);
    const [NumberOfEmp, setNumberOfEmp] = useState(0);
    const [AddOnData, setAddOnData] = useState(0)
  
    const [EnterpriseAmount, setEnterpriseAmount] = useState(0);


    useEffect(()=>{
      UpdatePlanDetails();
      GetUserDetails();
      getPlanDetails();
    },[])

    const UpdatePlanDetails = async () => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Plan/UpdateSubscritpionData?CompanyId=${userData.companyId}`
        )
        console.log("Plan Update Res",response);
      }catch (err){
        console.error(err);
      }
    }

    const GetUserDetails = async () => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Account/GetUserDetailByUserId?UserId=${userData.userId}`
          )
          console.log("User Details",response);
          setUserDetails(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    const getPlanDetails = async () =>{
        try {
            const response = await authApi.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Account/Register?PlainId=${PlanID}`
            )
            console.log("Plan Response",response);
            const res = response.data;
            if(res){
              setPlanDetails(res);
              if(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9"){
                GetActivePlanDetails(res);
              }
            }else{
              alert('Wrong Plan Id, Please Select Valid Plan')
              navigate('/viewsubscription')
            }
        } catch (error) {
            console.error(error);
        }
    }

    const GetActivePlanDetails = async (PlanData) => {
      try{
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetUserSubscriptionByEmail?EmailId=${userData.userName}`
        )
        console.log("Active Plan",response);
        const res = response.data;
        setActivePlanDetails(res);

        if(CheckoutType === "1"){
          setNumberOfEmp(Number(res.uptoUsers || 0))
          setEnterpriseAmount(numeral(Number(res.uptoUsers || 0)*Number(PlanData.perEmployeePrice || 0)).format('0.00'))
        }else{
          let NoOfEmp = Number(res.uptoUsers) <= 50 ? Math.abs(Number(res.uptoUsers)-51):1
          setNumberOfEmp(NoOfEmp);
          setEnterpriseAmount(numeral(NoOfEmp*Number(PlanData.perEmployeePrice || 0)).format('0.00'))
        }

      }catch (err){
        console.error(err); 
      }
    }



    useEffect(()=>{
      if((PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9")&&(CheckoutType !== '1')){
        let EmpAmountTotal = parseInt(NumberOfEmp || 0)*Number(PlanDetails.perEmployeePrice || 0);
        let DataAmountTotal = parseInt(AddOnData || 0)*Number(0.02 || 0)
        setEnterpriseAmount(numeral(Number(EmpAmountTotal+DataAmountTotal)).format('0.00'))
      }
    },[NumberOfEmp,AddOnData])

    const HandleUpgradePlan = async () => {
      let body = {
        // companyName: CompanyName,
        // role: "Admin",
        // name: RegisteredName,
         userName: userData.userName,
        // password: RegisterPassword,
        // confirmPassword: RegisteredConfirmPassword,
        // isOnline: 1,
         planId : PlanDetails.planRefId,
         amount : Number(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? EnterpriseAmount : PlanDetails.price),
         mobile : UserDetails.mobile,
         currentUsers : ActivePlanDetails.uptoUsers,
         addonUsers: NumberOfEmp || 0,
         AddonData:AddOnData || 0,
         createdDate: moment().format('YYYY-MM-DD')
      }

     console.log("upgrade Generate Body",body);

     await authApi.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Razorpay/Payment`,body
    )
    .then((response) => {
      console.log("generate response",response);
      const res = response.data;

      displatRazorpay(res)

      
    })
    .catch((error) => {
      console.error(error);

    });


    }



    const displatRazorpay = async  (RazorPayDetails) => {

      const options = {
          key: RazorPayDetails.key,
          currency: RazorPayDetails.currency,
          amount: RazorPayDetails.amountInSubUnits,
          name: RazorPayDetails.name,
          description: RazorPayDetails.description,
          image:'/HRMusterLogo.png',
          order_id : RazorPayDetails.orderId,
          profileName : RazorPayDetails.profileName,
          profileContact : RazorPayDetails.profileContact,
          profileEmail : RazorPayDetails.profileEmail,
          handler : async (response) => {
            console.log("Payment Response ",response);
            console.log("Payment ID: ",response.razorpay_payment_id);
            console.log("Order ID: ",response.razorpay_order_id);
            console.log("Signature: ",response.razorpay_signature);

              alert("Payment ID: " + response.razorpay_payment_id);
              alert("Order Id: " + response.razorpay_order_id);
              alert("Signature: " + response.razorpay_signature);
              alert('Payment Successfull');

              if (response.razorpay_payment_id) {
                
                  let body = {
                        profileEmail : RazorPayDetails.profileEmail,
                        razorpay_payment_id : response.razorpay_payment_id,
                        razorpay_order_id : response.razorpay_order_id,
                        razorpay_signature : response.razorpay_signature,
                        generated_order_id: RazorPayDetails.orderId,
                        errorCode : '',
                        errorDescription : '',
                        errorSource : '',
                        errorStep : '',
                        errorReason : '',
                        errorMetadataOrder_id : '',
                        errorMetadataPayment_id : '',
                        paymentdate: moment().format('YYYY-MM-DD'),
                        paymentNote: CheckoutType === '1' ? 'Renew' : CheckoutType === '2' ? 'Upgrade' : 'Enterprise'
                      }

                      console.log("Payment Response Body",body);

                      await authApi.post(
                          `${process.env.REACT_APP_ACQ_URL}/api/Razorpay/SavePaymentResponse`,body
                        )
                        .then((response) => {
                          console.log(response);
                          const res = response.data;
                          if (!toast.isActive(id)) {
                            toast({
                              id,
                              title:"Payment Successfull",
                              position: "top",
                              status: "success",
                              duration: 2000,
                              isClosable: true,
                            });
                          }

                          navigate('/plantransactions')
                          
                        })
                        .catch((error) => {
                          console.error(error);
                    
                          if (!toast.isActive(id)) {
                            toast({
                              id,
                              title: "ERROR",
                              description: `Payment Failed`,
                              position: "top",
                              status: "error",
                              duration: 2000,
                              isClosable: true,
                            });
                          }
                        });

              }
          },
      };

      console.log("Options",options);
      const paymentObject = new window.Razorpay(options)

      paymentObject.on('payment.failed', async (response) => {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
          alert("Payment Failed");

          let body = {
            profileEmail : RazorPayDetails.profileEmail,
            razorpay_payment_id : response.razorpay_payment_id,
            razorpay_order_id : response.razorpay_order_id,
            razorpay_signature : response.razorpay_signature,
            generated_order_id: RazorPayDetails.orderId,
            errorCode : response.error.code,
            errorDescription : response.error.description,
            errorSource : response.error.source,
            errorStep : response.error.step,
            errorReason : response.error.reason,
            errorMetadataOrder_id : response.error.metadata.order_id,
            errorMetadataPayment_id : response.error.metadata.payment_id,
            paymentdate: moment().format('YYYY-MM-DD'),
            paymentNote: CheckoutType === '1' ? 'Renew' : CheckoutType === '2' ? 'Upgrade' : 'Enterprise'
          }

          console.log("Payment Response Body",body);

          await authApi.post(
              `${process.env.REACT_APP_ACQ_URL}/api/Razorpay/SavePaymentResponse`,body
            )
            .then((response) => {
              console.log(response);
              const res = response.data;
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title:"Payment Failed",
                  position: "top",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
              }
            })
            .catch((error) => {
              console.error(error);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: "ERROR",
                  description: `Payment Failed`,
                  position: "top",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
              }
            });
      })
      paymentObject.open()
  }

  return (
    <div>
      <div className="text-center mb-4">
        <div className="inline-flex items-center gap-4 bg-white p-2 rounded-xl drop-shadow-md">
          <Image className="h-16" src="/HRMusterLogo.png" />
          <div className="text-left">
            <h5 className="text-2xl text-gray-700 font-bold">HR Muster</h5>
            <h6 className="text-sm text-gray-500">One HR Muster Pvt. Ltd.</h6>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-3/4 mx-auto">
        <div className="flex gap-4 ">
          <div className="bg-white h-full p-4 drop-shadow-md rounded-xl w-full lg:w-2/3">
            <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
              <h1 className="text-xl font-bold text-gray-800">{PlanDetails.planName} Plan Details</h1>
            </div>

            {PlanDetails._PlanHighlightsModel?.map((data,i)=>{
              return <p key={i} className="flex items-center text-gray-600 mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center text-blue-400 bg-white rounded-full flex-shrink-0">
                    <FontAwesomeIcon icon={faCircleCheck}/>
                </span>{data.highlight}
              </p>
            })}
          </div>

          <div className="bg-white h-full p-4 drop-shadow-md rounded-xl w-full lg:w-1/3 space-y-4">

            <div className='space-y-2'>
             <div className="flex gap-1 items-center">
               <h2 className="font-medium text-gray-500">Company :</h2>
               <h6 className="font-medium">{userData.companyName}</h6>
             </div>

             <div className="flex gap-1 items-center">
               <h2 className="font-medium text-gray-500">Name :</h2>
               <h6 className="font-medium">{userData.name}</h6>
             </div>

             <div className="flex gap-1 items-center">
               <h2 className="font-medium text-gray-500">Email :</h2>
               <h6 className="font-medium">{userData.userName}</h6>
             </div>
            </div>
            <hr />
            {PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? 
            <div className='space-y-2'>
            {CheckoutType !== '1' ? <>
              <div className="flex gap-1 items-center">
               <h2 className="font-medium text-gray-500">Current Employees Limit :</h2>
               <h6 className="font-medium">{ActivePlanDetails.uptoUsers}</h6>
             </div>

             <div className="flex gap-1 items-center">
               <h2 className="font-medium text-gray-500">Current Data Usage Limit :</h2>
               <h6 className="font-medium">{parseInt(ActivePlanDetails.dataUsageUpto)?.toString().replace(/\.?0+$/, '')} GB</h6>
             </div>

             <div className="flex gap-1 items-center">
               <h2 className="font-medium text-gray-500">New Employees Limit :</h2>
               <h6 className="font-medium">{Number(ActivePlanDetails.uptoUsers || 0)} + {Number(NumberOfEmp || 0)} = {Number(ActivePlanDetails.uptoUsers || 0) + Number(NumberOfEmp || 0)}</h6>
             </div>

             <div className="flex gap-1 items-center">
               <h2 className="font-medium text-gray-500">New Data Usage Limit :</h2>
               <h6 className="font-medium">{parseInt(ActivePlanDetails.dataUsageUpto)?.toString().replace(/\.?0+$/, '')} + {Number(AddOnData || 0)} = {Number(parseInt(ActivePlanDetails.dataUsageUpto)?.toString().replace(/\.?0+$/, '') || 0) + Number(AddOnData || 0)} GB</h6> 
             </div>


      <div className='space-y-2'>
        <label>Number of Employee<span className='text-red-500 font-semibold'>*</span></label>
        <Input value={NumberOfEmp || ''} onChange={({target})=>{
          let EnterAmount = target.value; 
           setNumberOfEmp(EnterAmount);
          }}  borderColor={'gray.400'} type='number' placeholder='Number of Employees' />
      </div>

      <div className='space-y-2'>
        <label>Data Add on</label>
        <Input value={AddOnData || ''} onChange={({target})=>{
          let DataEnter = target.value;
          setAddOnData(DataEnter);
          }}  borderColor={'gray.400'} type='number' placeholder='Enter Data in GBs' />
      </div>
      </> : 
      <>
        <div className="flex gap-1 items-center">
          <h2 className="font-medium text-gray-500">Employees Limit :</h2>
          <h6 className="font-medium">{ActivePlanDetails.uptoUsers}</h6>
        </div>
        
        <div className="flex gap-1 items-center">
          <h2 className="font-medium text-gray-500">Data Usage Limit :</h2>
          <h6 className="font-medium">{parseInt(ActivePlanDetails.dataUsageUpto)?.toString().replace(/\.?0+$/, '')} GB</h6>
        </div>
      </>}
              </div> : ''}
      {(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9") && (Number(ActivePlanDetails.uptoUsers || 0) + Number(NumberOfEmp || 0)) <=50 ? <span className="text-red-600 text-xs">Total Number of Employees Must be Greater than 50</span> : ''}

            
          <div className="h-full p-4 rounded-xl border-2 border-blue-400">
              <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                {PlanDetails.planName} PLAN
              </h2>
              <h1 className="text-3xl text-gray-900 leading-none flex items-center border-gray-200">
                <span className="font-semibold">
                  $ {PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9"
                    ? EnterpriseAmount
                    : PlanDetails.price}
                </span>
                <span className="text-lg ml-1 mt-auto font-normal text-gray-500">
                  {PlanDetails.planType === "Monthly"
                    ? "/month"
                    : PlanDetails.planType === "Annual"
                    ? "/year"
                    : ""}
                </span>
              </h1>
            </div>
            <div>
                <Button isDisabled={(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9") && ((Number(ActivePlanDetails.uptoUsers || 0) + Number(NumberOfEmp || 0)) <=50) ? true : false} onClick={HandleUpgradePlan} width={'full'} colorScheme='purple'>Pay Now</Button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradePayment