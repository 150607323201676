import React,{ useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure,Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Input, Avatar, Switch, Select, FormControl, FormLabel, InputGroup, InputRightElement, RadioGroup, Stack, Radio } from "@chakra-ui/react";
import { BsPlusCircleDotted } from "react-icons/bs";
import { useAuth } from "../Utils/AuthContext";
import usePost from "../Utils/usePost";
import useFetch from "../Utils/useFetch";
import { LuEdit, LuFingerprint } from "react-icons/lu";
import { AgGridReact } from "ag-grid-react";

const BiometricIntegration = () => {

    const { userData } = useAuth();

    const { IsPostLoading: IsAddDeviceLoading, PostData: PostAddDeviceData } = usePost('/api/BioMetric/SaveDevice');
    const { IsPostLoading: IsEmpLinkupLoading, PostData: PostEmpLinkData } = usePost('/api/BioMetric/SaveDeviceEmpLink');
    const { FetchedData: DevicesList, Refetch: RefetchDeviceList } = useFetch('/api/BioMetric/GetDeviceList',{
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch
    })
    const { FetchedData: EmployeesList, Refetch: RefetchEmployeesList } = useFetch('/api/EmployeeDetail/GetEmployeelist',{
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch
    })
    const { FetchedData: EmpDeviceList, Refetch: RefetchEmpDeviceList } = useFetch('/api/BioMetric/GetDeviceEnpList',{
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch
    })

    console.log("Devices List",DevicesList);

    const [ShowEmployees, setShowEmployees] = useState('1');

    const [FilteredEmpList, setFilteredEmpList] = useState([]);
    const [BioDeviceData, setBioDeviceData] = useState({
        deviceId: 0,
        deviceNo: "",
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      });

    const [EmpLinkUpData, setEmpLinkUpData] = useState({
        deviceId: 0,
        linkId: 0,
        empGuid: "",
        deviceEmpId: "",
        companyId: userData.companyId,
        branchId: String(userData.isActiveBranch)
      })

    // useEffect(()=>{
    //     if(FetchDataLoading && DevicesList?.length === 0){
    //         onAddDeviceOpen();
    //     }
    // },[DevicesList])

    const {
      isOpen: isAddDeviceOpen,
      onOpen: onAddDeviceOpen,
      onClose: onAddDeviceClose,
    } = useDisclosure();

    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode"
      },
      {
        headerName: "Emp Name",
        field: "empName"
      },
      {
        headerName: "Device Name",
        field: "deviceName"
      },
      {
        headerName: "Emp Device Id",
        field: "deviceEmpId"
      },
      {
        headerName: "Action",
        cellRenderer: ({data}) => <Button onClick={()=>{
          setEmpLinkUpData(data);
        }} leftIcon={<LuEdit size={'13px'} />} colorScheme="blue" size={'xs'} >Edit</Button>
      }
    ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      enableCellTextSelection: true,
      resizable: true
    }));


    useEffect(() => {
      if(ShowEmployees === '2'){
        setFilteredEmpList(EmployeesList?.filter((emp)=> emp.isActive === false));
      }else{
        setFilteredEmpList(EmployeesList?.filter((emp)=> emp.isActive === true));
      }
    }, [ShowEmployees,EmployeesList]);


  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 ">
        <h1 className="text-lg font-bold text-gray-800">
          Biometric Integration
        </h1>
      </div>

      <Tabs colorScheme="purple">
        <TabList>
          <Tab>Employee Linkup</Tab>
          <Tab>Devices</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <form onSubmit={async(e)=>{
                e.preventDefault();
                console.log("Emp Linkup Body",EmpLinkUpData);
                const response = await PostEmpLinkData(EmpLinkUpData);
                if(response){
                  RefetchEmpDeviceList();
                  setEmpLinkUpData({
                    deviceId: 0,
                    linkId: 0,
                    empGuid: "",
                    deviceEmpId: "",
                    companyId: userData.companyId,
                    branchId: String(userData.isActiveBranch)
                  });
                }
              }} className="bg-white grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 items-end gap-6 p-4 rounded-xl drop-shadow-md mb-6">
                <div className="space-y-2">
                  <label>Select Device</label>
                  <Select isRequired value={EmpLinkUpData?.deviceId} onChange={({target})=>{
                    setEmpLinkUpData({...EmpLinkUpData, deviceId : parseInt(target.value)})
                  }} borderColor={'gray.400'} placeholder="Select Biometric Device">
                      {DevicesList?.filter(dev => dev.isActive)?.map((data)=>(<option key={data.deviceId} value={data.deviceId} >{data.deviceNo}</option>))}
                  </Select>
                </div>
                <div className="space-y-4">    
                  <label>Show Employees</label>
                  <RadioGroup onChange={setShowEmployees} value={ShowEmployees}>
                    <Stack direction='row' gap={4} mb={2}>
                      <Radio borderColor={'gray.400'} value='1'>Current</Radio>
                      <Radio borderColor={'gray.400'} value='2'>Ex-Employee</Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                <div className="space-y-2">
                  <label>Select Employee</label>
                  <Select isRequired value={EmpLinkUpData?.empGuid} onChange={({target})=>{
                    setEmpLinkUpData({...EmpLinkUpData, empGuid : target.value});
                  }} borderColor={'gray.400'} placeholder="Select Employee">
                      {FilteredEmpList?.map((data)=>(<option key={data.id} value={data.guId}>{data.fullName}</option>))}
                  </Select>
                </div>
                <div className="space-y-2">
                  <label>Employee Device Id</label>
                  <Input isRequired value={EmpLinkUpData?.deviceEmpId} onChange={({target})=>{
                    setEmpLinkUpData({...EmpLinkUpData, deviceEmpId : target.value})
                  }} borderColor={'gray.400'} placeholder="Employee Device Id"/>
                </div>
                <div>
                  <Button isLoading={IsEmpLinkupLoading} loadingText="Submitting..." type="submit" colorScheme="purple" >Submit</Button>
                </div>
            </form>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={EmpDeviceList} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>

          </TabPanel>
          <TabPanel px={0}>
            <div className="flex flex-wrap gap-6">
                {DevicesList?.map((data)=>{
                    return (
                      <div
                        key={data.deviceId}
                        className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36 gap-8"
                      >
                        <div className="flex flex-col justify-between">
                          <p className="text-sm font-medium text-gray-500">
                            Device
                          </p>
                          <h6 className="text-3xl font-bold text-gray-800">
                            {data.deviceNo}
                          </h6>
                          <Switch
                            isChecked={data.isActive}
                            onChange={async () => {
                              const response = await PostAddDeviceData({...data,isActive: !data.isActive,});
                              if (response) {
                                RefetchDeviceList();
                              }
                            }}
                            colorScheme="green"
                          />
                        </div>
                        <div className="flex flex-col justify-between items-end">
                          <Avatar
                            bg={"blue.100"}
                            size="md"
                            icon={<LuFingerprint className="text-3xl text-blue-500" />}
                          />
                          <Button onClick={()=>{
                            setBioDeviceData(data);
                            onAddDeviceOpen();
                          }} leftIcon={<LuEdit/>} colorScheme="blue" variant={'link'}>Edit</Button>
                        </div>
                      </div>
                    );
                })}
              {DevicesList.length < 1  && <div onClick={()=>{onAddDeviceOpen();}} className="border-gray-200 text-gray-500 hover:text-gray-800 cursor-pointer border-2 bg-white bg-opacity-20 hover:bg-opacity-40 rounded-3xl w-52 flex items-center drop-shadow-md p-3 ">
                <div className="mx-auto ">
                  <div className="space-y-2 text-center">
                    <BsPlusCircleDotted size={"4rem"} className="mx-auto" />
                    <p className="text-sm ">Add Device</p>
                  </div>
                </div>
              </div>}
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>


      <Modal isOpen={isAddDeviceOpen} onClose={()=>{  onAddDeviceClose();
                setBioDeviceData({
                  deviceId: 0,
                  deviceNo: "",
                  isActive: true,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch
                })}}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Device Details</ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={async(e)=>{
            e.preventDefault();
            console.log("Add Device Body",BioDeviceData);
            const response = await PostAddDeviceData(BioDeviceData);
            if(response){
                RefetchDeviceList();
                onAddDeviceClose();
                setBioDeviceData({
                  deviceId: 0,
                  deviceNo: "",
                  isActive: true,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch
                })
            }
          }}>
          <ModalBody>
            <div className="space-y-2">
               <label>Device Number</label>
               <Input isRequired value={BioDeviceData?.deviceNo || ''} onChange={({target})=>{
                setBioDeviceData({...BioDeviceData, deviceNo: target.value});
               }} borderColor={'gray.400'} placeholder="Device Number" />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme={'purple'} variant={'outline'} mr={3} onClick={()=>{
               onAddDeviceClose();
               setBioDeviceData({
                 deviceId: 0,
                 deviceNo: "",
                 isActive: true,
                 companyId: userData.companyId,
                 branchId: userData.isActiveBranch
               })
            }}>
              Close
            </Button>
            <Button type="submit" isLoading={IsAddDeviceLoading} loadingText="Saving..." colorScheme={'purple'} variant={'solid'}>Save</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

    </div>
  );
};

export default BiometricIntegration;
