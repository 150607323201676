import { Select, Checkbox, useToast, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Center, Spinner, Switch, Input } from "@chakra-ui/react";
import moment from "moment";
import React, { useState, useRef, useMemo, useCallback } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import useFetch from "../Utils/useFetch";
import { useAuth } from "../Utils/AuthContext";
import ReactSelect from "react-select";
import { FiPlus } from "react-icons/fi";
import { AgGridReact } from "ag-grid-react";
import usePost from "../Utils/usePost";
import OvertimeSetting from "../OvertimeComponents/OvertimeSetting";
import authApi from "../Utils/AuthApis";

function AttendanceSettings () {

  const {userData} = useAuth();

  const {FetchedData: EmployeeList} = useFetch('/api/EmployeeDetail/GetEmployeelist',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });
  const {FetchedData: LeaveQuotaList} = useFetch('/api/Leave/GetLeaveQuotaList',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });

  const { IsFetchLoading : IsAutoAttListLoading ,FetchedData: AutoAttList,  Refetch: RefetchAutoAttList } = useFetch('/api/Attendance/GetAutoAttendenceSettingsByBranch',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });

  const { IsPostLoading: IsAutoAttPostLoading, PostData: PostAutoAttData } = usePost('/api/Attendance/SaveAutoAttendanceSetting');
  const { IsPostLoading: IsPostAttSettingsLoading, PostData: PostAttSettingsData } = usePost('/api/Attendance/SaveAttendanceSetting');

  const [AutoAttSelEmps, setAutoAttSelEmps] = useState([]);

  const [InTime, setInTime] = useState();
  const [OutTime, setOutTime] = useState();
  const [PresentHours, setPresentHours] = useState();
  const [MinPresentHours, setMinPresentHours] = useState();
  const [LunchBreak, setLunchBreak] = useState();
  const [AttendanceType, setAttendanceType] = useState();
  const [HalfDayHours, setHalfDayHours] = useState();
  const [GraceTiming, setGraceTiming] = useState();
  const [GraceOutTime, setGraceOutTime] = useState();
  const [IsGraceForWFHEmployee, setIsGraceForWFHEmployee] = useState();
  const [Break1, setBreak1] = useState();
  const [Break2, setBreak2] = useState();
  const [AttEditable, setAttEditable] = useState(false)
  const [IsAbsentSettle, setIsAbsentSettle] = useState(false);
  const [SettleLeaveQuota, setSettleLeaveQuota] = useState();
  const [LateCounts, setLateCounts] = useState('0');


  const [CalculationTypeList, setCalculationTypeList] = useState([]);
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Designation",
      field: "designationName",
    },
    {
      headerName: "Department",
      field: "departmentName",
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer:({data}) => <Switch 
        isChecked={data.isActive} 
        onChange={async({target})=>{
          let body = [{
            ...data,
            isActive: target.checked,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
          }]
          console.log("Switch Body",body);

          const response = await PostAutoAttData(body);

          if(response){
            RefetchAutoAttList();
          }
        }}
        colorScheme="green" 
        />
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true
  }));


  useEffect(()=>{
    getAttendancesettingData();
    getAttendanceTypeMasterList();
  },[])
  
  const getAttendanceTypeMasterList = async()=>{
    try {
      const response= await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendanceTypeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Get Attendance Type List Response",response);
       setCalculationTypeList(response.data);
    } catch (err) {
      console.error(err);
    }
  }


  function getDateFromTime(time) {
    const [hours, minutes, seconds] = time.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
  }


  const getAttendancesettingData = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendenceSettingsByBranch?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Setting Data",response);
      const res = response.data;

      setInTime(getDateFromTime(res.officeInTime || "00:00:00"))
      setOutTime(getDateFromTime(res.officeOutTime || "00:00:00"))
      setPresentHours(getDateFromTime(res.presentHour || "00:00:00"))
      setLunchBreak(getDateFromTime(res.lunchBreak || "00:00:00"))
      setAttendanceType(res.attendanceTypeVal)
      setHalfDayHours(getDateFromTime(res.halfDayhour || "00:00:00"))
      setGraceTiming(getDateFromTime(res.graceTime || "00:00:00"))
      setGraceOutTime(getDateFromTime(res.graceOutTime || "00:00:00"))
      setIsGraceForWFHEmployee(res.graceTimeForWFHApplicable)
      setBreak1(getDateFromTime(res.break1 || "00:00:00"))
      setBreak2(getDateFromTime(res.break2 || "00:00:00"))
      setAttEditable(res.isAttendanceEditable);
      setMinPresentHours(getDateFromTime(res.minPresentHrs || "00:00:00"))
      setIsAbsentSettle(res.isAbsenceSettledWithLeave)
      setSettleLeaveQuota(res.leaveQuotaId)
      setLateCounts(res.allowedLateComings)
    } catch (err) {
      console.error(err);
    }
  }


  const ConvertToHoursMinutes = (time) => {
    const date = new Date("1970-01-01T" + time + "Z");
const hours = date.getUTCHours();
const minutes = date.getUTCMinutes();
return hours + " hours " + minutes + " minutes"
  }


  function calculateHoursWorked(intime, outime) {
    const intimeDate = new Date(intime);
    const outimeDate = new Date(outime);
  
    const timeDifference = outimeDate - intimeDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
  
    const wholeHours = Math.floor(hoursDifference);
    const fractionalHours = (hoursDifference - wholeHours) * 60;
  
    const formattedHours = String(wholeHours).padStart(2, '0');
    const formattedMinutes = String(Math.round(fractionalHours)).padStart(2, '0');
    const formattedSeconds = '00';

    const date = new Date();
    date.setHours(formattedHours);
    date.setMinutes(formattedMinutes);
    date.setSeconds(formattedSeconds);
  
    return date;
  }

  useEffect(()=>{
    if(InTime&& OutTime){
      setPresentHours(calculateHoursWorked(InTime || "00:00:00",OutTime || "00:00:00") || new Date());
    }
  },[InTime,OutTime]);


  const SaveAttendanceSetting = async (e) => {
    e.preventDefault();

    let body = {
      officeInTime: moment(InTime).format('HH:mm:ss') || "00:00:00",
      officeOutTime: moment(OutTime).format('HH:mm:ss') || "00:00:00",
      presentHour: moment(PresentHours).format('HH:mm:ss') || "00:00:00",
      halfDayhour: moment(HalfDayHours).format('HH:mm:ss') || "00:00:00",
      graceTime: moment(GraceTiming).format('HH:mm:ss') || "00:00:00",
      graceOutTime: moment(GraceOutTime).format('HH:mm:ss') || "00:00:00",
      graceTimeForWFHApplicable: IsGraceForWFHEmployee,
      lunchBreak: moment(LunchBreak).format('HH:mm:ss') || "00:00:00",
      break1: moment(Break1).format('HH:mm:ss') || "00:00:00",
      break2: moment(Break2).format('HH:mm:ss') || "00:00:00",
      minPresentHrs: moment(MinPresentHours).format('HH:mm:ss') || "00:00:00",
      attendanceTypeVal: Number(AttendanceType),
      isAttendanceEditable: AttEditable,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      isAbsenceSettledWithLeave: IsAbsentSettle,
      leaveQuotaId: Number(SettleLeaveQuota || 0),
      allowedLateComings: Number(LateCounts || 0)
    }

    console.log("Save Att Setting Body",body);

    const response = await PostAttSettingsData(body);

    if(response){
      getAttendancesettingData();
    }

  }

  return (
    <div>

<Tabs variant={'solid-rounded'} size={'sm'} colorScheme="purple">
  <TabList>
    <Tab>General Setting</Tab>
    <Tab>Allow App Attendnace</Tab>
    {/* <Tab>Auto Attendance Setting</Tab> */}
    {/* <Tab>Overtime Setting</Tab> */}
    {/* <Tab>Geo Fencing Setting</Tab> */}
  </TabList>
  <TabPanels>
    {/* General Settings */}
    <TabPanel px={0}>
    <div className="bg-white rounded-2xl drop-shadow-md p-4">
    <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Attendance Policy</h1>
        <span className='text-red-600 font-semibold'>*Note: Enter Time in 24 Hours Format</span>
      </div>

        <form onSubmit={SaveAttendanceSetting}>
        <div className="border-b border-slate-400  my-2">
        <div className="flex gap-8 my-4">
          <div className="space-y-2 w-1/3">
            <label>Office Timing<span className='text-red-500 font-semibold'>*</span></label>
            <div className="flex gap-3 items-center">
              <p>In:</p>
              <div className="flex-1">
                <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={InTime}
                  onChange={(date) => setInTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="In Time"
                />
                </div>
              </div>
              <p>Out:</p>
              <div className="flex-1">
                <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={OutTime}
                  onChange={(date) => setOutTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Out Time"
                />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2 w-1/3">
            <label>Total Hours<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                  required
                  readOnly
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={PresentHours}
                  onChange={(date) => setPresentHours(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Present Hours"
                />
                </div>
          </div>
          <div className="space-y-2 w-1/3">
            <label>Lunch Break Starts at<span className='text-red-500 font-semibold'>*</span></label>
            <div>
              <DatePicker
                required
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 outline-none"
                selected={LunchBreak}
                onChange={(date) => {
                  setLunchBreak(date);

                  // if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                  //   setLunchBreak(date);
                  // }else{
                  //   setLunchBreak(InTime)
                  // }
                }}
                showTimeSelect
                showTimeSelectOnly
                minTime={new Date(InTime)}
                maxTime={new Date(OutTime)}
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="HH:mm"
                placeholderText="Lunch Break"
              />
            </div>
          </div>
        </div>
        </div>
        <div className="border-b border-slate-400  my-2">
        <div className="flex gap-8 my-4">
          <div className="space-y-2 mb-2 w-1/4">
            <label>Attendance Type<span className='text-red-500 font-semibold'>*</span></label>
            <Select isRequired value={AttendanceType} onChange={({target})=>{setAttendanceType(target.value)}}  borderColor="gray" placeholder="Select Days">
             {CalculationTypeList.map((data,i)=>{
               return <option key={i} value={data.attendanceTypeId}>{data.attendanceTypeName}</option>
             })}
            </Select>
          </div>

      <div className="space-y-2">
            <label>Attendance Editable?</label>
            <div>
              <Switch isChecked={AttEditable} onChange={({target})=>{setAttEditable(target.checked)}} size='md' colorScheme="green" />
            </div>
          </div>

          <div className="space-y-2 w-1/4">
            <label>Minimum Present Hours<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={MinPresentHours}
                  onChange={(date) => setMinPresentHours(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  minTime={new Date("1977-01-01T00:01:00")}
                  maxTime={new Date(PresentHours)}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Minimum Present Hours"
                />
                </div>
          </div>
          <div className="flex items-center gap-2">
            <p>Allowed Late Coming Count</p>
            <div className="w-28">
             <Input value={LateCounts} onChange={({target})=>{setLateCounts(target.value);}} type='number' borderColor='gray.400' placeholder='Enter Days' />
            </div>            
            <p>/month, after that treat as Half day</p>
          </div>
        </div>
        </div>
        
        
        <div className="border-b border-slate-400  my-2">
        <div className="flex gap-8 my-4">
          <div className="space-y-2 mb-4 w-1/4">
            <div className="flex justify-between">
              <label>Half Day Hours<span className='text-red-500 font-semibold'>*</span></label>
              <label>{ConvertToHoursMinutes(moment(HalfDayHours).format("HH:mm"))}</label>
            </div>
            <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={HalfDayHours}
                  onChange={(date) => {
                      setHalfDayHours(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  minTime={new Date("1977-01-01T00:01:00")}
                  maxTime={new Date(PresentHours)}
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Half Day Hours"
                />
                </div>
          </div>


          <div className="space-y-2 w-1/4">
            <label>Grace Time for Late Coming<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={GraceTiming}
                  onChange={(date) => {
                    setGraceTiming(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  minTime={new Date("1977-01-01T00:01:00")}
                  maxTime={new Date("1977-01-01T01:00:00")}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Grace Timing"
                />
                </div>
          </div>
          <div className="space-y-2 w-1/4">
            <label>Grace Time for Early Leaving<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={GraceOutTime}
                  onChange={(date) => {
                    setGraceOutTime(date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  minTime={new Date("1977-01-01T00:01:00")}
                  maxTime={new Date("1977-01-01T01:00:00")}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Grace Timing"
                />
                </div>
          </div>
          <div className="space-y-2 w-1/4">
            <label className="block">Grace Timing Applicable for</label>
            <Checkbox isChecked={IsGraceForWFHEmployee} onChange={({target})=>{setIsGraceForWFHEmployee(target.checked)}}  borderColor="gray">WFH Employee</Checkbox>
          </div>

        </div>
        </div>
        
      <div className="flex gap-8 my-4">

          <div className="space-y-2 w-1/4">
            <label>Break 1</label>
            <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={Break1}
                  onChange={(date) => {
                    setBreak1(date);
                    // if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                    //   setBreak1(date);
                    // }else{
                    //   setBreak1(InTime);
                    // }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={new Date(InTime)}
                  maxTime={new Date(OutTime)}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Break 1"
                />
                </div>
          </div>
          <div className="space-y-2 w-1/4">
            <label className="block">Break 2</label>
            <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={Break2}
                  onChange={(date) => {
                    setBreak2(date);
                    // if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                    //   setBreak2(date);
                    // }else{
                    //   setBreak2(InTime)
                    // }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={new Date(InTime)}
                  maxTime={new Date(OutTime)}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Break 2"
                />
                </div>
          </div>
          <div className="space-y-2">
            <label>Is Absent Settle?</label>
            <div>
              <Switch isChecked={IsAbsentSettle} onChange={({target})=>{setIsAbsentSettle(target.checked); setSettleLeaveQuota('');}} size='md' colorScheme="green" />
            </div>
          </div>
          <div className="space-y-2">
            <label>Settle With?</label>
            <Select isDisabled={!IsAbsentSettle} value={SettleLeaveQuota} onChange={({target})=>{setSettleLeaveQuota(target.value);}} borderColor='gray.400' placeholder='Select Leave Quota' >
              {LeaveQuotaList?.map((data)=>{
                return <option key={data?.leaveQuotaId} value={data?.leaveQuotaId}>{data?.leaveType}</option>
              })}
            </Select>
          </div>
        </div>



        <div className="space-x-4 text-right mt-6">
          <Button isLoading={IsPostAttSettingsLoading} loadingText="Saving..." type="submit" variant={'solid'} colorScheme={'purple'}>Save</Button>
        </div>

        </form>
      </div>
    </TabPanel>
    {/* Allow App Attendance */}
    <TabPanel px={0}>
      <div className="bg-white p-4 drop-shadow-md rounded-2xl mb-4">
        <div className="grid grid-cols-4 gap-6 items-end">
          <div className="space-y-2">
            <label>Select Employees</label>
            <ReactSelect
              menuPortalTarget={document.body} 
              styles={{
                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              options={EmployeeList?.filter(
                (obj1) => !AutoAttList?.find((obj2) => obj1.guId === obj2.empGuid)
              )}
              value={AutoAttSelEmps}
              onChange={(value) => {
                setAutoAttSelEmps(value);
              }}
              isMulti
              getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
              getOptionValue={(option) => option.guId}
              placeholder="Select Employees"
            />
          </div>
          <div>
          <Button 
            isDisabled={AutoAttSelEmps?.length === 0} 
            isLoading={IsAutoAttPostLoading} 
            loadingText="Adding Employees..." 
            onClick={async()=>{
              let body = AutoAttSelEmps?.map((data)=>{
                return {
                  empGuid: data.guId,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch,
                  isActive: true
                }
              })
              // const response = await PostAutoAttData(body);
              // if(response){
              //   setAutoAttSelEmps([]);
              //   RefetchAutoAttList();
              // }
            }} 
            leftIcon={<FiPlus />} 
            colorScheme="purple"
          >Add Employees</Button>
          </div>
        </div>
      </div>

      {IsAutoAttListLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> : <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={AutoAttList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>}
    </TabPanel>
    {/* Auto Attendance Setting */}
    <TabPanel px={0}>
      <div className="bg-white p-4 drop-shadow-md rounded-2xl mb-4">
        <div className="grid grid-cols-4 gap-6 items-end">
          <div className="space-y-2">
            <label>Select Employees</label>
            <ReactSelect
              menuPortalTarget={document.body} 
              styles={{
                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              options={EmployeeList?.filter(
                (obj1) => !AutoAttList?.find((obj2) => obj1.guId === obj2.empGuid)
              )}
              value={AutoAttSelEmps}
              onChange={(value) => {
                setAutoAttSelEmps(value);
              }}
              isMulti
              getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
              getOptionValue={(option) => option.guId}
              placeholder="Select Employees"
            />
          </div>
          <div>
          <Button 
            isDisabled={AutoAttSelEmps.length === 0} 
            isLoading={IsAutoAttPostLoading} 
            loadingText="Adding Employees..." 
            onClick={async()=>{
              let body = AutoAttSelEmps.map((data)=>{
                return {
                  empGuid: data.guId,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch,
                  isActive: true
                }
              })
              const response = await PostAutoAttData(body);
              if(response){
                setAutoAttSelEmps([]);
                RefetchAutoAttList();
              }
            }} 
            leftIcon={<FiPlus />} 
            colorScheme="purple"
          >Add Employees</Button>
          </div>
        </div>
      </div>

      {IsAutoAttListLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> : <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={AutoAttList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>}
    </TabPanel>
    {/* Overtime Setting */}
    <TabPanel px={0}>
      <OvertimeSetting/>
    </TabPanel>
    {/* <TabPanel>
      <p>Geo Fencing Settings!</p>
    </TabPanel> */}
  </TabPanels>
</Tabs>

    </div>
  );
}

export default AttendanceSettings;
