import { Avatar } from '@chakra-ui/react';
import { faChartPie, faHandHoldingDollar, faRightFromBracket, faUserLock, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../Utils/Transition';
import { useAuth } from '../Utils/AuthContext';

function UserMenu() {

  
  const { userData, signOut } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler)
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
      <Avatar name={userData.name} className='mr-3' width='10' height='10' />

        <div className="flex items-center truncate">
          <div className='text-left'>
            <h6 className='text-white font-medium'>{userData.name}</h6>
            <p className='text-gray-100 text-sm  font-light'>{userData.role}</p>
          </div>
        </div>
      </button>

        <Transition
          className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
            <div className='text-left px-3 py-2'>
              <h6 className='text-gray-800 font-medium'>{userData.name}</h6>
              <p className='text-gray-800 text-sm  font-light'>{userData.role}</p>
            </div>
              <hr />
          <ul>

          <li>
            <Link
              className="font-medium text-base hover:bg-gray-200 hover:text-indigo-600 text-indigo-500  flex items-center py-1 px-3"
              to='/dashboard'
              onClick={() => setDropdownOpen(!dropdownOpen)}
            ><FontAwesomeIcon icon={faChartPie} className='mr-2' />
              Dashboard
            </Link>
          </li>

          <li>
            <Link
              className="font-medium text-base hover:bg-gray-200 hover:text-indigo-600 text-indigo-500  flex items-center py-1 px-3"
              to='/myprofile'
              onClick={() => setDropdownOpen(!dropdownOpen)}
            ><FontAwesomeIcon icon={faUserPen} className='mr-2' />
              Edit Profile
            </Link>
          </li>

            <li>
              <Link
                className="font-medium text-base hover:bg-gray-200 hover:text-indigo-600 text-indigo-500  flex items-center py-1 px-3"
                to="/resetpassword"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              ><FontAwesomeIcon icon={faUserLock} className='mr-2' />
                Reset Password
              </Link>
            </li>


            {userData.role === "Admin" ?
            <li>
              <Link
                className="font-medium text-base hover:bg-gray-200 hover:text-indigo-600 text-indigo-500  flex items-center py-1 px-3"
                to="/plantransactions"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              ><FontAwesomeIcon icon={faHandHoldingDollar} className='mr-2' />
                Subscription
              </Link>
            </li> : ''}


            <li>
              <Link
                className="font-medium text-base hover:bg-gray-200 hover:text-indigo-600 text-indigo-500  flex items-center py-1 px-3"
                to="/"
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                  signOut();
                }}
              ><FontAwesomeIcon icon={faRightFromBracket} className='mr-2' />
                Sign Out
              </Link>
            </li>

          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default UserMenu;