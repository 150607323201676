import React, { useState } from "react";
import { Input, Button, Switch, Avatar } from "@chakra-ui/react";
import usePost from "../Utils/usePost";
import { CodeMasterSchema } from "../Utils/FormValidations";
import {useAuth} from '../Utils/AuthContext';
import { BiIdCard } from "react-icons/bi";
import useFetch from "../Utils/useFetch";

const CodeMaster = () => {
  const { userData } = useAuth();
  const { FetchedData: CodeMasterList, Refetch: RefetchCodeMasterList } = useFetch(`/api/Master/GetCodeMasterList`,{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
  });
  const { IsPostLoading: IsPostCodeMasterLoading, PostData: PostCodeMasterData } = usePost('/api/Master/SaveCodeMaster');
  const [CodeFormData, setCodeFormData] = useState({
    codemasterId: 0,
    mappingInfo: 'Employee',
    codePrefix: '',
    codeSuffix: '',
    startingnumber: '',
    isManual: false,
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  });
  const [FormErrors, setFormErrors] = useState({});

  const SaveCodeMaster = async () => {

    CodeMasterSchema.validate(CodeFormData,{abortEarly:false})
    .then(async() => {

    if(window.confirm("Are you sure you want to save this setting? Please recheck it is a one time setting you won't be able to edit later!")){
        
    let body = {
      codemasterId: CodeFormData?.codemasterId,
      mappingInfo: CodeFormData?.mappingInfo,
      codePrefix: CodeFormData?.codePrefix,
      codeSuffix: CodeFormData?.codeSuffix,
      startingnumber: Number(CodeFormData?.startingnumber || 0),
      isManual: false,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    };

    console.log(body);

    const response = await PostCodeMasterData(body);

    if(response){
      RefetchCodeMasterList();
      setCodeFormData({
        codemasterId: 0,
        mappingInfo: 'Employee',
        codePrefix: '',
        codeSuffix: '',
        startingnumber: '',
        isManual: false,
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      });
    }

    }

  }).catch((validationErrors) => {
    const newErrors = {};
    validationErrors.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setFormErrors(newErrors);
  });

  }

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Code Master</h1>
      </div>

      {CodeMasterList?.length === 0 ?
      <div className="bg-white p-4 rounded-xl drop-shadow-md">
       <div className="flex justify-between items-centerborder-b border-slate-400 pb-2 mb-4">
         <h1 className="text-xl font-bold text-gray-800">Code Details</h1>
         <div className="space-x-4">
          <label className="">Is Manual?</label>
          <Switch
            isChecked={CodeFormData?.isManual}
            onChange={async ({ target }) => {
              if(window.confirm('Are you sure you want to manually generate the employee code?')){
                setCodeFormData((prev)=>{
                  return {
                    ...prev,
                    isManual: target.checked
                  }
                })

                const response = await PostCodeMasterData({
                  ...CodeFormData,
                  isManual: true,
                  startingnumber:0
                })
                if(response){
                  RefetchCodeMasterList();
                  setCodeFormData({
                    codemasterId: 0,
                    mappingInfo: 'Employee',
                    codePrefix: '',
                    codeSuffix: '',
                    startingnumber: '',
                    isManual: false,
                    isActive: true,
                    companyId: userData.companyId,
                    branchId: userData.isActiveBranch,
                  })
                }
              }
            }}
            colorScheme={"green"}
          />
         </div>
       </div>

       <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

        <div className="space-y-2">
          <label>Prefix</label>
          <Input
            isDisabled={CodeFormData?.isManual}
            isInvalid={FormErrors?.codePrefix}
            value={CodeFormData?.codePrefix || ''}
            onChange={({ target }) => {
              setCodeFormData({
                ...CodeFormData,
                codePrefix: target.value
              })
            }}
            borderColor="gray"
            placeholder="Prefix"
          />
           {FormErrors?.codePrefix && <span className="text-red-600 my-1 text-sm">{FormErrors?.codePrefix}</span>}
        </div>

        <div className="space-y-2">
          <label>Starting Number</label>
          <Input 
            type='number'
            isDisabled={CodeFormData?.isManual}
            isInvalid={FormErrors?.startingnumber}
            value={CodeFormData?.startingnumber}
            onChange={({ target }) => {
              setCodeFormData({
                ...CodeFormData,
                startingnumber: target.value
              })
            }}
            borderColor="gray"
            placeholder="Starting Number"
          />
           {FormErrors?.startingnumber && <span className="text-red-600 my-1 text-sm">{FormErrors?.startingnumber}</span>}
        </div>

        <div className="space-y-2">
          <label>Suffix</label>
          <Input
            isDisabled={CodeFormData?.isManual}
            isInvalid={FormErrors?.codeSuffix}
            value={CodeFormData?.codeSuffix || ''}
            onChange={({ target }) => {
              setCodeFormData({
                ...CodeFormData,
                codeSuffix: target.value
              })
            }}
            borderColor="gray"
            placeholder="Suffix"
          />
          {FormErrors?.codeSuffix && <span className="text-red-600 my-1 text-sm">{FormErrors?.codeSuffix}</span>}
        </div>

       </div>
       <div className="mt-6 text-right">
         <Button onClick={SaveCodeMaster} isLoading={IsPostCodeMasterLoading} loadingText="Saving..." colorScheme="purple">
           Save
         </Button>
       </div> 
      </div>
      : 
     <div className="flex">
      {CodeMasterList?.map((data)=>{
        return  <div
        key={data.codemasterId}
        className="bg-white flex justify-between p-4 rounded-3xl shadow-lg  gap-8"
      >
        <div className="flex flex-col gap-6 justify-between">
          <p className="text-sm font-medium text-gray-500">
            Code Configuration
          </p>
          <h6 className="text-xl font-bold text-gray-800">
            {data.isManual ? "Manual Generation" : "Automatic Generation" }
          </h6>

          {!data?.isManual ? <div>
            <p className="font-medium text-sm">Code Prefix: <span>{data?.codePrefix}</span></p>
            <p className="font-medium text-sm">Code Suffix: <span>{data?.codeSuffix}</span></p>
            <p className="font-medium text-sm">Starting Number: <span>{data?.startingnumber}</span></p>
          </div> : <span>-</span>}

        </div>
        <div className="flex flex-col gap-4 justify-between items-end">
          <Avatar
            bg={"blue.100"}
            size="md"
             icon={<BiIdCard className="text-3xl text-blue-500" />}
          />
          <span className={`${data?.isActive ? "bg-green-600" : "bg-red-600"} text-white rounded-md px-1 py-0.5 text-sm font-medium`}>
            {data?.isActive ? "Active" : "InActive"}
          </span>
        </div>
      </div>
      })}

     </div> }

    </div>
  );
}

export default CodeMaster;
