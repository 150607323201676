import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import { Tabs, TabList, TabPanels, Tab, TabPanel,Button, useToast } from '@chakra-ui/react';
import moment from "moment";
import _ from "lodash";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function EmpAttendance() {

  const {userData} = useAuth();


  const toast = useToast();
  const id = "toast";

  const [MenuList, setMenuList] = useState([]);

    const [AttendanceDate, setAttendanceDate] = useState(new Date());

    const Today = new Date();

    const StartDateCurrent = moment(Today).startOf("month").format('YYYY-MM-DD');
    const EndDateCurrent = moment(Today).endOf("month").format('YYYY-MM-DD');
    console.log(StartDateCurrent,EndDateCurrent)

    const [TeamRowData, setTeamRowData] = useState([]);

    const [ELigibleforOvertime, setELigibleforOvertime] = useState(false);

    const [AttendanceDateRange, setAttendanceDateRange] = useState([new Date(StartDateCurrent),new Date(EndDateCurrent)]);

    const [AttendanceStartDate, AttendanceEndDate] = AttendanceDateRange;

    

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp Name",
      field: "empName",
      floatingFilter: false
    },
    {
      headerName: "Date",
      field: "attendanceDate",
      valueGetter:(params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
    },

    {
        headerName: "Punch In Time",
        field: "inTime",
      },
      {
        headerName: "Punch Out Time",
        field: "outTime",
      },
      {
        headerName: "Device",
        field: "linkDevice",
      },
      {
        headerName: "Status",
        field: "attendanceStatus",
        cellRenderer: (params) => 
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        P
      </span>:
       params.data.attendanceStatus === "L"?
      <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      L
    </span>
      :
       params.data.attendanceStatus === "A"?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      A
    </span> :
       params.data.attendanceStatus === "WO"?
      <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      WO
    </span> :
       params.data.attendanceStatus === "H"?
      <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      H
    </span> 
    :""

      },

      {
        headerName: "Attendance",
        field: "att_statusType",
        cellRenderer:(params)=>
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.attendanceStatus === 'A'?
        <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.att_statusType === 'Late'?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.attendanceStatus === 'L'?
        <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.att_statusType === 'HD'?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "WO"?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "H"?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>
       : ''
      },
      {
        headerName: "Remarks",
        field: "remark"
      },
  ]); 


  const [TeamColumnDefs, setTeamColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Date",
      field: "attendanceDate",
      valueGetter:(params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
    },

    {
        headerName: "Punch In Time",
        field: "inTime",
      },
      {
        headerName: "Punch Out Time",
        field: "outTime",
      },
      {
        headerName: "Device",
        field: "linkDevice",
      },
      {
        headerName: "Status",
        field: "attendanceStatus",
        cellRenderer: (params) => 
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        P
      </span>:
       params.data.attendanceStatus === "L"?
      <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      L
    </span>
      :
       params.data.attendanceStatus === "A"?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      A
    </span> :
       params.data.attendanceStatus === "WO"?
      <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      WO
    </span> :
       params.data.attendanceStatus === "H"?
      <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      H
    </span> 
    :""

      },

      {
        headerName: "Attendance",
        field: "att_statusType",
        cellRenderer:(params)=>
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.attendanceStatus === 'A'?
        <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.att_statusType === 'Late'?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.attendanceStatus === 'L'?
        <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.att_statusType === 'HD'?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "WO"?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "H"?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>
       : ''
      },
      {
        headerName: "Remarks",
        field: "remark"
      },
  ]); 
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: 'no-border',
    resizable:true
  }));

  // console.log("Month Selected Date",MonthSelect);

  const HandleAttendanceHistoryChange = (dates) => {
    const [start, end] = dates;
    console.log("Dates",dates)
    setAttendanceDateRange(dates);

    getEmpAttendance(start,end);

  };


  useEffect(() => {
    HandleSelectedRole();
    getEmpAttendance(AttendanceStartDate,AttendanceEndDate);
    GetTeamAttendance(AttendanceDate);
    GetOvertimeEmpEligibleList();
  }, [])


  const GetOvertimeEmpEligibleList = async () =>{

    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Eligible Overtime List",response.data);

      const res = response.data;


      let FoundUserEligibility = res.filter(data => data.empGuid === userData.guId).some((data)=>{
        if(data.isActive){
          return true;
        }else{
          return false;
        }
      })

      console.log("Found User Eligibility",FoundUserEligibility);

      setELigibleforOvertime(FoundUserEligibility);


    } catch (error) {
      console.error(error)
    }

  }


  const HandleSelectedRole = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData.roleId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Menu List", response);
      setMenuList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  


  const getEmpAttendance = async (FromDate,ToDate) =>{
    try {
      const response= await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetEmpFinalAttendanceByDate?EmpGuid=${userData.guId}&fromdate=${new Date(FromDate).toLocaleDateString('en-CA')}&Todate=${new Date(ToDate).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
        )
        console.log("Get Emp Attendance Response",response);
        const res = response.data;
        setRowData(_.orderBy(res,['attendanceDate'], ['desc']));
      } catch (error) {
          console.error(error);
      }
  }


  const GetTeamAttendance = async (AttendanceDate) =>{
    try {
      const response= await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetTeamFinalAttendanceView?Attendancedate=${new Date(AttendanceDate).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&EmpGuid=${userData.guId}`
        )
        console.log("Get Team Attendance Response",response);
        setTeamRowData(response.data)
     } catch (error) {
        console.error(error);
     }
  }

  return (
    <div>
              <div className="border-b items-center flex justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Attendance </h1>

  
        
        </div>


        <Tabs size={'sm'} variant={'solid-rounded'} colorScheme={'purple'}>

          {userData.role === "Admin"? 
            <TabList>
                <Tab>Attendance</Tab>
                {/* {ELigibleforOvertime === true ? 
                <Tab>Overtime</Tab> : ''} */}
                <Tab>Team Attendance</Tab>
         </TabList> :
              <TabList>

              {MenuList.filter(
              (data) =>  data.subMenuName === "View Attendance" 
            ).map((data) => {
              return data.isCreate ||
                data.isModify ||
                data.isView === true ? (
                  <Tab>Attendance</Tab>
              ) : (
                ""
              );
            })}
  
  {MenuList.filter(
              (data) => data.subMenuName === "Team Attendance" 
            ).map((data) => {
              return data.isCreate ||
                data.isModify ||
                data.isView === true ? (
                  <Tab>Team Attendance</Tab>
                  ) : (
                ""
              );
            })}
  
    </TabList> }

  <TabPanels>
    <TabPanel pt={0}>

    <div className='flex items-center justify-end mb-4'>
        <label className="font-semibold text-lg mr-3 ">Attendance Data Range :</label>
        <div>
        <DatePicker
          className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
          selectsRange={true}
          startDate={AttendanceStartDate}
          endDate={AttendanceEndDate}
          onChange={(update) => {
            HandleAttendanceHistoryChange(update);
          }}
          dateFormat="dd/MM/yyyy"
          // isClearable={true}
          placeholderText="Select Attendance Range"
        />
        </div>



        </div>

     <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>
    </TabPanel>

    {/* {ELigibleforOvertime === true ? 


    <TabPanel>
     <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={OvertimeRowData} // Row Data for Rows
          columnDefs={OvertimeColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>
    </TabPanel>
    : ""} */}

    <TabPanel pt={0}>

    <div className="flex items-center justify-end mb-4">
            <label className="font-semibold text-lg mr-3">Attendance Date :</label>
            <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {
                setAttendanceDate(date);
                GetTeamAttendance(date);
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Attendance Date'
            />
            </div>

        </div>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={TeamRowData} // Row Data for Rows
          columnDefs={TeamColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>
    </TabPanel>

  </TabPanels>
</Tabs>


    </div>
  )
}

export default EmpAttendance

