import { Avatar, Input, Button, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useAuth } from "./Utils/AuthContext";
import authApi from "./Utils/AuthApis";

function ResetPassword() {
  
  const {userData} = useAuth();
  const toast = useToast();
  const id = "toast";
  console.log("username",userData?.userName);
  const [UserName, setUserName] = useState(userData?.userName)
  const [OldPassword, setOldPassword] = useState();
  const [NewPassword, setNewPassword] = useState();
  const [ConfirmPassword, setConfirmPassword] = useState();

  const HandleResetPassword = async (e) => {

    e.preventDefault();

    let body = {
        empGuId: userData.guId,
        userName: UserName,
        oldPassword: OldPassword,
        password: NewPassword,
        confirmPassword: ConfirmPassword,
        companyId: userData.companyId,
        branchId: userData.branchId,
        isActive: true
      }

      console.log("Reset Password Body",body);

      await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/ResetPassword`,body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
          
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Password not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });

  }

  return (
    <div >

<div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Reset Password</h1>
      </div>

        <div className="flex justify-center">



      <div className="bg-white rounded-2xl p-4 drop-shadow-md md:w-1/3 ">
      <div className="flex items-center justify-center gap-6 mb-6 ">
            <div>
              <Avatar
                size={"xl"}
                name={userData.name}
                src={`http://122.176.36.172:8001/${userData.profilePicPath}`}
              />
            </div>
            <div className="text-left">
              <h6 className="text-lg font-medium">{userData.name}</h6>
              <p className="  font-normal">{userData.role}</p>
            </div>
          </div>

          <form onSubmit={HandleResetPassword}>



        <div className=" space-y-4 mx-auto w-9/12">

        <div className="space-y-2">
            <label>Username</label>
            <Input value={UserName || ''} onChange={({target})=>{setUserName(target.value)}} isReadOnly borderColor={"gray.400"} placeholder="Old Password" />
          </div>


          <div className="space-y-2">
            <label>Old Password</label>
            <Input type={'password'} value={OldPassword || ''} onChange={({target})=>{setOldPassword(target.value)}} isRequired borderColor={"gray.400"} placeholder="Old Password" />
          </div>

          <div className="space-y-2">
            <label>New Password</label>
            <Input type={'password'}  value={NewPassword || ''} onChange={({target})=>{setNewPassword(target.value)}}  isRequired borderColor={"gray.400"} placeholder="New Password" />
          </div>

          <div className="space-y-2">
            <label>Confirm Password</label>
            <Input type={'password'}  value={ConfirmPassword || ''} onChange={({target})=>{setConfirmPassword(target.value)}}  isRequired borderColor={"gray.400"} placeholder="Confirm Password" />
          </div>

          <div>
            <Button type="submit" className="w-full" colorScheme={"purple"}>
              Submit
            </Button>
          </div>
        </div>

        </form>

      </div>

        </div>

    </div>
  );
}

export default ResetPassword;
