import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
    Input,
    Button,
    Switch,useToast, Box, useDisclosure, Radio,RadioGroup,Stack
  } from "@chakra-ui/react";
  import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
  import { AgGridReact } from "ag-grid-react";
  import { Link } from "react-router-dom";
  import { Edit } from "react-feather";
  import ReactSelect from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function ExpenseAdvanceMaster() {

    const { userData } = useAuth();
    const toast = useToast();
    const id = "toast";
  
    const [IsEdit, setIsEdit] = useState(false)

    
    const {
      isOpen: isAddExpenseEligibilityOpen,
      onOpen: onAddExpenseEligibilityOpen,
      onClose: onAddExpenseEligibilityClose,
    } = useDisclosure();

    const [ShowEmployees, setShowEmployees] = useState('1');
    

    const [InActiveList, setInActiveList] = useState([]);

    const [EmpList, setEmpList] = useState([]);
    const [SelectedEmp, setSelectedEmp] = useState([]);

   const [ManagerApproval, setManagerApproval] = useState([]);
    const [HRApproval, setHRApproval] = useState([]);
    const [FinanceApproval, setFinanceApproval] = useState([]);

    const [Approval1List, setApproval1List] = useState([]);
    const [Approval2List, setApproval2List] = useState([]);
    const [Approval3List, setApproval3List] = useState([]);

    const [EligStartDate, setEligStartDate] = useState();
    const [EligEndDate, setEligEndDate] = useState();
  
    const [AdvanceMaxLimit, setAdvanceMaxLimit] = useState();
    const [ExpenseMaxLimit, setExpenseMaxLimit] = useState();
  
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Employee Code",
          field: "empCode",
        },
        {
          headerName: "Employee Name",
          field: "empName",
        },
        {
          headerName: "Advance Limit",
          field: "advanceLimit",
        },
        {
          headerName: "Expense Limit",
          field: "expenseLimit",
        },
        {
          headerName: "IsActive",
          field: "isActive",
          cellRenderer : (params) => <Switch isChecked={params.data.isActive}  onChange={({target})=>{HandleExpenseTypeSwitch(target.checked,params.data)}}  colorScheme={'green'} />
        },
        {
          headerName: "1st Reporting Person",
          field: "level1",
        },
        {
          headerName: "2nd HR",
          field: "level2",
        },
        {
          headerName: "3rd Finance",
          field: "level3",
        },
        {
          headerName: "Action",
          field: "level3",cellRenderer:(params) => <Button leftIcon={<FontAwesomeIcon icon={faEdit} />} size={'xs'} onClick={()=>{
            HandleEligibilityEdit(params.data);
          }} colorScheme={'blue'}>Edit</Button>
        }
      ]);
  
         console.log("Eligibility Data",rowData,SelectedEmp);
      const HandleEligibilityEdit = async (EligRowData) => {
        setIsEdit(true);
        setExpenseMaxLimit(EligRowData.expenseLimit);
        setAdvanceMaxLimit(EligRowData.advanceLimit);

        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Employee List",response.data);
          const res = response.data;
          let ActiveEmpList = res.filter((data)=>data.isActive === true);

          console.log("Level 1 Emp",ActiveEmpList[ActiveEmpList.findIndex(data => data.guId === EligRowData.level1GuId)],EligRowData.level1GuId);
             
          setSelectedEmp(ActiveEmpList[ActiveEmpList.findIndex(data => data.guId ===EligRowData.empGuId)])
          setManagerApproval(ActiveEmpList[ActiveEmpList.findIndex(data => data.guId ===EligRowData.level1GuId)]);
          setHRApproval(ActiveEmpList[ActiveEmpList.findIndex(data => data.guId === EligRowData.level2GuId)]);
          setFinanceApproval(ActiveEmpList[ActiveEmpList.findIndex(data => data.guId === EligRowData.level3GuId)]);
          onAddExpenseEligibilityOpen();
        } catch (error) {
          console.error(error);
        }

      }



            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              // flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border',
              resizable:true
            }));
  
  
            useEffect(()=>{
              getEmployeeList();
              GetExpenseTypeMasterList();
            },[])
          
            const getEmployeeList = async() =>{
              try {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Employee List",response.data);
                const res = response.data;
                let ActiveEmpList = res.filter((data)=>data.isActive === true);
                // setEmpList(ActiveEmpList);
                GetExpenseTypeMasterList(ActiveEmpList);
                HandleApprovalLevelList(ActiveEmpList);
              } catch (error) {
                console.error(error)
              }
            }

            const HandleApprovalLevelList = async(EmpList) => {
              try {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetMasterApprovalMatrixList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Approval Matrix List",response);
                const res = response.data;
                 console.log("second matrix",res.filter(data=>data.approvalLevel === 3).map(MatrixData => MatrixData.departmentId )[0] || 0);
                console.log("Second Approval List",EmpList.filter(data => Number(data.department) === res.filter(data=>data.approvalLevel === 2).map(MatrixData => MatrixData.departmentId )[0] || 0));
                setApproval1List(EmpList.filter(data => Number(data.department) === res.filter(data=>data.approvalLevel === 1).map(MatrixData => MatrixData.departmentId )[0] || 0));
                setApproval2List(EmpList.filter(data => Number(data.department) === res.filter(data=>data.approvalLevel === 2).map(MatrixData => MatrixData.departmentId )[0] || 0));
                setApproval3List(EmpList.filter(data => Number(data.department) === res.filter(data=>data.approvalLevel === 3).map(MatrixData => MatrixData.departmentId )[0] || 0));
              } catch (error) {
                console.error(error);
              }
            }
  
            const GetExpenseTypeMasterList = async (ActiveEmpList) => {
              try {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashTypeList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Expense Type List Response",response.data);
                const res = response.data;

                GetApprovalMatrixList(res);
                setEmpList(ActiveEmpList.filter(obj1 => !res.find(obj2 => obj1.guId === obj2.empGuId)));

              } catch (error) {
                console.error(error);
              }
            }


            const GetApprovalMatrixList = async (MasterData) => {
              try {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetEmpApprovalMatrixDetails?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Approval Matrix List",response);
                const ApprovalData = response.data;
                let FinalRowData = MasterData.map((MasterObj)=>{
                  return {...MasterObj,
                    level1GuId: ApprovalData.filter(FilterData => FilterData.empGuId === MasterObj.empGuId && FilterData.masterApprovalMatrixId === "1" ).map(data => data.masterApprovalGuId)[0] || '',
                    level1: ApprovalData.filter(FilterData => FilterData.empGuId === MasterObj.empGuId && FilterData.masterApprovalMatrixId === "1" ).map(data => data.masterApprovalName)[0] || '',
                    level2GuId: ApprovalData.filter(FilterData => FilterData.empGuId === MasterObj.empGuId && FilterData.masterApprovalMatrixId === "2" ).map(data => data.masterApprovalGuId)[0] || '',
                    level2: ApprovalData.filter(FilterData => FilterData.empGuId === MasterObj.empGuId && FilterData.masterApprovalMatrixId === "2" ).map(data => data.masterApprovalName)[0] || '',
                    level3GuId: ApprovalData.filter(FilterData => FilterData.empGuId === MasterObj.empGuId && FilterData.masterApprovalMatrixId === "3" ).map(data => data.masterApprovalGuId)[0] || '',
                    level3: ApprovalData.filter(FilterData => FilterData.empGuId === MasterObj.empGuId && FilterData.masterApprovalMatrixId === "3" ).map(data => data.masterApprovalName)[0] || ''
                  }
                })

                console.log("Final Row Data",FinalRowData);

                setInActiveList(FinalRowData.filter((data)=>data.isActive === false))
                setRowData(FinalRowData.filter((data)=>data.isActive === true));

              } catch (error) {
                console.error(error);
              }
            }

            const HandleExpenseTypeSwitch = async(checked,ExpenseData)=>{

              if (window.confirm(`${checked === true ? 'Are you sure you want to Active this Employee' : 'Are you sure you want to InActive this Employee'}`)) {

              setRowData(prev => {
                const newState =  prev.map((obj) => {
                  if (obj.pettyCashTypeId === ExpenseData.pettyCashTypeId) {
                    return {...obj, isActive: checked};
                  }
                  return obj;
                });
                return newState
              })
  
              let body ={
                pettyCashTypeId : ExpenseData.pettyCashTypeId,
                advanceLimit : ExpenseData.advanceLimit,
                expenseLimit : ExpenseData.expenseLimit,
                isActive : checked,
                companyId :ExpenseData.companyId,
                branchId : ExpenseData.branchId,
                empCode : ExpenseData.empCode,
                empGuId : ExpenseData.empGuId,
                empName : ExpenseData.empName,
              }

              let body2 ={
                empGuId : ExpenseData.empGuId,
                empName : ExpenseData.empName,
                eligibilityValidFrom : moment(EligStartDate).format('YYYY-MM-DD'),
                eligibilityValidTo : moment(EligEndDate).format('YYYY-MM-DD'),
                eligiblityActive : checked,
                companyId : userData.companyId,
                branchId : userData.isActiveBranch,
               }
  
              console.log("Switch Body",body2);
              await authApi.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Client/SavePettyCashEligibilitySetting`,body2
              )
              .then((response) => {
                console.log(response);
                const res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title:res,
                    position: "top",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }
                GetExpenseTypeMasterList();
              })
              .catch((error) => {
                console.error(error);
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "ERROR",
                    description: ` Designation not Updated`,
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });

            }
  
            }
  

            console.log("Reporting Person Guid",EmpList.filter(data => Number(data.id) === Number(SelectedEmp.reportingOfficersId)).map(fillData=> {
              return fillData.guId
            })[0]);
  
            const SaveExpenseEligibility = async (e) => {
              e.preventDefault();
              let body = {
                advanceLimit : AdvanceMaxLimit,
                expenseLimit : ExpenseMaxLimit,
                // totalLimit : Number(AdvanceMaxLimit || 0)+Number(ExpenseMaxLimit || 0),
                totalLimit : Number(ExpenseMaxLimit || 0),
                isActive : true,
                companyId : userData.companyId,
                branchId : userData.isActiveBranch,
                empCode : SelectedEmp.empCode,
                empGuId : SelectedEmp.guId,
                empName : SelectedEmp.fullName,
              }
  
              console.log("Expense Type Master Body",body);
  
              await authApi
              .post(
                `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashType`,
                body
              )
              .then((response) => {
                //  const res = response.data;
                console.log("Save Expense limit Response", response);
                // console.log("Add Reimbursement List Response ",response.data);
                // const res = response.data;
                // console.log("res",res);
              })
              .catch((error) => {
                console.log(error.response);

              });

              let body2 = {
                 empGuId : SelectedEmp.guId,
                 empName : SelectedEmp.fullName,
                 eligibilityValidFrom : moment(EligStartDate).format('YYYY-MM-DD'),
                 eligibilityValidTo : moment(EligEndDate).format('YYYY-MM-DD'),
                 eligiblityActive : true,
                 companyId : userData.companyId,
                 branchId : userData.isActiveBranch,
                }

              console.log("Eligibility body2",body2);

              await authApi
              .post(
                `${process.env.REACT_APP_ACQ_URL}/api/Client/SavePettyCashEligibilitySetting`,
                body2
              )
              .then((response) => {
                //  const res = response.data;
                console.log("Save Eligibility Response", response);
        
                // const res = response.data;
                // console.log("res",res);
                // if (!toast.isActive(id)) {
                //   toast({
                //     id,
                //     title: res,
                //     position: "top",
                //     status: "info",
                //     duration: 2000,
                //     isClosable: true,
                //   });
                // }
                // GetExpenseTypeMasterList();
  
                // setSelectedEmp([]);
                // setAdvanceMaxLimit('');
                // setExpenseMaxLimit('');
                // setEligStartDate();
                // setEligEndDate();
                // onAddExpenseEligibilityClose();

              })
              .catch((error) => {
                console.log(error.response);
                // if (!toast.isActive(id)) {
                //   toast({
                //     id,
                //     title: "ERROR",
                //     description: "Eligibility Not Added.",
                //     position: "top",
                //     status: "error",
                //     duration: 2000,
                //     isClosable: true,
                //   });
                // }
              });



              let body3 = [
                {
                   empGuId : SelectedEmp.guId,
                   masterApprovalMatrixId : 1,
                   masterApprovalGuId : ManagerApproval.guId,
                   companyId : userData.companyId,
                   branchId : userData.isActiveBranch
                },
                {
                  empGuId : SelectedEmp.guId,
                  masterApprovalMatrixId : 2,
                  masterApprovalGuId : HRApproval.guId,
                  companyId : userData.companyId,
                  branchId : userData.isActiveBranch
               },
               {
                empGuId : SelectedEmp.guId,
                masterApprovalMatrixId : 3,
                masterApprovalGuId : FinanceApproval.guId,
                companyId : userData.companyId,
                branchId : userData.isActiveBranch
             }
                ]


                console.log("Approval Matrix Body",body3);


                await authApi
                .post(
                  `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SaveEmpApprovalMatrix`,
                  body3
                )
                .then((response) => {
                  //  const res = response.data;
                  console.log("Save Matrix Response", response);
          
                  const res = response.data;
                  // console.log("res",res);
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: res,
                      position: "top",
                      status: "info",
                      duration: 2000,
                      isClosable: true,
                    });
                  }
    
  
                  GetExpenseTypeMasterList();
    
                  setSelectedEmp([]);
                  setAdvanceMaxLimit('');
                  setExpenseMaxLimit('');
                  setIsEdit(false)

                  setEligStartDate();
                  setEligEndDate();
                  onAddExpenseEligibilityClose();
  
                })
                .catch((error) => {
                  console.log(error.response);
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: "ERROR",
                      description: "Eligibility Not Added.",
                      position: "top",
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                    });
                  }
                });
  
            }



            console.log("Approvals Input",ManagerApproval.length , HRApproval.length ,FinanceApproval.length );
  
    return (
      <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
          <h1 className="text-gray-700 text-lg font-bold ">
            Expense Advance Master
          </h1>
          <div className="bg-white drop-shadow-sm flex gap-2 items-center rounded-lg py-1.5 px-3">    
          <label className="font-medium">Show :</label>
          <RadioGroup onChange={setShowEmployees} value={ShowEmployees}>
            <Stack direction='row' gap={2}>
              <Radio borderColor={'gray.400'} value='1'>Active</Radio>
              <Radio borderColor={'gray.400'} value='2'>InActive</Radio>
            </Stack>
          </RadioGroup>

        </div>
        </div>

        <Box className="bg-white rounded-2xl p-4 grid grid-cols-4 gap-8 items-end mb-4">
          <div className="space-y-2 ">
            <label>Select Employee</label>
            <ReactSelect
              options={EmpList}
              value={SelectedEmp}
              onChange={(value) => {
                setSelectedEmp(value);
              }}
              getOptionLabel={(option) =>
                `${option.fullName} ${option.empCode}`
              }
              getOptionValue={(option) => option.guId}
              placeholder="Select Employee"
            />
          </div>

          <div>
            <Button
              isDisabled={SelectedEmp.length === 0 ? true : false}
              onClick={async()=>{
                try {
                    const response = await authApi.get(
                      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                    )
                    const res = response.data;
                    let ActiveEmpList = res.filter((data)=>data.isActive === true);
             
                    setManagerApproval(ActiveEmpList[ActiveEmpList.findIndex(data => Number(data.id) === Number(SelectedEmp.reportingOfficersId))] || []);
                    onAddExpenseEligibilityOpen();
                } catch (error) {
                  console.error(error);
                }
              }}
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              colorScheme={"purple"}
            >
              Add Expense Eligibility
            </Button>
          </div>
        </Box>

        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={ShowEmployees === '1' ? rowData : InActiveList} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>

        <Modal
          size="3xl"
          isOpen={isAddExpenseEligibilityOpen}
          onClose={()=>{
            setSelectedEmp([]);
            setAdvanceMaxLimit('');
            setExpenseMaxLimit('');
            setManagerApproval([]);
            setHRApproval([]);
            setFinanceApproval([]);
            setIsEdit(false)
            onAddExpenseEligibilityClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Expense Eligibility</ModalHeader>
            <ModalCloseButton />
            <hr />
            <form onSubmit={SaveExpenseEligibility}>
              <ModalBody>
                <div className="flex py-2 gap-8">
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Employee Name</p>
                    <h6 className="font-medium">{SelectedEmp.fullName || SelectedEmp.empName}</h6>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Employee Code</p>
                    <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                  </div>

                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Designation</p>
                    <h6 className="font-medium">
                      {SelectedEmp.designationName}
                    </h6>
                  </div>

                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Department</p>
                    <h6 className="font-medium">
                      {SelectedEmp.departmentName}
                    </h6>
                  </div> 

                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Total Limit</p>
                    <h6 className="font-medium">
                      {Number(AdvanceMaxLimit || 0)+Number(ExpenseMaxLimit || 0)}
                    </h6>
                  </div>
                </div>
                <hr />

                <div className="grid grid-cols-2 gap-4 my-2">
                  {/* <div className="space-y-2">
                    <label>Start Date</label>
                    <DatePicker
                      required
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={EligStartDate}
                      onChange={(date) => setEligStartDate(date)}
                      placeholderText="DD-MM-YYYY"
                      dateFormat={"dd-MM-yyyy"}
                    />
                  </div>

                  <div className="space-y-2">
                    <label>End Date</label>
                    <DatePicker
                      required
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={EligEndDate}
                      onChange={(date) => setEligEndDate(date)}
                      placeholderText="DD-MM-YYYY"
                      dateFormat={"dd-MM-yyyy"}
                    />
                  </div> */}

                  <div className="space-y-2">
                    <label>Expense Max Limit</label>
                    <Input
                     isReadOnly={IsEdit}
                      isRequired
                      type={"number"}
                      value={ExpenseMaxLimit || ""}
                      onChange={({ target }) => {
                        setExpenseMaxLimit(target.value);
                      }}
                      placeholder="Expense Max Limit"
                      borderColor={"gray.400"}
                    />
                  </div>


                  <div className="space-y-2">
                    <label>Advance Max Limit</label>
                    <Input
                     isReadOnly={IsEdit}
                      isRequired
                      type={"number"}
                      value={AdvanceMaxLimit || ""}
                      onChange={({ target }) => {
                        let AdvanceAmount = target.value 

                        if (Number(AdvanceAmount) <= Number(ExpenseMaxLimit)) {
                          setAdvanceMaxLimit(AdvanceAmount);
                        }else{
                          setAdvanceMaxLimit(ExpenseMaxLimit)
                        }

                      }}
                      placeholder="Advance Max Limit"
                      borderColor={"gray.400"}
                    />
                  </div>



                  <div className="space-y-2 ">
                    <label>Reporting Officer</label>
                    {/* <Input readOnly value={SelectedEmp.reportingOfficerName} borderColor={'gray.400'} placeholder="Reporting Officer" /> */}
                    <ReactSelect
                      options={EmpList}
                       value={ManagerApproval}
                       onChange={(value) => {setManagerApproval(value);}}
                      getOptionLabel={(option) => option.fullName}
                      getOptionValue={(option) => option.id}
                      placeholder="Select Reporting Officer"
                    />
                  </div>

                  <div className="space-y-2 ">
                    <label>Select HR</label>
                    <ReactSelect
                      options={Approval2List}
                       value={HRApproval}
                       onChange={(value) => {setHRApproval(value);}}
                      getOptionLabel={(option) => option.fullName}
                      getOptionValue={(option) => option.id}
                      placeholder="Select HR"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Select Finance Person</label>
                    <ReactSelect
                      options={Approval3List}
                       value={FinanceApproval}
                       onChange={(value) => {setFinanceApproval(value);}}
                      getOptionLabel={(option) => option.fullName}
                      getOptionValue={(option) => option.id}
                      placeholder="Select Finance Person"
                    />
                  </div>
                </div>
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button isDisabled={(ManagerApproval.length === undefined) && (HRApproval.length === undefined) && (FinanceApproval.length === undefined) ? false : true} variant="solid" colorScheme="purple" type="submit">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={()=>{
                     onAddExpenseEligibilityClose();
                      setSelectedEmp([]);
                      setAdvanceMaxLimit('');
                      setExpenseMaxLimit('');
                      setManagerApproval([]);
                      setHRApproval([]);
                      setIsEdit(false)

                      setFinanceApproval([]);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </div>
    );
  }

export default ExpenseAdvanceMaster