import React,{useRef, useState, useMemo, useCallback, useEffect} from 'react'
import { AgGridReact } from "ag-grid-react";
import { useDisclosure, Button, Modal,ModalBody,ModalContent,ModalFooter,ModalHeader,ModalOverlay,ModalCloseButton } from '@chakra-ui/react';
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';
import usePost from '../Utils/usePost';
import useFetch from '../Utils/useFetch';
import { Eye, PlusCircle } from 'react-feather';
import { View, Text, Document, Page, StyleSheet, Image, PDFViewer} from '@react-pdf/renderer';
import useValidation from '../Utils/useValidation';

const styles = StyleSheet.create({
  page: {
      fontFamily: 'Helvetica',
      paddingTop: 35,
      paddingLeft:25,
      paddingRight:25,
      lineHeight: 1.5,
      flexDirection: 'column',
  }, 
  imgLogo:{
      height:80
  },
  bodyFont:{
    fontSize:9
  },
  headings:{
    fontSize:11,
    fontWeight:'extrabold',
    fontFamily:'Helvetica-Bold'
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    marginBottom:10
    // borderWidth: 1,
    // borderColor: '#ececec',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderWidth: 1,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
  mbFive:{
    marginBottom:6
  }
});

function AllocateAsset()  {

    const { userData, HandleSessionModal } = useAuth();

    const { FetchedData: FetchedAssetsListData, Refetch: RefetchAssetsList } = useFetch('/api/Assets/GetAssetsMasterList',{
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    });
    const { FetchedData: FetchedEmpListData, Refetch: RefetchEmpList } = useFetch('/api/Assets/EmpGenralInfoAssetsList',{
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    });

    const { PostData: PostAllocateAssetData } = usePost('/api/Assets/SaveEmployeeAllocateAssetslist');
    const { PostData: PostFormStatusData } = usePost('/api/Assets/SaveEmpAssetFormStatus');
  
    const { isOpen: isViewEmpAssetsOpen, onOpen: onViewEmpAssetsOpen, onClose: onViewEmpAssetsClose } = useDisclosure();
    const {
      isOpen: isViewAssetFormOpen,
      onOpen: onViewAssetFormOpen,
      onClose: onViewAssetFormClose,
    } = useDisclosure();

    const [BranchLogo, setBranchLogo] = useState();

    const gridRef = useRef();
    const AssetListGridRef = useRef();
    const [SelectedEmp, setSelectedEmp] = useState([]);
    const [ViewEmpAssetsRowData, setViewEmpAssetsRowData] = useState([]);
  
  
    const [AssetsListcolumnDefs] = useState([
      { 
        headerName: "Asset Name", 
        field: "assetsName",
      },
      { 
        headerName: "Serial No.", 
        field: "assetsSerialNo" 
      },
      { 
        headerName: "Model No.", 
        field: "assetsModelNo" 
      },
      { 
        headerName: "Asset Type", 
        field: "assetsTypeName" 
      },
      { 
        headerName: "Asset Status",  
        field: "isAllocate", 
        cellRenderer: ({data}) => 
        data.isAllocate === false ? 
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            Available
          </span> : 
        data.isAllocate === true ? 
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
           Not Available
          </span> : ''
      },
      {
        headerName: "Action",
        cellRenderer: ({data})=> !data.isAllocate && <Button 
          size='xs'
          colorScheme="blue"
          onClick={async()=>{
            let body = [{
              ...data,
              isAllocate: true,
            }]
            console.log("Allocate Body",body);
            const response = await PostAllocateAssetData(body);
      
            if(response){
              HandleViewEmpAssets(data.empGuId);
              RefetchAssetsList();
              RefetchEmpList();
            }
        
          }}
         >Allocate</Button>
      }
    ]);
  
    const [ViewEmpAssetsColumnDefs] = useState([
      { headerName: "Asset Name", field: "assetsName" },
      { headerName: "Serial No.", field: "assetsSerialNo" },
      { headerName: "Model No.", field: "assetsModelNo" },
      { headerName: "Asset Type", field: "assetsTypeName" },
      { 
         headerName: "Remarks", 
         field: "returnComment" ,
         editable:true, 
         singleClickEdit: true,
         cellClass:'editable-grid-cell'
      },
      {
        headerName: "Action",
        cellRenderer: ({data})=> <Button 
          size='xs'
          colorScheme="blue"
          onClick={async()=>{
           // console.log("Return comment",data?.returnComment?.length);

           if((data?.returnComment?.length < 1) || (data?.returnComment?.length === undefined)){
            alert('Please Enter Remark!!');
           }else{
            let body = [{
              ...data,
              isAllocate: false,
              isReturn: true
            }]
            console.log("Deallocate Body",body);
            const response = await PostAllocateAssetData(body);
            if(response){
              HandleViewEmpAssets(data.empGuId);
              RefetchAssetsList();
              RefetchEmpList();
            }
           }
          }}
         >Deallocate</Button>
      }
    ]);
  

  
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  
    const [columnDefs] = useState([
      { headerName: "Emp Code", field: "empCode" },
      { headerName: "Emp Name", field: "empName" },
      { headerName: "Designation", field: "designationName" },
      { headerName: "Department", field: "departmentName" },
      {
        headerName: "Allocate Status",
        field: "assetsAllocateStatus",
        cellRenderer: (params) =>
          params.data.assetsAllocateStatus ===  "Allocated" ? (
            <span className="bg-green-500  text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Allocated
            </span>
           ) : (
          <span className="bg-red-500 text-white  rounded-md px-1 py-0.5 text-sm font-medium">
            Not Allocated
          </span>
        ),
      },    
      {
        headerName: "Form Status",
        field: "isAssetFormSigned",
        cellRenderer: ({data}) => data.isAssetFormSigned ? (
            <span className="bg-green-500  text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Signed
            </span>
           ) : (
          <span className="bg-red-500 text-white  rounded-md px-1 py-0.5 text-sm font-medium">
            Not Signed
          </span>
        ),
      },  
      {
        headerName: "Action",
        field: "assetsAllocateStatus",
        width: 300,
        pinned:'right',
        cellRenderer: ({data}) => data.assetsAllocateStatus ===  "Not Allocated" ? (
            <Button 
             leftIcon={<PlusCircle size={'14px'} />}
             onClick={()=>{
                setSelectedEmp(()=>{
                  return {
                    ...data
                  }
                });
                HandleViewEmpAssets(data.empGuId,"View Assets");
              }} 
             size='xs' 
             colorScheme='blue'
            >Allocate Assets</Button>
          ) : (
            <div className='space-x-4'>
              <Button leftIcon={<Eye size={'14px'} />} variant='solid' onClick={()=>{setSelectedEmp(data); HandleViewEmpAssets(data.empGuId,"View Assets");}} size='xs' colorScheme='purple'>View</Button>
              <Button size='xs' variant='outline' colorScheme='blue' onClick={()=>{setSelectedEmp(data); HandleViewEmpAssets(data.empGuId,"View Form");}}>View Asset Form</Button>
              <Button 
                size='xs' 
                variant='outline' 
                colorScheme='blue' 
                onClick={async () => {

                  let body = {
                    empGuid: data?.empGuId,
                    IsAssetFormSigned: !data?.isAssetFormSigned,
                    companyId: userData?.companyId,
                    branchId: userData?.isActiveBranch
                  }

                  console.log("Form Status Body", body);

                  const response = await PostFormStatusData(body);

                  console.log("Form Status Response",response);

                  if(response){
                    RefetchEmpList();
                  }
                }}
              >{data.isAssetFormSigned ? "Unsigned" : "Signed"}</Button>
            </div>
          ),
      },
    ]);
  
    console.log("Selected Emp", SelectedEmp);

  
    const HandleViewEmpAssets = async(EmpGuid,Action) => {
        try {
          const response = await authApi.get(
           `${process.env.REACT_APP_ACQ_URL}/api/Assets/ViewAssetsAllocatelist?EmpGuId=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
           console.log("Employee Assets",response);
  
           const res = response.data;
  
           setViewEmpAssetsRowData(res);
           if(Action === "View Assets"){
             onViewEmpAssetsOpen();
           } else if(Action === "View Form"){
             onViewAssetFormOpen();
           }
          } catch (err) {
              console.error(err);
              if(err.response){
                if(err.response.statusText === "Unauthorized" || err.response.status === 401){
                    HandleSessionModal();
                }
              }
          }
    }

    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      resizable: true
    }));

    useEffect(()=>{
      GetBranchDetails();
    },[])

    
    const GetBranchDetails = async () => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/GetBranchMasterById?branchId=${userData.isActiveBranch}`
        );
        console.log("Branch Details", response.data);
        const res = response.data;
      
        handleDownloadFile(res.logoPath)
      } catch (err) {
        console.error(err);
      }
    }
  
  
    const handleDownloadFile = async (DocPath) =>{
      console.log("Document path",DocPath)
     await fetch(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
    ).then(response => {
  
      console.log("file Response",response);
  
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
  
          // Setting various property values
  
          let alink = document.createElement('a');
          alink.href = fileURL;
          //  alink.download = fileURL;
           setBranchLogo(alink.href)
  
           // alink.click();
      })
  
    }).catch((err)=>{
      console.log("File Api Error",err);
    })
    }

    const EmpAssetForm =  () => {
      return  <Document>
       <Page size="A4"  style={styles.page}>
         <View style={{flexDirection:"row", justifyContent:"space-between", paddingBottom:10, borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
           <Image style={styles.imgLogo} src={BranchLogo} />
           <View>
               <Text style={{marginBottom:2, fontSize:13, fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>{userData.companyName}</Text>
               <Text style={{fontSize:9, fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
               <Text style={{fontSize:9, fontWeight:'thin'}}>{userData.stateName} {userData.pincode}</Text>
           </View>
         </View>

         <View style={{flexDirection:"row", justifyContent: 'center', marginVertical:'5px'}}>
           <Text style={{textDecoration:'underline', fontSize:14, fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Assets Receiving Form</Text>
         </View>

       {/* Employee Details */}

       <View style={{flexDirection:"row", justifyContent:"space-between" ,paddingTop:10,paddingBottom:10 }}>
         <View style={{flexGrow:1}}>
          <Text style={{marginBottom:5, fontSize:10,}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Employee Name:</Text> {SelectedEmp?.empName}</Text>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Designation:</Text> {SelectedEmp?.designationName}</Text>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Pan Card No:</Text> </Text>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Handover Date:</Text></Text>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Contact No:</Text> {SelectedEmp?.contactNo}</Text>
          <Text style={{fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Present Address:</Text> {SelectedEmp?.permanentAddress}</Text>
         </View>
 
         <View style={{flexGrow:1}}>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Employee Code:</Text> {SelectedEmp?.empCode}</Text>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Department:</Text> {SelectedEmp?.departmentName}</Text>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Personal Email:</Text> {SelectedEmp?.personalEmail}</Text>
          <Text style={{marginBottom:5, fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Return Handover Date:</Text></Text>
          <Text style={{fontSize:10}}><Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>Session:</Text> {SelectedEmp?.currentSession}</Text>
         </View>
       </View>

       <Text style={[styles.bodyFont]}>Dear,</Text>
       <Text style={[{fontSize:10,marginBottom:'5px'}]}>We congratulate you for joining <Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}} >{userData.companyName}</Text> Please find below the assets handed over to you, to support you in carrying out your assignment in a most proficient manner. Please return this duly filled form to <Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>support@acquara.com</Text> and <Text style={{fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}}>human.resources@acquara.com.</Text></Text>

       <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <View style={{borderRight:1,width:'7%'}}>
            <Text style={[styles.tableCell,styles.headings]}>S No.</Text>
          </View>
          <View style={{borderRight:1,width:'15%'}}>
            <Text style={[styles.tableCell,styles.headings]}>Particulars</Text>
          </View>
          <View style={{borderRight:1,width:'60%'}}>
            <Text style={[styles.tableCell,styles.headings]}>Model No.</Text>
          </View>
          <View style={{width:'18%'}}>
            <Text style={[styles.tableCell,styles.headings]}>Remarks</Text>
          </View>
        </View>

        {ViewEmpAssetsRowData?.map((data,i)=>{
          return <View key={i} style={styles.tableRow}>
                   <View style={{borderRight:1,width:'7%'}}>
                     <Text style={styles.tableCell}>{i+1}</Text>
                   </View>
                   <View style={{borderRight:1,width:'15%'}}>
                     <Text style={styles.tableCell}>{data?.assetsTypeName}</Text>
                   </View>
                   <View style={{borderRight:1,width:'60%'}}>
                     <Text style={styles.tableCell}>{data.assetsName} / {data?.assetsModelNo} {data.assetsSerialNo ? '/' : ''} {data.assetsSerialNo}</Text>
                   </View>
                   <View style={{width:'18%'}}>
                     <Text style={styles.tableCell}></Text>
                   </View>
                 </View>
        })}

      </View>

       <Text style={[styles.bodyFont,styles.mbFive]}>Acknowledgment and declaration by Employee: <Text style={{textDecoration:'underline',fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}} >{SelectedEmp?.empName}</Text></Text>
       <Text style={[styles.bodyFont,styles.mbFive]}>I, <Text style={{textDecoration:'underline',fontWeight:'extrabold', fontFamily:'Helvetica-Bold'}} >{SelectedEmp?.empName}</Text> hereby declare that the assets received are in good condition and belong to the company name and are under my possession for carrying out my office work only. I hereby assure you that I will take care of the assets of the company to the best possible extent. Negligence in the care and use will be considered cause for disciplinary action or salary deduction for repairing the asset.</Text>
       <Text style={[styles.bodyFont,styles.mbFive]}>I also understand that the company assets must be returned to Acquara at the time of my separation from employment or when my manager or supervisor requests it and that I will be charged for any property issued and not returned to the Company.</Text>
       <Text style={[styles.headings,styles.mbFive]}>Important:</Text>
       <Text style={[styles.bodyFont,styles.mbFive]}>1.	The IT Support representative has communicated and fully explained how to use my hardware.</Text>
       <Text style={[styles.bodyFont,styles.mbFive]}>2.	By signing this form, I confirm that all listed items have been received in satisfactory condition, and I accept loss or damage, beyond natural wear and tear will be payable by me through the payroll function.</Text>
       <Text style={[styles.bodyFont,styles.mbFive]}>3.	Upon leaving the organization, I understand that my final payment will not be processed until all assets listed have been reviewed by Acquara IT Team and feedback provided to the payroll team.</Text>
       <Text style={[styles.headings,styles.mbFive]}>Day/Date:</Text>
       <Text style={[styles.headings,styles.mbFive]}>Employee Signature:</Text>
       
       </Page>
     </Document>
   }
  

    return (
      <div>
        <div className="flex items-center justify-between  border-b border-gray-400 mb-4 pb-2 text-left">
          <h1 className="text-gray-700 text-xl font-bold ">Allocate Assets</h1>
        </div>
  
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={FetchedEmpListData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>
  
        <Modal size="full" isOpen={isViewEmpAssetsOpen} onClose={onViewEmpAssetsClose}>
          <ModalOverlay />
          <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.800"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >
            Assets
          </ModalHeader>
          <ModalCloseButton mt={1} />
            <ModalBody>
            <div className="grid grid-cols-2 gap-4 border-b pb-4">
                
                <div className="space-y-2">
                  <h6 className="text-lg font-semibold">
                    Employee Name:- <span className="font-normal">{SelectedEmp.empName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Employee Code:- <span className="font-normal">{SelectedEmp.empCode}</span>
                  </h6>
                </div>
  
                <div className="space-y-2">
                  <h6 className="text-lg font-semibold">
                    Designation:- <span className="font-normal">{SelectedEmp.designationName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Department:- <span className="font-normal">{SelectedEmp.departmentName}</span>
                  </h6>
                </div>
  
              </div>

              <div className='grid grid-cols-2 gap-6 mt-4'>

                <div>
                <div className="flex items-center justify-between  border-b border-gray-400 mb-4 pb-2 text-left">
                <h1 className="text-gray-700 text-xl font-bold ">Allocated Assets</h1>
              </div>
              <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  rowData={ViewEmpAssetsRowData?.map((prev)=>{
                    return {
                      ...prev,
                      empGuId: SelectedEmp?.empGuId,
                      empCode: SelectedEmp?.empCode,
                      empName: SelectedEmp?.empName
                    }
                  })} // Row Data for Rows
                  columnDefs={ViewEmpAssetsColumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  rowSelection={"multiple"}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                />
              </div>
                </div>

                <div >
                <div className="flex items-center justify-between  border-b border-gray-400 mb-4 pb-2 text-left">
                <h1 className="text-gray-700 text-xl font-bold ">Assets List</h1>
              </div>
              <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={AssetListGridRef} // Ref for accessing Grid's API
                  rowData={FetchedAssetsListData.filter(data => !data.isAllocate )?.map((prev)=>{
                    return {
                      ...prev,
                      empGuId: SelectedEmp?.empGuId,
                      empCode: SelectedEmp?.empCode,
                      empName: SelectedEmp?.empName
                    }
                  })} // Row Data for Rows
                  columnDefs={AssetsListcolumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                />
              </div>

                </div>
            
            </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                onClick={onViewEmpAssetsClose}
                colorScheme="purple"
                variant="outline"
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


        <Modal scrollBehavior='inside' size="full" isOpen={isViewAssetFormOpen} onClose={onViewAssetFormClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
        <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>View Asset Form</ModalHeader>
        <ModalCloseButton mt={1.5}/>
            <ModalBody className="my-4">
             <PDFViewer width="100%" className="h-screen my-10">
               <EmpAssetForm />
             </PDFViewer>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                onClick={onViewAssetFormClose}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
  
      </div>
    )
  }

export default AllocateAsset