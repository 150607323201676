import React, { useCallback, useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button,useDisclosure,Modal,ModalBody,ModalCloseButton,ModalOverlay,ModalContent,ModalFooter,ModalHeader} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import authApi from "../Utils/AuthApis";
import { useAuth } from "../Utils/AuthContext";
import usePost from "../Utils/usePost";

function FnfAssetRecovery() {

    const {userData} = useAuth();

    const { PostData: PostAllocateAssetData } = usePost('/api/Assets/SaveEmployeeAllocateAssetslist');

    const { isOpen: isExitProcessOpen, onOpen: onExitProcessOpen, onClose: onExitProcessClose } = useDisclosure();

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); 

    const [FnfEmpRowData, setFnfEmpRowData] = useState([]);

    const [FNFSelectedEmp, setFNFSelectedEmp] = useState([]);
    const [ExitDetailsSelectedEMP, setExitDetailsSelectedEMP] = useState([]);
    const [EmpAssetsRowData, setEmpAssetsRowData] = useState([]);


    const [FNFColumnDefs, setFNFColumnDefs] = useState([
        { headerName: "Employee Name", field: "empName" },
        { headerName: "Resignation Date", field: "resignationDate",valueGetter: (params) => {
          return new Date(params.data.resignationDate).toLocaleDateString('en-GB')
        } },
        { headerName: "Last Working Date", field: "lastWorkingDate",valueGetter: (params) => {
          return new Date(params.data.lastWorkingDate).toLocaleDateString('en-GB')
        } },
        { headerName: "Separation Status", field: "resignationStatus",
        // cellRenderer:(params) => (
        // params.data.resignationStatus === 'Pending' ?
        // <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
        //   Pending
        // </span> : params.data.resignationStatus === 'Approved' ? 
        // <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
        //   Approved
        // </span> :  params.data.resignationStatus === 'Rejected' ? 
        // <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
        // Rejected
        // </span> : params.data.resignationStatus === 'Cancelled' ? 
        // <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
        // Cancelled
        // </span> : params.data.resignationStatus === 'Finalized' ? 
        // <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
        // Completed
        // </span> : ''
        // )
     },

     { headerName: "Recoverey Status", field: "IsRecovered",
     cellRenderer:(params) => (
     params.data.IsRecovered === false ?
     <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
       Pending
     </span> : params.data.IsRecovered === true ? 
     <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
       Recovered
     </span> : ''
     )
  },
             { headerName : "Action",cellRenderer:(params)=><Button onClick={()=>{HandleFNFSettlement(params.data.empGuId)}} variant={'solid'} size={'xs'} colorScheme={'blue'}>View</Button>}

      ]);


      const [AssetRecoveryColumnDefs, setAssetRecoveryColumnDefs] = useState([
        { headerName: "Asset Name", field: "assetsName" },
        { headerName: "Asset Type", field: "assetsTypeName" },
        { headerName: "Assigned On", field: "assetsIssueDate",valueGetter: (params) => {
          return new Date(params.data.assetsIssueDate).toLocaleDateString('en-GB')
        }
        },
        { headerName: "Comment", field: "recoveryComment", editable:true, singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
           values: ['Recovered in Good Condition', 'Recovered in Damaged','Retained'],
        } ,cellClass:'editable-grid-cell' },
        { headerName: "Amount", field: "recoveryAmount",editable:true,singleClickEdit:true ,cellClass:'editable-grid-cell' },
        { headerName: "Remarks", field: "assetsAllocateRemark", editable:true, singleClickEdit: true,cellClass:'editable-grid-cell' },
        { headerName: "Action", cellRenderer:(params) => 
        params.data.isRecovery === false ?
        <Button size='xs' 
        onClick={async()=>{
          let AssetData = params.data;

          let body = [{
            assetsTypeId:  AssetData.assetsTypeId,
            assetsTypeName:  AssetData.assetsTypeName,
            assetsMasterId:  AssetData.assetsMasterId,
            assetsName:  AssetData.assetsName,
            assetsSerialNo:  AssetData.assetsSerialNo,
            assetsModelNo:  AssetData.assetsModelNo,
            assetsPurchaseDate:  AssetData.assetsPurchaseDate,
            assetsWarrentyPeriod:  AssetData.assetsWarrentyPeriod,
            assetsWarrentyExpiryDate:  AssetData.assetsWarrentyExpiryDate,
            assetsRemark: AssetData.assetsRemark,
            isActive:  AssetData.isActive,
            createdBy:  AssetData.createdBy,
            createdOn: AssetData.createdOn,
            modifiedBy: AssetData.modifiedBy,
            modifiedOn:  AssetData.modifiedOn,
            companyId:  AssetData.companyId,
            branchId:  AssetData.branchId,
            empAllocateAssetsId:  AssetData.empAllocateAssetsId,
            empName:  AssetData.empName,
            empCode:  AssetData.empCode,
            empGuId:  AssetData.empGuId,
            assetsDescription:  AssetData.assetsDescription,
            assetsIssueDate:  AssetData.assetsIssueDate,
            assetsAllocateRemark:  AssetData.assetsAllocateRemark,
            isDamage:  AssetData.isDamage,
            damageDate:  AssetData.damageDate,
            isAllocate:  false,
            isRecovery:  true,
            recoveryAmount:  AssetData.recoveryAmount,
            recoveryComment:  AssetData.recoveryComment,
            recoveryDate: moment().format('YYYY-MM-DD'),
            isReturn:  AssetData.isReturn,
            returnDate:  AssetData.returnDate,
            returnComment:  AssetData.returnComment,
        }]

        console.log("Asset Recovery Body",body);

        const response = await PostAllocateAssetData(body);

        if(response){
          HandleFNFSettlement(AssetData.empGuId);
        }

        }} colorScheme='blue' >Save</Button>  : ''
},
      ]);


      
      const HandleFNFSettlement = async (EMPGUID) => {
        console.log(EMPGUID);
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeGeneralInfoByGuid?EmpGuid=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`,
          )
          console.log("Handle Fnf Settlement EMP Response",response);
          let res = response.data;
          setFNFSelectedEmp(()=> res);
          console.log(FNFSelectedEmp);
        } catch (error) {
          console.error(error);
        } 
    
        try {
          const ExitDetailsResponse = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpResignationByGUId?EmpGUID=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`,
          )
          console.log("EMP Resgination Details FNF Response",ExitDetailsResponse);
          setExitDetailsSelectedEMP(ExitDetailsResponse.data);
    

        } catch (error) {
          console.error(error);
        }




        try {
          const EmpAssetsResponse = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Assets/ViewAssetsAllocatelist?EmpGuId=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
          console.log("Emp Assets Response",EmpAssetsResponse);
          setEmpAssetsRowData(EmpAssetsResponse.data)
        } catch (error) {
          console.error(error);
        }


        onExitProcessOpen();

    
      }

      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
      }));

      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);


      useEffect(() => {
        getResignationListofMonth();
      }, [])

      const getResignationListofMonth = async () =>{
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpResignationList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
          console.log("Resignation List of Month", response);
          const AllResignation = response.data;          
          let ApprovedResign = AllResignation.filter((data)=>{
            return data.resignationStatus === "Approved" 
          })
          console.log("Approved Resignations",ApprovedResign);

          let OrderedData = _.orderBy(ApprovedResign,['resignationId'],['desc'])
          
        //   setFnfEmpRowData(_.orderBy(ApprovedResign,['resignationId'],['desc']));

          HandleRowData(OrderedData)
        } catch (error) {
          console.error(error);
        }
      };



      const HandleRowData = (AllEmployeeData) =>{
        Promise.all(
            AllEmployeeData.map(
              async (data) => {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/Assets/ViewAssetsAllocatelist?EmpGuId=${data.empGuId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                );
  
                console.log(`${data.empName} Assets Response`,response);

                const EmpAssets = response.data;
              console.log("Check Lock",{...data,IsRecovered : EmpAssets.every(LockData => LockData.isRecovery === true)});
  
                return setFnfEmpRowData((prev)=>{
                  return [...prev,{...data,IsRecovered : EmpAssets.every(LockData => LockData.isRecovery === true)}]
                })
              }
            )
          );

      }



  return (
    <div>
      <div className="border-b border-gray-400 mb-3 pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">FNF Asset Submission</h1>
      </div>

      <div className="ag-theme-alpine mt-2">
         <AgGridReact
           style={gridStyle}
           domLayout={"autoHeight"}
           rowData={FnfEmpRowData} // Row Data for Rows
           columnDefs={FNFColumnDefs} // Column Defs for Columns
           defaultColDef={defaultColDef} // Default Column Properties
           animateRows={true} // Optional - set to 'true' to have rows animate when sorted
           pagination={true}
           paginationPageSize={15}
           paginationNumberFormatter={paginationNumberFormatter}
         />
       </div>


       <Modal
        size="full"
        isOpen={isExitProcessOpen}
        onClose={onExitProcessClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="gray.400">Assets Recovery</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
          <div className="pt-4">

<div className="flex pb-1 gap-8 mb-8">
        <div className="space-y-1">
          <p className="text-sm text-gray-500">
            Employee Name
          </p>
          <h6 className="font-medium">{FNFSelectedEmp.firstName} {FNFSelectedEmp.lastName}</h6>
        </div>

        <div className="space-y-1">
        <p className="text-sm text-gray-500">Employee Code</p>
          <h6 className="font-medium">{FNFSelectedEmp.empCode}</h6>
        </div>

        <div className="space-y-1">
          <p className="text-sm text-gray-500">Department</p>
          <h6 className="font-medium">{FNFSelectedEmp.departmentName}</h6>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">Designation</p>
          <h6 className="font-medium">{FNFSelectedEmp.designationName}</h6>
        </div>

        <div className="space-y-1">
          <p className="text-sm text-gray-500">Date of Joining</p>
          <h6 className="font-medium">{new Date(FNFSelectedEmp.dateofjoin).toLocaleDateString('en-GB')}</h6>
        </div>

        <div className="space-y-1">
          <p className="text-sm text-gray-500">Resignation Date</p>
          <h6 className="font-medium">{new Date(ExitDetailsSelectedEMP.resignationDate).toLocaleDateString('en-GB') }</h6>
        </div>

        <div className="space-y-1">
          <p className="text-sm text-gray-500">Last Working Date</p>
          <h6 className="font-medium">{new Date(ExitDetailsSelectedEMP.lastWorkingDate).toLocaleDateString('en-GB') }</h6>
        </div>

      </div>

      <div className="ag-theme-alpine-dark">
    <AgGridReact
      style={gridStyle}
      domLayout={"autoHeight"}
      rowData={EmpAssetsRowData} // Row Data for Rows
      columnDefs={AssetRecoveryColumnDefs} // Column Defs for Columns
      defaultColDef={defaultColDef} // Default Column Properties
      animateRows={true} // Optional - set to 'true' to have rows animate when sorted
      pagination={true}
      rowSelection={"multiple"}
      paginationPageSize={10}
      paginationNumberFormatter={paginationNumberFormatter}
    />
  </div>
</div>
          </ModalBody>
          <hr />
          <ModalFooter>

          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default FnfAssetRecovery