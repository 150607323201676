import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import AttendanceDated from "./Attendance/AttendanceDated";
import HolidayMaster from "./Attendance/HolidayMaster";
import WeeklyOffMaster from "./Attendance/WeeklyOffMaster";
import Dashboard from "./Dashboard";
import CreateEmployee from "./Employees/CreateEmployee";
import EditEmployee from "./Employees/EditEmployee";
import EmployeeDetails from "./Employees/EmployeeDetails";
import BankMaster from "./Masters/BankMaster";
import CodeMaster from "./Masters/CodeMaster";
import CurrencyMaster from "./Masters/CurrencyMaster";
import DepartmentMaster from "./Masters/DepartmentMaster";
import DesignationMaster from "./Masters/DesignationMaster";
import DocumentMaster from "./Masters/DocumentMaster";
import EmployeeCategory from "./Masters/EmployeeCategory";
import LeaveQuota from "./Leave/LeaveQuota";
import LeaveBalance from "./Leave/LeaveBalance";
import LeaveApply from "./Leave/LeaveApply";
import LeaveDashboard from "./Leave/LeaveDashboard";
import EditEmpDetails from "./Employees/EditEmpDetails";
import EmpProfile from "./Employees/EmpProfile";
import EmpLeave from "./Leave/EmpLeave";
import EmpAttendance from "./Attendance/EmpAttendance";
import ViewAttendance from "./Attendance/ViewAttendance";
import AttendanceSettings from "./Attendance/AttendanceSettings";
import EmpPolicies from "./EmployeePanel/EmpPolicies";
import EmpSeparation from "./Seperation/EmpSeparation";
import PolicyUpload from "./Company/PolicyUpload";
import BranchMaster from "./Company/BranchMaster";
import CompanyDetail from "./Company/CompanyDetail";
import PolicyList from "./Company/PolicyList";
import EmployeeWFH from "./Leave/EmployeeWFH";
import EmpPayslip from "./EmployeePanel/EmpPayslip";
import Announcements from "./Company/Announcements";
import GeoFencingAttendance from "./Attendance/GeoFencingAttendance";
import AttendanceDashboard from "./Attendance/AttendanceDashboard";
import InductionInfo from "./Employees/InductionInfo";
import AddEmpGeoFencing from "./Attendance/AddEmpGeoFencing";
import EmpWFH from "./Leave/EmpWFH";
import ActivityReport from "./Attendance/ActivityReport";
import NewRole from "./Roles&Permissions/NewRole";
import RoleList from "./Roles&Permissions/RoleList";
import LeaveReports from "./Leave/LeaveReports";
import AddSeperation from "./Seperation/AllSeperation";
import ResetPassword from "./ResetPassword";
import OvertimeApplicable from "./OvertimeComponents/OvertimeApplicable";
import OvertimeReport from "./OvertimeComponents/OvertimeReport";
import EmployeeOvertimeReport from "./OvertimeComponents/EmployeeOvertimeReport";
import AddAsset from "./Assets/AddAsset";
import AllocateAsset from "./Assets/AllocateAsset";
import LeaveBalanceReport from "./Leave/LeaveBalanceReport";
import EmployeeDataReport from "./Employees/EmployeeDataReport";
import AddInvestment from "./Investment/AddInvestment";
import SeparationSettings from "./Seperation/SeparationSettings";
import ViewAssets from "./Assets/ViewAssets";
import AttendanceHelper from "./Attendance/AttendanceHelper";
import FNFSettlements from "./Seperation/FNFSettlements";
import Bonus from "./OtherComponents/Bonus";
import AddExpenseAndClaim from "./ExpenseComponents/AddExpenseAndClaim";
import ExpenseAdvanceMaster from "./ExpenseComponents/ExpenseAdvanceMaster";
import ExpensePayment from "./ExpenseComponents/ExpensePayment";
import ExpensesReport from "./ExpenseComponents/ExpensesReport";
import ExpensesReportAll from "./ExpenseComponents/ExpenseReportAll";
import PettyAdvanceRequest from "./ExpenseComponents/PettyAdvanceRequest";
import OvertimePayment from "./OvertimeComponents/OvertimePayment";
import SalaryDashboard from "./SalaryComponents/SalaryDashboard";
import SalaryOutlet from "./SalaryComponents/SalaryOutlet";
import ViewAllSalary from "./SalaryComponents/ViewAllSalary";
import AddSalaryStructure from "./SalaryComponents/AddSalaryStructure";
import EmpCompOutlet from "./SalaryComponents/EmpCompOutlet";
import AddEmpSalaryComponents from "./SalaryComponents/AddEmpSalaryComponents";
import EstimatedSalary from "./PayrollComponents/EstimatedSalary";
import ComponentMaster from "./Settings/ComponentMaster";
import PayrollConfiguration from "./Settings/PayrollConfiguration";
import ImportEmpNAttendance from "./OtherComponents/ImportEmpNAttendance";
import ClientMaster from "./Masters/ClientMaster";
import FinalAdhocDeductions from "./PayrollComponents/FinalAdhocDeductions";
import FinalAdhocPayment from "./PayrollComponents/FinalAdhocPayment";
import ExpensePaymentInfo from "./ExpenseComponents/ExpensePaymentInfo";
import AllOvertime2 from "./OvertimeComponents/AllOvertime2";
import AttendanceTypeMaster from "./Masters/AttendanceTypeMaster";
import ManagerOvertime from "./OvertimeComponents/ManagerOvertime";
import AttendanceReport from "./Attendance/AttendanceReport";
import PaySlipDesign1 from "./SalarySlips/PaySlipDesign1";
import EmpExpenseClaim from "./ExpenseComponents/EmpExpenseClaim";
import AllAttendanceReport from "./Attendance/AllAttendanceReport";
import ComplianceSettings from "./Settings/ComplianceSettings";
import Compliance from "./PayrollComponents/Compliance";
import ImportTds from "./PayrollComponents/ImportTds";
import FinalRunPayroll from "./PayrollComponents/FinalRunPayroll";
import EmployeeIncrementStructure from "./SalaryRevision/EmployeeIncrementStructure";
import FNFReport from "./Seperation/FNFReport";
import FnfAssetRecovery from "./Assets/FnfAssetRecovery";
import ViewSalaryIncrement from "./SalaryRevision/ViewSalaryIncrement";
import EmployeesReport from "./Reports/EmployeesReport";
import ExpenseTypeMaster from "./Masters/ExpenseTypeMaster";
import ExpensePaymentProcess from "./ExpenseComponents/ExpensePaymentProcess";
import ProbationEmployees from "./Employees/ProbationEmployees";
import AllApprovals from "./ExpenseComponents/AllApprovals";
import AdvancePaymentProcess from "./ExpenseComponents/AdvancePaymentProcess";
import ConfirmedExpensePayments from "./ExpenseComponents/ConfirmedExpensePayments";
import AdvanceConfirmedPayments from "./ExpenseComponents/AdvanceConfirmedPayments";
import EmpSummaryDetails from "./ExpenseComponents/EmpSummaryDetails";
import ReturnRequests from "./ExpenseComponents/ReturnRequests";
import ShiftMaster from "./Masters/ShiftMaster";
import AssignShift from "./ShiftComponents/AssignShift";
import AssetIssues from "./Assets/AssetIssues";
import ProjectMaster from "./ShiftComponents/ProjectMaster";
import AssignProject from "./ShiftComponents/AssignProject";
import ShiftDuty from "./ShiftComponents/ShiftDuty";
import EmailConfig from "./OtherComponents/EmailConfig";
import ViewSubscription from "./Subscription/ViewSubscription";
import HistoryOutlet from "./SalaryRevision/HistoryOutlet";
import AllTransactions from "./Subscription/AllTransactions";
import UpgradePayment from "./Subscription/UpgradePayment";
import CheckoutOutlet from "./OtherComponents/CheckoutOutlet";
import DesignationTracking from "./Reports/DesignationTracking";
import ReviseSalary from "./SalaryRevision/ReviseSalary";
import CarryForwardSetting from "./Leave/CarryForwardSetting";
import IncrementRule from "./SalaryRevision/IncrementRule";
import SettlementOutlet from "./LeaveSettlement/SettlementOutlet";
import PendingSettlements from "./LeaveSettlement/PendingSettlements";
import EmpLeaveSettlements from "./LeaveSettlement/EmpLeaveSettlements";
import GratuityList from "./OtherComponents/GratuityList";
import SalaryArrearsList from "./OtherComponents/SalaryArrearsList";
import NotFound from "./OtherComponents/NotFound";
import CompensationOff from "./OtherComponents/CompensationOff";
import AllCompensationOff from "./OtherComponents/AllCompensationOff";
import usePermissionCheck from "./Utils/usePermissionCheck";
import AllOvertime from "./OvertimeComponents/AllOvertime";
import EmpForm16 from "./Form16/EmpForm16";
import AddForm16 from "./Form16/AddForm16";
import PayrollReports from "./PayrollComponents/PayrollReports";
import AllEmpInvestments from "./Investment/AllEmpInvestments";
import BulkFormUpload from "./Form16/BulkFormUpload";
import EmpInvestDeclaration from "./Investment/EmpInvestDeclaration";
import PrivateRoute from "./Utils/PrivateRoute";
import TdsReport from "./Reports/TdsReport";
import EmailCampaigns from "./OtherComponents/EmailCampaigns";
import SeparationReport from "./Reports/SeparationReport";
import BiometricIntegration from "./Attendance/BiometricIntegration";
import EmpBranchTransfer from "./OtherComponents/EmpBranchTransfer";
import ImageRecognition from "./OtherComponents/ImageRecognition";
import EmpLeaveBalanceReport from "./EmployeePanel/EmpLeaveBalanceReport";
import OrgChart from "./Employees/OrgChart";
import AdminCreateEmp from "./Employees/AdminCreateEmp";
import { useAuth } from "./Utils/AuthContext";
import ReportsPanel from "./Reports/ReportsPanel";

const MainRoutes = () => {

  const { userData } = useAuth();

  return (
    <div>
      <Routes>
       {/* <Route exact path="/dashboard" element={userData === null ? <Navigate to={'/'} /> : <PrivateRoute Component={ userData.role === "Admin" ? Dashboard : EmpDashboard} />} />  */}
        <Route exact path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />
        <Route exact path="/activityreport" element={<ActivityReport />} />

        {/* Masters Routes */}
        {usePermissionCheck("Setting","Designation Master") && <Route exact path="/designation" element={<DesignationMaster />} />}
        {usePermissionCheck("Attendance","Emp Geo-Fencing") && <Route exact path="/empgeofencing" element={<AddEmpGeoFencing />} />}
        {usePermissionCheck("Setting","Department Master") && <Route exact path="/department" element={<DepartmentMaster />} />}
        {usePermissionCheck("Setting","Document Master") && <Route exact path="/document" element={<DocumentMaster />} />}
        {usePermissionCheck("Setting","Employee Category") && <Route exact path="/employeecategory" element={<EmployeeCategory />} />}
        {usePermissionCheck("Setting","Bank Master") && <Route exact path="/bank" element={<BankMaster />} />}
        {usePermissionCheck("Setting","Code Master") && <Route exact path="/code" element={<CodeMaster />} />}
        {usePermissionCheck("Setting","Client Master") && <Route exact path="/clientmaster" element={<ClientMaster />} />}
        <Route exact path="/currency" element={<CurrencyMaster />} />
        <Route exact path="/attendancetype" element={<AttendanceTypeMaster />} />
        <Route exact path="/expensetype" element={<ExpenseTypeMaster />} />
        <Route exact path="/shiftmaster" element={<ShiftMaster />} />
        <Route exact path="/assignshift" element={<AssignShift />} />
        <Route exact path="/assignproject" element={<AssignProject />} />
        <Route exact path="/projectmaster" element={<ProjectMaster />} />
        <Route exact path="/shiftduty" element={<ShiftDuty />} />
        <Route exact path="/emailconfig" element={<EmailConfig />} />
        <Route exact path="/importdata" element={<ImportEmpNAttendance />} />
        {usePermissionCheck("Attendance","Attendance Dashboard") && <Route exact path="/attendancedashboard" element={<AttendanceDashboard />} />}
        <Route exact path="/attendancehelper" element={<AttendanceHelper />} />

        {/* Employee Routes */}
        <Route exact path="/adminempdetails" element={<AdminCreateEmp />} />
        {usePermissionCheck("Employee Details","Create Employee") && <Route exact path="/createemployee" element={<CreateEmployee />} />}
        {usePermissionCheck("Employee Details","Profile") && <Route exact path="/myprofile" element={<EmpProfile />} />}
        {usePermissionCheck("Employee Details","All Employee info") && <Route exact path="/editemployees" element={<EditEmployee />} />}
        {usePermissionCheck("Employee Details","Employee Data Report") && <Route exact path="/empdatareport" element={<EmployeeDataReport />} />}

        
        {/* Attendance Routes */}
        <Route exact path="myattendance" element={<EmpAttendance />} />
        <Route exact path="empovertime" element={<ManagerOvertime />} />
        <Route exact path='finalovertime' element={<AllOvertime/>}/>
        <Route exact path="allovertime" element={<AllOvertime2 />} />

        <Route exact path="reportspanel" element={<ReportsPanel />} />

        {/* Leave Routes */}
        <Route exact path="myleave" element={<EmpLeave />} />
        <Route exact path="empwfh" element={<EmpWFH />} />

        {/* Assets Routes */}
        <Route exact path="addasset" element={<AddAsset />} />
        <Route exact path="allocateasset" element={<AllocateAsset />} />
        <Route exact path="viewempassets" element={<ViewAssets />} />
        <Route exact path="assetissues" element={<AssetIssues />} />

        {/* Policy Routes */}
        <Route exact path="viewpolicies" element={<EmpPolicies />} />
        <Route exact path="resetpassword" element={<ResetPassword />} />
        <Route exact path="fnfreport" element={<FNFReport />} />
        {userData?.role === "Admin" && <Route exact path="/newrole" element={<NewRole />} />}
        {userData?.role === "Admin" && <Route exact path="/rolelist" element={<RoleList />} />}
        <Route exact path="/empinduction" element={<InductionInfo />} />
        <Route exact path="/editempdetails" element={<EditEmpDetails />}>
          <Route exact path=":empguid" element={<EmployeeDetails />} />
        </Route>
        {/* <Route exact path="/viewattendance" element={<ViewAttendance />} /> */}
        {/* Attendance Routes */}
        {usePermissionCheck("Setting","Weekly Off Master") && <Route exact path="/weeklyoff" element={<WeeklyOffMaster />} />}
        {usePermissionCheck("Setting","Holiday Master") && <Route exact path="/holidaymaster" element={<HolidayMaster />} />}
        {usePermissionCheck("Attendance","All Attendance") && <Route exact path="/viewattendance" element={<ViewAttendance />} />}
        {usePermissionCheck("Attendance","Attendance Setting") && <Route exact path="/attendancesettings" element={<AttendanceSettings />} />}
        <Route exact path="/geoattendance" element={<GeoFencingAttendance />} />
        <Route exact path="/attendancereport" element={<AttendanceReport />} />
        <Route exact path="/expenseapprovals" element={<AllApprovals />} />
        <Route exact path="/empsummarydetails" element={<EmpSummaryDetails />} />
        <Route exact path="/attendancedated" element={<AttendanceDated />} />

        {usePermissionCheck("Employee Details","Add Salary") && (
          <>
           <Route exact path="/salarydashboard" element={<SalaryDashboard />} />
           <Route exact path="/salary" element={<SalaryOutlet />}>
             <Route exact path=":guId" element={<AddSalaryStructure />} />
           </Route>
          </>
        )}
        {usePermissionCheck("Employee Details","View Salary") && <Route exact path="/viewsalary" element={<ViewAllSalary />} /> }
        {usePermissionCheck("Payroll","Run Payroll") && <Route exact path="/finalrunpayroll" element={<FinalRunPayroll />} />}

        <Route exact path="/addcomponenets" element={<EmpCompOutlet />}>
          <Route exact path=":employee" element={<AddEmpSalaryComponents />} />
        </Route>
        <Route exact path="/probationemployees" element={<ProbationEmployees />} />

        {/* Salary Revision */}
        <Route exact path="/empsalaryincrement" element={<HistoryOutlet />} >
          <Route exact path=":empGuid" element={<EmployeeIncrementStructure />} />
        </Route>
        <Route exact path="/importsalaryrevision" element={<ReviseSalary />} />
        <Route exact path="/viewsalaryincrement" element={<ViewSalaryIncrement />} />
        <Route exact path="/incrementrule" element={<IncrementRule />} />

        {/* leave  */}
        {
          // usePermissionCheck("Leave","Leave Quota") && 
          <Route exact path="/leavequota" element={<LeaveQuota />} />}
        {usePermissionCheck("Leave","All Leave Application") && <Route exact path="/leaveapply" element={<LeaveApply />} />}
        {usePermissionCheck("Leave","Leave Balance") && <Route exact path="/leavebalance" element={<LeaveBalance />} />}
        {usePermissionCheck("Leave","Leave Dashboard") && <Route exact path="/leavedashboard" element={<LeaveDashboard />} />}
        <Route exact path="/leavereports" element={<LeaveReports />} />
        <Route exact path="/leavebalancereports" element={<LeaveBalanceReport />} />
        <Route exact path="/carryforwardsetting" element={<CarryForwardSetting />} />
        <Route exact path="/compensationoff" element={<CompensationOff />} />
        <Route exact path="/allcompensationoff" element={<AllCompensationOff />} />
        <Route exact path="/leavesettlement" element={<SettlementOutlet />} >
        <Route exact path="pendingsettlements" element={<PendingSettlements />} />
         <Route exact path=":EmpGuid" element={<EmpLeaveSettlements />} />
        </Route>
        <Route exact path="/policyupload" element={<PolicyUpload />} />
        <Route exact path="/branchmaster" element={<BranchMaster />} />
        <Route exact path="/companydetail" element={<CompanyDetail />} />
        <Route exact path="/policylist" element={<PolicyList />} />
        <Route exact path="/employeewfh" element={<EmployeeWFH />} />
        <Route exact path="/announcements" element={<Announcements />} />
        <Route exact path="/separationsettings" element={<SeparationSettings />} />

        {/* Overtime Routes */}
        <Route exact path="/overtimepayment" element={<OvertimePayment />} />
        <Route exact path="/applyovertime" element={<OvertimeApplicable />} />
        <Route exact path="/overtimereport" element={<OvertimeReport />} />
        <Route exact path="/empovertimereport" element={<EmployeeOvertimeReport />} />
        <Route exact path="/empviewovertime" element={<ManagerOvertime />} />

        {/* Seperation Routes */}
        <Route exact path="/addseparation" element={<AddSeperation />} />
        <Route exact path="/empseperation" element={<EmpSeparation />} />
        <Route exact path="/fnfsettlement" element={<FNFSettlements />} />

        {/* Expense Routes */}
        <Route exact path="/expenseadvancemaster" element={<ExpenseAdvanceMaster />} />
        <Route exact path="/pettyadvancerequest" element={<PettyAdvanceRequest />} />
        <Route exact path="/claimexpense" element={<AddExpenseAndClaim />} />
        <Route exact path="/expensepayment" element={<ExpensePayment />} />
        <Route exact path="/expensereport" element={<ExpensesReport />} />
        <Route exact path="/expensereportall" element={<ExpensesReportAll />} />
        <Route exact path="/empexpenseclaim" element={<EmpExpenseClaim />} />
        <Route exact path="/expensepaymentprocess" element={<ExpensePaymentProcess />} />
        <Route exact path="/advancepaymentprocess" element={<AdvancePaymentProcess />} />
        <Route exact path="/expenseconfirmedpay" element={<ConfirmedExpensePayments />} />
        <Route exact path="/advanceconfirmedpay" element={<AdvanceConfirmedPayments />}/>
        <Route exact path="/returnrequests" element={<ReturnRequests />} />
        <Route exact path="/expensepaymentinfo" element={<ExpensePaymentInfo />} />
        <Route exact path="/allattendancereport" element={<AllAttendanceReport />} />
        <Route exact path="/compliancesettings" element={<ComplianceSettings />}/>
        <Route exact path="/compliance" element={<Compliance />} />
        <Route exact path="/taxdeductionsource" element={<ImportTds />} />

        {/* Payroll Routes */}
        <Route exact path="/estimatedpayroll" element={<EstimatedSalary />} />
        <Route exact path="/adhocpayment" element={<FinalAdhocPayment />} />
        <Route exact path="/adhocdeduction" element={<FinalAdhocDeductions />} />
        <Route exact path="/payslipdesign1" element={<PaySlipDesign1 />} />

        {/* Other Routes */}
        <Route exact path="/bonus" element={<Bonus />} />
        <Route exact path="/emppayslips" element={<EmpPayslip />} />
        <Route exact path="/fnfassetrecovery" element={<FnfAssetRecovery />} />
        <Route exact path="/gratuitylist" element={<GratuityList />} />
        <Route exact path="/arrearlist" element={<SalaryArrearsList />} />


        {/* Reports */}
        <Route exact path="/employeesreport" element={<EmployeesReport />} />
        <Route exact path="/empdesignations" element={<DesignationTracking />} />
        <Route exact path="/separationreport" element={<SeparationReport />} />


        {/* Subscription */}
        <Route exact path="/viewsubscription" element={<ViewSubscription />} />
        <Route exact path="/plantransactions" element={<AllTransactions />} />
        <Route exact path='/upgradeplan' element={<CheckoutOutlet />}>
          <Route exact path=":checkoutType/:planId" element={<UpgradePayment />} />
        </Route>

        {/* Setting Routes */}
        <Route exact path="/componentmaster" element={<ComponentMaster />} />
        <Route exact path="/payrollconfiguration" element={<PayrollConfiguration />}/>

        {/* Form 16 Routes */}
        <Route exact path="/empform16" element={<EmpForm16 />} />
        <Route exact path="/addform16" element={<AddForm16 />} />
        <Route exact path="/bulkformupload" element={<BulkFormUpload />} />
        <Route exact path="/payrollreports" element={<PayrollReports />} />
        <Route exact path="/emailcampaigns" element={<EmailCampaigns />} />

        {/* Investment Routes */}
        <Route exact path="/addinvestment/:empGuId/:userRole/:gotFinancialYear" element={<AddInvestment />} />
        <Route exact path="/allinvestments" element={<AllEmpInvestments/>} />
        <Route exact path="/empinvestments" element={<EmpInvestDeclaration/>} />
        <Route exact path="/tdsreport" element={<TdsReport/>} />
        <Route exact path="/integratebiometric" element={<BiometricIntegration/>} />
        <Route exact path="/transferemployee" element={<EmpBranchTransfer/>} />
        <Route exact path="/iocr" element={<ImageRecognition/>} />
        <Route exact path="/empleavebalance" element={<EmpLeaveBalanceReport/>} />
        <Route exact path="/organisationchart" element={<OrgChart/>} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default MainRoutes;
