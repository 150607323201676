import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Button, useToast, useDisclosure,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import useValidation from "../Utils/useValidation";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";
import usePost from "../Utils/usePost";

function Compliance() {

    const { userData } = useAuth();

    const { PostData: PostEmpPFData } = usePost('/api/EmpPFESIData/SavePayrollEPFMonthData')
    const { PostData: PostImportedPFData } = usePost('/api/EmpPFESIData/ImportPayrollEPFMonthData')
    const { PostData: PostEmpESIData } = usePost('/api/EmpPFESIData/SavePayrollEsiMonthData')
    const { PostData: PostImportedESIData } = usePost('/api/EmpPFESIData/ImportPayrollEsiMonthData')
    
    const Validation = useValidation();

    const [tabIndex, setTabIndex] = useState(0);
    
    const [DataMonth, setDataMonth] = useState(new Date());
    const [EmpList, setEmpList] = useState([]);

    const [ImportedPfData, setImportedPfData] = useState([]);
    const [ImportedEsiData, setImportedEsiData] = useState([]);

    const [PfImportMonth, setPfImportMonth] = useState(new Date());
    const [EsiImportMonth, setEsiImportMonth] = useState(new Date());

    const [PfImportExcelHeads, setPfImportExcelHeads] = useState([
     { label: "empGuId", key: `empGuId`},
     { label: "employeeName", key: `employeeName`},
     { label: "empCode", key: `empCode`},
     { label: "empEPFAmount", key: `empEPFAmount`},
     { label: "emplrEPFAmount", key: `emplrEPFAmount`},
     { label: "payrollMonth", key: `payrollMonth`},
     { label: "payrollYear", key: `payrollYear`}
    ])

    const [EsiImportExcelHeads, setEsiImportExcelHeads] = useState([
        { label: "empGuId", key: `empGuId`},
        { label: "employeeName", key: `employeeName`},
        { label: "empCode", key: `empCode`},
        { label: "empESIAmount", key: `empESIAmount`},
        { label: "emplrESIAmount", key: `emplrESIAmount`},
        { label: "payrollMonth", key: `payrollMonth`},
        { label: "payrollYear", key: `payrollYear`}
       ])

    const {
      isOpen:  isImportPfDetailsOpen,
      onOpen:  onImportPfDetailsOpen,
      onClose: onImportPfDetailsClose,
    } = useDisclosure();

    const {
      isOpen:  isImportESIDetailsOpen,
      onOpen:  onImportESIDetailsOpen,
      onClose: onImportESIDetailsClose,
    } = useDisclosure();

    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

    const [PFRowData, setPFRowData] = useState([]);
    const [ESIRowData, setESIRowData] = useState([]);

    const [PfColumnDefs, setPfColumnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode",
      },
        {
          headerName: "Emp Name",
          field: "empName",
        },

        {
          headerName: "Payroll Month",
          field: "payrollMonth",
          valueGetter:(params)=> `${params.data.payrollMonth}/${params.data.payrollYear}`
        },
        {
          headerName: "Employee PF Contribution",
          field: "empEPFAmount",
          editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

        },
        {
            headerName: "Employer PF Contribution",
            field: "emplrEPFAmount",
            editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

          },

          {
            headerName: "Action",
            cellRenderer:(params) => <Button 
             onClick={async()=>{
                let EmpRowData = params.data;

                let body = {
                    payrollEPFMonthDataId : EmpRowData.payrollEPFMonthDataId,
                    empGuId : EmpRowData.empGuId,
                    empEPFAmount :  EmpRowData.empEPFAmount,
                    emplrEPFAmount :  EmpRowData.emplrEPFAmount,
                    payrollMonth :  EmpRowData.payrollMonth,
                    payrollYear :  EmpRowData.payrollYear,
                    companyId :  EmpRowData.companyId,
                    branchId : EmpRowData.branchId
                  }
            
                console.log("PF Update Body",body);

                const response = await PostEmpPFData(body);

                if(response){
                  GetPfDetailsOfMonth(EmpRowData.payrollMonth,EmpRowData.payrollYear)
                }
             }}
             colorScheme={'blue'} size={'xs'}>Update</Button>
          }
    ])

    const [EsiColumnDefs, setEsiColumnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode",
      },
       {
         headerName: "Emp Name",
         field: "empName",
       },

       {
        headerName: "Payroll Month",
        field: "payrollMonth",
        valueGetter:(params)=> `${params.data.payrollMonth}/${params.data.payrollYear}`
      },
      {
        headerName: "Employee ESI Contribution",
        field: "empESIAmount",
        editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

      },
      {
       headerName: "Employer ESI Contribution",
       field: "emplrESIAmount",
       editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

      },
      {
        headerName: "Action",
        cellRenderer:(params) => <Button 
         onClick={async()=>{
            let EmpRowData = params.data;

            let body = {
                payrollEsiMonthDataId : EmpRowData.payrollEsiMonthDataId,
                empGuId : EmpRowData.empGuId,
                empESIAmount :  EmpRowData.empESIAmount,
                emplrESIAmount :  EmpRowData.emplrESIAmount,
                payrollMonth :  EmpRowData.payrollMonth,
                payrollYear :  EmpRowData.payrollYear,
                companyId :  EmpRowData.companyId,
                branchId : EmpRowData.branchId
              }
        
            console.log("ESI Update Body",body);

            const response = await PostEmpESIData(body);

            if(response){
              GetEsiDetailsOfMonth(EmpRowData.payrollMonth,EmpRowData.payrollYear)
            }
         }}
         colorScheme={'blue'} size={'xs'}>Update</Button>
      }
    ])


      // Table Pagination
      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      //Table columns properties applies to all columns
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border',
        enableCellTextSelection:true
      }));

      useEffect(()=>{
        getEmployeeList();
        GetPfDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear())
        GetEsiDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear())
      },[])

      const GetPfDetailsOfMonth = async (MONTH,YEAR) => {
        try {
            const response = await authApi.get(
             `${process.env.REACT_APP_ACQ_URL}/api/EmpPFESIData/GetPayrollEPFMonthDataList?Month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Pf Details of Month",response);
            setPFRowData(response.data);
        } catch (error) {
            console.error(error)
        }
      }

      
      const GetEsiDetailsOfMonth = async (MONTH,YEAR) => {
        try {
            const response = await authApi.get(
             `${process.env.REACT_APP_ACQ_URL}/api/EmpPFESIData/GetPayrollEsiMonthDataList?Month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("ESI Details of Month",response);
            setESIRowData(response.data);
        } catch (error) {
            console.error(error)
        }
      }


      const getEmployeeList = async() =>{
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Employee List",response.data);
          const res = response.data;
          let ActiveEmpList = res.filter((data)=>data.isActive === true);
          setEmpList(ActiveEmpList.map((data)=>{
              return {
                  empGuId : data.guId,
                  employeeName: data.fullName,
                  empCode: data.empCode,
                  empEPFAmount : 0,
                  emplrEPFAmount : 0,
                  empESIAmount : 0,
                  emplrESIAmount : 0,
                  payrollMonth : new Date(PfImportMonth).getMonth()+1,
                  payrollYear : new Date(PfImportMonth).getFullYear(),
              }
          }))
        } catch (error) {
          console.error(error)
        }
      }



       const ImportedPfDetailsReadExcel = (file)=>{

    const promise = new Promise((resolve,reject) => {
      
     const fileReader = new FileReader();
     fileReader.readAsArrayBuffer(file);
 
     fileReader.onload=(e)=>{
       const bufferArray = e.target.result;
 
       const wb = XLSX.read(bufferArray,{type:'buffer'});
       const wsname = wb.SheetNames[0];
 
       const ws = wb.Sheets[wsname];
 
       const data = XLSX.utils.sheet_to_json(ws, {raw: false});
 
       resolve(data)
     } 
 
     fileReader.onerror = (error) => {
       reject(error)
     }
 
    });
 
    promise.then((data)=>{
      console.log("PF Imported Data",data);

      let FinalData = data.map((obj)=>{
        return {...obj,
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
       })

       console.log("PF Final Data",FinalData);
       setImportedPfData(FinalData);
       SavePfImportedData(FinalData)

      });
 }


 const SavePfImportedData = async (FinalData) => {

    let body = FinalData?.filter((filData) => Number(filData?.empEPFAmount) !== 0 && Number(filData?.emplrEPFAmount) !== 0)?.map((data)=>{
        return {
            empGuId : data.empGuId,
            empEPFAmount :  data.empEPFAmount,
            emplrEPFAmount :  data.emplrEPFAmount,
            payrollMonth :  data.payrollMonth,
            payrollYear :  data.payrollYear,
            companyId :  data.companyId,
            branchId : data.branchId
          }
    })

    console.log("PF Imported Save Bodt",body);

    const response = await PostImportedPFData(body);

    if(response){
      GetPfDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear())
      GetEsiDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear())
    }
 }


 const ImportedEsiDetailsReadExcel = (file)=>{

    const promise = new Promise((resolve,reject) => {
      
     const fileReader = new FileReader();
     fileReader.readAsArrayBuffer(file);
 
     fileReader.onload=(e)=>{
       const bufferArray = e.target.result;
 
       const wb = XLSX.read(bufferArray,{type:'buffer'});
       const wsname = wb.SheetNames[0];
 
       const ws = wb.Sheets[wsname];
 
       const data = XLSX.utils.sheet_to_json(ws, {raw: false});
 
       resolve(data)
     } 
 
     fileReader.onerror = (error) => {
       reject(error)
     }
 
    });
 
    promise.then((data)=>{
      console.log("ESI Imported Data",data);

      let FinalData = data.map((obj)=>{
        return {...obj,
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
       })

       console.log("ESI Final Data",FinalData);
       setImportedEsiData(FinalData);
       SaveEsiImportedData(FinalData)

      });
 }


 const SaveEsiImportedData = async (FinalData) => {

    let body = FinalData?.filter((filData) => Number(filData?.empESIAmount) !== 0 && Number(filData?.emplrESIAmount) !== 0)?.map((data)=>{
        return {
            empGuId : data.empGuId,
            empESIAmount :  data.empESIAmount,
            emplrESIAmount :  data.emplrESIAmount,
            payrollMonth :  data.payrollMonth,
            payrollYear :  data.payrollYear,
            companyId :  data.companyId,
            branchId : data.branchId
          }
    })

    console.log("ESI Imported Save Bodt",body);

    const response = await PostImportedESIData(body);

    if(response){
      GetPfDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear())
      GetEsiDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear())
    }
 }

 const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  return (
    <div>
      <div className="flex items-center justify-between border-b border-gray-400 mb-4 pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">
          Compliance
        </h1>
        <div className='flex gap-4 items-center'>
            {tabIndex === 0 ?
            <Button onClick={onImportPfDetailsOpen} size={'sm'} leftIcon={<FontAwesomeIcon icon={faFileImport} />} colorScheme={'purple'}>Import PF</Button>
            : tabIndex === 1 ?
            <Button onClick={onImportESIDetailsOpen} size={'sm'} leftIcon={<FontAwesomeIcon icon={faFileImport} />} colorScheme={'purple'}>Import ESI</Button> : ''}
            <div>
             <DatePicker
               className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
               selected={DataMonth}
               onChange={(date) => {
                 setDataMonth(date);
                 GetPfDetailsOfMonth(new Date(date).getMonth()+1,new Date(date).getFullYear())
                 GetEsiDetailsOfMonth(new Date(date).getMonth()+1,new Date(date).getFullYear())
               }}
               showMonthYearPicker
               minDate={new Date(Validation?.payrollStartFrom)}
               maxDate={new Date()}
               dateFormat="MM/yyyy"
               placeholderText="Select Month/Year"
             />
            </div>
        </div>
      </div>


      <Tabs size={'sm'} index={tabIndex} onChange={handleTabsChange} variant='solid-rounded' colorScheme={'purple'}>
         <TabList>
           <Tab>PF</Tab>
           <Tab>ESI</Tab>
         </TabList>
         <TabPanels>
           <TabPanel px={0}>
           <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={PFRowData} // Row Data for Rows
                columnDefs={PfColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
           </div>
           </TabPanel>
           <TabPanel px={0}>
           <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={ESIRowData} // Row Data for Rows
                columnDefs={EsiColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
           </div>
           </TabPanel>
         </TabPanels>
       </Tabs>



      <Modal size='full' isOpen={isImportPfDetailsOpen} onClose={onImportPfDetailsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import PF Details</ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedPfData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4 flex items-end gap-5'>

              <div className="space-y-1">
                <p className="text-sm text-gray-500">Select Data Month</p>
                <div>
                 <DatePicker
                   className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
                   selected={PfImportMonth}
                   onChange={(date) => {
                     setPfImportMonth(date);
                     let SelMonthEmpList = EmpList.map((data)=>{
                        return {...data,
                            payrollMonth : new Date(date).getMonth()+1,
                            payrollYear : new Date(date).getFullYear(),
                        }
                    })
                    setEmpList(SelMonthEmpList);
                   }}
                   showMonthYearPicker
                   minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
                   maxDate={new Date()}
                   dateFormat="MM/yyyy"
                   placeholderText="Select Month/Year"
                 />
                </div>
              </div>

               <CSVLink data={EmpList} headers={PfImportExcelHeads} filename={"Import Pf Details.csv"}>
                 <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} size='sm' colorScheme='purple'>Download Sample Excel for PF Details Import</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                ImportedPfDetailsReadExcel(file);
              }}
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      {/* <div>
        {JSON.stringify(ImportedEsiData)}
      </div> */}
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedPfData([]);
                    onImportPfDetailsClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal size='full' isOpen={isImportESIDetailsOpen} onClose={onImportESIDetailsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import ESI Details</ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedEsiData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4 flex items-end gap-5'>

              <div className="space-y-1">
                <p className="text-sm text-gray-500">Select Data Month</p>
                <div>
                 <DatePicker
                   className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
                   selected={EsiImportMonth}
                   onChange={(date) => {
                     setEsiImportMonth(date);

                     let SelMonthEmpList = EmpList.map((data)=>{
                        return {...data,
                            payrollMonth : new Date(date).getMonth()+1,
                            payrollYear : new Date(date).getFullYear()
                        }
                    })
                    setEmpList(SelMonthEmpList);
                   }}
                   showMonthYearPicker
                   minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
                   maxDate={new Date()}
                   dateFormat="MM/yyyy"
                   placeholderText="Select Month/Year"
                 />
                </div>
              </div>

               <CSVLink data={EmpList} headers={EsiImportExcelHeads} filename={"Import ESI Details.csv"}>
                 <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} size='sm' colorScheme='purple'>Download Sample Excel for ESI Details Import</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input  onChange={(e) => { const file = e.target.files[0]; ImportedEsiDetailsReadExcel(file); }} id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      {/* <div>
        {JSON.stringify(ImportedPfData)}
      </div> */}
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedEsiData([]);
                    onImportESIDetailsClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>


    </div>
  )
}

export default Compliance