import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
  import { Link } from "react-router-dom";
  import { Button,Input } from "@chakra-ui/react";
  import { faPlus, faDownload } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import DatePicker from "react-datepicker";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";
  
  function ExpensesReportAll() {
    const { userData } = useAuth();
  
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  
    const [EmpList, setEmpList] = useState([]);
    const [SelectedEmp, setSelectedEmp] = useState([]);
    const [StartDate, setStartDate] = useState();
  
    const [RemainingAmount, setRemainingAmount] = useState();
    const [ApprovedAmount, setApprovedAmount] = useState();
  
    const [ClaimAmountDetails, setClaimAmountDetails] = useState([]);
  
  
    useEffect(() => {
      getEmpReports();
    }, []);
  
     
    const getEmpReports = async () => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetLedgerDataAllByMonth?month=${new Date(StartDate).getMonth()+1}&Year=${new Date(StartDate).getFullYear()}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("Emp Reports", response.data);
        const EmpList = response.data;
        setRowData(EmpList);
      } catch (error) {
        console.error(error);
      }
      
  
    };
  
    const [columnDefs, setColumnDefs] = useState([
      // { headerName: "Sr No.", field: "id", width: 60, filter: false },
      {
        headerName: "Emp Code",
        field: "empCode",
      },
      {
        headerName: "Emp Name",
        field: "empName",
      },
      {
        headerName: "Transaction Date",
        field: "datedata",
        valueGetter: (params) => {
          return new Date(params.data.datedata).toLocaleDateString("en-GB");
        },
        width: 118,
      },
      { headerName: "Advance", field: "credit" },
      { headerName: "Expense", field: "debit" },
      { headerName: "Remaining Balance", field: "remainningBalance" },
  
    
      // { headerName: "ClientName", field: "clientName" },
      // {
      //   headerName: "Attachment",
      //   cellRenderer: (params) => (
      //     <Button
      //       leftIcon={<FontAwesomeIcon icon={faDownload} />}
      //       onClick={() => {
      //         handleDownloadFile(params.data.attachmentPath);
      //       }}
      //       colorScheme="purple"
      //       variant="link"
      //     >
      //       Download
      //     </Button>
      //   ),
      // },
    ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
    }));
  
   
    const exportData = () => {
      const params = {
        fileName: `Expense Report.csv`,
      };
      gridRef.current.api.exportDataAsCsv(params);
    };

    
    return (
      <div>
  
        <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-2 text-left">
            <h1 className="text-gray-700 text-lg font-bold ">
              Monthly Expense Report
            </h1>
            <div>
              <Button 
               isDisabled={rowData.length === 0 ? true : false}
               leftIcon={<FontAwesomeIcon icon={faDownload} />}
               onClick={()=>{exportData();}} colorScheme="purple">
                Export
              </Button>
            </div>
          </div>
  
  
          <form>
        <div className="bg-white rounded-2xl p-4 flex gap-8 items-end  mb-4">
          
          <div className="space-y-2">
            <label>Select Month/Year</label>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selected={StartDate}
              onChange={(date) => {
                setStartDate(date);
  
                // let SelMonth = date.getMonth()+1;
                // let SelYear = date.getFullYear();
  
  
              }}
              showMonthYearPicker
              dateFormat="MM/yyyy"
              placeholderText="Select Month/Year"
            />
          </div>
        
          <Button onClick={()=>{getEmpReports()}} colorScheme={"purple"}>Search</Button>
        </div>
        </form>
  
       
        <div className="ag-theme-alpine mt-4">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            suppressExcelExport={true}
          />
        </div>
      </div>
    );
  }
  
  export default ExpensesReportAll;
  