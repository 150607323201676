import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Input, Button, useDisclosure, useToast, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { CheckCircle, Eye, Upload } from "react-feather"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp, faEdit, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import usePost from "../Utils/usePost";
import useFinancialYear from "../Utils/useFinancialYear";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function AddForm16() {
  const { userData } = useAuth();
    const toast = useToast();
    const id = "toast";

    const FinancialYear = useFinancialYear();
    const [IsLoading, setIsLoading] = useState({
      ViewLoading: false,
      SaveLoading: false
    })

    const { IsPostLoading, PostData } = usePost('/api/Form16Document/SaveForm16Document');

    const FileRef = useRef();
  
    const reset = () => {
      FileRef.current.value = "";
    };

    const [BulkUploads, setBulkUploads] = useState([]);

    const {
      isOpen: isViewForm16Open,
      onOpen: onViewForm16Open,
      onClose: onViewForm16Close,
    } = useDisclosure();

    const {
      isOpen: isAddFinancialYearOpen,
      onOpen: onAddFinancialYearOpen,
      onClose: onAddFinancialYearClose,
    } = useDisclosure();

    const {
      isOpen: isBulkUploadOpen,
      onOpen: onBulkUploadOpen,
      onClose: onBulkUploadClose,
    } = useDisclosure();

    const {
      isOpen: isAddAttachmentOpen,
      onOpen: onAddAttachmentOpen,
      onClose: onAddAttachmentClose,
    } = useDisclosure();

    const [NewFinancialYearName, setNewFinancialYearName] = useState();

    const [EmpFormId, setEmpFormId] = useState(0);
    const [EmployeeList, setEmployeeList] = useState([]);    
    const [SelectedEmpData, setSelectedEmpData] = useState([]);
    const [FinancialYearList, setFinancialYearList] = useState([]);
    // const [FinancialYear, setFinancialYear] = useState();
    const [Attachment, setAttachment] = useState();
    const [PdfPath, setPdfPath] = useState();
    const [ConfirmSelctedEmp, setConfirmSelctedEmp] = useState([]);

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const gridOptions = {
      isRowSelectable: (rowNode) => {
        return rowNode.data.isStatus ==="1" && rowNode.data.isLock === false;
      },
    };
    const [columnDefs] = useState([
          {
            headerName: "Employee Code",
            field: "empCode",
            checkboxSelection:(params)=> params.data.isStatus ==="1" && params.data.isLock === false,
            headerCheckboxSelection:true
          },
          {
            headerName: "Employee Name",
            field: "fullName",
            valueGetter: (params) => (params.data.title || '') + ' ' + params.data.fullName
          },
          {
            headerName: "Financial Year",
            field: "financialYearName",
          },
          {
            headerName: "Status",
            field: "isStatus",
            cellRenderer:(params)=>{
             return params.data.isStatus === "1" && params.data.isLock === true ? 
             <span className="bg-green-600 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap"><FontAwesomeIcon icon={faLock} size="sm" className="mr-1"/>Confirmed</span>: params.data.isStatus === "1" && params.data.isLock === false ? 
             <span className="bg-green-500 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Completed</span>:
              <span className="bg-red-500 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Pending</span>
            }
          },
          {
            headerName: "Action",
            // width: 400,
            floatingFilter: false,
            cellRenderer: (params) => (
              <div className="space-x-4">
                { params.data.attachmentFile === null && <Button
                 leftIcon={<Upload size={'14px'} />}
                 onClick={()=>{
                  setSelectedEmpData(params.data);
                  onAddAttachmentOpen();
                 }}
                 colorScheme="blue"
                 variant='solid'
                 size={'xs'}
               >
                Upload
               </Button>}
                {params.data.isStatus === "1" &&<Button
                 isLoading={IsLoading?.ViewLoading}
                 loadingText="Loading..."
                 leftIcon={<Eye size={'14px'} />}
                 onClick={()=>{
                  let FormRowData = params.data;
                  setSelectedEmpData(FormRowData);
                   setIsLoading((prevData)=>{
                     return {
                       ...prevData,
                       ViewLoading : true
                     }
                   })
                   handleDownloadFile(params.data.attachmentFile)
                 }}
                 colorScheme="blue"
                 variant='outline'
                 size={'xs'}
               >
                 View
               </Button>}
               {(params.data.isLock === false && params.data.isStatus === "1" && <Button
                 leftIcon={<FontAwesomeIcon icon={faEdit} />}
                 onClick={()=>{
                  let FormRowData = params.data;
                  //  HandleForm16Edit(FormRowData);
                  setSelectedEmpData(FormRowData);
                  onAddAttachmentOpen();
                 }}
                 colorScheme="blue"
                 variant='outline'
                 size={'xs'}
               >
                 Edit
               </Button>)}   
               {(params.data.isLock === true && params.data.isStatus === "1" && <Button
                 leftIcon={<FontAwesomeIcon icon={faLockOpen} />}
                 onClick={async()=>{
                  let FormRowData = params.data;

                  const UploadData = new FormData();
                  UploadData.append("id",FormRowData.id);
                  UploadData.append("attachment",FormRowData.attachment); 
                  UploadData.append("attachmentFile",FormRowData.attachmentFile); 
                  UploadData.append("empGuid",FormRowData.empGuid);
                  UploadData.append("empCode",FormRowData.empCode);
                  UploadData.append("financialYearId",FormRowData.financialYearId);
                  UploadData.append("financialYearName",FormRowData.financialYearName);
                  UploadData.append("isLock",false);
                  UploadData.append("companyId",userData.companyId);
                  UploadData.append("branchId",userData.isActiveBranch);
          
                  const response = await PostData(UploadData);

                  if(response){
                    getForm16List(FinancialYear)
                  }

                 }}
                 colorScheme="blue"
                 variant='outline'
                 size={'xs'}
               >
                 Unlock
               </Button>)}
              </div>
            ),
          },
    ]);

    const onSelectionChanged = useCallback(() => {
      var SelectedEmployees = gridRef.current.api.getSelectedRows();
      console.log("Selected Employees", SelectedEmployees);
  
      setConfirmSelctedEmp(SelectedEmployees);
    }, []);

    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);

    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
       flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));

    // console.log("Filtered Emp List",FilteredEmpList);
  
    useEffect(() => {
      getFinancialYearList();
      getEmployeeList();
      // getForm16List();
    }, [])
  
    const getFinancialYearList = async () => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Master/GetFinancialYearMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Financial Year List",response);
        const res=  response.data;
        setFinancialYearList(response.data);
        getForm16List(res.filter(data => data.isCurrent === true).map(fildata => fildata.financialYearId )[0])
      } catch (error) {
        console.error(error)
      }
    }

    console.log('Financial Year',FinancialYear);
    
      const getEmployeeList = async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
            // console.log("Emp List",response?.data?.filter(data => data.isActive === true));
          const res = response.data;
          setEmployeeList(res.filter(data => data.isActive === true));
        } catch (err) {
          console.error(err)
        }
      }
  
  
  
    const getForm16List = async (FinanceYear) => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Form16Document/GetForm16DocumentList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FinancialYearId=${FinanceYear}`
        )
        console.log("Form 16 List",response);
        setRowData(response.data);
      } catch (error) {
        console.error(error)
      } finally {
        setConfirmSelctedEmp([])
        setSelectedEmpData([])
      }
    }
  
  
    const SaveForm16 = async (e) => {
  
      e.preventDefault();
      setIsLoading((prevData)=>{
        return {
          ...prevData,
          SaveLoading : true
        }
      })
  
      const UploadData = new FormData();
      // console.log("Attachment",EmpFormId,Attachment);
      UploadData.append("id",EmpFormId);
      UploadData.append("attachment",Attachment); 
      UploadData.append("attachmentFile",EmpFormId ? Attachment : '')
      UploadData.append("empGuid",SelectedEmpData.empGuid);
      UploadData.append("empCode",SelectedEmpData.empCode);
      UploadData.append("financialYearId",FinancialYear?.financialYearId);
      UploadData.append("financialYearName",FinancialYear?.financialYearName)
      UploadData.append("companyId",userData.companyId);
      UploadData.append("branchId",userData.isActiveBranch);
  
      await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Form16Document/SaveForm16Document`,
        UploadData
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true
          });
        }
  
        getForm16List(FinancialYear);

  
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Form 16 not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      }).finally(()=>{
        reset();
        onAddAttachmentClose();
        setSelectedEmpData([]);
        setEmpFormId(0);
        setIsLoading((prevData)=>{
          return {
            ...prevData,
            SaveLoading : false
          }
        });
      })
  
    }
  
    const SaveFinancialYearMaster = async (e) =>{
      e.preventDefault();
      let body = {
        financialYearName: NewFinancialYearName,
        isActive: true,
        isCurrent: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      }
      console.log(body);
      await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveFinancialYearMaster`,body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
  
         getFinancialYearList();
         onAddFinancialYearClose();
         setNewFinancialYearName('');
  
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Financial Year not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true
          });
        }
      });
  
  
    }
  
  
  
  
  
  
  
  
    const handleDownloadFile = async (DocPath) =>{
      console.log(DocPath)
     await fetch(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
    ).then(response => {
  
      console.log("file Response",response);
  
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
  
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          // alink.download = fileURL;
          console.log("Alink",alink.href);
          setPdfPath(alink)
         
          // alink.click();
      })
  
    }).catch((error)=>{
      console.log("File Api Error",error);
    }).finally(()=>{
      onViewForm16Open();
      setIsLoading((prevData)=>{
        return {
          ...prevData,
          ViewLoading : false
        }
      })
    })
    }
  
  
  //  console.log("Validate",Attachment,SelectedEmpData.length);

  //  const FileName = ("GURVINDER SINGH MUTTI_ALIPM0521H_AY202324_SIGNED")?.split('_')
  //  const FirstYear = /\d{4}/g;
  //  const SecondYear = /\d{2}$/;

  //  const year1 = FileName[2]?.match(FirstYear)[0];
  //  const year2 = FileName[2]?.match(SecondYear)[0];

  //  console.log("Years",year1,year2);

  //  const FinanYear = FinancialYearList?.filter(data => parseInt(data.financialYearId) === parseInt(FinancialYear))?.map(FilData => FilData.financialYearName)[0]?.split('-') || "2021-2022"
  //  console.log("Sel Financial Year",FinanYear);

  //  if(year1?.includes(FinanYear[0]) && FinanYear[1]?.includes(year2)){
  //    alert('Financial Year of the File doesnot Matches the Selected Financial Year')
  //  }

    const ValidateFile = (file,Employee) => {

      const FileName = file?.name?.split('_')

      const FirstYear = /\d{4}/g;
      const SecondYear = /\d{2}$/;
      const year1 = FileName[2]?.match(FirstYear)[0];
      const year2 = FileName[2]?.match(SecondYear)[0];
      console.log("Filname",FileName,year1,year2);

      const FinanYear = (FinancialYear?.financialYearName).split('-')

      if((Employee.panNumber === FileName[1]) && (year1?.includes(FinanYear[0]) && FinanYear[1]?.includes(year2))){
        return true
      }else {
        return false
      }
    }


  
    const HandleFileUpload = async (target) => {

      const file = target.files[0];

      if(ValidateFile(file,SelectedEmpData)){
         setAttachment(file);
      }else{
        alert('Incorrect File. Pan Number or Financial Year of the file does not matches with the Selected Details. Please Upload Correct File!');
        reset();
      }

    }

    const HandleBulkUpload =  (target) => {

      const files = target.files;

    const fileDataArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      fileDataArray.push({
        name: file.name,
        panNumber: file?.name?.split('_')[1] || "Not Found",
        file: file,
      });
    }


    let FoundedEmployees = fileDataArray.map((data)=>{
      let EmployeeFounded = EmployeeList.find(({panNumber})=> panNumber === data.panNumber )
      // console.log("Founded",EmployeeFounded);

      if(EmployeeFounded){
        return {
          ...data,
          isValidated: ValidateFile(data.file,EmployeeFounded),
          fullName: EmployeeFounded.fullName,
          guId:EmployeeFounded.guId,
          empCode:EmployeeFounded.empCode,
          fileFinanYear : ValidateFile(data.file,EmployeeFounded) ? FinancialYear?.financialYearName : "Not Found"
        }
      }else{
        return {
          ...data,
          isValidated: false,
          fullName: "Not Found",
          guId:"",
          empCode:"",
          fileFinanYear: "Not Found"
        }      
      }
    })


    setBulkUploads(FoundedEmployees);

    }
  
    // console.log("File Data",BulkUploads);


    const SaveBulkForm16 = async () => {

      let WrongRecord = BulkUploads.filter(data => data.fullName === "Not Found" && data.fileFinanYear === "Not Found");

      // console.log("Wrong Records",WrongRecord);
      if(window.confirm(`${WrongRecord.length > 0 ? 'Only Error Free Employee Form 16 will get Saved. Are you sure you want to continue?' : 'Are you sure you want to save the records?'}`)){

      let Records = BulkUploads.filter(data => data.fullName !== "Not Found" && data.fileFinanYear !== "Not Found");

      // console.log("Records",Records);

      if(Records.length === 0){
        alert("No Records to Save! Enter the Correct Attachments")
      }else{
        for(const FormFile of Records){
          const UploadData = new FormData();
          UploadData.append("id",0);
          UploadData.append("attachment",FormFile.file); 
          UploadData.append("empGuid",FormFile.guId);
          UploadData.append("empCode",FormFile.empCode);
          UploadData.append("financialYearId",FinancialYear?.financialYearId);
          UploadData.append("financialYearName",FinancialYear?.financialYearName)
              UploadData.append("companyId",userData.companyId);
          UploadData.append("branchId",userData.isActiveBranch);
 
          const response = await PostData(UploadData);
 
        }
 
        getForm16List(FinancialYear);
        onBulkUploadClose();
        setBulkUploads([]);
      }



      }

    }

    console.log("Every Record",ConfirmSelctedEmp);

  
    return (
      <div>
        <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
          <h1 className="text-xl font-bold text-gray-800">
            Add Form 16
          </h1>
          <Button onClick={()=>{onBulkUploadOpen()}} colorScheme="purple" leftIcon={<Upload size={'16px'}/>} size={'sm'}>Bulk Upload</Button>
        </div>

        {/* <div className="bg-white p-4 rounded-2xl drop-shadow-md mb-6">
        <div className="grid grid-cols-4 gap-4">

<div className="space-y-2 ">
    <label>Financial Year</label>
    <Select
        isRequired
        borderColor="gray.400"
        placeholder="Select Financial Year"
        value={FinancialYear}
        onChange={({target})=>{setFinancialYear(target.value)}}
      >
     {FinancialYearList.map((data,i)=>{
       return <option key={i} value={data.financialYearId}>{data.financialYearName}</option>
      })}
      </Select>  </div>

  <div className="mt-auto space-x-4">
    <Button onClick={()=>{
      getForm16List(FinancialYear);
    }} leftIcon={<Search size={'16px'} />} colorScheme="purple">Search</Button>
  </div>
  

</div>
        </div> */}

        <div className="flex justify-between">
        <div className="px-5 flex py-3 space-x-3 ">
          <div className="my-auto">
            <FontAwesomeIcon
              className="rotate-180 text-"
              icon={faArrowTurnUp}
            />
          </div>
          <Button isDisabled={ConfirmSelctedEmp.length === 0 ? true : false} onClick={async()=>{
              for(const FormFile of ConfirmSelctedEmp){
                const UploadData = new FormData();
                UploadData.append("id",FormFile.id);
                UploadData.append("attachment",FormFile.attachment);
                UploadData.append("attachmentFile",FormFile.attachmentFile); 
                UploadData.append("empGuid",FormFile.empGuid);
                UploadData.append("empCode",FormFile.empCode);
                UploadData.append("financialYearId",FormFile.financialYearId);
                UploadData.append("financialYearName",FormFile.financialYearName);
                UploadData.append("isLock",true);
                UploadData.append("companyId",userData.companyId);
                UploadData.append("branchId",userData.isActiveBranch);
       
                const response = await PostData(UploadData);
       
              }

              getForm16List(FinancialYear);
    }} leftIcon={<CheckCircle size={'14px'} />} size={"sm"}  colorScheme="green">
            Confirm
          </Button>
        </div>

        <div className="py-3">
          <Button 
           size={"sm"}
           isDisabled={rowData.every(data => data.isLock === true)} 
           leftIcon={<FontAwesomeIcon icon={faLock} />} 
           colorScheme="purple" 
           variant={'outline'}
           onClick={async()=>{
            try {
              const response = await authApi.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Master/LockFinancialYearMaster?FinancialYearId=${FinancialYear?.financialYearId}`
              )
              console.log("Lock FY response",response);

              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: response?.data,
                  position: "top",
                  status: "success",
                  duration: 2000,
                  isClosable: true
                });
              }
            } catch (err) {
              console.error(err);
            }
           }}
          >Lock Financial Year</Button>
        </div>

      
        </div>

        <div className="ag-theme-alpine">
          <AgGridReact
            gridOptions={gridOptions}
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection="multiple"
            paginationPageSize={20}
            paginationNumberFormatter={paginationNumberFormatter}
            onSelectionChanged={onSelectionChanged}
          />
        </div>


        <Modal 
                  isOpen={isAddAttachmentOpen}
                  onClose={onAddAttachmentClose}
                  size={'lg'}
                >
                  <ModalOverlay />
                  <ModalContent rounded={'3xl'}>
                    <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Add Form 16</ModalHeader>
                    <ModalCloseButton mt={1} />
                    <hr />
                    <form onSubmit={SaveForm16}>
                      <ModalBody className="space-y-2">
                      <div className="flex flex-wrap pb-1 gap-8">
                       <div>
                         <p className="text-sm text-gray-500">Emp Name</p>
                         <h6 className="font-medium">{SelectedEmpData?.fullName}</h6>
                       </div>
                       <div>
                         <p className="text-sm text-gray-500">Emp Code</p>
                         <h6 className="font-medium">{SelectedEmpData?.empCode}</h6>
                       </div>
                       <div>
                         <p className="text-sm text-gray-500">Pan Number</p>
                         <h6 className="font-medium">{SelectedEmpData?.panNumber}</h6>
                       </div>
                       <div>
                         <p className="text-sm text-gray-500">Financial Year</p>
                         <h6 className="font-medium">{FinancialYear?.financialYearName}</h6>
                       </div>
                      </div>
                      <hr />
                      <div className="space-y-2">
                        <label>Attachment</label>
                        <Input
                          isRequired
                          isDisabled={SelectedEmpData?.empGuid && FinancialYear !== undefined ? false : true}
                          ref={FileRef}
                          accept="application/pdf"
                          onChange={({target})=>{HandleFileUpload(target)}}
                          type="file"
                          borderColor="gray.400"
                        />
                      </div>
                      </ModalBody>
                      <hr />
                      <ModalFooter>
                        <div className="space-x-4">
                          <Button
                            type="submit"
                            colorScheme="purple"
                            variant="solid"
                          >
                            Save
                          </Button>
                          <Button
                            variant="outline"
                            colorScheme="purple"
                            mr={3}
                            onClick={onAddAttachmentClose}
                          >
                            Cancel
                          </Button>
                        </div>
                      </ModalFooter>
                    </form>
                  </ModalContent>
                </Modal>
  
        <Modal 
                  isOpen={isAddFinancialYearOpen}
                  onClose={onAddFinancialYearClose}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Add Financial Year</ModalHeader>
                    <ModalCloseButton />
                    <hr />
                    <form onSubmit={SaveFinancialYearMaster}>
                      <ModalBody>
                        <div className="space-y-2">
                          <label>Financial Year</label>
                          <Input
                            isRequired
                            value={NewFinancialYearName || ''}
                            onChange={({target})=>{setNewFinancialYearName(target.value)}}
                            borderColor="gray"
                            placeholder="Enter Financial Year"
                          />
                        </div>
                      </ModalBody>
                      <hr />
                      <ModalFooter>
                        <div className="space-x-4">
                          <Button
                            type="submit"
                            colorScheme="purple"
                            variant="solid"
                          >
                            Save
                          </Button>
                          <Button
                            variant="outline"
                            colorScheme="purple"
                            mr={3}
                            onClick={onAddFinancialYearClose}
                          >
                            Cancel
                          </Button>
                        </div>
                      </ModalFooter>
                    </form>
                  </ModalContent>
                </Modal>
  
  
                <Modal size='full' scrollBehavior="inside" isOpen={isViewForm16Open} onClose={onViewForm16Close}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Form 16</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
              <div id="pdf-div"  >
              <embed
                src={PdfPath + "#toolbar=0"}
                type="application/pdf"
                className="h-screen w-full !select-none"
                height={800}
                width={500}
              />
              </div>
            </ModalBody>
            <hr />
  
            <ModalFooter className="space-x-4">

            {SelectedEmpData.isLock === false ?  <Button colorScheme='green' leftIcon={<CheckCircle size={'14px'} />} onClick={async()=>{

                  const UploadData = new FormData();
                  UploadData.append("id",SelectedEmpData.id);
                  UploadData.append("attachment",SelectedEmpData.attachment); 
                  UploadData.append("attachmentFile",SelectedEmpData.attachmentFile); 
                  UploadData.append("empGuid",SelectedEmpData.empGuid);
                  UploadData.append("empCode",SelectedEmpData.empCode);
                  UploadData.append("financialYearId",SelectedEmpData.financialYearId);
                  UploadData.append("financialYearName",SelectedEmpData.financialYearName);
                  UploadData.append("isLock",true);
                  UploadData.append("companyId",userData.companyId);
                  UploadData.append("branchId",userData.isActiveBranch);
          
                  const response = await PostData(UploadData);

                  if(response){
                    getForm16List(FinancialYear);
                    onViewForm16Close();
                    setSelectedEmpData([]);
                  }

                 }}>
                Lock
              </Button> :  SelectedEmpData.isLock === true ? <Button colorScheme='green' variant={'outline'} leftIcon={<FontAwesomeIcon icon={faLockOpen} />} onClick={async()=>{

                const UploadData = new FormData();
                UploadData.append("id",SelectedEmpData.id);
                UploadData.append("attachment",SelectedEmpData.attachment); 
                UploadData.append("attachmentFile",SelectedEmpData.attachmentFile); 
                UploadData.append("empGuid",SelectedEmpData.empGuid);
                UploadData.append("empCode",SelectedEmpData.empCode);
                UploadData.append("financialYearId",SelectedEmpData.financialYearId);
                UploadData.append("financialYearName",SelectedEmpData.financialYearName);
                UploadData.append("isLock",false);
                UploadData.append("companyId",userData.companyId);
                UploadData.append("branchId",userData.isActiveBranch);
                
                const response = await PostData(UploadData);
                
                if(response){
                  getForm16List(FinancialYear);
                  onViewForm16Close();
                  setSelectedEmpData([]);
                }
                
                }}>
                Unlock
                </Button> : ''}

              <Button colorScheme='purple' variant={'outline'} onClick={onViewForm16Close}>
                Close
              </Button>
  
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal size='full' scrollBehavior="inside" isOpen={isBulkUploadOpen} onClose={()=>{
                onBulkUploadClose();
                setBulkUploads([]);
                reset();
              }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Upload Form 16</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>

              {FinancialYear !== undefined && <div className="flex justify-center items-center w-full my-10">
                <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
                  <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">only PDFs are accepted</p>
                  </div>
                  <Input id="dropzone-file" hidden type="file" multiple accept="application/pdf" onChange={({target})=>{HandleBulkUpload(target)}} />
                </label>
              </div> }

              {BulkUploads?.length !== 0 &&<div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-white uppercase bg-slate-700 whitespace-nowrap">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                       File Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                       File Pan Number
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Financial Year
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Employee Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                       Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {BulkUploads?.map((data,i)=>{
                      return <tr key={i} className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                      <th
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                      >
                        {data.name}
                      </th>
                      <td className="py-4 px-6">
                        {data.panNumber === "Not Found" ? <span className="bg-red-500 px-1.5 py-1 rounded-lg text-white font-semibold whitespace-nowrap">Wrong File Name</span> : data.panNumber}
                      </td>
                      <td className="py-4 px-6">
                       {data.fileFinanYear  === "Not Found" ? <span className="bg-red-500 px-1.5 py-1 rounded-lg text-white font-semibold whitespace-nowrap">Wrong Financial Year</span> : data.fileFinanYear}
                      </td>
                      <td className="py-4 px-6">
                        {data.fullName  === "Not Found" ? <span className="bg-red-500 px-1.5 py-1 rounded-lg text-white font-semibold whitespace-nowrap">Employee Not Found</span> : data.fullName}
                      </td>
                      <td className="py-4 px-6">
                        <Button onClick={()=>{
                           const objectUrl = URL.createObjectURL(data.file)
                           setPdfPath(objectUrl);
                           onViewForm16Open();
                        }} leftIcon={<Eye size={'16px'} />} size={'xs'} colorScheme="blue" >View</Button>
                      </td>
                    </tr>
                    })}
                  </tbody>
                </table>
              </div>}

            </ModalBody>
            <hr />
            <ModalFooter>
            <Button isDisabled={ BulkUploads.length === 0 && FinancialYear === undefined ? true : false} onClick={()=>{
              SaveBulkForm16();
            }} colorScheme='purple' mr={4} >
                Save
              </Button>
              <Button variant={'outline'} colorScheme='purple' onClick={()=>{
                onBulkUploadClose();
                setBulkUploads([]);
              }}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

      </div>
    );
  }
  
  export default AddForm16;
  