import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Download, Eye } from "react-feather";
import { Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function EmpForm16() {

    const { userData } = useAuth();
    const gridRef = useRef();

    const {
      isOpen: isViewForm16Open,
      onOpen: onViewForm16Open,
      onClose: onViewForm16Close,
    } = useDisclosure();
  
  
    const [rowData, setRowData] = useState([]);
    const [PdfPath, setPdfPath] = useState();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs] = useState([
        {
          headerName: "Employee Code",
          field: "empCode",
          sort: 'alphanumeric'
        },
        {
          headerName: "Employee Name",
          field: "fullName",
          valueGetter: (params) => (params.data.title || '') + ' ' + params.data.fullName
        },
        {
          headerName: "FinancialYear",
          field: "financialYearName",
        },
        {
          headerName: "Action",floatingFilter: false,
          cellRenderer : (params) => <div className="space-x-4">
           <Button leftIcon={<Eye size={'14px'} />} size={'xs'} onClick={()=>{
                    handleViewFile(params.data.attachmentFile);
                 }} colorScheme='blue'>View</Button>
                 <a href={params.data.path} download>
          <Button onClick={()=>{handleDownloadFile(params.data.attachmentFile)}} leftIcon={<Download size={'14px'} />} size={'xs'} colorScheme='blue'>Download</Button>
          </a>
        </div>
        },
      ]);



 // Table Pagination
      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    

        //Table columns properties applies to all columns
        const defaultColDef = useMemo(() => ({
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cacheQuickFilter: true,
          cellClass: 'no-border',
          resizable: true,
          enableCellTextSelection: true
        }));

useEffect(() => {
  getEmpForm16List();
}, [])

const getEmpForm16List = async () => {
  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Form16Document/GetForm16DocumentListByEmpGuid?EmpGuid=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log(response);
    const res = _.sortBy(response.data, [(o) => -o.id]);

    setRowData(res);

  } catch (error) {
    console.error(error);
  }
}

const handleViewFile = async (DocPath) =>{
  console.log("Document path",DocPath)
 await fetch(
    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
).then(response => {

  console.log("file Response",response);

  response.blob().then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);

      // Setting various property values

      let alink = document.createElement('a');
      alink.href = fileURL;
      //  alink.download = fileURL;
       setPdfPath(alink.href)
       onViewForm16Open();
       // alink.click();
  })

}).catch((error)=>{
  console.log("File Api Error",error);
})
}

const handleDownloadFile = async (DocPath) =>{
  console.log("Document path",DocPath)
 await fetch(
    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
).then(response => {

  console.log("file Response",response);

  response.blob().then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);

      // Setting various property values

      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `${userData.name}`;
      alink.click();
  })

}).catch((error)=>{
  console.log("File Api Error",error);
})
}

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Form 16</h1>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          rowSelection={'multiple'}
          enableCellTextSelection={true}
        />
      </div>

      <Modal size='full' scrollBehavior="inside" isOpen={isViewForm16Open} onClose={onViewForm16Close}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Form 16</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
              <div id="pdf-div"  >
               <embed
                 src={PdfPath + "#toolbar=0"}
                 type="application/pdf"
                 className="h-screen w-full !select-none"
                 height={800}
                 width={500}
               />
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button colorScheme='purple' onClick={onViewForm16Close}>
                Close
              </Button>
  
            </ModalFooter>
          </ModalContent>
        </Modal>

    </div>
  );
}

export default EmpForm16