import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, useToast, Tabs, TabList, TabPanels, Tab, TabPanel, Center, Spinner,  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,useDisclosure, Tooltip } from "@chakra-ui/react";
import ReactSelect from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import { PlusCircle, XCircle } from "react-feather";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function AssignShift() {

  const { userData } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const id = "toast";

  const GetDatesArray = (StartDate,EndDate) => {

    for (
      var a = [
        { headerName: "Emp Code", field: "empCode"},
        { headerName: "Emp Name", field: "fullName"}
      ], d = new Date(StartDate);
      d <= new Date(EndDate);
      d.setDate(d.getDate() + 1)
    ) {
      // a.push(new Date(d));
      a.push({
        headerName : `${moment(d).format("MMM D")}`,
        field : moment(d).format("YYYY-MM-DD"),
        autoHeight:true
      });

    }
    return a;

  }

  const [ShiftEmpColumnDefs, setShiftEmpColumnDefs] = useState([]);
  console.log("Shift Column Defs",ShiftEmpColumnDefs);
  
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridRef = useRef();

  const [currentDate, setCurrentDate] = useState(moment());

  const [SelEmpAssign, setSelEmpAssign] = useState([])

  const [currentMonth, setCurrentMonth] = useState(moment().startOf("month"));

  const [viewMode, setViewMode] = useState("week");
  
  // Calculate the start and end dates of the current week
  const weekStart = currentDate.clone().startOf("week");
  const weekEnd = currentDate.clone().endOf("week");

  // console.log("Week Start",weekStart.format("YYYY-MM-DD"),"Week End",weekEnd.format("YYYY-MM-DD"));

  // Generate an array of date strings for the current week
  const dateStrings = [];

  if(viewMode === 'week'){
    for (let date = weekStart; date <= weekEnd; date.add(1, "day")) {
      dateStrings.push(date.format("YYYY-MM-DD"));
  }

  }else{
   const daysInMonth = currentMonth.daysInMonth();
    for (let day = 1; day <= daysInMonth; day++) {
      const dateString = currentMonth.clone().date(day).format("YYYY-MM-DD");
      dateStrings.push(dateString);
    }
  }


  console.log("Current Date",currentDate.format('YYYY-MM-DD'),"Current Month",currentMonth.format('YYYY-MM-DD'));

  const [IsLoading, setIsLoading] = useState(false);


  const [ShiftEmployees, setShiftEmployees] = useState([]);

  const [ShiftList, setShiftList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);

  const [ShiftAssignDate, setShiftAssignDate] = useState(new Date());

  const [SelectedShift, setSelectedShift] = useState([]);
  const [SelectedProject, setSelectedProject] = useState([]);
  const [SelectedEmployee, setSelectedEmployee] = useState([]);

  const [SelectedData, setSelectedData] = useState([])
  const [SelectedColumnDefs, setSelectedColumnDefs] = useState([
    { headerName: "Emp Code", field: "empCode"},
    { headerName: "Emp Name", field: "fullName"},
    { headerName: "Designation", field: "designationName"},
    { headerName: "Department", field: "departmentName"},
  ])
  const [EmployeeList, setEmployeeList] = useState([]);
  const [EmpColumnDefs, setEmpColumnDefs] = useState([
    { headerName: "Emp Code", field: "empCode" , headerCheckboxSelection: true, checkboxSelection:true},
    { headerName: "Emp Name", field: "fullName"},
    { headerName: "Designation", field: "designationName"},
    { headerName: "Department", field: "departmentName"},
  ])


  const onSelectionChanged = useCallback(() => {
    var SelData = gridRef.current.api.getSelectedRows();
    console.log("Selected Emp",SelData);
    console.log("Data Size",SelData.length,"Team Size",SelectedProject.teamSize);
    setSelectedData(SelData);
  }, []);

    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    

      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
         floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: "no-border",
        resizable: true
      }));


      // console.log("Date String",dateStrings);

      const handlePrevWeek = () => {
        if(viewMode === "week"){
          setCurrentDate((prevDate) => prevDate.clone().subtract(1, "week"));
          let PrevWeek = currentDate.clone().subtract(1,'week');
          GetShiftEmployees(PrevWeek.startOf("week").format('YYYY-MM-DD'),PrevWeek.endOf("week").format('YYYY-MM-DD'),SelectedProject.length !== 0 ? 'Project':'Dates');
        }else{
          setCurrentMonth((prevMonth) => prevMonth.clone().subtract(1, "month"));
          let PreviousMonth = currentMonth.clone().subtract(1, "month")
          GetShiftEmployees(PreviousMonth.startOf("month").format('YYYY-MM-DD'),PreviousMonth.endOf("month").format('YYYY-MM-DD'),SelectedProject.length !== 0 ? 'Project':'Dates');
        }
      };
    
      const handleNextWeek = () => {
        if(viewMode === "week"){
          setCurrentDate((prevDate) => prevDate.clone().add(1, "week"));
          let NextWeek = currentDate.clone().add(1, "week")
           GetShiftEmployees(NextWeek.clone().startOf("week").format('YYYY-MM-DD'),NextWeek.clone().endOf("week").format('YYYY-MM-DD'),SelectedProject.length !== 0 ? 'Project':'Dates');
          }else{
          setCurrentMonth((prevMonth) => prevMonth.clone().add(1, "month"));
          let NextMonth = currentMonth.clone().add(1, "month")
          GetShiftEmployees(NextMonth.startOf("month").format('YYYY-MM-DD'),NextMonth.endOf("month").format('YYYY-MM-DD'),SelectedProject.length !== 0 ? 'Project':'Dates');
        }
      };

      console.log("Dates Array",GetDatesArray(moment().startOf("week").format('YYYY-MM-DD'),moment().endOf("week").format('YYYY-MM-DD')));

  useEffect(() => {
    GetProjectList();
    GetShiftEmployees(moment().startOf("week").format('YYYY-MM-DD'),moment().endOf("week").format('YYYY-MM-DD'));
  }, [])


  
  const GetProjectList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftProjectMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("PRoject List Response",response);
        const res = response.data;

        setProjectList(res.filter(data => data.isActive === true));
    } catch (error) {
      console.error(error);
    }
  }

  const GetShiftMasterList = async (ProjectId) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftMasterListbyProjID?ShiftPojectID=${ProjectId}`
        )
        console.log("Shift List Response",response);
        const res = response.data;

        setShiftList(res.filter(data => data.isActive === true));
    } catch (error) {
      console.error(error);
    }
  }



  
  const HandleAssignEmp = async () => {
    try{
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftProjectEmpLinkByProj?ShiftPojectID=${SelectedProject.shiftPojectID}`
        )
        console.log("Employee List Response",response);
        const res = response.data;
        // setEmployeeList(res);
        GetAssignShiftData(res);
    }catch(error){
       console.error(error);
    }
   }


   const GetAssignShiftData = async (AllEmp) => {
    try {
      let body = {
        shiftPojectID: SelectedProject.shiftPojectID,
        shiftStartDate : ShiftAssignDate,
        shiftEndDate : ShiftAssignDate,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch
      }
      console.log("Assigned Data Body",body);
      const response = await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftAssignListbyProj`,body
      )
      console.log("Shift Emp Response",response.data.shifts);
      const res = response.data.shifts;
      let ShiftEmps = res.filter((data)=>data.shiftMasterId === SelectedShift.shiftMasterId)
      console.log("Modified Row Data",AllEmp.filter(obj1 => !ShiftEmps.find(obj2 => obj1.guId === obj2.empGuid)));
      setEmployeeList(AllEmp.filter(obj1 => !ShiftEmps.find(obj2 => obj1.guId === obj2.empGuid)))
  
    } catch (error) {
      console.error(error);
    }

   }



  const GetShiftEmployees = async (DataStartDate,DataEndDate,SearchBy) => {
    if(SearchBy === 'Project'){
      try {
        let body = {
          shiftPojectID: SelectedProject.shiftPojectID,
          shiftStartDate : DataStartDate,
          shiftEndDate : DataEndDate,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
        }
        console.log("Get Shift by Project Body",body);
        const response = await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftAssignListbyProj`,body
        )
        console.log("Shift Emp Response",response.data.shifts);
        const res = response.data.shifts;
        console.log("Modified Row Data",res);
        setShiftEmployees(response.data.shifts);
      } catch (error) {
        console.error(error);
      }
    }else{
      try {
        let body = {
          shiftStartDate : DataStartDate,
          shiftEndDate : DataEndDate,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
        }
  
        console.log("Get Shift Body",body);
        
        const response = await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftAssignListbyDate`,body
        )
        console.log("Shift Emp Response",response.data);
        setShiftEmployees(response.data.shifts);
      } catch (error) {
        console.error(error);
      }
    }
  }

   console.log("Selected Employees",SelectedEmployee);


  const SaveAssignShift = async () => {

      let body = SelectedData.map((data)=>{
        return {
          empGuid : data.guId,
          shiftName : SelectedShift.shiftName,
          shiftMasterId : SelectedShift.shiftMasterId,
          shiftStartDate : moment(ShiftAssignDate).format('YYYY-MM-DD'),
          shiftEndDate : moment(ShiftAssignDate).format('YYYY-MM-DD'),
          shiftInTime : SelectedShift.shiftInTime,
          shiftOutTime : SelectedShift.shiftOutTime,
          shiftType : SelectedShift.shiftType,
          shiftPojectID : SelectedProject.shiftPojectID,
          projectName : SelectedProject.shiftProjectName,
          shiftLocation : SelectedProject.projectLocation,
          isActive : true,
          shiftStatus : "Assigned",
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
       }
      })

      console.log("Assign Save Body",body);

      await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/SaveShiftAssignData`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        setSelectedProject([]);
        setSelectedData([]);
        setEmployeeList([]);
        setSelectedShift([]);

        if(viewMode === 'week'){
          GetShiftEmployees(moment(ShiftAssignDate).startOf('week').format('YYYY-MM-DD'),moment(ShiftAssignDate).endOf('week').format('YYYY-MM-DD'));
        }else{
          GetShiftEmployees(moment(ShiftAssignDate).startOf('month').format('YYYY-MM-DD'),moment(ShiftAssignDate).endOf('month').format('YYYY-MM-DD'));
        }

      })
      .catch((error) => {
        console.log(error.response);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Shift Not Assigned to Employee",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });

  }


  const SaveEmpShiftAssign = async () => {

    let checkBody = {
      empGuid : SelEmpAssign.empGuid,
      shiftStartDate : moment(SelEmpAssign.assignDate).format('YYYY-MM-DD'),
      shiftEndDate : moment(SelEmpAssign.assignDate).format('YYYY-MM-DD'),
      companyId : userData.companyId,
      branchId : userData.isActiveBranch
    }

    console.log("Check Shift Body",checkBody);
    
    try {
      const response = await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftAssignListbyEmp`,checkBody
      )

      console.log(response);
      const res = response.data.shifts
      if(res.some(data => Number(data.shiftMasterId) === Number(SelectedShift.shiftMasterId) && data.shiftStatus !== "Cancelled")){

        alert(`This Shift is already Assigned to this Employee. Please try to assign another shift!`)
        
      }else{

        let body = [{
          shiftAssignId: SelEmpAssign.shiftAssignId,
          empGuid : SelEmpAssign.empGuid,
          shiftName : SelectedShift.shiftName,
          shiftMasterId : SelectedShift.shiftMasterId,
          shiftStartDate : moment(SelEmpAssign.assignDate).format('YYYY-MM-DD'),
          shiftEndDate : moment(SelEmpAssign.assignDate).format('YYYY-MM-DD'),
          shiftInTime : SelectedShift.shiftInTime,
          shiftOutTime : SelectedShift.shiftOutTime,
          shiftType : SelectedShift.shiftType,
          shiftPojectID : SelectedProject.shiftPojectID,
          projectName : SelectedProject.shiftProjectName,
          shiftLocation : SelectedProject.projectLocation,
          isActive : true,
          shiftStatus : "Assigned",
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
       }]
       console.log("Emp Shift Assign Body",body);
    
       await authApi
       .post(
         `${process.env.REACT_APP_ACQ_URL}/api/Shift/SaveShiftAssignData`,
         body
       )
       .then((response) => {
         console.log(response);
         const res = response.data;
         console.log("res", res);
         if (!toast.isActive(id)) {
           toast({
             id,
             title: res,
             position: "top",
             status: "info",
             duration: 2000,
             isClosable: true,
           });
         }
    
         if(viewMode === 'week'){
          GetShiftEmployees(moment(SelEmpAssign.assignDate).startOf('week').format('YYYY-MM-DD'),moment(SelEmpAssign.assignDate).endOf('week').format('YYYY-MM-DD'));
        }else{
          GetShiftEmployees(moment(SelEmpAssign.assignDate).startOf('month').format('YYYY-MM-DD'),moment(SelEmpAssign.assignDate).endOf('month').format('YYYY-MM-DD'));
        }
    
         setSelectedProject([]);
         setSelectedData([]);
         setSelectedShift([])
         setEmployeeList([]);
         onClose();
    
       })
       .catch((error) => {
         console.log(error.response);
         if (!toast.isActive(id)) {
           toast({
             id,
             title: "ERROR",
             description: "Shift Not Assigned to Employee",
             position: "top",
             status: "error",
             duration: 2000,
             isClosable: true,
           });
         }
       })

       
      }




    } catch (error) {
      console.error(error);
    }


  }



  return (
    <div>


<Tabs size={'sm'} variant={'solid-rounded'} colorScheme={'purple'}>
  <TabList>
    <Tab>View Assigned Shifts</Tab>
    <Tab>Assign Shift</Tab>
  </TabList>

  <TabPanels>
  <TabPanel px={0}>
    <div className="bg-white p-4 rounded-2xl">

        <div className="flex justify-between items-center">
            <div className="space-x-4">
                <Button leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} onClick={handlePrevWeek} colorScheme="purple">Prev</Button>
                <Button rightIcon={<FontAwesomeIcon icon={faChevronRight} />} onClick={handleNextWeek} colorScheme="purple">Next</Button>
            </div>
            <div>
              {viewMode === 'week' ? 
                <h2 className="font-semibold text-xl">{`${moment(currentDate).startOf("week").format('MMM D, YYYY')} - ${moment(currentDate).endOf("week").format('MMM D, YYYY')}`}</h2> :
                <h2 className="font-semibold text-xl">{`${moment(currentMonth).startOf("month").format('MMM D, YYYY')} - ${moment(currentMonth).endOf("month").format('MMM D, YYYY')}`}</h2> }
            </div>
            <div className="space-x-4">
                <Button variant={viewMode === "week" ? 'solid':'outline'} 
                 onClick={()=>{
                   setViewMode('week');
                   GetShiftEmployees(moment(currentDate).startOf("week").format('YYYY-MM-DD'),moment(currentDate).endOf("week").format('YYYY-MM-DD'))
                   }} colorScheme="purple">Week</Button>
                <Button variant={viewMode === "month" ? 'solid':'outline'} 
                 onClick={()=>{
                   setViewMode('month');   
                   GetShiftEmployees(moment(currentMonth).startOf("month").format('YYYY-MM-DD'),moment(currentMonth).endOf("month").format('YYYY-MM-DD'))
                 }} colorScheme="purple">Month</Button>
            </div>
        </div>
        <div className="flex my-4 gap-4">

     <div>
      <ReactSelect
        options={ProjectList}
        value={SelectedProject}
        onChange={(value) => {
          setSelectedProject(value);
        }}
        getOptionLabel={(option) => option.shiftProjectName}
        getOptionValue={(option) => option.shiftPojectID}
        placeholder="Select Project"
      />
     </div>
          <Button isDisabled={SelectedProject.length === 0 ? true : false} leftIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />} onClick={()=>{
            if(viewMode === 'week'){
              GetShiftEmployees(currentDate.startOf('week').format('YYYY-MM-DD'),currentDate.endOf('week').format('YYYY-MM-DD'),'Project')
            }else{
              GetShiftEmployees(moment(currentMonth).startOf("month").format('YYYY-MM-DD'),moment(currentMonth).endOf("month").format('YYYY-MM-DD'),'Project')
            }
          }} colorScheme="purple" >Search</Button>
        </div>

        {IsLoading ? <Center>
          <Spinner  thickness='4px' color={'purple.500 '}  size='lg'  />
        </Center> : 
        <>  {ShiftEmployees.length === 0 ? 
         <div className="text-center my-4">
           <h2 className="text-xl font-semibold">No Assigned Shift Found!!</h2>
         </div>
        : 
        <>
        <div className="my-4  overflow-x-auto  shadow-md sm:rounded-lg">
                          <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-white uppercase bg-slate-700 ">
                              <tr>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                                  Employee Name
                                </th>
                                {dateStrings.map((dateString) => (
                                  <th scope="col" className="py-3 px-6" key={dateString}>{moment(dateString).format('MMM D')}</th>
                                ))}
                              </tr>
                            </thead>
                           <tbody>

                                {_.uniqBy(ShiftEmployees, "empGuid")?.map((data,i)=> {
                                  return <tr key={i} className="bg-white border-b text-gray-700 odd:bg-gray-100 even:bg-gray-200">
                                     <th
                                       scope="row"
                                       className="py-2 px-6 font-medium text-gray-900 whitespace-nowrap "
                                     >
                                       <div>
                                         <h1 className="text-gray-800 font-semibold">{data.fullName}</h1>
                                         <h6 className="text-gray-600 font-normal">{data.empCode}</h6>
                                       </div>
                                     </th>

                                     {dateStrings.map((datesData,j)=>{
                                       let EmployeeShifts = ShiftEmployees.filter((employeedata) => employeedata.empGuid === data.empGuid && employeedata.shiftStatus !== "Cancelled");
                                       if(EmployeeShifts.some(data => moment(data.shiftStartDate).format('YYYY-MM-DD') === moment(datesData).format('YYYY-MM-DD'))){
                                          // console.log("Shift Data", EmployeeShifts.filter(data => moment(data.shiftStartDate).format('YYYY-MM-DD') === moment(datesData).format('YYYY-MM-DD')));
                                          let AllShiftData = EmployeeShifts.filter(data => moment(data.shiftStartDate).format('YYYY-MM-DD') === moment(datesData).format('YYYY-MM-DD'))
                                        return  <td key={j} className="py-2 px-6">
                                        {AllShiftData.map((ShiftData,k)=>{
                                          return  <div key={k} className={`mb-1 last:mb-0 border-l-4 ${ShiftData.shiftType === "Shift A" ? 'bg-purple-200' : ShiftData.shiftType === "Shift B" ? 'bg-orange-200' : ShiftData.shiftType === "Shift C" ? 'bg-red-200' : ShiftData.shiftType === "General Shift" ? 'bg-green-200' : 'bg-blue-200' } p-1 ${ShiftData.shiftType === "Shift A" ? 'border-purple-400' : ShiftData.shiftType === "Shift B" ? 'border-orange-400' : ShiftData.shiftType === "Shift C" ? 'border-red-400' : ShiftData.shiftType === "General Shift" ? 'border-green-400' : 'border-blue-400' } bg-opacity-60 rounded-lg`}>
                                               <h1 className="text-gray-800 font-semibold whitespace-nowrap">{moment(ShiftData.shiftInTime, "HH:mm:ss").format("hh:mm A")} - {moment(ShiftData.shiftOutTime, "HH:mm:ss").format("hh:mm A")}</h1>
                                               <h6 className="text-gray-600 text-xs font-normal">{ShiftData.shiftName}</h6>
                                               <div className="flex justify-between items-center">
                                                <p className="text-gray-600 text-xs font-normal">{ShiftData.projectName}</p>
                                                <div className="flex gap-1">
                                                <Tooltip placement='top' rounded='5px' hasArrow label='Add Shift' bg='gray.700' color='white'>
                                                 <PlusCircle 
                                                   onClick={()=>{
                                                     setSelEmpAssign({
                                                       empGuid:data.empGuid,
                                                       fullName:data.fullName,
                                                       empCode:data.empCode,
                                                       assignDate:datesData,
                                                      // shiftAssignId:ShiftData.shiftAssignId,
                                                       shiftAssignId:0
                                                     })
                                                     onOpen();
                                                   }}
                                                  size={'13px'}  className="text-blue-600 cursor-pointer" />
                                                </Tooltip>
                                                <Tooltip placement='top' rounded='5px' hasArrow label='Cancel Shift' bg='gray.700' color='white'>
                                                 <XCircle onClick={async()=>{
                                                   if(window.confirm('Are you sure you want to cancel shift for this employee ?')){
                                                     let body = [{
                                                       shiftAssignId: ShiftData.shiftAssignId,
                                                       empGuid : ShiftData.empGuid,
                                                       shiftName : ShiftData.shiftName,
                                                       shiftMasterId : ShiftData.shiftMasterId,
                                                       shiftStartDate : moment(ShiftData.shiftStartDate).format('YYYY-MM-DD'),
                                                       shiftEndDate : moment(ShiftData.shiftEndDate).format('YYYY-MM-DD'),
                                                       shiftInTime : ShiftData.shiftInTime,
                                                       shiftOutTime : ShiftData.shiftOutTime,
                                                       shiftType : ShiftData.shiftType,
                                                       shiftPojectID : ShiftData.shiftPojectID,
                                                       projectName : ShiftData.projectName,
                                                       shiftLocation : ShiftData.shiftLocation,
                                                       isActive : ShiftData.isActive,
                                                       shiftStatus : "Cancelled",
                                                       companyId : userData.companyId,
                                                       branchId : userData.isActiveBranch
                                                    }]
                                                    console.log("Emp Shift Assign Body",body);

                                                    await authApi
                                                    .post(
                                                      `${process.env.REACT_APP_ACQ_URL}/api/Shift/SaveShiftAssignData`,
                                                      body
                                                    )
                                                    .then((response) => {
                                                      console.log(response);
                                                      const res = response.data;
                                                      console.log("res", res);
                                                      if (!toast.isActive(id)) {
                                                        toast({
                                                          id,
                                                          title: res,
                                                          position: "top",
                                                          status: "info",
                                                          duration: 2000,
                                                          isClosable: true,
                                                        });
                                                      }

                                                      if(viewMode === 'week'){
                                                        GetShiftEmployees(moment(datesData).startOf('week').format('YYYY-MM-DD'),moment(datesData).endOf('week').format('YYYY-MM-DD'));
                                                      }else{
                                                        GetShiftEmployees(moment(datesData).startOf('month').format('YYYY-MM-DD'),moment(datesData).endOf('month').format('YYYY-MM-DD'));
                                                      }
                                                    })
                                                    .catch((error) => {
                                                      console.log(error.response);
                                                      if (!toast.isActive(id)) {
                                                        toast({
                                                          id,
                                                          title: "ERROR",
                                                          description: "Shift Not Cancelled",
                                                          position: "top",
                                                          status: "error",
                                                          duration: 2000,
                                                          isClosable: true,
                                                        });
                                                      }
                                                    })
                                                   }
                                                 }} size={'13px'}  className="text-red-600 cursor-pointer" />
                                                </Tooltip>

                                                </div>

                                               </div>
                                           </div>
                                          })}
                                          </td>


                                       }else{
                                       return <td key={j} className="py-4 px-6">
                                             <Tooltip placement='top' rounded='5px' hasArrow label='Assign Shift' bg='gray.700' color='white'>

                                            <Button 
                                            onClick={()=>{
                                              setSelEmpAssign({
                                                empGuid:data.empGuid,
                                                fullName:data.fullName,
                                                empCode:data.empCode,
                                                assignDate:datesData,
                                                shiftAssignId:0
                                              })
                                              onOpen();
                                            }}
                                             variant={'outline'} size={'xs'} leftIcon={<FontAwesomeIcon icon={faPlus} />} colorScheme="blue">Assign</Button>
                                             </Tooltip>
                                         </td>
                                       }

                                     })}


                                   </tr>
                                })}

                                </tbody>

                            
                          </table>
         </div>
        </> }
        </>
 }

      </div>
    </TabPanel>
    <TabPanel px={0}>
    <div className={`bg-white rounded-2xl p-4 drop-shadow-md ${EmployeeList.length === 0 ? "mb-64" : "mb-4"}`}>
        <div className='flex gap-4 items-end'>

        <div className='space-y-2'>
          <label>Select Project</label>
          <ReactSelect
            options={ProjectList}
            value={SelectedProject}
            onChange={(value) => {
              setSelectedProject(value);
              GetShiftMasterList(value.shiftPojectID);
            }}
            getOptionLabel={(option) => option.shiftProjectName}
            getOptionValue={(option) => option.shiftPojectID}
            placeholder="Select Project"
          />
        </div>

        <div className='space-y-2'>
          <label>Select Shift</label>
          <ReactSelect
            isDisabled={SelectedProject.length === 0 ? true : false}
            options={ShiftList}
            value={SelectedShift}
            onChange={(value)=>{
              setSelectedShift(value);
            }}
            getOptionLabel={(option) => option.shiftName}
            getOptionValue={(option) => option.shiftMasterId}
            placeholder="Select Shift"
          />
        </div>

        <div className="space-y-2">
          <label>Assign for Date</label>
          <DatePicker
    required
    className="bg-white border  border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={ShiftAssignDate}
    onChange={(date) =>{
      setShiftAssignDate(date);
    }}
    minDate={new Date(SelectedProject.projectStartDate)}
    maxDate={new Date(SelectedProject.projectEndDate)}
    placeholderText="Assign Shift for Date"
    dateFormat={"dd-MM-yyyy"}
  />

        </div>

<Button isDisabled={SelectedProject.length === 0 && SelectedShift.length === 0 ? true : false} onClick={HandleAssignEmp} colorScheme='purple'>Assign Employees</Button>

        </div>
      </div>


      {IsLoading ?
        <Center>
          <Spinner  thickness='4px' color={'purple.500 '}  size='lg'  />
        </Center> 
      :  <>

{ EmployeeList.length !== 0 ? <>

<div className="bg-white rounded-2xl p-4 mb-4">

  
<div className="flex py-2 gap-8">

<div className="space-y-1">
  <p className="text-sm text-gray-500">Project Name</p>
  <h6 className="font-medium">{SelectedProject.shiftProjectName}</h6>
 </div>

 <div className="space-y-1">
  <p className="text-sm text-gray-500">Location</p>
  <h6 className="font-medium">{SelectedProject.projectLocation}</h6>
 </div>

 <div className="space-y-1">
  <p className="text-sm text-gray-500">Shift Name</p>
  <h6 className="font-medium">{SelectedShift.shiftName}</h6>
 </div>

 <div className="space-y-1">
  <p className="text-sm text-gray-500">Shift Type</p>
  <h6 className="font-medium">{SelectedShift.shiftType}</h6>
 </div>

 <div className="space-y-1">
  <p className="text-sm text-gray-500">In Time</p>
  <h6 className="font-medium">{moment(SelectedShift.shiftInTime, "HH:mm:ss").format("hh:mm A")}</h6>
 </div>       
 <div className="space-y-1">
  <p className="text-sm text-gray-500">Out Time</p>
  <h6 className="font-medium">{moment(SelectedShift.shiftOutTime, "HH:mm:ss").format("hh:mm A")}</h6>
 </div> 
 
</div>

</div>


<div className='grid grid-cols-2 gap-6 '>
<div>
<div className=" border-b border-gray-400 mb-4 pb-2 text-left">
<h1 className="text-gray-700 text-lg font-bold ">
All Employees
</h1>
</div>

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={EmployeeList} // Row Data for Rows
          columnDefs={EmpColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          rowSelection='multiple'
          paginationNumberFormatter={paginationNumberFormatter}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
</div>

<div>
<div className=" flex items-center justify-between border-b border-gray-400 mb-4 pb-2 text-left">
<h1 className="text-gray-700 text-lg font-bold ">
Assigned Employees
</h1>
</div>

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={SelectedData} // Row Data for Rows
          columnDefs={SelectedColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
</div>

</div>


<div className='text-right'>
    <div className='space-x-4'>
        <Button 
        onClick={()=>{
          setSelectedProject([]);
          setSelectedShift([]);
          setSelectedData([]);
          setEmployeeList([]);
        }} variant={'outline'} colorScheme='purple'>Cancel</Button>
        <Button onClick={SaveAssignShift} colorScheme='purple'>Save</Button>
    </div>
</div>
</> : ''}

</> }

    </TabPanel>


  </TabPanels>
</Tabs>





<Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Shift</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>

          <div className="flex py-2 gap-8">
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Employee Name</p>
                      <h6 className="font-medium">{SelEmpAssign.fullName}</h6>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Emp Code</p>
                      <h6 className="font-medium">{SelEmpAssign.empCode}</h6>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Assigning for Date</p>
                      <h6 className="font-medium">{moment(SelEmpAssign.assignDate).format('DD/MM/YYYY')}</h6>
                    </div>
                  </div>
                  <hr />

                  <div className="space-y-4 my-2">
                  <div className='space-y-2'>
          <label>Select Project</label>
          <ReactSelect
            options={ProjectList}
            value={SelectedProject}
            onChange={(value) => {
              setSelectedProject(value);
              GetShiftMasterList(value.shiftPojectID);
            }}
            getOptionLabel={(option) => option.shiftProjectName}
            getOptionValue={(option) => option.shiftPojectID}
            placeholder="Select Project"
          />
        </div>

        <div className='space-y-2'>
          <label>Select Shift</label>
          <ReactSelect
            isDisabled={SelectedProject.length === 0 ? true : false}
            options={ShiftList}
            value={SelectedShift}
            onChange={(value)=>{
              setSelectedShift(value);
            }}
            getOptionLabel={(option) => option.shiftName}
            getOptionValue={(option) => option.shiftMasterId}
            placeholder="Select Shift"
          />
        </div>
                  </div>
            
            
          </ModalBody>
<hr />
          <ModalFooter>
            <Button variant='outline' colorScheme='purple' mr={3} 
             onClick={()=>{
                   setSelectedProject([]);
                   setSelectedData([]);
                   setSelectedShift([])
                   setEmployeeList([]);
                   onClose();
             }}>
              Cancel
            </Button>
            <Button isDisabled={SelectedProject.length === 0 && SelectedShift.length === 0 ? true :false} onClick={SaveEmpShiftAssign} variant='solid' colorScheme="purple">Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </div>
  )
}

export default AssignShift