import React, {useState,useRef, useMemo,useCallback, useEffect} from 'react'
import { Input, Button, Switch, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, Select } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit, Plus, Search } from "react-feather";
import { useAuth } from '../Utils/AuthContext';
import moment from 'moment';
import _ from 'lodash';
import usePost from '../Utils/usePost';
import DatePicker from "react-datepicker";
import { HolidayMasterSchema } from '../Utils/FormValidations';
import authApi from '../Utils/AuthApis';
import useFetch from '../Utils/useFetch';

export default function HolidayMaster() {

   const { userData } = useAuth();
   const [IsSearching, setIsSearching] = useState(false);
   const [SelectedYear, setSelectedYear] = useState(new Date().getFullYear());
   const { IsPostLoading, PostData } = usePost('/api/Attendance/SaveHolidayMaster');
   const { IsFetchLoading: IsHolidayListLoading, FetchedData: HolidayListData, Refetch: RefetchHolidayListData } = useFetch('/api/Home/GetHolidayMasterList',{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
   })

   const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); 
   const gridRef = useRef();
   const [rowData, setRowData] = useState([]); 
   const { isOpen: isdraweropen, onOpen: ondraweropen, onClose: ondrawerclose } = useDisclosure()
   const [FilteredRowData, setFilteredRowData] = useState([]);
   const [FormErrors, setFormErrors] = useState({});
   const [HolidayFormData, setHolidayFormData] = useState({
    holidayMasterId: 0,
    holidayDate: '',
    remark: '',
    holidayName: '',
    backgroundColor: '#000',
    fontColor: '#000',
    shortName: '',
    isActive: true,
    companyId: userData?.companyId,
    branchId: userData?.isActiveBranch
   });



    const [columnDefs] = useState([
        {
          headerName: "Holiday Name",
          field: "holidayName",
        },
        {
          headerName: "Holiday Date",
          field: "holidayDate",
          cellRenderer:({data}) => moment(data.holidayDate).format('DD-MMM-YYYY')
        },
        { 
          headerName: "IsActive", 
          field: "isActive" ,
          floatingFilter: false,
          cellRenderer:(params) => <Switch colorScheme='green' isChecked={params.data.isActive} onChange={({target})=>{HandleHolidaySwitch(target.checked,params.data)}}  size='md' />
        },
        {
          headerName: "Action",
          floatingFilter: false,
          cellRenderer: ({data}) => new Date(data.holidayDate) > new Date() && <div className="space-x-4">
             <Button  variant='solid' size='xs' colorScheme='blue' 
              onClick={()=>{
                setHolidayFormData({
                  ...data,
                  holidayDate: new Date(data.holidayDate)
                })
               ondraweropen();
              }} leftIcon={<Edit size='14px'/>}>Edit</Button>
          </div>
        },
      ]); 
    
      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      //Table columns properties applies to all columns
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border',
        resizable: true
      })); 


      useEffect(()=>{
        if(HolidayListData?.length > 0){
          setFilteredRowData(HolidayListData.filter((data)=> new Date(data.holidayDate).getFullYear() === parseInt(SelectedYear)));
        }
      },[HolidayListData])



    const HandleHolidaySwitch = async (checked,holidayData) => {

      let body = {
        srNo: holidayData.srNo,
        holidayMasterId: holidayData.holidayMasterId,
        holidayDateText: holidayData.holidayDateText,
        holidayDate: holidayData.holidayDate,
        remark: holidayData.remark,
        holidayName: holidayData.holidayName,
        isActive: checked,
        companyId: holidayData.companyId,
        branchId: holidayData.branchId,
        deleted: holidayData.deleted,
        backgroundColor: holidayData.backgroundColor,
        fontColor: holidayData.fontColor,
        shortName: holidayData.shortName
      }
      console.log("Holiday Switch Body",body);
      const response = await PostData(body);
      if(response){
        RefetchHolidayListData();
      }

    }


    const SaveHolidayMaster = async (e) => {
      e.preventDefault();

      HolidayMasterSchema.validate(HolidayFormData,{abortEarly:false})
      .then(async() => {

      let body = {
        ...HolidayFormData,
        holidayDate: moment(HolidayFormData?.holidayDate).format('YYYY-MM-DD'),
        isActive: true,
        companyId: userData?.companyId,
        branchId: userData?.isActiveBranch
      }

      console.log("Holiday Save Body",body);
      const response = await PostData(body);

      if(response){
        RefetchHolidayListData();
        ondrawerclose();
        setFormErrors({});
        setHolidayFormData({
          holidayMasterId: 0,
          holidayDate: '',
          remark: '',
          holidayName: '',
          backgroundColor: '',
          fontColor: '',
          shortName: '',
          isActive: true,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch
         });
      }

      }).catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setFormErrors(newErrors);
      });
    } 
  
    console.log("Filtered Row Data",FilteredRowData);
  return (
    <div>
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
          <h1 className="text-xl font-bold text-gray-800">Holiday Master</h1>
          <Button colorScheme='purple' leftIcon={<Plus size='16px'/>} onClick={ondraweropen}>Add Holiday</Button>
        </div>

        {HolidayListData.length > 0 && 
          <div className='flex justify-end items-center gap-4 mb-4'>
            <div>
             <Select value={SelectedYear} onChange={({target})=>{setSelectedYear(target.value)}} borderColor={'gray.400'} bg>
                <option hidden disabled defaultValue>Select Year</option>
                {_.uniqBy(HolidayListData, holiday => new Date(holiday.holidayDate).getFullYear())?.map((data)=>{
                  return <option key={data.holidayMasterId} value={new Date(data.holidayDate).getFullYear()}>{new Date(data.holidayDate).getFullYear()}</option>
                })}
             </Select>
            </div>
            <Button 
              isLoading={IsHolidayListLoading} 
              loadingText='Searching...' 
              onClick={() => {
                 RefetchHolidayListData();
                
              }} 
              leftIcon={<Search size={'16px'}/>} 
              colorScheme='purple' 
            >Search</Button>
          </div>
        }

       <div className="ag-theme-alpine">
         <AgGridReact
           style={gridStyle}
           domLayout={"autoHeight"}
           ref={gridRef} // Ref for accessing Grid's API
           rowData={FilteredRowData} // Row Data for Rows
           columnDefs={columnDefs} // Column Defs for Columns
           defaultColDef={defaultColDef} // Default Column Properties
           animateRows={true} // Optional - set to 'true' to have rows animate when sorted
           pagination={true}
           paginationPageSize={15}
           paginationNumberFormatter={paginationNumberFormatter}
         />
       </div>

       <Drawer
        isOpen={isdraweropen}
        placement='right'
        size='md'
        onClose={()=>{
          ondrawerclose();
          setFormErrors({});
          setHolidayFormData({
            holidayMasterId: 0,
            holidayDate: '',
            remark: '',
            holidayName: '',
            isActive: true,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            backgroundColor: '',
            fontColor: '',
            shortName: ''
           });
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Holiday</DrawerHeader>

          <form onSubmit={SaveHolidayMaster}>
          <DrawerBody>
            <div className="space-y-2 mb-4">
             <label>Holiday Date</label>
             <DatePicker
              className={`${FormErrors?.holidayDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
              selected={HolidayFormData?.holidayDate}
              onChange={(date) =>{
                setHolidayFormData({
                  ...HolidayFormData,
                  holidayDate: date
                });
              }}
              placeholderText="Holiday Date"
              dateFormat={"dd-MM-yyyy"}
             /> 
             {FormErrors?.holidayDate && <span className="text-red-600 my-1 text-sm">{FormErrors?.holidayDate}</span>}
            </div>
            <div className="space-y-2 mb-4">
             <label>Holiday Name</label>
             <Input 
               
               isInvalid={FormErrors?.holidayName}
               value={HolidayFormData?.holidayName} 
               onChange={({target})=>{
                setHolidayFormData({
                  ...HolidayFormData,
                  holidayName: target.value
                })
               }}  
               borderColor='gray' 
               placeholder="Holiday Name"  />
               {FormErrors?.holidayName && <span className="text-red-600 my-1 text-sm">{FormErrors?.holidayName}</span>}
            </div>
            <div className="space-y-2 mb-4">
             <label>Short Name</label>
             <Input  
               isInvalid={FormErrors?.shortName}
               value={HolidayFormData?.shortName} 
               onChange={({target})=>{
                setHolidayFormData({
                  ...HolidayFormData,
                  shortName: target.value
                });
               }}  
               borderColor='gray' 
               placeholder="Short Name"  />
               {FormErrors?.shortName && <span className="text-red-600 my-1 text-sm">{FormErrors?.shortName}</span>}
            </div>

          
            <div className="space-y-2 mb-4">
             <label>Remarks</label>
             <Input  
               isInvalid={FormErrors?.remark}
               value={HolidayFormData?.remark} 
               onChange={({target})=>{
                setHolidayFormData({
                  ...HolidayFormData,
                  remark: target.value
                });
               }}  
               borderColor='gray' 
               placeholder="Remarks"  />
               {FormErrors?.remark && <span className="text-red-600 my-1 text-sm">{FormErrors?.remark}</span>}
            </div>
                <div className="flex gap-8 mb-4">
                  <div className="space-y-2 w-1/3">
                   <label>Background Color</label>
                   <Input  
                     value={HolidayFormData?.backgroundColor}
                     onChange={({target})=>{
                      setHolidayFormData({
                        ...HolidayFormData,
                        backgroundColor: target.value
                      })
                     }}  
                     borderColor='gray' 
                     type='color' />
                  </div>
                  <div className="space-y-2 w-1/3">
                   <label>Font Color</label>
                   <Input 
                     value={HolidayFormData?.fontColor}
                     onChange={({target})=>{
                      setHolidayFormData({
                        ...HolidayFormData,
                        fontColor: target.value
                      })
                     }}  
                     borderColor='gray' 
                     type='color' />
                  </div>
                </div>
          
           
                <div className="space-x-3 my-2 text-right">
                  <Button isLoading={IsPostLoading} loadingText={'Saving...'} type="submit" colorScheme='purple'>Save</Button>
                </div>
          
          </DrawerBody>
          </form>
        </DrawerContent>
      </Drawer>




    </div>
  )

 
}
