import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react'
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { useAuth } from '../Utils/AuthContext';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';


const ImportEmployeeData = ({EmpList}) => {
    console.log("Active Emps",EmpList);

    const { userData } = useAuth();
    const FileRef = useRef();

    const reset = () => {
      FileRef.current.value = "";
    };

    const { isOpen: isImportEmployeesOpen, onOpen: onImportEmployeesOpen, onClose: onImportEmployeesClose } = useDisclosure();

    const [ImportExcelHeads, setImportExcelHeads] = useState([
        { label: "guId", key: `guId`},
        { label: "fullName", key: `fullName`},

        { label: "dateOfBirth", key:"dateOfBirth"},
        { label: "nationality", key:"nationality"},
        { label: "gender", key:"gender"},
        { label: "personalMobile", key:"personalMobile"},
        { label: "personalEmail", key:"personalEmail"},
        { label: "placeOfBirth", key:"placeOfBirth"},
        { label: "bloodGroup", key:"bloodGroup"},
        { label: "maritalStatus", key:"maritalStatus"},
        { label: "citizenShip", key:"citizenShip"},
        { label: "emergencyContactNo", key:"emergencyContactNo"},
        { label: "contactName", key:"contactName"},
        { label: "relationMaillingAddress", key:"relationMaillingAddress"},
        { label: "familyMemberName", key:"familyMemberName"},
        { label: "familyMemberRelation", key:"familyMemberRelation"},
        { label: "familyMemberContact", key:"familyMemberContact"},
        { label: "presentHouseNo", key:"presentHouseNo"},
        { label: "presentAddress1", key:"presentAddress1"},
        { label: "presentAddress2", key:"presentAddress2"},
        { label: "presentLandmark", key:"presentLandmark"},
        { label: "presentCountry", key:"presentCountry"},
        { label: "presentState", key:"presentState"},
        { label: "presentCity", key:"presentCity"},
        { label: "presentPin", key:"presentPin"},
        { label: "isSameAsPresent", key:"isSameAsPresent"},
        { label: "permanentHouseNo", key:"permanentHouseNo"},
        { label: "permanentAddress1", key:"permanentAddress1"},
        { label: "permanentAddress2", key:"permanentAddress2"},
        { label: "permanentLandmark", key:"permanentLandmark"},
        { label: "permanentCountry", key:"permanentCountry"},
        { label: "permanentState", key:"permanentState"},
        { label: "permanentCity", key:"permanentCity"},
        { label: "permanentPin", key:"permanentPin"},

        { label: "visaStatus", key:"visaStatus"},
        { label: "visaType", key:"visaType"},
        { label: "visaNo", key:"visaNo"},
        { label: "vissaIssuePlace", key:"vissaIssuePlace"},
        { label: "visaStartDate", key:"visaStartDate"},
        { label: "visaExpiryDate", key:"visaExpiryDate"},
        { label: "passportNo", key:"passportNo"},
        { label: "passportExpiryDate", key:"passportExpiryDate"},
        { label: "labourCardNo", key:"labourCardNo"},
        { label: "labourCardExpiry", key:"labourCardExpiry"},

        { label: "empQualification",key:"empQualification"},
        { label: "empUniversity",key:"empUniversity"},
        { label: "empYearOfPassing",key:"empYearOfPassing"},
        { label: "instituteName",key:"instituteName"},
        { label: "status",key:"status"},
        { label: "country",key:"country"},
        { label: "city",key:"city"},
        { label: "state",key:"state"},

        { label: "startDate", key:"startDate"},
        { label: "endDate", key:"endDate"},
        { label: "salary", key:"salary"},
        { label: "jobDescription", key:"jobDescription"},
        { label: "position", key:"position"},
        { label: "company", key:"company"},

        { label: "bankName",key:"bankName"},
        { label: "bankBranchName",key:"bankBranchName"},
        { label: "accountNumber",key:"accountNumber"},
        { label: "ifscCode",key:"ifscCode"},
        { label: "personalId",key:"personalId"},
        { label: "routingAndSwiftCode",key:"routingAndSwiftCode"},
        { label: "ibanNumber",key:"ibanNumber"},

        { label: "empEPFNo",key: "empEPFNo"},
        { label: "empESICNo",key: "empESICNo"},
        { label: "empEPFUANNo",key: "empEPFUANNo"},
      ]);


      const readExcel = (file)=>{

        const promise = new Promise((resolve,reject) => {
          
         const fileReader = new FileReader();
         fileReader.readAsArrayBuffer(file);
      
         fileReader.onload=(e)=>{
           const bufferArray = e.target.result;
           const wb = XLSX.read(bufferArray,{type:'buffer'});
           const wsname = wb.SheetNames[0];
           const ws = wb.Sheets[wsname];
           const data = XLSX.utils.sheet_to_json(ws, {raw: false});
           resolve(data)
         } 
      
         fileReader.onerror = (error) => {
           reject(error)
         }
      
        });
      
        promise.then((data)=>{
          console.log("Imported Data",data);
      
          let FinalData = data.map((obj)=>{
            return {...obj,
              companyId:userData.companyId,
              branchId:userData.isActiveBranch
            }
           })
      
         console.log("Final Data",FinalData);
        });
      
      }
  return (
    <div>
      <Button leftIcon={<FontAwesomeIcon icon={faUpload} />} onClick={onImportEmployeesOpen} colorScheme='purple'>Import Employees Data</Button>

      <Modal size='full' scrollBehavior='inside' isOpen={isImportEmployeesOpen} onClose={onImportEmployeesClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Employees</ModalHeader>
          <ModalCloseButton />
          <hr />
            <ModalBody>

               <div className='mt-4'>
               <CSVLink data={EmpList} headers={ImportExcelHeads} filename={"Employees Data.csv"}>
                 <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} size='sm' colorScheme='purple'>Download Sample Excel for Employees</Button>
               </CSVLink>
              </div>
          <div className="flex justify-center items-center w-full my-10">
              <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
                  <div className="flex flex-col justify-center items-center pt-5 pb-6">
                      <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
                  </div>
                  <input 
                    ref={FileRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      readExcel(file);
                    }} 
                    id="dropzone-file" 
                    type="file" 
                    className="hidden" 
                  />
              </label>
          </div> 

       {/* <div>
        {JSON.stringify(EmployeesImportData)}
      </div> */}
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    onImportEmployeesClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default ImportEmployeeData