import React from 'react'
import { BsDatabaseX } from 'react-icons/bs'

const NoRecordFound = () => {
  return (
    <div className="h-64 flex justify-center items-center rounded-3xl drop-shadow-md border-2 border-gray-400 border-dashed bg-red-50">
    <div className="space-y-2">
      <BsDatabaseX size={'4rem'} className="mx-auto text-red-500"/>
      <h6 className="text-2xl font-bold text-gray-600">No Record Found</h6>
    </div>
  </div>
  )
}

export default NoRecordFound