import { Button, Input, Select, Stack,Radio,RadioGroup, useToast, useDisclosure, ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalOverlay,Modal,ModalContent } from '@chakra-ui/react'
import { faArrowTurnUp, faDownload, faFileImport } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';

function ReviseSalary() {

    const { userData } = useAuth();
    const toast = useToast();
    const id = "toast";

    const navigate = useNavigate();

    const {
      isOpen: isSalaryIncrementOpen,
      onOpen: onSalaryIncrementOpen,
      onClose: onSalaryIncrementClose,
    } = useDisclosure();

    const {
      isOpen: isPercentageImportOpen,
      onOpen: onPercentageImportOpen,
      onClose: onPercentageImportClose,
    } = useDisclosure();

    const {
      isOpen: isFlatAmountImportOpen,
      onOpen: onFlatAmountImportOpen,
      onClose: onFlatAmountImportClose,
    } = useDisclosure();


    const {
        isOpen: isViewRevisedSalaryOpen,
        onOpen: onViewRevisedSalaryOpen,
        onClose: onViewRevisedSalaryClose,
      } = useDisclosure();

    const [RevisedSalaryRowData, setRevisedSalaryRowData] = useState([]);
    const [RevisedSalaryColumnDefs, setRevisedSalaryColumnDefs] = useState([
        { headerName : "Emp Code", field : `empCode`},
        { headerName : "Emp Name", field : `empName`},
    ])

    const [PercentageImportData, setPercentageImportData] = useState([]);


    const [FlatAmountImportData, setFlatAmountImportData] = useState([]);
    const [FlatAmountExcelHeads, setFlatAmountExcelHeads] = useState([
      { label: "empGuId", key: `empGuId`},
      { label: "employeeSalaryMasterId", key: `employeeSalaryMasterId`},
      { label: "empName", key: `empName`},
      { label: "empCode", key: `empCode`},
  
    ]);
    const [FlatAmountExcelData, setFlatAmountExcelData] = useState([]);

    const [PercentageHeads, setPercentageHeads] = useState([
      { label: "empGuId", key: `empGuId`},
      { label: "empName", key: `empName`},
      { label: "empCode", key: `empCode`},
      { label: "max_Rating", key: `max_Rating`},
      { label: "salary_Rating", key: `salary_Rating`},
      { label: "salary_Percentage", key: `salary_Percentage`},
    ]);

    const [PercentageExcelData, setPercentageExcelData] = useState([]);


    const gridRef = useRef();
    const [NextSalaryRowData , setNextSalaryRowData ] = useState([])
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling



    const [IncrementRule, setIncrementRule] = useState('flatAmount');
    const [NextSalaryDataMonth, setNextSalaryDataMonth] = useState(new Date());



    const [SelectedEmpRowData, setSelectedEmpRowData] = useState([]);

    const [RevisionType, setRevisionType] = useState();
    const [EffectiveFrom, setEffectiveFrom] = useState();
    const Today = new Date()
    const [SalaryRevisionDate, setSalaryRevisionDate] = useState(new Date(Today.getFullYear(),Today.getMonth(),1));
    const [NextRevisionDate, setNextRevisionDate] = useState();
    const [MaxRating, setMaxRating] = useState();



    const [NextSalaryColumnDefs, setNextSalaryColumnDefs] = useState([
      {
        headerName: "Employee Code",
        field: "empCode",
        headerCheckboxSelection: true, 
        checkboxSelection: (params) => {
          return params.data.empCode;
        }
      },
      {
        headerName: "Employee Name",
        field: "empName",
      },              
      {
        headerName: "Salary Revision Date",
        field: "nextRevisionDate",
        valueGetter:(params) => new Date(params.data.nextRevisionDate).toLocaleDateString('en-GB')
      },
      {
        headerName: "Revision Month",
        valueGetter:(params) => new Date(params.data.nextRevisionDate).getMonth()+1 + '/' + new Date(params.data.nextRevisionDate).getFullYear()
      },     
      ]);


      const [SalaryIncrementColumnDefs, setSalaryIncrementColumnDefs] = useState([
        {
          headerName: "Employee Code",
          field: "empCode",
        },
        {
          headerName: "Employee Name",
          field: "empName",
        },              

        ]);



        const [SalaryRevisedColumnDefs, setSalaryRevisedColumnDefs] = useState([
          {
            headerName: "Employee Code",
            field: "empCode"
          },
          {
            headerName: "Employee Name",
            field: "empName"
          },
          {
            headerName: "Revision Type",
            field: "salary_RevisionType",
            // valueGetter : (params) => params.data.salaryIncrementFinal.salary_RevisionType
          },              
          {
            headerName: "Salary Rating",
            field: "salary_Rating",
            // valueGetter : (params) => params.data.salaryIncrementFinal.salary_Rating ,
            cellClass:'editable-grid-cell' ,
            editable:true, singleClickEdit: true,
          },              
          {
            headerName: "Percentage",
            field: "salary_Percentage",
            // valueGetter : (params) => params.data.salaryIncrementFinal.salary_Percentage,
            cellClass:'editable-grid-cell' ,
            editable:true, singleClickEdit: true
          },              
          // {
          //   headerName: "Gross Salary",
          //   field: "grossSalary",
          //   cellClass:'editable-grid-cell' ,
          //   editable:true, singleClickEdit: true,
          // },
          // {
          //   headerName: "Net Salary",
          //   field: "netSalary",
          //   cellClass:'editable-grid-cell' ,
          //   editable:true, singleClickEdit: true,
          // },              
          // {
          //   headerName: "CTC",
          //   field: "ctc",
          //   cellClass:'editable-grid-cell' ,
          //   editable:true, singleClickEdit: true,
          // }
          ]);


          const [FlatSalaryRevisedColumnDefs, setFlatSalaryRevisedColumnDefs] = useState([
            {
              headerName: "Employee Code",
              field: "empCode"
            },
            {
              headerName: "Employee Name",
              field: "empName"
            },
            {
              headerName: "Revision Type",
              field: "salary_RevisionType",
              // valueGetter : (params) => params.data.salaryIncrementFinal.salary_RevisionType
            },              
            // {
            //   headerName: "Salary Rating",
            //   field: "salary_Rating",
            //   // valueGetter : (params) => params.data.salaryIncrementFinal.salary_Rating ,
            //   cellClass:'editable-grid-cell' ,
            //   editable:true, singleClickEdit: true,
            // },              
            // {
            //   headerName: "Percentage",
            //   field: "salary_Percentage",
            //   // valueGetter : (params) => params.data.salaryIncrementFinal.salary_Percentage,
            //   cellClass:'editable-grid-cell' ,
            //   editable:true, singleClickEdit: true,
            // },              
            {
              headerName: "Gross Salary",
              field: "grossSalary",
              cellClass:'editable-grid-cell' ,
              editable:true, singleClickEdit: true,
            },
            {
              headerName: "Net Salary",
              field: "netSalary",
              cellClass:'editable-grid-cell' ,
              editable:true, singleClickEdit: true,
            },              
            {
              headerName: "CTC",
              field: "ctc",
              cellClass:'editable-grid-cell' ,
              editable:true, singleClickEdit: true,
            }
            ]);


      const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.forEachNode((node) =>
          node.setSelected(true)
        );
      }, []);


      const onSelectionChanged = useCallback(() => {
        var SelectedEmps = gridRef.current.api.getSelectedRows();
        console.log("Selected Employees",SelectedEmps);
        setSelectedEmpRowData(SelectedEmps);
      }, []);

      
         // Table Pagination
         const paginationNumberFormatter = useCallback((params) => {
           return "[" + params.value.toLocaleString() + "]";
         }, []);
      
         //Table columns properties applies to all columns
         const defaultColDef = useMemo(() => ({
           flex: 1,
           sortable: true,
           filter: "agTextColumnFilter",
           floatingFilter: true,
           cacheQuickFilter: true,
           cellClass: 'no-border',
           enableCellTextSelection:true
         }));

         const viewColDef = useMemo(() => ({
            // flex: 1,
            sortable: true,
            filter: "agTextColumnFilter",
            floatingFilter: true,
            cacheQuickFilter: true,
            cellClass: 'no-border',
            enableCellTextSelection:true
          }));

         useEffect(()=>{
          if(RevisionType === 'Annual'){
            setNextRevisionDate(new Date(moment(SalaryRevisionDate).add(12,'months').format('YYYY'),0,1))
          }

          if(RevisionType === 'Interim'){
            setNextRevisionDate(new Date(SalaryRevisionDate))
          }
         },[RevisionType,SalaryRevisionDate])


    useEffect(() => {
      GetRevisionRule()
      GetNextSalaryEmployeeList(moment(NextSalaryDataMonth).format('YYYY-MM-DD'));
      getMasterStructure();
    }, []);


    const GetRevisionRule = async () => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/GetSalaryRevisionRuleSettingList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Get Revsion Rule Response",response);
        const res = response.data;

        if(!res){
          alert('Please Setup Increment Rules First!!');
          navigate('/incrementrule')
        }
  
      } catch (error) {
        console.log(error);
      }
    }


    const GetNextSalaryEmployeeList = async (DATE) => {
        setSelectedEmpRowData([])
      try {
        const response =  await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/GetEmployeeNextSalarybyEmpDate?NextDate=${DATE}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Next Salary Employees",response);
        setNextSalaryRowData(response.data);
        onFirstDataRendered()
      } catch (error) {
        console.error(error);
      }
    }


    const getMasterStructure = async () => {
      await authApi
        .get(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        .then((response) => {
          //  console.log("Branch Header", response.data);
          let resdata = response.data;
          let ActiveComp = resdata.filter(data => data.isActive  === true)
          HandleBranchExcel(ActiveComp);
        })
        .catch((error) => {
          console.error(error);
        });
    };


      
    const HandleBranchExcel = async(branchComponents) => {
  
      // console.log(branchComponents);
  
      branchComponents.map(obj=>{
        setFlatAmountExcelHeads(prev => [...prev, { label: obj.displayLabel, key: obj.displayLabel }])
      })

      branchComponents.map(obj=>{
        setRevisedSalaryColumnDefs(prev => [...prev, { headerName: obj.displayLabel, field: obj.displayLabel }])
      })


      let StartingHeaders = [
        { headerName: "Emp Code", field: "empCode", },
        { headerName: "Emp Name", field: "empName"},
      ]

      let MiddleHeaders = branchComponents.map(obj=>{
        return { 
          headerName: obj.displayLabel, 
          field: obj.branchSalaryStructureMasterId+'salaryValue',}
      })

 
      let EditableMiddleHeaders = branchComponents.map(obj=>{
        return { 
            headerName: obj.displayLabel, 
            field: obj.displayLabel,
            cellClass:'editable-grid-cell' ,
            editable:true, 
            singleClickEdit: true
         }
      })

      let IndiaEndHeads = [
        { 
          headerName: "Emp PF", 
          field: `employeeESIPFModelObj.employeePFValue`
        },
        { 
          headerName: "Emp_ER PF", 
          field: `employeeESIPFModelObj.employerPFValue`
        },
        { 
          headerName: "Additional PF", 
          field: `employeeESIPFModelObj.employeeAdditionalPFValue`
        },
        { 
          headerName: "Emp ESI", 
          field: `employeeESIPFModelObj.employeeESIValue`
        },
        { 
          headerName: "Emp_ER ESI", 
          field: `employeeESIPFModelObj.employerESIValue`
        },
        { 
          headerName: "Gross", 
          field: `grossSalary`
        },
        { 
          headerName: "Net Salary", 
          field: `netSalary`
        },
        { 
          headerName: "CTC", 
          field: `ctc`
        }
      ]

       let OtherEndHeads = [
        { 
          headerName: "Gross", 
          field: `grossSalary`
        },
        { 
          headerName: "Net Salary", 
          field: `netSalary`
        },
        { 
          headerName: "CTC", 
          field: `ctc`
        }
      ]

      let EditableEndHeads = [
        { 
          headerName: "Gross", 
          field: `Gross`, 
          cellClass:'editable-grid-cell', 
          editable:true, 
          singleClickEdit: true
        },
        { 
          headerName: "Net Salary", 
          field: `NetSalary`, 
          cellClass:'editable-grid-cell', 
          editable:true, 
          singleClickEdit: true
        },
        // { headerName: "CTC", field: `ctc`, cellClass:'editable-grid-cell', editable:true, singleClickEdit: true}
      ]


      let EditableIndiaEndHeads = [
        { 
          headerName: "Emp PF",
          field: `EmployeePFValue`, 
          cellClass:'editable-grid-cell', 
          editable:true, 
          singleClickEdit: true
        },
        { 
          headerName: "Emp_ER PF", 
          field: `EmployerPFValue`, 
          cellClass:'editable-grid-cell', 
          editable:true, 
          singleClickEdit: true
        },
        { 
          headerName: "Additional PF",
          field: `EmployeeAdditionalPFValue`,
          cellClass:'editable-grid-cell',
          editable:true,
          singleClickEdit: true
        },
        { 
          headerName: "Emp ESI",
          field: `EmployeeESIValue`,
          cellClass:'editable-grid-cell',
          editable:true,
          singleClickEdit: true
        },
        { 
          headerName: "Emp_ER ESI",
          field: `EmployerESIValue`,
          cellClass:'editable-grid-cell',
          editable:true,
          singleClickEdit: true
        },
        { 
          headerName: "Gross",
          field: `Gross`,
          cellClass:'editable-grid-cell',
          editable:true,
          singleClickEdit: true
        },
        { 
          headerName: "Net Salary",
          field: `NetSalary`,
          cellClass:'editable-grid-cell',
          editable:true,
          singleClickEdit: true 
        },
        // { headerName: "CTC", field: `ctc`, cellClass:'editable-grid-cell', editable:true, singleClickEdit: true}
      ]


      console.log("View Salary Headers",_.union(StartingHeaders,MiddleHeaders,OtherEndHeads));

      if(userData.countryName === "India"){
        setRevisedSalaryColumnDefs(_.union(StartingHeaders,MiddleHeaders,IndiaEndHeads))
        setFlatSalaryRevisedColumnDefs(_.union(StartingHeaders,EditableMiddleHeaders,EditableIndiaEndHeads))

      }else{
        setRevisedSalaryColumnDefs(_.union(StartingHeaders,MiddleHeaders,OtherEndHeads))
        setFlatSalaryRevisedColumnDefs(_.union(StartingHeaders,EditableMiddleHeaders,EditableEndHeads))

      }


      userData.countryName === "India" ?
      setFlatAmountExcelHeads(prev => [...prev,
        { label: "Gross", key: `gross`},
        { label: "NetSalary", key: `netSalary`},
        { label: "EmployeePFValue", key: `EmployeePFValue`},
        { label: "EmployerPFValue", key: `EmployerPFValue`},
        { label: "EmployeeAdditionalPFValue", key: `EmployeeAdditionalPFValue`},
        { label: "EmployeeESIValue", key: `EmployeeESIValue`},
        { label: "EmployerESIValue", key: `EmployerESIValue`},
      ]) : setFlatAmountExcelHeads(prev => [...prev,
        { label: "Gross", key: `gross`},
        { label: "NetSalary", key: `netSalary`},
        // { label: "CTC", key: `ctc`},
      ])    
    }

    console.log("Percentage Excel Data",PercentageExcelData);
 console.log("Selcted Emp Data",SelectedEmpRowData);

    const ProcessPercentageTable = async (e) => {
      e.preventDefault();

      setPercentageExcelData(SelectedEmpRowData.map((data)=>{
        return {
          empGuId : data.empGuId,
          empName : data.empName,
          empCode : data.empCode,
          max_Rating : MaxRating,
          salary_Rating : 0,
          salary_Percentage : 0,
          employeeSalaryMasterId: data.employeeSalaryMasterId
        }
      }))
      onPercentageImportOpen();
    }


    const ProcessFlatAmountTable = async (e) => {
      e.preventDefault();
      
      setFlatAmountExcelData(SelectedEmpRowData.map((data)=>{
        return {
          empGuId : data.empGuId,
          empName : data.empName,
          empCode : data.empCode,
          employeeSalaryMasterId : data.employeeSalaryMasterId
        }
      }))

      onFlatAmountImportOpen();
    }


    const readPercentageImportExcel = (file)=>{
  
      const promise = new Promise((resolve,reject) => {
        
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
   
       fileReader.onload=(e)=>{
         const bufferArray = e.target.result;
   
         const wb = XLSX.read(bufferArray,{type:'buffer'});
         const wsname = wb.SheetNames[0];
   
         const ws = wb.Sheets[wsname];
   
         const data = XLSX.utils.sheet_to_json(ws);
   
         resolve(data)
       }
   
       fileReader.onerror = (error) => {
         reject(error)
       }
   
      });
   
      promise.then((data)=>{
        console.log(data);
  
        let FinalData = data.map((obj)=>{
          return {...obj,
            companyId:userData.companyId,
            branchId:userData.isActiveBranch,
            nextRevisionDate : moment(NextRevisionDate).format('YYYY-MM-DD'),
            salary_RevisionType : RevisionType,
            salary_Revision_Date : moment(SalaryRevisionDate).format('YYYY-MM-DD'),
            salary_Effectivefrom : moment(EffectiveFrom).format('YYYY-MM-DD'),
            increment_Rule : IncrementRule
           }
         })
         console.log("Import Data",FinalData);
         setPercentageImportData(FinalData);
      });
   
   }


   
   const readFlatAmountImportExcel = (file)=>{
  
    const promise = new Promise((resolve,reject) => {
      
     const fileReader = new FileReader();
     fileReader.readAsArrayBuffer(file);
 
     fileReader.onload=(e)=>{
       const bufferArray = e.target.result;
 
       const wb = XLSX.read(bufferArray,{type:'buffer'});
       const wsname = wb.SheetNames[0];
 
       const ws = wb.Sheets[wsname];
 
       const data = XLSX.utils.sheet_to_json(ws);
 
       resolve(data)
     } 
 
     fileReader.onerror = (error) => {
       reject(error)
     }
 
    });
 
    promise.then((data)=>{
      console.log(data);
       console.log("Flat Amount Import Data",data);
       setFlatAmountImportData(data)
    //    HandleFlatAmountImportData(data);
    });
 
 }


 const HandleFlatAmountImportData = async (FinalData) => {
    onViewRevisedSalaryOpen();

  let body = FinalData.map((data)=>{
    return {
        testData : [data],
        employeeSalaryMasterId : data.employeeSalaryMasterId,
        employeeSalaryMasterId_old : 0,
        nextRevisionDate : moment(NextRevisionDate).format('YYYY-MM-DD'),
        // salary_StartDate : "2023-04-18T05:00:43.228Z",
        // salary_EndDate : "2023-04-18T05:00:43.228Z",
        isActiveSalary : true,
        salaryTillDate : true,
        salary_AnnualId : 0,
        empName : data.empName,
        empCode : data.empCode,
        empGuId : data.empGuId,
        salary_RevisionType : RevisionType,
        salary_Revision_Date : moment(SalaryRevisionDate).format('YYYY-MM-DD'),
        salary_Effectivefrom : moment(EffectiveFrom).format('YYYY-MM-DD'),
        increment_Status : true,
        valid : true,
        increment_Rule : IncrementRule === "flatAmount" ? 'direct' : IncrementRule,
        salary_Rating : 0,
        salary_Percentage : 0,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch,
        max_Rating : 0,
    }
   }) 
  console.log("Final Flat Data",body);

   await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/SaveEmpSalaryIncrementDirect`,body
   )
   .then((response) => {
     console.log("Import Response",response);
     const res = response.data;
     console.log("res",res);

     setFlatAmountImportData(res)
     HandleRevisedSalary(res);
     if (!toast.isActive(id)) {
       toast({
         id,
         title:"Salary Revised Successfully",
         position: 'top',
         status: 'info',
         duration: 2000,
         isClosable: true,
       })
     }

     onFlatAmountImportClose();
 
 
   })
   .catch((error) => {
     console.log("Import Error",error);
 
     if (!toast.isActive(id)) {
       toast({
         id,
         title: 'ERROR',
         description: "Salary Data not Imported.",
         position: 'top',
         status: 'error',
         duration: 2000,
         isClosable: true,
       })
     }
   });
 


 }


   const HandlePercentageImportData = async (FinalData) => {
    onViewRevisedSalaryOpen();

    console.log("Percentage Save Body",FinalData);
    if(FinalData.some(item => Number(item.salary_Rating) > Number(MaxRating))){
      alert('Salary Rating Value Cannot be Greater than Max Rating. Please Import Again!');
      // onPercentageImportClose();
      setPercentageImportData([])
    }else{
      await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/SalaryRevision/SaveEmpSalaryIncrement`,FinalData
      )
      .then((response) => {
        console.log("Import Response",response);
        const res = response.data;
        console.log("res",res);
        setRevisedSalaryRowData(res);  
        HandleRevisedSalary(res);

        if (!toast.isActive(id)) {
            toast({
              id,
              title:"Salary Revised Successfully",
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
          onPercentageImportClose();
      })
      .catch((error) => {
        console.log("Import Error",error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: "Salary Data not Imported.",
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });
    }

   }

   console.log("Flat Response",RevisedSalaryColumnDefs,RevisedSalaryRowData);

  const HandleRevisedSalary = async (RevisedRes) => {
    setRevisedSalaryRowData(RevisedRes.map((data)=>{
        let EmpData = data;
        let FinalRowdata = EmpData.componantDataList?.map((data)=>{
            let rowData = [];
            // console.log("Internal Row Data",rowData);
            rowData.push({[data.branchSalaryStructureMasterId+'salaryValue']: data.salaryValue});
            return  Object.assign(EmpData,...rowData)
          })
          console.log("F Row Data",_.unionBy(FinalRowdata,'empGuid'));
          return _.unionBy(FinalRowdata,'empGuid')[0]
    }))
   }

  return (
    <div>
        <div className="border-b flex justify-between border-slate-400 pb-2 mb-4">
          <h1 className="text-lg font-bold text-gray-800">
            Salary Revision
          </h1>
          <div className="flex items-center gap-4">
            <label>Data Month :</label>
            <div>
              <DatePicker
                className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
                selected={NextSalaryDataMonth}
                onChange={(date) => {
                  setNextSalaryDataMonth(date);
                  GetNextSalaryEmployeeList(moment(date).format("YYYY-MM-DD"));
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                placeholderText="Select Month"
              />
            </div>
          </div>
        </div>

        <form onSubmit={IncrementRule === 'flatAmount'?   ProcessFlatAmountTable : ProcessPercentageTable} >
            <div className="bg-white shadow-lg rounded-3xl p-6 mb-4 mt-3">


              <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-6 items-end">
                <div className="space-y-2">
                  <label>Salary Revision Date</label>
                  <DatePicker
                    required
                    className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2.5   outline-none"
                    selected={SalaryRevisionDate}
                    onChange={(date) => setSalaryRevisionDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Revision Date"
                  />
                </div>

                <div className="space-y-2">
                  <label>Revision Type</label>
                  <Select
                    isRequired
                    value={RevisionType}
                    onChange={({ target }) => {
                      setRevisionType(target.value);
                    }}
                    borderColor="gray.400"
                    placeholder="Select Revision Type"
                  >
                    <option value="Annual">Annual</option>
                    <option value="Interim">Interim</option>
                    <option value="Other">Other</option>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label>Effective From</label>
                  <DatePicker
                    required
                    className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2.5   outline-none"
                    selected={EffectiveFrom}
                    onChange={(date) => {
                      setEffectiveFrom(date);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    placeholderText="Select Month"
                  />
                </div>

                <div className="space-y-2">
                  <label>Next Revision Date</label>
                  <DatePicker
                    required
                    className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2.5   outline-none"
                    selected={NextRevisionDate}
                    onChange={(date) => setNextRevisionDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Next Revision Date"
                  />
                </div>

                <div className="space-y-4">
                  <label>Increment Rule</label>
                  <div>
                    <RadioGroup
                      mb={2}
                      onChange={setIncrementRule}
                      value={IncrementRule}
                    >
                      <Stack direction="row" justify={"space-between"}>
                        <Radio value="flatAmount">Flat Amount</Radio>
                        <Radio value="percentage">Percentage</Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </div>

                {IncrementRule === "percentage" ? (
                  <div className="space-y-2">
                    <label>Max Rating</label>
                    <Input
                      isRequired
                      value={MaxRating ||''}
                      onChange={({target})=>{setMaxRating(target.value)}}
                      borderColor="gray.400"
                      type="number"
                      placeholder="Max Rating"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              </div>

              {/* {IncrementRule === "flatAmount" ? (
                <div className="text-right mt-6">
                  <Button  type="submit"  leftIcon={<FontAwesomeIcon icon={faFileImport} />}  colorScheme={"purple"} >Import Flat Amount</Button>
                </div>
              ) :
                <div className="text-right mt-6">
                  <Button  type="submit"  leftIcon={<FontAwesomeIcon icon={faFileImport} />}  colorScheme={"purple"}>Import Percentage</Button>
                </div>} */}

      <div className="flex items-center ml-5 gap-3 my-2">
        <div className="my-auto">
          <FontAwesomeIcon
            className="rotate-180 text-lg"
            icon={faArrowTurnUp}
          />
        </div>
        <h6 className='text-lg font-bold mb-3'>Select Employees for Salary Revision</h6>
        {/* <Button isDisabled={SelectedEmpRowData.length === 0 ? true : false} onClick={onSalaryIncrementOpen} colorScheme={"purple"} size="sm">Revise Salary</Button> */}
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef}
          rowData={NextSalaryRowData}
          columnDefs={NextSalaryColumnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          pagination={true}
          rowSelection={"multiple"}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          onFirstDataRendered={onFirstDataRendered}
          onSelectionChanged={onSelectionChanged}
        />
      </div>

      <div className='text-right mt-4'>
        <Button 
         type="submit"
          isDisabled={SelectedEmpRowData.length === 0 ? true : false}
        // onClick={onSalaryIncrementOpen}
          colorScheme={"purple"}
        // size="sm"
        >
          Revise Salary
        </Button>
      </div>

      </form>


      <Modal
        scrollBehavior="inside"
        size="full"
        isOpen={isSalaryIncrementOpen}
        onClose={()=>{
          onSalaryIncrementClose();
          setSelectedEmpRowData([]);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Revise Salary</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody className="bg-slate-300">
            <form onSubmit={IncrementRule === 'flatAmount'?   ProcessFlatAmountTable : ProcessPercentageTable} className="bg-white shadow-lg rounded-3xl p-6 mb-4 mt-3">
              <div className="grid grid-cols-6 gap-6 items-end">
                <div className="space-y-2">
                  <label>Salary Revision Date</label>
                  <DatePicker
                    required
                    className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2.5   outline-none"
                    selected={SalaryRevisionDate}
                    onChange={(date) => setSalaryRevisionDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Revision Date"
                  />
                </div>

                <div className="space-y-2">
                  <label>Revision Type</label>
                  <Select
                    isRequired
                    value={RevisionType}
                    onChange={({ target }) => {
                      setRevisionType(target.value);
                    }}
                    borderColor="gray.400"
                    placeholder="Select Revision Type"
                  >
                    <option value="Annual">Annual</option>
                    <option value="Interim">Interim</option>
                    <option value="Other">Other</option>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label>Effective From</label>
                  <DatePicker
                    required
                    className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2.5   outline-none"
                    selected={EffectiveFrom}
                    onChange={(date) => {
                      setEffectiveFrom(date);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    placeholderText="Select Month"
                  />
                </div>

                <div className="space-y-2">
                  <label>Next Revision Date</label>
                  <DatePicker
                    required
                    className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2.5   outline-none"
                    selected={NextRevisionDate}
                    onChange={(date) => setNextRevisionDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Next Revision Date"
                  />
                </div>

                <div className="space-y-4">
                  <label>Increment Rule</label>
                  <div>
                    <RadioGroup
                      mb={2}
                      onChange={setIncrementRule}
                      value={IncrementRule}
                    >
                      <Stack direction="row" justify={"space-between"}>
                        <Radio value="flatAmount">Flat Amount</Radio>
                        <Radio value="percentage">Percentage</Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </div>

                {IncrementRule === "percentage" ? (
                  <div className="space-y-2">
                    <label>Max Rating</label>
                    <Input
                      isRequired
                      value={MaxRating ||''}
                      onChange={({target})=>{setMaxRating(target.value)}}
                      borderColor="gray.400"
                      type="number"
                      placeholder="Max Rating"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {IncrementRule === "flatAmount" ? (
                <div className="text-right mt-6">
                  <Button
                    type="submit"
                    leftIcon={<FontAwesomeIcon icon={faFileImport} />}
                    colorScheme={"purple"}
                  >
                    Import Flat Amount
                  </Button>
                </div>
              ) : <div className="text-right mt-6">
              <Button
                type="submit"
                leftIcon={<FontAwesomeIcon icon={faFileImport} />}
                colorScheme={"purple"}
              >
                Import Percentage
              </Button>
            </div>}
            </form>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                rowData={SelectedEmpRowData} // Row Data for Rows
                columnDefs={SalaryIncrementColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                rowSelection={"multiple"}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant="solid" colorScheme="purple" mr={4}>
              Save
            </Button>
            <Button
              variant="outline"
              onClick={onSalaryIncrementClose}
              colorScheme="purple"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size='full' isOpen={isPercentageImportOpen} onClose={onPercentageImportClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Import Salary Increment</ModalHeader>
            <ModalCloseButton onClick={()=>{setPercentageImportData([])}} />
            <hr />
              <ModalBody>
  
                <div className='mt-4'>
                <CSVLink data={PercentageExcelData} headers={PercentageHeads} filename={"Salary Increment Sample.csv"}>
              <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel</Button>
            </CSVLink>
                </div>
              <div className="flex justify-center items-center w-full my-10">
            <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
                </div>
                <input onChange={(e) => {
                  const file = e.target.files[0];
                  readPercentageImportExcel(file);
                }} id="dropzone-file" type="file" className="hidden" />
            </label>
        </div> 

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
        //   ref={gridRef} // Ref for accessing Grid's API
          rowData={PercentageImportData} // Row Data for Rows
          columnDefs={SalaryRevisedColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          rowSelection={"multiple"}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
  
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={()=>{setPercentageImportData([]);
                      onPercentageImportClose()}}
                  >
                    Close
                  </Button>
                  <Button onClick={()=>{HandlePercentageImportData(PercentageImportData)}} variant="solid" colorScheme="purple">Save</Button>
                </div>
              </ModalFooter>
          </ModalContent>
        </Modal>


        <Modal size='full' isOpen={isFlatAmountImportOpen} onClose={onFlatAmountImportClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Import Salary Increment</ModalHeader>
            <ModalCloseButton onClick={()=>{setFlatAmountImportData([])}} />
            <hr />
              <ModalBody>
                <div className='mt-4'>
                <CSVLink data={FlatAmountExcelData} headers={FlatAmountExcelHeads} filename={"Salary Increment Sample.csv"}>
              <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel</Button>
            </CSVLink>
                </div>
              <div className="flex justify-center items-center w-full my-10">
            <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
                </div>
                <input onChange={(e) => {
                  const file = e.target.files[0];
                  readFlatAmountImportExcel(file);
                }} id="dropzone-file" type="file" className="hidden" />
            </label>
        </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
        // ref={gridRef} // Ref for accessing Grid's API
          rowData={FlatAmountImportData} // Row Data for Rows
          columnDefs={FlatSalaryRevisedColumnDefs} // Column Defs for Columns
          defaultColDef={viewColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          rowSelection={"multiple"}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
  
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={()=>{
                      setFlatAmountImportData([]);
                      onFlatAmountImportClose();
                    }}
                  >
                    Close
                  </Button>
                  <Button onClick={()=>{HandleFlatAmountImportData(FlatAmountImportData)}} variant="solid" colorScheme="purple">Save</Button>

                </div>
              </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal size='full' isOpen={isViewRevisedSalaryOpen} onClose={onViewRevisedSalaryClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>View Revised Salary</ModalHeader>
            <ModalCloseButton onClick={()=>{setFlatAmountImportData([])}} />
            <hr />
              <ModalBody>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
        // ref={gridRef} // Ref for accessing Grid's API
          rowData={RevisedSalaryRowData} // Row Data for Rows
          columnDefs={RevisedSalaryColumnDefs} // Column Defs for Columns
          defaultColDef={viewColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          rowSelection={"multiple"}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
  
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    mr={3}
                    onClick={()=>{
                      setFlatAmountImportData([]);
                      onViewRevisedSalaryClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
          </ModalContent>
        </Modal>
    </div>
  );
}
export default ReviseSalary