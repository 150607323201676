import React from "react";

const OrgChart = () => {
  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Organisation Chart</h1>
      </div>
    </div>
  );
};

export default OrgChart;
