import { Button, Switch } from '@chakra-ui/react';
import React, { useState } from 'react'
import { CheckCircle } from 'react-feather';
import ReactSelect from 'react-select';
import useFetch from '../Utils/useFetch';
import { useAuth } from '../Utils/AuthContext';

const OvertimeSetting = () => {

  const { userData } = useAuth();
  const { IsFetchLoading: IsBranchCompListLoading, FetchedData: BranchCompList, Refetch: RefetchBranchCompList} = useFetch('/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList',{ CompanyId: userData.companyId, BranchId: userData.isActiveBranch });
  const [OttHrCalMode, setOttHrCalMode] = useState('1');
  console.log("Comp List",BranchCompList);
  return (
    <div>
        <div className='bg-white p-4 rounded-2xl w-full md:w-1/2 drop-shadow-md space-y-4'>
           <div className='space-y-2'>
            <label>Overtime Calculation</label>
            <div className='space-x-3'>
             <Button onClick={()=>{setOttHrCalMode('1')}} rounded={'full'} leftIcon={OttHrCalMode === "1" ? <CheckCircle size={'14px'}/> : ''} colorScheme='blue' size={'sm'} variant={OttHrCalMode === '1' ? 'solid' : 'outline'}>Auto Calculate</Button>
             <Button onClick={()=>{setOttHrCalMode('2')}} rounded={'full'} leftIcon={OttHrCalMode === "2" ? <CheckCircle size={'14px'}/> : ''} colorScheme='blue' size={'sm'} variant={OttHrCalMode === '2' ? 'solid' : 'outline'}>Manually</Button>
            </div>
           </div>
           <div className='space-y-2 '>
            <label>Amount Calculation Components</label>
            <div className='flex gap-6 items-center'>
             <div className='w-2/3'>
             <ReactSelect 
               options={BranchCompList}
               getOptionLabel={(option) => option.displayLabel}
               getOptionValue={(option) => option.branchSalaryStructureMasterId}
               placeholder="Select Components"
               key={(option)=> option.branchSalaryStructureMasterId}
             />
             </div>
             <div className='space-x-4'>
                <label>On Gross Salary</label>
                <Switch colorScheme='green' />
             </div>
            </div>
           </div>
        </div>
    </div>
  )
}

export default OvertimeSetting