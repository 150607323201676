import React, { useState, useRef } from "react";
import { Input, Textarea, Select, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import usePost from "./Utils/usePost";
import { BranchMasterSchema } from "./Utils/FormValidations";
import useFetch from "./Utils/useFetch";
import { useAuth } from "./Utils/AuthContext";
import authApi from "./Utils/AuthApis";
import { TbLogout } from "react-icons/tb";

const CreateBranch = () => {
  const { userData, signOut } = useAuth();

  const { FetchedData: TimezoneDataList } = useFetch("/api/MasterComponent/GetTimezoneMasterList");
  const { FetchedData: CountryList } = useFetch("/api/Master/GetCountryMasterList");
  const { FetchedData: CurrencyList } = useFetch("/api/Master/GetCurrencyMasterList",{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
  });
  const { IsPostLoading: BranchPostLoading, PostData: PostBranchData } = usePost("/api/BranchMaster/SaveBranchMaster");
  
  const navigate = useNavigate();

  const FileRef = useRef();
  const reset = () => {
    FileRef.current.value = "";
  };

  const [FormErrors, setFormErrors] = useState({});
  const [BranchFormDetails, setBranchFormDetails] = useState({
    branchId: 0,
    branchName: "",
    branchCode: "",
    countryId: "",
    selectedCountry: "",
    countryName: "",
    selectedState: "",
    stateId: "",
    stateName: "",
    address1: "",
    address: "",
    address2: "",
    pincode: 0,
    currencyId: "",
    timeZoneName: "",
    companyName: userData?.companyName,
    isActive: true,
    companyId: userData?.companyId,
    _userId: "",
    _userName: "",
    logo: "",
    logoPath: "",
    viewLogoPath: "",
  });

  const [StateList, setStateList] = useState([]);

  const getStateList = async (CountryID) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetStateListByCountryId?CountryId=${CountryID}`
      );
      console.log("State List", response);
      setStateList(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const SaveBranchName = async (e) => {
    e.preventDefault();
    console.log(BranchFormDetails)
    setFormErrors({});
    try {
          
    BranchMasterSchema.validate(BranchFormDetails, { abortEarly: false })
    .then(async () => {

       const BranchFormData = new FormData();
             
       BranchFormData.append("branchId", BranchFormDetails.branchId);
       BranchFormData.append("branchName", BranchFormDetails.branchName);
       BranchFormData.append("branchCode", BranchFormDetails.branchCode);
       BranchFormData.append("countryId", String(BranchFormDetails.countryId));
       BranchFormData.append("stateId", String(BranchFormDetails.stateId));
       BranchFormData.append("address1", BranchFormDetails.address1);
       BranchFormData.append("address", BranchFormDetails.address);
       BranchFormData.append("address2", BranchFormDetails.address2);
       BranchFormData.append("pincode", BranchFormDetails.pincode);
       BranchFormData.append("currencyId", BranchFormDetails.currencyId);
       BranchFormData.append("timezoneId", String(BranchFormDetails.timezoneId));
       BranchFormData.append("companyName", BranchFormDetails.companyName);
       BranchFormData.append("isActive", true);
       BranchFormData.append("companyId", userData?.companyId);
       BranchFormData.append("_userId", String(userData?.userId));
       BranchFormData.append("_userName", userData?.userName);
       BranchFormData.append("logo", BranchFormDetails.logo);
       BranchFormData.append("logoPath", BranchFormDetails.logoPath);
       
       const response = await authApi.post(
         `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/SaveBranchMaster`,
         BranchFormData,{
           headers: {
             "Content-Type": "multipart/form-data",
           },
         }
       );

       console.log("Branch Response",response); 
       
      }).catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setFormErrors(newErrors);
      });

    } catch(err) {
       console.log("Branch Error",err);
    }

  }


  return (
    <div>
      <div className="grid md:grid-cols-2 sm:grid-cols-1">
        <div className=" bg-slate-800 hidden  md:my-38 md:flex  items-center place-content-center ">
          <img
            className=" h-auto p-10 lg:p-28 mx-auto"
            src="/images/CreateBranch.png"
            alt=""
          />
        </div>
        <div className="basis-1/2   h-auto md:h-screen flex items-center justify-start py-12 px-4 sm:px-6 lg:px-8 sm:basis-1">
          <div className="max-w-md w-full space-y-8 sm:max-w-md md:max-w-2xl">
            <div>
              <h2 className="mt-6 text-3xl font-bold text-gray-800">
                Branch (Location) Details
              </h2>
            </div>
            <form className="mt-6 space-y-4" onSubmit={SaveBranchName}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <label>Branch Name<span className="text-red-600">*</span></label>
                  <Input
                    isInvalid={FormErrors?.branchName}
                    value={BranchFormDetails?.branchName || ""}
                    onChange={({ target }) => {
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        branchName: target.value,
                      });
                    }}
                    borderColor="gray"
                    placeholder="Enter Branch Name"
                  />
                  {FormErrors?.branchName && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.branchName}
                    </span>
                  )}
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label>Branch Code<span className="text-red-600">*</span></label>
                    <Input
                      isInvalid={FormErrors?.branchCode}
                      value={BranchFormDetails?.branchCode || ""}
                      onChange={({ target }) => {
                        setBranchFormDetails({
                          ...BranchFormDetails,
                          branchCode: target.value,
                        });
                      }}
                      borderColor="gray"
                      placeholder="Enter Branch Code"
                    />
                    {FormErrors?.branchCode && (
                      <span className="text-red-600 my-1 text-sm">
                        {FormErrors?.branchCode}
                      </span>
                    )}
                  </div>

                  <div className="space-y-2">
                    <label>Branch Logo<span className="text-red-600">*</span></label>
                    <Input
                      ref={FileRef}
                      accept="image/*"
                      isInvalid={FormErrors?.logo}
                      onChange={({ target }) => {
                        setBranchFormDetails({
                          ...BranchFormDetails,
                          logo: target.files[0],
                        });
                        console.log("Logo", target.files[0]);
                      }}
                      borderColor="gray"
                      type={"file"}
                      placeholder="Enter Branch Code"
                    />
                    {FormErrors?.logo && (
                      <span className="text-red-600 my-1 text-sm">
                        {FormErrors?.logo}
                      </span>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <label>Country Name<span className="text-red-600">*</span></label>
                  <ReactSelect
                    required
                    options={CountryList}
                    value={BranchFormDetails?.countryName}
                    className="w-full my-auto"
                    onChange={(value) => {
                      console.log("Country ID", value.countryId);
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        countryId: value.countryId,
                        countryName: value,
                      });
                      getStateList(value.countryId);
                    }}
                    getOptionLabel={(option) => option.countryName}
                    getOptionValue={(option) => option.countryId}
                    placeholder="Select Country"
                  />
                  {FormErrors?.countryId && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.countryId}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <label>State Name<span className="text-red-600">*</span></label>
                  <ReactSelect
                    required
                    options={StateList}
                    value={BranchFormDetails?.stateName}
                    className="w-full my-auto"
                    onChange={(value) => {
                      console.log("State ID", value.stateId);
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        stateId: value.stateId,
                        stateName: value,
                      });
                    }}
                    getOptionLabel={(option) => option.stateName}
                    getOptionValue={(option) => option.stateId}
                    placeholder="Select State"
                  />
                  {FormErrors?.stateId && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.stateId}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <label>Address Line 1<span className="text-red-600">*</span></label>
                  <Textarea
                    isInvalid={FormErrors?.address1}
                    value={BranchFormDetails?.address1 || ""}
                    onChange={({ target }) => {
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        address1: target.value,
                      });
                    }}
                    borderColor="gray"
                    placeholder="Enter Address Line 1"
                  />
                  {FormErrors?.address1 && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.address1}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <label>Address Line 2<span className="text-red-600">*</span></label>
                  <Textarea
                    isInvalid={FormErrors?.address2}
                    value={BranchFormDetails?.address2 || ""}
                    onChange={({ target }) => {
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        address2: target.value,
                      });
                    }}
                    borderColor="gray"
                    placeholder="Enter Address Line 2"
                  />
                  {FormErrors?.address2 && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.address2}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <label>Pincode<span className="text-red-600">*</span></label>
                  <Input
                    type="number"
                    isInvalid={FormErrors?.pincode}
                    value={BranchFormDetails?.pincode || ""}
                    onChange={({ target }) => {
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        pincode: target.value,
                      });
                    }}
                    borderColor="gray"
                    placeholder="Pincode"
                  />
                  {FormErrors?.pincode && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.pincode}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <label>Currency Name<span className="text-red-600">*</span></label>
                  <Select
                    isInvalid={FormErrors?.currencyId}
                    value={BranchFormDetails?.currencyId || ""}
                    onChange={({ target }) => {
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        currencyId: target.value,
                      });
                    }}
                    borderColor="gray"
                    placeholder="Please Select Currency"
                  >
                    {CurrencyList.map((data, i) => {
                      return (
                        <option key={i} value={data.currencyId}>
                          {data.currencyName}
                        </option>
                      );
                    })}
                  </Select>
                  {FormErrors?.currencyId && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.currencyId}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <label>Time Zone<span className="text-red-600">*</span></label>
                  {/* <Select
                    isInvalid={FormErrors?.timezoneId}
                    value={BranchFormDetails?.timezoneId || ""}
                    onChange={({ target }) => {
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        timezoneId: target.value,
                      });
                    }}
                    borderColor="gray"
                    placeholder="Please Select Time Zone"
                  >
                    {TimezoneDataList?.map((data) => {
                      return (
                        <option key={data?.timezoneId} value={data?.timezoneId}>
                          {data?.timezoneName}
                        </option>
                      );
                    })}
                  </Select> */}

                  <ReactSelect
                    required
                    options={TimezoneDataList}
                    value={BranchFormDetails?.timeZoneName}
                    className="w-full my-auto"
                    onChange={(value) => {
                      console.log("Timezone ID", value.timezoneId);
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        timezoneId: value.timezoneId,
                        timeZoneName: value,
                      });
                    }}
                    getOptionLabel={(option) => option.timezoneName}
                    getOptionValue={(option) => option.timezoneId}
                    placeholder="Select Timezone"
                  />

                  {FormErrors?.timezoneId && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.timezoneId}
                    </span>
                  )}
                </div>

                <div className="space-y-2">
                  <label>Company Name<span className="text-red-600">*</span></label>
                  <Input
                    isInvalid={FormErrors?.companyName}
                    value={BranchFormDetails?.companyName || ""}
                    onChange={({ target }) => {
                      setBranchFormDetails({
                        ...BranchFormDetails,
                        companyName: target.value,
                      });
                    }}
                    borderColor="gray"
                    placeholder="Enter Company Name"
                  />
                  {FormErrors?.companyName && (
                    <span className="text-red-600 my-1 text-sm">
                      {FormErrors?.companyName}
                    </span>
                  )}
                </div>
              </div>

              <div className="text-right space-x-4 mt-2">
                <Button 
                  onClick={async () => {
                  const response = await signOut();
                  console.log("Response",response);
                  if(response){
                    navigate('/')
                  }
                }}
                  variant={'outline'}
                  colorScheme={"purple"}
                  leftIcon={<TbLogout className="rotate-180" size='18px' />}
                >
                  Logout
                </Button>
                <Button
                  isLoading={BranchPostLoading}
                  loadingText={"Creating..."}
                  type="submit"
                  colorScheme={"purple"}
                >
                  Create Branch
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBranch;
