import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import {
    Box,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Search } from "react-feather";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import moment from "moment";
import _ from "lodash";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";


function DesignationTracking() {

  const { userData } = useAuth();

    const {
        isOpen: isImportOpen,
        onOpen: onImportOpen,
        onClose: onImportClose,
      } = useDisclosure();

    const [EmpList, setEmpList] = useState([]);
    const [SelectedEmp, setSelectedEmp] = useState([]);

     const [ImportedDesignations, setImportedDesignations] = useState([]);
     const ExcelHeads = [
      { label: "guId", key: `guId`},
      { label: "empName", key: `fullName`},
      { label: "empCode", key: `empCode`},
      { label: "designationName", key: `designationName`},
      { label: "departmentName", key: `departmentName`},
     ]

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); // Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Old Designation",
        field: "designationOldName"
      },
      {
        headerName: "New Designation",
        field: "designationNewName"
      },
      {
        headerName: "Status",
        field: "designationStatus",
        cellRenderer:(params) => params.data.designationStatus === 1 ? 
        <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Current
        </span> :
        <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Old
        </span>
      },
      {
        headerName: "Start Date",
        field: "desigStartDate",
        cellRenderer : (params) => <span>{moment(params.data.desigStartDate).format('DD/MM/YYYY')}</span>
      },
      {
        headerName: "End Date",
        field: "desigEndDate",
        cellRenderer : (params) => <span>{moment(params.data.desigEndDate).format('DD/MM/YYYY')}</span>
      }
      ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      // cellStyle: {textAlign: 'center'},
      cellClass: "no-border ",
    }));


    useEffect(()=>{
        getEmployeeList();
    },[])

    const getEmployeeList = async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Employee List", response.data);
    
          const res = response.data;
          let ActiveEmpList = res.filter((data) => data.isActive === true);
          setEmpList(ActiveEmpList);
        } catch (error) {
          console.error(error);
        }
      };


      const GetEmpDesignations = async () => {
        try{
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/DesignationTracking/GetDesignationTrackingDetailByGuid?EmpGUID=${SelectedEmp.guId}`
          )
          console.log("Emp Designations",response);
          const res = response.data
          setRowData(_.orderBy(res,'designationStatus',['desc']));
        }catch (err) {
          console.error(err);
        }
      }


      const readExcel = (file)=>{

        const promise = new Promise((resolve,reject) => {
          
         const fileReader = new FileReader();
         fileReader.readAsArrayBuffer(file);
      
         fileReader.onload=(e)=>{
           const bufferArray = e.target.result;
      
           const wb = XLSX.read(bufferArray,{type:'buffer'});
           const wsname = wb.SheetNames[0];
      
           const ws = wb.Sheets[wsname];
      
           const data = XLSX.utils.sheet_to_json(ws, {raw: false});
      
           resolve(data)
         } 
      
         fileReader.onerror = (error) => {
           reject(error)
         }
      
        });
      
        promise.then((data)=>{
          console.log("Imported Data",data);
      
          let FinalData = data.map((obj)=>{
            return {...obj,
              companyId:userData.companyId,
              branchId:userData.isActiveBranch
            }
           })
      
           console.log("Final Data",FinalData);
         setImportedDesignations(FinalData);

        });
      
      }





  return (
    <div>

<div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Designations</h1>
        <Button onClick={onImportOpen} leftIcon={<FontAwesomeIcon icon={faFileImport} />} colorScheme="purple">Import Designations</Button>
      </div>

<Box className="bg-white rounded-2xl p-4 flex gap-8 items-end  mb-4">
              <div className="space-y-2 w-1/4">
                <label>Select Employee</label>
                <ReactSelect
                  options={EmpList}
                  value={SelectedEmp}
                  onChange={(value) => {
                    setSelectedEmp(value);
                  }}
                  getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
                  getOptionValue={(option) => option.guId}
                  placeholder="Select Employee"
                />
              </div>

              <Button 
                isDisabled={SelectedEmp.length === 0 ? true : false}
                leftIcon={<Search size={"16px"} />} 
                onClick={()=>{GetEmpDesignations();}}
                colorScheme={"purple"}
              >
                Search
              </Button>
            </Box>

            
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>


            <Modal size='5xl' scrollBehavior='inside' isOpen={isImportOpen} onClose={()=>{
               setImportedDesignations([]);
               onImportClose();
            }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Designations</ModalHeader>
          <ModalCloseButton />
          <hr />
            <ModalBody>

              <div className='mt-4'>
               <CSVLink data={EmpList} headers={ExcelHeads} filename={"Designation Data.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Designations</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(ImportedDesignations)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedDesignations([]);
                    onImportClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default DesignationTracking