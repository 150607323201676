import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, RadioGroup, Radio, Stack } from "@chakra-ui/react";
import { Search } from "react-feather";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _ from "lodash";
import AllEmpLeaveAppliedReport from "./AllEmpLeaveAppliedReport";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function LeaveReports() {
  const { userData } = useAuth();

  const [ShowEmployees, setShowEmployees] = useState('1');
  const [AllEmpList, setAllEmpList] = useState([]);
  const [FilteredList, setFilteredList] = useState([]);

  const [AllEmployeeRowData, setAllEmployeeRowData] = useState([]);

  const [MonthlyLeavetypeData, setMonthlyLeavetypeData] = useState([]);

  const [SelectedEmp, setSelectedEmp] = useState([]);
  const {
    isOpen: ismodalopen,
    onOpen: onmodalopen,
    onClose: onmodalclose,
  } = useDisclosure();

  const MonthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const WeekNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [EmpLeaveReportRowData, setEmpLeaveReportRowData] = useState([]);

  const [EmpReason, setEmpReason] = useState();

  const [SelectedDateMonth, setSelectedDateMonth] = useState(new Date());

  const [StartDateOfMonth, setStartDateOfMonth] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [EndDateOfMonth, setEndDateOfMonth] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  console.log(
    "Start Date of Month",
    StartDateOfMonth,
    "End Date of Month",
    EndDateOfMonth
  );

  const [MonthDates, setMonthDates] = useState([]);

  const [LeaveHistoryDateRange, setLeaveHistoryDateRange] = useState([
    null,
    null,
  ]);
  const [LeaveHistoryStartDate, LeaveHistoryEndDate] = LeaveHistoryDateRange;

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Employee Name",
      field: "empName",
    },

    {
      headerName: "From Date",
      field: "leaveFromDate",
      cellRenderer:(params) => <span>{new Date(params.data.leaveFromDate).toLocaleDateString("en-GB")}</span>
    },
    {
      headerName: "To Date",
      field: "leaveToDate",
      cellRenderer:(params) => <span>{new Date(params.data.leaveFromDate).toLocaleDateString("en-GB")}</span>

    },
    {
      headerName: "Days",
      field: "leaveNoOfDays",
      width: 100,
    },
    {
      headerName: "Leave Type",
      field: "leaveType",
      cellRenderer: (params) =>
        params.data.leaveType !== null ? (
          <span className={`${ params.data.leaveType === "CL" || params.data.leaveType === "Casual Leave" ? "bg-blue-500" : params.data.leaveType === "SL" ? "bg-red-500" : params.data.leaveType === "FL" ? "bg-lime-400" : "" } text-white rounded-md px-1 py-0.5 text-sm font-medium`}>
            {params.data.leaveType}
          </span>
        ) : (
          ""
        ),
    },

    {
      headerName: "Leave Reason",
      field: "leaveReason",
      cellRenderer: (params) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            setEmpReason(params.data.leaveReason);
            onmodalopen();
          }}
        >
          {params.data.leaveReason}
        </div>
      ),
    },

    {
      headerName: "Leave Status",
      field: "leaveStatus",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.leaveStatus === "Pending" ? (
          <div className="space-x-4">
            <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Pending
            </span>
          </div>
        ) : params.data.leaveStatus === "Approve" ||
          params.data.leaveStatus === "Approved" ? (
          <div className="space-x-4">
            <span className="bg-green-600 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Approved
            </span>
          </div>
        ) : params.data.leaveStatus === "Reject" ? (
          <div className="space-x-4">
            <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Rejected
            </span>
          </div>
        ) : (
          ""
        ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    // cellStyle: {textAlign: 'center'},
    cellClass: "no-border ",
  }));

  const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push({
        fullDate: new Date(date).toLocaleDateString("en-CA"),
        monthDate: new Date(date).getDate(),
        dateWeekDay: WeekNames[new Date(date).getDay()],
        month: MonthNames[new Date(date).getMonth()],
      });
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  const getLeaveTypeDates = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push({
        LeaveDate: new Date(date).toLocaleDateString("en-CA"),
        LeaveMonth: new Date(date).getMonth(),
        LeaveTypeName: "-",
      });
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  
  useEffect(() => {
    if(ShowEmployees === '2'){
      setFilteredList(AllEmpList.filter((emp)=> emp.isActive === false));
    }else{
      setFilteredList(AllEmpList.filter((emp)=> emp.isActive === true));
    }
  }, [ShowEmployees]);

  useEffect(() => {
    getLeaveAppliedList(StartDateOfMonth, EndDateOfMonth);
    getEmployeeList();

    setMonthDates(
      getAllDaysInMonth(
        SelectedDateMonth.getFullYear(),
        SelectedDateMonth.getMonth()
      )
    );

    setMonthlyLeavetypeData(
      getLeaveTypeDates(
        SelectedDateMonth.getFullYear(),
        SelectedDateMonth.getMonth()
      )
    );
  }, []);

  console.log("Month Dates", MonthDates);
  console.log("MonthlyLeaveTypeData", MonthlyLeavetypeData);

  const getEmployeeList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee List", response.data);

      const res = response.data;

      setAllEmpList(res);
      setFilteredList(res);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleLeaveReportSearch = async (FromDate, ToDate) => {
    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/EmployeeDashBoard/GetEmployeeLeaveHistory?EmpGuid=${
          SelectedEmp.guId
        }&Fromdate=${new Date(FromDate).toLocaleDateString(
          "en-CA"
        )}&ToDate=${new Date(ToDate).toLocaleDateString("en-CA")}&CompanyId=${
          userData.companyId
        }&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Report Data", response.data);

      const res = response.data;

      let ApprovedList = res.filter(
        (data) =>
          data.leaveStatus === "Approve" || data.leaveStatus === "Approved"
      );
      setEmpLeaveReportRowData(res);
    } catch (error) {
      console.error(error);
    }
  };

  const getLeaveAppliedList = async (FromDate, ToDate) => {
    console.log("From Date", FromDate);
    console.log("TO Date", ToDate);
    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Leave/GetLeaveApplyList?startdate=${new Date(
          FromDate
        ).toLocaleDateString("en-CA")}&Enddate=${new Date(
          ToDate
        ).toLocaleDateString("en-CA")}&CompanyId=${
          userData.companyId
        }&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Applied List", response.data);

      const res = response.data;

      let ApprovedList = res.filter(
        (data) =>
          data.leaveStatus === "Approve" || data.leaveStatus === "Approved"
      );

      console.log("Approved Leave List", ApprovedList);
      setAllEmployeeRowData(ApprovedList);
    } catch (error) {
      console.error(error);
    }
  };

  const GetDatesArray = (StartDate, EndDate, LeaveType) => {
    for (
      var a = [], d = new Date(StartDate);
      d <= new Date(EndDate);
      d.setDate(d.getDate() + 1)
    ) {
      // a.push(new Date(d));
      a.push({
        LeaveDate: moment(new Date(d)).format("YYYY-MM-DD"),
        LeaveMonth: new Date(d).getMonth(),
        LeaveTypeName: LeaveType,
      });
    }
    return a;
  };

  console.log("Seleccted  Date Month", SelectedDateMonth);

  const exportData = () => {
    const params = {
      fileName: `Leave Report.csv`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  };

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Reports</h1>
      </div>

      <Tabs size={"sm"} variant={"solid-rounded"} colorScheme={"purple"}>
        <TabList>
          <Tab>All Employees</Tab>
          <Tab>Individual</Tab>
          <Tab>All Applied Leave</Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={1}>
            <div>
              <div className="flex justify-end items-center gap-4 mb-4">
                <Button
                  onClick={() => {
                    let PrevMonth = moment(SelectedDateMonth).subtract(1, "M");
                    console.log("Next Month", PrevMonth);

                    let PreviousMonthStartDate = moment(PrevMonth)
                      .startOf("month")
                      .format("YYYY-MM-DD");
                    let PreviousMonthEndDate = moment(PrevMonth)
                      .endOf("month")
                      .format("YYYY-MM-DD");

                    console.log(
                      "Previous Month Start Date",
                      PreviousMonthStartDate
                    );
                    console.log(
                      "Previous Month End Date",
                      PreviousMonthEndDate
                    );

                    setSelectedDateMonth(PrevMonth);
                    setMonthDates(
                      getAllDaysInMonth(
                        new Date(PrevMonth).getFullYear(),
                        new Date(PrevMonth).getMonth()
                      )
                    );

                    setMonthlyLeavetypeData(
                      getLeaveTypeDates(
                        new Date(PrevMonth).getFullYear(),
                        new Date(PrevMonth).getMonth()
                      )
                    );
                    getLeaveAppliedList(
                      PreviousMonthStartDate,
                      PreviousMonthEndDate
                    );
                  }}
                  size={"sm"}
                  colorScheme="purple"
                  variant={"solid"}
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                  Previous
                </Button>

                <h6 className="font-bold">
                  {MonthNames[new Date(SelectedDateMonth).getMonth()]}
                </h6>
                <Button
                  onClick={() => {
                    let NextMonth = moment(SelectedDateMonth).add(1, "M");
                    console.log("Next Month", NextMonth);

                    let NextMonthStartDate = moment(NextMonth)
                      .startOf("month")
                      .format("YYYY-MM-DD");
                    let NextMonthEndDate = moment(NextMonth)
                      .endOf("month")
                      .format("YYYY-MM-DD");

                    console.log("Next Month Start Date", NextMonthStartDate);
                    console.log("Next Month End Date", NextMonthEndDate);

                    setSelectedDateMonth(NextMonth);
                    setMonthDates(
                      getAllDaysInMonth(
                        new Date(NextMonth).getFullYear(),
                        new Date(NextMonth).getMonth()
                      )
                    );

                    setMonthlyLeavetypeData(
                      getLeaveTypeDates(
                        new Date(NextMonth).getFullYear(),
                        new Date(NextMonth).getMonth()
                      )
                    );
                    getLeaveAppliedList(NextMonthStartDate, NextMonthEndDate);
                  }}
                  size={"sm"}
                  colorScheme="purple"
                  variant={"solid"}
                >
                  Next
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </Button>
              </div>

              <div className="overflow-x-auto relative rounded-2xl drop-shadow-md">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase whitespace-nowrap bg-[#e9e9e9]">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Employee Code
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Employee Name
                      </th>

                      {MonthDates.map((data, i) => {
                        return (
                          <th key={i} scope="col" className="py-3 px-6">
                            <div className="text-center ">
                              <h6 className="text-gray-700 block font-bold text-sm ">
                                {data.monthDate} {data.month}
                              </h6>
                              <p className="text-gray-500 font-normal text-xs">
                                {data.dateWeekDay}
                              </p>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_.uniqBy(AllEmployeeRowData, "empGuid").map((data, i) => {
                      return (
                        <tr
                          key={i}
                          className="odd:bg-gray-50 even:bg-[#ecebeb] border-b whitespace-nowrap "
                        >
                          <th
                            scope="row"
                            className="py-2 px-6 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {data.empCode}
                          </th>
                          <td className="py-2 px-6">{data.empName}</td>

                          {
                            MonthDates.map(() => {
                              let Employee = AllEmployeeRowData.filter(
                                (employeedata) =>
                                  employeedata.empGuid === data.empGuid
                              );

                              // console.log("Employee",Employee);

                              let LeaveDates = Employee.map((data) => {
                                let ArrayData = GetDatesArray(
                                  moment(data.leaveFromDate).format(
                                    "YYYY-MM-DD"
                                  ),
                                  moment(data.leaveToDate).format("YYYY-MM-DD"),
                                  data.leaveType
                                );
                                return ArrayData;
                              });

                              let FlattenLeaveDates = _.flatten(LeaveDates);

                              console.log("LeaveDates", FlattenLeaveDates);

                              const LeaveRowData = _.unionBy(
                                FlattenLeaveDates,
                                MonthlyLeavetypeData
                              );

                              let SortedLeaveRowData = _.sortBy(
                                _.uniqBy(LeaveRowData, "LeaveDate"),
                                "LeaveDate"
                              );

                              console.log(
                                "Sorted Leave Data",
                                SortedLeaveRowData.filter(
                                  (Sorteddata) =>
                                    Sorteddata.LeaveMonth + 1 ===
                                    new Date(SelectedDateMonth).getMonth() + 1
                                )
                              );

                              return SortedLeaveRowData.filter(
                                (Sorteddata) =>
                                  Sorteddata.LeaveMonth + 1 ===
                                  new Date(SelectedDateMonth).getMonth() + 1
                              ).map((DatesData, K) => {
                                if (DatesData.LeaveTypeName !== "-") {
                                  return (
                                    <td className="py-2 px-6 text-center">
                                      <span
                                        className={`${
                                          DatesData.LeaveTypeName === "CL" ||
                                          DatesData.LeaveTypeName ===
                                            "Casual Leave"
                                            ? "bg-blue-500"
                                            : DatesData.LeaveTypeName === "SL"
                                            ? "bg-red-500"
                                            : DatesData.LeaveTypeName === "FL"
                                            ? "bg-lime-400"
                                            : ""
                                        } text-white rounded-md px-1 py-0.5 text-sm font-medium`}
                                      >
                                        {DatesData.LeaveTypeName}
                                      </span>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td className="py-2 px-6 text-center">-</td>
                                  );
                                }
                              });
                            })[0]
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </TabPanel>
          <TabPanel px={1}>
            <Box className="bg-white rounded-2xl p-4 flex gap-8 items-end  mb-4">

            <div className="space-y-3">
              <label>Employee List</label>
              <RadioGroup  onChange={setShowEmployees} value={ShowEmployees}>
                <Stack mb={2} direction='row' gap={2}>
                  <Radio borderColor={'gray.400'} value='1'>Active Employees</Radio>
                  <Radio borderColor={'gray.400'} value='2'>Inactive Employees</Radio>
                </Stack>
              </RadioGroup>
            </div>


              <div className="space-y-2 w-1/4">
                <label>Select Employee</label>
                <ReactSelect
                  options={FilteredList}
                  value={SelectedEmp}
                  onChange={(value) => { setSelectedEmp(value); }}
                  getOptionLabel={(option) => `${option.fullName} ${option.empCode}` }
                  getOptionValue={(option) => option.guId}
                  key={(option)=> option.id}
                  placeholder="Select Employee"

                />
              </div>

              <div className="space-y-2">
                <label>Select Date Range</label>
                <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
                  selectsRange={true}
                  startDate={LeaveHistoryStartDate}
                  endDate={LeaveHistoryEndDate}
                  onChange={(update) => {
                    setLeaveHistoryDateRange(update);
                  }}
                  dateFormat="dd/MM/yyyy"
                  // isClearable={true}
                  showMonthDropdown
                  showYearDropdown
                  placeholderText="Select Leave Report Range"
                />
              </div>

              <Button
                onClick={() => {
                  HandleLeaveReportSearch(
                    LeaveHistoryStartDate,
                    LeaveHistoryEndDate
                  );
                }}
                colorScheme={"purple"}
              >
                <Search size={"16px"} className="mr-2" />
                Search
              </Button>
            </Box>

            <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-2 text-left">
              {/* <div className="bg-white px-4 py-1 rounded-lg flex items-center gap-2">
                <h6 className="font-semibold text-lg">Total: </h6>
                {_.uniqBy(EmpLeaveReportRowData,'leaveType')?.map((UniqData,i)=>{
                  return <div key={i}>
                     <label className="font-medium text-lg">{UniqData?.leaveType}: {EmpLeaveReportRowData?.filter(data => data.leaveType === UniqData.leaveType)?.length}</label>
                    </div>
                })}
              </div> */}
              <Button
                isDisabled={EmpLeaveReportRowData.length === 0 ? true : false}
                leftIcon={<FontAwesomeIcon icon={faDownload} />}
                onClick={() => {
                  exportData();
                }}
                colorScheme="purple"
              >
                Export
              </Button>
            </div>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={EmpLeaveReportRowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </TabPanel>
          <TabPanel px={1}>
            <AllEmpLeaveAppliedReport/>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={ismodalopen} onClose={onmodalclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reason</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div>{EmpReason}</div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onmodalclose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default LeaveReports;
