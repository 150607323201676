import { useEffect, useState } from 'react'
import { useAuth } from './AuthContext';
import authApi from './AuthApis';

const useFinancialYear = () => {
    const {userData} = useAuth();

    const [FinancialYear,setFinancialYear] = useState();

    useEffect(()=>{
        const GetFinancialYearList = async () => {
            try {
                const response = await authApi.get(
                    `${process.env.REACT_APP_ACQ_URL}/api/Master/GetFinancialYearMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                // console.log("Financial Year List",response);
                const res = response.data;
                setFinancialYear(res.filter(data => data.isCurrent === true)[0]);
            } catch (error) {
                console.error(error)
            }
        }
        GetFinancialYearList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  return FinancialYear
}

export default useFinancialYear