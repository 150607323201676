import React , {useState} from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  InputGroup,InputRightElement,Button, Checkbox, useToast
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Eye, EyeOff } from "react-feather";

function Register() {
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const toast = useToast();
    const id = "toast";
  
    const [tabIndex, setTabIndex] = useState(0)
  
  
    // Sign Up Variables
    const [RegisterName, setRegisterName] = useState();
    const [RegisterEmail, setRegisterEmail] = useState();
    const [RegisterPassword, setRegisterPassword] = useState();
    const [RegisteredConfirmPassword, setRegisteredConfirmPassword] = useState();
  
    const [ComparePassword, setComparePassword] = useState(true);
  
  
    // Sign In Variables
    const [signemail, setSignEmail] = useState('');
    const [signpassword,setSignPassword] = useState('');  
     const navigate = useNavigate();
  
     const handleTabsChange = (index) => {
      setTabIndex(index)
    }
  
     const handleSubmit = async (e) =>{
        
      e.preventDefault();

      let body = {
        userName : signemail,
        password : signpassword
      }
  
      console.log("Login Body",body);
  
  
      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyUserLogin`,body
      )
      .then((response) => {
        // console.log(response);
        const json =  response.data;
  
        console.log("json",json);
       // setUser(response.data)
       // console.log("user data",user);
       if (json === 'Failed') {
         // redirect
         alert("Invalid Credentials");
       }else{
         console.log("Success");
         // console.log(json);
         localStorage.removeItem('user');
         localStorage.setItem('user', JSON.stringify(json));
         localStorage.setItem('loggedInEmail', json.userName);
   
         console.log("Is Password Reset",json.isPasswordReset);
         console.log("Company Id",json.companyId);
         console.log("Branch Id",json.branchId);
  
         if (json.companyId === 0) {
          navigate('/createcompany'); 
         }
         else{
          if(json.isActiveBranch === 0){
            navigate('/createbranch'); 
           }else{
            if(!json.isPasswordReset){
              navigate('/changepassword');
            }else{
             navigate('/dashboard'); 
            }
           }
           }
         }
  
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Network Error`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
    }
  
  
  
  
    const HandleSignUp = async (e) => {
  
      e.preventDefault();
  
  
      if(ComparePassword){   
         try {
  
        // const response = await axios.get(
        //   `${process.env.REACT_APP_ACQ_URL}/api/Account/CheckNewRegisterUser?UserName=${RegisterEmail}`
        // )
        // console.log("User Exist Response",response);
  
        // const UserExists = response.data;
  
        // if(!UserExists){
        let body = {
        // role : "string",
        // userId : 0,
        
        // guId : "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name : RegisterName,
        userName : RegisterEmail,
        password : RegisterPassword,
        confirmPassword : RegisteredConfirmPassword,
        }
  
//         {
//   "role": "string",
//   "userId": 0,
//   "name": "string",
//   "guId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "userName": "user@example.com",
//   "password": "string",
//   "passwordHash": "string",
//   "confirmPassword": "string",
//   "createdOn": "2024-11-07T17:12:02.619Z",
//   "createdBy": 0,
//   "modifyOn": "2024-11-07T17:12:02.619Z",
//   "modifyBy": 0,
//   "isOnline": 0,
//   "planId": "string",
//   "paymentNote": "string",
//   "planUsers": 0,
//   "addonUsers": 0,
//   "addonData": 0,
//   "amount": 0,
//   "mobile": "string",
//   "companyName": "string"
// }
      console.log("Register Body",body);
  
  
      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/AddUser`,body
      )
      .then((response) => {
        console.log("Sign Up Response",response);
        const res =  response.data;
  
        if(res){
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "Success",
              description: `Account Created Successfully! Now You can Sign In`,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
  
          setRegisterName('');
          setRegisterEmail('');
          setRegisterPassword('');
          setRegisteredConfirmPassword('');
          navigate('/'); 
          
          // setTabIndex(1);
  
        }
  
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Network Error`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  
        // }else{
        //   toast({
        //     id,
        //     title: "User Already Exists",
        //     description: `Please Sign In with your Registered Credentials`,
        //     position: "top",
        //     status: "info",
        //     duration: 2000,
        //     isClosable: true,
        //   });
  
        //   setRegisterName('');
        //   setRegisterEmail('');
        //   setRegisterPassword('');
        //   setRegisteredConfirmPassword('');
          
        //   setTabIndex(1);
  
        // }
        
      } catch (error) {
        console.error(error)
      }
  
      }
  
      
  
  
  
    }
  
  
    return (
      <div className="h-screen drop-shadow-md loginBgMesh flex">
        <div className="bg-white rounded-3xl mx-auto  max-w-3xl w-full my-auto grid grid-cols-2 ">
          <div className="flex rounded-l-3xl bg-gradient-to-tr from-indigo-200 via-red-200 to-yellow-100 p-4">
            <div className="my-auto mx-auto space-y-4">
              <img src="/HRMusterLogo.png" className="h-56" alt="hrms-login" />
              <div className="text-center">
               <h1 className="text-3xl font-bold ">HR Muster</h1>
              </div>
            </div>
          </div>
          <div className=" rounded-r-3xl">
            <Tabs colorScheme={"purple"} size='lg'  index={tabIndex} onChange={handleTabsChange}  isFitted>
              <TabList>
                <Tab>Sign Up</Tab>
                {/* <Tab>Sign In</Tab> */}
              </TabList>
              <TabPanels>
  
                {/* Sign Up Form */}
                <TabPanel>
                <form className="space-y-4" onSubmit={HandleSignUp}>
  
                <div className="space-y-2">
                      <label>Name</label>
                      <Input
                        isRequired
                        value={RegisterName || ''}
                        onChange={({target})=>{setRegisterName(target.value)}}
                        borderColor="gray"
                        placeholder="Enter Name"
                      />
                    </div>
  
                    <div className="space-y-2">
                      <label>Email Address</label>
                      <Input
                        isRequired
                        value={RegisterEmail || ''}
                        onChange={({target})=>{setRegisterEmail(target.value)}}
                        type='email'
                        borderColor="gray"
                        placeholder="Enter Email Address"
                      />
                    </div>
  
                    <div className="space-y-2">
                      <label>Password</label>
                      <InputGroup size="md">
                        <Input
                          isRequired
                          value={RegisterPassword || ''}
                          onChange={({target})=>{setRegisterPassword(target.value)}}                      
                          borderColor='gray'
                          type={show ? "text" : "password"}
                          placeholder="Enter password"
                        />
                        <InputRightElement width="2.5rem">
                          <Button h="1.75rem" size="xs" onClick={handleClick}>
                            {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </div>
  
                    <div className="space-y-2">
                      <label>Confirm Password</label>
                      <InputGroup size="md">
                        <Input
                          isRequired
                          value={RegisteredConfirmPassword || ''}
                          onChange={({target})=>{
                            setRegisteredConfirmPassword(target.value);
  
                            // console.log(target.value);
                            // console.log(RegisterPassword)
  
                            if(target.value !== RegisterPassword){
                              setComparePassword(false);
                            }else{
                              setComparePassword(true);
                            }
                          }}   
                          borderColor='gray'
                          type={show ? "text" : "password"}
                          placeholder="Enter password"
                        />
                        <InputRightElement width="2.5rem">
                          <Button h="1.75rem" size="xs" onClick={handleClick}>
                            {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {!ComparePassword ? <span className="text-red-600 text-sm animate-ping ">Password doesn't match!!</span> : ''}
                      
                    </div>
  
                    <div>
                      <Checkbox borderColor='gray' >I accept the Terms & Conditions</Checkbox>
                    </div>
  
                    <div>
                       <Button type="submit" className="w-full" colorScheme='purple' variant='solid'>Register</Button>
                    </div>
                  </form>
                </TabPanel>
  
  
                {/* Sign In Form */}
                <TabPanel>
                  <form className="space-y-4" onSubmit={handleSubmit}>
                    <div className="space-y-2">
                      <label>Email Address</label>
                      <Input
                        value={signemail || ''}
                        onChange={({target})=>{
                          setSignEmail(target.value);
                        }}
                        type='email'
                        borderColor="gray"
                        placeholder="Enter Email Address"
                      />
                    </div>
  
                    <div className="space-y-2">
                      <label>Password</label>
                      <InputGroup size="md">
                        <Input
                          value={signpassword || ''}
                          onChange={(e)=>{setSignPassword(e.target.value)}}
                          // pr="2rem"
                          borderColor='gray'
                          type={show ? "text" : "password"}
                          placeholder="Enter password"
                        />
                        <InputRightElement width="2.5rem">
                          <Button h="1.75rem" size="xs" onClick={handleClick}>
                            {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </div>
  
                    <div className="flex justify-between">
                      <Checkbox borderColor='gray' >Remember Me</Checkbox>
  
                      <Button colorScheme='blue' variant='link' >Forgot Password?</Button>
  
                    </div>
  
                    <div>
                       <Button type="submit" className="w-full" colorScheme='purple' variant='solid'>Sign In</Button>
                    </div>
                  </form>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }

export default Register