import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogFooter,
  useDisclosure, RadioGroup, Stack, Radio
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { CheckCircle, XCircle } from "react-feather";
import moment from "moment";
import authApi from "../Utils/AuthApis";
import { useAuth } from "../Utils/AuthContext";

function EmployeeDataReport() {

  const {userData} = useAuth();

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [SelectedEmpDetails, setSelectedEmpDetails] = useState('');
  const [ShowEmployees, setShowEmployees] = useState('1');

  const gridRef = useRef();
  const EmpInfoGridRef = useRef();
  const [EmpInfoRowData, setEmpInfoRowData] = useState([]);
  const [FilteredEmpInfoRowData, setFilteredEmpInfoRowData] = useState([]);
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Employee Name",
      field: "fullName",
    },
    {
      headerName: "Personal Info",
      field: "empPersonalDetail",
      width: 140,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
    {
      headerName: "Passport/Visa Info",
      field: "empPassportVisaInfo",
      width: 165,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
    {
      headerName: "Qualification Info",
      field: "empQualificationInfo",
      width: 160,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
    {
      headerName: "Previous Job Info",
      field: "empPreviousJobDetails",
      width: 160,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
    {
      headerName: "Document Info",
      field: "employeeDocumentInfo",
      width: 145,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
    {
      headerName: "Bank Info",
      field: "empBankDetail",
      width: 115,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
    {
      headerName: "Family Info",
      field: "empFamilyDetail",
      width: 125,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
    {
      headerName: "Login Info",
      field: "employeeLogin",
      width: 115,
      cellRenderer: (params) =>
        params.data.empPersonalDetail === "Not Complete" ? (
          <span className="text-red-500 inline-block align-middle">
            <XCircle size={"18px"} />
          </span>
        ) : (
          <span className="text-green-500 inline-block align-middle">
            <CheckCircle size={"18px"} />
          </span>
        ),
    },
  ]);

  const [EmpInfoColumnDefs, setEmpInfoColumnDefs] = useState([
    {
      headerName: "Employee Code",
      field: "empCode"
    },
    {
      headerName: "Employee Name",
      field: "fullName"
    },
    {
      headerName: "Designation Name",
      field: "designationName"
    },
    {
      headerName: "Department Name",
      field: "departmentName"
    },
    {
      headerName: "Reporting Manager",
      field: "reportingOfficerName"
    },
    {
      headerName: "Contact Number",
      field: "personalMobile"
    },
    {
      headerName: "Name of Emergency",
      field: "contactName"
    },
    {
      headerName: "Emergency Contact",
      field: "emergencyContactNo"
    },
    {
      headerName: "Personal Email",
      field: "personalEmail"
    },
    {
      headerName: "Official Email",
      field: "officeEmail"
    },
    {
      headerName: "DOJ",
      field: "dateofjoin",
      cellRenderer: (params) => (
        <span>{moment(params.data.dateofjoin).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      headerName: "DOR",
      field: "resignationDate",
      cellRenderer: (params) => (
        <span>
          {params.data.resignationDate === "0001-01-01T00:00:00"
            ? "-"
            : moment(params.data.resignationDate).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      headerName: "Last Working Date",
      field: "lastWorkingDate",
      cellRenderer: (params) => (
        <span>
          {params.data.lastWorkingDate === "0001-01-01T00:00:00"
            ? "-"
            : moment(params.data.lastWorkingDate).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      headerName: "DOB",
      field: "dateOfBirth",
      cellRenderer: (params) => (
        <span>
          {params.data.dateOfBirth === "0001-01-01T00:00:00"
            ? "-"
            : moment(params.data.dateOfBirth).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      headerName: "Current Address",
      field: "presentAddress",
      cellRenderer: (params) => (
        <span
          className="cursor-pointer"
          onClick={() => {
            setSelectedEmpDetails(params.data.presentAddress);
            onAlertOpen();
          }}
        >
          {params.data.presentAddress}
        </span>
      ),
    },
    {
      headerName: "Permanent Address",
      field: "permanentAddress",
      cellRenderer: (params) => (
        <span
          className="cursor-pointer"
          onClick={() => {
            setSelectedEmpDetails(params.data.permanentAddress);
            onAlertOpen();
          }}
        >
          {params.data.permanentAddress}
        </span>
      ),
    }
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
    resizable: true,
  }));

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  useEffect(() => {
    if(ShowEmployees === '2'){
      setFilteredEmpInfoRowData(EmpInfoRowData.filter((emp)=> emp.isActive === false));
    }else{
      setFilteredEmpInfoRowData(EmpInfoRowData.filter((emp)=> emp.isActive === true));
    }
  }, [ShowEmployees]);

  useEffect(() => {
    GetEmployeeDataStatusList();
    GetEmployeeInfoReport();
  }, []);

  const GetEmployeeDataStatusList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeDataStatusReport?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee Data Status Response", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetEmployeeInfoReport = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeReport?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );

      console.log("Employee Info Report", response);
      const res = response.data;
      setEmpInfoRowData(response.data);
      setFilteredEmpInfoRowData(res.filter(data => data.isActive === true))
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Reports</h1>
      </div>

      <Tabs variant={"solid-rounded"} colorScheme="purple" size={"sm"}>
        <TabList>
          <Tab>Employee Info</Tab>
          <Tab>Data Status</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="flex justify-between text-right mb-4">
            <div className="bg-white rounded-lg px-2 py-1">
        <RadioGroup  onChange={setShowEmployees} value={ShowEmployees}>
            <Stack  direction='row' gap={2}>
              <Radio borderColor={'gray.400'} value='1'>Active Employees</Radio>
              <Radio borderColor={'gray.400'} value='2'>Inactive Employees</Radio>
            </Stack>
          </RadioGroup>
        </div>

              <Button
                onClick={() => {
                  EmpInfoGridRef.current.api.exportDataAsCsv();
                }}
                size={"sm"}
                colorScheme={"purple"}
                leftIcon={<FontAwesomeIcon icon={faDownload} />}
              >
                Export
              </Button>
            </div>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={EmpInfoGridRef} // Ref for accessing Grid's API
                rowData={FilteredEmpInfoRowData} // Row Data for Rows
                columnDefs={EmpInfoColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="text-right mb-4">
              <Button onClick={onBtnExport} leftIcon={<FontAwesomeIcon icon={faDownload} />} size={"sm"} colorScheme={"purple"}>
                
                Export
              </Button>
            </div>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <AlertDialog isOpen={isAlertOpen} onClose={()=>{
        setSelectedEmpDetails('')
        onAlertClose();
      }} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent rounded={"3xl"}>
            <AlertDialogHeader
              textColor={"gray.800"}
              fontSize="lg"
              backgroundColor={"purple.200"}
              roundedTop={"3xl"}
              roundedBottom={"xl"}
              fontWeight="bold"
            >
              Address
            </AlertDialogHeader>
            <AlertDialogBody>{SelectedEmpDetails}</AlertDialogBody>
            <hr />
            <AlertDialogFooter>
              <Button
                variant={"outline"}
                colorScheme="purple"
                onClick={onAlertClose}
              >
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default EmployeeDataReport;
