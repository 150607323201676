import React from "react";
import { FaTools } from "react-icons/fa";

const Maintenance = () => {
  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12">
        <FaTools className="mx-auto mb-4 text-9xl text-gray-400" />
        <h1 className="mb-4 text-4xl font-bold tracking-tight leading-none text-gray-800 lg:mb-6 md:text-5xl xl:text-6xl">
          Under Maintenance
        </h1>
        <p className="font-light text-gray-500 md:text-lg xl:text-xl">
        <span className="font-medium">HR Muster</span> is currently undergoing scheduled maintenance. We apologize for any inconvenience. Please check back later. Thank you for your patience.
        </p>
      </div>
    </section>
  );
};

export default Maintenance;
