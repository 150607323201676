import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import ReactSelect from "react-select";
import { Box, Button, Input, useToast } from '@chakra-ui/react'
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function CarryForwardSetting() {

  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";

  const [LeaveQuotaList, setLeaveQuotaList] = useState();
  const [BaseLeave, setBaseLeave] = useState([]);
  const [CarryForwardLeave, setCarryForwardLeave] = useState([]);
  const [MaxCarryLeave, setMaxCarryLeave] = useState();

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Base Leave",
      field: "leaveType",
    },
    {
      headerName: "Carry Forward Leave",
      field: "carryForward_Leave",
    },
    {
      headerName: "Max Quota",
      field: "leave_MaxQuota",
    },
  ]);


    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      enableCellTextSelection: true,
    }));

useEffect(()=>{
  // GetCarryForwardList();
  GetLeaveQuotaList();
},[])

  const GetLeaveQuotaList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Quota List",response);
      setLeaveQuotaList(response.data);

      GetCarryForwardList(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const GetCarryForwardList = async(QuotaData) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetCarryFrwdLeaveConfig?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Carry Forward List",response);
      const res = response.data;

      const leaveQuotaMapping = {};

      QuotaData.forEach(item => {
        const { leaveQuotaId, leaveType } = item;
        leaveQuotaMapping[leaveQuotaId] = leaveType;
      });

      let FinalRowData = res.map((item)=>{
        if (item.carryForward_Leave !== 'NA') {
          const ids = item.carryForward_Leave.split('|').map(Number);
          const mappedLeaveTypes = ids.map(id => leaveQuotaMapping[id]);
          item.carryForward_Leave = mappedLeaveTypes.join(' | ');
        }
        return item;
      })
      setRowData(FinalRowData)



      // console.log("Final Row Data",res.map((item)=>{
      //   if (item.carryForward_Leave !== 'NA') {
      //     const ids = item.carryForward_Leave.split('|').map(Number);
      //     const mappedLeaveTypes = ids.map(id => leaveQuotaMapping[id]);
      //     item.carryForward_Leave = mappedLeaveTypes.join(' | ');
      //   }
      //   return item;
      // }));

    } catch (err) {
      console.error(err)
    }
  }


  const SaveCarryForwardSetting = async (e) => {
    e.preventDefault();

     
     let data = [
      {
        base_Leave : BaseLeave.leaveQuotaId,
        carryForward_Leave : CarryForwardLeave.map(data =>{
          return data.leaveQuotaId
        }).join('|'),
        leave_MaxQuota : Number(MaxCarryLeave || 0),
        companyId : userData.companyId,
        branchId : userData.isActiveBranch,
     },
     LeaveQuotaList.filter(obj => obj.leaveQuotaId !== BaseLeave.leaveQuotaId).map((data)=>{
      return {
        base_Leave : data.leaveQuotaId,
        carryForward_Leave : "NA",
        leave_MaxQuota : 0,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch,
      }
     })
     ]

     let body = data.flatMap((item) => Array.isArray(item) ? item : [item]);
     
     console.log("Save Carry Forward Setting",body);

     await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/Leave/SaveLeaveCarryFrwdConfig`,body)
     .then((response) => {
       console.log(response);
       const res = response.data;
       if (!toast.isActive(id)) {
         toast({
           id,
           title: res,
           position: "top",
           status: "success",
           duration: 2000,
           isClosable: true,
         });
       }

       GetLeaveQuotaList();

       setBaseLeave([])
       setCarryForwardLeave([])
       setMaxCarryLeave()

     })
     .catch((error) => {
       console.error(error);

       if (!toast.isActive(id)) {
         toast({
           id,
           title: "ERROR",
           description: `Carry Forward Settings not Saved`,
           position: "top",
           status: "error",
           duration: 2000,
           isClosable: true,
         });
       }
     })

  }

  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Carry Forward Setting</h1>
      </div>

      <Box className='bg-white rounded-xl drop-shadow-md p-4 mb-6'>
        <form onSubmit={SaveCarryForwardSetting}>


        <div className=' grid grid-cols-4 gap-4'>

            <div className='space-y-2'>
                <label>Base Leave Type</label>
                <ReactSelect
                  menuPortalTarget={document.body} 
                  styles={{
                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  options={LeaveQuotaList}
                  value={BaseLeave}
                  onChange={(value) => {
                    setBaseLeave(value);
                    setCarryForwardLeave([value]);
                  }}
                  getOptionLabel={(option) => option.leaveType}
                  getOptionValue={(option) => option.leaveQuotaId}
                  placeholder="Select Base Leave"
                />
            </div>

            <div className='space-y-2'>
                <label>Carry Forward Leave Type</label>
                <ReactSelect
                  menuPortalTarget={document.body} 
                  styles={{
                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  options={LeaveQuotaList}
                  isMulti
                  value={CarryForwardLeave}
                  onChange={(value) => {
                    setCarryForwardLeave(value);
                  }}
                  getOptionLabel={(option) => option.leaveType}
                  getOptionValue={(option) => option.leaveQuotaId}
                  placeholder="Select Carry Forward Leave"
                />
            </div>

            <div className='space-y-2'>
                <label>Max Leave</label>
                <Input value={MaxCarryLeave || ''} onChange={({target})=>{setMaxCarryLeave(target.value)}} type='number' borderColor={'gray.400'} placeholder='Max Carry Forward' />
            </div>

        </div>

        <div className='text-right mt-4'>
          <Button type='submit' colorScheme='purple' >Submit</Button>
        </div>

        </form>
      </Box>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

    </div>
  )
}

export default CarryForwardSetting