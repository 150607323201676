import React, { useState, useRef, useMemo, useCallback } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionPanel, AccordionItem, Input, Button, Switch, Checkbox, Select, useDisclosure, Tooltip, InputRightAddon, ModalFooter, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit, Plus } from "react-feather";
import { useAuth } from "../Utils/AuthContext";
import usePost from "../Utils/usePost";
import useFetch from "../Utils/useFetch";
import { EmpTypeSchema } from "../Utils/FormValidations";

export const AddEmployeeCategory = ({onAddEmpCategoryResponse}) => {

  const { userData } = useAuth();
  const { isOpen: isAddEmpCategoryOpen, onOpen: onAddEmpCategoryOpen, onClose: onAddEmpCategoryClose } = useDisclosure();
  const { IsPostLoading, PostData } = usePost('/api/Master/SaveEmployeeTypeMaster');
  const [Errors, setErrors] = useState({});
  const [EmpTypeDetails, setEmpTypeDetails] = useState({
    empTypeId: 0,
    empTypeName: '',
    isProbationApply: false,
    probationMonth: '',
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  return(
    <>
      <Tooltip placement='top' rounded='5px' hasArrow label='Add Employee Category' bg='gray.700' color='white'>
       <InputRightAddon onClick={onAddEmpCategoryOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>

      <Modal isOpen={isAddEmpCategoryOpen} onClose={onAddEmpCategoryClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Add Employee Category</ModalHeader>
          <ModalCloseButton mt={1.5} />
            <ModalBody>
            <div className="space-y-2 mb-2">
                  <label>Employee Catgeory Name</label>
                  <Input 
                    isInvalid={Errors?.empTypeName}
                    value={EmpTypeDetails?.empTypeName || ''}
                    onChange={({target})=>{
                      setEmpTypeDetails({...EmpTypeDetails, empTypeName : target.value});
                    }}
                    isRequired
                    borderColor="gray.400"
                    placeholder="Enter Employee Category Name"
                  />
                  {Errors?.empTypeName && <span className="text-red-600 my-1 text-sm">{Errors?.empTypeName}</span>}
                </div>

                <div className="space-y-2  mb-4">
                    <div className="flex justify-between">
                    <label>Probation Month</label>
                    <Checkbox
                    isChecked={EmpTypeDetails?.isProbationApply}
                    onChange={({ target }) => {
                      setEmpTypeDetails({...EmpTypeDetails, isProbationApply: target.checked,probationMonth: ''})

                    }}
                    borderColor="gray"
                  >
                    is Probation Apply?
                  </Checkbox>
                    </div>
                    <Select
                      isDisabled={!EmpTypeDetails?.isProbationApply}
                      isRequired={EmpTypeDetails?.isProbationApply}
                      value={EmpTypeDetails?.probationMonth || ''}
                      onChange={({ target }) => {
                        setEmpTypeDetails({...EmpTypeDetails, probationMonth: parseInt(target.value)})
                      }}
                      borderColor="gray.400"
                      placeholder="Select Month"
                    >
                      <option value="1">1 Month</option>
                      <option value="2">2 Month</option>
                      <option value="3">3 Month</option>
                    </Select>
                  </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button onClick={async(e)=>{
                  e.preventDefault();
                  EmpTypeSchema.validate(EmpTypeDetails,{abortEarly:false})
                  .then(async() => {
                    const response = await PostData(EmpTypeDetails);
                    if(response){
                      onAddEmpCategoryClose();
                      setEmpTypeDetails({
                        empTypeId: 0, 
                        empTypeName: '',
                        isProbationApply: false,
                        probationMonth: 0,
                        isActive: true,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch,
                      });
                      if(onAddEmpCategoryResponse){
                        onAddEmpCategoryResponse(true) 
                      }
                    }

                    
                    }).catch((validationErrors) => {
                      const newErrors = {};
                      validationErrors.inner.forEach((error) => {
                        newErrors[error.path] = error.message;
                      });
                      setErrors(newErrors);
                    });
                  }} isLoading={IsPostLoading} isDisabled={!EmpTypeDetails?.empTypeName?.length > 0} loadingText="Saving..." colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={onAddEmpCategoryClose}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>

        </ModalContent>
      </Modal>
    </>
  )
}

function EmployeeCategory() {

  const { userData } = useAuth();
  const { FetchedData, Refetch} = useFetch('/api/Master/GetEmployeeTypeMasterList',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  })
  const { IsPostLoading, PostData } = usePost('/api/Master/SaveEmployeeTypeMaster');
  const [Errors, setErrors] = useState({});

  const [EmpCatFormData, setEmpCatFormData] = useState({
    empTypeId: 0,
    empTypeName: '',
    isProbationApply: false,
    probationMonth: 0,
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  })

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Category Name",
      field: "empTypeName",
    },
    {
      headerName: "Probation Apply",
      field: "isProbationApply",
    },
    {
      headerName: "Probation Month",
      field: "probationMonth",
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleEmpCategorySwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: ({data}) => (
          <Button
            onClick={() => {
              setEmpCatFormData(data);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
            leftIcon={<Edit size="14px" />}
          >
            Edit
          </Button>
      ),
    },
  ]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border"
  }));


  const HandleEmpCategorySwitch = async (checked, EmpCatData) => {

    let body = {
      empTypeId: EmpCatData.empTypeId,
      srNo: EmpCatData.srNo,
      empTypeName: EmpCatData.empTypeName,
      isActive: checked,
      isProbationApply: EmpCatData.isProbationApply,
      probationMonth: EmpCatData.probationMonth,
      probationApply: EmpCatData.probationApply,
      companyId: EmpCatData.companyId,
      branchId: EmpCatData.branchId
    };

    console.log("Switch Body", body);

    const response = await PostData(body);

    if(response){
      Refetch();
    }

  };

  const SaveEmpTypeName = async (e) => {
    e.preventDefault();

    EmpTypeSchema.validate(EmpCatFormData,{abortEarly:false})
    .then(async() => {

    let body = {
      ...EmpCatFormData,
      probationMonth: Number(EmpCatFormData.probationMonth || 0),
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    };

    console.log(body);

    const response = await PostData(body);

    if(response){ 
      Refetch();
      setErrors({});
      setEmpCatFormData({
        empTypeId: 0,
        empTypeName: '',
        isProbationApply: false,
        probationMonth: 0,
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      }); 
    }

    }).catch((validationErrors) => {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Category</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Employee Category
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveEmpTypeName}>
                <div className="flex gap-8 ">
                  <div className="space-y-2">
                    <label>Category Name</label>
                    <Input 
                      isInvalid={Errors?.empTypeName}
                      value={EmpCatFormData?.empTypeName || ""}
                      onChange={({ target }) => {
                        setEmpCatFormData({
                          ...EmpCatFormData,
                          empTypeName: target.value
                        })
                      }}
                      borderColor="gray.400"
                      placeholder="Category Name"
                    />
                    {Errors?.empTypeName && <span className="text-red-600 my-1 text-sm">{Errors?.empTypeName}</span>}
                  </div>

                  <Checkbox
                    isChecked={EmpCatFormData?.isProbationApply}
                    onChange={({ target }) => {
                      setEmpCatFormData({
                        ...EmpCatFormData,
                        isProbationApply: target.checked,
                        probationMonth:''
                      })

                    }}
                    borderColor="gray"
                  >
                    is Probation Apply?
                  </Checkbox>

                  <div className="space-y-2 w-1/3 ">
                    <label>Probation Month</label>
                    <Select
                      isDisabled={!EmpCatFormData?.isProbationApply}
                      isRequired={EmpCatFormData?.isProbationApply}
                      value={EmpCatFormData?.probationMonth || ""}
                      onChange={({ target }) => {
                        setEmpCatFormData({
                          ...EmpCatFormData,
                          probationMonth: target.value
                        })
                      }}
                      borderColor="gray"
                      placeholder="Select Month"
                    >
                      <option value="1">1 Month</option>
                      <option value="2">2 Month</option>
                      <option value="3">3 Month</option>
                    </Select>
                  </div>
                </div>
                <div className="space-x-4 my-4 text-right">
                  <Button
                    onClick={() => {
                      setErrors({});
                      setEmpCatFormData({
                        empTypeId: 0,
                        empTypeName: '',
                        isProbationApply: false,
                        probationMonth: 0,
                        isActive: true,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch
                      });
                    }}
                    variant="outline"
                    colorScheme="purple"
                  >
                    Reset
                  </Button>
                  <Button isLoading={IsPostLoading} loadingText="Saving..." type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FetchedData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default EmployeeCategory;
