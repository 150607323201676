import React, {useRef, useMemo, useState, useCallback, useEffect} from 'react'
import { AgGridReact } from "ag-grid-react";
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Button, Center, Spinner, useToast, Menu, MenuButton, MenuList, MenuItemOption, MenuOptionGroup, MenuDivider } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { LuDownload,LuRefreshCw } from 'react-icons/lu';
import useValidation from '../Utils/useValidation';
import { FiFilter } from 'react-icons/fi';
import useFetch from '../Utils/useFetch';
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';

function AllAttendanceReport(){

    const Validation = useValidation();
    const { userData } = useAuth()

    const { FetchedData: LeaveQuotaList } = useFetch(`/api/Leave/GetLeaveQuotaList`,{
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    })
    const { FetchedData: AttendanceSettingData } = useFetch(`/api/Attendance/GetAttendenceSettingsByBranch`,{
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    })


    const calculateNewTime = (officeOutTime, graceTime) => {
      const [outHours, outMinutes, outSeconds] = officeOutTime?.split(':').map(Number) ;
      const [graceHours, graceMinutes, graceSeconds] = graceTime?.split(':').map(Number);
      const totalOfficeOutSeconds = outHours * 3600 + outMinutes * 60 + outSeconds;
      const totalGraceSeconds = graceHours * 3600 + graceMinutes * 60 + graceSeconds;
      const newTotalSeconds = totalOfficeOutSeconds - totalGraceSeconds;
      const newHours = Math.floor(newTotalSeconds / 3600);
      const newMinutes = Math.floor((newTotalSeconds % 3600) / 60);
      const newSeconds = newTotalSeconds % 60;
      return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
    };

    
    console.log("Early Leaving Data",calculateNewTime(AttendanceSettingData?.officeOutTime || "00:00:00", AttendanceSettingData?.graceTime || "00:00:00"));

    const EarlyLeavingTime = calculateNewTime(AttendanceSettingData?.officeOutTime || "00:00:00", AttendanceSettingData?.graceTime || "00:00:00");
    const toast = useToast();
    const id = "toast";

    const [IsLoading, setIsLoading] = useState(false);
    const [SyncLoading, setSyncLoading] = useState(false);
    const [ColVisbility, setColVisbility] = useState(['Status','Status Type','In Time', 'Out Time', 'Total Leaves', 'Total Absent'])
    const [SelectedMonth, setSelectedMonth] = useState(new Date());
    const [FinalRowData, setFinalRowData] = useState([]);
    const MonthNames = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

    const calculateHours = (timeString) => {
      const [hoursStr, minutesStr, secondsStr] = timeString.split(':');
      const totalHours = parseInt(hoursStr) + (parseInt(minutesStr) / 60) + (parseInt(secondsStr) / 3600);
      return totalHours
    };
  

    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
    const defaultColDef = useMemo(() => ({
      //  flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      resizable : true
    }));

    const [columnDefs, setColumnDefs] = useState([]);

     console.log("Column Defs",columnDefs);

      useEffect(() => {
        console.log("RowData",rowData);
        setFinalRowData(_.flatten(rowData));
      }, [rowData])

      const getAllDaysInMonth = (year, month) => {
        const date = new Date(year, month, 1);
        const dates = [];
    
        while (date.getMonth() === month) {
          dates.push({
            headerName: `${new Date(date).getDate()}-${MonthNames[new Date(date).getMonth()]}-${new Date(date).getFullYear()}`,
            // field: new Date(date).toLocaleDateString("en-CA"),
            // width:140
            children: [
              ColVisbility?.includes('Status') && {
                headerName:'Status',
                field: moment(date).format('YYYY-MM-DD'),
                width:100
              },
              ColVisbility?.includes('Status Type') && {
                headerName:'Status Type',
                field: moment(date).format('YYYY-MM-DD')+"T",
                width:140
              },
              ColVisbility?.includes('In Time') && {
                headerName:'In Time',
                field: new Date(date).getDate()+'InTime',
                width:120
              },
              ColVisbility?.includes('Out Time') && {
                headerName:'Out Time',
                field: new Date(date).getDate()+'OutTime',
                width:120
              },
              ColVisbility?.includes('OT Hours') && {
                headerName:'OT Hours',
                field: new Date(date).getDate()+'OTHours',
                width:120
              }
            ].filter(Boolean),
          });
          date.setDate(date.getDate() + 1);
        }
    
        return dates;
      };

      const HandleGridHeader = (year, month) => {

        let StartingHead = [
            {
              headerName: "Emp Code",
              field: "empCode",
              width: 140,
              pinned: true
            },
            {
              headerName: "Emp Name",
              field: "empName",
              pinned: true
            },
            ColVisbility?.includes('Division') && {
              headerName: "Division",
              field: "division",
              width: 160
            }
          ]?.filter(Boolean);

         let DatesHead = getAllDaysInMonth(year,month);

         let QuotaHeaders = LeaveQuotaList?.map((data)=>{
          return ColVisbility?.includes(data.leaveType) && { 
            headerName: data.leaveType,
            field: `total${data.leaveType}`,
            width:100
          }
         })?.filter(Boolean);

         let EndHeads = [
          ColVisbility?.includes('Total Leaves') && {
            headerName: "Total Leaves",
            field: "totalLeaves",
            width:140
          },
          ColVisbility?.includes('Total Absent') && {
            headerName: "Total Absents",
            field: "totalAbsents",
            width:140
          },
          ColVisbility?.includes('Total Half Days') && {
            headerName: "Total Half Days",
            field: "totalHalfDays",
            width:160
          },
          ColVisbility?.includes('Total Late Comings') && {
            headerName: "Total Late Comings",
            field: "totalLateComings",
            width:180
          },
          ColVisbility?.includes('Total OT Hours') && {
            headerName: "Total OT Hours",
            field: "totalOtHours",
            width:180
          },
          ColVisbility?.includes('Total Early Leavings') && {
            headerName: "Early Leavings",
            field: 'earlyLeaving',
            width: 140
          }
        ].filter(Boolean)

        setColumnDefs(_.union(StartingHead,DatesHead,QuotaHeaders,EndHeads));
      }

      const getAllEmpData = async () => {
        setIsLoading(true);
        setRowData([]);
        setFinalRowData([]);
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log( " All Employees",response);
          const res = response.data;
          let ISActiveEmp = res.filter((data)=>data.isActive === true)
          console.log("IS Active EMp",ISActiveEmp);
          HandleRowData(ISActiveEmp);
 
          HandleGridHeader(new Date(SelectedMonth).getFullYear(),new Date(SelectedMonth).getMonth());
        } catch (error) {
          console.error(error);
        }
      };


      const HandleRowData = (AllEmployeeData) =>{
        // console.log("Start date",moment(SelectedMonth).startOf("month").format("YYYY-MM-DD"),"End Date",moment(SelectedMonth).endOf("month").format("YYYY-MM-DD"));
        Promise.all(
            AllEmployeeData.map(
              async (data) => {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetEmpFinalAttendanceByDate?EmpGuid=${data.guId}&fromdate=${moment(SelectedMonth).startOf("month").format("YYYY-MM-DD")}&Todate=${moment(SelectedMonth).endOf("month").format("YYYY-MM-DD")}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                );
                const EmpAttData = response.data;
  
                console.log("Emp Att Data",response);
  
                let TempData = _.uniqBy(EmpAttData, "empGuid").map((attData)=>{
                  let EmpRowData = [];
  
                   EmpRowData.push({
                      empGuId : attData.empGuId,
                      empName : attData.empName,
                      empCode : attData.empCode
                    });
  
                  EmpAttData.map((AllAttData) => {
                      return EmpRowData.push({
                       [moment(AllAttData.attendanceDate).format('YYYY-MM-DD')]:AllAttData.attendanceStatus,
                       [moment(AllAttData.attendanceDate).format('YYYY-MM-DD')+"T"]:AllAttData.att_statusType,
                       [new Date(AllAttData.attendanceDate).getDate()+'InTime']:AllAttData.inTime,
                       [new Date(AllAttData.attendanceDate).getDate()+'OutTime']:AllAttData.outTime,
                       [new Date(AllAttData.attendanceDate).getDate()+'OTHours']:calculateHours(AllAttData.otHours),
                       totalLeaves: EmpAttData.filter(obj => obj.attendanceStatus === 'L')?.length,
                       totalAbsents: EmpAttData.filter(obj => obj.attendanceStatus === 'A')?.length,
                       totalHalfDays: EmpAttData.filter(obj => obj.attendanceStatus === 'HD')?.length,
                       totalLateComings: EmpAttData.filter(obj => obj.att_statusType === 'Late')?.length,
                       totalOtHours: EmpAttData.reduce((acc,curr)=> parseFloat(acc) + parseFloat(calculateHours(curr.otHours)),0),
                       earlyLeaving: EmpAttData.filter(obj => {
                        if (obj.outTime === "00:00:00") return false; 
                        const outTime = new Date("1970-01-01T" + obj.outTime); 
                        const cutoffTime = new Date("1970-01-01T" + EarlyLeavingTime);
                        return outTime < cutoffTime;
                      }).length
                    });
                  });

                  LeaveQuotaList.map((QuotaData)=>{
                    return EmpRowData.push({
                      ["total"+QuotaData?.leaveType]: EmpAttData.filter(obj => obj?.att_statusType === QuotaData?.leaveType)?.length
                    })
                  })

                  return Object.assign({},...EmpRowData)
                })

                return setRowData((prev)=>{
                  return [...prev,TempData]
                })
              }
            )
          );

          setIsLoading(false);
      }

console.log("Final variable row data",FinalRowData)

const exportData = () => {

  // let SelMonthName = MonthNames[new Date(SelectedDateMonth).getMonth()]
  const params = {
    fileName: `All Attendance Report of ${userData.activeBranchName}.csv`,
  };

  gridRef.current.api.exportDataAsCsv(params);
};


const AttendanceHelper = async () => {
   setSyncLoading(true);
   setFinalRowData([]);
  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/CloneAttendance?AttendanceDate=${new Date(SelectedMonth).getFullYear()}-${new Date(SelectedMonth).getMonth()+1}-01&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log("Run Process Attendance Reponse",response);

    if(response.data === 'True') {
      toast({
        id,
        title: response.data,
        description: `Attendance Processed for Month ${MonthNames[new Date(SelectedMonth).getMonth()]}`,
        position: "top",
        status: "success",
        duration: 5000,
        isClosable: true
      });
    }else{
      toast({
        id,
        title: response.data,
        position: "top",
        status: "info",
        duration: 5000,
        isClosable: true
      });
    }
  } catch (err) {
    console.error(err);
   } finally {
    setSyncLoading(false);
  }

  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/ProcessDailyAttendance?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Attendancedate=${moment(SelectedMonth).format('YYYY-MM-DD')}`
    );
    console.log("Process Attendance Response", response);
  } catch (error) {
    console.error(error);
  }
}


  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">All Attendance Report</h1>
        <div className='space-x-4'>
         <Button variant={'ghost'} isDisabled={new Date(SelectedMonth) <= new Date(Validation?.payrollYear,Validation?.payrollMonth,1)} isLoading={SyncLoading} loadingText='Syncing...' onClick={AttendanceHelper} leftIcon={<LuRefreshCw className="text-lg" />} colorScheme="purple">Sync Attendance</Button>
         <Button isDisabled={FinalRowData?.length === 0 ? true : false} onClick={()=>{exportData()}} leftIcon={<LuDownload />} colorScheme={'purple'}>Export</Button>
        </div>
      </div>

     {SyncLoading ?
       <Center>
         <Spinner thickness='4px' color={'purple.500 '} size='lg' />
        </Center> : <div className="bg-white rounded-2xl p-4 flex gap-4 items-end  mb-4">
          
          <div className="space-y-2">
            <label>Select Month/Year</label>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 outline-none"
              selected={SelectedMonth}
              onChange={(date) => {
                setSelectedMonth(date);
              }}
              showMonthYearPicker
              dateFormat="MM/yyyy"
              placeholderText="Select Month/Year"
              // minDate={new Date(Validation?.payrollYear,Validation?.payrollMonth,1)}
              maxDate={new Date()}
            />
          </div>

          <div>
           <Menu>
             <MenuButton border={'1px'} borderColor={'gray.400'} as={Button} rightIcon={<FiFilter />}>
               Column Filters
             </MenuButton>
             <MenuList className='grid grid-cols-2 gap-4'>
               <MenuOptionGroup value={ColVisbility} onChange={setColVisbility} title='Columns' type='checkbox'>
                 <MenuItemOption closeOnSelect={false} value='Status'>Status</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Status Type'>Status Type</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='In Time'>In Time</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Out Time'>Out Time</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='OT Hours'>OT Hours</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Division'>Division</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Total Leaves'>Total Leaves</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Total Absent'>Total Absent</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Total Half Days'>Total Half Days</MenuItemOption>
               </MenuOptionGroup>
               <MenuOptionGroup value={ColVisbility} onChange={setColVisbility} title='Columns' type='checkbox'>
                 <MenuItemOption closeOnSelect={false} value='Total Late Comings'>Total Late Comings</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Total Early Leavings'>Total Early Leavings</MenuItemOption>
                 <MenuItemOption closeOnSelect={false} value='Total OT Hours'>Total OT Hours</MenuItemOption>
                 {LeaveQuotaList?.map((data,i)=>{
                  return <MenuItemOption key={i} closeOnSelect={false} value={data.leaveType}>{data.leaveType} Quota</MenuItemOption>
                 })}
               </MenuOptionGroup>
             </MenuList>
           </Menu>
          </div>
          <Button isDisabled={ColVisbility?.length === 0} isLoading={IsLoading} loadingText='Searching...' onClick={()=>{getAllEmpData();}} leftIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />} colorScheme={"purple"}>Search</Button>
        </div> }


        {IsLoading ? <Center>
         <Spinner thickness='4px' color={'purple.500'} size='lg' />
        </Center> :
      <div className="ag-theme-alpine mb-4">
        {FinalRowData.length !== 0 ? 
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef}
            rowData={FinalRowData?.slice()?.sort((a, b) => a.empName.localeCompare(b.empName))}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            suppressExcelExport={true}
          /> : ''}
        </div> 
      }
    </div>
  )
}

export default AllAttendanceReport