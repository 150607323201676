import React, { useState, useRef, useMemo, useCallback, } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionPanel, AccordionItem, Input, Button, Switch } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import { useAuth } from "../Utils/AuthContext";
import useFetch from "../Utils/useFetch";
import usePost from "../Utils/usePost";
import { BankMasterSchema } from "../Utils/FormValidations";

function BankMaster() {

  const { userData } = useAuth();
  const { FetchedData, Refetch } = useFetch('/api/Master/GetBankMasterList',{CompanyId: userData.companyId,BranchId: userData.isActiveBranch});
  const { IsPostLoading, PostData } = usePost('/api/Master/SaveBankMaster');

  const [BankFormData,setBankFormData] = useState({
    bankId: 0,
    bankName: '',
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  });

  const [Errors, setErrors] = useState({});

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Bank Name",
      field: "bankName",
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleBankSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      cellRenderer: ({data}) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setBankFormData(data);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
            leftIcon={<Edit size="14px" />}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));


  const HandleBankSwitch = async (checked, BankData) => {

    let body = {
      bankId: BankData.bankId,
      createByName: BankData.createByName,
      srNo: BankData.srNo,
      bankName: BankData.bankName,
      isActive: checked,
      companyId: BankData.companyId,
      branchId: BankData.branchId,
    };
    const response = await PostData(body);
    if(response){
      Refetch();
    }

  };

  const SaveBankName = async (e) => {
    e.preventDefault();
    BankMasterSchema.validate(BankFormData,{abortEarly:false})
    .then(async() => {

    let body = {
      ...BankFormData,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    };
    console.log(body);

    const response = await PostData(body);

    if(response){
      Refetch();
      setErrors({});
      setBankFormData({
        bankId: 0,
        bankName: '',
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      });
    }

    }).catch((validationErrors) => {
      console.log("Errors",validationErrors);
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    });

  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Bank Master</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Bank
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveBankName}>
                <div className="space-y-2 w-1/3">
                  <label>Add Bank Name</label>
                  <Input 
                    isInvalid={Errors?.bankName}
                    value={BankFormData?.bankName || ""}
                    onChange={({ target }) => {
                      setBankFormData({
                       ...BankFormData,
                       bankName: target.value
                      });
                    }}
                    borderColor="gray"
                    placeholder="Enter Bank Name"
                  />
                  {Errors?.bankName && <span className="text-red-600 my-1 text-sm">{Errors?.bankName}</span>}
                </div>

                <div className="space-x-3 my-2 text-right">
                  <Button isLoading={IsPostLoading} loadingText="Saving..." type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FetchedData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default BankMaster;
