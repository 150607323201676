import { Button, InputGroup, InputRightAddon, Tooltip , Select,  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Input, useToast, Checkbox, } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Plus } from 'react-feather';
import _ from 'lodash';
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';
import useFormValidation from '../Utils/useFormValidation';

function NewRole() {

    const { userData } = useAuth();
    const { FormErrors, IsValidating, ValidateField } = useFormValidation();
    console.log("Form FormErrors",FormErrors,IsValidating);

    const toast = useToast();
    const id = "toast";

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [RoleName, setRoleName] = useState();
    const [RoleList, setRoleList] = useState([]);
    const [AddRoleName, setAddRoleName] = useState();
    const [MenuList, setMenuList] = useState([]);


    useEffect(() => {
      GetRoleList();
    }, [])
    

    const GetRoleList = async() => {
        try {
            const response = await authApi.get(
                `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetRoleMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )
              // console.log("Role List ",response)

              setRoleList(response.data);

        } catch (error) {
         console.error(error);   
        }
    }


    const HandleSelectedRole = async (Value) => {
        setRoleName(Value)

        try {
          const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${Value}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Menu List ",response);
            setMenuList(response.data);
        } catch (error) {
         console.error(error);   
        }
    }

const SaveRoleMaster = async (e) => {

    e.preventDefault();

    let body = {
        roleName: AddRoleName,
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
    }

    console.log("Save Role Body",body);

    await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/SaveRoleMaster`,body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        setRoleName('');
        GetRoleList();
        onClose();

      })
      .catch((error) => {
        console.error(error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Role not Created`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
}

// console.log("Role Name",RoleName);
const HandleSubMenuCheck = async(ModuleMasterID,checkedStatus) => {

    setMenuList(prev => {
        const newState =  prev.map((obj) => {
          if (obj.moduleMasterId === Number(ModuleMasterID)) {
            return {...obj,
                roleId:Number(RoleName),
                moduleId:Number(ModuleMasterID),
                isCreate: checkedStatus,
                isModify: checkedStatus,
                isView: checkedStatus,
                companyId:userData.companyId,
                branchId:userData.isActiveBranch
            };
          }
          return obj
        });
        return newState
      })

}

const HandleAllPermissions = async (CheckedStatus, type) => {
  setMenuList(prev => {
    return prev.map(obj => {
      if (obj.isDefault) return obj; // Exclude objects with isDefault: true

      const updatedObj = {
        ...obj,
        roleId: Number(RoleName),
        moduleId: Number(obj.moduleMasterId),
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      };

      if (type === "create" || type === "all") updatedObj.isCreate = CheckedStatus;
      if (type === "modify" || type === "all") updatedObj.isModify = CheckedStatus;
      if (type === "view" || type === "all") updatedObj.isView = CheckedStatus;

      return updatedObj;
    });
  });
};


const HandlePermission = async (ModuleMasterID,checkedStatus,ActionName) => {
    // console.log("Module Master ID",Number(ModuleMasterID));
    // console.log("Checked Status",checkedStatus);
    // console.log("Action Name",ActionName);

    setMenuList(prev => {
        const newState =  prev.map((obj) => {
          if (obj.moduleMasterId === Number(ModuleMasterID)) {
            return {...obj,
                roleId:Number(RoleName),
                moduleId:Number(ModuleMasterID),
                isCreate: ActionName === 'create'? checkedStatus : obj.isCreate,
                isModify: ActionName === 'modify'? checkedStatus : obj.isModify,
                isView: ActionName === 'view'? checkedStatus : obj.isView,
                companyId:userData.companyId,
                branchId:userData.isActiveBranch
            };
          }
          return obj
        });
        return newState
      })

}

// console.log("New Menu List",MenuList);


const SavePermisions = async () =>{ 

    await authApi.post(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/SaveRoleAndPermission`,MenuList
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        HandleSelectedRole(RoleName);
        //  window.location.reload(true);
      })
      .catch((error) => {
        console.error(error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Role not Created`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
    
}

console.log("Role Name",RoleName);

  return (
    <div>
        <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Role & Permissions</h1>
      </div>

      
      <div className='bg-white p-4 rounded-2xl drop-shadow-md mb-4'>
        <div className='space-y-2 w-1/3'>
            <label>Select Role</label>
            <InputGroup>
             <Select value={RoleName} onChange={({target})=>{HandleSelectedRole(target.value)}} borderColor={'gray.400'} placeholder='Select Role'>
                {RoleList.filter((data)=> data.isActive ===  true).map((data,i)=>{
                    return <option key={i} value={data.id}>{data.roleName}</option>
                })}
             </Select>
             <Tooltip bg='black' placement='top' hasArrow rounded={5} label='Add Role'>
              <InputRightAddon onClick={onOpen} px={0} children={<Button colorScheme={'purple'}><Plus size={'16px'} /></Button>}/>
             </Tooltip>
            </InputGroup>
        </div>

      </div>


      {RoleName?

      <div className='bg-white p-4 rounded-2xl drop-shadow-md '>
        
<div className="overflow-x-auto relative drop-shadow-md  rounded-2xl">
    <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-50 uppercase bg-gray-500 ">
            <tr>
                <th scope="col" className="py-3 px-6">
                    Main Menu Name
                </th>
                <th scope="col" className="py-3 px-6">
                <Checkbox 
                   isDisabled={RoleName === "2"}
                   isChecked={MenuList.filter(item => !item.isDefault).every(({isModify,isCreate,isView})=>isModify && isCreate && isView)} 
                   onChange={({target})=>{
                    HandleAllPermissions(target.checked, "all");                   
                    }}></Checkbox> Sub Menu Name
                </th>
                <th scope="col" className="py-3 px-6">
                   <Checkbox 
                      isDisabled={RoleName === "2"}
                    isChecked={MenuList.filter(item => !item.isDefault).every(item => item.isCreate)}
                    onChange={({target})=>{
                      HandleAllPermissions(target.checked, "create");
                    }}></Checkbox> Create
                </th>
                <th scope="col" className="py-3 px-6">
                <Checkbox 
                   isDisabled={RoleName === "2"}
                   isChecked={MenuList.filter(item => !item.isDefault).every(item => item.isModify)}
                   onChange={({target})=>{
                    HandleAllPermissions(target.checked, "modify");                   
                   }}></Checkbox> Modify
                </th>
                <th scope="col" className="py-3 px-6">
                <Checkbox 
                   isDisabled={RoleName === "2"}
                  isChecked={MenuList.filter(item => !item.isDefault).every(item => item.isView)}
                  onChange={({target})=>{
                    HandleAllPermissions(target.checked, "view");                   
                  }}></Checkbox> View
                </th>
            </tr>
        </thead>
        <tbody>

        {_.uniqBy(MenuList,'mainMenuName').map((Maindata)=>{
         return MenuList.filter((MainObj)=> MainObj.mainMenuName === Maindata.mainMenuName ).map((data,i)=>{
                   return <tr key={i} className="odd:bg-gray-200 even:bg-gray-300 text-gray-800 border-b last:border-b-0 border-gray-400  " >
                       <th className={`py-2 px-6 text-base`}><span className={`${Maindata.moduleMasterId === data.moduleMasterId ? 'block font-semibold':'hidden'}`}>{data.mainMenuName}</span></th>
                        <td className="py-2 px-6">
                         {data.isDefault ? data.subMenuName : <Checkbox isDisabled={RoleName === "2"} isChecked={data.isCreate && data.isModify && data.isView === true ? true : false} id={data.moduleMasterId} onChange={({target}) => {HandleSubMenuCheck(target.id,target.checked)}} borderColor={'gray.500'} >{data.subMenuName}</Checkbox>}
                       </td>
                       <td className="py-2 px-6">
                        {!data.isDefault && <Checkbox isDisabled={RoleName === "2"} id={data.moduleMasterId} name={'create'} isChecked={data.isCreate} onChange={({target})=>{HandlePermission(target.id,target.checked,target.name)}} borderColor={'gray.500'} />}
                       </td>
                       <td className="py-2 px-6">
                        {!data.isDefault && <Checkbox isDisabled={RoleName === "2"} id={data.moduleMasterId} name={'modify'} isChecked={data.isModify} onChange={({target})=>{HandlePermission(target.id,target.checked,target.name)}} borderColor={'gray.500'} />}
                       </td>
                       <td className="py-2 px-6">
                        {!data.isDefault && <Checkbox isDisabled={RoleName === "2"} id={data.moduleMasterId} name={'view'} isChecked={data.isView} onChange={({target})=>{HandlePermission(target.id,target.checked,target.name)}} borderColor={'gray.500'} />}
                       </td>
                     </tr>
                 })
        })}


        </tbody>
    </table>
</div>



        <div className='my-4 space-x-4 text-right'>
        <Button onClick={()=>{
            setRoleName('');
            setMenuList([]);
        }} variant={'outline'} colorScheme={'purple'}>Cancel</Button>

        {RoleName !== "2" &&<Button onClick={SavePermisions} colorScheme={'purple'}>Save</Button>}
        </div>
        
      </div>
       : ''}


      <Modal 
        isOpen={isOpen} 
        onClose={()=>{ 
          setAddRoleName(''); 
          onClose(); 
        }}
      >
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Add Role</ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={SaveRoleMaster}>
          <ModalBody>
            <div className='space-y-2 my-2'>
                <label>Role Name</label>
                <Input 
                  isInvalid={FormErrors.roleName} 
                  value={AddRoleName || ''} 
                  onChange={({target})=>{
                    ValidateField('Alphanumeric','roleName',target.value)
                    setAddRoleName(target.value)
                  }} 
                  borderColor={'gray.400'}  
                  placeholder='Role Name' />
                {FormErrors?.roleName && <span className='text-red-600'>{FormErrors.roleName}</span>}
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
           <Button isDisabled={Object.keys(FormErrors).length > 0} type='submit' variant='solid' mr={3} colorScheme={'purple'}>Save</Button>
           <Button colorScheme='purple' variant={'outline'}  onClick={onClose}>Close</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


    </div>
  )
}

export default NewRole