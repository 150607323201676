import { Button, Input,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,InputGroup,InputRightElement,
    ModalCloseButton,useToast,
    useDisclosure,PinInput, PinInputField,HStack,Flex } from '@chakra-ui/react'
import React,{useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { CheckCircle,Eye, EyeOff, XCircle } from "react-feather";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import numeral from 'numeral';

function Checkout() {

    const toast = useToast();
    const id = "toast";

    const navigate = useNavigate();

    const UrlParams = useParams();
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    const PlanID = UrlParams.planId;


    const [IsZero, setIsZero] = useState(false);
    const [NumberOfEmp, setNumberOfEmp] = useState(0);
    const [EnterpriseAmount, setEnterpriseAmount] = useState(0);

    const [containsUppercase, setContainsUppercase] = useState(false);
    const [containsLowercase, setContainsLowercase] = useState(false);
    const [containsSpecialChar, setContainsSpecialChar] = useState(false);
    const [hasMinLength, setHasMinLength] = useState(false);
    const [EmailOTP, setEmailOTP] = useState();
    
    const {
        nextStep: PlaceOrderNext,
        prevStep: PlaceOrderPrev,
        reset: PlaceOrderReset,
        activeStep: PlaceOrderActiveStep,
      } = useSteps({ initialStep: 0 });

    const { 
        isOpen : isVerifyOtpOpen, 
        onOpen : onVerifyOtpOpen, 
        onClose : onVerifyOtpClose
      } = useDisclosure();

    const [CompanyName, setCompanyName] = useState();
    const [RazorPayDetails, setRazorPayDetails] = useState([]);
    const [PlanDetails, setPlanDetails] = useState([]);
    const [RegisteredName, setRegisteredName] = useState();
    const [RegisteredContact, setRegisteredContact] = useState();
    const [EmailAddress, setEmailAddress] = useState();
    const [RegisterPassword, setRegisterPassword] = useState('');
    const [RegisteredConfirmPassword, setRegisteredConfirmPassword] = useState();
  
    const [ComparePassword, setComparePassword] = useState(true);
    const [OTPInput, setOTPInput] = useState();
    const [OTPError, setOTPError] = useState(false);

   useEffect(()=>{
    getPlanDetails();
   },[])

    console.log("Email OTP",EmailOTP);

 
   function handlePasswordChange(e) {
     const newPassword = e.target.value;
     setRegisterPassword(newPassword);
     setContainsUppercase(/[A-Z]/.test(newPassword));
     setContainsLowercase(/[a-z]/.test(newPassword));
     setContainsSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword));
     setHasMinLength(newPassword.length >= 8);
   }

    const getPlanDetails = async () =>{
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Account/Register?PlainId=${PlanID}`
            )
            console.log("Plan Response",response);
            const res = response.data;

            if(res){
              setPlanDetails(res)
              
              if(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9"){
                setNumberOfEmp(51);
                setEnterpriseAmount(numeral(51*Number(res.perEmployeePrice || 0)).format('0.00'))
              }

              if(res.price === 0){
                setIsZero(true);
              }else{
                setIsZero(false);
              }

            }else{
              alert('Wrong Subscription Id, Please Select Valid Plan')
              window.location.replace('http://hrmuster.com/Pricing');
            }
        } catch (err) {
            console.error(err);
        }
    }

   const HandleRegister = async (e) => {
     e.preventDefault();

      try {
        const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Account/GenerateOTPToEmailVerify?UserName=${EmailAddress}`
        )
        console.log("Generate Otp Response",response);
        const res = response.data;

        if(res === 'Email already exists.'){
          alert('Email Already Exist! Try to Login');                            
          navigate('/')
        }
        setEmailOTP(response.data);
        onVerifyOtpOpen();

      } catch (error) {
        console.error(error);
      }

   }


    console.log("Plan Highlights ",PlanDetails._PlanHighlightsModel);
    const HandleVerifyOTP =  async (e) =>{
        e.preventDefault();
        setOTPError(false)

        if (Number(EmailOTP || 0) === Number(OTPInput || 0)) {
    
          let body = {
           companyName: CompanyName,
           role : "Admin",
           name : RegisteredName,
           userName : EmailAddress,
           password : RegisterPassword,
           confirmPassword : RegisteredConfirmPassword,
           isOnline : 1,
           planId : PlanDetails.planRefId,
           amount : Number(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? EnterpriseAmount : PlanDetails.price),
           mobile : RegisteredContact
        }
        
        console.log("Register Body",body);

        await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/Razorpay/Payment`,body
          )
          .then((response) => {
            console.log(response);
            const res = response.data;
            if (!toast.isActive(id)) {
              toast({
                id,
                title:"Account Created",
                position: "top",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }
            setRazorPayDetails(res);
            setOTPError(false)
            onVerifyOtpClose();

            if(IsZero){
              navigate('/');
            }else{
              PlaceOrderNext();
            }
            
          })
          .catch((error) => {
            console.error(error);
      
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: `Account not created!`,
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true
              });
            }
          });

        }else{
            setOTPError(true);
            if (!toast.isActive(id)) {
                toast({
                  id,
                  title: "Incorrect OTP!",
                  description: `Please enter a valid OTP`,
                  position: "top",
                  status: "error",
                  duration: 2000,
                  isClosable: true
                });
              }
        }
    }

    const displatRazorpay = async  () => {
        // const res = await loadScript(`https://checkout.razorpay.com/v1/checkout.js`)
        // if(!res){
        //     alert('You are offline... Failed to load Razorpay SDK')
        //     return
        // }

        const options = {
            key: RazorPayDetails.key,
            currency: RazorPayDetails.currency,
            amount: RazorPayDetails.amountInSubUnits,
            name: RazorPayDetails.name,
            description: RazorPayDetails.description,
            image:'/HRMusterLogo.png',
            order_id : RazorPayDetails.orderId,
            profileName : RazorPayDetails.profileName,
            profileContact : RazorPayDetails.profileContact,
            profileEmail : RazorPayDetails.profileEmail,
            handler : async (response) => {
              console.log("Payment Response ",response);
              console.log("Payment ID: ",response.razorpay_payment_id);
              console.log("Order ID: ",response.razorpay_order_id);
              console.log("Signature: ",response.razorpay_signature);

                alert("Payment ID: " + response.razorpay_payment_id);
                alert("Order Id: " + response.razorpay_order_id);
                alert("Signature: " + response.razorpay_signature)
                alert('Payment Successfull')

                if (response.razorpay_payment_id) {
                    let body = {
                          profileEmail : RazorPayDetails.profileEmail,
                          razorpay_payment_id : response.razorpay_payment_id,
                          razorpay_order_id : response.razorpay_order_id,
                          razorpay_signature : response.razorpay_signature,
                          generated_order_id: RazorPayDetails.orderId,
                          errorCode : '',
                          errorDescription : '',
                          errorSource : '',
                          errorStep : '',
                          errorReason : '',
                          errorMetadataOrder_id : '',
                          errorMetadataPayment_id : ''
                        }

                        console.log("Payment Response Body",body);

                        await axios.post(
                            `${process.env.REACT_APP_ACQ_URL}/api/Razorpay/SavePaymentResponse`,body
                          )
                          .then((response) => {
                            console.log(response);
                            const res = response.data;
                            if (!toast.isActive(id)) {
                              toast({
                                id,
                                title:"Payment Successfull",
                                position: "top",
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                              });
                            }
                            navigate('/');
                            
                          })
                          .catch((error) => {
                            console.error(error);
                      
                            if (!toast.isActive(id)) {
                              toast({
                                id,
                                title: "ERROR",
                                description: `Payment Failed`,
                                position: "top",
                                status: "error",
                                duration: 2000,
                                isClosable: true,
                              });
                            }
                          });

                }
            },
        };

        console.log("Options",options);
        const paymentObject = new window.Razorpay(options)

        paymentObject.on('payment.failed', async (response) => {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);

            let body = {
              profileEmail : RazorPayDetails.profileEmail,
              razorpay_payment_id : response.razorpay_payment_id,
              razorpay_order_id : response.razorpay_order_id,
              razorpay_signature : response.razorpay_signature,
              generated_order_id: RazorPayDetails.orderId,
              errorCode : response.error.code,
              errorDescription : response.error.description,
              errorSource : response.error.source,
              errorStep : response.error.step,
              errorReason : response.error.reason,
              errorMetadataOrder_id : response.error.metadata.order_id,
              errorMetadataPayment_id : response.error.metadata.payment_id
            }

            console.log("Payment Response Body",body);

            await axios.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Razorpay/SavePaymentResponse`,body
              )
              .then((response) => {
                console.log(response);
                const res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title:"Payment Successfull",
                    position: "top",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }

              })
              .catch((error) => {
                console.error(error);
          
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "ERROR",
                    description: `Payment Failed`,
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });
        })
        paymentObject.open()
    }


  return (
    <div>

        <div className="grid md:grid-cols-2 sm:grid-cols-1">

<div className=" bg-slate-800 hidden  md:my-38 md:flex  items-center place-content-center ">
  <img className=" h-auto p-10 lg:p-28 mx-auto" src="/images/Register.png" alt=""  />

</div>
<div className="basis-1/2   h-auto md:h-screen flex items-center justify-start py-12 px-4 sm:px-6 lg:px-8 sm:basis-1">
  <div className="max-w-2xl w-full sm:max-w-md">

              {!IsZero ?
              <Steps
                checkIcon={CheckCircle}
                colorScheme="purple"
                size={'sm'}
                activeStep={PlaceOrderActiveStep}
              >
                <Step label={"Create Account"}>
                <div className="w-full mt-4">
                  <div className="h-full p-3 rounded-xl border-2 border-blue-400 flex flex-col relative overflow-hidden">
                    <h2 className="text-sm tracking-widest title-font mb-1 font-medium">{PlanDetails.planName} PLAN</h2>
                    <h1 className="text-3xl text-gray-900 leading-none flex items-center border-gray-200">
                      <span className='font-semibold'>$ {PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? EnterpriseAmount : PlanDetails.price}</span>
                      <span className="text-base ml-1 mt-auto font-normal text-gray-500">{PlanDetails.planType === 'Monthly' ? '/month' : PlanDetails.planType === "Annual" ? '/year' : ''}</span>
                    </h1>
                  </div>
                </div>

    <form className='space-y-4 my-2' onSubmit={HandleRegister}>

    {PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? 
      <div className='space-y-2'>
        <label>Number of Employee<span className='text-red-500 font-semibold'>*</span></label>
        <Input value={NumberOfEmp || ''} onChange={({target})=>{
          let EnterAmount = target.value;
           setNumberOfEmp(EnterAmount);
           setEnterpriseAmount(numeral(Number(EnterAmount)*Number(PlanDetails.perEmployeePrice || 0)).format('0.00'))
          }}  borderColor={'gray.400'} type='number' placeholder='Number of Employees' />
      </div> : ''}
      {(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9") && Number(NumberOfEmp) <=50 ? <span className="text-red-600 text-xs">Number of Employees Must be Greater than 50</span> : ''}


       <div className='space-y-2'>
            <label>Company Name<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={CompanyName || ''} onChange={({target})=>{setCompanyName(target.value)}} type={'text'} borderColor={'gray.400'} placeholder='Company Name' />
        </div>

        <div className='space-y-2'>
            <label>Name<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={RegisteredName || ''} onChange={({target})=>{setRegisteredName(target.value)}} type={'text'} borderColor={'gray.400'} placeholder='Full Name' />
        </div>

        <div className='space-y-2'>
            <label>Contact<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={RegisteredContact || ''} onChange={({target})=>{setRegisteredContact(target.value)}} type={'number'} borderColor={'gray.400'} placeholder='Contact Number' />
        </div>

        <div className='space-y-2'>
            <label>E-mail<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={EmailAddress || ''} onChange={({target})=>{setEmailAddress(target.value)}} type={'email'} borderColor={'gray.400'} placeholder='E-mail Address' />
        </div>

        <div className="space-y-2">
          <label>Password<span className='text-red-500 font-semibold'>*</span></label>
          <InputGroup size="md">
            <Input
              isRequired
              value={RegisterPassword || ''}
              onChange={(e)=>{handlePasswordChange(e);}}
              borderColor='gray'
              type={show ? "text" : "password"}
              placeholder="Enter Password"
            />
            <InputRightElement width="2.5rem">
              <Button h="1.75rem" size="xs" onClick={handleClick}>
                {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>
  
                    <div className="space-y-2">
                      <label>Confirm Password<span className='text-red-500 font-semibold'>*</span></label>
                      <InputGroup size="md">
                        <Input
                          isRequired
                          value={RegisteredConfirmPassword || ''}
                          onChange={({target})=>{
                            setRegisteredConfirmPassword(target.value);
                            if(target.value !== RegisterPassword){
                              setComparePassword(false);
                            }else{
                              setComparePassword(true);
                            }
                          }}
                          borderColor='gray'
                          type={show ? "text" : "password"}
                          placeholder="Confirm Password"
                        />
                        <InputRightElement width="2.5rem">
                          <Button h="1.75rem" size="xs" onClick={handleClick}>
                            {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {!ComparePassword ? <span className="text-red-600 text-sm animate-ping ">Password doesn't match!!</span> : ''}
                      
                    </div>

                    {RegisterPassword &&

                    <div className='bg-slate-100 rounded-xl p-2'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='space-y-2'>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${containsUppercase ? 'text-green-600' : 'text-red-600'}`}>{containsUppercase ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain upper case</h6>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${hasMinLength ? 'text-green-600' : 'text-red-600'}`}>{hasMinLength ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain 8 characters</h6>
                            </div>
                            <div className='space-y-2'>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${containsLowercase ? 'text-green-600' : 'text-red-600'}`}>{containsLowercase ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain lower case</h6>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${containsSpecialChar ? 'text-green-600' : 'text-red-600'}`}>{containsSpecialChar ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain special characters</h6>
                            </div>
                        </div>
                    </div> }

        <div>
            <Button isDisabled={!(ComparePassword && containsUppercase && containsLowercase && hasMinLength && containsSpecialChar && ((PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9") && NumberOfEmp <=50 ? false : true))} className='mt-3' type='submit' width={'full'} colorScheme={'purple'}>Register</Button>
        </div>

    </form>

                </Step>
                <Step label={"Payment"}>
                    <div className='space-y-4 my-4'>
                    <div className="grid grid-cols-2 mt-5">
                            <div className="space-y-3">
                              <div className="space-y-1">
                                <p className="text-sm text-gray-500">
                                 Comapany Name
                                </p>
                                <h6 className="font-medium">
                                    {CompanyName}
                                </h6>
                              </div>

                              <div className="space-y-1">
                                <p className="text-sm text-gray-500">
                                  Contact
                                </p>
                                <h6 className="font-medium">
                                    {RegisteredContact}
                                </h6>
                              </div>

                            </div>

                            <div className="space-y-3">

                            <div className="space-y-1">
                                <p className="text-sm text-gray-500">
                                  Name
                                </p>
                                <h6 className="font-medium">
                                    {RegisteredName}
                                 </h6>
                              </div>

                              <div className="space-y-1">
                                <p className="text-sm text-gray-500">
                                 Email Address
                                </p>
                                <h6 className="font-medium">
                                    {EmailAddress}
                                 </h6>
                              </div>

                            </div>
                          </div>
                          <div className="border-b flex items-center justify-between border-slate-400 pb-1 mb-1">
        <h1 className="text-lg font-medium text-gray-800">
            Your Plan
          </h1>
        </div>

     <div className="w-full">
        <div className="h-full p-4 rounded-xl border-2 border-blue-400 flex flex-col relative overflow-hidden">
        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">{PlanDetails.planName} PLAN</h2>
          <h1 className="text-3xl text-gray-900 leading-none flex items-center border-gray-200">
            <span className='font-semibold'>$ {PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? EnterpriseAmount : PlanDetails.price}</span>
            <span className="text-lg ml-1 mt-auto font-normal text-gray-500">{PlanDetails.planType === 'Monthly' ? '/month' : PlanDetails.planType === "Annual" ? '/year' : ''}</span>
          </h1>

          <hr className='my-2' />

           {PlanDetails._PlanHighlightsModel?.map((data,i)=>{
             return <p key={i} className="flex items-center text-gray-600 mb-2">
             <span className="w-4 h-4 mr-2 inline-flex items-center justify-center text-blue-400 bg-white rounded-full flex-shrink-0">
                   <FontAwesomeIcon icon={faCircleCheck}/>
               </span>{data.highlight}
             </p>
           })}

        </div>
      </div>

                    <div>
                        <Button  onClick={()=>{displatRazorpay();}} width={'full'} colorScheme={'purple'}>Pay Now</Button>
                    </div>
                    </div>

                </Step> 

              </Steps>
              :
              <>
                            <div>
                <div>
                  <h1 className="text-2xl font-medium text-gray-800">
                    Create Account
                  </h1>
                </div>
              <div className="w-full mt-4">
        <div className="h-full p-3 rounded-xl border-2 border-blue-400 flex flex-col relative overflow-hidden">
          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">{PlanDetails.planName} PLAN</h2>
          <h1 className="text-3xl text-gray-900 leading-none flex items-center border-gray-200">
            <span className='font-semibold'>$ {PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? EnterpriseAmount : PlanDetails.price}</span>
            <span className="text-base ml-1 mt-auto font-normal text-gray-500">{PlanDetails.planType === 'Monthly' ? '/month' : PlanDetails.planType === "Annual" ? '/year' : ''}</span>
          </h1>
        </div>
      </div>

    <form className='space-y-4 my-2' onSubmit={HandleRegister}>

    {PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9" ? 
      <div className='space-y-2'>
        <label>Number of Employee<span className='text-red-500 font-semibold'>*</span></label>
        <Input value={NumberOfEmp || ''} onChange={({target})=>{
          let EnterAmount = target.value;
           setNumberOfEmp(EnterAmount);
           setEnterpriseAmount(numeral(EnterAmount*Number(PlanDetails.perEmployeePrice || 0)).format('0.00'))
          }}  borderColor={'gray.400'} placeholder='Number of Employees' />
      </div> : ''}
      {(PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9") && NumberOfEmp <=50 ? <span className="text-red-600 text-xs">Number of Employees Must be Greater than 50</span> : ''}


    <div className='space-y-2'>
            <label>Company Name<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={CompanyName || ''} onChange={({target})=>{setCompanyName(target.value)}} type={'text'} borderColor={'gray.400'} placeholder='Company Name' />
        </div>

        <div className='space-y-2'>
            <label>Name<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={RegisteredName || ''} onChange={({target})=>{setRegisteredName(target.value)}} type={'text'} borderColor={'gray.400'} placeholder='Full Name' />
        </div>

        <div className='space-y-2'>
            <label>Contact<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={RegisteredContact || ''} onChange={({target})=>{setRegisteredContact(target.value)}} type={'number'} borderColor={'gray.400'} placeholder='Contact Number' />
        </div>

        <div className='space-y-2'>
            <label>E-mail<span className='text-red-500 font-semibold'>*</span></label>
            <Input isRequired value={EmailAddress || ''} onChange={({target})=>{setEmailAddress(target.value)}} type={'email'} borderColor={'gray.400'} placeholder='E-mail Address' />
        </div>

        <div className="space-y-2">
                      <label>Password<span className='text-red-500 font-semibold'>*</span></label>
                      <InputGroup size="md">
                        <Input
                          isRequired
                          borderColor={'gray.400'}
                          value={RegisterPassword || ''}
                          onChange={(e)=>{handlePasswordChange(e);}}                      
                          type={show ? "text" : "password"}
                          placeholder="Enter Password"
                        />
                        <InputRightElement width="2.5rem">
                          <Button h="1.75rem" size="xs" onClick={handleClick}>
                            {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </div>
  
                    <div className="space-y-2">
                      <label>Confirm Password<span className='text-red-500 font-semibold'>*</span></label>
                      <InputGroup size="md">
                        <Input
                          isRequired
                          value={RegisteredConfirmPassword || ''}
                          onChange={({target})=>{
                            setRegisteredConfirmPassword(target.value);
                            if(target.value !== RegisterPassword){
                              setComparePassword(false);
                            }else{
                              setComparePassword(true);
                            }
                          }}
                          borderColor='gray.400'
                          type={show ? "text" : "password"}
                          placeholder="Confirm Password"
                        />
                        <InputRightElement width="2.5rem">
                          <Button h="1.75rem" size="xs" onClick={handleClick}>
                            {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {!ComparePassword ? <span className="text-red-600 text-sm animate-ping ">Password doesn't match!!</span> : ''}
                      
                    </div>

                    {RegisterPassword &&

                    <div className='bg-slate-100 rounded-xl p-2'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='space-y-2'>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${containsUppercase ? 'text-green-600' : 'text-red-600'}`}>{containsUppercase ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain upper case</h6>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${hasMinLength ? 'text-green-600' : 'text-red-600'}`}>{hasMinLength ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain 8 characters</h6>
                            </div>
                            <div className='space-y-2'>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${containsLowercase ? 'text-green-600' : 'text-red-600'}`}>{containsLowercase ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain lower case</h6>
                                <h6 className={`text-[0.7rem] font-medium flex gap-2 ${containsSpecialChar ? 'text-green-600' : 'text-red-600'}`}>{containsSpecialChar ?<CheckCircle size={'14px'}/> : <XCircle size={'14px'}/> }Password contain special characters</h6>
                            </div>
                        </div>
                    </div> }

        <div>
            <Button isDisabled={!(ComparePassword && containsUppercase && containsLowercase && hasMinLength && containsSpecialChar && ((PlanID === "HRM-2023-5" || PlanID === "HRM-2023-9") && NumberOfEmp <=50 ? false : true))} className='mt-3' type='submit' width={'full'} colorScheme={'purple'}>Register</Button>
        </div>

    </form>
              </div> 
              </>
              }


  </div>
</div>
</div> 




<Modal closeOnOverlayClick={false} isOpen={isVerifyOtpOpen} onClose={onVerifyOtpClose} isCentered>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Verify OTP</ModalHeader>
    <ModalCloseButton />
    <hr />
    <form onSubmit={HandleVerifyOTP}>

    <ModalBody className='space-y-4'>
        <div className="space-y-2 text-center">
        <HStack justifyContent={'center'}>
             <PinInput otp value={OTPInput} onChange={(value)=>{
                setOTPError(false)
                setOTPInput(value)
                }}>
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
               <PinInputField borderColor={'gray.400'} />
             </PinInput>
           </HStack>
           {OTPError && <span className="text-red-600 text-sm animate-ping ">Incorrect OTP!!</span>}
         </div>



    </ModalBody>
    <hr />
    <ModalFooter>
     <Button type="submit" colorScheme={'purple'}>Verify</Button>
    </ModalFooter>

    </form>
  </ModalContent>
</Modal>

    </div>
  )
}

export default Checkout