import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Select, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Input} from "@chakra-ui/react";
import moment from "moment";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function EmpLeaveSettlements() {

  const { userData } = useAuth();
    const toast = useToast();
    const id = "toast";
    const UrlParams = useParams();
    const EMPGUID = UrlParams.EmpGuid

    const { 
      isOpen: isSettleOpen, 
      onOpen: onSettleOpen, 
      onClose: onSettleClose
    } = useDisclosure()
 
    const gridRef = useRef();

    const [EmpInfo,setEmpInfo] = useState();
    const [SelectedRecord, setSelectedRecord] = useState([]);
    const [EmpLeaveBalance,setEmpLeaveBalance] = useState();
    const [SingleSettleBy, setSingleSettleBy] = useState([]);
    const [BulkSettleBy, setBulkSettleBy] = useState();
    const [LeaveQuotaList, setLeaveQuotaList] = useState([]);
    const [SelectedDataList, setSelectedDataList] = useState([]);

    const [BulkLeaveType, setBulkLeaveType] = useState();
    const [NoOfDays, setNoOfDays] = useState();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Attendance Date",
        field: "aDate",
        cellRenderer: (params) => <span>{moment(params.data.aDate).format('DD/MM/YYYY')}</span>,
      },
      // {
      //   headerName: "Attendance Status",
      //   field: "isPresent",
      //   cellRenderer: (params) => params.data.isPresent === true ? 
      //   <span className="bg-green-500 text-white px-1 py-0.5 rounded-md text-sm font-medium">
      //     Present
      //   </span> : 
      //   <span className="bg-red-500 text-sm px-1 py-0.5 rounded-md font-medium text-white">
      //     Absent
      //   </span>
      // },
      // {
      //   headerName: "LOP Status",
      //   field: "isLop",
      //   cellRenderer: (params) => params.data.isLop === true ? 
      //   <span className="bg-green-500 text-white px-1 py-0.5 rounded-md text-sm font-medium">
      //     True
      //   </span> : 
      //   <span className="bg-red-500 text-sm px-1 py-0.5 rounded-md font-medium text-white">
      //     False
      //   </span>
      // },
      {
        headerName: "Leave Type",
        field: "leaveTypeName"
      },
      {
        headerName: "Settlement Status",
        field: "settlementStatus",
        cellRenderer:(params) => params.data.isLopSettled === true ?
        <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Settled
        </span> : <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Pending
        </span>
      },
      {
        headerName: "Action",
        cellRenderer: (params) => params.data.isLopSettled === false ? <Button onClick={()=>{
          setSelectedRecord(params.data);
          onSettleOpen();
        }} size={'xs'} colorScheme="blue">Settle</Button> : ''
      }
    ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));
    
  
    useEffect(()=>{
      GetEmpSettlements();
      GetEmpGeneralInfo();
      GetEmpLeaveBalance();
      GetLeaveQuotaList();
    },[])

    const GetEmpGeneralInfo = async () => {
      try{
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeGeneralInfoByGuid?EmpGuid=${EMPGUID}`
        )
        console.log("Emp General Info",response);
        setEmpInfo(response.data);
      }catch (err) {
        console.error(err);
      }
    }

    const GetEmpLeaveBalance = async () => {
      try{
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDashBoard/GetLeaveBalanceByEmpGuid?Empguid=${EMPGUID}&year=${new Date().getFullYear()}&month=${new Date().getMonth()+1}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Emp Leave Balance",response);
        setEmpLeaveBalance(response.data);
      }catch (err) {
        console.error(err);
      }
    }

    const GetLeaveQuotaList = async () => {
      try {
        const response = await authApi.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("Leave Quota List", response);
        setLeaveQuotaList(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const GetEmpSettlements = async () => {
        try {
          let body = {
              empGuid : EMPGUID,
              isLopSettled : false,
              companyId : userData.companyId,
              branchId : userData.isActiveBranch,
            }
           console.log("Pending Body",body);
            const response = await authApi.post(
              `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLOPAdjustmentListbyEmp`,body
            )
            console.log("Emp Settlements",response);
            const res = response.data;
            setRowData(res.filter((data)=>data.leaveTypeId !== 0))
        } catch (err) {
            console.error(err);
        }
    }


    const SaveSingleSettlement = async (e) => {
      e.preventDefault();

      let body={
           empGuid : SelectedRecord.empGuid,
           leaveQuotaId : SelectedRecord.leaveTypeId,
           settled_LeaveQuotaId : SingleSettleBy.leaveQuotaId,
           leaveDays : 1,
           settledDate : moment().format('YYYY-MM-DD'),
           companyId : userData.companyId,
           branchId : userData.isActiveBranch,
           remark : "single",
           startDate : moment().startOf('year').format('YYYY-MM-DD'),
           endDate : moment().endOf('year').format('YYYY-MM-DD'),
           id : SelectedRecord.id,
           isLopSettled : true,
           leaveType : SelectedRecord.leaveTypeId,
           aDate : SelectedRecord.aDate
        }

        console.log("Single Settlement Body",body);

        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Leave/SaveLOPSettlement`,body
        ).then((response)=>{
          console.log(response);

          if(!toast.isActive(id)){
            toast({
              id,
              title:response.data,
              position: 'top',
              status:'info',
              duration: 2000,
              isClosable:true
            })
          }

          onSettleClose();
          setSingleSettleBy([]);
          GetEmpSettlements();
          GetEmpLeaveBalance();

        }).catch((err)=>{
          console.error(err);

          if(!toast.isActive(id)){
            toast({
              id,
              title: 'Server Error',
              position: 'top',
              status:'error',
              duration: 2000,
              isClosable:true
            })
          }

        })
    }


    const SaveBulkSettlement = async () => {

      // let body=SelectedDataList.map((data)=>{
      //   return {
      //        empGuid : data.empGuid,
      //        leaveQuotaId : data.leaveTypeId,
      //        settled_LeaveQuotaId : BulkSettleBy.leaveQuotaId,
      //        leaveDays : SelectedDataList.length,
      //        settledDate : data.aDate,
      //        companyId : userData.companyId,
      //        branchId : userData.isActiveBranch,
      //        remark : "multi",
      //        startDate : moment().startOf('year').format('YYYY-MM-DD'),
      //        endDate : moment().endOf('year').format('YYYY-MM-DD'),
      //        id : data.id,
      //        isLopSettled : true,
      //        leaveType : data.leaveTypeId,
      //        aDate : data.aDate
      //     }
      // })


        let body = {
          empGuid : EMPGUID,
          leaveQuotaId : 0,
          settled_LeaveQuotaId : BulkSettleBy.leaveQuotaId || 0,
          leaveDays : SelectedDataList.length,
          settledDate : moment().format('YYYY-MM-DD'),
          companyId : userData.companyId,
          branchId : userData.isActiveBranch,
          remark : "multi",
          startDate : moment().startOf('year').format('YYYY-MM-DD'),
          endDate : moment().endOf('year').format('YYYY-MM-DD'),
          id : 0,
          isLopSettled : true,
          leaveType : 0,
          aDate : moment().format('YYYY-MM-DD')
        }

        console.log("Bulk Settlement Body",body);

        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Leave/SaveLOPSettlementBatchProcess`,body
        ).then((response)=>{
          console.log(response);

          if(!toast.isActive(id)){
            toast({
              id,
              title:response.data,
              position: 'top',
              status:'info',
              duration: 2000,
              isClosable:true
            })
          }

          setBulkSettleBy([]);
          setSelectedDataList([]);
          GetEmpSettlements();
          GetEmpLeaveBalance();

        }).catch((err)=>{
          console.error(err);

          if(!toast.isActive(id)){
            toast({
              id,
              title: 'Server Error',
              position: 'top',
              status:'error',
              duration: 2000,
              isClosable:true
            })
          }

        })
    }



  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Settlements</h1>
      </div>

      <div className="flex gap-4">
      <div className="bg-white rounded-2xl p-4 flex gap-8 mb-4">
        <div className="space-y-1">
          <p className="text-sm text-gray-500">Employee Name</p>
          <h6 className="font-medium">{EmpInfo?.fullName}</h6>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">Employee Code</p>
          <h6 className="font-medium">{EmpInfo?.empCode}</h6>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">Designation</p>
          <h6 className="font-medium">{EmpInfo?.designationName}</h6>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">Department</p>
          <h6 className="font-medium">{EmpInfo?.departmentName}</h6>
        </div>
      </div>

      <div className="bg-white rounded-2xl px-4 py-2 mb-4 space-y-1">
      <h6 className=" font-semibold">Leave Balance</h6>
      <div className="flex flex-wrap gap-4">
      {EmpLeaveBalance?.map((data, i) => {
        return (
          <div
            key={i}
            className="bg-blue-200 rounded-xl drop-shadow  px-2 py-0.5  hover:-translate-y-2 ease-in-out duration-200"
          >
            <h6 className="font-semibold ">
              {data.leaveType}:{" "}
              <span className="font-semibold text-lg">
                {data.remainingQuota}
                <span className="text-xs text-gray-500 font-normal">
                  / {data.openingQuota}
                </span>
              </span>
            </h6>
          </div>
        );
      })}
      </div>
      </div>

      </div>

      <div className="ag-theme-alpine mb-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          rowSelection="multiple"
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>


      {/* <div className="flex">



        <div className="flex gap-4 bg-white p-4 rounded-xl">
            <ReactSelect
              required
              options={LeaveQuotaList}
              value={BulkSettleBy}
              onChange={(value) => {
                setBulkSettleBy(value);
              }}
              getOptionLabel={(option) => option.leaveDescription}
              getOptionValue={(option) => option.leaveQuotaId}
              key={(option) => option.leaveQuotaId}
              placeholder="Select Settlement By"
            />
            <Button isDisabled={SelectedDataList.length === 0 ? true : false} onClick={()=>{SaveBulkSettlement();}} colorScheme="purple" >Settle</Button>
          </div>


      </div> */}

<div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Bulk Action</h1>
      </div>


      <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 ">
                              <thead className="text-xs text-white uppercase bg-slate-700 ">
                                <tr>
                                <th scope="col" className="py-3 px-6">
                                    Leave Type
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Total LOP Adjustments
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    No. of Days
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Settle By
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {LeaveQuotaList.map((data)=>{
                                  return <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                                  <th
                                    scope="row"
                                    className="py-2 px-6 font-medium text-gray-900 whitespace-nowrap "
                                  >
                                    <Input borderColor={'gray.400'} bg  placeholder="Enter No. of Days" />
                                  </th>
                                  <td className="py-2 px-6">
                                    {rowData.filter(LopData => LopData.leaveTypeId === data.leaveQuotaId).length}
                                  </td>
                                  <td className="py-2 px-6">
                                    {data.leaveType}
                                  </td>
                                  <td className="py-2 px-6">
                                    <Select borderColor={'gray.400'} bg placeholder="Select Settle By">
                                      {LeaveQuotaList.map((QuotaData)=>{
                                        return <option value={QuotaData.leaveQuotaId} >{QuotaData.leaveDescription}</option>
                                      })}
                                    </Select>
                                  </td>
                                  <td className="py-2 px-6">
                                    <Button onClick={async () => {
                                              let body = {
                                                empGuid : EMPGUID,
                                                leaveQuotaId : 0,
                                                settled_LeaveQuotaId : BulkSettleBy.leaveQuotaId || 0,
                                                leaveDays : SelectedDataList.length,
                                                settledDate : moment().format('YYYY-MM-DD'),
                                                companyId : userData.companyId,
                                                branchId : userData.isActiveBranch,
                                                remark : "multi",
                                                startDate : moment().startOf('year').format('YYYY-MM-DD'),
                                                endDate : moment().endOf('year').format('YYYY-MM-DD'),
                                                id : 0,
                                                isLopSettled : true,
                                                leaveType : 0,
                                                aDate : moment().format('YYYY-MM-DD')
                                              }
                                      
                                              console.log("Bulk Settlement Body",body);
                                      
                                              await authApi.post(
                                                `${process.env.REACT_APP_ACQ_URL}/api/Leave/SaveLOPSettlementBatchProcess`,body
                                              ).then((response)=>{
                                                console.log(response);
                                      
                                                if(!toast.isActive(id)){
                                                  toast({
                                                    id,
                                                    title:response.data,
                                                    position: 'top',
                                                    status:'info',
                                                    duration: 2000,
                                                    isClosable:true
                                                  })
                                                }
                                      
                                                setBulkSettleBy([]);
                                                setSelectedDataList([]);
                                                GetEmpSettlements();
                                                GetEmpLeaveBalance();
                                      
                                              }).catch((err)=>{
                                                console.error(err);
                                      
                                                if(!toast.isActive(id)){
                                                  toast({
                                                    id,
                                                    title: 'Server Error',
                                                    position: 'top',
                                                    status:'error',
                                                    duration: 2000,
                                                    isClosable:true
                                                  })
                                                }
                                      
                                              })
                                      
                                    }} size={'sm'} colorScheme="blue" >Settle</Button>
                                   </td>
                                </tr>

                                })} */}

                                <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                                  <td
                                    className="py-2 px-6"
                                  >
                                    <Select value={BulkLeaveType} onChange={({target})=>{
                                       setNoOfDays(0);
                                       setBulkLeaveType(target.value);
                                      }} borderColor={'gray.400'} bg placeholder="Select Leave Type">
                                      {LeaveQuotaList.map((QuotaData,i)=>{
                                        return <option key={i} value={QuotaData.leaveQuotaId} >{QuotaData.leaveDescription}</option>
                                      })}
                                    </Select>
                                  </td>
                                  <td className="py-2 px-6">
                                  {rowData.filter(LopData => LopData.leaveTypeId === Number(BulkLeaveType)).length}
                                  </td>
                                  <td className="py-2 px-6">
                                  <Input value={NoOfDays || ''} onChange={({target})=>{
                                    let EnteredDays = target.value;
                                    
                                    if(EnteredDays <= rowData.filter(LopData => LopData.leaveTypeId === Number(BulkLeaveType)).length){
                                      setNoOfDays(EnteredDays);
                                    }else{
                                      setNoOfDays(rowData.filter(LopData => LopData.leaveTypeId === Number(BulkLeaveType)).length);
                                    }

                                  }} type="number" borderColor={'gray.400'} bg  placeholder="Enter No. of Days" />
                                  </td>
                                  <td className="py-2 px-6">
                                    <Select value={BulkSettleBy} onChange={({target})=>{setBulkSettleBy(target.value)}} borderColor={'gray.400'} bg placeholder="Select Settle By">
                                      {LeaveQuotaList.map((QuotaData,i)=>{
                                        return <option key={i} value={QuotaData.leaveQuotaId} >{QuotaData.leaveDescription}</option>
                                      })}
                                    </Select>
                                  </td>
                                  <td className="py-2 px-6">
                                  <Button onClick={async () => {
                                              let body = {
                                                empGuid : EMPGUID,
                                                leaveQuotaId : Number(BulkLeaveType || 0),
                                                settled_LeaveQuotaId : Number(BulkSettleBy || 0),
                                                leaveDays : Number(NoOfDays || 0),
                                                settledDate : moment().format('YYYY-MM-DD'),
                                                companyId : userData.companyId,
                                                branchId : userData.isActiveBranch,
                                                remark : "multi",
                                                startDate : moment().startOf('year').format('YYYY-MM-DD'),
                                                endDate : moment().endOf('year').format('YYYY-MM-DD'),
                                                id : 0,
                                                isLopSettled : true,
                                                leaveType : Number(BulkLeaveType || 0),
                                                aDate : moment().format('YYYY-MM-DD')
                                              }
                                      
                                              console.log("Bulk Settlement Body",body);
                                      
                                              await authApi.post(
                                                `${process.env.REACT_APP_ACQ_URL}/api/Leave/SaveLOPSettlementBatchProcess`,body
                                              ).then((response)=>{
                                                console.log(response);
                                      
                                                if(!toast.isActive(id)){
                                                  toast({
                                                    id,
                                                    title:response.data,
                                                    position: 'top',
                                                    status:'info',
                                                    duration: 2000,
                                                    isClosable:true
                                                  })
                                                }
                                      
                                                setBulkLeaveType('');
                                                setNoOfDays(0);
                                                setBulkSettleBy();
                                                setSelectedDataList([]);
                                                GetEmpSettlements();
                                                GetEmpLeaveBalance();
                                      
                                              }).catch((err)=>{
                                                console.error(err);
                                      
                                                if(!toast.isActive(id)){
                                                  toast({
                                                    id,
                                                    title: 'Server Error',
                                                    position: 'top',
                                                    status:'error',
                                                    duration: 2000,
                                                    isClosable:true
                                                  })
                                                }
                                      
                                              })
                                      
                                    }} size={'sm'} colorScheme="blue" >Settle</Button>
                                   </td>
                                </tr>



                              </tbody>
                            </table>
                          </div>


      <Modal isOpen={isSettleOpen} onClose={()=>{
              onSettleClose();
              setSingleSettleBy([]);
            }}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
          <ModalHeader textColor={'gray.700'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Settle LOP</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={SaveSingleSettlement}>
          <ModalBody>
            <div className="flex gap-6 mb-1">
             <div className="space-y-1">
               <p className="text-sm text-gray-500">Attendance Date</p>
               <h6 className="font-medium">{moment(SelectedRecord?.aDate).format('DD/MM/YYYY')}</h6>
             </div>
             <div className="space-y-1">
               <p className="text-sm text-gray-500">Leave Type</p>
               <h6 className="font-medium">{SelectedRecord?.leaveTypeName}</h6>
             </div>
            </div>
            <hr />
            <div className="space-y-2 my-2">
              <h6 className=" font-semibold">Leave Balance</h6>
              <div className="flex flex-wrap gap-4">
              {EmpLeaveBalance?.map((data, i) => {
                return (
                  <div
                    key={i}
                    className="bg-blue-200 rounded-xl drop-shadow  px-2 py-0.5  hover:-translate-y-2 ease-in-out duration-200"
                  >
                    <h6 className="font-semibold ">
                      {data.leaveType}:{" "}
                      <span className="font-semibold text-lg">
                        {data.remainingQuota}
                        <span className="text-xs text-gray-500 font-normal">
                          / {data.openingQuota}
                        </span>
                      </span>
                    </h6>
                  </div>
                );
              })}
              </div>
            </div>
            <div className="space-y-2 my-2">
             <label>Settle LOP By</label>
             <ReactSelect
                  required
                  options={LeaveQuotaList}
                  value={SingleSettleBy}
                  onChange={(value) => {
                    setSingleSettleBy(value);
                  }}
                  getOptionLabel={(option) => option.leaveDescription}
                  getOptionValue={(option) => option.leaveQuotaId}
                  key={(option) => option.leaveQuotaId}
                  placeholder="Select Settlement By"
                />

            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button variant={'outline'} colorScheme='purple' mr={3} onClick={()=>{
              onSettleClose();
              setSingleSettleBy([]);
            }}>
              Cancel
            </Button>
            <Button isDisabled={SingleSettleBy.length === 0 ? true : false} type="submit" colorScheme='purple'>Save</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default EmpLeaveSettlements