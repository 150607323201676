import { Tooltip, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HelpGuideMenu from './HelpGuideMenu';
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

const HeadSettings = () => {
  const {userData} = useAuth();
  
  const [MenuList, setMenuList] = useState([]);

  const location = useLocation();
  const { pathname } = location;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  useEffect(() => {
    HandleSelectedRole();
  }, []);

  const HandleSelectedRole = async () => {
    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/RoleAndPermission/GetModuleMasterList?RoleId=${
          userData.roleId || 0
        }&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log("Setting Menu List ", response);
      setMenuList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const CheckMainMenuAccess = (ReqMainMenuName) => {
    return MenuList?.some((data) => {
     if (data.mainMenuName === ReqMainMenuName) {
       const UserAccess = userData?.isRoleBased ? (data.isCreate || data.isModify || data.isView ) : data.isDefault;
       return UserAccess;
     }
     return false;
    }); 
  }

  const CheckSubMenuAccess = (ReqSubMenuName) => {
    return MenuList?.some((data) => {
      if (data.subMenuName === ReqSubMenuName) {
        const UserAccess = userData?.isRoleBased ? (data.isCreate || data.isModify || data.isView ) : data.isDefault;
        return UserAccess;
      }
      return false;
    });
  };

  return CheckMainMenuAccess('Setting') && (
    <div className="relative inline-flex ">
      <Tooltip
        borderRadius={"7px"}
        hasArrow
        label="Settings"
        bgColor={"gray.600"}
      >
        <button
          onClick={onOpen}
          className={` w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full`}
        >
          <span className="sr-only">Settings</span>
          <IoSettingsOutline className=" text-xl text-gray-700" />
        </button>
      </Tooltip>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton className="mt-2" />
          <DrawerHeader>Settings</DrawerHeader>
          <hr />
          <DrawerBody>
            <div>
              <ul className={` mt-1`}>
                {
                  (CheckSubMenuAccess("Company Master") || 
                  CheckSubMenuAccess("Branch") ) && (
                  <>

              <HelpGuideMenu
                activecondition={
                  pathname === "/companydetail" ||
                  pathname === "/branchmaster"
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#"
                        className={`block text-slate-700 hover:text-slate-900 truncate transition duration-150 ${
                          (pathname === "/sdf" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                           handleClick();
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-sm font-medium hover:ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Company Settings
                            </span>
                          </div>
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-4 mt-1 ${!open && "hidden"}`}>

                        {CheckSubMenuAccess("Company Master") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/companydetail"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Company Master
                            </span>
                          </NavLink>
                        </li>
                      )}


                  {CheckSubMenuAccess("Branch") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/branchmaster"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Branch
                            </span>
                          </NavLink>
                        </li>
                      )}
                          
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </HelpGuideMenu>

                  </>
                )}

                { 
                  (CheckSubMenuAccess("Component Master") || 
                  CheckSubMenuAccess("Payroll Configuration") || 
                  CheckSubMenuAccess("Attendance Type Master")) && (
                  <>

         <HelpGuideMenu
                activecondition={
                  pathname === "/componentmaster" ||
                  pathname === "/compliancesettings" ||
                  pathname === "/payrollconfiguration" 
                  // || pathname === "/attendancetype" 
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#"
                        className={`block text-slate-700 hover:text-slate-900 truncate transition duration-150 ${
                          (pathname === "/sdf" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                           handleClick();
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-sm font-medium hover:ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Payroll Salary Settings
                            </span>
                          </div>
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-4 mt-1 ${!open && "hidden"}`}>

                        {CheckSubMenuAccess("Component Master") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/componentmaster"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Component Master
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Compliance Setting") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/compliancesettings"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Compliance Settings
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Payroll Configuration") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/payrollconfiguration"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Payroll Configuration
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {/* {MenuList.filter(
                      (data) => data.subMenuName === "Attendance Type Master"
                    ).map((data,i) => {
                      return data.isCreate ||
                        data.isModify ||
                        data.isView === true ||
                        userData.role === "Admin" ? (
                        <li key={i} className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/attendancetype"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Attendance Type Master
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      );
                    })} */}
                          
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </HelpGuideMenu>

                  </>
                )}

                {userData.role === "Admin" && (
                  <>

             <HelpGuideMenu
                activecondition={
                  pathname === "/newrole" ||
                  pathname === "/rolelist"
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#"
                        className={`block text-slate-700 hover:text-slate-900 truncate transition duration-150 ${
                          (pathname === "/sdf" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                           handleClick(); 
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-sm font-medium hover:ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Role Settings
                            </span>
                          </div>
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-4 mt-1 ${!open && "hidden"}`}>

                        <li className="mb-1 last:mb-0">
                      <NavLink
                        end
                        onClick={onClose}
                        to="/newrole"
                        className={({ isActive }) =>
                          "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                          (isActive ? "!text-indigo-500" : "")
                        }
                      >
                        <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Role & Permissions
                        </span>
                      </NavLink>
                    </li>

                    <li className="mb-1 last:mb-0">
                      <NavLink
                        onClick={onClose}
                        end
                        to="/rolelist"
                        className={({ isActive }) =>
                          "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                          (isActive ? "!text-indigo-500" : "")
                        }
                      >
                        <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Role List
                        </span>
                      </NavLink>
                    </li>

                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </HelpGuideMenu>

                  </>
                )}

                { 
                  (CheckSubMenuAccess("Designation Master") ||
                  CheckSubMenuAccess("Department Master") ||
                  CheckSubMenuAccess("Document Master") ||
                  CheckSubMenuAccess("Employee Category") ||
                  CheckSubMenuAccess("Bank Master") ||
                  CheckSubMenuAccess("Code Master") ||
                  CheckSubMenuAccess("Holiday Master") ||
                  CheckSubMenuAccess("Weekly Off Master") ||
                  CheckSubMenuAccess("Client Master") ||
                  CheckSubMenuAccess("Separation Settings")) &&
                 (
                  <>

             <HelpGuideMenu
                activecondition={
                  pathname === "/designation" ||
                  pathname === "/department" ||
                  pathname === "/document" ||
                  pathname === "/employeecategory" ||
                  pathname === "/bank" ||
                  pathname === "/code" ||
                  pathname === "/holidaymaster" ||
                  pathname === "/weeklyoff" ||
                  pathname === "/expensetype" ||
                  pathname === "/clientmaster" ||
                  pathname === "/separationsettings"
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#"
                        className={`block text-slate-700 hover:text-slate-900 truncate transition duration-150 ${
                          (pathname === "/sdf" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                           handleClick(); 
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-sm font-medium hover:ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                             Master Settings
                            </span>
                          </div>
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-4 mt-1 ${!open && "hidden"}`}>

                        {CheckSubMenuAccess("Designation Master") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/designation"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Designation Master
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Department Master") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/department"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Department Master
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {/* {MenuList.filter(
                      (data) => data.subMenuName === "Document Master"
                    ).map((data,i) => {
                      return data.isCreate ||
                        data.isModify ||
                        data.isView === true ||
                        userData.role === "Admin" ? (
                        <li key={i} className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/document"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Document Master
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      );
                    })} */}

                    {CheckSubMenuAccess("Employee Category") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/employeecategory"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Employee Category
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Bank Master") && (
                        <li  className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/bank"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Bank Master
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Code Master") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/code"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Code Master
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Holiday Master") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/holidaymaster"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Holiday Master
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Weekly Off Master") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/weeklyoff"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Weekly Off Master
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {/* {MenuList.filter(
                      (data) => data.subMenuName === "Expense Master"
                    ).map((data,i) => {
                      return data.isCreate ||
                        data.isModify ||
                        data.isView === true ||
                        userData.role === "Admin" ? (
                        <li key={i} className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/expensetype"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Expense Master
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      );
                    })} */}

                    {/* {MenuList.filter(
                      (data) => data.subMenuName === "Client Master"
                    ).map((data,i) => {
                      return data.isCreate ||
                        data.isModify ||
                        data.isView === true || userData.role === "Admin" ? (
                        <li key={i} className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/clientmaster"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Client Master
                            </span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      );
                    })} */}

                    {CheckSubMenuAccess("Separation Settings") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/separationsettings"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Separation Settings
                            </span>
                          </NavLink>
                        </li>
                      )}


                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </HelpGuideMenu>

                  </>
                )}


                

                { 
                  (CheckSubMenuAccess("Import Data") ||
                  CheckSubMenuAccess("Email Config") ||
                  CheckSubMenuAccess("Biometric Integration")) &&
                 (
                  <>

              <HelpGuideMenu
                activecondition={
                  pathname === "/importdata" ||
                  pathname === "/emailconfig" ||
                  pathname === "/integratebiometric" 
                  // || pathname === "/transferemployee"
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#"
                        className={`block text-slate-700 hover:text-slate-900 truncate transition duration-150 ${
                          (pathname === "/sdf" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                           handleClick(); 
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-sm font-medium hover:ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Other Settings
                            </span>
                          </div>
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-4 mt-1 ${!open && "hidden"}`}>
                 
                        {CheckSubMenuAccess("Import Data") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            onClick={onClose}
                            end
                            to="/importdata"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Import Data
                            </span>
                          </NavLink>
                        </li>
                      )}

                    {CheckSubMenuAccess("Email Config") && (
                        <li className="mb-1 last:mb-0">
                          <NavLink
                            end
                            onClick={onClose}
                            to="/emailconfig"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500" : "")
                            }
                          >
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Email Config
                            </span>
                          </NavLink>
                        </li>
                      )}

                  {CheckSubMenuAccess("Biometric Integration") && (
                        <li  
                          className="mb-1 last:mb-0"
                        >
                          <NavLink
                            end
                            onClick={onClose}
                            to="/integratebiometric"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }>
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Biometric Integration
                            </span>
                          </NavLink>
                        </li>
                   )} 

                    {/* {MenuList.filter(
                      (data) => data.subMenuName === "Transfer Employee"
                    ).map((data,i) => {
                      return data.isCreate ||
                        data.isModify ||
                        data.isView === true || userData.role === "Admin" ? ( 
                        <li 
                         key={i} 
                          className="mb-1 last:mb-0"
                        >
                          <NavLink
                            end
                            onClick={onClose}
                            to="/transferemployee"
                            className={({ isActive }) =>
                              "block text-slate-400 hover:text-slate-700 hover:translate-x-2 transition duration-150 truncate " +
                              (isActive ? "!text-indigo-500 " : "")
                            }>
                            <span className=" font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Transfer Employee
                            </span>
                          </NavLink>
                        </li>
                      ):("")})}  */}

                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}

              </HelpGuideMenu>
              
                  </>
                )}


              </ul>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  )
};

export default HeadSettings;
