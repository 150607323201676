import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { Button, Center, Spinner } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from '../Utils/AuthContext';
import authApi from "../Utils/AuthApis";

function LeaveBalanceReport() {

  const { userData } = useAuth();

  const [IsLoading, setIsLoading] = useState(false);
  const [SelectedMonth, setSelectedMonth] = useState(new Date());
  const [LeaveBalanceRowData, setLeaveBalanceRowData] = useState([]);
  const [FilteredRowData, setFilteredRowData] = useState([]);

  const [Query, setQuery] = useState("");
  const gridRef = useRef();
  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  const [LeaveRowData, setLeaveRowData] = useState([]);
  const [LeaveColumnDefs, setLeaveColumnDefs] = useState([]);

  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  useEffect(() => {
    getLeaveBalanceList(
      SelectedMonth.getMonth() + 1,
      SelectedMonth.getFullYear()
    );
  }, []);

  const getLeaveBalanceList = async (MONTH, YEAR) => {
    console.log("Month", MONTH);
    console.log("Year", YEAR);
    setIsLoading(true);

    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveBalanceList?month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Balance List", response);

      const res = response.data;

      let FinalLeaveRowData = _.uniqBy(res, "empGuid").map((data) => {
        let Rowdata = [];
        Rowdata.push({
          empName: data.empName,
          empCode: data.empCode,
        });
        res
          .filter((lempData) => lempData.empGuid === data.empGuid)
          .map((BalanceData, j) => {
            return Rowdata.push({
              [BalanceData.leaveType + "RemainingQuota"]:
                BalanceData.remainingQuota,
              [BalanceData.leaveType + "OpeningQuota"]:
                BalanceData.openingQuota,
            });
          });

        //   console.log("Row Data",Rowdata);
        return Object.assign({}, ...Rowdata);
      });

      let FinalHeaderData = _.uniqBy(res, "leaveType").map((data) => {
        return {
          headerName: data.leaveType,
          children: [
            {
              headerName: "Opening",
              field: data.leaveType + "OpeningQuota",
            },
            {
              headerName: "Remaining",
              field: data.leaveType + "RemainingQuota",
            },
          ],
        };
      });

      let OldHeaderData = [
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "empName",
        },
      ];

      setLeaveColumnDefs(_.union(OldHeaderData, FinalHeaderData));

      // console.log("Final Leave Row Data",FinalLeaveRowData);
      console.log("Final Header Row data", FinalHeaderData);

      setLeaveRowData(FinalLeaveRowData);
      setLeaveBalanceRowData(response.data);
      setFilteredRowData(response.data);
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false)
    }
  };

  const HandleSearch = async (e) => {
    const getSearch = e.target.value;

    if (getSearch.length > 0) {
      const SearchData = LeaveBalanceRowData.filter(
        (item) =>
          item.empName.toLowerCase().includes(getSearch) ||
          item.empCode.toLowerCase().includes(getSearch)
      );
      setLeaveBalanceRowData(SearchData);
    } else {
      setLeaveBalanceRowData(FilteredRowData);
    }

    setQuery(getSearch);
  };

  const exportData = () => {
    const params = {
      fileName: `Leave Balance Report of ${userData.activeBranchName}.csv`,
    };

    gridRef.current.api.exportDataAsCsv(params);
  };

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">
          Leave Balance Reports
        </h1>
        <div className="flex items-center gap-4">
          <label className="font-semibold text-lg mr-3 ">Month:</label>
          <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
              selected={SelectedMonth}
              onChange={(date) => {
                setSelectedMonth(date);
                getLeaveBalanceList(date.getMonth()+1, date.getFullYear());
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
          <div>
            <Button
              leftIcon={<FontAwesomeIcon icon={faDownload} />}
              onClick={() => {
                exportData();
                //  gridRef.current.api.exportDataAsCsv();
              }}
              colorScheme="purple"
            >
              Export
            </Button>
          </div>
        </div>
      </div>

      {IsLoading ? <Center>
         <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
        </Center> : <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={LeaveRowData} // Row Data for Rows
          columnDefs={LeaveColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>}
    </div>
  );
}

export default LeaveBalanceReport;
