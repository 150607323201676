import { faCalendarMinus, faFileLines, faMoneyBill1, } from "@fortawesome/free-regular-svg-icons";
import { faBoxesStacked, faPersonWalkingArrowRight, faSackDollar, faWallet, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Calendar, Clock, Home, Users } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";

function FinalSidebar({ sidebarOpen, setSidebarOpen }) {
  const {userData} = useAuth();

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const [MenuList, setMenuList] = useState([]);
  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === "true");

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    HandleSelectedRole();
  }, []);

  const HandleSelectedRole = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData?.roleId || 0}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log("Sidebar Menu List", response);
      setMenuList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const CheckMainMenuAccess = (ReqMainMenuName) => {
    return MenuList?.some((data) => {
     if (data.mainMenuName === ReqMainMenuName) {
       const UserAccess = userData?.isRoleBased ? (data.isCreate || data.isModify || data.isView ) : data.isDefault;
       return UserAccess;
     }
     return false;
    }); 
  }

  const CheckSubMenuAccess = (ReqSubMenuName) => {
    return MenuList?.some((data) => {
      if (data.subMenuName === ReqSubMenuName) {
        const UserAccess = userData?.isRoleBased ? (data.isCreate || data.isModify || data.isView ) : data.isDefault;
        return UserAccess;
      }
      return false;
    });
  }
  
  return (
    <div>
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col m-2 mb-8  top-0 rounded-2xl absolute z-40 left-0  lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-60 md:sidebar-expanded:!w-60 lg:w-60 lg:sidebar-expanded:!w-60 2xl:!w-60 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-72"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2 bg-slate-100 p-1 drop-shadow-md rounded-lg">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}

          <NavLink end to="/dashboard" className="block">
            <div className="flex justify-center gap-2 items-center">
              <img className="h-7 ml-2" src="/HRMusterLogo.png" alt="HR Muster Logo" />
              <h1 className="text-gray-800 font-bold text-2xl">
                HR Muster
              </h1>
            </div>
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>

            <ul className="mt-3 space-y-2">
                  {/* Dashboard */}
                  <li
                    className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("/dashboard") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/dashboard"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/dashboard") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <Home size="16px" />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Dashboard
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {CheckMainMenuAccess("Employee Details") && (
                    <>
                      {/* Employees */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/createemployee" ||
                          pathname === "/editemployees" ||
                          pathname.includes("/editempdetails/") ||
                          pathname === "/empinduction" ||
                          pathname === "/myprofile" ||
                          pathname === "/emppayslips" ||
                          pathname === "/myteamempdetails" ||
                          pathname === "/salarydashboard" ||
                          pathname === "/viewsalary" ||
                          pathname === "/probationemployees"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/createemployee" ||
                                    pathname.includes("createemployee")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <Users size="16px" />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Employee Details
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {CheckSubMenuAccess("Create Employee") && (
                                    <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/createemployee"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Create Employee
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                  {CheckSubMenuAccess("All Employee info") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/editemployees"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ||
                                            pathname.includes("/editempdetails/") ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Employee Info
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Employee Induction") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empinduction"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ||
                                            pathname.includes("/empinduction")
                                              ? "!text-indigo-500"
                                              : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Employee Induction
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}


                                  {CheckSubMenuAccess("Employee Probation") && (
                                        <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/probationemployees"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive )
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Employee Probation
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {/* {MenuList.filter(
                                    (data) => data.subMenuName === "Profile"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/myprofile"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Profile
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}

                                  {CheckSubMenuAccess("Payslips") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/emppayslips"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }>
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Payslips
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Add Salary") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/salarydashboard"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Salary Structure
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("View Salary") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewsalary"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Salary
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}



                 {CheckMainMenuAccess("Salary Revision") && (
                    <>
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/viewsalaryincrement" || 
                      pathname === "/importsalaryrevision" || 
                      pathname === "/incrementrule"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faSackDollar} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Salary Revision
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {CheckSubMenuAccess("Revise Salary") &&  ( 
                                <li className="mb-1 last:mb-0">
                                  <NavLink
                                    end
                                    to="/importsalaryrevision"
                                    className={({ isActive }) =>
                                      "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                      (isActive ? "!text-indigo-500" : "")
                                    }
                                  >
                                    <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Revise Salary
                                    </span>
                                  </NavLink>
                                </li>
                               )}

                               {CheckSubMenuAccess("Revise Salary") && (
                                <li className="mb-1 last:mb-0">
                                  <NavLink
                                    end
                                    to="/viewsalaryincrement"
                                    className={({ isActive }) =>
                                      "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                      (isActive ? "!text-indigo-500" : "")
                                    }
                                  >
                                    <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                     View Salary Increment
                                    </span>
                                  </NavLink>
                                </li>
                               )}

                            {CheckSubMenuAccess("Revision") && ( 
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/incrementrule"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")}
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Revision Rules
                                  </span>
                                </NavLink>
                              </li>
                            )}

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                    </>
                  )}


                  {CheckMainMenuAccess("Assets") && (
                    <>
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/addasset" ||
                          pathname === "/allocateasset" ||
                          pathname === "/viewempassets"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faBoxesStacked} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Assets
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {CheckSubMenuAccess("Add Asset") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/addasset"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Asset Register
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Allocate Asset") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allocateasset"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                             Asset Allocation
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("View Assets") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewempassets"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Assets
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}


                           {CheckSubMenuAccess("FNF Asset Recovery") && (
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/fnfassetrecovery"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    FNF Asset Recovery
                                  </span>
                                </NavLink>
                              </li>
                              )}

                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                  {CheckMainMenuAccess("Attendance") && (
                    <>
                      {/* Attendance */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/forgotattendance" ||
                          pathname === "/geoattendance" ||
                          pathname === "/viewattendance" ||
                          pathname === "/attendancesettings" ||
                          pathname === "/attendancedashboard" ||
                          pathname === "/empgeofencing" ||
                          pathname === "/activityreport" ||
                          pathname === "/myattendance" ||
                          pathname === "/attendancehelper"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/viewattendance" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <Calendar size="16px" />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Attendance
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {CheckSubMenuAccess("Attendance Dashboard") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="attendancedashboard"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Dashboard
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("All Attendance") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewattendance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Attendance
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Emp Geo-Fencing") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empgeofencing"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Emp Geo-Fencing
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {/* {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Activity Report"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/activityreport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Activity Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}

                                  {CheckSubMenuAccess("Attendance Setting") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/attendancesettings"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Attendance Settings
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("View Attendance") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/myattendance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Attendance
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                  {CheckMainMenuAccess("Overtime") && (
                    <>
                      {/* Overtime */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/empviewovertime" ||
                          pathname === "/allovertime" ||
                          pathname === "/empovertime" ||
                          pathname === "/finalovertime" ||
                          pathname === "/applyovertime" ||
                          pathname === "/overtimepayment"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/viewattendance" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <Clock size="16px" />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Overtime
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {CheckSubMenuAccess("Overtime Eligibility") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/applyovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Overtime Eligibility
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("All Overtime") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Overtime
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                 {CheckSubMenuAccess("Final Overtime") && (
                                        <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/finalovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Final Overtime
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}
                                  

                                  {CheckSubMenuAccess("View Overtime Status") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Overtime Status
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                  {CheckMainMenuAccess("Leave") && (
                    <>
                      {/* Leave */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/leavequota" ||
                          pathname === "/leavesettings" ||
                          pathname === "/leaveapply" ||
                          pathname === "/leavebalance" ||
                          pathname === "/leavedashboard" ||
                          pathname === "/employeewfh" ||
                          pathname === "/myleave" ||
                          pathname === "/empwfh" ||
                          pathname === "/compensationoff" ||
                          pathname === "/allcompensationoff" ||
                          pathname === "/carryforwardsetting" || 
                          pathname === "/empleavebalance" || 
                          pathname.includes("leavesettlement")
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faCalendarMinus} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Leave
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {CheckSubMenuAccess("Leave Dashboard") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavedashboard"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Dashboard
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("All Leave Application") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leaveapply"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Leave Application
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Leave Balance") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavebalance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Balance
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Leave Quota") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavequota"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Quota
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                 {CheckSubMenuAccess("Leave Settlements") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavesettlement/pendingsettlements"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Settlements
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("All WFH Application") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/employeewfh"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All WFH Application
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("View Leave") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/myleave"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Leaves
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Work From Home") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empwfh"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Work From Home
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Carry Forward Setting") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/carryforwardsetting"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Carry Forward Setting
                                        </span>
                                      </NavLink>
                                    </li>
                                    )}

                                  {/* {CheckSubMenuAccess("Employee Leave Balance") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empleavebalance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Employee Leave Balance
                                          </span>
                                        </NavLink>
                                      </li>
                                    )} */}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                  {CheckMainMenuAccess("Payroll") && (
                    <>
                      {/* Payroll */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/estimatedpayroll" ||
                          pathname === "/finalrunpayroll" ||
                          pathname === "/adhocpayment" ||
                          pathname === "/adhocdeduction" ||
                          pathname === "/compliance" ||
                          pathname === "/taxdeductionsource"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faMoneyBill1} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Payroll
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >


                                  {CheckSubMenuAccess("Adhoc Payment") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/adhocpayment"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Adhoc Payment
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Adhoc Deduction") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/adhocdeduction"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Adhoc Deduction
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Compliance") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/compliance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Compliance
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("TDS") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/taxdeductionsource"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            TDS
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}


                                 {CheckSubMenuAccess("Estimated Payroll") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/estimatedpayroll"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Estimated Payroll
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Run Payroll") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/finalrunpayroll"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Run Payroll
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                  {CheckMainMenuAccess("Expense") && (
                    <>
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/expenseadvancemaster" ||
                          pathname === "/pettyadvancerequest" ||
                          pathname === "/claimexpense" ||
                          pathname === "/expensepayment" ||
                          pathname === "/empsummarydetails" ||
                          pathname === "/expensepaymentprocess" ||
                          pathname === "/advancepaymentprocess" ||
                          pathname === "/empexpenseclaim" ||
                          pathname === "/expenseapprovals" || 
                          pathname === "/returnrequests"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/expenseadvancemaster" ||
                                    pathname.includes(
                                      "expenseadvancemaster"
                                    )) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faWallet} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Expense
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                              <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>

                                 {CheckSubMenuAccess("Advance Limit Master") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expenseadvancemaster"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Advance Limit/Matrix
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                 {CheckSubMenuAccess("Emp Summary") && (
                                         <li className="mb-1 last:mb-0">
                                          <NavLink
                                            end
                                            to="/empsummarydetails"
                                            className={({ isActive }) =>
                                              "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                              (isActive ? "!text-indigo-500" : "")
                                            }
                                          >
                                            <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                              Emp Summary
                                            </span>
                                          </NavLink>
                                        </li>
                                    )}


                                 {CheckSubMenuAccess("Add Expense") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empexpenseclaim"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Expense
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                 {CheckSubMenuAccess("Expense Approvals") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expenseapprovals"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Expense Approvals
                                        </span>
                                      </NavLink>
                                    </li>
                                    )}

                                 {CheckSubMenuAccess("Advance Payment Process") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/advancepaymentprocess"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Advance Payment Process
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                 {CheckSubMenuAccess("Expense Payment Process") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/expensepaymentprocess"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Expense Payment Process
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                 {CheckSubMenuAccess("Advance Confirmed Payments") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/advanceconfirmedpay"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Advance Confirmed Payments
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                 {CheckSubMenuAccess("Expense Confirmed Payments") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/expenseconfirmedpay"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Expense Confirmed Payments
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                 {CheckSubMenuAccess("Return Requests") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/returnrequests"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Return Requests
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                 {CheckSubMenuAccess("Payment Report") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/expensepaymentinfo"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Payment Report
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                            </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                  {/* {FoundBonusMenu ? (
                    <>
                      <li
                        className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                          pathname.includes("/bonus") &&
                          "bg-slate-900 rounded-xl"
                        }`}
                      >
                        <NavLink
                          end
                          to="/bonus"
                          className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                            pathname.includes("/bonus") && "hover:text-slate-50"
                          }`}
                        >
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                            <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Bonus
                            </span>
                          </div>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )} */}

                      {/* Investment */}

                  {/* {FoundInvestmentsMenu ? (
                    <>
                      <SidebarLinkGroup
                        activecondition={
                          pathname.includes("addinvestment") ||
                          pathname === "/addform16" ||
                          pathname === "/empform16" || 
                          pathname === "/allinvestments" ||
                          pathname === "/empinvestments"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true)
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon
                                      icon={faFileInvoiceDollar}
                                    />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Investments
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Investments"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allinvestments"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                           All Investments
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Add Investment"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to={`/addinvestment/${userData.guId}/${FinancialYear?.financialYearName}`}
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Investment
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                 {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "My Investments"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                        <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to={`/empinvestments`}
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive || (pathname.includes("addinvestment") && pathname.includes("employee")) ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            My Investments
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                 {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Add Form 16"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/addform16"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Form 16
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Form 16"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empform16"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                           Form 16
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )} */}

                  {CheckMainMenuAccess("Separation") && (
                    <>
                      {/* Seperation */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/addseparation" ||
                          pathname === "/empseperation" ||
                          pathname === "/fnfsettlement"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon
                                      icon={faPersonWalkingArrowRight}
                                    />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Separation
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {CheckSubMenuAccess("All Separation") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/addseparation"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Separation
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("View Separation") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empseperation"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Separation Request
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("FNF Settlement") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/fnfsettlement"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            FNF Settlement
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                  {CheckMainMenuAccess("Reports") && (
                    <>
                      {/* Reports */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/leavereports" ||
                          pathname === "/leavebalancereports" ||
                          pathname === "/allattendancereport" ||
                          pathname === "/attendancereport" ||
                          pathname === '/expensepaymentinfo' ||
                          pathname === '/expensereport' ||
                          pathname === '/employeesreport' ||
                          pathname === '/expensereportall' ||
                          pathname === '/separationreport' ||
                          pathname === '/empdesignations' || 
                          pathname === '/overtimereport' || 
                          pathname === '/payrollreports' || 
                          pathname === '/fnfreport' || 
                          pathname === '/empdatareport'
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faFileLines} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Reports
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                {MenuList.filter(
                                      (data) =>
                                        data.subMenuName === "Emp Details Reports" || true
                                    ).map((data) => {
                                      return (data.isCreate ||
                                        data.isModify ||
                                        data.isView || userData?.role === "Admin" ) && (
                                  <li className="text-red-400 my-2 text-xs">
                                    Emp Details Reports
                                  </li>
                                   )
                                  })[0]
                                }

                                  {CheckSubMenuAccess("Employees Report") && (
                                      <li className="mb-1 last:mb-0">
                                      <NavLink
                                        end
                                        to="/employeesreport"
                                        className={({ isActive }) =>
                                          "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                          (isActive ? "!text-indigo-500" : "")
                                        }
                                      >
                                        <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Employees Report
                                        </span>
                                      </NavLink>
                                    </li>
                                    )}


                                  {CheckSubMenuAccess("Employee Data Report") && (
                                       <li className="mb-1 last:mb-0">
                                         <NavLink
                                           end
                                           to="/empdatareport"
                                           className={({ isActive }) =>
                                             "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                             (isActive ? "!text-indigo-500" : "")
                                           }
                                         >
                                           <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                             Employee Data Report
                                           </span>
                                         </NavLink>
                                       </li>
                                    )}

                                    {CheckSubMenuAccess("Emp Designations") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empdesignations"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Emp Designations
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                    {CheckSubMenuAccess("Separation Report") && (
                                       <li className="mb-1 last:mb-0">
                                         <NavLink
                                           end
                                           to="/separationreport"
                                           className={({ isActive }) =>
                                             "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                             (isActive ? "!text-indigo-500" : "")
                                           }
                                         >
                                           <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                             Separation Report
                                           </span>
                                         </NavLink>
                                       </li>
                                    )}



                                  {CheckSubMenuAccess("Leave Balance Report") && CheckSubMenuAccess("Leave Applied Report") && (
                                    <li className="text-red-400 my-2 text-xs">
                                      Leave Reports
                                    </li>
                                  )}

                                  {CheckSubMenuAccess("Leave Applied Report") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavereports"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Applied Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Leave Balance Report") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavebalancereports"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Balance Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Attendance Report") && (
                                      <li className="text-red-400 my-2 text-xs">
                                        Attendance Reports
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("Attendance Report") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/attendancereport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Attendance Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  {CheckSubMenuAccess("All Attendance Report") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allattendancereport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Attendance Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                  
                                  {CheckSubMenuAccess("Overtime Report") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/overtimereport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Overtime Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                    {CheckSubMenuAccess("Payroll Reports") && (
                                       <li className="text-red-400 my-2 text-xs">
                                        Payroll Reports
                                      </li>
                                    )}

                                    {CheckSubMenuAccess("Payroll Reports") && (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/payrollreports"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Payroll Reports
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}

                                    {CheckSubMenuAccess("FNF Report") && (
                                        <li className="mb-1 last:mb-0">
                                          <NavLink
                                            end
                                            to="/fnfreport"
                                            className={({ isActive }) =>
                                              "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                              (isActive ? "!text-indigo-500" : "")
                                            }
                                          >
                                            <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                              FNF Report
                                            </span>
                                          </NavLink>
                                        </li>
                                    )}

                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  )}

                      {/* Announcements */}

                  {/* {FoundAnnouncementMenu ? (
                    <>
                      <SidebarLinkGroup
                        activecondition={pathname === "/announcements"}
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faBullhorn} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Announcements
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Announcement & News"
                                  ).map((data) => {
                                    return (data.isCreate || data.isModify || data.isView || userData?.role === "Admin") &&  (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/announcements"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Announcement & News
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )} */}

                      {/* Policy */}
                  {/* {FoundPolicyMEnu ? (
                    <>
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/policyupload" ||
                          pathname === "/viewpolicies"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faFileInvoice} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Policy
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Policy"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/policyupload"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Policy
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Policy"
                                  ).map((data) => {
                                    return data.isView || userData?.role === "Admin" ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewpolicies"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Policies
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )} */}
                
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:hidden 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinalSidebar;
