import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Center, Spinner, Input, TagCloseButton, TagLabel, Tag, Tabs, TabList, TabPanels, Tab, TabPanel, useBoolean } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import moment from "moment";
import StatCard from "../UI Components/StatCard";
import { LuCalendarX,LuUserX,LuUser,LuUsers,LuDownload, LuSend, LuPaperclip } from 'react-icons/lu';
import useValidation from "../Utils/useValidation";
import { useAuth } from '../Utils/AuthContext';
import { checkValidData } from "../Utils/InputValidate";
import EmpEditAttendance from "./EmpEditAttendance";
import authApi from "../Utils/AuthApis";
import useFetch from "../Utils/useFetch";
import { Lock, RefreshCw } from "react-feather";

function ViewAttendance() {

  const Validation = useValidation();
  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";

  const { FetchedData: FetchedAttendanceSettings } = useFetch('/api/Attendance/GetAttendenceSettingsByBranch',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  })

  console.log("Attendance Settings",FetchedAttendanceSettings,FetchedAttendanceSettings?.isAttendanceEditable);

  const [ToEmail, setToEmail] = useState();
  const [ToEmailList, setToEmailList] = useState([]);
  const [CcEmail, setCcEmail] = useState();
  const [CcEmailList, setCcEmailList] = useState([]);
  const [ErrorMsg, setErrorMsg] = useState({
    ToEmailError: false,
    ToErrMsg: null,
    CcEmailError: false,
    CcErrMsg: null
  });

  const [ShareAttLoading, setShareAttLoading] = useState(false);
  const [IsLoading, setIsLoading] = useState({
    ProcessLoading: false,
    StatsLoading:false,
    AttendanceData: false
  });
  const [IsLocking, setIsLocking] = useState(false);
  const [LockAtt, setLockAtt] = useBoolean(localStorage.getItem('isLocedAtt') || false);

  const CurrentDate = new Date().toLocaleDateString("en-CA");
  console.log("Current Date", CurrentDate);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEmailAttOpen, onClose: onEmailAttClose } = useDisclosure();

  const [AttAction, setAttAction] = useState("Mark Present");
  const [SelectedEmp, setSelectedEmp ] = useState([]);
  const [AttInTime, setAttInTime] = useState();
  const [AttOutTime, setAttOutTime] = useState();

  const [EditAttRemarks, setEditAttRemarks] = useState();
  const [AttendanceDate, setAttendanceDate] = useState(new Date());
  const [AttendanceStats, setAttendanceStats] = useState([]);
  
  const [IsLateAttach, setIsLateAttach] = useState(false);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Employee Name",
      field: "empName",
    },
    {
      headerName: "Designation",
      field: "designationName",
    },
    {
      headerName: "Status",
      field: "attendanceStatus",
      cellRenderer: (params) =>
        params.data.attendanceStatus === "P" ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            P
          </span>
        ) : params.data.attendanceStatus === "P*" ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            P*
          </span>
        ) : params.data.attendanceStatus === "L" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            L
          </span>
        ) : params.data.attendanceStatus === "LWP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            LWP
          </span>
        ) : params.data.attendanceStatus === "LOP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            LOP
          </span>
        ) : params.data.attendanceStatus === "A" ? (
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            A
          </span>
        ) : params.data.attendanceStatus === "WO" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            WO
          </span>
        ) : params.data.attendanceStatus === "H" ? (
          <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            H
          </span>
        ) : params.data.attendanceStatus === "Not Join" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            NJ
          </span>
        ) : params.data.attendanceStatus === "Resign" ? (
          <span className="bg-pink-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            R
          </span>
        ) : (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.attendanceStatus}
          </span>
        ),
    },
    {
      headerName: "Attendance",
      field: "att_statusType",
      cellRenderer: (params) =>
        params.data.attendanceStatus === "P" ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) 
        // : params.data.attendanceStatus === "" ? (
        //   <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        //     {params.data.att_statusType}
        //   </span>
        // ) 
        : params.data.attendanceStatus === "A" ? (
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.att_statusType === "Late" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "L" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) :params.data.attendanceStatus === "LOP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) :params.data.attendanceStatus === "LWP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.att_statusType === "HD" ? (
          <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "WO" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "H" ? (
          <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "Not Join" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "Resign" ? (
          <span className="bg-pink-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ),
    },
    {
      headerName: "In-Time",
      field: "inTime",
    },
    {
      headerName: "Out-Time",
      field: "outTime",
    },
    {
      headerName: "Date",
      field: "attendanceDate",
      valueGetter: ({data}) =>
        new Date(data.attendanceDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "Device Name",
      field: "linkDevice",
    },
    {
      headerName: "Remarks",
      field: "remark",
    },
    // {
    //   headerName: "Action",
    //   cellRenderer: ({data}) => <Button
    //     leftIcon={<LuEdit />}
    //     onClick={() => {
    //       setSelectedEmp(data);
    //       setAttInTime(data.inTime);
    //       setAttOutTime(data.outTime);
    //       setIsLateAttach(false);
    //       onOpen();
    //     }}
    //     size={"xs"}
    //     colorScheme={"blue"}
    //   >
    //     Edit
    //   </Button>
    // },
    {
      headerName: "Action",
      cellRenderer: ({data}) => data.att_statusType === "Late" && <Button
        leftIcon={<LuPaperclip size={'13px'} />}
        onClick={() => {
          setSelectedEmp(data);
          setAttInTime(data.inTime);
          setAttOutTime(data.outTime);
          setIsLateAttach(true);
          onOpen();
        }}
        size={"xs"}
        colorScheme={"blue"}
      >
        Attach
      </Button>
    }
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true,
  }));


  const handleEmailInputKeyDown = (e) => {

    const isEnterCommaOrSpace =
      e.key === "Enter" || e.key === "," || e.key === " ";
    const isToMail = e.target.name === "TOMAIL";

    if (isEnterCommaOrSpace) {
      e.preventDefault();

      const errorType = isToMail ? "ToEmailError" : "CcEmailError";
      const errMsgType = isToMail ? "ToErrMsg" : "CcErrMsg";

      setErrorMsg({
        ...ErrorMsg,
        [errorType]: false,
        [errMsgType]: null,
      });

      const currentEmail = isToMail ? ToEmail : CcEmail;
      const trimmedEmail = currentEmail ? currentEmail.trim() : "";

      if (trimmedEmail !== "" && !checkValidData("email", trimmedEmail)) {
        setErrorMsg({
          ...ErrorMsg,
          [errorType]: true,
          [errMsgType]: "Please enter correct Email Address!!",
        });
      } else if (trimmedEmail !== "") {
        const isToEmailDuplicate = ToEmailList.includes(trimmedEmail);
        const isCcEmailDuplicate = CcEmailList.includes(trimmedEmail);

        if (!isToEmailDuplicate && !isCcEmailDuplicate) {
          setToEmailList(
            isToMail ? [...ToEmailList, trimmedEmail] : ToEmailList
          );
          setCcEmailList(
            isToMail ? CcEmailList : [...CcEmailList, trimmedEmail]
          );
        } else {
          setErrorMsg({
            ...ErrorMsg,
            [errorType]: true,
            [errMsgType]: "Email Address already exists in the list!!",
          });
        }
      }

      isToMail ? setToEmail("") : setCcEmail("")
    }
  };

  const handleRemoveEmail = (index, Action) => {
    if (Action === "TO") {
      const newEmails = [...ToEmailList];
      newEmails.splice(index, 1);
      setToEmailList(newEmails);
    } else {
      const newEmails = [...CcEmailList];
      newEmails.splice(index, 1);
      setCcEmailList(newEmails);
    }
  };


  useEffect(() => {
    // ProcessAttendanceData(AttendanceDate);
    GetDailyAttendanceData(AttendanceDate);
    GetDailyAttendanceStats(AttendanceDate);
  }, []);

  const ProcessAttendanceData = async (DATE) => {
    setIsLoading((prev)=>{
      return {
         ...prev,
         ProcessLoading: true,
       }
     });
    try {
      const response = await authApi.get(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/ProcessDailyAttendance?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Attendancedate=${moment(DATE).format('YYYY-MM-DD')}`);
      console.log("Process Attendance Response", response);
      GetDailyAttendanceData(DATE);
      GetDailyAttendanceStats(DATE);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading((prev)=>{
        return {
           ...prev,
           ProcessLoading: false,
         }
       });
    }
  };

  const GetDailyAttendanceData = async (DATE) => {
    setIsLoading((prev)=>{
     return {
        ...prev,
        StatsLoading: true,
      }
    });
    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Attendance/GetFinalAttendanceView?Attendancedate=${moment(DATE).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Get Daily Attendance Response", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading((prev)=>{
        return {
           ...prev,
           StatsLoading: false,
         }
       });
    }
  };

  const GetDailyAttendanceStats = async (DATE) => {
    setIsLoading((prev)=>{
      return {
         ...prev,
         AttendanceData: true,
       }
     });

    try {
      const response = await authApi.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Attendance/GetDashBoardData?Attendancedate=${DATE.toLocaleDateString(
          "en-CA"
        )}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log(" Daily Attendance Stats Response", response);
      setAttendanceStats(response.data);
    } catch (error) {
      console.error(error);
    } finally{
      setIsLoading((prev)=>{
        return {
           ...prev,
           AttendanceData: false,
         }
       });
    }
  };


  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  function convertToSeconds(hours, minutes) {
    return Number(hours) * 60 * 60 + Number(minutes) * 60;
  }

  const convertToTimeSpan = (time) => {
    let getTime = time;
    //  console.log("Get time",getTime);
    const [hours, minutes] = getTime.split(":");
    //  console.log("Hours",hours);
    //  console.log("Minutes",minutes);
    let seconds = convertToSeconds(hours, minutes);
    //  console.log("Secconds",seconds);
    let ConvertedTime = convertHMS(seconds);
    //  console.log("Converted Time",ConvertedTime);
    return ConvertedTime;
  };

  // console.log("Sel Emp",SelectedEmp,AttInTime,AttOutTime);

  const SaveEditAttendance = async (e) => {
    e.preventDefault();

    let body = {
      empGuId: SelectedEmp.empGuId,
      empName: SelectedEmp.empName,
      empCode: SelectedEmp.empCode,
      attendanceDate: SelectedEmp.attendanceDate,
      inTime: convertToTimeSpan(AttInTime || "00:00:00") ,
      outTime: convertToTimeSpan(AttOutTime || "00:00:00"),
      attendanceStatus: AttAction === "Mark Present" ? "P" : AttAction === "Mark LWP" ? "LWP" : AttAction === "Mark LOP" ? "LOP" : AttAction === "Mark Half Day" ? "HD" : '',
      linkDevice: SelectedEmp.linkDevice,
      remark: EditAttRemarks + '*',
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      att_statusType: AttAction === "Mark Present" ? "Present" : AttAction === "Mark LWP" ? "LWP" : AttAction === "Mark LOP" ? "LOP" : AttAction === "Mark Half Day" ? "Half Day" : 'Absent',
      lock: true,
      designation: SelectedEmp.designation,
      department: SelectedEmp.department
    };

    console.log("Edit Body", body);

    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/UpdateLockedFinalAttandance`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetDailyAttendanceData(AttendanceDate);
        GetDailyAttendanceStats(AttendanceDate);
        // ProcessAttendanceData(AttendanceDate);

        setAttInTime("");
        setAttOutTime("");
        setIsLateAttach(false);
        onClose();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Employee Attendance Not Updated",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const exportData = () => {
    // let SelMonthName = MonthNames[new Date(SelectedDateMonth).getMonth()]
    const params = {
      fileName: `Attendance Report of ${userData.activeBranchName} ${moment(AttendanceDate).format("YYYY-MM-DD")}.csv`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  };

  const selectedKeys = ['empCode', 'empName','designationName','attendanceStatus','att_statusType','inTime','outTime','attendanceDate','linkDevice','remark'];

  const convertJsonToExcel = (json, keys) => {
    const header = keys.join(',');
    const csvData = json.map(item => keys.map(key => item[key]).join(',') ).join('\n');

    const csvContent = `${header}\n${csvData}`;
    const blob = new Blob([csvContent], { type: 'text/csv' });

    return blob;
  };

  const ShareAttendanceReport = async () => {
    setShareAttLoading(true);
    // if(rowData?.filter(data => data.attendanceStatus === "A")?.length > 0){
    const excelBlob = convertJsonToExcel(rowData?.filter(data => data.attendanceStatus === "A"),selectedKeys)
     
    const CampaignData = new FormData();

    // console.log("grid Data",CampaignData,excelBlob);

    // CampaignData.append("emailSchedulerId", 0);
    // CampaignData.append("emailSchedulerName", `Attendance Report of ${userData.activeBranchName}, ${moment(AttendanceDate).format("DD-MMM-YYYY")}`);
    // CampaignData.append("startDate", moment().format("YYYY-MM-DD"));
    // CampaignData.append("startTime", moment().format("HH:mm:ss"));
    // CampaignData.append("endDate", moment().add(1,'day').format("YYYY-MM-DD"));
    // CampaignData.append("frequencyId", 1);
    // CampaignData.append("frequencyName", String('test'));
    CampaignData.append("attachmentFile", excelBlob, `Attendance Report of ${userData.companyName} - ${userData.activeBranchName}, ${moment(AttendanceDate).format("DD-MMM-YYYY")}.csv`);
    CampaignData.append("attachmentPath", null);
    CampaignData.append("toEmail", ToEmailList?.join(","));
    CampaignData.append("ccEmail", CcEmailList?.join(","));
    CampaignData.append("emailSubject", `Attendance Report of ${userData.companyName} - ${userData.activeBranchName}, ${moment(AttendanceDate).format("DD-MMM-YYYY")}`);
    CampaignData.append("emailBody", `<h1>Attendance Report of ${userData.companyName} - ${userData.activeBranchName}, ${moment(AttendanceDate).format("DD-MMM-YYYY")}</h1>`);
    CampaignData.append("emailFrom", 'test@gmail.com');
    CampaignData.append("isActive", true);
    CampaignData.append("companyId", userData?.companyId);
    CampaignData.append("branchId", userData?.isActiveBranch);
    CampaignData.append("createdBy", userData?.userId);
    CampaignData.append("createdOn", moment().format("YYYY-MM-DD"));

    await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/EmailScheduler/SendDirectEmail`,CampaignData,{
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then((response)=>{
      console.log(response)
      if (!toast.isActive(id)) {
        toast({
          id,
          title: response.data,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      onEmailAttClose();
      setToEmailList([]);
      setCcEmailList([]);
    }).catch((err)=>{
      console.error(err);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: err.message,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    }).finally(()=>{
      setShareAttLoading(false);
    })

  // }else {
  //   alert(`No Employee is absent today`);
  // }

  }

  console.log("Lock status",LockAtt);

  return (
    <div>
      <Tabs colorScheme="purple" size={'sm'} variant={'solid-rounded'}>
  <TabList>
    <Tab>Day Attendance</Tab>
    {FetchedAttendanceSettings?.isAttendanceEditable && <Tab>Employee Attendance</Tab>}
  </TabList>
  <TabPanels>
    <TabPanel px={0}>
     <div className="flex items-center gap-4 justify-end mb-4">
          <label className="font-semibold text-lg">
            Attendance Date:
          </label>
          <div>
            <DatePicker 
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {
                setAttendanceDate(date);
                // ProcessAttendanceData(date);
                GetDailyAttendanceData(date);
                GetDailyAttendanceStats(date);
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Attendance Date"
              minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
              maxDate={new Date()}
            />
          </div>
          <Button 
            isLoading={IsLoading?.ProcessLoading}
            loadingText={'Processing...'}
            onClick={()=>{
               ProcessAttendanceData(AttendanceDate);
            }} 
            leftIcon={<RefreshCw size={'16px'} />} 
            colorScheme='purple' 
          >Process</Button>
        </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-6 gap-6">
       <StatCard IsLoading={IsLoading?.StatsLoading} StatName = "Total Employees" StatNumber = {AttendanceStats.totalEmpCount} Description = {'-'} IconBg = {"green.100"} StatIcon ={<LuUsers className="text-green-600 text-2xl"/>} />
       <StatCard IsLoading={IsLoading?.StatsLoading} StatName = "Total Present" StatNumber = {AttendanceStats.totalPresent} Description = {'-'} IconBg = {"orange.100"} StatIcon ={<LuUser className="text-orange-600 text-2xl"/>} />
       <StatCard IsLoading={IsLoading?.StatsLoading} StatName = "Total Leaves" StatNumber = {AttendanceStats.totalLeave} Description = {'-'} IconBg = {"purple.100"} StatIcon ={<LuCalendarX className="text-purple-600 text-2xl"/>} />
       <StatCard IsLoading={IsLoading?.StatsLoading} StatName = "Total Absent" StatNumber = {AttendanceStats.totalAbsent} Description = {'-'} IconBg = {"red.100"} StatIcon ={<LuUserX className="text-red-600 text-2xl"/>} />
      </div>

      {IsLoading?.AttendanceData ? ( 
       <Center>
         <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
       </Center> ) : (
        <>

          <div className="space-x-4 text-right mb-4">
            <Button 
              isDisabled={LockAtt}
              isLoading={IsLocking} 
              loadingText={"Locking..."} 
              leftIcon={<Lock size={'16px'} />} 
              colorScheme='green' 
              variant='solid' 
              onClick={()=>{
               if(window.confirm('Are you sure you want to Lock Attendance?')){
                localStorage.setItem('isLocedAtt',true);
                setIsLocking(true);
                setLockAtt.toggle();
                setTimeout(() => {
                  setIsLocking(false);
                }, 2000); 
               }
              }} 
            >{!LockAtt ? "Lock" : "Locked"}</Button>
            <Button
              isDisabled={rowData?.length === 0}
              leftIcon={<LuDownload/>}
              onClick={() => {
                exportData();
              }}
              colorScheme="purple"
            >
              Export
            </Button>
            {/* <Button 
             isDisabled={rowData?.filter(data => data.attendanceStatus === "A")?.length === 0} 
             leftIcon={<TbMailFast size={'22px'} />} 
             colorScheme="purple"
             onClick={() => {
              onEmailAttOpen();
             }}
            >Email Report</Button> */}
          </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          suppressExcelExport={true}
        />
      </div>

      </>
      )}
    </TabPanel>
    <TabPanel px={0}>
      <EmpEditAttendance/>
    </TabPanel>
  </TabPanels>
</Tabs>


      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >Edit Attendance</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
          <form onSubmit={SaveEditAttendance}>
            <ModalBody className="space-y-2 my-2">
              <div className="flex pb-1 gap-8">
                <div>
                  <p className="text-sm text-gray-500">Emp Code</p>
                  <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Emp Name</p>
                  <h6 className="font-medium">{SelectedEmp.empName}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Attendance Date</p>
                  <h6 className="font-medium">{moment(SelectedEmp.attendanceDate).format('DD/MM/YYYY')}</h6>
                </div>
              </div>
              <hr />

              {IsLateAttach ? (
                <div>
                  <div>
                    <label>Attachment</label>
                    <Input type='file' borderColor='gray.400' />
                  </div>

                </div>
              ):(
                <>


              <div className="grid grid-cols-2 gap-4">

        <div onClick={()=>{setAttAction("Mark Present")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark Present" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark Present</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark LWP")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark LWP" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark LWP</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark LOP")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark LOP" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark LOP</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark Half Day")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark Half Day" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark Half Day</div>
            </div>
        </div>
              </div>

              {AttAction === "Mark Present" ? 
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label>In-Time</label>
              <Input
                value={AttInTime || ""}
                onChange={({ target }) => {
                  setAttInTime(target.value);
                }}
                borderColor={"gray.400"}
                type={"time"}
              />
            </div>
            <div className="space-y-2">
              <label>Out-Time</label>
              <Input
                value={AttOutTime || ""}
                onChange={({ target }) => {
                  setAttOutTime(target.value);
                }}
                borderColor={"gray.400"}
                type={"time"}
              />
            </div>
          </div> 
        : '' }

        <div className="space-y-2">
          <label>Reason</label>
          <Input isRequired value={EditAttRemarks || ''} onChange={({target})=>{setEditAttRemarks(target.value)}} borderColor={'gray.400'} placeholder="Enter Reason" />
        </div>

        </>
              )}

            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                variant={"outline"}
                colorScheme="purple"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              <Button type="submit" colorScheme={"purple"} variant="solid">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


      <Modal size={'lg'} isOpen={isEmailAttOpen} onClose={()=>{
        onEmailAttClose();
        setToEmailList([]);
        setCcEmailList([]);
      }}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >Email Recipients</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
            <ModalBody className="space-y-2">
            <div className="space-y-2">
                <label>To</label>
                <Input
                  className=""
                  isInvalid={ErrorMsg?.ToEmailError || ""}
                  value={ToEmail || ""}
                  onChange={({ target }) => {
                    setToEmail(target.value);
                  }}
                  onKeyDown={handleEmailInputKeyDown}
                  name="TOMAIL"
                  type="email"
                  borderColor={"gray.400"}
                  placeholder="To"
                />
              </div>
              {ErrorMsg?.ToEmailError && (
                <span className="text-xs text-red-500 font-medium">
                  {ErrorMsg?.ToErrMsg}
                </span>
              )}

              <div className="flex flex-wrap gap-2">
                {ToEmailList.map((email, index) => (
                  <Tag
                    size={"md"}
                    key={index}
                    borderRadius="full"
                    variant="subtle"
                    colorScheme="blue"
                  >
                    <TagLabel>{email}</TagLabel>
                    <TagCloseButton
                      onClick={() => {
                        handleRemoveEmail(index, "TO");
                      }}
                    />
                  </Tag>
                ))}
              </div>

              <div className="space-y-2">
                <label>Cc</label>
                <Input
                  isInvalid={ErrorMsg?.CcEmailError || ""}
                  value={CcEmail || ""}
                  onChange={({ target }) => {
                    setCcEmail(target.value);
                  }}
                  onKeyDown={handleEmailInputKeyDown}
                  name="CCMAIL"
                  type="email"
                  borderColor={"gray.400"}
                  placeholder="Cc"
                />

              </div>
              {ErrorMsg?.CcEmailError && (
                <span className="text-xs text-red-500 font-medium">
                  {ErrorMsg?.CcErrMsg}
                </span>
              )}

              <div className="flex flex-wrap gap-2">
                {CcEmailList.map((email, index) => (
                  <Tag
                    size={"md"}
                    key={index}
                    borderRadius="full"
                    variant="subtle"
                    colorScheme="orange"
                  >
                    <TagLabel>{email}</TagLabel>
                    <TagCloseButton
                      onClick={() => {
                        handleRemoveEmail(index, "CC");
                      }}
                    />
                  </Tag>
                ))}
              </div>

            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                variant={"outline"}
                colorScheme="purple"
                mr={3}
                onClick={()=>{
                  onEmailAttClose();
                  setToEmailList([]);
                  setCcEmailList([]);
                }}
              >
                Close
              </Button>
              <Button             
               leftIcon={<LuSend/>}
               isLoading={ShareAttLoading} 
               loadingText="Sending..."  
               isDisabled={ToEmailList.length === 0} 
               onClick={()=>{
                ShareAttendanceReport();
               }} 
               colorScheme={"purple"} 
               variant="solid"
              >
                Send
              </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default ViewAttendance;
