import React, { useCallback, useMemo, useState, } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button, useDisclosure, Select, ModalFooter, Checkbox, Input, ModalBody, ModalCloseButton, ModalHeader, ModalContent, ModalOverlay, Modal, InputGroup, InputRightAddon, Switch, FormControl, FormLabel, Center, Spinner, } from "@chakra-ui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertCircle, Edit, Settings } from "react-feather";
import { useAuth } from "../Utils/AuthContext";
import useFetch from "../Utils/useFetch";
import usePost from "../Utils/usePost";
import authApi from "../Utils/AuthApis";

function ComponentMaster()  {
    const {userData} = useAuth();
    const { IsFetchLoading: IsBranchCompListLoading, FetchedData: BranchCompList, Refetch: RefetchBranchCompList} = useFetch('/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList',{ CompanyId: userData.companyId, BranchId: userData.isActiveBranch });
    const { FetchedData : ComponentTypeList } = useFetch('/api/MasterComponent/GetComponentType');
    const { FetchedData : FixedComponentList } = useFetch('/api/MasterComponent/GetMasterComponentList',{BranchId: userData.isActiveBranch});
    const { IsPostLoading: IsPostBranchCompLoading, PostData: PostBranchCompData} = usePost('/api/BranchSalaryStructureMaster/SaveBranchSalaryStructureMaster');
    const { IsPostLoading: IsPostHRAConfigLoading, PostData: PostHRAConfigData} = usePost('/api/EmployeePFESICSetting/SaveHRAConfiguration');
    const { IsPostLoading: IsPostAddCompConfigLoading, PostData: PostAddCompConfigData} = usePost('/api/SalaryComponentConfig/SaveSalaryComponentConfiguration');

    const [MasterCompDetails, setMasterCompDetails] = useState({
      branchSalaryStructureMasterId: 0,
      componentType: "",
      componentTypeId: 0,
      componentTypeName: "",
      componentId: 0,
      displayLabel: "",
      salarySlipLabel: "",
      isActive: true,
      isEmpComponent: false,
      calculationTypeId: 0,
      isViewInSalarySlip: false,
      isEditComp:false,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    });

  const [HRAConfigDetails, setHRAConfigDetails] = useState({
      hraConfigurationId: 0,
      hraApplied: false,
      hraForMetroCity: 0,
      hraForNonMetroCity: 0,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
   });


  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const {
      isOpen: isAddComponentOpen,
      onOpen: onAddComponentOpen,
      onClose: onAddComponentClose,
    } = useDisclosure();

    const {
      isOpen: isConfigureComponentOpen,
      onOpen: onConfigureComponentOpen,
      onClose: onConfigureComponentClose,
    } = useDisclosure();

    const {
      isOpen: isHRAConfigurationOpen,
      onOpen: onHRAConfigurationOpen,
      onClose: onHRAConfigurationClose,
    } = useDisclosure();

    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        minWidth: 150,
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        cacheQuickFilter: true,
      };
    }, []);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);

    const [McolumnDefs] = useState([
      { headerName: "Salary Label", field: "displayLabel" },
      { headerName: "Salary Slip Label", field: "salarySlipLabel" },
      { headerName: "Salary Type", field: "componentTypeName" },
      {
        headerName: "Status",
        field: "isActive",
        floatingFilter: false,
        cellRenderer: ({data}) => <Switch 
          isChecked={data.isActive} 
          onChange={({target})=>{
            handleBranchStructureSwitch(target.checked,data)
          }} 
          colorScheme='green' />
      },
      {
        headerName: "Action",
        floatingFilter: false,
        cellRenderer: ({data}) => (
          <div className="space-x-4">
            <Button onClick={() => {
                setMasterCompDetails({
                  ...data,
                  isEditComp:true
                });
                onAddComponentOpen();
              }} size={'xs'} colorScheme={'blue'}><Edit size={'12px'} className='mr-1' />Edit</Button>

          {data.componentTypeId === 4 ? 
            <Button onClick={() => {
                setMasterCompDetails({
                  ...data,
                  isEditComp: false
                });
                onConfigureComponentOpen();
              }}
           size={'xs'} variant={'outline'} leftIcon={<Settings size={'12px'} />} colorScheme={'blue'}>Configure</Button> : ''}


            {data.componentId === 2 && <Button 
            onClick={async()=>{
              try {
                const response = await authApi.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetHRAConfiguration?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Hra Configuration",response);
                const res = response.data;
                setHRAConfigDetails(res)
                onHRAConfigurationOpen();
              } catch (err) {
                console.error(err);
              }
            }} size={'xs'} variant={'outline'} leftIcon={<Settings size={'12px'} />} colorScheme={'blue'}>Configure HRA</Button>}
            </div>
        ),
      },
    ]);
  

    const handleBranchStructureSwitch = async (CheckedStatus,ComponentData) => {

      let body ={
        branchSalaryStructureMasterId : ComponentData.branchSalaryStructureMasterId,
        componentType : ComponentData.componentType,
        componentTypeId : ComponentData.componentTypeId,
        componentTypeName : ComponentData.componentTypeName,
        componentId : ComponentData.componentId,
        displayLabel : ComponentData.displayLabel,
        salarySlipLabel : ComponentData.salarySlipLabel,
        isActive : CheckedStatus,
        isEmpComponent : ComponentData.isEmpComponent,
        companyId : ComponentData.companyId,
        branchId : ComponentData.branchId
      }
 
      console.log("Switch Save Body",body);

      const response = await PostBranchCompData(body);
      console.log("response",response);
      if(response){
        RefetchBranchCompList();
      }
    }

    const handleBranchSalaryComponent = async (e) => {
      e.preventDefault();

      let body = {
        branchSalaryStructureMasterId: MasterCompDetails?.branchSalaryStructureMasterId,
        componentType: String(MasterCompDetails?.componentTypeId),
        displayLabel: MasterCompDetails?.displayLabel,
        salarySlipLabel: MasterCompDetails?.salarySlipLabel,
        isActive: MasterCompDetails?.isActive,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      };
    
      console.log("Component Save Body",body);

      const response = await PostBranchCompData(body);

      if(response){
        RefetchBranchCompList();
        if(MasterCompDetails?.isEditComp){
          onAddComponentClose();
        }
        setMasterCompDetails({
          branchSalaryStructureMasterId: 0,
          componentType: "",
          componentTypeId: 0,
          componentTypeName: "",
          componentId: 0,
          displayLabel: "",
          salarySlipLabel: "",
          isActive: true,
          isEmpComponent: false,
          calculationTypeId: 0,
          isViewInSalarySlip: false,
          isEditComp:false,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch
        });
      }

    };
     

    const SaveComponentConfiguration = async (e) => {
      e.preventDefault();
      let body = {
        componentTypeId : MasterCompDetails?.componentTypeId,
        componentId : MasterCompDetails?.branchSalaryStructureMasterId,
        componentName : MasterCompDetails?.displayLabel,
        calculationTypeId : MasterCompDetails?.calculationTypeId,
        isViewInSalarySlip : MasterCompDetails?.isViewInSalarySlip,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch,
      }

      console.log("Additional Config Save Body",body);

      const response = await PostAddCompConfigData(body);

      if(response){
        RefetchBranchCompList();
        setMasterCompDetails({
          branchSalaryStructureMasterId: 0,
          componentType: "",
          componentTypeId: 0,
          componentTypeName: "",
          componentId: 0,
          displayLabel: "",
          salarySlipLabel: "",
          isActive: true,
          isEmpComponent: false,
          calculationTypeId: 0,
          isViewInSalarySlip: false,
          isEditComp:false,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch
        });
        onConfigureComponentClose();
      }
    }


    const SaveHraConfiguration = async (e) => {
      e.preventDefault();
      
      let body = {
        hraApplied : HRAConfigDetails?.hraApplied,
        hraForMetroCity : HRAConfigDetails?.hraForMetroCity,
        hraForNonMetroCity : HRAConfigDetails?.hraForNonMetroCity,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch
      }

      console.log("HRA Configure Body",body);

      const response = await PostHRAConfigData(body);
      if(response){
        onHRAConfigurationClose();
        setHRAConfigDetails({
          hraConfigurationId: 0,
          hraApplied: false,
          hraForMetroCity: 0,
          hraForNonMetroCity: 0,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch
        });
      }
    }
    

    return (
      <div>
        <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-2 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">
            Branch Salary Structure
          </h1>
          <div>
            <Button onClick={onAddComponentOpen} size={'sm'} colorScheme="purple">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Salary Component
            </Button>
          </div>
        </div>
  
        {IsBranchCompListLoading ? <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> : <div className="ag-theme-alpine mb-6">
          <AgGridReact
            style={gridStyle}
            rowData={BranchCompList} // Row Data for Rows
            columnDefs={McolumnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional- set to 'true'
            pagination={true}
            domLayout={"autoHeight"}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>}
  
  
        <Modal size={'xl'} closeOnOverlayClick={true} isOpen={isAddComponentOpen} onClose={()=>{
          setMasterCompDetails({
            branchSalaryStructureMasterId: 0,
            componentType: "",
            componentTypeId: 0,
            componentTypeName: "",
            componentId: 0,
            displayLabel: "",
            salarySlipLabel: "",
            isActive: true,
            isEmpComponent: false,
            calculationTypeId: 0,
            isViewInSalarySlip: false,
            isEditComp:false,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch
          });
          onAddComponentClose();
        }}>
          <ModalOverlay />
          <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >{MasterCompDetails?.isEditComp ? "Edit" : "Add"} Salary Component</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
            <form onSubmit={handleBranchSalaryComponent}>
              <ModalBody>
                <div className="space-y-6 py-4">

                  {!MasterCompDetails.isEditComp && <div className=" flex items-center gap-4 space-y-2">
                    <label>Default Components:</label>
                    <div className="space-x-4">
                      {FixedComponentList?.map((data)=>{
                        return <Button  
                                isLoading={IsPostBranchCompLoading}
                                isDisabled={BranchCompList.some(bdata => bdata.componentId === data.id)}
                                key={data.id} 
                                onClick={async()=>{
                                  let body = {
                                        componentType: data.componentType,
                                        displayLabel: data.name,
                                        salarySlipLabel: data.name,
                                        componentId:data.id,
                                        isActive: true,
                                        companyId: userData.companyId,
                                        branchId: userData.isActiveBranch,
                                      }
                                      console.log("Fixed Comp Save Body",body);
                                   const response = await PostBranchCompData(body);
                                   console.log("Fix Save Response",response);
                                   if(response){
                                    RefetchBranchCompList();
                                   }
                                }}
                                size={'sm'} 
                                variant={'outline'} 
                                colorScheme="blue" 
                                rounded={'full'}>{data.name}</Button>
                      })}
                    </div>
                  </div>}

                  <FormControl variant={'floating'} isRequired>
                    <Input
                      borderColor="gray.400"
                      value={MasterCompDetails?.displayLabel || ""}
                      onChange={({target}) => {
                        setMasterCompDetails({...MasterCompDetails,displayLabel: target.value});
                      }}
                      placeholder=""
                    />
                    <FormLabel>Component Name</FormLabel>
                  </FormControl>

                  <FormControl variant={'floating'} isRequired>
                    <Input
                      borderColor="gray.400"
                      value={MasterCompDetails?.salarySlipLabel || ""}
                      onChange={({target}) => {
                        setMasterCompDetails({...MasterCompDetails, salarySlipLabel: target.value});
                      }}
                      placeholder=""
                    />
                    <FormLabel>Salary Slip Name</FormLabel>
                  </FormControl>

                  <FormControl variant={'floating'} isRequired>
                    <Select
                      borderColor="gray.400"
                      value={MasterCompDetails?.componentTypeId || ""}
                      onChange={({target}) => {
                        setMasterCompDetails({...MasterCompDetails, componentTypeId: target.value});
                      }}
                      placeholder="Select Component Type"
                    >
                      {ComponentTypeList.filter(filterdata => filterdata.isActive===  true).map((data,i)=>{
                        return <option key={i} value={data.id}>{data.componentTypeName}</option>
                      })}
                    </Select>
                    <FormLabel>Component Type</FormLabel>
                  </FormControl>
                  
                </div>
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button isLoading={IsPostBranchCompLoading} loadingText="Saving..." type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={()=>{
                      setMasterCompDetails({
                        branchSalaryStructureMasterId: 0,
                        componentType: "",
                        componentTypeId: 0,
                        componentTypeName: "",
                        componentId: 0,
                        displayLabel: "",
                        salarySlipLabel: "",
                        isActive: true,
                        isEmpComponent: false,
                        calculationTypeId: 0,
                        isViewInSalarySlip: false,
                        isEditComp:false,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch
                      });
                      onAddComponentClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>


        <Modal  isOpen={isConfigureComponentOpen} onClose={()=>{
            setMasterCompDetails({
              branchSalaryStructureMasterId: 0,
              componentType: "",
              componentTypeId: 0,
              componentTypeName: "",
              componentId: 0,
              displayLabel: "",
              salarySlipLabel: "",
              isActive: true,
              isEmpComponent: false,
              calculationTypeId: 0,
              isViewInSalarySlip: false,
              isEditComp: false,
              companyId: userData.companyId,
              branchId: userData.isActiveBranch
            });
            onConfigureComponentClose();
        }}>
          <ModalOverlay />
          <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >Configure Component</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
            <form onSubmit={SaveComponentConfiguration}>
              <ModalBody>
                <div className="space-y-4">

                <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Component Name</p>
                <h6 className="font-medium">{MasterCompDetails?.displayLabel}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Component Type</p>
                <h6 className="font-medium">{MasterCompDetails?.componentTypeName}</h6>
              </div>
            </div>

                <div className="space-y-2">
  <div className="flex justify-between items-center">
   <label>Calculation Type</label>
   <Checkbox
    isChecked={MasterCompDetails?.isViewInSalarySlip}
    onChange={({target}) => {
      setMasterCompDetails({...MasterCompDetails, isViewInSalarySlip: target.checked})
    }}
    borderColor="gray.400"
  >
    View in Salary Slip?
  </Checkbox>
  </div>
  <Select
    borderColor="gray"
    value={MasterCompDetails?.calculationTypeId || ""}
    onChange={({target}) => {
      setMasterCompDetails({...MasterCompDetails,calculationTypeId: target.value})
    }}
    placeholder="Select Calculation Type"
  >
    <option value='1'>Fix & Re-Accuring</option>
    <option value='2'>Not-Fix & Re-Accuring</option>
    <option value='3'>Fix & Non Re-Accuring</option>
    <option value='4'>Not-Fix & Non Re-Accuring</option>
  </Select>
</div>



  {MasterCompDetails?.calculationTypeId === '1' || MasterCompDetails?.calculationTypeId === 1 ?
  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is fix as per Salary Structure & Same amount will given Every Month!</div>
  : MasterCompDetails?.calculationTypeId === '2' || MasterCompDetails?.calculationTypeId === 2 ?
  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is variable or can change every month but maximum of salary structure & amount given every month</div>
  : MasterCompDetails?.calculationTypeId === '3' || MasterCompDetails?.calculationTypeId === 3 ?
  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is fix as per Salary Structure & Same amount will given once in a year</div>
  : MasterCompDetails?.calculationTypeId === '4' || MasterCompDetails?.calculationTypeId === 4 ?
  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is variable or can change every month but maximum of salary structure & same amount will given once in a year</div> : ''}

</div>
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button isLoading={IsPostAddCompConfigLoading} loadingText="Saving..." type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={()=>{
                      setMasterCompDetails({
                        branchSalaryStructureMasterId: 0,
                        componentType: "",
                        componentTypeId: 0,
                        componentTypeName: "",
                        componentId: 0,
                        displayLabel: "",
                        salarySlipLabel: "",
                        isActive: true,
                        isEmpComponent: false,
                        calculationTypeId: 0,
                        isViewInSalarySlip: false,
                        isEditComp: false,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch
                      });
                      onConfigureComponentClose();
                  }}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>


        <Modal  isOpen={isHRAConfigurationOpen} onClose={()=>{
          onHRAConfigurationClose();
          setHRAConfigDetails({
            hraConfigurationId: 0,
            hraApplied: false,
            hraForMetroCity: 0,
            hraForNonMetroCity: 0,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch
          });
        }}>
          <ModalOverlay />
          <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >HRA Configuration</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
            <hr />
            <form onSubmit={SaveHraConfiguration}>
              <ModalBody>
                <div className="space-y-4">
                  <Checkbox isChecked={HRAConfigDetails?.hraApplied} onChange={({target})=>{setHRAConfigDetails({...HRAConfigDetails,hraApplied:target.checked,hraForMetroCity: 0,hraForNonMetroCity: 0})}} borderColor={'gray.400'}>Is HRA Rule Applicable?</Checkbox>
                  <div className="space-y-2">
                    <label>For Metro Cities</label>
                    <InputGroup>
                      <Input isDisabled={!HRAConfigDetails?.hraApplied} isRequired={HRAConfigDetails?.hraApplied} value={HRAConfigDetails?.hraForMetroCity || ''} onChange={({target})=>{setHRAConfigDetails({...HRAConfigDetails, hraForMetroCity: target.value})}} type="number" borderColor={'gray.400'} placeholder='Metro Cities' />
                      <InputRightAddon borderColor={'gray.400'} children='%' />
                    </InputGroup>
                  </div>
                  <div className="space-y-2">
                    <label>For Non Metro Cities</label>
                    <InputGroup>
                      <Input isDisabled={!HRAConfigDetails?.hraApplied} isRequired={HRAConfigDetails?.hraApplied} value={HRAConfigDetails?.hraForNonMetroCity || ''} onChange={({target})=>{setHRAConfigDetails({...HRAConfigDetails, hraForNonMetroCity: target.value})}} type="number" borderColor={'gray.400'} placeholder='Non Metro Cities' />
                      <InputRightAddon borderColor={'gray.400'} children='%' />
                    </InputGroup>
                  </div>
                </div>

              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button isLoading={IsPostHRAConfigLoading} loadingText="Saving..." type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={()=>{
                      onHRAConfigurationClose();
                      setHRAConfigDetails({
                        hraConfigurationId: 0,
                        hraApplied: false,
                        hraForMetroCity: 0,
                        hraForNonMetroCity: 0,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch
                      });
                    }}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

      </div>
    );
  }

export default ComponentMaster