import { Button, useToast } from '@chakra-ui/react';
import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import moment from 'moment';
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';

function ReturnRequests() {

    const { userData } = useAuth();
    const toast = useToast();
    const id = "toast";

    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

    const [ReturnRowData, setReturnRowData] = useState([]);

    const [ReturnColumnDefs, setReturnColumnDefs] = useState([
        { headerName: "Emp Code", field: "empCode" },
        { headerName: "Emp Name", field: "empName" },
        { headerName: "Return Date", field: "returnDate", cellRenderer: (params) => <span>{moment(params.data.returnDate).format('DD/MM/YYYY')}</span> },
        { headerName: "Return Amount", field: "returnAmount" },
        { headerName: "Return Method", field: "returnMethod" },
        { headerName: "Return Remarks", field: "returnInfo" },
        { headerName: "Receiving Status", field: "returnStatus",
        cellRenderer: (params) => (
          params.data.returnStatus === "Receiving Pending"?
          <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Receiving Pending
        </span>
         : params.data.returnStatus === 'Received'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
         Received
        </span> : ''
        ),
       },
       { headerName: "Action",
       cellRenderer: (params) =>
         params.data.returnStatus === "Receiving Pending"? <Button onClick={async()=>{

            let ReturnRow = params.data;

            let body ={
                    returnDetailsId : ReturnRow.returnDetailsId,
                    empGuId : ReturnRow.empGuId,
                    empCode : ReturnRow.empCode,
                    empName : ReturnRow.empName,
                    returnDate : ReturnRow.returnDate,
                    returnMonth : ReturnRow.returnMonth,
                    returnYear : ReturnRow.returnYear,
                    returnMethod : ReturnRow.returnMethod,
                    returnAmount : ReturnRow.returnAmount,
                    returnInfo : ReturnRow.returnInfo,
                    companyId : ReturnRow.companyId,
                    branchId : ReturnRow.branchId,
                    returnStatus : "Received",
                    actionByGuId : userData.guId
                }

                console.log("Return Received Save Body",body);


                await authApi.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashReturnDetails`,body
                )
                .then((response) => {
                  console.log(response);
                  const res = response.data;
                  //console.log("res",res);
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title:res,
                      position: 'top',
                      status: 'info',
                      duration: 2000,
                      isClosable: true,
                    })
                  }      

                  GetReturnRequestsData();
      
                })
                .catch((error) => {
                  console.log(error.response);
      
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: 'ERROR',
                      description: "Return Receiving not Updated",
                      position: 'top',
                      status: 'error',
                      duration: 2000,
                      isClosable: true,
                    })
                  }
                });

         }} colorScheme={'blue'} variant='solid' size={'xs'} >Return Received</Button>: ''
      }
     ]);

     // Table Pagination
     const paginationNumberFormatter = useCallback((params) => {
       return "[" + params.value.toLocaleString() + "]";
     }, []);
    
     //Table Columns Properties Applies to All Columns
     const defaultColDef = useMemo(() => ({
       flex: 1,
       sortable: true,
       filter: "agTextColumnFilter",
       floatingFilter: true,
       cacheQuickFilter: true,
       cellClass: 'no-border',
     }));


     useEffect(()=>{
        GetReturnRequestsData();
     },[])



     const GetReturnRequestsData = async () => {
        try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashReturnDetailsList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Return Data Response",response);
             const res = response.data;

            setReturnRowData(res);
          } catch (error) {
            console.error(error);
          }

      }

  return (
    <div>
      <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Return Requests</h1>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={ReturnRowData} // Row Data for Rows
          columnDefs={ReturnColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  )
}

export default ReturnRequests