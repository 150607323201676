import { Input, Select, InputGroup, ModalBody, ModalCloseButton, useToast, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay, Button, useDisclosure, Checkbox } from '@chakra-ui/react'
import React,{useState , useEffect} from 'react'
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileImport, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AddDesignation } from '../Masters/DesignationMaster';
import useFetch from '../Utils/useFetch';
import { useAuth } from '../Utils/AuthContext';
import { AddDepartment } from '../Masters/DepartmentMaster';
import { AddEmployeeCategory } from '../Masters/EmployeeCategory';
import useValidation from '../Utils/useValidation';
import usePost from '../Utils/usePost';
import { CreateEmployeeSchema, ImportEmpSchema } from '../Utils/FormValidations';
import { useRef } from 'react';
import authApi from '../Utils/AuthApis';

function CreateEmployee() {

  const { userData } = useAuth();
  const toast = useToast();
  const id = "toast";
  const Validation = useValidation();
  const navigate = useNavigate();

  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };


  const {FetchedData: DesignationList, Refetch: RefetchDesignationList} = useFetch('/api/Master/GetDesignationMasterList',{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  });

  const { FetchedData: DepartmentList, Refetch: RefetchDepartmentList } = useFetch(
    '/api/Master/DepartmentMasterList', {
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    }
  );

  const { FetchedData: EmployeeCategoryList, Refetch: RefetchEmployeeCategoryList } = useFetch(
    '/api/Master/GetEmployeeTypeMasterList', {
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    }
  );

  const { FetchedData: RoleList } = useFetch(
    '/api/RoleAndPermission/GetRoleMasterList', {
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    }
  );

  const { IsPostLoading: IsPostCreateEmpLoading, PostData: PostCreateEmpData } = usePost('/api/EmployeeDetail/SaveEmployeeGeneralInfo');
  const { PostData: PostImportEmpData } = usePost('/api/EmployeeDetail/ImportEmployeeGeneralInfo');
  
  const [FormErrors, setFormErrors] = useState({});
  const [CreateEmpFormDetails, setCreateEmpFormDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    empCode: '',
    dateofjoin:  '',
    designation: '',
    selectedDesignation: '',
    department: '',
    selectedDepartment: '',
    selfReporting: false,
    reportingOfficersId: '',
    selectedReporting:'',
    mobile: '',
    officeEmail: '',
    personalEmail: '',
    regulationDate:  '',
    probationStartDate: '',
    probationEndDate: '',
    empType: '',
    selectedEmpType: '',
    roleId: '',
    status: true,
    isActive: true,
    companyId: userData?.companyId,
    branchId: userData?.isActiveBranch
  })

  const [IsManualEmpCode, setIsManualEmpCode] = useState(false);
  const [ActivePlanDetails,setActivePlanDetails] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [EmployeesImportData, setEmployeesImportData] = useState([]);
  const { isOpen: isImportEmployeesOpen, onOpen: onImportEmployeesOpen, onClose: onImportEmployeesClose } = useDisclosure();

  const [EmpExcelHeads, setEmpExcelHeads] = useState([
    // { label: "title", key: `title`},
    { label: "firstName", key: `firstName`},
    { label: "middleName", key: `middleName`},
    { label: "lastName", key: `lastName`},
    { label: "gender", key: `gender`},
    { label: "empCode", key: `empCode`},
    { label: "dateofjoin", key: `dateofjoin`},
    { label: "designation", key: `designation`},
    { label: "department", key: `department`},
    { label: "division", key: `division`},
    { label: "grade", key: `grade`},
    // { label: "reportingOfficerName", key: `reportingOfficerName`},
    { label: "mobile", key: `mobile`},
    { label: "officeEmail", key: `officeEmail`},
    // { label: "regulationDate", key: `regulationDate`},
    // { label: "officeLocation", key: `officeLocation`},
    // { label: "probationType", key: `probationType`},
    // { label: "empTypeName", key: `empTypeName`}
  ])

useEffect(() => {
  UpdatePlanDetails();
  GetUserPlanDetails();
  getCodeMasterList();
  getEmpList();
}, [])

const UpdatePlanDetails = async () => {
  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Plan/UpdateSubscritpionData?CompanyId=${userData.companyId}`
    )
    console.log("Plan Update Res",response);
  }catch (err){
    console.error(err);
  }
}

const GetUserPlanDetails = async () => {
  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetUserSubscriptionByEmail?EmailId=${userData.userName}`
    )
    console.log("Active Plan Details",response);
    setActivePlanDetails(response.data);
  } catch (err) {
    console.error(err);
  }
}


console.log("Is Manual Code",IsManualEmpCode);

const getCodeMasterList = async () => {
  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCodeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    );
    console.log("Code Master List", response);

    const res = response.data;

    const isCodeMasterCreated = res.length ? false : true;


    if(isCodeMasterCreated){

      navigate('/code');

      toast({
        id,
        title:"Error Code Not Created",
        description : "Please Create Code Master Before Creating Employee!",
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }

    console.log("Code Master Created",isCodeMasterCreated);
    setIsManualEmpCode(res[0].isManual)
  } catch (error) {
    console.error(error);
  }
};



const getEmpList = async () => {
  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log(response);
    const res = response.data;
    setEmployeeList(res.filter((data)=> data.isActive === true));
  } catch (error) {
    console.error(error);
  }
}


const readExcel = (file)=>{

  const promise = new Promise((resolve,reject) => {
    
   const fileReader = new FileReader();
   fileReader.readAsArrayBuffer(file);

   fileReader.onload=(e)=>{
     const bufferArray = e.target.result;
     const wb = XLSX.read(bufferArray,{type:'buffer'});
     const wsname = wb.SheetNames[0];
     const ws = wb.Sheets[wsname];
     const data = XLSX.utils.sheet_to_json(ws, {raw: false});
     resolve(data)
   } 

   fileReader.onerror = (error) => {
     reject(error)
   }

  });

  promise.then((data)=>{
    console.log("Imported Data",data);

    let FinalData = data.map((obj)=>{
      return {...obj,
        companyId:userData.companyId,
        branchId:userData.isActiveBranch
      }
     })

   console.log("Final Data",FinalData);
   handleImportEmployeesData(FinalData);
  });

}

const handleImportEmployeesData = async (FinalData) =>{
  
console.log(FinalData);
let body = FinalData.map(obj =>{
             return { ...obj,
                      empCode : String(obj.empCode),
                      firstName : String(obj.firstName),
                      middleName : String(obj.middleName || ''),
                      lastName : String(obj.lastName),
                      gender : String(obj.gender),
                      dateofjoin : moment(obj.dateofjoin).format('YYYY-MM-DD'),
                      designation : String(obj.designation),
                      department : String(obj.department),
                      division: String(obj.division),
                      grade: String(obj.grade),
                      mobile : String(obj.mobile),
                      officeEmail : String(obj.officeEmail),
                    }
             })


console.log("Import Body",body)


ImportEmpSchema.validate(body,{abortEarly:false})
.then(async() => {

  const response = await PostImportEmpData(body);

  if(response){
    GetUserPlanDetails();
    setEmployeesImportData([]);
    onImportEmployeesClose();
  }


}).catch((validationErrors) => {
  const newErrors = {};
  validationErrors.inner.forEach((error) => {
    newErrors[error.path] = error.message;
  });

  console.log("Validation errors",newErrors);
  setFormErrors(newErrors);

  let errorMessage = '';

  Object.entries(newErrors).forEach(([field, message]) => {
    const row = field.match(/\d+/)[0]; // Extract numbers using regular expression
    const fieldName = field.split('.')[1]; // Extract field name
    errorMessage += `At row ${Number(row)+2} in ${fieldName}: ${message}\n`;
  });
   console.log("Errors",errorMessage);
  alert(errorMessage);
  reset();
});


}

const SaveCreateEmployee = async()=>{

  CreateEmployeeSchema.validate(CreateEmpFormDetails,{abortEarly:false})
  .then(async() => {

    let body = {
      ...CreateEmpFormDetails,
      dateofjoin: moment(CreateEmpFormDetails.dateofjoin).format('YYYY-MM-DD'),
      regulationDate: moment(CreateEmpFormDetails?.regulationDate).format('YYYY-MM-DD'),
      probationStartDate: moment(CreateEmpFormDetails?.probationStartDate).format('YYYY-MM-DD'),
      probationEndDate: moment(CreateEmpFormDetails?.probationEndDate).format('YYYY-MM-DD'),
      roleId: Number(CreateEmpFormDetails.roleId),
      designation: String(CreateEmpFormDetails.designation),
      department: String(CreateEmpFormDetails.department),
      empType: String(CreateEmpFormDetails.empType),
    }

  console.log("Create Emp Save Body",body);
  const response = await PostCreateEmpData(body);
  if(response){
    UpdatePlanDetails();
    GetUserPlanDetails();
    setCreateEmpFormDetails({
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      empCode: '',
      dateofjoin:  '',
      designation: '',
      selectedDesignation: '',
      department: '',
      selectedDepartment: '',
      reportingOfficersId: '',
      selectedReporting:'',
      mobile: '',
      officeEmail: '',
      personalEmail: '',
      regulationDate:  '',
      probationStartDate: '',
      probationEndDate: '',
      empType: '',
      selectedEmpType: '',
      roleId: '',
      status: true,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    })
  }

}).catch((validationErrors) => {
  const newErrors = {};
  validationErrors.inner.forEach((error) => {
    newErrors[error.path] = error.message;
  });
  setFormErrors(newErrors);
});
  
}



  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Create Employee</h1>
        {Number(ActivePlanDetails.currentUsers) < Number(ActivePlanDetails.uptoUsers) ? 
          <Button onClick={onImportEmployeesOpen} size={'sm'} colorScheme={'purple'}><FontAwesomeIcon icon={faFileImport} className='mr-2' />Import Employees</Button>:  ''
        }
      </div>
   {Number(ActivePlanDetails.currentUsers) < Number(ActivePlanDetails.uptoUsers) ?

      <div className='bg-white rounded-2xl drop-shadow-md p-4'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

          <div className='flex gap-6'>
            <div className='space-y-2'>
              <label>Title<span className="text-red-600">*</span></label>
              <Select 
                isInvalid={FormErrors?.title}
                value={CreateEmpFormDetails?.title} 
                onChange={({target})=>{setCreateEmpFormDetails({
                  ...CreateEmpFormDetails,
                  title: target.value
                })}}
                borderColor='gray.400' 
                placeholder='Select Title'
              >
                <option value={'Mr.'}>Mr.</option>
                <option value={'Ms.'}>Ms.</option>
              </Select>
              {FormErrors?.title && <span className="text-red-600 my-1 text-sm">{FormErrors?.title}</span>}
            </div>

           <div className='space-y-2'>
              <label>First Name<span className="text-red-600">*</span></label>
              <Input 
                isInvalid={FormErrors?.firstName}
                value={CreateEmpFormDetails?.firstName || ''} 
                onChange={({target})=>{
                  let InputTarget = target?.value?.trim();
                  setCreateEmpFormDetails({
                    ...CreateEmpFormDetails,
                    firstName: InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1)
                  });
                }} 
                borderColor='gray.400' 
                placeholder='First Name' 
              />
              {FormErrors?.firstName && <span className="text-red-600 my-1 text-sm">{FormErrors?.firstName}</span>}
            </div>

            <div className='space-y-2'>
              <label>Middle Name</label>
              <Input 
                isInvalid={FormErrors?.middleName}
                value={CreateEmpFormDetails?.middleName || ''} 
                onChange={({target})=>{
                  let InputTarget = target?.value?.trim();
                  setCreateEmpFormDetails({
                    ...CreateEmpFormDetails,
                    middleName: InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1)
                  })
                }} 
                borderColor='gray.400' 
                placeholder='Middle Name' />
                {FormErrors?.middleName && <span className="text-red-600 my-1 text-sm">{FormErrors?.middleName}</span>}
            </div>

          </div>


          <div className={`${IsManualEmpCode ? 'grid grid-cols-2 gap-6':''}`}>

          <div className='space-y-2'>
              <label>Last Name<span className="text-red-600">*</span></label>
              <Input 
                isInvalid={FormErrors?.lastName}
                value={CreateEmpFormDetails?.lastName || ''} 
                onChange={({target})=>{
                  let InputTarget = target?.value?.trim();
                  setCreateEmpFormDetails({
                    ...CreateEmpFormDetails,
                    lastName: InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1)
                  })
                }} 
                borderColor='gray.400' 
                placeholder='Last Name' />
                {FormErrors?.lastName && <span className="text-red-600 my-1 text-sm">{FormErrors?.lastName}</span>}
            </div>


            {IsManualEmpCode && 
             <div className='space-y-2'>
               <label>Employee Code</label>
               <Input 
                isInvalid={FormErrors?.empCode}
                value={CreateEmpFormDetails?.empCode || ''} 
                onChange={({target})=>{
                  let InputTarget = target?.value?.trim();
                  setCreateEmpFormDetails((prev)=>{
                    return {
                      ...prev,
                      empCode: InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1),
                    }
                  })
                }} 
                borderColor='gray.400' placeholder='Employee Code' />
                {FormErrors?.empCode && <span className="text-red-600 my-1 text-sm">{FormErrors?.empCode}</span>}
             </div>
            }

          </div>


        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

          <div className='space-y-2'>
            <label>Date of Joining<span className="text-red-600">*</span></label>
            <DatePicker
              className={`${FormErrors?.dateofjoin ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
              selected={CreateEmpFormDetails?.dateofjoin}
              onChange={(date) =>{
                setCreateEmpFormDetails({
                  ...CreateEmpFormDetails,
                  dateofjoin: date,
                  regulationDate: date,
                  probationStartDate: date,
                  probationEndDate: date
                });
              }}
              minDate={Validation?.payrollMonth === 0 ? new Date(Validation?.payrollStartFrom) : new Date(Validation?.payrollYear,Validation?.payrollMonth,1)}
              placeholderText="Date of Joining"
              dateFormat={"dd-MM-yyyy"}
              maxDate={new Date()}
            />
            {FormErrors?.dateofjoin && <span className="text-red-600 my-1 text-sm">{FormErrors?.dateofjoin}</span>}
          </div>




<div className='grid grid-cols-2 gap-6'>

<div className='space-y-2'>
  <label>Designation<span className="text-red-600">*</span></label>
  <InputGroup size='md'>
      <ReactSelect
        required
        options={DesignationList}
        value={CreateEmpFormDetails?.selectedDesignation}
        className="w-full my-auto"
        onChange={(value)=>{
          setCreateEmpFormDetails((prev)=>{
            return {
              ...prev,
              selectedDesignation: value,
              designation: value?.designationId
            }
          })
        }}
        getOptionLabel={(option) => option.designationName}
        getOptionValue={(option) => option.designationName}
        key={(option)=> option.designationId}
        placeholder="Select Designation"
      />
      <AddDesignation onAddDesignationResponse={RefetchDesignationList}/>
    </InputGroup>
    {FormErrors?.designation && <span className="text-red-600 my-1 text-sm">{FormErrors?.designation}</span>}
</div>


<div className='space-y-2'>
  <label>Assign Role<span className="text-red-600">*</span></label>
  <Select 
    isInvalid={FormErrors?.roleId}
    value={CreateEmpFormDetails?.roleId || ''} 
    onChange={({target})=>{
      setCreateEmpFormDetails({
        ...CreateEmpFormDetails,
        roleId: target.value
      })
    }} 
    borderColor={'gray.400'} 
    placeholder='Select Role'
  >
    {RoleList.filter((data)=> data.isActive ===  true).map((data,i)=>{
        return <option key={i} value={data.id}>{data.roleName}</option>
    })}
 </Select>
 {FormErrors?.roleId && <span className="text-red-600 my-1 text-sm">{FormErrors?.roleId}</span>}
</div>

</div>


</div>


<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Department<span className="text-red-600">*</span></label>
  <InputGroup size='md'>
      <ReactSelect
        required
        options={DepartmentList}
        value={CreateEmpFormDetails?.selectedDepartment}
        className="w-full my-auto"
        onChange={(value)=>{
          setCreateEmpFormDetails((prev)=>{
            return {
              ...prev,
              selectedDepartment: value,
              department: value?.departmentId
            }
          })
        }}
        getOptionLabel={(option) => option.departmentName}
        getOptionValue={(option) => option.departmentName}
        key={(option)=> option.departmentId}
        placeholder="Select Department"
      /> 
      <AddDepartment onAddDepartmentResponse={RefetchDepartmentList}/>
    </InputGroup>
    {FormErrors?.department && <span className="text-red-600 my-1 text-sm">{FormErrors?.department}</span>}
</div>

<div className='space-y-2'>

  <div className='flex justify-between'>
   <label>Reporting Officer<span className="text-red-600">*</span></label>
   <Checkbox 
     borderColor={'gray.400'} 
     isChecked={CreateEmpFormDetails?.selfReporting} 
     onChange={({target})=>{
       if(target.checked){
        setCreateEmpFormDetails({
          ...CreateEmpFormDetails,
          selfReporting:target.checked,
          reportingOfficersId:"0"
        })
       }else{
        setCreateEmpFormDetails({
          ...CreateEmpFormDetails,
          selfReporting:target.checked,
          reportingOfficersId:''
        })
       }
      }}>Self Reporting</Checkbox>
  </div>
      <ReactSelect
        required
        isDisabled={CreateEmpFormDetails?.selfReporting}
        options={EmployeeList}
        value={CreateEmpFormDetails?.selectedReporting}
        className="w-full my-auto"
        onChange={(value) => {

          setCreateEmpFormDetails((prev)=>{
            return {
              ...prev,
              reportingOfficersId: value?.id,
              selectedReporting: value
            }
          });

        }}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionValue={(option) => option.id}
        key={(option)=> option.id}
        placeholder="Select Reporting Officer"
        />
        {FormErrors?.reportingOfficersId && <span className="text-red-600 my-1 text-sm">{FormErrors?.reportingOfficersId}</span>}
</div>



</div>


<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Mobile Number<span className="text-red-600">*</span></label>
  <Input 
    isInvalid={FormErrors?.mobile}
    value={CreateEmpFormDetails?.mobile || ''} 
    onChange={({target})=>{
      setCreateEmpFormDetails({
        ...CreateEmpFormDetails,
        mobile: target.value
      })
    }} 
    type='number' 
    borderColor='gray.400' 
    placeholder='Mobile Number' 
  />
  {FormErrors?.mobile && <span className="text-red-600 my-1 text-sm">{FormErrors?.mobile}</span>}
</div>

<div className='space-y-2'>
  <label>Employee Category<span className="text-red-600">*</span></label>
  <InputGroup size='md'>
      <ReactSelect
        required
        options={EmployeeCategoryList}
        value={CreateEmpFormDetails?.selectedEmpType}
        className="w-full my-auto"
        onChange={(value) => {
          let ProbationMonth = value?.probationMonth
          //  console.log("Probation Month",ProbationMonth);
           let dt = CreateEmpFormDetails?.regulationDate;
           let EndDate = moment(dt).add(ProbationMonth,'M');
           setCreateEmpFormDetails((prev)=>{
            return {
              ...prev,
              selectedEmpType: value,
              empType: value?.empTypeId,
              probationEndDate: new Date(EndDate)
            }
           })
        }}
        getOptionLabel={(option) => option.empTypeName}
        getOptionValue={(option) => option.empTypeId}
        key={(option)=> option.empTypeId}
        placeholder="Select Employee Category"
      />
      <AddEmployeeCategory onAddEmpCategoryResponse={RefetchEmployeeCategoryList}/>
    </InputGroup>
    {FormErrors?.empType && <span className="text-red-600 my-1 text-sm">{FormErrors?.empType}</span>}
</div>


</div>


<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Regulation Date<span className="text-red-600">*</span></label>
  <DatePicker
    disabled
    className={`${FormErrors?.regulationDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
    selected={CreateEmpFormDetails?.regulationDate}
    // onChange={(date) => setRegulationDate(date)}
    placeholderText="Regulation Date"
    dateFormat={'dd-MM-yyyy'}
  />
  {FormErrors?.regulationDate && <span className="text-red-600 my-1 text-sm">{FormErrors?.regulationDate}</span>}
</div>


<div className='grid grid-cols-2 gap-6'>
<div className='space-y-2'>
  <label>Probation Start Date<span className="text-red-600">*</span></label>
  <DatePicker
    disabled
    className={`${FormErrors?.probationStartDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
    selected={CreateEmpFormDetails?.probationStartDate}
    // onChange={(date) => setProbationStartDate(date)}
    placeholderText="Probation Start Date"
    dateFormat={'dd-MM-yyyy'}
  />
  {FormErrors?.probationStartDate && <span className="text-red-600 my-1 text-sm">{FormErrors?.probationStartDate}</span>}
</div>

<div className='space-y-2'>
  <label>Probation End Date<span className="text-red-600">*</span></label>
  <DatePicker
    disabled
    className={`${FormErrors?.probationEndDate ? 'border-2 border-red-600' : 'border border-gray-400'} bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
    selected={CreateEmpFormDetails?.probationEndDate}
    // onChange={(date) => setProbationEndDate(date)}
    placeholderText="Probation End Date"
    dateFormat={'dd-MM-yyyy'}
  />
  {FormErrors?.probationEndDate && <span className="text-red-600 my-1 text-sm">{FormErrors?.probationEndDate}</span>}
</div>

</div>


</div>

<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>


<div className='space-y-2'>
  <label>Office Email<span className="text-red-600">*</span></label>
  <Input 
    isInvalid={FormErrors?.officeEmail}
    value={CreateEmpFormDetails?.officeEmail || ''} 
    onChange={({target})=>{
      setCreateEmpFormDetails({
        ...CreateEmpFormDetails,
        officeEmail: target.value
      })
    }} 
    type='email' 
    borderColor='gray.400' 
    placeholder='Office Email' 
  />
    {FormErrors?.officeEmail && <span className="text-red-600 my-1 text-sm">{FormErrors?.officeEmail}</span>}
</div>

</div>

<div className='flex gap-6 justify-end mt-6'>
  {Number(ActivePlanDetails.currentUsers || 0) <= Number(ActivePlanDetails.uptoUsers || 0) ? 
  <Button isLoading={IsPostCreateEmpLoading} loadingText="Creating..." onClick={SaveCreateEmployee} variant='solid' colorScheme='purple'>Create</Button>
  : <h6 onClick={()=>{navigate('/plantransactions')}} className="text-white cursor-pointer bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">Employee Limit Exceeded. Please Upgrade your Plan!! <FontAwesomeIcon icon={faUpRightFromSquare} className=' ml-1' /></h6>
   }
</div>


      </div> : 
      <div className='p-4 flex justify-center items-center'>
        <div className='text-center space-y-6'>
          <img src="./images/oops.png" className='mx-auto' alt="oops"/>
          <h2 className='text-2xl font-semibold text-gray-800'>Employee Limit Exceeded. Please Upgrade your Plan!!</h2>
          <Button onClick={()=>{navigate('/plantransactions')}} variant={'solid'} colorScheme='purple'>Upgrade Plan</Button>
        </div>
      </div> }


      <Modal size='5xl' scrollBehavior='inside' isOpen={isImportEmployeesOpen} onClose={onImportEmployeesClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Employees</ModalHeader>
          <ModalCloseButton onClick={()=>{setEmployeesImportData([])}} />
          <hr />
            <ModalBody>

              <div className='mt-4'>
               <CSVLink data={EmpExcelHeads} headers={EmpExcelHeads} filename={"Employees Data.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Employees</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
               ref={FileRef}
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(EmployeesImportData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setEmployeesImportData([]);
                    onImportEmployeesClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default CreateEmployee