import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Input,
  Button,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import authApi from "../Utils/AuthApis";

function AttendanceTypeMaster() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [DesignationID, setDesignationID] = useState(0);
  const [DesignationName, setDesignationName] = useState();

  const [AttendanceTypeID, setAttendanceTypeID] = useState(0);
  const [IsIncludeHoliday, setIsIncludeHoliday] = useState(true);
  const [IsIncludeWeekOff, setIsIncludeWeekOff] = useState(true);
  const [AttendanceTypeName, setAttendanceTypeName] = useState();
  const [AttendanceDays, setAttendanceDays] = useState();

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Attendance Type Name",
      field: "attendanceTypeName",
    },
    {
      headerName: "Attendance Days",
      field: "attendanceDays",
    },

    {
      headerName: "Is Holiday Include?",
      field: "isincludeHoliday",
    },

    {
      headerName: "Is Week-Off Include?",
      field: "isincludeWeekOff",
    },
    // {
    //    headerName: "IsActive", field: "isActive" ,
    //   cellRenderer:(params) => <Switch colorScheme='green' isChecked={params.data.isActive} onChange={({target})=>{HandleDesignationSwitch(target.checked,params.data)}} size='md' />
    // },
    // {
    //   headerName: "Action",
    //   field:'isActive',
    //   cellRenderer: (params) => <div className="space-x-4">
    //      <Button onClick={()=>{
    //       setDesignationID(params.data.designationId);
    //       setDesignationName(params.data.designationName);
    //      }}  variant='solid' size='xs' colorScheme='blue'><Edit size='14px' className="mr-2"/>Edit</Button>
    //   </div>
    // },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
  }));

  useEffect(() => {
    getAttendanceTypeMasterList();
  }, []);

  const getAttendanceTypeMasterList = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendanceTypeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Get Attendance Type List Response", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const SaveAttendanceTypeMaster = async (e) => {
    e.preventDefault();
    let body = {
      attendanceTypeName: AttendanceTypeName,
      attendanceDays: AttendanceDays,
      isincludeHoliday: IsIncludeHoliday,
      isincludeWeekOff: IsIncludeWeekOff,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log("Save Attendace Type Body", body);
    await authApi
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveAttendanceTypeMaster`,
        body
      )
      .then((response) => {
        console.log("Save Attendance Type Response", response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getAttendanceTypeMasterList();
        setIsIncludeHoliday(true);
        setIsIncludeWeekOff(true);
        setAttendanceTypeName("");
        setAttendanceDays("");
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Attendance Type not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">
          Attendance Type Master
        </h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Attendance Type
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveAttendanceTypeMaster}>
                <div className="grid grid-cols-5 gap-6 items-end">
                  <div className="space-y-2">
                    <label>Attendance Type Name</label>
                    <Input
                      isRequired
                      value={AttendanceTypeName || ""}
                      onChange={({ target }) => {
                        setAttendanceTypeName(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Add Attendance Type Name"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Attendance Days</label>
                    <Input
                      type={"number"}
                      value={AttendanceDays || ""}
                      onChange={({ target }) => {
                        setAttendanceDays(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Add Attendance Days"
                    />
                  </div>

                  <div>
                    <Checkbox
                      isChecked={IsIncludeHoliday}
                      onChange={({ target }) => {
                        setIsIncludeHoliday(target.checked);
                      }}
                      borderColor={"gray.400"}
                    >
                      Is Holidays Include?
                    </Checkbox>
                  </div>

                  <div>
                    <Checkbox
                      isChecked={IsIncludeWeekOff}
                      onChange={({ target }) => {
                        setIsIncludeWeekOff(target.checked);
                      }}
                      borderColor={"gray.400"}
                    >
                      Is Week-Off Include?
                    </Checkbox>
                  </div>
                </div>

                <div className="space-x-3 my-2 text-right">
                  <Button
                    onClick={() => {
                      setDesignationName("");
                      setDesignationID(0);
                    }}
                    type="reset"
                    variant="outline"
                    colorScheme="purple"
                  >
                    Reset
                  </Button>
                  <Button type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>
    </div>
  );
}

export default AttendanceTypeMaster;
