import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Button, Center, Spinner, useDisclosure, Modal, ModalOverlay, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Input, Select } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import { LuRefreshCw } from "react-icons/lu";
import moment from "moment";
import { useAuth } from "../Utils/AuthContext";
import authApi from "../Utils/AuthApis";
import { Edit } from "react-feather";


export default function LeaveBalance() {

  const { userData } = useAuth();

  const { isOpen: isEditBalOpen, onOpen: onEditBalOpen, onClose: onEditBalClose } = useDisclosure();


  const [IsLoading, setIsLoading] = useState(false);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border", 
    resizable: true
  }));

  const [LeaveQuotaList, setLeaveQuotaList] = useState([]);
  const [LeaveColumnDefs, setLeaveColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Month",
      field: "lMonth",
    }
  ]);

  const  EndHeads = [
    {
      headerName: "Action",
      cellRenderer: (params) => <Button onClick={()=>{
        // console.log("Emp Bal Data",params.data);
        onEditBalOpen();
      }} size='xs' colorScheme="blue" variant='outline' leftIcon={<Edit size={'14px'} />} >Edit</Button>
    }
  ]


  const gridRef = useRef();

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [leaveMonth, setleaveMonth] = useState(new Date());
  const [LeaveRowData, setLeaveRowData] = useState([]);

  useEffect(() => {
    createHeaders();
  }, []);

  const createHeaders = async () => {
    setIsLoading(true);
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Headers Leave", response.data);
      const res = response.data;
      setLeaveQuotaList(res);
    
      res.map((obj) => {
        return setLeaveColumnDefs((prev) => [
          ...prev,
          { headerName: obj.leaveType, field: obj.leaveType },
          { headerName: "Excess "+obj.leaveType, field: "Excess"+obj.leaveType},
          { headerName: "Final "+obj.leaveType, field: "Final"+obj.leaveType}
        ]);
      });

      ProcessLeaveBalance(new Date(leaveMonth).getMonth()+1, new Date(leaveMonth).getFullYear(),res);
    } catch (error) {
      console.error(error);
    }
  };

  const ProcessLeaveBalance = async (MONTH, YEAR, QuotaList) => {
    setLeaveRowData([]);
    setIsLoading(true);

    console.log("Month", MONTH);
    console.log("Year", YEAR);
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/MonthlyLeaveProcedure?year=${YEAR}&Month=${MONTH}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Process Leave", response);
      LeaveBalList(MONTH, YEAR, QuotaList);
    } catch (err) {
      console.error(err);
    }
  };

  const LeaveBalList = async (MONTH, YEAR, QuotaList) => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveBalanceList?month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Bal List",response);

      GetLeaveSettlementData(_.uniqBy(response.data, "empGuid"),response.data,QuotaList);
    } catch (error) {
      console.log(error);
    }
  }


  const GetLeaveSettlementData = (UniqBalList,BalanceList,QuotaList) => {
    console.log("Balance List",UniqBalList,QuotaList);
    try {
      Promise.all(
        UniqBalList.map(async (EmpData)=>{
          let body = {
            empGuid : EmpData.empGuid,
            isLopSettled : false,
            companyId : userData?.companyId,
            branchId : userData?.isActiveBranch,
            startDate : moment().startOf('year').format('YYYY-MM-DD'),
            endDate : moment(leaveMonth).endOf('month').format('YYYY-MM-DD')
          }
          const response = await authApi.post(
            `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLOPAdjustmentListbyEmp`,body
          )
          // console.log("Emp Settlements",response);
          const EmpSettleList = response.data;
          const EmpLeaveBalList = BalanceList.filter(data => data.empGuid === EmpData.empGuid);

          const SettledCounts = {};
          const BalancedLeave = {};
          const FinalDifference = {};
           
          QuotaList?.forEach((record)=>{
            const leaveType = record.leaveType;
            const key = 'Excess'+leaveType;
            SettledCounts[key] = 0

            if(EmpSettleList.some(SettleData => SettleData.leaveTypeName === record.leaveType)){
              // console.log("Have",record.leaveType, EmpData.empName);
              EmpSettleList
              .filter(setData => setData.leaveTypeName === record.leaveType)
              .forEach((SettleRecord) => {
                const leaveTypeName = SettleRecord.leaveTypeName;
                const key = 'Excess' + leaveTypeName;
                if (key in SettledCounts) {
                  SettledCounts[key] += 1;
                } else {
                  SettledCounts[key] = 1;
                }
              });
            }else{
              // console.log("Not Have",record.leaveType, EmpData.empName);
              SettledCounts[key] = 0
            }
          });


          EmpLeaveBalList.forEach((record) => {
            const leaveTypeName = record.leaveType;
            const remainingQuota = record.remainingQuota;
            BalancedLeave[leaveTypeName] = remainingQuota;
          });

          for (const leaveTypeName in BalancedLeave) {
            if (SettledCounts.hasOwnProperty(`Excess${leaveTypeName}`)) {
              const key = `Final${leaveTypeName}`
              const rawDifference = BalancedLeave[leaveTypeName] - SettledCounts[`Excess${leaveTypeName}`];
              FinalDifference[key] = Number.isInteger(rawDifference) ? rawDifference : parseFloat(rawDifference.toFixed(2));
            }
          }

          //  console.log("EmpSettleList",EmpSettleList);
          //  console.log("Emp Counts",SettledCounts,BalancedLeave,FinalDifference);

          const FinalEmpObject = {
            empCode: EmpData.empCode,
            empGuid: EmpData.empGuid,
            empName: EmpData.empName,
            lMonth: EmpData.lMonth,
            lyear: EmpData.lyear,
            empLeaveBalance: EmpLeaveBalList,
            empSetteledData: EmpSettleList,
            ...SettledCounts,
            ...BalancedLeave,
            ...FinalDifference
          }
          
          //  console.log("New Final Row Data",FinalEmpObject);

          setLeaveRowData((prev)=>[...prev,FinalEmpObject])
        })
      )
    } catch (err) {
      console.error(err)
    }finally{
      setIsLoading(false)
    }

  }


  console.log("column Defs",[...LeaveColumnDefs,...EndHeads]);


  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Balance</h1>
        <div className="flex gap-4 items-center">
          <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={leaveMonth}
              onChange={(date) => {
                setleaveMonth(date);
              }}
              maxDate={new Date()}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </div>
          <div>
            <Button onClick={()=>{
              // createHeaders();
              ProcessLeaveBalance(
                new Date(leaveMonth).getMonth()+1,
                new Date(leaveMonth).getFullYear(),
                LeaveQuotaList
              );
            }} leftIcon={<LuRefreshCw/>} colorScheme="purple">Process</Button>
          </div>
        </div>
      </div>

      {IsLoading ? 
       <Center mt={10}>
         <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
       </Center> :
      <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={LeaveRowData} // Row Data for Rows
          columnDefs={[...LeaveColumnDefs,...EndHeads]} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          rowSelection="single"
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div> }


      <Modal isOpen={isEditBalOpen} onClose={onEditBalClose}>
        <ModalOverlay />
        <ModalContent rounded={'3xl'}>
        <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Edit Leave Balance</ModalHeader>
        <ModalCloseButton mt={1.5}/>
          {/* <form > */}
            <ModalBody className='space-y-4'>
              <div className="space-y-2">
                <label>Select Quota</label>
                <Select borderColor='gray.400' placeholder={'Select Leave Quota'}>
                  {LeaveQuotaList?.map((data)=>{
                    return <option value={data?.leaveQuotaId} >{data.leaveType}</option>
                  })}
                </Select>
              </div>
              <div className="space-y-2">
                <label>Enter Balance</label>
                <Input
                  type="number"
                  borderColor="gray.400"
                  placeholder="Enter Balance"
                />
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button  colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button onClick={onEditBalClose} variant="outline" colorScheme="purple" >
                Cancel
              </Button>
            </ModalFooter>
          {/* </form> */}
        </ModalContent>
      </Modal>

    </div>
  );
}
