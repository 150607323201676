import {   Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Select,
    Checkbox,
    Input,
    Tooltip,
    InputGroup,
    InputRightAddon,
    useToast,
    InputLeftAddon,
    Textarea,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,Box, Image} from '@chakra-ui/react'
import React, {
    useRef,
    useMemo,
    useState,
    useCallback,
    useEffect,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
  import { faPlus, faDownload, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircle, Paperclip, Search, XCircle } from "react-feather";
import ReactSelect from 'react-select';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { useAuth } from '../Utils/AuthContext';
import authApi from '../Utils/AuthApis';


function AllApprovals() {

    const {userData} = useAuth();
    const toast = useToast();
    const id = "toast";


    const [ManagerLevel, setManagerLevel] = useState(false);
    const [HRLevel, setHRLevel] = useState(false);
    const [FinanceLevel, setFinanceLevel] = useState(false);

    const [DataDateRange, setDataDateRange] = useState([new Date(moment().startOf('month')),new Date(moment().endOf('month'))]);
    const [DataStartDate, DataEndDate] = DataDateRange;

    const [DataStatus, setDataStatus] = useState('Pending');

    const [ApprovalID, setApprovalID] = useState(0);

    const ClaimRef = useRef();
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling



    const [ApprovalLevelDetails, setApprovalLevelDetails] = useState([]);

    const [TeamAdvanceRowData, setTeamAdvanceRowData] = useState([]);
    const [TeamExpenseRowData, setTeamExpenseRowData] = useState([]);

    const [HRAdvanceRowData, setHRAdvanceRowData] = useState([]);
    const [FinanceAdvanceRowData, setFinanceAdvanceRowData] = useState([]);

    const [HrExpenseRequestsRowData, setHrExpenseRequestsRowData] = useState([]);
    const [FinanceExpenseRequestsRowData, setFinanceExpenseRequestsRowData] = useState([]);


    const [ApprovedClaimList, setApprovedClaimList] = useState([]);


    const [ViewClaimAttachment, setViewClaimAttachment] = useState();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [PayableAmount, setPayableAmount] = useState();



    const [ReviewSelectedEmp, setReviewSelectedEmp] = useState([]);
    const [ReviewClaimRowData, setReviewClaimRowData] = useState([]);


    const [ReviewClaimColumnDefs, setReviewClaimColumnDefs] = useState([
      { headerName: "Emp Code", field: "empCode",      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        return params.data.employeeName;
      }, },
  
      {
        headerName: "Emp Name",
        field: "employeeName",
  
      },
      { headerName: "Expense Name", field: "reimbursementName" },
      { headerName: "Ticket ID", field: "reimbursementTicketGenerate" },
      { headerName: "Requested Amount", field: "claimedAmount" },
      { headerName: "Requested Date", field: "claimDate" ,
      cellRenderer:(params) => <span>{moment(params.data.claimDate).format('DD/MM/YYYY')}</span>
    },

      { headerName: "Description", field: "reason" },
      {
        headerName: "Attachment",
        field: "attachment",floatingFilter : false,
        cellRenderer:(params) => params.data.attachment != null ? 
      <Button colorScheme={'blue'} size={'xs'} onClick={()=>{handleViewFile(params.data.attachment)}}>
        <FontAwesomeIcon className="cursor-pointer mr-1"  icon={faPaperclip} /> View
     </Button> : ''
      },
    ]);
  

    const [AppReviewClaimColumnDefs, setAppReviewClaimColumnDefs] = useState([
      { headerName: "Emp Code", field: "empCode", },
      {
        headerName: "Emp Name",
        field: "employeeName",
  
      },
      { headerName: "Expense Name", field: "reimbursementName" },
      { headerName: "Ticket ID", field: "reimbursementTicketGenerate" },
      { headerName: "Requested Amount", field: "claimedAmount" },
      { headerName: "Requested Date", field: "claimDate" ,
      cellRenderer:(params) => <span>{moment(params.data.claimDate).format('DD/MM/YYYY')}</span>
    },
      { headerName: "Description", field: "reason" },
      {
        headerName: "Attachment",
        field: "attachment",floatingFilter : false,
        cellRenderer:(params) => params.data.attachment != null ? 
      <Button colorScheme={'blue'} size={'xs'} onClick={()=>{handleViewFile(params.data.attachment)}}>
        <FontAwesomeIcon className="cursor-pointer mr-1"  icon={faPaperclip} /> View
     </Button> : ''
      },
    ]);

    const {
      isOpen: isReviewClaimOpen,
      onOpen: onReviewClaimOpen,
      onClose: onReviewClaimClose,
    } = useDisclosure();



    
    const [TeamAdvanceCashColumnDefs, setTeamAdvanceCashColumnDefs] = useState([
      { headerName: "Emp Code", field: "empCode" },
      { headerName: "Emp Name", field: "fullName",
      valueGetter:(params) => params.data.empName || params.data.fullName  },        { headerName: "Purpose", field: "pT_Purpose" },
        { headerName: "Request Date", field: "pT_ClaimDate" ,valueGetter: (params) => {
            return new Date(params.data.pT_ClaimDate).toLocaleDateString('en-GB')
          }},
        {
          headerName: "Claim Status",
          field: "pT_ClaimStatus",
          cellRenderer: (params) => (
            params.data.pT_ClaimStatus === 'Pending' ||  params.data.pT_ClaimStatus === 'pending'?
            <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
           : params.data.pT_ClaimStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Approved
          </span> : params.data.pT_ClaimStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Rejected
          </span>: ''
          ),
        },
        {
          headerName: "Approval Status",
          field: "approvalStatus",
          cellRenderer: (params) => (
            params.data.approvalStatus === 'Pending'?
            <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
           : params.data.approvalStatus === 'Pending'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Approved
          </span> : params.data.approvalStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Rejected
          </span> : <span>{params.data.approvalStatus}</span>
          ),
        },
        { headerName: "Total Request Amount", field: "pT_ClaimAmount" },
          {
            headerName: "Action",
            field: "approvalStatus",floatingFilter : false,
            cellRenderer: (params) => (
              params.data.approvalStatus === 'Pending' ?
              <div className="space-x-4">
                <Button size='xs' 
                 onClick={async ()=>{

                    let ReportingRowData = params.data;

                    let body ={
                           pettyCashApprovalList: [
                             {
                               ptAdvMId : ReportingRowData.ptAdvMId,
                               empGuId : ReportingRowData.empGuId,
                               empName : ReportingRowData.empName,
                               empCode : ReportingRowData.empCode,
                               pT_ClaimAmount : ReportingRowData.pT_ClaimAmount,
                               pT_ClaimDate : ReportingRowData.pT_ClaimDate,
                               pT_Purpose : ReportingRowData.pT_Purpose,
                               companyId : userData.companyId,
                               branchId : userData.isActiveBranch,
                               pT_ClaimStatus : "Pending",
                             }
                           ],
                           approvalLinkAdvanceId : ReportingRowData.approvalLinkAdvanceId,
                           empApprovalMatrixId : ReportingRowData.empApprovalMatrixId,
                           approvalStatus : "Approved",
                           approvalDate : ReportingRowData.pT_ClaimDate,
                           ptAdvMId : ReportingRowData.ptAdvMId,
                           approvedByGuId : userData.guId,
                           empGuId : ReportingRowData.empGuId,
                           level_IndexId : 1,
                           companyId : userData.companyId,
                           branchId : userData.isActiveBranch,
                           totalAmount : ReportingRowData.pT_ClaimAmount,
                           isLock : false,
                        }


                        console.log("Reporting Approval Body",body);


                        await authApi
                        .post(
                          `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/UpdateApprovalLinkAdvance`,
                          body
                        )
                        .then((response) => {
                          //  const res = response.data;
                          console.log("Save Eligibility Response", response);
                  
                          const res = response.data;
                          console.log("res",res);
                          if (!toast.isActive(id)) {
                            toast({
                              id,
                              title: "Done",
                              position: "top",
                              status: "info",
                              duration: 2000,
                              isClosable: true,
                            });
                          }
            
                          GetTeamAdvanceList();
          
                        })
                        .catch((error) => {
                          console.error(error);
                          if (!toast.isActive(id)) {
                            toast({
                              id,
                              title: "ERROR",
                              description: "Eligibility Not Added.",
                              position: "top",
                              status: "error",
                              duration: 2000,
                              isClosable: true,
                            });
                          }
                        });
                 }}
                  colorScheme='green'><CheckCircle size='14px' className="mr-2" />Approve</Button>
                <Button 
                                 onClick={async ()=>{

                                  let ReportingRowData = params.data
              
                                  // console.log("Reporting Person Row Data",ReportingRowData);
              
              
                                  let body ={
                                         pettyCashApprovalList: [
                                           {
                                             ptAdvMId : ReportingRowData.ptAdvMId,
                                             empGuId : ReportingRowData.empGuId,
                                             empName : ReportingRowData.empName,
                                             empCode : ReportingRowData.empCode,
                                             pT_ClaimAmount : ReportingRowData.pT_ClaimAmount,
                                             pT_ClaimDate : ReportingRowData.pT_ClaimDate,
                                             pT_Purpose : ReportingRowData.pT_Purpose,
                                             companyId : userData.companyId,
                                             branchId : userData.isActiveBranch,
                                             pT_ClaimStatus : "Pending",
                                           }
                                         ],
                                         approvalLinkAdvanceId : ReportingRowData.approvalLinkAdvanceId,
                                         empApprovalMatrixId : ReportingRowData.empApprovalMatrixId,
                                         approvalStatus : "Rejected",
                                         approvalDate : ReportingRowData.pT_ClaimDate,
                                         ptAdvMId : ReportingRowData.ptAdvMId,
                                         approvedByGuId : userData.guId,
                                         empGuId : ReportingRowData.empGuId,
                                         level_IndexId : 2,
                                         companyId : userData.companyId,
                                         branchId : userData.isActiveBranch,
                                         totalAmount : ReportingRowData.pT_ClaimAmount,
                                         isLock : false,
                                      }
              
              
              
                                      console.log("Reporting Approval Body",body);
              
              
                                      await authApi
                                      .post(
                                        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/UpdateApprovalLinkAdvance`,
                                        body
                                      )
                                      .then((response) => {
                                        //  const res = response.data;
                                        console.log("Save Eligibility Response", response);
                                
                                        const res = response.data;
                                        console.log("res",res);
                                        if (!toast.isActive(id)) {
                                          toast({
                                            id,
                                            title: "Done",
                                            position: "top",
                                            status: "info",
                                            duration: 2000,
                                            isClosable: true,
                                          });
                                        }
                          
                                        GetTeamAdvanceList();
                        
                                      })
                                      .catch((error) => {
                                        console.error(error);
                                        if (!toast.isActive(id)) {
                                          toast({
                                            id,
                                            title: "ERROR",
                                            description: "Eligibility Not Added.",
                                            position: "top",
                                            status: "error",
                                            duration: 2000,
                                            isClosable: true,
                                          });
                                        }
                                      });
                               }}
                 size='xs'  colorScheme='red'><XCircle size='14px' className="mr-2" />Reject</Button>
              </div> 
              : ''
            ),
          },
        ]);

        const [HRAdvanceCashColumnDefs, setHRAdvanceCashColumnDefs] = useState([
          { headerName: "Emp Code", field: "empCode" },
          { headerName: "Emp Name", field: "fullName",
          valueGetter:(params) => params.data.empName || params.data.fullName  },            { headerName: "Purpose", field: "pT_Purpose" },
            { headerName: "Request Date", field: "pT_ClaimDate" ,valueGetter: (params) => {
                return new Date(params.data.pT_ClaimDate).toLocaleDateString('en-GB')
              }},
            {
              headerName: "Claim Status",
              field: "pT_ClaimStatus",
              cellRenderer: (params) => (
                params.data.pT_ClaimStatus === 'Pending' ||  params.data.pT_ClaimStatus === 'pending'?
                <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Pending
              </span>
               : params.data.pT_ClaimStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
               Approved
              </span> : params.data.pT_ClaimStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
               Rejected
              </span>: ''
              ),
            },


            {
              headerName: "Approval Status",
              field: "approvalStatus",
              cellRenderer: (params) => (
                params.data.level_IndexId === 1 ?
                <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Pending
              </span>
               : <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
               Approved
              </span>
              ),
            },


            { headerName: "Total Request Amount", field: "pT_ClaimAmount" },
              {
                headerName: "Action",
                field: "approvalStatus",floatingFilter : false,
                cellRenderer: (params) => (
                  params.data.level_IndexId === 1 ?
                  <div className="space-x-4">
                    <Button size='xs' 
                     onClick={async ()=>{
    
                        let ReportingRowData = params.data
    
                        // console.log("Reporting Person Row Data",ReportingRowData);
    
    
                        let body ={
                               pettyCashApprovalList: [
                                 {
                                   ptAdvMId : ReportingRowData.ptAdvMId,
                                   empGuId : ReportingRowData.empGuId,
                                   empName : ReportingRowData.empName,
                                   empCode : ReportingRowData.empCode,
                                   pT_ClaimAmount : ReportingRowData.pT_ClaimAmount,
                                   pT_ClaimDate : ReportingRowData.pT_ClaimDate,
                                   pT_Purpose : ReportingRowData.pT_Purpose,
                                   companyId : userData.companyId,
                                   branchId : userData.isActiveBranch,
                                   pT_ClaimStatus : "Pending",
                                 }
                               ],
                               approvalLinkAdvanceId : ReportingRowData.approvalLinkAdvanceId,
                               empApprovalMatrixId : ReportingRowData.empApprovalMatrixId,
                               approvalStatus : "Approved",
                               approvalDate : ReportingRowData.pT_ClaimDate,
                               ptAdvMId : ReportingRowData.ptAdvMId,
                               approvedByGuId : userData.guId,
                               empGuId : ReportingRowData.empGuId,
                               level_IndexId : 2,
                               companyId : userData.companyId,
                               branchId : userData.isActiveBranch,
                               totalAmount : ReportingRowData.pT_ClaimAmount,
                               isLock : false,
                            }
    
    
    
                            console.log("Reporting Approval Body",body);
    
    
                            await authApi
                            .post(
                              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/UpdateApprovalLinkAdvance`,
                              body
                            )
                            .then((response) => {
                              //  const res = response.data;
                              console.log("Save Eligibility Response", response);
                      
                              const res = response.data;
                              console.log("res",res);
                              if (!toast.isActive(id)) {
                                toast({
                                  id,
                                  title: "Done",
                                  position: "top",
                                  status: "info",
                                  duration: 2000,
                                  isClosable: true,
                                });
                              }
                
                              GetHRAdvanceList();

              
                            })
                            .catch((error) => {
                              console.error(error);
                              if (!toast.isActive(id)) {
                                toast({
                                  id,
                                  title: "ERROR",
                                  description: "Eligibility Not Added.",
                                  position: "top",
                                  status: "error",
                                  duration: 2000,
                                  isClosable: true,
                                });
                              }
                            });
                     }}
                      colorScheme='green'><CheckCircle size='14px' className="mr-2" />Approve</Button>
                    <Button 
                                     onClick={async ()=>{
    
                                      let ReportingRowData = params.data
                  
                                      // console.log("Reporting Person Row Data",ReportingRowData);
                  
                  
                                      let body ={
                                             pettyCashApprovalList: [
                                               {
                                                 ptAdvMId : ReportingRowData.ptAdvMId,
                                                 empGuId : ReportingRowData.empGuId,
                                                 empName : ReportingRowData.empName,
                                                 empCode : ReportingRowData.empCode,
                                                 pT_ClaimAmount : ReportingRowData.pT_ClaimAmount,
                                                 pT_ClaimDate : ReportingRowData.pT_ClaimDate,
                                                 pT_Purpose : ReportingRowData.pT_Purpose,
                                                 companyId : userData.companyId,
                                                 branchId : userData.isActiveBranch,
                                                 pT_ClaimStatus : "Pending",
                                               }
                                             ],
                                             approvalLinkAdvanceId : ReportingRowData.approvalLinkAdvanceId,
                                             empApprovalMatrixId : ReportingRowData.empApprovalMatrixId,
                                             approvalStatus : "Rejected",
                                             approvalDate : ReportingRowData.pT_ClaimDate,
                                             ptAdvMId : ReportingRowData.ptAdvMId,
                                             approvedByGuId : userData.guId,
                                             empGuId : ReportingRowData.empGuId,
                                             level_IndexId : 2,
                                             companyId : userData.companyId,
                                             branchId : userData.isActiveBranch,
                                             totalAmount : ReportingRowData.pT_ClaimAmount,
                                             isLock : false,
                                          }
                  
                  
                  
                                          console.log("Reporting Approval Body",body);
                  
                  
                                          await authApi
                                          .post(
                                            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/UpdateApprovalLinkAdvance`,
                                            body
                                          )
                                          .then((response) => {
                                            //  const res = response.data;
                                            console.log("Save Eligibility Response", response);
                                    
                                            const res = response.data;
                                            console.log("res",res);
                                            if (!toast.isActive(id)) {
                                              toast({
                                                id,
                                                title: "Done",
                                                position: "top",
                                                status: "info",
                                                duration: 2000,
                                                isClosable: true,
                                              });
                                            }
                              
                                            GetHRAdvanceList();
                            
                                          })
                                          .catch((error) => {
                                            console.error(error);
                                            if (!toast.isActive(id)) {
                                              toast({
                                                id,
                                                title: "ERROR",
                                                description: "Eligibility Not Added.",
                                                position: "top",
                                                status: "error",
                                                duration: 2000,
                                                isClosable: true,
                                              });
                                            }
                                          });
                                   }}
                     size='xs'  colorScheme='red'><XCircle size='14px' className="mr-2" />Reject</Button>
                  </div> 
                    : ''
                ),
              },
            ]);

            const [FinanceAdvanceCashColumnDefs, setFinanceAdvanceCashColumnDefs] = useState([
              { headerName: "Emp Code", field: "empCode" },
              { headerName: "Emp Name", field: "fullName",
              valueGetter:(params) => params.data.empName || params.data.fullName  },                { headerName: "Purpose", field: "pT_Purpose" },
                { headerName: "Request Date", field: "pT_ClaimDate" ,valueGetter: (params) => {
                    return new Date(params.data.pT_ClaimDate).toLocaleDateString('en-GB')
                  }},
                {
                  headerName: "Claim Status",
                  field: "pT_ClaimStatus",
                  cellRenderer: (params) => (
                    params.data.pT_ClaimStatus === 'Pending' ||  params.data.pT_ClaimStatus === 'pending'?
                    <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                    Pending
                  </span>
                   : params.data.pT_ClaimStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                   Approved
                  </span> : params.data.pT_ClaimStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                   Rejected
                  </span>: ''
                  ),
                },

                {
                  headerName: "Approval Status",
                  field: "approvalStatus",
                  cellRenderer: (params) => (
                    params.data.level_IndexId === 2 ?
                    <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                    Pending
                  </span>
                   : <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                   Approved
                  </span>
                  ),
                },
                { headerName: "Total Request Amount", field: "pT_ClaimAmount" },
                  {
                    headerName: "Action",
                    field: "approvalStatus",floatingFilter : false,
                    cellRenderer: (params) => (
                      params.data.level_IndexId === 2 ?
                      <div className="space-x-4">
                        <Button size='xs' 
                         onClick={async ()=>{
        
                            let ReportingRowData = params.data
        
                            // console.log("Reporting Person Row Data",ReportingRowData);
        
        
                            let body ={
                                   pettyCashApprovalList: [
                                     {
                                       ptAdvMId : ReportingRowData.ptAdvMId,
                                       empGuId : ReportingRowData.empGuId,
                                       empName : ReportingRowData.empName,
                                       empCode : ReportingRowData.empCode,
                                       pT_ClaimAmount : ReportingRowData.pT_ClaimAmount,
                                       pT_ClaimDate : ReportingRowData.pT_ClaimDate,
                                       pT_Purpose : ReportingRowData.pT_Purpose,
                                       companyId : userData.companyId,
                                       branchId : userData.isActiveBranch,
                                       pT_ClaimStatus : "Approved",
                                     }
                                   ],
                                   approvalLinkAdvanceId : ReportingRowData.approvalLinkAdvanceId,
                                   empApprovalMatrixId : ReportingRowData.empApprovalMatrixId,
                                   approvalStatus : "Approved",
                                   approvalDate : ReportingRowData.pT_ClaimDate,
                                   ptAdvMId : ReportingRowData.ptAdvMId,
                                   approvedByGuId : userData.guId,
                                   empGuId : ReportingRowData.empGuId,
                                   level_IndexId : 3,
                                   companyId : userData.companyId,
                                   branchId : userData.isActiveBranch,
                                   totalAmount : ReportingRowData.pT_ClaimAmount,
                                   isLock : false,
                                }
        
        
        
                                console.log("Reporting Approval Body",body);
        
        
                                await authApi
                                .post(
                                  `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/UpdateApprovalLinkAdvance`,
                                  body
                                )
                                .then((response) => {
                                  //  const res = response.data;
                                  console.log("Save Eligibility Response", response);
                          
                                  const res = response.data;
                                  console.log("res",res);
                                  if (!toast.isActive(id)) {
                                    toast({
                                      id,
                                      title: "Done",
                                      position: "top",
                                      status: "info",
                                      duration: 2000,
                                      isClosable: true,
                                    });
                                  }
                    
                                  GetFinanceAdvanceList();
                  
                                })
                                .catch((error) => {
                                  console.error(error);
                                  if (!toast.isActive(id)) {
                                    toast({
                                      id,
                                      title: "ERROR",
                                      description: "Eligibility Not Added.",
                                      position: "top",
                                      status: "error",
                                      duration: 2000,
                                      isClosable: true,
                                    });
                                  }
                                });
                         }}
                          colorScheme='green'><CheckCircle size='14px' className="mr-2" />Approve</Button>
                        <Button 
                                         onClick={async ()=>{
        
                                          let ReportingRowData = params.data
                      
                                          // console.log("Reporting Person Row Data",ReportingRowData);
                      
                      
                                          let body ={
                                                 pettyCashApprovalList: [
                                                   {
                                                     ptAdvMId : ReportingRowData.ptAdvMId,
                                                     empGuId : ReportingRowData.empGuId,
                                                     empName : ReportingRowData.empName,
                                                     empCode : ReportingRowData.empCode,
                                                     pT_ClaimAmount : ReportingRowData.pT_ClaimAmount,
                                                     pT_ClaimDate : ReportingRowData.pT_ClaimDate,
                                                     pT_Purpose : ReportingRowData.pT_Purpose,
                                                     companyId : userData.companyId,
                                                     branchId : userData.isActiveBranch,
                                                     pT_ClaimStatus : "Pending",
                                                   }
                                                 ],
                                                 approvalLinkAdvanceId : ReportingRowData.approvalLinkAdvanceId,
                                                 empApprovalMatrixId : ReportingRowData.empApprovalMatrixId,
                                                 approvalStatus : "Rejected",
                                                 approvalDate : ReportingRowData.pT_ClaimDate,
                                                 ptAdvMId : ReportingRowData.ptAdvMId,
                                                 approvedByGuId : userData.guId,
                                                 empGuId : ReportingRowData.empGuId,
                                                 level_IndexId : 3,
                                                 companyId : userData.companyId,
                                                 branchId : userData.isActiveBranch,
                                                 totalAmount : ReportingRowData.pT_ClaimAmount,
                                                 isLock : false,
                                              }
                      
                      
                      
                                              console.log("Reporting Approval Body",body);
                      
                      
                                              await authApi
                                              .post(
                                                `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/UpdateApprovalLinkAdvance`,
                                                body
                                              )
                                              .then((response) => {
                                                //  const res = response.data;
                                                console.log("Save Eligibility Response", response);
                                        
                                                const res = response.data;
                                                console.log("res",res);
                                                if (!toast.isActive(id)) {
                                                  toast({
                                                    id,
                                                    title: "Done",
                                                    position: "top",
                                                    status: "info",
                                                    duration: 2000,
                                                    isClosable: true,
                                                  });
                                                }
                                  
                                                GetFinanceAdvanceList();
                                
                                              })
                                              .catch((error) => {
                                                console.error(error);
                                                if (!toast.isActive(id)) {
                                                  toast({
                                                    id,
                                                    title: "ERROR",
                                                    description: "Eligibility Not Added.",
                                                    position: "top",
                                                    status: "error",
                                                    duration: 2000,
                                                    isClosable: true,
                                                  });
                                                }
                                              });
                                       }}
                         size='xs'  colorScheme='red'><XCircle size='14px' className="mr-2" />Reject</Button>
                      </div> 
                        : ''
                    ),
                  },
                ]);

        const [TeamExpenseCashColumnDefs, setTeamExpenseCashColumnDefs] = useState([
          { headerName: "Ticket Id", field: "reimbursementTicketGenerate" },

          { headerName: "Emp Code", field: "empCode" },
          { headerName: "Emp Name", field: "fullName",
          valueGetter:(params) => params.data.empName || params.data.fullName  },
            {
              headerName: "Approval Status",
              field: "approvalStatus",
              cellRenderer: (params) => (
                params.data.approvalStatus === 'Pending'?
                <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Pending
              </span>
               : params.data.approvalStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
               Approved
              </span> : params.data.approvalStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
               Rejected
              </span>: ''
              ),
            },
            { headerName: "Total Request Amount", field: "totalAmount" },

            {
              headerName: "Action",
              field: "approvalStatus",floatingFilter : false,
              cellRenderer: (params) => (
                <div className="space-x-4">
                   <Button size='xs' onClick={() => {
              HandleReviewClaim(params.data);
              setReviewSelectedEmp(params.data);
              setApprovalID(1);
            }}  colorScheme='blue'>Review</Button>
                </div> 
              ),
            },
          ]);

          const [HRExpenseColumnDefs, setHRExpenseColumnDefs] = useState([
            { headerName: "Ticket Id", field: "reimbursementTicketGenerate" },
  
            { headerName: "Emp Code", field: "empCode" },
            { headerName: "Emp Name", field: "fullName",
            valueGetter:(params) => params.data.empName || params.data.fullName  },

              { headerName: "Total Request Amount", field: "totalAmount" },
  
              {
                headerName: "Action",
                field: "approvalStatus",floatingFilter : false,
                cellRenderer: (params) => (
                  <div className="space-x-4">
                     <Button size='xs' onClick={() => {
                HandleReviewClaim(params.data);
                setReviewSelectedEmp(params.data);
                setApprovalID(2);
              }}  colorScheme='blue'>Review</Button>
                  </div>
                ),
              },
            ]);

            const [FinanceExpenseColumnDefs, setFinanceExpenseColumnDefs] = useState([
              { headerName: "Ticket Id", field: "reimbursementTicketGenerate" },
    
              { headerName: "Emp Code", field: "empCode" },
                { headerName: "Emp Name", field: "fullName",
                valueGetter:(params) => params.data.empName || params.data.fullName  },

                { headerName: "Total Request Amount", field: "totalAmount" },
    
                {
                  headerName: "Action",floatingFilter : false,
                  field: "approvalStatus",
                  cellRenderer: (params) => (
                    <div className="space-x-4">
                       <Button size='xs' onClick={() => {
                  HandleReviewClaim(params.data);
                  setReviewSelectedEmp(params.data);
                  setApprovalID(3);
                }}  colorScheme='blue'>Review</Button>
                    </div> 
                  ),
                },
              ]);
          
  const handleViewFile = async (DocPath) =>{
    console.log(DocPath)
   await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
  ).then(response => {

    console.log("file Response",response);

    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        setViewClaimAttachment(alink);
        onOpen();
        // alink.download = fileURL;
        // alink.click();
    })

    }).catch((error)=>{
      console.log("File Api Error",error);
    })
  }

const HandleReviewClaim = async (RowData) => {
  console.log("Review Row Data",RowData);

  try {
    const response = await authApi.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementByTicketId?Ticket=${RowData.reimbursementTicketGenerate}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log("Ticket Expenses",response);
    setReviewClaimRowData(response.data);
    onReviewClaimOpen();
  } catch (error) {
    console.error(error);
  }
}

const onFirstDataRendered = useCallback((params) => {
  ClaimRef.current.api.forEachNode((node) =>
    node.setSelected(true)
  );
}, []);


const onSelectionChanged = useCallback(() => {
  var ClaimData = ClaimRef.current.api.getSelectedRows();
  // console.log(ClaimData);


  setApprovedClaimList(ClaimData);

  setPayableAmount(() => {
    return ClaimData.reduce((prev, values) => {
      return parseInt(prev) + parseInt(values.claimedAmount || 0);
    }, 0);
  });
}, []);


const ReviewClaimColDef = useMemo(() => ({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  cacheQuickFilter: true,
  // editable: true,
}));
    
    useEffect(() => {
        GetTeamAdvanceList(DataStatus,DataStartDate,DataEndDate);
        GetTeamExpenseList(DataStatus,DataStartDate,DataEndDate);
        GetHRAdvanceList(DataStatus,DataStartDate,DataEndDate);
        GetHRExpenseRequestsList(DataStatus,DataStartDate,DataEndDate);
        GetFinanceAdvanceList(DataStatus,DataStartDate,DataEndDate);
        GetFinanceExpenseRequestsList(DataStatus,DataStartDate,DataEndDate);
        GetEmpApprovalLevel(DataStatus,DataStartDate,DataEndDate);
      }, [])


      const defaultColDef = useMemo(() => ({
        // flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: "no-border",
        resizable:true
      }));
    
        // Table Pagination
        const paginationNumberFormatter = useCallback((params) => {
            return "[" + params.value.toLocaleString() + "]";
          }, []);
    

      const GetEmpApprovalLevel = async () => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetApprovePersonDetails?ApprovedbyGuid=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("Approval Level Details Response",response);
           const res = response.data;

           setManagerLevel(res.some(data => data.masterApprovalMatrixId === 1))
           setHRLevel(res.some(data => data.masterApprovalMatrixId === 2))
           setFinanceLevel(res.some(data => data.masterApprovalMatrixId === 3))

          setApprovalLevelDetails(response.data);
        } catch (error) {
          console.error(error);
        }
      }


      const GetTeamAdvanceList = async (RequestStatus,StartDate,EndDate)  => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetAdvanceApprovalDetailsByApprovedEmp?ApprovedbyGuid=${userData.guId}&Status=${RequestStatus}&FromDate=${moment(StartDate).format('YYYY-MM-DD')}&Todate=${moment(EndDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("Team Advance Data Response",response);
          setTeamAdvanceRowData(response.data);
        } catch (error) {
          console.error(error);
        }
      }


      const GetTeamExpenseList = async (RequestStatus,StartDate,EndDate) => {
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetBillApprovalDetailsByApprovedEmp?ApprovedbyGuid=${userData.guId}&Status=${RequestStatus}&FromDate=${moment(StartDate).format('YYYY-MM-DD')}&Todate=${moment(EndDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("Team Expense Data Response",response);
          setTeamExpenseRowData(response.data);
        } catch (error) {
          console.error(error);
        }
      }
      

      const GetHRAdvanceList = async (RequestStatus,StartDate,EndDate) => {

        if(RequestStatus === 'Pending'){
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetApprovalLinkAdvanceLevel2?ApprovedByGuId=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("HR Advance Data Response",response);
          setHRAdvanceRowData(response.data);
        } catch (error) {
          console.error(error);
        }
      }else{
        try {
          const response = await authApi.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetAdvanceApprovalDetailsByApprovedEmp?ApprovedbyGuid=${userData.guId}&Status=${RequestStatus}&FromDate=${moment(StartDate).format('YYYY-MM-DD')}&Todate=${moment(EndDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log("HR Advance Data Response",response);
           setHRAdvanceRowData(response.data);
        } catch (error) {
          console.error(error);
        }
      }
      }


      const GetHRExpenseRequestsList = async (RequestStatus,StartDate,EndDate) => {

        if(RequestStatus === 'Pending'){
          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetApprovalLinkExpenseLevel2?EmpGuid=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("HR Expense Data Response",response);
            setHrExpenseRequestsRowData(response.data);
          } catch (error) {
            console.error(error);
          }
        }else{
          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetBillApprovalDetailsByApprovedEmp?ApprovedbyGuid=${userData.guId}&Status=${RequestStatus}&FromDate=${moment(StartDate).format('YYYY-MM-DD')}&Todate=${moment(EndDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("HR Expense Data Response",response);
             setHrExpenseRequestsRowData(response.data);
          } catch (error) {
            console.error(error);
          }
        }

      }


      const GetFinanceAdvanceList = async (RequestStatus,StartDate,EndDate) => {

        if(RequestStatus === 'Pending'){
          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetApprovalLinkAdvanceLevel3?ApprovedByGuId=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Finance Advance Data Response",response);
            setFinanceAdvanceRowData(response.data);
          } catch (error) {
            console.error(error);
          }
        }else{
          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetAdvanceApprovalDetailsByApprovedEmp?ApprovedbyGuid=${userData.guId}&Status=${RequestStatus}&FromDate=${moment(StartDate).format('YYYY-MM-DD')}&Todate=${moment(EndDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Finance Advance Data Response",response);
             setFinanceAdvanceRowData(response.data);
          } catch (error) {
            console.error(error);
          }
        }

      }



      
      const GetFinanceExpenseRequestsList = async (RequestStatus,StartDate,EndDate) => {

        if(RequestStatus === 'Pending'){

          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetApprovalLinkExpenseLevel3?EmpGuid=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Finance Expense Data Response",response);
            setFinanceExpenseRequestsRowData(response.data);
          } catch (error) {
            console.error(error);
          }

        }else{
          try {
            const response = await authApi.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetBillApprovalDetailsByApprovedEmp?ApprovedbyGuid=${userData.guId}&Status=${RequestStatus}&FromDate=${moment(StartDate).format('YYYY-MM-DD')}&Todate=${moment(EndDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Finance Expense Data Response",response);
             setFinanceExpenseRequestsRowData(response.data);
          } catch (error) {
            console.error(error);
          }
        }

      }


      console.log("Selected Emp Claim for Review",ReviewSelectedEmp);
     console.log("Approval ID",ApprovalID);

      const SaveReimbursementApproval = async (e) => {
        e.preventDefault();

        let Approvedbody = ApprovedClaimList.map((data)=>{
          return  {
                   reimbursementId : data.reimbursementId,
                   claimStatus : ApprovalID === 3 ? "Approved" : "Pending",
                   reimbursementName : data.reimbursementName,
                   claimAction : "Approved",
                   approvalId : ApprovalID,
                   attachment : data.attachment,
                   empApprovalMatrixId : ReviewSelectedEmp.empApprovalMatrixId,
                   approvalStatus : "Approved",
                   approvalDate : moment().format('YYYY-MM-DD'),
                   reimbursementTicketGenerate : data.reimbursementTicketGenerate,
                   approvedByGuId : userData.guId,
                   empGuId : data.empGuId,
                   level_IndexId : ApprovalID,
                   companyId : userData.companyId,
                   branchId : userData.isActiveBranch,
                   totalAmount : PayableAmount,
                   isLock : false,
                   fullName : data.employeeName,
                   empCode : data.empCode
              }
        })
        
        console.log("Approval Body",Approvedbody);


        let RejectedBody = ReviewClaimRowData.filter((AllData)=> !Approvedbody.find(ApprovedData => AllData.reimbursementId === ApprovedData.reimbursementId)).map((data)=>{
          return  {
                   reimbursementId : data.reimbursementId,
                   claimStatus : "Rejected",
                   reimbursementName : data.reimbursementName,
                   claimAction : "Rejected",
                   approvalId : ApprovalID,
                   attachment : data.attachment,
                   claimedAmount:data.claimedAmount,
                   empApprovalMatrixId : ReviewSelectedEmp.empApprovalMatrixId,
                   approvalStatus : Approvedbody.length !== 0 ? "Approved":"Rejected",
                   approvalDate : moment().format('YYYY-MM-DD'),
                   reimbursementTicketGenerate : data.reimbursementTicketGenerate,
                   approvedByGuId : userData.guId,
                   empGuId : data.empGuId,
                   level_IndexId : ApprovalID,
                   companyId : userData.companyId,
                   branchId : userData.isActiveBranch,
                   totalAmount : PayableAmount,
                   isLock : false,
                   rejectedAmount : ReviewClaimRowData.filter((AllData)=> !Approvedbody.find(ApprovedData => AllData.reimbursementId === ApprovedData.reimbursementId)).reduce((sum,ExpData)=>{
                    return Number(sum || 0) + Number(ExpData.claimedAmount || 0)
                   },0),
                   fullName : data.employeeName,
                   empCode : data.empCode
              }
        })

        console.log("Rejection Body",RejectedBody);


        let Finalbody = _.union(Approvedbody,RejectedBody);

        console.log("Final Body",Finalbody);



        await authApi.post(
          `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SaveApprovalLinkExpense`,Finalbody
      )
      .then((response) => {
         //  const res = response.data;
          console.log( "Save Expense Approval Response",response);
          // console.log("Add Reimbursement List Response ",response.data);
      
          const res = response.data;
          // console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:'Done',
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
          GetTeamExpenseList();
          GetHRExpenseRequestsList();
           GetFinanceExpenseRequestsList();
          setReviewClaimRowData([]);
          setReviewSelectedEmp([]);
          setPayableAmount(0);
          onReviewClaimClose();
        })
        .catch((error) => {
          console.error(error);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Expense Claim not Updated",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });

      };



  return (
    <div>

      <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Expense Approvals</h1>
      </div>


      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end  mb-4'>

        <div className="space-y-2">
            <label>Select Date Range</label>
            <DatePicker
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selectsRange={true}
              startDate={DataStartDate}
              endDate={DataEndDate}
              onChange={(update) => {
                 setDataDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Date Range'
            />
        </div>


        <div className="space-y-2">
            <label>Select Status</label>
            <Select value={DataStatus} onChange={({target})=>{setDataStatus(target.value)}} borderColor={'gray.400'}>
              <option value={'Pending'}>Pending</option>
              <option value={'Approved'}>Approved</option>
            </Select>
        </div>

        <Button leftIcon={<Search size={'16px'} />} colorScheme={'purple'} onClick={()=>{
          if(ManagerLevel){
            GetTeamAdvanceList(DataStatus,DataStartDate,DataEndDate);
            GetTeamExpenseList(DataStatus,DataStartDate,DataEndDate);
          }
          
          if(HRLevel){
            GetHRAdvanceList(DataStatus,DataStartDate,DataEndDate);
            GetHRExpenseRequestsList(DataStatus,DataStartDate,DataEndDate);
          }

          if(FinanceLevel){
            GetFinanceAdvanceList(DataStatus,DataStartDate,DataEndDate);
            GetFinanceExpenseRequestsList(DataStatus,DataStartDate,DataEndDate);
          }
        }}>Search</Button>

      </Box>



<Tabs size={'sm'} variant={'solid-rounded'} colorScheme={'purple'}>
            <TabList>
                {ManagerLevel ? 
                <Tab>1st Expense Approval</Tab> : ''}
                {ManagerLevel ? 
                <Tab>1st Advance Approval</Tab> :''}
                {HRLevel ? 
                <Tab>2nd Expense Approval</Tab>: ''}
                {HRLevel ? 
                <Tab>2nd Advance Approval</Tab>: ''}
                {FinanceLevel ? 
                <Tab>3rd Expense Approval</Tab>: ''}
                {FinanceLevel ? 
                <Tab>3rd Advance Approval</Tab>: ''}
    </TabList> 
  <TabPanels>
  {ManagerLevel ? 
    <TabPanel  px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={TeamExpenseRowData} // Row Data for Rows
          columnDefs={TeamExpenseCashColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>  : ''}

  {ManagerLevel ?  
    <TabPanel  px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={TeamAdvanceRowData} // Row Data for Rows
          columnDefs={TeamAdvanceCashColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel> :''}

    {HRLevel ?  
    <TabPanel  px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={HrExpenseRequestsRowData} // Row Data for Rows
          columnDefs={HRExpenseColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel> : ''}

    {HRLevel ? 
    <TabPanel  px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={HRAdvanceRowData} // Row Data for Rows
          columnDefs={HRAdvanceCashColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel> : ''}

    {FinanceLevel ? 
    <TabPanel  px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FinanceExpenseRequestsRowData} // Row Data for Rows
          columnDefs={FinanceExpenseColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel> : ''}

    {FinanceLevel ? 
    <TabPanel  px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FinanceAdvanceRowData} // Row Data for Rows
          columnDefs={FinanceAdvanceCashColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel> : ''}



  </TabPanels>
</Tabs>

      {/* Review Claim Modal Start */}
      <Modal
        size="full"
        isOpen={isReviewClaimOpen}
        onClose={onReviewClaimClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review Expense Claim</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody className='bg-slate-200'>
            <div className="flex pb-1 gap-8">
              <div>
                <p className="text-sm text-gray-500">Employee Name</p>
                <h6 className="font-semibold">
                  {ReviewSelectedEmp.fullName || ReviewSelectedEmp.empName}
                </h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Employee Code</p>
                <h6 className="font-semibold">{ReviewSelectedEmp.empCode}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Ticket Id</p>
                <h6 className="font-semibold">{ReviewSelectedEmp.reimbursementTicketGenerate}</h6>
              </div>
              

            </div>
            <hr />

            <div className="ag-theme-alpine my-6">
                  <AgGridReact
                    style={gridStyle}
                    ref={ClaimRef}
                    domLayout={"autoHeight"}
                    rowData={ReviewClaimRowData} // Row Data for Rows
                    columnDefs={DataStatus === 'Pending'? ReviewClaimColumnDefs : AppReviewClaimColumnDefs} // Column Defs for Columns
                    defaultColDef={ReviewClaimColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    pagination={true}
                    rowSelection={"multiple"}
                    paginationPageSize={15}
                    onFirstDataRendered= {DataStatus === 'Pending'? onFirstDataRendered : ''}
                    paginationNumberFormatter={paginationNumberFormatter}
                    onSelectionChanged={onSelectionChanged}
                  />
                </div>

                {DataStatus === 'Pending'?
                <div className='my-6 flex justify-end '> 
                <form onSubmit={SaveReimbursementApproval}>
                <div className="space-y-4 ">

                    <div className="space-y-2">
                      <label>Approved Amount</label>
                      <InputGroup>
                        <InputLeftAddon bg={'gray.300'} children="INR" />
                        <Input
                          bgColor="white"
                          isReadOnly
                          value={PayableAmount}
                          onChange={(e) => {
                            setPayableAmount(e.target.value);
                          }}
                          borderColor="gray.400"
                          placeholder="Payable Amount"
                        />
                      </InputGroup>
                    </div>


                  <div className="space-x-4 text-right">
                    <Button type="submit" variant="solid" colorScheme="purple">
                      Submit
                    </Button>
                  </div> 
                </div>
              </form>
                </div>
                : ''}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal size='full' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attachment</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div id="pdf-div"  >
            <embed

              src={ViewClaimAttachment + "#toolbar=0"}
              type="application/pdf"
              className="h-screen w-full !select-none"
              height={800}
              width={500}
            />
            </div>
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme='purple' onClick={onClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>


    </div>
  )
}

export default AllApprovals